import { Component, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { cl } from '@fullcalendar/core/internal-common';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
  iResultHttp,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { Subject, filter, takeUntil } from 'rxjs';
import { AttendanceService } from 'src/app/Services/Api/Attendance.service';
import { EnrollmentService } from 'src/app/Services/Api/Enrollment.service';
import { ProjectService } from 'src/app/Services/Api/Project.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { StudentPromotionService } from 'src/app/Services/Api/StudentPromotion.service';
import { OutputsService } from 'src/app/Services/Utils/Outputs.service';
import { DetailViewStudentsClass } from 'src/app/Shared/Classes/DetailViewStudentsClass';
import * as XLSX from 'xlsx';

@Component({
  selector: 'detailView-Students',
  templateUrl: './DetailView-Students.component.html',
  styleUrls: ['./DetailView-Students.component.scss'],
})
export class DetailViewStudentsComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input()
  get promotionStudent() {
    return this.extraClass.promotionStudent;
  }
  set promotionStudent(value) {
    this.extraClass.promotionStudent = value;
    setTimeout(() => {
      this.isLoaded = true;
      this.extraClass.displayTable = true;
    }, 100);
  }

  @Input() tableDataParsed: any[] = [];

  @Input() promotionId: number = 0;

  extraClass: DetailViewStudentsClass = new DetailViewStudentsClass();

  isLoaded: boolean = false;

  constructor(
    private outputsSE: OutputsService,
    private attendanceSE: AttendanceService,
    private generalLoaderSE: GeneralLoaderService,
    private enrollmentSE: EnrollmentService,
    private projectSE: ProjectService,
    private studentPromotionSE: StudentPromotionService,
    private promotionSE: PromotionFormationService,
    private staticUtilitiesSE: StaticUtilitiesService
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeToOutputs();
    this.getAttendanceSelector();
    this.getFundaeSelector();
    this.getEnrollmentStateSelector();
    this.getProjectState();
  }

  override ngOnDestroy(): void {
    this.outputsSE.clearOutputs();
    this.destroySubscriptions();
  }

  /**
   * HANDLERS
   */

  successGetAttendanceSelectorHandler(res: iResultHttp) {
    let { data } = res;
    this.extraClass.modalitySelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetFundaeSelectorHandler(res: iResultHttp) {
    let { data } = res;
    this.extraClass.fundaeSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetEnrollmentStateSelectorHandler(res: iResultHttp) {
    let { data } = res;
    this.extraClass.chipBorderSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetProjectStateHandler(res: iResultHttp) {
    let { data } = res;
    this.extraClass.chipSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }
  successDownloadDiplomasHandler(res: iResultHttp) {
    let { data } = res;
    this.downloadFile(data.file, 'diplomas', 'application/zip');
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateEntollmentHandler(res: iResultHttp) {}
  successUpdateStudentPromotionHandler(res: iResultHttp) {}
  successSendDiplomasHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback('Diplomas enviados correctamente');
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDownloadFUNDAEHandler(res: iResultHttp) {
    const { data } = res;
    this.generateExcelFUNDAE(data);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */

  toggleIsEditing() {
    this.extraClass.isEditing = !this.extraClass.isEditing;
    this.extraClass.setAsEditing(this.extraClass.isEditing);
  }

  onChangeSelectedRows(event) {
    this.extraClass.selectedRows = event;
  }

  base64ToBlob(base64String: string, type: string): Blob {
    const byteCharacters: string = atob(base64String);
    const byteArrays: any = [];
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
    const byteArray = new Uint8Array(byteArrays);
    const blob = new Blob([byteArray], { type });
    return blob;
  }

  downloadFile(base64String: string, fileName: string, type: string) {
    const blob = this.base64ToBlob(base64String, type);
    const fileUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(fileUrl);
  }

  generateExcelFUNDAE(arrayToConvert: any[]) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(
      this.jsonExcelParse(arrayToConvert)
    );
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    XLSX.writeFile(workBook, 'fundae.xlsx');
  }

  jsonExcelParse(array: Array<any>) {
    var newJsonParsedArray: Array<any> = [];
    array.forEach((element, index) => {
      const newJsonObj = {
        'N°': index + 1,
        EMPRESA: element.company,
        CIF: element?.companyCif,
        'PRIMER APELLIDO': element?.companyCif,
        'SEGUNDO APELLIDO': element?.secondSurname ?? '',
        NOMBRE: element?.studentName,
        'NIF (Letra y todos los NÚM, incluidos los "0")': element?.nif,
        'Nº SS (completo, incluido los "0". Sin barras ni espacios)': '',
        'CTA. COTIZ. CENTRO TRAB.': '',
        'FECHA NACIMIENTO': element?.dateOfBirth,
        EMAIL: element?.email,
        TELÉFONO: element?.phone,
        SEXO: element?.gender,
        DISCAPACIDAD: '',
        'NIVEL DE ESTUDIOS': '',
        'CATEGORIA PROFESIONAL': '',
        'GRUPO COTIZACIÓN': '',
        '¿TRABAJADOR EN ERTE?': '',
        'HORARIO LABORAL TRABAJADOR': '',
        'COSTE/HORA TRABAJADOR (SUELDO BRUTO/HORAS CONVENIO)': '',
      };
      newJsonParsedArray.push(newJsonObj);
    });
    return newJsonParsedArray;
  }
  /**
   * Este es el método que hay que llamar en el NgOnInit para que se suscriba a los outputs de los componentes que se insertan dinámicamente en la tabla.
   */
  subscribeToOutputs() {
    this.outputsSE.mustSubscribe
      .pipe(takeUntil(this.extraClass.mustSubscribeSubject))
      .subscribe((res) => {
        this.destroySubscriptions();
        this.subscribeToOutputsarray();
      });
  }

  /**
   * Este método se encarga de suscribirse a los outputs de los componentes que se insertan dinámicamente en la tabla.
   */
  subscribeToOutputsarray() {
    this.outputsSE.outputsArray.forEach((outputObject, index) => {
      this.extraClass.SubjectArray.push(new Subject());
      outputObject.value
        .pipe(
          takeUntil(this.extraClass.SubjectArray[index]),
          filter((res) => res)
        )
        .subscribe((res) => {
          switch (outputObject.key) {
            case 'modalityId':
              this.prepareForEnrollmentUpdate(
                res,
                'modality',
                outputObject.line
              );
              break;
            case 'fundae':
              this.prepareForEnrollmentUpdate(res, 'fundae', outputObject.line);
              break;
            case 'internship':
              this.prepareForEnrollmentUpdate(
                res,
                'internship',
                outputObject.line
              );
              break;
            case 'enrollmentState':
              this.prepareForPromotionStudentUpdate(
                res,
                'enrollmentState',
                outputObject.line
              );
              break;
            case 'attendanceQualification':
              this.prepareForPromotionStudentUpdate(
                res,
                'attendanceQualification',
                outputObject.line
              );
              break;
            case 'projectQualification':
              this.prepareForPromotionStudentUpdate(
                res,
                'projectQualification',
                outputObject.line
              );
              break;
            case 'finalQualification':
              this.prepareForPromotionStudentUpdate(
                res,
                'finalQualification',
                outputObject.line
              );
              break;
            case 'projectState':
              this.prepareForPromotionStudentUpdate(
                res,
                'projectState',
                outputObject.line
              );
              break;

            default:
              break;
          }
        });
    });
  }

  /**
   * Este método se encarga de destruir las suscripciones a los outputs de los componentes que se insertan dinámicamente en la tabla.
   * Hay que ponerlo en el NgOnDestroy.
   */
  destroySubscriptions() {
    this.extraClass.SubjectArray.forEach((subject) => {
      subject.next('');
      subject.complete();
    });
    this.extraClass.SubjectArray = [];
  }

  /**
   * FUNCTINOALITIES
   */

  prepareForEnrollmentUpdate(value, key: string, index: number) {
    let objectToPass = {
      id: this.extraClass.promotionStudent[index].enrollmentId,
      [key]: value,
    };
    this.updateEntollment(objectToPass);
  }
  prepareForPromotionStudentUpdate(value, key: string, index: number) {
    let objectToPass = {
      id: this.extraClass.promotionStudent[index].id,
      [key]: value,
    };
    this.updateStudentPromotion(objectToPass);
  }

  generateBodyForSendDiplomas() {
    let objectToPass = {
      id: this.promotionId,
      ids: this.extraClass.selectedRows.map((row) => row.id),
    };
    this.sendDiplomas(objectToPass);
  }

  generateBodyForDownloadDiplomas() {
    let objectToPass = {
      id: this.promotionId,
      ids: this.extraClass.selectedRows.map((row) => row.id),
    };
    this.downloadDiplomas(objectToPass);
  }

  onRowClick(event) {
    this.staticUtilitiesSE.goTo(
      'alumnos/mis-alumnos/ficha-alumno',
      slideNavigationTypes.slideToTop,
      { alumno: event.studentId }
    );
  }

  /**
   * API CALLS
   */

  getAttendanceSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.attendanceSE.getSelector(behaviorSubject, {
      formationId: this.staticUtilitiesSE.getParams().formation,
    });
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAttendanceSelectorHandler(res),
        });
      });
  }

  getFundaeSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.enrollmentSE.getFundaeSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetFundaeSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getEnrollmentStateSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.enrollmentSE.getEnrollmentStateSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetEnrollmentStateSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getProjectState() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.getStateSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetProjectStateHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateEntollment(objectToPass) {
    this.enrollmentSE.update(objectToPass);
    this.enrollmentSE
      .getResultUpdate()
      .pipe(
        takeUntil(this._unsubInd),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.successUpdateEntollmentHandler(res);
        this._unsubInd.next('');
      });
    this.enrollmentSE
      .getResultUpdateError()
      .pipe(
        takeUntil(this._unsub),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  updateStudentPromotion(objectToPass) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.studentPromotionSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateStudentPromotionHandler(res),
          },
        ]);
      });
  }

  downloadFUNDAE() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.getDataForFundae(behaviorSubject, this.promotionId);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDownloadFUNDAEHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  sendDiplomas(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.sendDiplomas(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successSendDiplomasHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  downloadDiplomas(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.getDiplomas(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDownloadDiplomasHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
