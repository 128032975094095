<div class="tutorias">
  <ng-container *ngIf="extraClass.tutorings.length > 0; else noData">
    <ng-container
      *ngFor="
        let teacher of extraClass.tutorings;
        index as teacherIndex;
        trackBy: tutoringTrackBy
      "
    >
      <div
        class="tutoria__teacherBlock"
        (click)="goToTeacherDetail(teacher, $event)"
      >
        <div class="tutoria__teacherBlock--title">
          {{ teacher.teacherName }}
        </div>

        <ng-container
          *ngFor="let tutoring of teacher.tutorings; index as tutoringIndex"
        >
          <div class="tutoria__teacherBlock--tutoring">
            <div class="tutoria__teacherBlock--infoLine">
              <div class="tutoria__teacherBlock--infoLine--items">
                <div class="tutoria__teacherBlock--infoLine--bluePill">
                  {{ tutoring.startDate ?? "-" }}
                </div>

                <div class="tutoria__teacherBlock--infoLine--bluePill">
                  {{ tutoring.hours ?? "-" }}
                </div>

                <div class="tutoria__teacherBlock--infoLine--bluePill">
                  {{ tutoring.slotMinutes ?? "-" }} minutos
                </div>

                <div class="tutoria__teacherBlock--infoLine--bluePill">
                  {{ tutoring.tutoringAttendanceName ?? "-" }}
                </div>

                <div class="tutoria__teacherBlock--infoLine--bluePill">
                  {{ tutoring.classroomName ?? "-" }}
                </div>
              </div>

              <div class="tutoria__teacherBlock--infoLine--stateAndIcons">
                <Chip
                  [value]="tutoring.tutoringStateName"
                  [bold]="true"
                  [type]="tutoring.tutoringStateColor"
                ></Chip>

                <img
                  [src]="IconsHandlerService.getEditBlueIcon()"
                  (click)="$event.stopPropagation(); openEditPopup(tutoring)"
                  alt="edit"
                />
                <img
                  [src]="IconsHandlerService.getDeleteIcon()"
                  (click)="
                    $event.stopPropagation(); onDeleteClick(tutoring.tutoringId)
                  "
                  alt="delete"
                />
                <img
                  [src]="IconsHandlerService.getDuplicateIcon()"
                  (click)="
                    $event.stopPropagation();
                    onDuplicateClick(tutoring.tutoringId)
                  "
                  alt="duplicate"
                />
              </div>
            </div>

            <div class="tutoria__teacherBlock--whiteBox">
              <div class="tutoria__teacherBlock--whiteBox--tutoringLine">
                <div
                  class="tutoria__teacherBlock--whiteBox--tutoringLine--items"
                >
                  <ng-container *ngFor="let topicNames of tutoring.topicNames">
                    <div class="items--backgroung gray">
                      {{ topicNames ?? "-" }}
                    </div>
                  </ng-container>
                </div>
                <div
                  class="tutoria__teacherBlock--whiteBox--tutoringLine--items"
                ></div>
              </div>

              <div class="tutoria__teacherBlock--whiteBox--tutoringLine">
                <div
                  class="tutoria__teacherBlock--whiteBox--tutoringLine--items"
                >
                  <ng-container
                    *ngFor="let formationName of tutoring.formationNames"
                  >
                    <div class="items--backgroung beige">
                      {{ formationName }}
                    </div>
                  </ng-container>
                </div>
                <div
                  class="tutoria__teacherBlock--whiteBox--tutoringLine--items"
                ></div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #noData>
  <div class="noData center-in-screen">No hay tutorias de esta promoción</div>
</ng-template>
