<customInput
  ngDefaultControl
  [name]="name"
  [ngModel]="model"
  [disabled]="optionsInput.readOnly ?? false"
  [options]="optionsInput"
  (ngModelChange)="onModelChange($event)"
  (change)="sendNgModel($event);onNgModelChange($event)"
  [classList]="getClassname()"
>
</customInput>