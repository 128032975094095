<div class="popupGeneralContainer">
  <div class="header">
    <p class="title">Añadiendo usuario a IEM</p>
  </div>

  <div class="body">

    <div class="fullSection">
      <div class="rowFirstSection">
        <CustomInput  (change)="isCompleted()" [placeHolder]="'Nombre'" ngDefaultControl  [(ngModel)]="data.name" [label]="'Nombre'"></CustomInput>
        <CustomInput  (change)="isCompleted()" [placeHolder]="'Apellidos'" ngDefaultControl  [(ngModel)]="data.surname" [label]="'Apellidos'"></CustomInput>
      </div>
    
      <div class="rowFirstSection">
        <CustomInput  (change)="isCompleted()" [placeHolder]="'DNI'" ngDefaultControl  [(ngModel)]="data.dni" [label]="'DNI'"></CustomInput>
        <div class="telefonoDeContacto">
          <div class="subColumn">
            <label for="selector">Teléfono de contacto</label>
            <CountrySelector ngDefaultControl (emitCountry)="addPhonePrefix($event)" [(ngModel)]="data.phonePrefix" ></CountrySelector>
          </div>
          <div class="inputDiv">
            <CustomInput  (change)="isCompleted()" [placeHolder]="'Teléfono'" ngDefaultControl  [(ngModel)]="data.phone"></CustomInput>
          </div>
        </div>
      </div>
      <div class="longInput">
        <CustomInput  (change)="isCompleted()" [placeHolder]="'Correo electrónico'" ngDefaultControl  [(ngModel)]="data.email" [label]="'Correo electrónico'"></CustomInput>
      </div>
    </div>


  </div>

  <div class="footer">
    <btn classList="violetButton" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="principalButton" [class.disabled]="!isActive" (click)="finishButton()" >
      <ng-container>
        Finalizar
      </ng-container>
    </btn>
  </div>

</div>