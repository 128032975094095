import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  StaticUtilitiesService,
  SubjectGeneratorService,
  iOptionsSelector,
  iResultHttp,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';

import { StaticUtilitiesService as NewStaticUtilitiesService } from '@quasar-dynamics/basic-designsystem';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { iPromotionFormationFilter } from 'src/app/Shared/Interfaces/iPromotionFormation';
import { filter, takeUntil } from 'rxjs';
import { FormationService } from 'src/app/Services/Api/Formation.service';
import { GeneralLoaderService } from '@quasar-dynamics/basic-designsystem';
import { AttendanceService } from 'src/app/Services/Api/Attendance.service';

@Component({
  selector: 'AlumnoSelectedSecondStep',
  templateUrl: './AlumnoSelectedSecondStep.component.html',
  styleUrls: ['./AlumnoSelectedSecondStep.component.scss'],
})
export class AlumnoSelectedSecondStepComponent
  extends SubjectGeneratorService
  implements OnInit
{
  @Output() dataUnAlumnoSelectedSecondStep = new EventEmitter();
  @Input() dataUnAlumnoSelectedFirstStep: any = null;

  attendanceModality: any[] = [null, null];
  doubleMasterId: number | null = null;
  selectorModo: any = null;
  selectorFormation: any = null;
  doubleEnrollment: boolean = false;
  enrollmentOption: any = null;
  specialConditions: any = null;
  conditionsText: string = '';
  indexSelectores: number = 0;
  typeOfFormation: any = {
    type: '',
  };
  selectorOptionsformation: iOptionsSelector = {
    items: [],
    bindLabel: 'name',
    search: true,
    append: true,
    placeholder: 'Seleccione una formación',
  };
  optionsSelectorTipoMatricula: iOptionsSelector = {
    items: ['General', 'Internacional', 'Solo proyecto'],
    bindLabel: 'name',
    search: true,
    append: true,
    placeholder: 'Seleccione un tipo de matrícula',
  };
  arrayFormatos: any = [null, null];
  opcionesSelectorModalidad: iOptionsSelector = {
    items: [],
    bindLabel: 'name',
    bindValue: 'id',
    append: true,
    search: true,
    placeholder: 'Seleccione una modalidad',
    clearable: false,
    notFoundText: 'No se han encontrado modalidades',
  };
  formationArray: any = [];
  fundaeRadioButtons: any = null;
  arrayModos: any = [];
  arrayCounter: number = 0;
  arraySelectorPromotion: any = [];
  selectorOptionsPromotionArray: iOptionsSelector[] | any = [];
  formationFilter: iPromotionFormationFilter = {
    num_devoluciones: 5000000000000000,
    num_pagina: 1,
  };
  promotionFilter: iPromotionFormationFilter = {
    num_devoluciones: 5000000000000000,
    num_pagina: 1,
  };
  selectorFilter = {
    typeOf: 'master',
  };
  staticUtilitiesSE: any;

  constructor(
    private promotionSE: PromotionFormationService,
    private formationSE: FormationService,
    private generalLoaderSE: GeneralLoaderService,
    private attendanceSE: AttendanceService
  ) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataUnAlumnoSelectedFirstStep']) {
      if (changes['dataUnAlumnoSelectedFirstStep'].currentValue) {
        let studentId =
          changes['dataUnAlumnoSelectedFirstStep'].currentValue?.student
            ?.idStudent;
        this.typeOfFormation.type = this.dataUnAlumnoSelectedFirstStep?.type;
        if (!studentId) {
          this.getFormationsNoStudent();
        } else {
          this.getFormationsByStudentId(studentId);
        }
      }
    }
  }

  /**
   * HANDLERS
   */

  successGetSelectorByPromotionIdHandler(res: iResultHttp) {
    const { data } = res;
    this.opcionesSelectorModalidad.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */

  detectHowManyFormationsSelected(event) {
    if (!event) return;
    this.cleanSelectors();

    if (event.masters) {
      this.doubleMasterId = event.id;
      this.formationArray = [event.masters.masterOne, event.masters.masterTwo];

      let formaciones = this.formationArray;
      formaciones.forEach((formacion, index) => {
        this.eachPromotionSelected(formacion, index);
      });
      return;
    }

    this.doubleMasterId = null;
    this.formationArray.push(event);
    this.pushSelector();
    this.getPromotionsByFormationId(event.id, 0);
    this.arrayModos.push(event.modality);
  }

  async eachPromotionSelected(formacion, index) {
    const selectorIndex = await this.pushSelector();
    if (index == 0) {
      this.getPromotionsByFormationId(formacion.idFormation, selectorIndex);
    }
    if (index == 1) {
      this.getPromotionsByFormationId2(formacion.idFormation, selectorIndex);
    }
    this.arrayModos.push(formacion.modality);
  }

  pushSelector(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.indexSelectores == 1
        ? (this.indexSelectores = 0)
        : (this.indexSelectores = 1);
      this.selectorOptionsPromotionArray?.push({
        items: [],
        append: true,
        placeholder: 'Seleccione una edición',
      });
      this.arraySelectorPromotion.push({ selector: null });
      resolve(this.indexSelectores);
    });
  }
  cleanSelectors() {
    this.arraySelectorPromotion = [];
    this.selectorFormation = null;
    this.selectorOptionsPromotionArray.length = 0;
    this.arrayCounter = 0;
    this.arrayFormatos = [null, null];
    this.arrayModos = [];
  }

  removeObjectSelector(event) {
    this.arraySelectorPromotion.splice(event.index, 1);
    this.doubleEnrollment = false;
  }

  detectSpecialConditions(event) {
    this.specialConditions = event.checked;
  }

  /**
   * API CALLS
   */

  getFormationsByStudentId(studentId: number) {
    this.formationSE.getFormationsByStudentId(studentId, this.typeOfFormation);
    const unsubSubject = this.createSubject();

    this.formationSE
      .getResultAllFormationByStudentId()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res) => {
        if (!res) return;
        let { data } = res;
        this.selectorOptionsformation.items = data;
        this.sendDataToSubject(unsubSubject);
      });
    this.formationSE
      .getResultAllFormationByStudentIdError()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this.sendDataToSubject(unsubSubject);
      });
  }

  getFormationsNoStudent() {
    this.formationSE.getAllFormationsForSelectorOV(this.selectorFilter);
    const unsubSubject = this.createSubject();

    this.formationSE
      .getResultAllFormationListOV()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res) => {
        if (!res) return;
        let { data } = res;
        this.selectorOptionsformation.items = data;
        this.sendDataToSubject(unsubSubject);
      });
    this.formationSE
      .getResultAllFormationListOVError()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this.sendDataToSubject(unsubSubject);
      });
  }

  getPromotionsByFormationId(id, index) {
    const unsubSubject = this.createSubject();
    this.promotionSE.getPromotionsByFormationId(id);
    this.promotionSE
      .getResultPromotionsByFormationId()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res) => {
        if (!res) return;
        let { data } = res;
        this.selectorOptionsPromotionArray![0].items = data.map((item: any) => {
          return {
            ...item,
            label: `${item.edition}`,
          };
        });
        this.sendDataToSubject(unsubSubject);
      });
    this.promotionSE
      .getResultPromotionsByFormationIdError()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this.sendDataToSubject(unsubSubject);
      });
  }

  getPromotionsByFormationId2(id, index) {
    const unsubSubject = this.createSubject();
    this.promotionSE.getPromotionsByFormationId2(id);
    this.promotionSE
      .getResultPromotionsByFormationId2()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res) => {
        if (!res) return;
        let { data } = res;
        this.selectorOptionsPromotionArray![1].items = data.map((item: any) => {
          return { ...item, label: `${item.edition}` };
        });
        this.sendDataToSubject(unsubSubject);
      });
    this.promotionSE
      .getResultPromotionsByFormationId2Error()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this.sendDataToSubject(unsubSubject);
      });
  }

  getSelectorByPromotionId(promotionId: number) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = NewStaticUtilitiesService.createSubject();
    const behaviorSubject = NewStaticUtilitiesService.createBehaviorSubject();
    this.attendanceSE.getSelectorByPromotionId(behaviorSubject, promotionId);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        NewStaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetSelectorByPromotionIdHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  emitData() {
    this.dataUnAlumnoSelectedSecondStep.emit({
      promotion: this.arraySelectorPromotion.map(
        (item: any) => item['selector']?.id ?? null
      ),
      type: this.enrollmentOption,
      isDouble: this.formationArray.length > 1,
      doubleTitulation: this.doubleMasterId,
      specialConditions: this.conditionsText,
      modality: this.attendanceModality,
    });
  }
}
