<div class="popupGeneralContainer">
  <div class="header">
    <p class="title">Asociar profesor a producto</p>
  </div>

  <div class="body">
    <div class="fullSection">
      <selector class="popupAddProject" [options]="eventTypeSelectorOptions" [(ngModel)]="selectedType" ></selector>
    </div>
  </div>

  <div class="footer">
    <btn classList="violetButton" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="principalButton" (click)="closePopup(selectedType)">
      <ng-container>
        Finalizar
      </ng-container>
    </btn>
  </div>

</div>