import { Component, OnInit } from '@angular/core';
import {
  PopupService,
  iOptionsSelector,
  iResultHttp,
  iUnsubscribeDestroy,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { FormationService } from 'src/app/Services/Api/Formation.service';
import { environment } from 'src/environments/environment';
import { filter, takeUntil } from 'rxjs/operators';
import { MasterService } from 'src/app/Services/Api/Master.service';
import { IncompanyService } from 'src/app/Services/Api/Incompany.service';
import { CourseService } from 'src/app/Services/Api/Course.service';
import { AddDobleTitulacionPopupComponent } from 'src/app/Popups/AddPopup/AddDobleTitulacion-Popup/AddDobleTitulacion-Popup.component';
import { StaticUtilitiesService as oldStaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import {
  StaticUtilitiesService,
  iDate,
} from '@quasar-dynamics/basic-designsystem';
import { GeneralLoaderService } from '@quasar-dynamics/basic-designsystem';
import {
  iFilterReturn,
  iOptionsFilters,
} from 'src/app/Shared/Components/searcher-and-filter/searcher-and-filter.component';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';
import { GestionUsuariosFormacionesComponentNewVersionClass } from 'src/app/Shared/Classes/GestionUsuariosFormacionesComponentNewVersionClass';
import { AcademicService } from 'src/app/Services/Api/Academic.service';
import { AttendanceService } from 'src/app/Services/Api/Attendance.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { ClassroomService } from 'src/app/Services/Api/Classroom.service';
import { TutoringService } from 'src/app/Services/Api/Tutoring.service';

@Component({
  selector: 'app-GestionUsuarios-Formaciones',
  templateUrl: './GestionUsuarios-Formaciones.component.html',
  styleUrls: ['./GestionUsuarios-Formaciones.component.scss'],
})
export class GestionUsuariosFormacionesComponentNewVersion
  extends iUnsubscribeDestroy
  implements OnInit
{
  extraClass: GestionUsuariosFormacionesComponentNewVersionClass =
    new GestionUsuariosFormacionesComponentNewVersionClass();

  rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  placeholdersArray: string[] = [
    'Busca por nombre de la formación, descripción, duración o precio',
    'Busca por nombre de la formación, descripción, duración o precio',
    'Busca por nombre de la formación, descripción, duración o precio',
    'Busca por nombre de la doble titulación o precio',
    'Busca por el nombre y apellidos del profesor',
  ];

  // refreshTutorings
  refreshTutorings: boolean = false;

  // Datos
  allFormations: any = [];
  allMasters: any = [];
  allCursos: any = [];
  allIncompanies: any = [];
  allDobleTitulacion: any = [];
  // Totales
  totalFormations: number = 0;
  totalMasters: number = 0;
  totalCursos: number = 0;
  totalIncompanies: number = 0;
  totalDobleTitulacion: number = 0;
  // Readys
  areReadyAllFormations: boolean = false;
  areReadyAllMasters: boolean = false;
  areReadyAllCursos: boolean = false;
  areReadyAllIncompanies: boolean = false;
  areReadyAllDobleTitulacion: boolean = false;
  // Toggle
  optionSubmenuSelected: number = 0;
  // Buscadores y filtros
  searcher: string = '';
  filterAllFormations: any = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  };
  filterAllMasters: any = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  };
  filterAllCursos: any = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  };
  filterAllIncompanies: any = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  };
  filterAllDobleTitulacion: any = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  };
  filterTutorings: {
    promotionId?: number;
    masterId?: number;
    genericFilter?: string;
    stateId?: number;
    startDate?: string;
    classroomId?: number;
  } = {};

  IconsHandlerService = IconsHandlerService;

  modalidadFilter: string | null = null;

  selectorArray: iOptionsFilters[] = [
    {
      label: 'Modalidad',
      model: this.modalidadFilter,
      options: {
        items: [
          {
            id: 1,
            name: 'Presencial',
          },
          {
            id: 2,
            name: 'Online',
          },
          {
            id: 3,
            name: 'Blended',
          },
          {
            id: 4,
            name: 'Incompany',
          },
        ],
        placeholder: 'Modalidad',
        search: true,
        append: true,
        bindLabel: 'name',
        bindValue: 'id',
      },
    },
    {
      label: 'Horas',
      model: this.modalidadFilter,
      options: {
        items: [
          {
            id: 1,
            name: 'Presencial',
          },
          {
            id: 2,
            name: 'Online',
          },
          {
            id: 3,
            name: 'Blended',
          },
          {
            id: 4,
            name: 'Incompany',
          },
        ],
        placeholder: 'Modalidad',
        search: true,
        append: true,
        bindLabel: 'name',
        bindValue: 'id',
      },
    },
  ];

  gridView: boolean = false;

  constructor(
    private formationSE: FormationService,
    private masterSE: MasterService,
    private incompanySE: IncompanyService,
    private courseSE: CourseService,
    private popupSE: PopupService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private generalLoaderSE: GeneralLoaderService,
    private academicSE: AcademicService,
    private attendanceModalitySE: AttendanceService,
    private promotionSE: PromotionFormationService,
    private classroomSE: ClassroomService,
    private tutoringSE: TutoringService
  ) {
    super();
  }

  ngOnInit() {
    this.rolesAndPermissions =
      oldStaticUtilitiesService.getUserPermissionsAndRoles();
    // this.getFormations();
    this.getCursos();
    this.getMasters();
    this.getIncompanies();
    this.getMasterSelector();
    this.getDobleTitulacion();
    this.getClassroomSelector();
    this._getTutoringStateSelector();
    // this.getAcademicStateSelector();
    this.getAttendanceModalitySelector();
    this.getPromotionsByAllMastersSelector();
  }

  /**
   * HANDLERS
   */

  successCreateMasterHandler(res: iResultHttp) {
    let { data } = res;
    this.staticUtilitiesSE.goTo(
      'formaciones/formaciones-detalle',
      slideNavigationTypes.slideToTop,
      { master: data.masterId }
    );
    this.generalLoaderSE.removeFromLoaderAmount();
  }
  successCreateCourseHandler(res: iResultHttp) {
    let { data } = res;
    this.staticUtilitiesSE.goTo(
      'formaciones/formaciones-detalle',
      slideNavigationTypes.slideToTop,
      { curso: data.courseId }
    );
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  // successGetAcademicStateSelectorHandler(res: iResultHttp) {
  //   const { data } = res;
  //   this.extraClass.academicStateSelectorOptions.items = data;
  //   this.generalLoaderSE.removeFromLoaderAmount();
  // }

  successGetAttendanceModalitySelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.modalityAttendanceSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetPromotionsByAllMastersSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.promotionSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetMasterSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.masterSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetClassroomSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.classroomSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  success_GetTutoringStateSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.tutoringStateSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */

  handleClickSwitchTable() {
    this.gridView = !this.gridView;
  }

  dateTreatment(date: string, key) {
    const formattedDate = iDate
      .javascriptConvert(new Date(date))
      .toStringDate('JAP');
    this.extraClass.filtersNgModels[key] = date;
    this.getFilter(formattedDate, key);
  }

  getFilter(event, key) {
    switch (this.optionSubmenuSelected) {
      // case 0:
      //   if (!event) {
      //     delete this.filterAllFormations[key];
      //     delete this.filterAllDobleTitulacion[key];
      //     this.getDobleTitulacion();
      //     this.getFormations();
      //     return;
      //   }
      //   this.filterAllFormations[key] = event;
      //   this.filterAllDobleTitulacion[key] = event;
      //   this.getFormations();
      //   this.getDobleTitulacion();
      //   break;

      case 0:
        if (!event || event?.length === 0) {
          delete this.filterAllMasters[key];
          this.getMasters();
          return;
        }
        this.filterAllMasters[key] = event;
        this.getMasters();
        break;

      case 1:
        if (!event || event?.length === 0) {
          delete this.filterAllCursos[key];
          this.getCursos();
          return;
        }
        this.filterAllCursos[key] = event;
        this.getCursos();
        break;

      case 2:
        if (!event || event?.length === 0) {
          delete this.filterAllIncompanies[key];
          this.getIncompanies();
          return;
        }
        this.filterAllIncompanies[key] = event;
        this.getIncompanies();
        break;

      case 3:
        if (!event || event?.length === 0) {
          delete this.filterAllDobleTitulacion[key];
          this.getDobleTitulacion();
          return;
        }
        this.filterAllDobleTitulacion[key] = event;
        this.getDobleTitulacion();
        break;

      case 4:
        this.filterTutorings[key] = event;
        if (!event || event?.length === 0) {
          delete this.filterTutorings[key];
        }
        this.refreshTutorings = true;
        setTimeout(() => {
          this.refreshTutorings = false;
        }, 150);
        break;

      default:
        break;
    }
  }
  onToggleSubmenuChange(optionSelected) {
    // Limpiamos los filtros al cambiar de subpestaña
    Object.keys(this.extraClass.filtersNgModels).forEach((key) => {
      this.extraClass.filtersNgModels[key] = null;
    });
    setTimeout(() => {
      this.optionSubmenuSelected = Number(optionSelected);
    }, 1);
  }
  pageChange(event) {
    switch (this.optionSubmenuSelected) {
      // case 0:
      //   this.filterAllFormations.num_pagina = event;
      //   this.getFormations();
      //   break;

      case 0:
        this.filterAllMasters.num_pagina = event;
        this.getMasters();
        break;

      case 1:
        this.filterAllCursos.num_pagina = event;
        this.getCursos();
        break;

      case 2:
        this.filterAllIncompanies.num_pagina = event;
        this.getIncompanies();
        break;

      case 3:
        this.filterAllDobleTitulacion.num_pagina = event;
        this.getDobleTitulacion();
        break;

      default:
        break;
    }
  }
  checkRolesAndPermissions() {
    return (
      this.rolesAndPermissions.roles.includes('ROLE_ADMIN') ||
      this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') ||
      this.rolesAndPermissions.permissions.includes(4)
    );
  }

  openNuevaTitulacionPopup() {
    this.popupSE.openPopup(AddDobleTitulacionPopupComponent);
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.getDobleTitulacion();
        this._unsubInd9.next('');
      });
  }

  goToNuevaTutoria() {
    this.staticUtilitiesSE.goTo(
      'formaciones/crear-tutoria',
      slideNavigationTypes.slideToTop
    );
  }

  refresh(event) {
    if (!event) return;
    this.getDobleTitulacion();
  }

  nextPage(option: string) {
    switch (option) {
      case 'all':
        this.pageChange(this.filterAllFormations.num_pagina + 1);
        break;
    }
  }

  getFilters($event: iFilterReturn) {}

  // Goto

  goTo(key) {
    switch (key) {
      // case 'formaciones':
      //   this.staticUtilitiesSE.goTo(
      //     '/formaciones/crear-formaciones',
      //     slideNavigationTypes.slideToTop
      //   );
      //   break;
      case 'master':
        this.createMaster();
        break;
      case 'curso':
        this.createCourse();
        break;

      case 'incompany':
        this.staticUtilitiesSE.goTo(
          '/formaciones/crear-formaciones',
          slideNavigationTypes.slideToTop,
          { type: 'incompany' }
        );
        break;
      default:
        break;
    }
  }

  /**
   * API CALLS
   */

  getFormations() {
    this.areReadyAllFormations = false;
    this.generalLoaderSE.addToLoaderAmount();
    this.formationSE.getAllTable(this.filterAllFormations);
    this.formationSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.allFormations = data;
        this.totalFormations = total;
        this.areReadyAllFormations = true;
        this.generalLoaderSE.removeFromLoaderAmount();
        this._unsubInd.next('');
      });
    this.formationSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getMasters() {
    this.generalLoaderSE.addToLoaderAmount();
    this.areReadyAllMasters = false;
    this.masterSE.getAll(this.filterAllMasters);
    this.masterSE
      .getResultGetAllMasters()
      .pipe(
        takeUntil(this._unsubInd2),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.allMasters = data
          ? data.map((master) => {
              return {
                ...master.formation,
                masterId: master.id,
              };
            })
          : [];
        this.totalMasters = total;
        this.areReadyAllMasters = true;
        this.generalLoaderSE.removeFromLoaderAmount();
        this._unsubInd2.next('');
      });
    this.masterSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getCursos() {
    this.generalLoaderSE.addToLoaderAmount();
    this.areReadyAllCursos = false;
    this.courseSE.getAll(this.filterAllCursos);
    this.courseSE
      .getResult()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.allCursos = data;
        this.totalCursos = total;
        this.areReadyAllCursos = true;
        this.generalLoaderSE.removeFromLoaderAmount();
        this._unsubInd5.next('');
      });
    this.courseSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getIncompanies() {
    this.generalLoaderSE.addToLoaderAmount();
    this.areReadyAllIncompanies = false;
    this.incompanySE.getAll(this.filterAllIncompanies);
    this.incompanySE
      .getResultGetIncompanyList()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.allIncompanies = data;
        this.totalIncompanies = total;
        this.areReadyAllIncompanies = true;
        this.generalLoaderSE.removeFromLoaderAmount();
        this._unsubInd3.next('');
      });
    this.incompanySE
      .getResultGetIncompanyListError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getDobleTitulacion() {
    this.generalLoaderSE.addToLoaderAmount();
    this.areReadyAllDobleTitulacion = false;
    this.masterSE.getAllDobleTitulacion(this.filterAllDobleTitulacion);
    this.masterSE
      .getResultGetAllDobleTitulacion()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.allDobleTitulacion = data;
        this.totalDobleTitulacion = total;
        this.areReadyAllDobleTitulacion = true;
        this.generalLoaderSE.removeFromLoaderAmount();
        this._unsubInd4.next('');
      });
    this.masterSE
      .getResultGetAllDobleTitulacionError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  createMaster() {
    this.generalLoaderSE.addToLoaderAmount();
    this.masterSE.create();
    this.masterSE
      .getResultUpdate()
      .pipe(
        takeUntil(this._unsubInd6),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.successCreateMasterHandler(res);
        this._unsubInd6.next('');
      });
    this.masterSE
      .getResultUpdateError()
      .pipe(
        takeUntil(this._unsub),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  createCourse() {
    this.generalLoaderSE.addToLoaderAmount();
    this.courseSE.create();
    this.courseSE
      .getResultUpdate()
      .pipe(
        takeUntil(this._unsubInd6),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.successCreateCourseHandler(res);
        this._unsubInd6.next('');
      });
    this.courseSE
      .getResultUpdateError()
      .pipe(
        takeUntil(this._unsub),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  // getAcademicStateSelector() {
  //   this.generalLoaderSE.addToLoaderAmount();
  //   const subject = StaticUtilitiesService.createSubject();
  //   const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
  //   this.academicSE.getSelector(behaviorSubject);
  //   behaviorSubject
  //     .pipe(
  //       takeUntil(subject),
  //       filter((res) => res)
  //     )
  //     .subscribe((res: iResultHttp) => {
  //       StaticUtilitiesService.apiResponseHandler(res, subject, [
  //         {
  //           method: () => this.successGetAcademicStateSelectorHandler(res),
  //         },
  //         {
  //           method: () => this.generalLoaderSE.removeFromLoaderAmount(),
  //           error: true,
  //         },
  //       ]);
  //     });
  // }

  getAttendanceModalitySelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.attendanceModalitySE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetAttendanceModalitySelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getPromotionsByAllMastersSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.getPromotionsByAllMastersSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successGetPromotionsByAllMastersSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getMasterSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.masterSE.getMastersForFilter();
    this.masterSE
      .getResult()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.successGetMasterSelectorHandler(res);
        subject.next('');
        subject.complete();
      });
    this.masterSE
      .getResultError()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        subject.next('');
        subject.complete();
      });
  }

  getClassroomSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.classroomSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetClassroomSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  _getTutoringStateSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.getTutoringStateSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.success_GetTutoringStateSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
