import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import {
  iResultHttp,
  iUnsubscribeDestroy,
  PopupService,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { PersonalizarColumnasPopupComponent } from 'src/app/Popups/PersonalizarColumnas-Popup/PersonalizarColumnas-Popup.component';
import { PreferencesService } from 'src/app/Services/Api/Preferences.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'gestionUsuariosAlumnosListadoTabla',
  templateUrl: './GestionUsuarios-Alumnos-Listado-Tabla.component.html',
  styleUrls: ['./GestionUsuarios-Alumnos-Listado-Tabla.component.scss'],
})
export class GestionUsuariosAlumnosListadoTablaComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input() alumnos: Array<any> = [];
  @Input() filters: any = {};
  @Input() total: number = 0;
  @Input() navigateActive: boolean = true;
  @Input() changePage: boolean = false;
  @Input() openPrerferencesAlumnoPopup: boolean = false;
  @Input() downloadExcelFile: boolean = false;
  @Output() selectedAlumnos = new EventEmitter<any>();
  @Output() changeAlumnoBooleanToFalse = new EventEmitter<any>();
  @Output() changeExcelBooleanToFalse = new EventEmitter<any>();

  // Pagination
  currentPage: number = 1;
  alumnosSelected: Array<any> = [];
  preferences: any = [];
  isDataLoaded: boolean = false;
  allSelected: boolean = false;
  filtros: Array<any> = [
    { position: 1, label: 'Nombre', realFilter: 'nombre', checked: true },
    { position: 2, realFilter: 'email', label: 'Email', checked: true },
    {
      position: 3,
      realFilter: 'telefono',
      label: 'Teléfono de contacto',
      checked: true,
    },
    { position: 4, realFilter: 'formacion', label: 'Formación', checked: true },
    { position: 5, realFilter: 'estado', label: 'Estado', checked: true },
    { position: 6, realFilter: 'alias', label: 'Alias', checked: true },
    {
      position: 7,
      realFilter: 'fechaDeNacimiento',
      label: 'Fecha de nacimiento',
      checked: true,
    },
    { position: 8, realFilter: 'dni', label: 'DNI', checked: true },
    { position: 9, realFilter: 'gender', label: 'Sexo', checked: true },
    {
      position: 10,
      realFilter: 'nationality',
      label: 'Nacionalidad',
      checked: true,
    },
    {
      position: 10,
      realFilter: 'registerDate',
      label: 'Fecha de registro',
      checked: true,
    },
  ];

  displayedColumns: any[] = [
    { displayName: 'Nombre', property: 'name' },
    { displayName: 'Correo electrónico', property: 'email' },
    { displayName: 'Teléfono de contacto', property: 'phone' },
    { displayName: 'Formación', property: 'enrollments' },
    { displayName: 'Estado', property: 'state' },
    { displayName: 'Alias', property: 'alias' },
    { displayName: 'Fecha de nacimiento', property: 'fechaDeNacimiento' },
    { displayName: 'DNI', property: 'dni' },
    { displayName: 'Sexo', property: 'gender' },
    { displayName: 'Nacionalidad', property: 'nationality' },
    { displayName: 'Fecha de registro', property: 'registerDate' },
  ];

  dataSource: Array<any> = [];
  disabledColumns: string[] = [];
  constructor(
    private popupSE: PopupService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private preferencesSE: PreferencesService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllSettings();
  }
  ngOnChanges(change: SimpleChange) {
    if (change['changePage'] != undefined) {
      this.alumnosSelected = [];
      this.allSelected = false;
      this.selectedAlumnos.emit(this.alumnosSelected);
    }
    if (change['alumnos'] != undefined) {
      this.isDataLoaded = true;
    }
    if (change['openPrerferencesAlumnoPopup']) {
      if (change['openPrerferencesAlumnoPopup'].currentValue) {
        this.personalizarColumnas();
        setTimeout(() => {
          this.changeAlumnoBooleanToFalse.emit(false);
        }, 10);
      }
    }
    if (change['downloadExcelFile']) {
      if (change['downloadExcelFile'].currentValue) {
        this.exportexcel();
        setTimeout(() => {
          this.changeExcelBooleanToFalse.emit(false);
        }, 10);
      }
    }
  }

  ngAfterViewInit() {}

  /**
   * FUNCTIONALITIES
   */
  addSelected(formacion) {
    !this.alumnosSelected.includes(formacion)
      ? this.alumnosSelected.push(formacion)
      : this.alumnosSelected.splice(this.alumnosSelected.indexOf(formacion), 1);
    this.alumnosSelected.length == this.alumnos.length
      ? (this.allSelected = true)
      : (this.allSelected = false);
    this.selectedAlumnos.emit(this.alumnosSelected);
  }
  selecteAll(event) {
    if (event.checked) {
      this.alumnosSelected = [...this.alumnos];
      this.allSelected = true;
    } else {
      this.alumnosSelected = [];
      this.allSelected = false;
    }
    this.selectedAlumnos.emit(this.alumnosSelected);
  }
  personalizarColumnas() {
    this.popupSE.openPopup(PersonalizarColumnasPopupComponent, {
      filtros:
        this.preferences.length > 0
          ? this.preferences.filter((e) => e.displayName != 'Nombre')
          : this.displayedColumns.filter((e) => e.displayName != 'Nombre'),
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        let columnsToShow: any = [];
        columnsToShow.push({ displayName: 'Nombre', property: 'name' });
        res.returnValue.forEach((element) => {
          if (element.checked) {
            columnsToShow.push({
              displayName: element.displayName,
              property: element.property,
            });
          }
        });
        this.displayedColumns = columnsToShow;
        let dataToAdd = {
          type: 'Student',
          options: res.returnValue.map((element: any, index) => {
            return {
              property: element.property,
              checked: element?.checked ?? false,
              displayName: element.displayName,
            };
          }),
        };
        dataToAdd.options.unshift({
          property: 'name',
          checked: true,
          displayName: 'Nombre',
        });
        this.setPreferences(dataToAdd);
        this._unsubInd.next('');
      });
  }
  checkUncheckedColumns(res) {
    res.map((element) => {
      if (!element.checked) {
        Array.from(
          document.querySelectorAll('.mat-column-' + element.realFilter)
        ).map((column) => {
          (column as HTMLElement).style.display = 'none';
        });
      } else {
        Array.from(
          document.querySelectorAll('.mat-column-' + element.realFilter)
        ).map((column) => {
          (column as HTMLElement).style.display = 'revert';
        });
      }
    });
  }

  goToDetailView(event) {
    this.navigateActive
      ? this.staticUtilitiesSE.goTo(
          '/alumnos/mis-alumnos/ficha-alumno',
          slideNavigationTypes.slideToTop,
          { alumno: event.id }
        )
      : null;
  }

  // Descargar excel

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (document.querySelector('table thead') as HTMLElement).cloneNode(true)
    );
    table.querySelectorAll('thead th mat-icon').forEach((element) => {
      element.remove();
    });
    table.querySelector('thead th:first-of-type')?.remove();
    let rows = Array.from(document.querySelectorAll('table tbody tr '));
    let filteredRows = rows.filter((row) => {
      if (row.className === 'loaderLine') return null;
      return (
        row
          .querySelector('mat-checkbox')!
          .getAttribute('ng-reflect-checked') === 'true'
      );
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (document.querySelector('table tbody') as HTMLElement).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Alumnos.xlsx');
  }

  /**
   * API CALLS
   */

  setPreferences(preferences) {
    this.preferencesSE.setStudentSettings(preferences);
    this.preferencesSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showFeedback(
          'Se han modificado las preferencias correctamente'
        );
        this.getAllSettings();
        this._unsubInd3.next('');
      });
    this.preferencesSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showFeedback(
          'Se han modificado las preferencias correctamente'
        );
        this._unsubInd3.next('');
      });
  }

  getAllSettings() {
    this.preferencesSE.getAllSettings({ type: 'Student' });
    this.preferencesSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) return;
        let { data } = res;

        this.preferences = data.options;
        this.displayedColumns = data.options.filter((e) => e.checked);
        this._unsubInd.next('');
      });
    this.preferencesSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
