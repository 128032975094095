<div class="fullContainer" (mouseup)="setIsSelectingAs(false)">

  <div class="header">
    <div class="typeSpan" [class]="event.className">
      <p class="cursor-auto" (mousedown)="setIsSelectingAs(true)">{{getEventType()}}</p>
    </div>
    <div class="iconContainer">
      <ng-container *ngIf="extraClass.isEditing">

        <ng-container *ngIf="!extraClass.canEdit">
          <img [src]="getIcon('outlinedPencil')" alt="infoIcon" class="cursor-pointer" (click)="toggleEdit()"
            *ngIf="!extraClass.redirectTo">

          <img [src]="getIcon('penOutside')" alt="infoIcon" class="cursor-pointer"
            (click)="redirectTo(extraClass.redirectTo)" *ngIf="extraClass.redirectTo">

        </ng-container>

        <ng-container *ngIf="extraClass.canEdit">
          <mat-icon class="cursor-pointer" (click)="toggleEdit()">check_circle</mat-icon>
          <img [src]="getIcon('delete')" alt="infoIcon" class="cursor-pointer" (click)="onDeleteLandmark()" >
        </ng-container>
        
      </ng-container>
      <mat-icon class="cursor-pointer" (click)="closeEventWindow()">close</mat-icon>
    </div>
  </div>

  <div class="eventInfoContainer">
    <!-- Title -->
    <div class="eventLine">
      <span class="spanDot" [class]="event.className"></span>
      <p class="title fit-content" (mousedown)="setIsSelectingAs(true)" [contentEditable]="extraClass.canEdit"
        (blur)="changeValue($event,'title')">{{event.title}}</p>
    </div>
    <!-- Subtitle -->
    <div class="eventLine">
      <span class="empty"></span>
      <ng-container *ngIf="hasSubtitle(event)">
        <p class="sub-title fit-content" [contentEditable]="extraClass.canEdit" (blur)="changeValue($event,'subtitle')"
          (mousedown)="setIsSelectingAs(true)">{{event.subtitle}}</p>
      </ng-container>
    </div>
    <!-- Date and time -->
    <div class="eventLine">
      <span class="empty"></span>
      <div class="date-and-time fit-content">
        <!-- StartDate -->

        <CustomDatepicker [inputDate]="event.startDate" *ngIf="event?.startDate && extraClass.canEdit"
          (dateChange)="onDateChange($event,'startDate')">
        </CustomDatepicker>
        <ng-container *ngIf="!extraClass.canEdit">
          <p class="date-and-time-text cursor-auto" (mousedown)="setIsSelectingAs(true)">{{event.startDate | date:
            'fullDate'}}</p>
        </ng-container>
        <!-- StartTime -->
        <ng-container
          *ngIf="hasStartHour(event) && hasEndHour(event) && !hasAllDay(event) || ( hasAllDay(event) && !event.allDay)">
          <input type="time" *ngIf="hasStartHour(event) && extraClass.canEdit" [(ngModel)]="event.startHour"
            (change)="getTime(event.startHour, 'startHour')">
          <ng-container *ngIf="!extraClass.canEdit && hasStartHour(event)">
            <p class="date-and-time-text cursor-auto" (mousedown)="setIsSelectingAs(true)">{{event.startHour }}</p>
          </ng-container>

          <ng-container *ngIf="hasStartHour(event) && hasEndHour(event)">
            <span class="cursor-auto"> - </span>
          </ng-container>
          <!-- EndTime -->
          <input type="time" *ngIf="hasEndHour(event) && extraClass.canEdit " [(ngModel)]="event.endHour"
            (change)="getTime(event.endHour, 'endHour')">
          <ng-container *ngIf="!extraClass.canEdit && hasEndHour(event)">
            <p class="date-and-time-text cursor-auto" (mousedown)="setIsSelectingAs(true)">{{event.endHour }}</p>
          </ng-container>
        </ng-container>
        <!-- EndDate -->
        <ng-container *ngIf="hasEndDate(event) ">
          <CustomDatepicker [inputDate]="event.endDate" *ngIf="extraClass.canEdit" [inputDate]="event.endDate"
            (dateChange)="onDateChange($event,'endDate')"></CustomDatepicker>
          <ng-container *ngIf="!extraClass.canEdit && hasEndDate(event) && event.endDate">
            <p class="date-and-time-text cursor-auto" (mousedown)="setIsSelectingAs(true)">{{event.endDate | date:
              'fullDate'}}</p>
          </ng-container>
        </ng-container>

      </div>
    </div>
    <!-- All day -->
    <ng-container *ngIf="hasAllDay(event)">
      <div class="eventLine">
        <span class="empty"></span>
        <mat-checkbox [labelPosition]="'after'" (change)="getChecked($event, 'allDay')" [checked]="event.allDay"
          [disabled]="!extraClass.canEdit">Todo el
          día</mat-checkbox>
      </div>
    </ng-container>

    <!-- Formation -->

    <ng-container *ngIf="hasFormation(event)">
      <div class="eventLine">
        <img class="min-width-1-rem" [src]="getIcon('formation')" alt="infoIcon">
        <selector (mousedown)="setIsSelectingAs(true)" [(ngModel)]="event.formation"
          [options]="extraClass.formationSelectorOptions" (change)="onFormationChange(event.formation)"></selector>
      </div>
    </ng-container>

    <!-- Edition / Group -->

    <ng-container *ngIf="hasEdition(event)">
      <div class="eventLine">
        <span class="empty"></span>
        <selector (mousedown)="setIsSelectingAs(true)" [(ngModel)]="event.edition"
          [options]="extraClass.editionSelectorOptions" (change)="ifIsEditingEdit(event.edition,'edition')"></selector>
      </div>
    </ng-container>

    <!-- Teacher -->
    <ng-container *ngIf="
      (hasTeachers(event) && event.type === 4 && event.teachers.length > 0) 
      || 
      (hasTeachers(event) && event.type !== 4) ">
      <div class="eventLine">
        <img class="min-width-1-rem" [src]="getIcon('person')" alt="infoIcon">
        <selector (mousedown)="setIsSelectingAs(true)" [(ngModel)]="event.teachers"
          [options]="extraClass.teacherSelectorOptions" (change)="ifIsEditingEdit(event.teachers,'teachers')">
        </selector>
      </div>
    </ng-container>

    <!-- Student -->
    <ng-container *ngIf="hasStudent(event)">
      <div class="eventLine">
        <img class="min-width-1-rem" [src]="getIcon('student')" alt="infoIcon">
        <selector (mousedown)="setIsSelectingAs(true)" [(ngModel)]="event.student"
          [options]="extraClass.studentSelectorOptions" (change)="ifIsEditingEdit(event.student,'student')"></selector>
      </div>
    </ng-container>

    <!-- Company -->
    <ng-container *ngIf="hasCompany(event)">
      <div class="eventLine">
        <img class="min-width-1-rem" [src]="getIcon('work')" alt="infoIcon">
        <selector (mousedown)="setIsSelectingAs(true)" [(ngModel)]="event.company"
          [options]="extraClass.companySelectorOptions" (change)="ifIsEditingEdit(event.company,'company')"></selector>
      </div>
    </ng-container>

    <!-- Modality -->
    <ng-container *ngIf="hasModality(event)">
      <div class="eventLine">
        <img class="min-width-1-rem" [src]="getIcon('modality')" alt="infoIcon">
        <selector (mousedown)="setIsSelectingAs(true)" [(ngModel)]="event.modality"
          [options]="extraClass.modalitySelectorOptions" (change)="ifIsEditingEdit(event.modality,'modality')">
        </selector>
      </div>
    </ng-container>


    <!-- Classroom -->
    <ng-container *ngIf="hasClassroom(event)">
      <div class="eventLine">
        <img class="min-width-1-rem" [src]="getIcon('school')" alt="infoIcon">
        <selector (mousedown)="setIsSelectingAs(true)" [(ngModel)]="event.classroom"
          [options]="extraClass.placeSelectorOptions" (change)="ifIsEditingEdit(event.classroom,'classroom')">
        </selector>
      </div>
    </ng-container>
    <!-- Sede -->
    <ng-container *ngIf="hasSede(event)">
      <div class="eventLine">
        <img class="min-width-1-rem" [src]="getIcon('location')" alt="infoIcon">
        <p class="sub-title fit-content" (mousedown)="setIsSelectingAs(true)">{{ 'Sede de impartición:'}}</p>
      </div>
      <div class="eventLine">
        <span class="empty"></span>
        <selector (mousedown)="setIsSelectingAs(true)" [(ngModel)]="event.sede"
          [options]="extraClass.sedeSelectorOptions" (change)="ifIsEditingEdit(event.sede,'sede')">
        </selector>
      </div>

      <div class="eventLine">
        <span class="empty"></span>
        <customTextArea class="disabledTextArea" [options]="extraClass.sedeCustomTextAreaOptions"></customTextArea>
      </div>
    </ng-container>
    <!-- Location -->
    <ng-container *ngIf="hasLocation(event)">
      <div class="eventLine">
        <img class="min-width-1-rem" [src]="getIcon('location')" alt="infoIcon">
        <p class="sub-title fit-content" (mousedown)="setIsSelectingAs(true)">{{ 'Ubicación:'}}</p>
      </div>
      <div class="eventLine">
        <span class="empty"></span>
        <customTextArea [ngClass]="{'disabledTextArea':  !extraClass.canEdit}" [(ngModel)]="event.location"
          [options]="extraClass.locationCustomTextAreaOptions" (change)="ifIsEditingEdit(event.location,'location')">
        </customTextArea>
      </div>

    </ng-container>

    <!-- Session URL -->
    <ng-container *ngIf="hasUrl(event) && event.url">
      <div class="eventLine">
        <img class="min-width-1-rem" [src]="getIcon('videoCam')" alt="infoIcon">
        <btn (mousedown)="setIsSelectingAs(true)" [url]="event.url" joinSessionButton></btn>
      </div>
    </ng-container>

    <!-- State -->
    <ng-container *ngIf="hasStatus(event)">
      <div class="eventLine">
        <img class="min-width-1-rem" [src]="getIcon('info')" alt="infoIcon">
        <chip-selector [chipSelectorOptions]="extraClass.chipSelectorOptions"
          [(ngModel)]="event.status"></chip-selector>
      </div>
    </ng-container>

    <!-- Observaciones -->

    <ng-container *ngIf="hasObservations(event)">
      <div>

        <div class="eventLine">
          <img class="min-width-1-rem" [src]="getIcon('dialog')" alt="infoIcon">
          <p class="sub-title fit-content" (mousedown)="setIsSelectingAs(true)">Observaciones</p>
        </div>

        <div class="eventLine">
          <span class="empty"></span>
          <customTextArea (change)="getDataToSendToEdit(event.observations,'observations')"
            [options]="extraClass.ovservationsCustomTextAreaOptions" [(ngModel)]="event.observations"
            (change)="ifIsEditingEdit(event.observations,'observations')"></customTextArea>
        </div>
      </div>
    </ng-container>

  </div>

  <div class="footer" *ngIf="!event.id ">
    <btn classList="generalbutton principalButton fitWidth" (click)="createLandmark()">Guardar</btn>
  </div>
</div>