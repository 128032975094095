import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StaticUtilitiesService, iOptionsSelector, iResultHttp, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { FormationService } from 'src/app/Services/Api/Formation.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { iPromotionFormationFilter } from 'src/app/Shared/Interfaces/iPromotionFormation';
import { filter, takeUntil } from 'rxjs';
import { GeneralLoaderService } from '@quasar-dynamics/basic-designsystem';
import { AttendanceService } from 'src/app/Services/Api/Attendance.service';
import { StaticUtilitiesService as NewStaticUtilitiesService } from '@quasar-dynamics/basic-designsystem';


@Component({
  selector: 'VariosAlumnosSecondStep',
  templateUrl: './VariosAlumnosSecondStep.component.html',
  styleUrls: ['./VariosAlumnosSecondStep.component.scss']
})
export class VariosAlumnosSecondStepComponent extends iUnsubscribeDestroy implements OnInit {
  @Output() dataVariosAlumnosSecondStep = new EventEmitter();

  attendanceModality:any = null;
  selectorFormation:any = null;
  doubleEnrollment:boolean = false;
  selectorOptionsformation:iOptionsSelector = {
    items:[],
    bindLabel:'name',
    append:true,
    placeholder:'Seleccione una formación',
    multiple:{
      isMultiple:true,
      checkbox:true,
      maxSelectedItems:2,
    }
  }
  arrayFormatos:any = [null,null];
  fundaeRadioButtons:any = null;
  arrayCounter:number = 0;
  arraySelectorPromotion:any = [];
  selectorOptionsPromotionArray:iOptionsSelector[] | any = []
  formationFilter: iPromotionFormationFilter = {
    num_devoluciones:5000000000000000,
    num_pagina:1,
  }
  promotionFilter: iPromotionFormationFilter = {
    num_devoluciones:5000000000000000,
    num_pagina:1,
  }

  opcionesSelectorModalidad:iOptionsSelector = {
    items:[],
    bindLabel:'name',
    append:true,
    placeholder:'Seleccione una modalidad',
    clearable:false,
  }
  constructor(
    private promotionSE:PromotionFormationService, 
    private formationSE:FormationService,
    private generalLoaderSE: GeneralLoaderService,
    private attendanceSE: AttendanceService

  ) {
    super();
  }

  ngOnInit() {
    this.getAllFormations();
  }

  /**
 * HANDLERS
 */


successGetSelectorByPromotionIdHandler(res: iResultHttp) {
  const { data } = res;
  this.opcionesSelectorModalidad.items = data;
  this.generalLoaderSE.removeFromLoaderAmount();
}


  /**
   * FUNCTIONALITY
   */

 async detectHowManyFormationsSelected(event){
     
    if(event.length === 0){ this.cleanSelectors();return};
    if(event.length === 1){
      if(this.arrayCounter === 2){return;}
      this.pushSelector();
      this.getPromotionsByFormationId(event[0].id, 0);
      this.arrayCounter = 1;
    }
    if(event.length === 2){
        this.pushSelector();
      this.getPromotionsByFormationId(event[1].id, 1);
      this.arrayCounter = 2;
    }
  }

  pushSelector(){
    this.selectorOptionsPromotionArray?.push({
      items:[],
      append:true,
      placeholder:'Seleccione una edición',
    })
    this.arraySelectorPromotion.push({selector:null});
  }
  cleanSelectors(){
    this.arraySelectorPromotion = [];
    this.selectorFormation = null; 
    this.selectorOptionsPromotionArray.length = 0 ;
    this.arrayCounter = 0
    this.arrayFormatos = [null,null];

  }

  removeObjectSelector(event){
    this.arraySelectorPromotion.splice(event.index, 1); 
    this.doubleEnrollment = false;
  }
    /**
   * API CALLS
   */

    getAllFormations(){
      this.formationSE.getAll(this.formationFilter);
      this.formationSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
        if(!res)return;
        let {data} = res.data;
        this.selectorOptionsformation.items = data;
        this._unsubInd.next('')
      });
      this.formationSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
        if(!res){return}
        if(res.status != 401){
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('')
      })
    }

    getPromotionsByFormationId(id, index){
      this.promotionSE.getPromotionsByFormationId(id);
      this.promotionSE.getResultPromotionsByFormationId().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
        if(!res)return;
        let {data} = res;
         
        this.selectorOptionsPromotionArray![index].items = data.map((item:any) => {return{...item, label: `${item.edition} - ${item.schedule}` }});
        this._unsubInd3.next('')
      });
      this.promotionSE.getResultPromotionsByFormationIdError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
        if(!res){return}
        if(res.status != 401){
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('')
      })
    }

    getSelectorByPromotionId(promotionId: number) {
      this.generalLoaderSE.addToLoaderAmount();
      const subject = NewStaticUtilitiesService.createSubject();
      const behaviorSubject = NewStaticUtilitiesService.createBehaviorSubject();
      this.attendanceSE.getSelectorByPromotionId(behaviorSubject, promotionId);
      behaviorSubject
        .pipe(
          takeUntil(subject),
          filter((res) => res)
        )
        .subscribe((res: iResultHttp) => {
          NewStaticUtilitiesService.apiResponseHandler(res, subject, [
            {
              method: () => this.successGetSelectorByPromotionIdHandler(res),
            },
            {
              method: () => this.generalLoaderSE.removeFromLoaderAmount(),
              error: true,
            },
          ]);
        });
    }
  

    emitData() {
      this.dataVariosAlumnosSecondStep.emit({
        promotion:this.arraySelectorPromotion.map((item:any) => item['selector']?.id ?? null),
        fundae:this.fundaeRadioButtons,
        isDouble:this.doubleEnrollment, 
        modality:this.attendanceModality
       });
    }  
}
