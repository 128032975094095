<div class="detailView-Students">
  <div class="detailView-Students__btns">
    <div class="buttonsInLine">
      <btn
        classList="generalbutton principalButton detailView-Students__btn purple"
        [class.disabled]="
          extraClass.isEditing || extraClass.selectedRows.length > 0
        "
        (click)="downloadFUNDAE()"
      >
        <img src="/assets/Icons/General/download.png" alt="sendDates" />
        <span>Descargar FUNDAE</span>
      </btn>
    </div>

    <div class="buttonsInLine">
      <btn
        classList="generalbutton principalButton detailView-Students__btn"
        [class.disabled]="
          extraClass.isEditing || extraClass.selectedRows.length === 0
        "
        (click)="generateBodyForDownloadDiplomas()"
      >
        Descargar diplomas
        <img src="/assets/Icons/General/download.png" alt="sendDates" />
      </btn>

      <btn
        classList="generalbutton principalButton detailView-Students__btn"
        [class.disabled]="
          extraClass.isEditing || extraClass.selectedRows.length === 0
        "
        (click)="generateBodyForSendDiplomas()"
      >
        <img src="/assets/Icons/General/sendDatesIcon.svg" alt="sendDates" />
        <span>Enviar diplomas</span>
      </btn>

      <ng-container *ngIf="extraClass.isEditing">
        <btn
          classList="generalbutton principalButton detailView-Students__btn verySmall"
          (click)="toggleIsEditing()"
        >
          <span>Terminar</span>
        </btn>
      </ng-container>

      <ng-container *ngIf="!extraClass.isEditing">
        <btn
          classList="generalbutton principalButton detailView-Students__btn purple verySmall"
          (click)="toggleIsEditing()"
        >
          <span>Editar</span>
        </btn>
      </ng-container>
    </div>
  </div>
  <main-table
    *ngIf="extraClass.displayTable"
    [headerData]="extraClass.tableHead"
    [dataSource]="promotionStudent"
    [selectMultiple]="true"
    (changeSelectedRows)="onChangeSelectedRows($event)"
    (clickOnRow)="onRowClick($event)"
  >
  </main-table>
</div>
