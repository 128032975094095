import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import { ModuleService } from 'src/app/Services/Api/Module.service';
import { filter, takeUntil } from 'rxjs';
import {
  iResultHttp,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';

@Component({
  selector: 'DetailView-Content-ModulesContainer',
  templateUrl: './DetailView-Content-ModulesContainer.component.html',
  styleUrls: ['./DetailView-Content-ModulesContainer.component.scss'],
})
export class DetailViewContentModulesContainerComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input() modules: any[] = [];
  @Input() isEditing: boolean = false;
  @Input() promotion: number = 0;

  @Output() onCreate = new EventEmitter();

  constructor(
    private moduleSE: ModuleService,
    private promotionFormationSE: PromotionFormationService,
    private generalLoaderSE: GeneralLoaderService
  ) {
    super();
  }

  ngOnInit() {}

  /**
   * HANDLERS
   */

  successCreateModuleHandler(res: iResultHttp) {
    let { data } = res;
    this.onCreate.emit(data);
  }

  successUpdateModuleHandler(res: iResultHttp) {}

  successReorderModulesHandler(res) {}

  successDeleteModulesHandler(res) {
    let { data } = res;
    this.onCreate.emit(data);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  handleModuleNameChange(newValue: string, module: any) {
    // const newModules = [...this.modules];
    // newModules[index].name = newValue;
    if (newValue === module.moduleTitle) return;
    this.updateModule({ id: module.moduleId, title: newValue });
    module.moduleTitle = newValue;
  }

  handleDrop(event: CdkDragDrop<any, any, any>) {
    const { previousIndex, currentIndex } = event;
    if (previousIndex === currentIndex) return;

    const newModules = [...this.modules];
    moveItemInArray(newModules, previousIndex, currentIndex);
    const sequencedArray = newModules.map((module, index) => {
      return { ...module, sequence: index };
    });
    const objectToPass = {
      id: this.promotion,
      modules: sequencedArray,
    };
    this.onCreate.emit(sequencedArray);
    this.reorderModules(objectToPass);
  }

  handleClickDeleteModule(id: number) {
    this.deleteModules([id]);
  }

  handleCreateSession(event) {
    this.onCreate.emit(event);
  }

  moduleTracking(index: number) {
    return index;
  }

  addModule() {
    this.createModule({ promotion: this.promotion });
  }

  changesInSessions(sessions: any, index: number) {
    // const newModules = [...this.modules];
    // newModules[index].sessions = sessions;
    // this.moduleChange.emit(newModules);
  }

  /**
   * API CALLS
   */

  createModule(objectToPass) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.moduleSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successCreateModuleHandler(res),
        });
      });
  }

  updateModule(objectToPass) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.moduleSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successUpdateModuleHandler(res),
        });
      });
  }

  reorderModules(objectToPass) {
    this.promotionFormationSE.update(objectToPass);
    this.promotionFormationSE
      .getResultUpdate()
      .pipe(
        takeUntil(this._unsubInd),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.successReorderModulesHandler(res);
      });
  }

  deleteModules(ids: number[]) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.moduleSE.delete(behaviorSubject, ids);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteModulesHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
