import { iOptionsSelector } from '@quasar-dynamics/basic-designsystem';
import { headerData } from '../Components/main-table/main-table.component';
import { MultipleSelectorColumnComponent } from '../Components/multiple-selector-column/multiple-selector-column.component';
import { SessionProgramacionTableComponent } from '../Components/session-programacion-table/session-programacion-table.component';
import {
  BlueDataPickerComponent,
  iBlueDataPicker,
} from '../Components/blue-data-picker/blue-data-picker.component';
import { BlueSelectorComponent } from '../Components/blue-selector/blue-selector.component';
import { ScheduleTimeSelectorComponent } from '../Components/schedule-time-selector/schedule-time-selector.component';
import { iOptionsScheduleTimeSelector } from '../Interfaces/iOptionsScheduleTimeSelector';
import { RatingTextEditableComponent } from '../Components/rating-text-editable/rating-text-editable.component';
import { iOptionsRating } from '../Interfaces/iOptionsRating';
import { ChipSelectorComponent } from '../Components/chip-selector/chip-selector.component';
import { iChipSelectorOptions } from '../Interfaces/iChipSelectorOptions';
import { StaticSelectorHandlersService } from 'src/app/Services/Utils/StaticSelectorHandlers.service';
import {
  IPromotionDates,
  iPromotionDates,
} from '../Interfaces/Utils/iPromotionDates';
import { Subject, Subscription } from 'rxjs';

export class DetailViewDatesClass {
  // displayTable
  displayTable: boolean = false;

  // Subject
  mustSubscribeSubject: Subject<any> = new Subject();

  // SubjectArray
  SubjectArray: Subject<any>[] = [];

  // Editable Box
  editableController: any[] = [];

  // Promotion Dates
  _promotionDates: iPromotionDates = IPromotionDates.getEmptyObject();

  // Promotion Info
  promotionDatesProgram: iPromotionDates['program'] = [];
  promotionDatesTutorings: iPromotionDates['tutorings'] = [];
  promotionDatesTribunals: iPromotionDates['tribunals'] = [];

  // Editable Box
  isEditingProgramacion: { boolean: boolean } = {
    boolean: false,
  };

  // Selected Rows
  selectedRows: any[] = [];

  // Options
  optionsClassroom: iOptionsSelector = {
    items: [
      { id: 1, name: 'Aula A' },
      { id: 2, name: 'Aula B' },
      { id: 3, name: 'Aula C' },
      { id: 4, name: 'Aula D' },
      { id: 5, name: 'Aula E' },
      { id: 6, name: 'Aula F' },
      { id: 7, name: 'Aula G' },
      { id: 8, name: 'Aula H' },
      { id: 9, name: 'Aula I' },
      { id: 10, name: 'Aula J' },
    ],
    bindLabel: 'name',
    bindValue: 'id',
    search: true,
    append: true,
    readonly: true,
    classList: 'slim',
  };

  optionsDisposition: iOptionsSelector = {
    items: [
      { id: 1, name: 'Disposición A' },
      { id: 2, name: 'Disposición B' },
      { id: 3, name: 'Disposición C' },
      { id: 4, name: 'Disposición D' },
      { id: 5, name: 'Disposición E' },
      { id: 6, name: 'Disposición F' },
      { id: 7, name: 'Disposición G' },
      { id: 8, name: 'Disposición H' },
      { id: 9, name: 'Disposición I' },
      { id: 10, name: 'Disposición J' },
    ],
    bindLabel: 'name',
    bindValue: 'id',
    search: true,
    append: true,
    readonly: true,
    classList: 'slim',
  };

  options: iOptionsSelector = {
    items: [],
    bindLabel: 'name',
    bindValue: 'id',
    search: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      maxSelectedItems: 100,
      hideSelected: false,
      multipleLimitReplacer: 100,
    },
    append: true,
    readonly: true,
  };

  optionsBlueDataPicker: iBlueDataPicker = {
    placeholder: 'Selecciona una fecha',
    type: 'date',
    readonly: true,
  };

  optionsScheduleTimeSelector: iOptionsScheduleTimeSelector = {
    readonly: true,
    input1Options: {
      type: 'time',
      placeholder: 'Desde',
      readOnly: false,
    },
    input2Options: {
      type: 'time',
      placeholder: 'Hasta',
      readOnly: false,
    },
  };

  ratingOptionsArray: iOptionsRating[] = [];

  optionsRating: iOptionsRating = {
    rating: '',
    readonly: false,
    maxRating: 5,
  };

  chipSelectorOptions: iChipSelectorOptions = {
    items: [],
    bold: true,
    size: 'md',
    readonly: true,
    bindValue: 'id',
  };

  // Header Data
  headerData: headerData = [
    // Módulo
    {
      variant: 'chip',
      classNameGenerator: () => '--medium-grey',
      key: 'module',
      display: 'Módulo',
      className: 'moduleChip',
      width: '250px',
    },
    // Sesión
    {
      variant: 'component',
      component: SessionProgramacionTableComponent,
      display: 'Sesión',
      cellClassName: 'justify-center',
      width: '350px',
      key: 'session',
      inputs: [
        {
          callback: (index: number, key: string) => {
            return {
              title: `Sesión ${
                Number(this.promotionDatesProgram[index].sessionNumber ?? 0) + 1
              }`,
              text: this.promotionDatesProgram[index].session,
            };
          },
          inputKey: 'data',
          key: 'session',
        },
      ],
    },
    // Fecha
    {
      variant: 'component',
      component: BlueDataPickerComponent,
      cellClassName: 'justify-center',
      display: 'Fecha',
      width: '150px',
      key: 'date',
      inputs: [
        {
          callback: (index: number, key: string) =>
            this.returnSimplecallbackProgram(index, key),
          inputKey: 'date',
          key: 'date',
        },
        {
          callback: (index: number, key: string) => this.optionsBlueDataPicker,
          inputKey: 'optionsCustomInput',
          key: 'date',
        },
      ],
    },
    // Horario
    {
      variant: 'component',
      component: ScheduleTimeSelectorComponent,
      display: 'Horario',
      key: 'schedule',
      cellClassName: 'justify-center',
      width: '280px',
      inputs: [
        {
          callback: (index: number, key: string) =>
            this.returnSimplecallbackProgram(index, key),
          inputKey: 'ngModel1',
          key: 'startHour',
        },
        {
          callback: (index: number, key: string) =>
            this.returnSimplecallbackProgram(index, key),
          inputKey: 'ngModel2',
          key: 'endHour',
        },
        {
          callback: (index: number, key: string) =>
            this.optionsScheduleTimeSelector,
          inputKey: 'options',
          key: '',
        },
      ],
    },
    // Profesores
    {
      variant: 'component',
      component: MultipleSelectorColumnComponent,
      key: 'teachers',
      display: 'Profesores',
      cellClassName: 'justify-center',
      width: '250px',
      inputs: [
        {
          callback: (index: number, key: string) => this.options,
          key: 'options',
          inputKey: 'options',
        },
        {
          callback: (index: number, key: string) =>
            this.returnSimplecallbackProgram(index, key),
          inputKey: 'model',
          key: 'teachers',
        },
      ],
    },
    // Aula
    {
      variant: 'component',
      component: BlueSelectorComponent,
      display: 'Aula',
      key: 'classroomId',
      cellClassName: 'justify-center',
      width: '200px',
      inputs: [
        {
          callback: (index: number, key: string) => this.optionsClassroom,
          key: 'optionsSelector',
          inputKey: 'optionsSelector',
        },
        {
          callback: (index: number, key: string) =>
            this.returnSimplecallbackProgram(index, key),
          inputKey: 'model',
          key: 'classroomId',
        },
      ],
    },
    // Disposición
    {
      variant: 'component',
      component: BlueSelectorComponent,
      display: 'Disposición',
      key: 'classroomDispositionId',
      cellClassName: 'justify-center',
      width: '200px',
      inputs: [
        {
          callback: (index: number, key: string) => this.optionsDisposition,
          key: 'optionsSelector',
          inputKey: 'optionsSelector',
        },
        {
          callback: (index: number, key: string) =>
            this.returnSimplecallbackProgram(index, key),
          inputKey: 'model',
          key: 'classroomDispositionId',
        },
      ],
    },
    // Estado
    {
      variant: 'component',
      component: ChipSelectorComponent,
      display: 'Estado',
      key: 'stateId',
      width: '250px',
      cellClassName: 'justify-center',
      inputs: [
        {
          callback: (index: number, key: string) => this.chipSelectorOptions,
          inputKey: 'chipSelectorOptions',
          key: 'chipSelectorOptions',
        },
        {
          callback: (index: number, key: string) =>
            this.returnSimplecallbackProgram(index, key),
          inputKey: 'model',
          key: 'stateId',
        },
      ],
    },
  ];

  // Header Data tutorias
  headerDataTutorias: headerData = [
    // Profesor
    {
      variant: 'chip',
      display: 'Profesor',
      key: 'teacherName',
      width: '250px',
      className: 'moduleChipLightBlue',
      classNameGenerator: () => '--light-blue',
    },
    // Date
    {
      variant: 'chip',
      display: 'Fecha',
      key: 'startDate',
      width: '150px',
      className: 'moduleChipLightBlue',
      classNameGenerator: () => '--light-blue',
    },
    // Schedule
    {
      variant: 'chip',
      display: 'Horario',
      key: 'hours',
      width: '150px',
      className: 'moduleChipLightBlue',
      classNameGenerator: () => '--light-blue',
    },
    // Temática
    {
      variant: 'chip',
      display: 'Temática',
      key: 'topicNames',
      width: '550px',
      className: 'moduleChipLightGrey maxWidth500',
      classNameGenerator: () => '--',
    },
    // Modalidad
    {
      variant: 'chip',
      display: 'Modalidad',
      key: 'tutoringAttendanceName',
      width: '150px',
      className: 'moduleChipLightBlue',
      classNameGenerator: () => '--light-blue',
    },
    // Aula
    {
      variant: 'chip',
      display: 'Aula',
      key: 'classroomName',
      width: '200px',
      className: 'moduleChipLightBlue',
      classNameGenerator: (key, index) => `--light-blue`,
    },
    // Estado
    {
      variant: 'chip',
      display: 'Estado',
      key: 'tutoringStateName',
      width: '150px',
      classNameGenerator: (key, index, row) => row.tutoringStateColor,
    },
  ];

  // Table content tutorias
  tableContentTutorias: any[] = [
    {
      teacherName: 'Roberto Carlos da Silva',
      date: new Date('2024-10-10'),
      schedule: '08:00 - 10:00',
      theme:
        'Direccion de Marketing + Plan de marketing + Presupuesto + Copywriting',
      modality: 'Presencial',
      classroomName: 'Aula A',
      status: 'Pendiente',
    },
  ];

  headerDataTribunal: headerData = [
    // Tribunal
    {
      variant: 'chip',
      display: 'Tribunal',
      key: 'title',
      width: '250px',
      className: 'moduleChipLightBlue max-width200',
      classNameGenerator(any, index) {
        return '--light-blue';
      },
    },
    // Date
    {
      variant: 'chip',
      display: 'Fecha',
      key: 'startDate',
      date: {
        date: true,
        pipeTemplate: 'dd/MM/yyyy',
      },

      width: '100px',
      className: 'moduleChipLightBlue',
      classNameGenerator: () => '--light-blue',
    },
    // Schedule
    {
      variant: 'chip',
      display: 'Horario',
      key: 'hours',
      width: '100px',
      className: 'moduleChipLightBlue',
      classNameGenerator: () => '--light-blue',
    },
    // Slots
    {
      variant: 'chip',
      display: 'Slots',
      key: 'slot',
      width: '150px',
      className: 'moduleChipLightBlue',
      classNameGenerator: () => '--light-blue',
    },
    // Aula
    {
      variant: 'chip',
      display: 'Aula',
      key: 'classroom',
      width: '200px',
      className: 'moduleChipLightBlue max-width200',
      classNameGenerator: (key, index) => `--light-blue`,
    },
  ];

  tableContentTribunal: any[] = [
    {
      tribunalName: 'Título y descripción',
      date: new Date('2024-10-10'),
      schedule: '08:00 - 10:00',
      classroomName: 'Aula A',
      slots: '30 min',
    },
  ];

  // functions
  setAsEditing() {
    if (this.editableController['Programación']) {
      this.options.readonly = false;
      this.optionsBlueDataPicker.readonly = false;
      this.optionsClassroom.readonly = false;
      this.optionsScheduleTimeSelector.readonly = false;
      this.ratingOptionsArray.forEach((ratingOptions) => {
        ratingOptions.readonly = false;
      });
      this.chipSelectorOptions.readonly = false;
      this.optionsDisposition.readonly = false;
    } else {
      this.options.readonly = true;
      this.optionsBlueDataPicker.readonly = true;
      this.optionsClassroom.readonly = true;
      this.optionsScheduleTimeSelector.readonly = true;
      this.ratingOptionsArray.forEach((ratingOptions) => {
        ratingOptions.readonly = true;
      });
      this.optionsDisposition.readonly = true;
      this.chipSelectorOptions.readonly = true;
    }
  }

  prepareRatingOptions(index, key) {
    const dataToReturn: iOptionsRating = {
      rating: this.returnSimplecallbackProgram(index, key),
      readonly: true,
      maxRating: 5,
    };
    this.ratingOptionsArray.push(dataToReturn);
    return this.ratingOptionsArray[index];
  }

  returnSimplecallbackProgram(index, key) {
    return this.promotionDatesProgram[index][key];
  }
}
