import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-EnrollmentDetail-Popup',
  templateUrl: './EnrollmentDetail-Popup.component.html',
  styleUrls: ['./EnrollmentDetail-Popup.component.scss']
})
export class EnrollmentDetailPopupComponent implements OnInit {



  constructor(public dialogRef: MatDialogRef<EnrollmentDetailPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) { }

  ngOnInit() {
     
    this.setter();
  }

  /**
   * GETTERS && SETTERS
   */

  setter() {

  }

  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  swapPlaceholder(event){
    event.target.src = "./assets/Images/Placeholder/placeholder.jpg";
  }

}
