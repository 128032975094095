<fullContainer *ngIf="!isMaster && !isTeacher && !isCurso">
  <header></header>
  <customSearcher
    class="calendar"
    (input)="onSearch($event)"
    [placeHolder]="'Busca el evento...'"
  ></customSearcher>
  <ng-container *ngTemplateOutlet="calendar"></ng-container>
</fullContainer>

<EventCalendarContainer
  cdkDrag
  [cdkDragDisabled]="extraClass.isSelecting"
  (isSelecting)="setIsSelectingAs($event)"
  *ngIf="isNewEventReadyToFill"
></EventCalendarContainer>

<ng-container *ngIf="isMaster || isTeacher || isCurso">
  <ng-container *ngTemplateOutlet="calendar"></ng-container>
</ng-container>

<ng-template #calendar>
  <div class="calendarAndFliltersContainer">
    <Calendar
      [isMaster]="isMaster"
      [isCurso]="isCurso"
      [isTeacher]="isTeacher"
      [publicHolidays]="extraClass.publicHolidays"
      [events]="extraClass.events"
      [checkBoxesArray]="extraClass.checkBoxesArray"
      (dateChange)="onDateChange($event)"
      (eventClick)="onEventClick($event)"
      (dateClick)="onDateClick($event)"
      (refreshLandmarks)="refreshLandmarks()"
    ></Calendar>
    <div class="filtersContainer">
      <span
        class="emptyHeight40"
        *ngIf="isMaster || isTeacher || isCurso"
      ></span>
      <div
        class="buttonsContainer"
        *ngIf="(!isMaster && !isTeacher) || isCurso"
      >
        <btn
          classList="violetButton fitWidth padding816 height40"
          (click)="goToTimeline()"
          >Timeline
          <img [src]="IconsHandlerService.getTimelineIcon()" alt="timeline" />
        </btn>
        <btn
          classList="secondaryButton fitWidth padding816 height40"
          (click)="openFilterPopup()"
          >Filtrar
          <img [src]="IconsHandlerService.getFiltrarIcon()" alt="fiñtrar" />
        </btn>
      </div>

      <ng-container
        *ngFor="let checkbox of extraClass.checkBoxesArray; index as index"
      >
        <custom-checkbox
          [options]="checkbox"
          (change)="onCheckboxChange($event)"
        ></custom-checkbox>
      </ng-container>
    </div>
  </div>
</ng-template>
