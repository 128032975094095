export interface iTutoringSession {
    tutoringId: number;
    landmarkParentId: number;
    startDate: string;
    hours: string;
    slotMinutes: number;
    tutoringAttendanceName: string;
    classroomName: string;
    tutoringStateName: string;
    tutoringStateColor: string;
    topics: Topic[];
    formations: FormationDetail[];
    students: Student[];

}

interface Topic {
    topicName: string;
}

interface FormationDetail {
    formationName: string;
}

interface Student {
    tutoringStudentId: number;
    hours: string;
    studentId: number | null;
    studentName: string | null;
    phone: string | null;
    email: string | null;
    tutoringAttendance: string;
    meetingUrl: string | null;
    classroomName: string | null;
    tutoringStudentState: string;
    tutoringStudentStateColor: string;
    date: string | null;
    comment: string | null;
}

export class ITutoringSession {
    static getEmptyObject(): iTutoringSession {
        return {
            tutoringId: 0,
            landmarkParentId: 0,
            startDate: '',
            hours: '',
            tutoringStateColor: '',
            slotMinutes: 0,
            tutoringAttendanceName: '',
            classroomName: '',
            tutoringStateName: '',
            topics: [],
            formations: [],
            students: []
        };
    }
}