<div class="tableContainer">
  <table class="mainTable">
    <thead class="thead">
      <tr class="tr">
        <th class="th"> <mat-checkbox [checked]="allSelected" (change)="selecteAll($event)"></mat-checkbox></th>
        <th class="th" *ngFor="let item of displayedColumns"
          [ngClass]="{justifyContentFlexStart:item?.displayName === 'Nombre'}" (click)="changeOrder(item.displayName)">
          {{item.displayName}} </th>
        </tr>
      </thead>
      <tbody class="tbody">
        <tr class="loaderLine">
          <loaderIcon *ngIf="!isDataLoaded"></loaderIcon >
        </tr>
      <tr class="tr" *ngFor="let profesor of profesores | paginate:{
        itemsPerPage: filters.num_devoluciones,
        currentPage: filters.num_pagina,
        totalItems: total
      }" (click)="goTo(profesor)">
        <td class="td"> <mat-checkbox (click)="$event.stopPropagation()" (change)="addSelected(profesor)"
            [checked]="profesorSelected.includes(profesor)"></mat-checkbox></td>
        <td class="td" *ngFor="let column of displayedColumns; let index = index;"
          [ngClass]="{fwBold: column?.property === 'name',justifyContentFlexStart:column?.property === 'name' ,estadoTable:column?.property === 'state' }">
          <p
            [ngClass]="{timeBox: column?.property === 'startDate' || column?.property === 'endDate', estadoTabla: column?.property === 'state'}">
            {{profesor[column?.property] ?? '-'}} </p>
        </td>
      </tr>
    </tbody>
  </table>

</div>