<div 

    class="contenedor" 
    [style.height]="height"
    id="ganttContainer"
    >
    <div 
    class="years"
    id="years"
    [style.min-width]="anchoFases"
    >
        
    </div>
    <div 
    class="meses" 
    id="meses"
    [style.min-width]="anchoFases"
    >
        <div 
        class="mes" 
        [style.width]="mes.width+'px'"
        [style.left]="mes.left+'px'"
        *ngFor="let mes of mesesString" 
        >
            {{mes.nombre}}
        </div>
    </div>
    <div 
    class="fase" 
    [style.height]="calcularTamFase(i)" 
    [style.min-width]="anchoFases"
    *ngFor="let fase of fases; let i = index" 
    >
        <button 
        [style.border-color]="fase.backgroundColor"
        >{{fase.nombre}}
        </button>
        <gantt-tarea  
        [color]="fase.backgroundColor"
        [textColor]="fase.textColor"
        [tarea]="tarea"
        [posicion]="tareasPosicion[indiceFaseTarea(i,j)]" 
        [startsBefore]="tareasPosicion[indiceFaseTarea(i,j)].startsBefore"
        [endsAfter]="tareasPosicion[indiceFaseTarea(i,j)].endsAfter"
        
        *ngFor="let tarea of fase.tareas; let j = index" 
        ></gantt-tarea>

    </div>
    <div *ngIf="hoy && today <= fechaFin && today >= fechaInicio" [style.left]="calcularHoy()" class="hoy" [style.height]="altoTodasTareas + 'px'">
        <span class="triangle">

        </span>
        <!-- <span class="fecha">{{today|date:'dd'}}</span> -->
    </div>

</div>
