import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iTeacher, iTeacherFilter } from 'src/app/Shared/Interfaces/iTeacher';

@Injectable({
  providedIn: 'root'
})
export class TeacherService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();
  
  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: iTeacherFilter){
    this.http.get(this.urlApi + '/api/teacher' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
         
        let normalizedTeacher = iStaticUtilities.normalizeNamesCollection(data.data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:normalizedTeacher,total:data.total}});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
         
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAll, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }
  getTeachersSelector(){
    this.http.get(this.urlApi + '/api/teacher/selector' , this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedTeacher = iStaticUtilities.normalizeNamesCollection(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedTeacher});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getTeachersSelector, args: [] });
        this.checkStatusError(error);
      },
    });
  }
  getProductosAsociadosByTeacher(id: number){
    this.http.get(this.urlApi + '/api/teacher/products/' +id, this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedTeacher =  iStaticUtilities.normalizeNamesCollection(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedTeacher});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getProductosAsociadosByTeacher, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  getSingle(id: number){
    this.http.get(this.urlApi + '/api/teacher/'+id, this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedTeacher =  iStaticUtilities.normalizeNames(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedTeacher});
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getSingle, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  getAcademicRegisterByTeacherId(behaviorSubject: BehaviorSubject<any>,teacherId:number ,params: any) {
    this.http
      .get(
        `${this.urlApi}/api/teacher/register/${teacherId}${this.optionsGet(params)}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAcademicRegisterByTeacherId,
            args: [behaviorSubject,teacherId ,params],
          });
        },
      });
  }

  getConceptTeacherBillSelector(behaviorSubject: BehaviorSubject<any>,teacherId:number ) {
    this.http
      .get(
        `${this.urlApi}/api/teacher/pending/bills/selector/${teacherId}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getConceptTeacherBillSelector,
            args: [behaviorSubject,teacherId ],
          });
        },
      });
  }

  getPendingBillsByTeacherId(behaviorSubject: BehaviorSubject<any>,teacherId:number ,params: any) {
    this.http
      .get(
        `${this.urlApi}/api/teacher/pending/bills/${teacherId}${this.optionsGet(params)}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getPendingBillsByTeacherId,
            args: [behaviorSubject,teacherId ,params],
          });
        },
      });
  }

  getTutoringsByTeacherId(behaviorSubject: BehaviorSubject<any>,teacherId:number ) {
    this.http
      .get(
        `${this.urlApi}/api/teacher/tutoring/${teacherId}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getTutoringsByTeacherId,
            args: [behaviorSubject,teacherId ],
          });
        },
      });
  }

  updateTeacherPendingFee(behaviorSubject:BehaviorSubject<any>, data:any){
    this.http
    .put(this.urlApi + '/api/teacher/pending/bills', data, this.getHeader())
    .subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: data,
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.updateTeacherPendingFee,
          args: [behaviorSubject, data],
        });
      },
    });

  }

  updateTeacherTopics(behaviorSubject:BehaviorSubject<any>, data:any){
    this.http
    .put(this.urlApi + '/api/teacher/topics/'+ data.teacherId, data, this.getHeader())
    .subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: data,
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.updateTeacherTopics,
          args: [behaviorSubject, data],
        });
      },
    });

  }

  create(teacher:iTeacher){
    this.http.post(this.urlApi+"/api/teacher", teacher, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [teacher] });
        this.checkStatusError(error);
      },
    });
  }

  update(teacher:any){
    this.http.put(this.urlApi+"/api/teacher/"+teacher.id, teacher, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [teacher] });
        this.checkStatusError(error);
      },
    });
  }

  delete(ids: Array<number|string>){
    let idsObjs = this.convertArrayObject(ids, "ids");
    this.http.delete(this.urlApi+"/api/teacher", this.sendBodyOptions(idsObjs) ).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [ids] });
        this.checkStatusError(error);
      },
    });
  }

}
