<div class="fullSection">
  <div class="subColumnLong">
    <label for="selector">Formación en la que se va a matricular</label>
    <selector (remove)="removeObjectSelector($event)"  [options]="selectorOptionsformation" [(ngModel)]="selectorFormation" (change)="detectHowManyFormationsSelected(selectorFormation)"></selector>
  </div>
  <p class="checkDoubleEnroll"><mat-checkbox [(ngModel)]="doubleEnrollment" *ngIf="arraySelectorPromotion.length == 2" class="checkDoubleEnroll" (change)="emitData()" >Doble matrícula</mat-checkbox></p>
  <div class="selectoresMatricula" *ngIf="arraySelectorPromotion.length > 0">
    <div class="subColumnLong" *ngFor="let promotion of arraySelectorPromotion ; let index = index ">
      <label for="selector">{{ arraySelectorPromotion.length == 1? 'Seleccionar edición' : 'Seleccionar edición para la formación ' + selectorFormation[index].name}}</label>
      <selector [(ngModel)]="promotion.selector" (change)="getSelectorByPromotionId(promotion.selector.id);emitData()" [options]="selectorOptionsPromotionArray![index]"></selector>
      <p class="fwBold capitalize" *ngIf="selectorFormation[index].mode === 'presencial' || selectorFormation[index].mode === 'online' ">{{selectorFormation[index].mode}}</p>
      <selector [options]="opcionesSelectorModalidad" [(ngModel)]="attendanceModality" (change)="emitData()"></selector>

    </div>  
  </div>
  <div class="checkboxesLine">
    <div class="label">
      <p class="fwBold">Alumno FUNDAE</p>
    </div>
    <div class="checkBoxes">
      <mat-radio-group aria-label="Select an option" (change)="emitData()" [(ngModel)]="fundaeRadioButtons">
        <mat-radio-button value="true">Si</mat-radio-button>
        <mat-radio-button value="false">No</mat-radio-button>
        <mat-radio-button value="null">Rellenar por parte de la empresa</mat-radio-button>
      </mat-radio-group>      
    </div>
  </div>
</div>