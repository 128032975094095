import { iDate } from "@quasar-dynamics/basic-designsystem";

export class CalendarioGeneralTimelineClass {
  // timelineSelectedRange
  timelineSelectedRange: number = 2;
  plusOneYear: Date = new Date(new Date().getFullYear() + 1 , new Date().getMonth(), new Date().getDate());
  minusOneYear: Date = new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate());

  timelineFilters:{startDate:string, endDate:string} = {
    startDate: iDate.javascriptConvert(new Date(this.minusOneYear)).toStringDate('JAP'),
    endDate: iDate.javascriptConvert(new Date(this.plusOneYear)).toStringDate('JAP')
  }

  // anchoDia
  anchoDia: number = 3.5;
  // fases
  startDate: Date = this.minusOneYear;
  endDate: Date = this.plusOneYear;
  fases: any[] = [
    {
      nombre: 'Másters',
      backgroundColor: '#3F43AD',
      textColor: '#fff',
      tareas: [
       
      ],
    },
    {
      nombre: 'Cursos',
      backgroundColor: '#78E6D0',
      textColor: '#000',
      tareas: [
       
      ],
    },
    {
      nombre: 'Incompany',
      backgroundColor: '#E3EB50',
      textColor: '#000',
      tareas: [
      ],
    },
  ];
}
