import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  PopupService,
  StaticUtilitiesService,
  SubjectGeneratorService,
  iResultHttp,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { SubirArchivoPopupComponent } from 'src/app/Popups/SubirArchivo-Popup/SubirArchivo-Popup.component';
import { GubrtService } from 'src/app/Services/Api/Gubrt.service';

@Component({
  selector: 'MetodosDePago',
  templateUrl: './MetodosDePago.component.html',
  styleUrls: ['./MetodosDePago.component.scss'],
})
export class MetodosDePagoComponent
  extends SubjectGeneratorService
  implements OnInit
{
  @Input() studentData: any = null;
  @Input() token: any = null;

  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  enrrollmentType: string = '';
  buttonSelected: number = 0;
  isDragging: boolean = false;
  isDocumentLoading: boolean = false;
  file: any = null;
  documentos: any = null;
  objectToSend: any = {};
  constructor(private gubrtSE: GubrtService, private popupSE: PopupService) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['studentData']) {
      if (changes['studentData'].currentValue) {
        this.selectButton(
          changes['studentData'].currentValue.enrollments[0].paymentMethod
        );
        this.enrrollmentType =
          changes['studentData'].currentValue.enrollments[0].type;
      }
    }
    if (changes['token']) {
      if (changes['token'].currentValue) {
        this.objectToSend.token = changes['token'].currentValue.token;
      }
    }
  }

  ngAfterViewInit() {}

  /**
   *
   * FUNCTINALITY
   */

  selectButton(option) {
    switch (option) {
      case 'Transferencia bancaria':
        this.buttonSelected = 1;
        break;
      case 'Domiciliación bancaria':
        this.buttonSelected = 2;
        break;
      case 'Flywire':
        this.buttonSelected = 3;
        break;
      case 'Tarjeta bancaria':
        this.buttonSelected = 4;
        break;

      default:
        break;
    }
  }

  sendToUpdate(value, key, option) {
    switch (option) {
      case 'enrollment':
        this.objectToSend.enrollment = {};
        this.objectToSend.enrollment[key] = value;
        this.studentData.enrollments.forEach((element) => {
          this.objectToSend.enrollment.id = element.id;
          this.update();
          delete this.objectToSend.enrollment.id;
          delete this.objectToSend.enrollment;
        });
        break;

      case 'student':

      default:
        break;
    }
  }

  /**
   * API CALLS
   */

  update() {
    const unsubSubject = this.createSubject();
    this.gubrtSE.editGubrtOldVersion(this.objectToSend);
    this.gubrtSE
      .getResultUpdate()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        //  this.refresh.emit(true);
        this.sendDataToSubject(unsubSubject);
      });
    this.gubrtSE
      .getResultUpdateError()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) return;
        window.open('/', '_self');
        StaticUtilitiesService.showError('No tienes permisos para estar aquí');
        this.sendDataToSubject(unsubSubject);
      });
  }
}
