import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  StaticUtilitiesService,
  SubjectGeneratorService,
  iOptionsSelector,
  iResultHttp,
} from '@quasar_dynamics/basic-designsystem';
import { GubrtService } from 'src/app/Services/Api/Gubrt.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'DatosDeFacturacion',
  templateUrl: './DatosDeFacturacion.component.html',
  styleUrls: ['./DatosDeFacturacion.component.scss'],
})
export class DatosDeFacturacionComponent
  extends SubjectGeneratorService
  implements OnInit
{
  @Input() countriesList: any[] = [];
  @Input() studentData: any = null;
  @Input() token: any = null;
  @Input() sameAddress: boolean = false;

  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sendSameAddressBoolean: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() dataFromStepThree = new EventEmitter<any>();

  isBusiness: boolean = false;

  studentDataStepThree: any = null;
  opcionesPais: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Selecciona un país',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
  };
  objectToSend: any = {
    user: {
      bankData: {},
    },
  };

  constructor(private gubrtSE: GubrtService) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['countriesList']) {
      if (changes['countriesList'].currentValue) {
        this.opcionesPais.items = changes['countriesList'].currentValue;
      }
    }
    if (changes['studentData']) {
      if (changes['studentData'].currentValue) {
        this.studentDataStepThree = changes['studentData'].currentValue;
        if (this.studentData.user.bankData == null)
          this.studentData.user.bankData = { fiscalAddress: {} };
      }
    }
    if (changes['token']) {
      if (changes['token'].currentValue) {
        this.objectToSend.token = changes['token'].currentValue.token;
      }
    }
  }

  /**
   * FUNCTIONALITY
   */

  sameDirectionSelect(event) {
    this.studentData.user.sameAddress = event.checked;
    this.sameAddress = event.checked;
    this.sendSameAddressBoolean.emit(this.sameAddress);

    if (event.checked) {
      this.duplicateAddress();
      this.refresh.emit(true);
    } else {
      this.objectToSend.user.bankData = {
        fiscalAddress: {
          street: '',
          number: '',
          door: '',
          municipality: '',
          postalCode: '',
          province: '',
          country: '',
          email: '',
          phone: '',
        },
      };
      this.update();
      this.emit();
      this.refresh.emit(true);
    }
  }

  isBusinessSelect(event) {
    const checked = event.checked;
    this.isBusiness = checked;

    const emptyData = {
      company: '',
      cif: '',
      owner: '',
      ownerDni: '',
    };

    this.objectToSend.user.bankData = {
      ...emptyData,
    };

    this.studentData.user.bankData = {
      ...this.studentData.user.bankData,
      ...emptyData,
    };

    this.update();
    this.emit();
  }

  duplicateAddress() {
    this.objectToSend.user.bankData = {
      fiscalAddress: {
        country: null,
        street: '',
        number: '',
        door: '',
        municipality: '',
        postalCode: '',
        province: '',
        email: '',
        phone: '',
      },
    };
    for (const key in this.objectToSend.user.bankData.fiscalAddress) {
      this.objectToSend.user.bankData.fiscalAddress[key] =
        this.studentData.user?.address[key] ?? null;
    }
    if (!this.objectToSend.user.bankData.fiscalAddress.country) {
      this.objectToSend.user.bankData.fiscalAddress.country = {
        id: null,
        name: null,
      };
    }
    this.objectToSend.user.bankData.fiscalAddress.email =
      this.studentData.user.email;
    this.objectToSend.user.bankData.fiscalAddress.phone =
      this.studentData.user.phone;

    this.update();
    this.emit();
  }

  emit() {
    this.dataFromStepThree.emit(this.studentDataStepThree);
  }

  sendToUpdate(value, key, option) {
    switch (option) {
      case 'bankData':
        this.objectToSend.user.bankData[key] = value;
        this.update();
        this.emit();
        delete this.objectToSend.user.bankData[key];
        break;

      case 'fiscalAddress':
        this.objectToSend.user.bankData.fiscalAddress = {};
        if (key == 'country') {
          this.objectToSend.user.bankData.fiscalAddress.country = {
            id: null,
          };
          this.objectToSend.user.bankData.fiscalAddress[key].id = value;
        } else {
          this.objectToSend.user.bankData.fiscalAddress[key] = value;
        }
        this.update();
        this.emit();
        delete this.objectToSend.user.bankData.fiscalAddress[key];
        delete this.objectToSend.user.bankData.fiscalAddress;
        break;

      default:
        break;
    }
  }

  /**
   * API CALLS
   */

  update() {
    const unsubSubject = this.createSubject();
    this.gubrtSE.editGubrtOldVersion(this.objectToSend);
    this.gubrtSE
      .getResultUpdate()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        //  this.refresh.emit(true);
        this.sendDataToSubject(unsubSubject);
      });
    this.gubrtSE
      .getResultUpdateError()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) return;
        window.open('/', '_self');
        StaticUtilitiesService.showError('No tienes permisos para estar aquí');
        this.sendDataToSubject(unsubSubject);
      });
  }
}
