import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iBase64File } from '../Interfaces/Utils/iBase64File';

export class AddTeacherBillPopupClass {
  // ngModels
  ngModels: iCreateBill = {
    concepts: null,
    number: null,
    amount: null,
    date: null,
    teacherId: null,
    paymentDate: null,
    billState: null,
    document: { name: '', url: '' },
  };

  // conceptLabel
  conceptLabel: string = '';

  // isEditing
  isEditing: boolean = false;

  // billId
  billId: number | null = null;

  // files
  files: iBase64File[] = [];

  // CanFinish
  canFinish: boolean = false;

  // selectorOptions
  conceptSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'label',
    placeholder: 'Selecciona uno o más conceptos',
    multiple: {
      isMultiple: true,
      checkbox: true,
    },
    search: true,
    label: 'Concepto',
  };
  billStatusSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Selecciona un estado',
    search: true,
    label: 'Estado factura',
  };

  //   Custom input options
  billNumberCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Nº Factura',
    label: 'Nº Factura',
    type: 'text',
  };
  importCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Importe',
    label: 'Importe',
    type: 'number',
  };
  billDateCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Fecha factura',
    label: 'Fecha factura',
    type: 'date',
  };
  paymentDateCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Fecha de pago',
    label: 'Fecha de pago',
    type: 'date',
  };
  readonlyConceptCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Concepto',
    label: 'Concepto',
    type: 'text',
    readOnly: true,
  };
}

interface Document {
  name: string;
  url: string;
}

export interface iCreateBill {
  concepts?: string | null;
  number: number | null;
  amount: number | null;
  date: Date | null;
  teacherId?: number | null;
  paymentDate?: Date | null;
  billState: string | null;
  document: Document;
}

export interface iCreateBillStudent extends iCreateBill {
  concept: string | null;
  payBy: string | null;
  paymentMethod: string | null;
}
