import { Component, OnInit } from '@angular/core';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import { PopupService, iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { Subject, filter, takeUntil } from 'rxjs';
import { AddDispositionPopupComponent } from 'src/app/Popups/AddPopup/AddDisposition-Popup/AddDisposition-Popup.component';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { DispositionService } from 'src/app/Services/Api/Disposition.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'Configuracion-Disposicion',
  templateUrl: './Configuracion-Disposicion.component.html',
  styleUrls: ['./Configuracion-Disposicion.component.scss'],
})
export class ConfiguracionDisposicionComponent implements OnInit {
  isDispositionListLoaded: boolean = false;
  dispositionFilters: any = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  };
  total: number = 0;
  dispositionList: any[] = [
    {
      name: 'Aula 1',
      observations: 'Aula 1 Observaciones',
    },
    {
      name: 'Aula 2',
      observations: 'Aula 2 Observaciones',
    },
    {
      name: 'Aula 3',
      observations: 'Aula 3 Observaciones',
    },
    {
      name: 'Aula 4',
      observations: 'Aula 4 Observaciones',
    },
    {
      name: 'Aula 5',
      observations: 'Aula 5 Observaciones',
    },
  ];

  displayedColumns: any[] = [{ displayName: 'Nombre', property: 'name' }];

  constructor(
    private popupSE: PopupService,
    private generalLoaderSE: GeneralLoaderService,
    private dispositionSE: DispositionService
  ) {}

  ngOnInit() {
    this.getAllDispositions();
  }

  /**
   * HANDLERS
   */

  successGetAllDispositionsHandler(res: iResultHttp) {
    const { data } = res;
    this.dispositionList = data;
    this.isDispositionListLoaded = true;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successupdateDispositionHandler(res: iResultHttp) {
    const { data } = res;
    this.getAllDispositions();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successcreateDispositionHandler(res: iResultHttp) {
    const { data } = res;
    this.getAllDispositions();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteDispositionHandler(res: iResultHttp) {
    const { data } = res;
    this.getAllDispositions();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */

  createDisposition() {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(AddDispositionPopupComponent);
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res: any) => {
        if (res === undefined) {
          return;
        }
        if (res === false) {
          subject.next('');
          return;
        }
        const returnValue = res.returnValue;
        this.createDispositionCall(returnValue);
        subject.next('');
      });
  }

  openDispositionPopup(disposition) {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(AddDispositionPopupComponent, {
      data: disposition,
      edit: true,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res: any) => {
        if (res === undefined) {
          return;
        }
        if (res === false) {
          subject.next('');
          return;
        }
        const returnValue = res.returnValue;
        const objectToPass = {
          id: disposition.id,
          ...returnValue,
        };
        this.updateDisposition(objectToPass);
        subject.next('');
      });
  }

  deleteDispositionAassurer(disposition) {
    const subject = new Subject();
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      accion: 'Eliminar',
      elemento: `la disposición ${disposition.name}`,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }

        this.deleteDisposition([disposition.id]);
        subject.next('');
      });
  }

  pageChanged(event) {
    this.dispositionFilters.page = event;
    this.getAllDispositions();
  }

  /**
   * API CALLS
   */

  getAllDispositions() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.dispositionSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetAllDispositionsHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateDisposition(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.dispositionSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successupdateDispositionHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  createDispositionCall(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.dispositionSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successcreateDispositionHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteDisposition(ids) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.dispositionSE.delete(behaviorSubject, ids);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteDispositionHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
