<div class="tutorias">
  <ng-container *ngIf="tutorings.length > 0; else noData">
    <div class="tutoria__teacherBlock" skeleton [isDataLoaded]="isDataLoaded">
      <div class="tutoria__teacherBlock--title">
        {{ teacherName }}
      </div>

      <ng-container *ngFor="let tutoring of tutorings">
        <div class="tutoria__teacherBlock--tutoring">
          <div class="tutoria__teacherBlock--infoLine">
            <div class="tutoria__teacherBlock--infoLine--items">
              <div class="tutoria__teacherBlock--infoLine--bluePill">
                {{ tutoring.startDate }}
              </div>

              <div class="tutoria__teacherBlock--infoLine--bluePill">
                {{ tutoring.hours }}
              </div>

              <div class="tutoria__teacherBlock--infoLine--bluePill">
                {{ tutoring.slotMinutes }} minutos
              </div>

              <div class="tutoria__teacherBlock--infoLine--bluePill">
                {{ tutoring.tutoringAttendanceName }}
              </div>

              <div class="tutoria__teacherBlock--infoLine--bluePill">
                {{ tutoring.classroomName }}
              </div>
            </div>

            <div class="tutoria__teacherBlock--infoLine--stateAndIcons">
              <Chip
                [value]="tutoring.tutoringStateName"
                [bold]="true"
                [type]="tutoring.tutoringStateColor"
              ></Chip>

              <img
                [src]="IconsHandlerService.getEditBlueIcon()"
                (click)="$event.stopPropagation(); sendEdit(tutoring)"
                alt="edit"
              />
              <img
                [src]="IconsHandlerService.getDeleteIcon()"
                (click)="$event.stopPropagation(); sendDelete(tutoring)"
                alt="delete"
              />
              <img
                [src]="IconsHandlerService.getDuplicateIcon()"
                (click)="
                  $event.stopPropagation();
                  onDuplicateClick(tutoring.tutoringId)
                "
                alt="duplicate"
              />
            </div>
          </div>

          <div class="tutoria__teacherBlock--infoLine">
            <div class="tutoria__teacherBlock--infoLine--items">
              <ng-container *ngFor="let formationName of tutoring.formations">
                <div class="items--backgroung beige">
                  {{ formationName.formationName }}
                </div>
              </ng-container>

              <ng-container *ngFor="let topicName of tutoring.topics">
                <div class="items--backgroung gray">
                  {{ topicName.topicName }}
                </div>
              </ng-container>
            </div>
          </div>

          <div class="tutoria__teacherBlock--whiteBox">
            <main-table
              (clickOnRow)="sendRowClick($event)"
              [slim]="true"
              [dataSource]="tutoring.students"
              [headerData]="headerData"
            ></main-table>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #noData>
  <div class="noData center-in-screen">Este profesor no tiene tutorías</div>
</ng-template>
