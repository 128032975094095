import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import {
  PopupService,
  iResultHttp,
  iUnsubscribeDestroy,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';
import { RoleGlobalStateService } from 'src/app/Services/Utils/RoleGlobalState.service';
import { StaticUtilitiesService as OldStaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { headerData } from 'src/app/Shared/Components/main-table/main-table.component';

@Component({
  selector: 'DetailView-promotions',
  templateUrl: './DetailView-promotions.component.html',
  styleUrls: ['./DetailView-promotions.component.scss'],
})
export class DetailViewPromotionsComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input() incompanies: Array<any> = [];
  @Input() promotions: Array<any> = [];
  @Input() filters: any = {};
  @Input() total: number = 0;
  @Input() navigateActive: boolean = true;
  @Input() changePage: boolean = false;
  @Input() isDataPromotionLoaded: boolean = false;
  @Input() typeOf: string = '';
  @Input() formationId: number = 0;

  @Output() selectedPromotions: any = new EventEmitter<any>();
  @Output() onPageChange: any = new EventEmitter<any>();
  @Output() refresh: EventEmitter<any> = new EventEmitter();

  tableHead: headerData = [
    {
      variant: 'bold',
      display: 'Nombre formación',
      key: 'formation',
      width: '300px',
    },
    {
      variant: 'standard',
      display: 'Edición',
      key: 'edition',
      width: '250px',
    },
    {
      variant: 'standard',
      display: 'Turno',
      key: 'shift',
      width: '250px',
    },
    {
      variant: 'standard',
      display: 'Horario',
      key: 'schedule',
      width: '200px',
    },
    {
      variant: 'box',
      display: 'Inicio',
      key: 'startDate',
      classNameGenerator: () => 'black',
      width: '200px',
    },
    {
      variant: 'box',
      display: 'Finalización',
      key: 'endDate',
      width: '200px',
      classNameGenerator: () => 'default',
    },
    {
      variant: 'standard',
      display: 'Nº Alumnos',
      key: 'students',
      width: '200px',
    },
    {
      variant: 'standard',
      display: 'AF - Grupo',
      key: 'aFGroup',
      width: '300px',
    },
    {
      variant: 'standard',
      display: 'Aula',
      key: 'classroom',
      width: '200px',
    },
    {
      variant: 'chip',
      display: 'Estado',
      key: 'academicState',
      width: '200px',
      classNameGenerator: (state, index, row) => row.academicStateColor,
    },
    {
      variant: 'icons',
      display: '',
      key: 'actions',
      icons: [
        {
          action: (row) => this.onDuplicatePromotion(row),
          image: IconsHandlerService.getDuplicateIcon(),
        },
        {
          action: (row) => this.assurePopupDeletePromotion(row),
          image: IconsHandlerService.getDeleteIcon(),
        },
      ],
    },
  ];

  rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  promotionsSelected: Array<any> = [];
  allSelected: boolean = false;

  constructor(
    private staticUtilitiesSE: StaticUtilitiesService,
    private popupSE: PopupService,
    private promotionSE: PromotionFormationService,
    private generalLoaderSE: GeneralLoaderService,
    public roleGlobalStateSE: RoleGlobalStateService
  ) {
    super();
  }

  ngOnInit() {
    this.rolesAndPermissions = this.roleGlobalStateSE.getRoleGlobalState();
  }

  ngAfterViewInit() {}

  /**
   * HANDLERS
   */

  successDuplicatePromotionHandler(res: iResultHttp) {
    const { message } = res;
    StaticUtilitiesService.showFeedback(String(message));
    this.refresh.emit(true);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteArchievePromotionHandler(res: iResultHttp) {
    const { data } = res;
    StaticUtilitiesService.showFeedback('Promoción eliminada correctamente');
    this.refresh.emit(true);

    this.generalLoaderSE.removeFromLoaderAmount();
  }

  getStatusColor(status: string) {
    switch (status) {
      case 'Activa':
        return '--greenState';
      case 'Programada':
        return '--yellowState';
      case 'Histórico':
        return '--orangeState';
      default:
        return 'default';
    }
  }

  goToPromocionDetailView(promotion) {
    this.staticUtilitiesSE.goTo(
      'formaciones/promocion-detalle/',
      slideNavigationTypes.slideToTop,
      {
        promotion: promotion.id,
        typeOf: this.typeOf,
        formation: this.formationId,
      }
    );
  }

  addSelected(formacion) {
    !this.promotionsSelected.includes(formacion)
      ? this.promotionsSelected.push(formacion)
      : this.promotionsSelected.splice(
          this.promotionsSelected.indexOf(formacion),
          1
        );
    this.promotionsSelected.length == this.promotions.length
      ? (this.allSelected = true)
      : (this.allSelected = false);
    this.selectedPromotions.emit(this.promotionsSelected);
  }
  selectAll(event) {
    if (event.checked) {
      this.promotionsSelected = [...this.promotions];
      this.allSelected = true;
    } else {
      this.promotionsSelected = [];
      this.allSelected = false;
    }
    this.selectedPromotions.emit(this.promotionsSelected);
  }

  checkUncheckedColumns(res) {
    res.map((element) => {
      if (!element.checked) {
        Array.from(
          document.querySelectorAll('.mat-column-' + element.realFilter)
        ).map((column) => {
          (column as HTMLElement).style.display = 'none';
        });
      } else {
        Array.from(
          document.querySelectorAll('.mat-column-' + element.realFilter)
        ).map((column) => {
          (column as HTMLElement).style.display = 'revert';
        });
      }
    });
  }
  checkRolesAndPermissions() {
    return (
      this.rolesAndPermissions.roles.includes('ROLE_ADMIN') ||
      this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') ||
      this.rolesAndPermissions.permissions.includes(4)
    );
  }

  assurePopupDeletePromotion(promotion) {
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      accion: 'Eliminar',
      elemento: 'la promoción seleccionada',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) return;
        this.deleteArchievePromotion(promotion);
        this._unsubInd.next('');
      });
  }

  pageChange(event) {
    this.onPageChange.emit(event);
  }

  onDuplicatePromotion(row) {
    const objectToPass = { promotionId: row.id };
    this.duplicatePromotion(objectToPass);
  }

  /**
   * API CALLS
   */

  deleteArchievePromotion(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.deleteArchievePromotion(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteArchievePromotionHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  duplicatePromotion(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.duplicatePromotion(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDuplicatePromotionHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
