<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="closePopup(false)">close</mat-icon>
    <p >Conflictos:</p>
    <mat-icon (click)="closePopup(false)">close</mat-icon>
  </div>

  <div class="body">

    
    <ng-container *ngIf="modalData.classroom.length > 0">
      <div class="conflictBlock">
        <p class="title" >Aula</p>
        <ng-container *ngFor="let classroomConflict of modalData.classroom">
          <p>{{classroomConflict}}</p>
        </ng-container>
        
      </div>
    </ng-container>
      
    
    <ng-container *ngIf="modalData.teacher.length > 0">
      <div class="conflictBlock">
        <p class="title" >Profesor</p>
        <ng-container *ngFor="let teacherConflict of modalData.teacher">
          <p>{{teacherConflict}}</p>
        </ng-container>

      </div>
    </ng-container>

    
  </div>

  <div class="footer">
    <btn classList="principalButton" (click)="closePopup(false)">
      <ng-container>
       Cerrar
      </ng-container>
    </btn>
  </div>
</div>
