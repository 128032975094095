import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { InputTestClass } from '../../Classes/InputTestClass';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'inputTest',
  templateUrl: './inputTest.component.html',
  styleUrls: ['./inputTest.component.scss'],
})
export class InputTestComponent implements OnInit {
  private extraClass: InputTestClass = new InputTestClass();

  testItems: number[] = [];

  // @Input() value:string = '';

  @Input()
  public get value(): string {
    return this.extraClass._value;
  }
  public set value(value: string) {
    this.extraClass._value = value;
  }

  @Input() className: string = '';

  @Output() a:EventEmitter<string> = new EventEmitter<string>();

  constructor(private _ngZone: NgZone, private http: HttpClient) {}

  ngOnInit() {
  }
    
}
