import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StaticDataHandlerService {
  _infoTeacher: any = {
    activity_time: '10 días',
    id: 2,
    user: {
      age: null,
      id: 7,
      email: 'teacher1@mail.com',
      name: 'teacher1',
      surname: 'teacher1',
      register_date: '2023-05-02T09:29:58+00:00',
      _dni: '11111111A',
      phone: '665101112',
      date_of_birth: null,
      gender: 'mujer',
      address: null,
      biography: null,
      company: null,
      job_position: null,
      employment_status: null,
      languages: ['spanish'],
      observation: null,
      bank_data: null,
      nationality: 'española',
      phone_two: null,
      phone_prefix: null,
      phone_two_prefix: null,
      social_medias: null,
    },
    specialty: 'marketing',
    incorporation_date: '2023-05-15T00:00:00+00:00',
    documents: [
      {
        id: 10,
        name: 'dniprofesor2.pdf',
        url: 'http://localhost/IEM/public/docs/facturasfactura_96fdb0ab1c.pdf',
      },
    ],
    bills: [],
    type_of: 'teacher',
    teacher_promotions: [
      {
        id: 4,
        promotion: {
          id: 1,
          start_date: '2024-05-15T00:00:00+00:00',
          end_date: '2024-07-15T00:00:00+00:00',
          schedule: 'Lunes 18:00 - 21:00 / Miércoles 15:00 - 19:00',
          formation: {
            id: 1,
            name: 'curso1',
            type_of: 'curso',
          },
          edition: 'Mayo 2024',
        },
        position: 'director',
        assignment_date: '2023-05-09T00:00:00+00:00',
      },
      {
        id: 5,
        promotion: {
          id: 2,
          start_date: '2023-03-01T00:00:00+00:00',
          end_date: '2023-05-01T00:00:00+00:00',
          schedule: 'Lunes 18:00 - 21:00 / Miércoles 15:00 - 19:00',
          formation: {
            id: 2,
            name: 'incompany1',
            type_of: 'incompany',
          },
          edition: 'Marzo 2023',
        },
        position: 'profesor raso',
        assignment_date: '2023-05-25T00:00:00+00:00',
      },
      {
        id: 6,
        promotion: {
          id: 4,
          start_date: '2023-05-15T00:00:00+00:00',
          end_date: '2023-08-15T00:00:00+00:00',
          schedule: 'Lunes 18:00 - 21:00 / Miércoles 15:00 - 19:00',
          formation: {
            id: 1,
            name: 'curso1',
            type_of: 'curso',
          },
          edition: 'Mayo 2023',
        },
        position: 'profesor raso',
        assignment_date: '2023-05-25T00:00:00+00:00',
      },
    ],
    active: true,
    active_days: 10,
    work_experience_since: '1995-05-10T00:00:00+00:00',
  };
  _years: Array<number> = [];
  _paises = [
    'Afganistá',
    'Åland',
    'Albani',
    'Alemania',
    'Andorra',
    'Angola',
    'Anguila',
    'Antártida',
    'Antigua y Barbuda',
    'Arabia Saudita',
    'Argelia',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaiyán',
    'Bahamas',
    'Bangladés',
    'Barbados',
    'Baréin',
    'Bélgica',
    'Belice',
    'Benín',
    'Bermudas',
    'Bielorrusia',
    'Bolivia',
    'Bonaire, San Eustaquio y Saba',
    'Bosnia y Herzegovina',
    'Botsuana',
    'Brasil',
    'Brunéi',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Bután',
    'Cabo Verde',
    'Camboya',
    'Camerún',
    'Canadá',
    'Catar',
    'Chad',
    'Chile',
    'China',
    'Chipre',
    'Colombia',
    'Comoras',
    'Corea del Norte',
    'Corea del Sur',
    'Costa de Marfil',
    'Costa Rica',
    'Croacia',
    'Cuba',
    'Curazao',
    'Dinamarca',
    'Dominica',
    'Ecuador',
    'Egipto',
    'El Salvador',
    'Emiratos Árabes Unidos',
    'Eritrea',
    'Eslovaquia',
    'Eslovenia',
    'España',
    'Estados Unidos',
    'Estonia',
    'Etiopía',
    'Filipinas',
    'Finlandia',
    'Fiyi',
    'Francia',
    'Gabón',
    'Gambia',
    'Georgia',
    'Ghana',
    'Gibraltar',
    'Granada',
    'Grecia',
    'Groenlandia',
    'Guadalupe',
    'Guam',
    'Guatemala',
    'Guayana Francesa',
    'Guernsey',
    'Guinea',
    'Guinea-Bisáu',
    'Guinea Ecuatorial',
    'Guyana',
    'Haití',
    'Honduras',
    'Hong Kong',
    'Hungría',
    'India',
    'Indonesia',
    'Irak',
    'Irán',
    'Irlanda',
    'Isla Bouvet',
    'Isla de Man',
    'Isla de Navidad',
    'Islandia',
    'Islas Caimán',
    'Islas Cocos',
    'Islas Cook',
    'Islas Feroe',
    'Islas Georgias del Sur y Sandwich del Sur',
    'Islas Heard y McDonald',
    'Islas Malvinas',
    'Islas Marianas del Norte',
    'Islas Marshall',
    'Islas Pitcairn',
    'Islas Salomón',
    'Islas Turcas y Caicos',
    'Islas ultramarinas de Estados Unidos',
    'Islas Vírgenes Británicas',
    'Islas Vírgenes de los Estados Unidos',
    'Israel',
    'Italia',
    'Jamaica',
    'Japón',
    'Jersey',
    'Jordania',
    'Kazajistán',
    'Kenia',
    'Kirguistán',
    'Kiribati',
    'Kuwait',
    'Laos',
    'Lesoto',
    'Letonia',
    'Líbano',
    'Liberia',
    'Libia',
    'Liechtenstein',
    'Lituania',
    'Luxemburgo',
    'Macao',
    'Macedonia',
    'Madagascar',
    'Malasia',
    'Malaui',
    'Maldivas',
    'Malí',
    'Malta',
    'Marruecos',
    'Martinica',
    'Mauricio',
    'Mauritania',
    'Mayotte',
    'México',
    'Micronesia',
    'Moldavia',
    'Mónaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Nicaragua',
    'Níger',
    'Nigeria',
    'Niue',
    'Norfolk',
    'Noruega',
    'Nueva Caledonia',
    'Nueva Zelanda',
    'Omán',
    'Países Bajos',
    'Pakistán',
    'Palaos',
    'Palestina',
    'Panamá',
    'Papúa Nueva Guinea',
    'Paraguay',
    'Perú',
    'Polinesia Francesa',
    'Polonia',
    'Portugal',
    'Puerto Rico',
    'Reino Unido',
    'República Árabe Saharaui Democrática',
    'República Centroafricana',
    'República Checa',
    'República del Congo',
    'República Democrática del Congo',
    'República Dominicana',
    'Reunión',
    'Ruanda',
    'Rumania',
    'Rusia',
    'Samoa',
    'Samoa Americana',
    'San Bartolomé',
    'San Cristóbal y Nieves',
    'San Marino',
    'San Martín',
    'San Pedro y Miquelón',
    'San Vicente y las Granadinas',
    'Santa Elena, Ascensión y Tristán de Acuña',
    'Santa Lucía',
    'Santo Tomé y Príncipe',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leona',
    'Singapur',
    'Sint Maarten',
    'Siria',
    'Somalia',
    'Sri Lanka',
    'Suazilandia',
    'Sudáfrica',
    'Sudán',
    'Sudán del Sur',
    'Suecia',
    'Suiza',
    'Surinam',
    'Svalbard y Jan Mayen',
    'Tailandia',
    'Taiwán (República de China)',
    'Tanzania',
    'Tayikistán',
    'Territorio Británico del Océano Índico',
    'Tierras Australes y Antárticas Francesas',
    'Timor Oriental',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad y Tobago',
    'Túnez',
    'Turkmenistán',
    'Turquía',
    'Tuvalu',
    'Ucrania',
    'Uganda',
    'Uruguay',
    'Uzbekistán',
    'Vanuatu',
    'Vaticano, Ciudad del',
    'Venezuela',
    'Vietnam',
    'Wallis y Futuna',
    'Yemen',
    'Yibuti',
    'Zambia',
    'Zimbabue',
  ];
  _provincias = [
    'Alava',
    'Albacete',
    'Alicante',
    'Almería',
    'Asturias',
    'Avila',
    'Badajoz',
    'Barcelona',
    'Burgos',
    'Cáceres',
    'Cádiz',
    'Cantabria',
    'Castellón',
    'Ciudad Real',
    'Córdoba',
    'La Coruña',
    'Cuenca',
    'Gerona',
    'Granada',
    'Guadalajara',
    'Guipúzcoa',
    'Huelva',
    'Huesca',
    'Islas Baleares',
    'Jaén',
    'León',
    'Lérida',
    'Lugo',
    'Madrid',
    'Málaga',
    'Murcia',
    'Navarra',
    'Orense',
    'Palencia',
    'Las Palmas',
    'Pontevedra',
    'La Rioja',
    'Salamanca',
    'Segovia',
    'Sevilla',
    'Soria',
    'Tarragona',
    'Santa Cruz de Tenerife',
    'Teruel',
    'Toledo',
    'Valencia',
    'Valladolid',
    'Vizcaya',
    'Zamora',
    'Zaragoza',
  ];
  _meses = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiemnbre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  _profesores = [
    {
      id: 1,
      url: '',
      nombre: 'Antonio Corraliza León',
      email: 'corralizaleonantonio@gmail.com',
      telefono: '(+34) 645 245 324',
      etiquetas: ['másters', 'cursos'],
      estado: 'En activo',
    },
    {
      id: 2,
      url: '',
      nombre: 'Antonio Corraliza León',
      email: 'corralizaleonantonio@gmail.com',
      telefono: '(+34) 645 245 324',
      etiquetas: ['másters', 'cursos'],
      estado: 'En activo',
    },
    {
      id: 3,
      url: '',
      nombre: 'Antonio Corraliza León',
      email: 'corralizaleonantonio@gmail.com',
      telefono: '(+34) 645 245 324',
      etiquetas: ['másters', 'cursos'],
      estado: 'En activo',
    },
    {
      id: 4,
      url: '',
      nombre: 'Antonio Corraliza León',
      email: 'corralizaleonantonio@gmail.com',
      telefono: '(+34) 645 245 324',
      etiquetas: ['másters', 'cursos'],
      estado: 'En activo',
    },
    {
      id: 5,
      url: '',
      nombre: 'Antonio Corraliza León',
      email: 'corralizaleonantonio@gmail.com',
      telefono: '(+34) 645 245 324',
      etiquetas: ['másters', 'cursos'],
      estado: 'En activo',
    },
    {
      id: 6,
      url: '',
      nombre: 'Antonio Corraliza León',
      email: 'corralizaleonantonio@gmail.com',
      telefono: '(+34) 645 245 324',
      etiquetas: ['másters', 'cursos'],
      estado: 'En activo',
    },
    {
      id: 7,
      url: '',
      nombre: 'Antonio Corraliza León',
      email: 'corralizaleonantonio@gmail.com',
      telefono: '(+34) 645 245 324',
      etiquetas: ['másters', 'cursos'],
      estado: 'En activo',
    },
    {
      id: 8,
      url: '',
      nombre: 'Antonio Corraliza León',
      email: 'corralizaleonantonio@gmail.com',
      telefono: '(+34) 645 245 324',
      etiquetas: ['másters', 'cursos'],
      estado: 'En activo',
    },
  ];
  _observacionesProfe = [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
  ];
  _topInfoProfe = {
    user: {
      name: 'Lucius',
      surname: 'Cornelius Sulla',
      email: 'correodeejemplo@correo.com',
      phone: '111111111',
      phonePrefix: '+34',
      observation:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      address: {
        province: 'Madrid',
        country: 'España',
      },
    },
    incorporationDate: new Date(),
    specialty: 'Marketing',
  };
  _productosAsociadosProfe = [
    {
      id: 1,
      tipo: 'Máster',

      nombre: 'Máster en marketing digital y digital business',
    },
  ];
  _documentos: Array<any> = [
    {
      id: 1,
      url: 'https://www.laspalmasgc.es/export/sites/laspalmasgc/.galleries/contenido-anuncios-edusi/documentoPrueba.pdf',
      tipo: 'pdf',
      nombre: 'Anna_ClimentSmith_CV_2022',
    },
    {
      id: 2,
      url: 'https://www.laspalmasgc.es/export/sites/laspalmasgc/.galleries/contenido-anuncios-edusi/documentoPrueba.pdf',
      tipo: 'pdf',
      nombre: 'Contrato_Anna_Climent_Smith_2021',
    },
    {
      id: 3,
      url: 'https://www.laspalmasgc.es/export/sites/laspalmasgc/.galleries/contenido-anuncios-edusi/documentoPrueba.pdf',
      tipo: 'pdf',
      nombre: 'Anna_ClimentSmith_CV_2022',
    },
    {
      id: 4,
      url: 'https://www.laspalmasgc.es/export/sites/laspalmasgc/.galleries/contenido-anuncios-edusi/documentoPrueba.pdf',
      tipo: 'pdf',
      nombre: 'Contrato_Anna_Climent_Smith_2021',
    },
    {
      id: 5,
      url: 'https://www.laspalmasgc.es/export/sites/laspalmasgc/.galleries/contenido-anuncios-edusi/documentoPrueba.pdf',
      tipo: 'pdf',
      nombre: 'Anna_ClimentSmith_CV_2022',
    },
    {
      id: 6,
      url: 'https://www.laspalmasgc.es/export/sites/laspalmasgc/.galleries/contenido-anuncios-edusi/documentoPrueba.pdf',
      tipo: 'pdf',
      nombre: 'Contrato_Anna_Climent_Smith_2021',
    },
  ];
  _facturasProfesor: Array<any> = [
    {
      id: 12,
      amount: 8850,
      document: {
        id: 64,
        name: 'lorem-ipsum.pdf',
        url: 'https://iem.javiero21.sg-host.com/back/public/docs/facturasfactura_3fe09b3ae0.pdf',
      },
      state: 'pagado',
    },
    {
      id: 12,
      amount: 8850,
      document: {
        id: 64,
        name: 'lorem-ipsum.pdf',
        url: 'https://iem.javiero21.sg-host.com/back/public/docs/facturasfactura_3fe09b3ae0.pdf',
      },
      state: 'pagado',
    },
  ];

  _formaciones: Array<any> = [
    {
      id: 1,
      nombre: 'Máster en Community Management y social',
      tipo: 'Máster',
      promocion: 'Mayo 2023',
      horario: 'M, X, J, Tardes',
      fechaInicio: '12/05/2023',
      fechaFin: '12/05/2024',
      modo: 'Presencial',
      estado: 'En activo',
    },
    {
      id: 2,
      nombre: 'Curso en Google Ads',
      tipo: 'Curso',
      promocion: 'Mayo 2023',
      horario: 'M, X, J, Tardes',
      fechaInicio: '12/05/2023',
      fechaFin: '12/05/2024',
      modo: 'Online',
      estado: 'Abierto matrícula',
    },
    {
      id: 3,
      nombre: 'Máster en Community Management y social',
      tipo: 'Máster',
      promocion: 'Mayo 2023',
      horario: 'M, X, J, Tardes',
      fechaInicio: '12/05/2023',
      fechaFin: '12/05/2024',
      modo: 'Presencial',
      estado: 'En activo',
    },
    {
      id: 4,
      nombre: 'Curso en Google Ads',
      tipo: 'Curso',
      promocion: 'Mayo 2023',
      horario: 'M, X, J, Tardes',
      fechaInicio: '12/05/2023',
      fechaFin: '12/05/2024',
      modo: 'Online',
      estado: 'Abierto matrícula',
    },
    {
      id: 5,
      nombre: 'Máster en Community Management y social',
      tipo: 'Máster',
      promocion: 'Mayo 2023',
      horario: 'M, X, J, Tardes',
      fechaInicio: '12/05/2023',
      fechaFin: '12/05/2024',
      modo: 'Presencial',
      estado: 'En activo',
    },
    {
      id: 6,
      nombre: 'Curso en Google Ads',
      tipo: 'Curso',
      promocion: 'Mayo 2023',
      horario: 'M, X, J, Tardes',
      fechaInicio: '12/05/2023',
      fechaFin: '12/05/2024',
      modo: 'Online',
      estado: 'Abierto matrícula',
    },
  ];
  _alumnos: Array<any> = [
    {
      id: 1,
      nombre: 'Manel Almenar Cardona',
      url: '',
      email: 'alcarmanel@gmail.com',
      telefono: '(+34) 645 245 324',
      formacion: 'Máster en community management y social',
      estado: 'Cursando',
      anyoFormacion: '2021',
    },
    {
      id: 2,
      nombre: 'Manel Almenar Cardona',
      url: '',
      email: 'alcarmanel@gmail.com',
      telefono: '(+34) 645 245 324',
      formacion: 'Máster en community management y social',
      estado: 'Matriculado',
      anyoFormacion: '2021',
    },
    {
      id: 3,
      nombre: 'Manel Almenar Cardona',
      url: '',
      email: 'alcarmanel@gmail.com',
      telefono: '(+34) 645 245 324',
      formacion: 'Máster en community management y social',
      estado: 'Cursando',
      anyoFormacion: '2021',
    },
    {
      id: 4,
      nombre: 'Manel Almenar Cardona',
      url: '',
      email: 'alcarmanel@gmail.com',
      telefono: '(+34) 645 245 324',
      formacion: 'Máster en community management y social',
      estado: 'Matriculado',
      anyoFormacion: '2021',
    },
    {
      id: 5,
      nombre: 'Manel Almenar Cardona',
      url: '',
      email: 'alcarmanel@gmail.com',
      telefono: '(+34) 645 245 324',
      formacion: 'Máster en community management y social',
      estado: 'Cursando',
      anyoFormacion: '2021',
    },
    {
      id: 6,
      nombre: 'Manel Almenar Cardona',
      url: '',
      email: 'alcarmanel@gmail.com',
      telefono: '(+34) 645 245 324',
      formacion: 'Máster en community management y social',
      estado: 'Matriculado',
      anyoFormacion: '2021',
    },
  ];
  _matriculacionesLista: Array<any> = [
    {
      id: 1,
      nombre: 'Manel Almenar Cardona',
      url: '',
      email: 'alcarmanel@gmail.com',
      telefono: '(+34) 645 245 324',
      tipoFormacion: ['másters', 'cursos', 'incompany'],
      estado: 'Pendiente de verificar',
      fase: 1,
    },
    {
      id: 2,
      nombre: 'Manel Almenar Cardona',
      url: '',
      email: 'alcarmanel@gmail.com',
      telefono: '(+34) 645 245 324',
      tipoFormacion: ['másters', 'cursos', 'incompany'],
      estado: 'Pago único',
      fase: 2,
    },
    {
      id: 3,
      nombre: 'Manel Almenar Cardona',
      url: '',
      email: 'alcarmanel@gmail.com',
      telefono: '(+34) 645 245 324',
      tipoFormacion: ['másters', 'cursos'],
      estado: 'Correo enviado',
      fase: 3,
    },
  ];
  _EstadosPromociones: string[] = [
    'Nueva',
    'Matrícula abierta',
    'Matrícula cerrada',
    'En curso',
    'Finalizada',
  ];
  _formacionesSkelleton: Array<any> = [
    {
      name: 'Máster en Community Management y social',
      active: true,
      id: 1,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Null',
      duration: '260',
      mode: 'Mixto',
    },
    {
      name: 'Máster en Community Management y social',
      active: true,
      id: 1,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Null',
      duration: '260',
      mode: 'Mixto',
    },
    {
      name: 'Máster en Community Management y social',
      active: true,
      id: 1,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Null',
      duration: '260',
      mode: 'Mixto',
    },
    {
      name: 'Máster en Community Management y social',
      active: true,
      id: 1,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Null',
      duration: '260',
      mode: 'Mixto',
    },
    {
      name: 'Máster en Community Management y social',
      active: true,
      id: 1,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Null',
      duration: '260',
      mode: 'Mixto',
    },
    {
      name: 'Máster en Community Management y social',
      active: true,
      id: 1,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Null',
      duration: '260',
      mode: 'Mixto',
    },
  ];
  _studentsSkelleton: any = {
    edition: 'Mayo 2023',
    schedule: 'Lunes a viernes de 9:00 a 14:00',
    startDate: '01/05/2023',
    endDate: '01/05/2023',
    state: 'En curso',
  };
  _bonificacionesFUNDAE: Array<string> = [
    'Bonificación IEM',
    'Bonificación Empresa',
    'Solo Bonificación',
    'Sin Bonificación',
  ];
  _emptyGroupStudents: Array<any> = [
    {
      student: {
        user: {
          name: 'Manel',
          surname: 'Almenar Cardona',
          email: 'info@lasprovincias.com',
          phone: '645245324',
          phonePrefix: '+34',
          dni: '12345678A',
        },
      },
    },
    {
      student: {
        user: {
          name: 'Manel',
          surname: 'Almenar Cardona',
          email: 'info@lasprovincias.com',
          phone: '645245324',
          phonePrefix: '+34',
          dni: '12345678A',
        },
      },
    },
    {
      student: {
        user: {
          name: 'Manel',
          surname: 'Almenar Cardona',
          email: 'info@lasprovincias.com',
          phone: '645245324',
          phonePrefix: '+34',
          dni: '12345678A',
        },
      },
    },
    {
      student: {
        user: {
          name: 'Manel',
          surname: 'Almenar Cardona',
          email: 'info@lasprovincias.com',
          phone: '645245324',
          phonePrefix: '+34',
          dni: '12345678A',
        },
      },
    },
  ];
  _iemUserList: Array<any> = [
    {
      name: 'Nombre',
      surname: 'Apellido',
      fullName: 'Nombre Apellido',
      email: 'Correo electrónico',
      startDate: new Date().toISOString().split('T')[0],
    },
    {
      name: 'Nombre',
      surname: 'Apellido',
      fullName: 'Nombre Apellido',
      email: 'Correo electrónico',
      startDate: new Date().toISOString().split('T')[0],
    },
    {
      name: 'Nombre',
      surname: 'Apellido',
      fullName: 'Nombre Apellido',
      email: 'Correo electrónico',
      startDate: new Date().toISOString().split('T')[0],
    },
  ];
  private _languages: string[] = [
    // Familia Indoeuropea
    'Alemán',
    'Inglés',
    'Neerlandés',
    'Sueco',
    'Danés',
    'Noruego',
    'Islandés',
    'Español',
    'Francés',
    'Italiano',
    'Portugués',
    'Rumano',
    'Catalán',
    'Ruso',
    'Polaco',
    'Checo',
    'Eslovaco',
    'Serbio',
    'Croata',
    'Búlgaro',
    'Irlandés',
    'Gaélico escocés',
    'Galés',
    'Griego',

    // Familia Sino-Tibetana
    'Chino',

    // Familia Afroasiática
    'Árabe',
    'Hebreo',

    // Familia Uralica
    'Húngaro',
    'Finés',
    'Estonio',

    // Familia Altaica (Disputada)
    'Turco',
    'Mongol',

    // Familia Japonés-coreana (Disputada)
    'Japonés',
    'Coreano',

    // Familia Tai-Kadai
    'Tailandés',

    // Lenguas de Signos
    'Lengua de Signos Americana (ASL)',
    'Lengua de Signos Británica (BSL)',
    'Lengua de Signos Española (LSE)',

    // Lenguas Aisladas
    'Vasco',
  ];

  constructor() {}

  generateYears() {
    let currentYear = new Date().getFullYear();
    let years: Array<number> = [];
    for (let i = currentYear - 10; i <= currentYear + 10; i++) {
      years.push(i);
    }
    return years;
  }

  getYears() {
    this._years = this.generateYears();
    return this._years;
  }

  static getPaises() {
    return new StaticDataHandlerService()._paises;
  }
  static getInfoTeacher() {
    return new StaticDataHandlerService()._infoTeacher;
  }
  static getProvincias() {
    return new StaticDataHandlerService()._provincias;
  }
  static getMeses() {
    return new StaticDataHandlerService()._meses;
  }
  static getProfesores() {
    return new StaticDataHandlerService()._profesores;
  }
  static getProductosAsociadosProfe() {
    return new StaticDataHandlerService()._productosAsociadosProfe;
  }
  static topInfoProfe() {
    return new StaticDataHandlerService()._topInfoProfe;
  }
  static getDocumentos() {
    return new StaticDataHandlerService()._documentos;
  }
  static getFacturas() {
    return new StaticDataHandlerService()._facturasProfesor;
  }
  static getFormaciones() {
    return new StaticDataHandlerService()._formaciones;
  }
  static getAlumnos() {
    return new StaticDataHandlerService()._alumnos;
  }
  static getMatriculasListado() {
    return new StaticDataHandlerService()._matriculacionesLista;
  }
  static getEstadosPromociones() {
    return new StaticDataHandlerService()._EstadosPromociones;
  }
  static getFormacionesSkelleton() {
    return new StaticDataHandlerService()._formacionesSkelleton;
  }
  static getStudentsSkelleton() {
    return new StaticDataHandlerService()._studentsSkelleton;
  }
  static getBonificacionesFUNDAE() {
    return new StaticDataHandlerService()._bonificacionesFUNDAE;
  }
  static getEmptyGroupStudents() {
    return new StaticDataHandlerService()._emptyGroupStudents;
  }
  static getIEMUserList() {
    return new StaticDataHandlerService()._iemUserList;
  }

  static getLanguages() {
    return new StaticDataHandlerService()._languages;
  }
}
