import { Component, Input, OnInit } from '@angular/core';
import { DetailViewTeachersClass } from 'src/app/Shared/Classes/DetailViewTeachersClass';
import { iPromotionBill } from 'src/app/Shared/Interfaces/Utils/iPromotionBill';

@Component({
  selector: 'detailView-Facturacion',
  templateUrl: './DetailView-Facturacion.component.html',
  styleUrls: ['./DetailView-Facturacion.component.scss']
})
export class DetailViewTeachersComponent implements OnInit {

  @Input() get promotionBilling(){
    return this.extraClass.promotionBilling
  }

  set promotionBilling(value){
    this.extraClass.promotionBilling = value
    this.splitPromotionBilling(value)
  }
  

  extraClass:DetailViewTeachersClass = new DetailViewTeachersClass()

  constructor() { }

  ngOnInit() {
  }

  /**
   * Functionalities
   */

  splitPromotionBilling(promotionBilling:iPromotionBill){
    const {bills , teachers} = promotionBilling
    this.extraClass.billingBills = bills
    this.extraClass.billingTeachers = teachers
  }

}
