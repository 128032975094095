import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject, Subject } from 'rxjs';
import { iStudent, iStudentFilter } from 'src/app/Shared/Interfaces/iStudent';

@Injectable({
  providedIn: 'root',
})
export class StudentService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  protected _filterInfo = new BehaviorSubject<any>(null);
  getResultfilterInfo() {
    return this._filterInfo;
  }
  clearResultfilterInfo() {
    this._filterInfo.next(null);
  }
  protected _filterInfoError = new BehaviorSubject<any>(null);
  getResultfilterInfoError() {
    return this._filterInfoError;
  }
  clearResultfilterInfoError() {
    this._filterInfoError.next(null);
  }

  protected _alumnList = new BehaviorSubject<any>(null);
  getResultalumnList() {
    return this._alumnList;
  }
  clearResultalumnList() {
    this._alumnList.next(null);
  }
  protected _alumnListError = new BehaviorSubject<any>(null);
  getResultalumnListError() {
    return this._alumnListError;
  }
  clearResultalumnListError() {
    this._alumnListError.next(null);
  }

  protected _alumniList = new BehaviorSubject<any>(null);
  getResultalumniList() {
    return this._alumniList;
  }
  clearResultalumniList() {
    this._alumniList.next(null);
  }
  protected _alumniListError = new BehaviorSubject<any>(null);
  getResultalumniListError() {
    return this._alumniListError;
  }
  clearResultalumniListError() {
    this._alumniListError.next(null);
  }

  protected _allStudents = new BehaviorSubject<any>(null);
  getResultallStudents() {
    return this._allStudents;
  }
  clearResultallStudents() {
    this._allStudents.next(null);
  }
  protected _allStudentsError = new BehaviorSubject<any>(null);
  getResultallStudentsError() {
    return this._allStudentsError;
  }
  clearResultallStudentsError() {
    this._allStudentsError.next(null);
  }

  protected _ProductsPerStudent = new BehaviorSubject<any>(null);
  getResultProductsPerStudent() {
    return this._ProductsPerStudent;
  }
  clearResultProductsPerStudent() {
    this._ProductsPerStudent.next(null);
  }

  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: iStudentFilter) {
    this.http
      .get(
        this.urlApi + '/api/student' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedStudent = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedStudent, total: data.total },
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [queryParams],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAlumnList(queryParams: any) {
    this.http
      .get(
        this.urlApi + '/api/alumn' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedStudent = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedStudent, total: data.total },
          });
          this.sendNextObservable(this._alumnList, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._alumnListError, this.res, true, {
            method: this.getAlumnList,
            args: [queryParams],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAlumniList(queryParams: any) {
    this.http
      .get(
        this.urlApi + '/api/alumni' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedStudent = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedStudent, total: data.total },
          });
          this.sendNextObservable(this._alumniList, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._alumniListError, this.res, true, {
            method: this.getAlumnList,
            args: [queryParams],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAllStudents(queryParams: any) {
    this.http
      .get(
        this.urlApi + '/api/student' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedStudent =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedStudent,
          });
          this.sendNextObservable(this._allStudents, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._allStudentsError, this.res, true, {
            method: this.getAlumnList,
            args: [queryParams],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAllProductsPerStudent(id) {
    this.http
      .get(this.urlApi + '/api/student/formations/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedStudent =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedStudent,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAllProductsPerStudent,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  getFilterInfo(queryParams: any) {
    this.http
      .get(
        this.urlApi + '/api/student/filters' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedStudent =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedStudent,
          });
          this.sendNextObservable(this._filterInfo, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._filterInfoError, this.res, true, {
            method: this.getFilterInfo,
            args: [queryParams],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSingle(id: number) {
    this.http
      .get(this.urlApi + '/api/student/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedStudent = iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedStudent,
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  getStudentsByPromotionSelector(
    behaviorSubject: BehaviorSubject<any>,
    promotionId: number
  ) {
    this.http
      .get(
        `${this.urlApi}/api/students/promotion/${promotionId}/selector`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getStudentsByPromotionSelector,
            args: [behaviorSubject, promotionId],
          });
        },
      });
  }

  getStudentsProformaByPromotionSelector(
    behaviorSubject: BehaviorSubject<any>,
    studentId: number
  ) {
    this.http
      .get(
        `${this.urlApi}/api/students/promotion/${studentId}/proforma
`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getStudentsProformaByPromotionSelector,
            args: [behaviorSubject, studentId],
          });
        },
      });
  }

  getPendingAndTotalAmountFromStudentPromotion(
    behaviorSubject: BehaviorSubject<any>,
    studentPromotionId: number
  ) {
    this.http
      .get(
        `${this.urlApi}/api/student/promotion/pending/amount/${studentPromotionId}
`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getPendingAndTotalAmountFromStudentPromotion,
            args: [behaviorSubject, studentPromotionId],
          });
        },
      });
  }

  getUserByRecoveryToken(token: any) {
    this.http.post(this.urlApi + '/gubrt', token, this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedStudent = iStaticUtilities.normalizeNames(data);
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: normalizedStudent,
        });
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });

        this.sendNextResult('resultUpdateError', this.res, {
          method: this.getUserByRecoveryToken,
          args: [token],
        });
        this.checkStatusError(error);
      },
    });
  }

  getStudentFinance(behaviorSubject: BehaviorSubject<any>, studentId: number) {
    this.http
      .get(this.urlApi + '/api/student/finance/' + studentId, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getStudentFinance,
            args: [behaviorSubject, studentId],
          });
        },
      });
  }

  create(student: iStudent) {
    this.http
      .post(this.urlApi + '/api/student', student, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [student],
          });
          this.checkStatusError(error);
        },
      });
  }

  update(student: any) {
    this.http
      .put(
        this.urlApi + '/api/student/' + student.id,
        student,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [student],
          });
          this.checkStatusError(error);
        },
      });
  }

  delete(ids: Array<number | string>) {
    let idsObjs = this.convertArrayObject(ids, 'ids');
    this.http
      .delete(this.urlApi + '/api/student', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [ids],
          });
          this.checkStatusError(error);
        },
      });
  }
}
