import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  eEventCalendarType,
  iEventCalendar,
} from '../../Interfaces/Utils/iEventCalendar';
import { EventCalendarHandlerService } from 'src/app/Services/Utils/EventCalendarHandler.service';
import { EventCalendarClass } from '../../Classes/EventCalendarClass';
import {
  DebouncerService,
  GeneralLoaderService,
  StaticUtilitiesService,
  iDate,
  iUnsubscribeDestroy,
  slideNavigationTypes,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';
import {
  EventTypesService,
  iEventTypesToRedirect,
} from 'src/app/Services/Utils/EventTypes.service';
import { LocalStorageHandlerService } from 'src/app/Services/Utils/LocalStorageHandler.service';
import { EventWindowService } from 'src/app/Services/Utils/EventWindow.service';
import { filter, takeUntil } from 'rxjs';
import { PopupService,  } from '@quasar_dynamics/basic-designsystem';
import { LandmarkService } from 'src/app/Services/Api/Landmark.service';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { FormationService } from 'src/app/Services/Api/Formation.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { ClassroomService } from 'src/app/Services/Api/Classroom.service';
import { SessionService } from 'src/app/Services/Api/Session.service';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { ConflictsService } from 'src/app/Services/Utils/Conflicts.service';

@Component({
  selector: 'EventCalendarContainer',
  templateUrl: './EventCalendarContainer.component.html',
  styleUrls: ['./EventCalendarContainer.component.scss'],
})
export class EventCalendarContainerComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  event: iEventCalendar = {
    type: eEventCalendarType.Festivo,
    className: 'festivo',
    id: null,
    startDate: new Date().toISOString(),
    title: '',
  };

  @Output() isSelecting: EventEmitter<boolean> = new EventEmitter<boolean>();

  extraClass: EventCalendarClass = new EventCalendarClass();

  disableDrag: boolean = false;
  mouseDownTime: number = 0;
  mouseUpTime: number = 0;

  constructor(
    private eventWindowSE: EventWindowService,
    private landmarkSE: LandmarkService,
    private generalLoaderSE: GeneralLoaderService,
    private teacherSE: TeacherService,
    private formationSE: FormationService,
    private promotionSE: PromotionFormationService,
    private classroomSE: ClassroomService,
    private sessionSE: SessionService,
    private companySE: CompanyService,
    private popupSE: PopupService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private conflictsSE: ConflictsService
  ) {
    super();
  }

  ngOnInit() {
    this.getDataFromLocalStorage();
    this.getEventType();
    this.extraClass.toggleReadOnly(this.extraClass.canEdit);
  }

  /**
   * HANDLERS
   */

  successCreateHandler(res: iResultHttp) {
    this.generalLoaderSE.removeFromLoaderAmount();
    this.eventWindowSE.reloadLandmarks();
    this.closeEventWindow();
  }

  sucessGetTeacherSelectorHandler(res: iResultHttp) {
    let { data } = res;
    const mappedData = data.map((teacher) => {
      teacher.name = teacher.user.name + ' ' + teacher.user.surname;
      return teacher;
    });
    this.extraClass.teacherSelectorOptions.items = mappedData;
  }

  successGetFormationSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.formationSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successpromotionByFormationIdHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.editionSelectorOptions.items = data.map((promotion) => {
      return { name: promotion.edition, id: promotion.id };
    });
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  GetClassroomSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.placeSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetSessionStateSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.chipSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateLandmarkHandler(res: iResultHttp) {
    const { data } = res;
    this.eventWindowSE.reloadLandmarks();
  }

  successGetCompanySelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.companySelectorOptions.items = data.map((company) => {
      return { id: company.id, name: company.name };
    });
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteLandmarkHandler(res: iResultHttp) {
    const { data } = res;
    localStorage.removeItem('newEvent');
    this.eventWindowSE.close();
    this.eventWindowSE.reloadLandmarks();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */

  checkItemsForSelectorCalls() {
    if (this.hasTeachers(this.event)) {
      this.getTeacherSelector();
    }
    if (this.hasFormation(this.event)) {
      this.getFormationSelector();
    }
    if (
      this.hasEdition(this.event) &&
      this.hasFormation(this.event) &&
      this.event.formation
    ) {
      this.promotionByFormationId(this.event.formation);
    }
    if (this.hasClassroom(this.event)) {
      this.getClassroomSelector();
    }
    if (this.hasStatus(this.event)) {
      if (
        this.event.type === eEventCalendarType.MasterClass ||
        this.event.type === eEventCalendarType.MasterTutoriaTeacher ||
        this.event.type === eEventCalendarType.CursosClases
      ) {
        this.getSessionStateSelector();
      }
    }
    if (this.hasCompany(this.event)) {
      this.getCompanySelector();
    }
  }

  getEventType() {
    return EventCalendarHandlerService.getEventCalendarType(this.event.type);
  }

  setIsSelectingAs(value: boolean) {
    this.isSelecting.emit(value);
  }

  setEditingType() {
    const redirectToEventsArray: iEventTypesToRedirect[] =
      EventTypesService.getEventTypesToRedirect();
    const eventTypeToRedirect = redirectToEventsArray.find(
      (event) => event.type === this.event.type
    );
    if (eventTypeToRedirect) {
      this.extraClass.redirectTo = eventTypeToRedirect;
      this.extraClass.canEdit = false;
      this.extraClass.toggleReadOnly(this.extraClass.canEdit);
    }
  }

  toggleEdit() {
    this.extraClass.canEdit = !this.extraClass.canEdit;
    this.extraClass.toggleReadOnly(this.extraClass.canEdit);
  }

  onInputEditablecontent(event: any, key: string) {
    const content = event.target.innerText;
    const selection: Selection | null = window.getSelection();
    let range: any = null;
    if (selection && selection.rangeCount > 0) {
      range = selection.getRangeAt(0);
    }

    DebouncerService.formDebouncer(
      () => {
        this.event[key] = content;
        if (selection && range) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      },
      key,
      content,
      1000
    );
  }

  changeValue(event, key: string) {
    const content = event.target.innerText;
    this.event[key] = content;
    event.target.innerText = content;
    this.ifIsEditingEdit(content, key);
  }

  onDateChange(event, key) {
    this.event[key] = iDate
      .javascriptConvert(new Date(event))
      .toStringDate('JAP');
    this.ifIsEditingEdit(this.event[key], key);
  }

  getTime(value, key) {
    DebouncerService.formDebouncer(
      () => this.ifIsEditingEdit(value, key),
      key,
      value,
      1000
    );
  }

  ifIsEditingEdit(value, key) {
    if (this.extraClass.isEditing) {
      this.getDataToSendToEdit(value, key);
    }
  }

  getChecked(event: MatCheckboxChange, key: string) {
    this.event[key] = event.checked;
    this.ifIsEditingEdit(event.checked, key);
  }

  redirectTo(redirectTo: iEventTypesToRedirect) {
    if (this.hasEdition(this.event)) {
      this.closeEventWindow();
      this.staticUtilitiesSE.goTo(
        'formaciones/promocion-detalle',
        slideNavigationTypes.slideToBottom,
        { promotion: this.event.edition, typeOf: redirectTo.redirectsTo }
      );
    }
  }

  getDataFromLocalStorage() {
    const data = LocalStorageHandlerService.getItem('newEvent');
    if (data) {
      this.event = data;
      if (this.event.id) {
        this.extraClass.canEdit = false;
        this.extraClass.isEditing = true;
      }
      this.checkItemsForSelectorCalls();
      this.getEventType();
      this.setEditingType();
      this.setData(data);
    }
  }

  setData(data) {
    this.extraClass.ngModels.observations = data.observations;
  }

  getDataToSendToEdit(value, key) {
    if (!this.event.id) return;
    const objectToPass = { id: this.event.id };
    objectToPass[key] = value;
    this.updateLandmark(objectToPass);
  }

  onFormationChange(formationId) {
    if (this.hasEdition(this.event)) {
      this.event.edition = null;
    }
    this.promotionByFormationId(formationId);
    this.ifIsEditingEdit(formationId, 'formation');
  }

  onDeleteLandmark() {
    this.openAssurePopup(this.event.id);
  }

  openAssurePopup(id) {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      elemento: 'el evento',
      accion: 'Eliminar',
    });
    this.popupSE
      .returnData()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        const returnValue = res?.returnValue;
        if (returnValue) this.deleteLandmark(id);
        subject.next('');
        subject.complete();
      });
  }

  closeEventWindow() {
    localStorage.removeItem('newEvent');
    this.eventWindowSE.close();
  }

  createLandmark() {
    this.create(this.event);
  }


  /**
   * CONDITIONS
   */

  hasSubtitle(
    event: iEventCalendar
  ): event is iEventCalendar & { subtitle: string } {
    const typesWithSubtitle2 = [
      eEventCalendarType.MasterClass,
      eEventCalendarType.MasterTutoriaStudent,
      eEventCalendarType.MasterTribunales,
      eEventCalendarType.MasterOtros,
      eEventCalendarType.CursosClases,
      eEventCalendarType.IncompanyClases,
      eEventCalendarType.Alquiler,
      eEventCalendarType.Evento,
      eEventCalendarType.CursosOtros,
      eEventCalendarType.IncompanyOtros,
      eEventCalendarType.MasterTutoriaTeacher,
      eEventCalendarType.MasterTribunalEntregas,
      eEventCalendarType.MasterTribunalStudent,
    ];
    return typesWithSubtitle2.includes(event.type);
  }

  hasStartHour(
    event: iEventCalendar
  ): event is iEventCalendar & { startHour: string } {
    const typesWithStart = [
      eEventCalendarType.MasterClass,
      eEventCalendarType.MasterTutoriaStudent,
      eEventCalendarType.MasterTribunales,
      eEventCalendarType.MasterOtros,
      eEventCalendarType.CursosClases,
      eEventCalendarType.IncompanyClases,
      eEventCalendarType.Alquiler,
      eEventCalendarType.Evento,
      eEventCalendarType.CursosOtros,
      eEventCalendarType.IncompanyOtros,
      eEventCalendarType.MasterTutoriaTeacher,
      eEventCalendarType.IncompanyOtros,
      eEventCalendarType.MasterTribunalEntregas,
      eEventCalendarType.MasterTribunalStudent,
    ];
    return typesWithStart.includes(event.type);
  }

  hasEndHour(
    event: iEventCalendar
  ): event is iEventCalendar & { endHour: string } {
    const typesWithEnd = [
      eEventCalendarType.MasterClass,
      eEventCalendarType.MasterTutoriaStudent,
      eEventCalendarType.MasterTribunales,
      eEventCalendarType.MasterOtros,
      eEventCalendarType.CursosClases,
      eEventCalendarType.CursosOtros,
      eEventCalendarType.IncompanyClases,
      eEventCalendarType.Alquiler,
      eEventCalendarType.Evento,
      eEventCalendarType.IncompanyOtros,
      eEventCalendarType.MasterTribunalEntregas,
      eEventCalendarType.MasterTribunalStudent,
    ];
    return typesWithEnd.includes(event.type);
  }

  hasSede(event: iEventCalendar): event is iEventCalendar & { sede: number } {
    const typesWithSede = [eEventCalendarType.IncompanyClases];
    return typesWithSede.includes(event.type);
  }

  hasClassroom(
    event: iEventCalendar
  ): event is iEventCalendar & { classroom: number } {
    const typesWithClassroom = [
      eEventCalendarType.MasterClass,
      eEventCalendarType.MasterTutoriaStudent,
      eEventCalendarType.MasterTribunales,
      eEventCalendarType.MasterOtros,
      eEventCalendarType.CursosClases,
      eEventCalendarType.IncompanyClases,
      eEventCalendarType.Alquiler,
      eEventCalendarType.Evento,
      eEventCalendarType.MasterTutoriaTeacher,
      eEventCalendarType.CursosOtros,
      eEventCalendarType.IncompanyOtros,
      eEventCalendarType.MasterTribunalStudent,
    ];
    return typesWithClassroom.includes(event.type);
  }

  hasTeachers(event): event is iEventCalendar & { teachers: any[] } {
    const typesWithTeacher = [
      eEventCalendarType.MasterClass,
      eEventCalendarType.MasterTutoriaStudent,
      eEventCalendarType.MasterTribunales,
      eEventCalendarType.MasterOtros,
      eEventCalendarType.CursosClases,
      eEventCalendarType.IncompanyClases,
      eEventCalendarType.Evento,
      eEventCalendarType.MasterTutoriaTeacher,
      eEventCalendarType.CursosOtros,
      eEventCalendarType.IncompanyOtros,
      eEventCalendarType.MasterTribunalStudent,
    ];
    return typesWithTeacher.includes(this.event.type);
  }

  hasCompany(event): event is iEventCalendar & { company: number } {
    const typesWithCompany = [
      eEventCalendarType.IncompanyClases,
      eEventCalendarType.IncompanyOtros,
      eEventCalendarType.Alquiler,
      eEventCalendarType.Evento,
    ];
    return typesWithCompany.includes(this.event.type);
  }

  hasUrl(event): event is iEventCalendar & { url?: string } {
    const typesWithUrl = [
      eEventCalendarType.MasterClass,
      eEventCalendarType.MasterTutoriaStudent,
      eEventCalendarType.CursosClases,
      eEventCalendarType.IncompanyClases,
      eEventCalendarType.MasterTribunalStudent,
    ];
    return typesWithUrl.includes(this.event.type);
  }

  hasStatus(event): event is iEventCalendar & { status?: string } {
    const typesWithStatus = [
      eEventCalendarType.MasterClass,
      eEventCalendarType.MasterTutoriaStudent,
      eEventCalendarType.CursosClases,
      eEventCalendarType.IncompanyClases,
      eEventCalendarType.MasterTutoriaTeacher,
      eEventCalendarType.IncompanyOtros,
    ];
    return typesWithStatus.includes(this.event.type);
  }

  hasAllDay(event): event is iEventCalendar & { allDay: boolean } {
    const typesWithAllDay = [
      eEventCalendarType.MasterTribunales,
      eEventCalendarType.Alquiler,
      eEventCalendarType.Evento,
      eEventCalendarType.MasterTribunalEntregas,
    ];
    return typesWithAllDay.includes(this.event.type);
  }

  hasObservations(event): event is iEventCalendar & { observations?: string } {
    const typesWithState = [
      eEventCalendarType.MasterClass,
      eEventCalendarType.MasterTutoriaStudent,
      eEventCalendarType.MasterTribunales,
      eEventCalendarType.MasterOtros,
      eEventCalendarType.CursosClases,
      eEventCalendarType.IncompanyClases,
      eEventCalendarType.Alquiler,
      eEventCalendarType.Evento,
      eEventCalendarType.CursosOtros,
      eEventCalendarType.IncompanyOtros,
      eEventCalendarType.MasterTutoriaTeacher,
      eEventCalendarType.MasterTribunalEntregas,
      eEventCalendarType.MasterTribunalStudent,
      eEventCalendarType.NoLectivo,
    ];
    return typesWithState.includes(this.event.type);
  }

  hasEndDate(event): event is iEventCalendar & { endDate?: string } {
    const typesWithEndDate = [
      eEventCalendarType.NoLectivo,
      eEventCalendarType.Alquiler,
      eEventCalendarType.Evento,
    ];
    return typesWithEndDate.includes(this.event.type);
  }

  canEditTitle(event): boolean {
    const typesWithEdit = [
      eEventCalendarType.MasterOtros,
      eEventCalendarType.CursosOtros,
      eEventCalendarType.IncompanyOtros,
      eEventCalendarType.Alquiler,
      eEventCalendarType.Evento,
      eEventCalendarType.NoLectivo,
    ];
    return typesWithEdit.includes(this.event.type);
  }

  hasModality(event): event is iEventCalendar & { modality: number } {
    const typesWithModality = [
      eEventCalendarType.MasterTutoriaStudent,
      eEventCalendarType.MasterTribunales,
    ];
    return typesWithModality.includes(this.event.type);
  }

  hasLocation(event): event is iEventCalendar & { location: string } {
    const typesWithLocation = [
      eEventCalendarType.CursosOtros,
      eEventCalendarType.MasterOtros,
      eEventCalendarType.Evento,
      eEventCalendarType.IncompanyOtros,
    ];
    return typesWithLocation.includes(this.event.type);
  }

  hasFormation(event): event is iEventCalendar & { formation: number } {
    const typesWithFormation = [
      eEventCalendarType.MasterClass,
      eEventCalendarType.MasterTutoriaStudent,
      eEventCalendarType.MasterOtros,
      eEventCalendarType.CursosClases,
      eEventCalendarType.IncompanyClases,
      eEventCalendarType.CursosOtros,
      eEventCalendarType.IncompanyOtros,
      eEventCalendarType.MasterTribunalEntregas,
      eEventCalendarType.MasterTribunalStudent,
    ];
    return typesWithFormation.includes(this.event.type);
  }

  hasEdition(event): event is iEventCalendar & { edition: number | null } {
    const typesWithEdition = [
      eEventCalendarType.MasterClass,
      eEventCalendarType.MasterTutoriaStudent,
      eEventCalendarType.MasterOtros,
      eEventCalendarType.CursosClases,
      eEventCalendarType.IncompanyClases,
      eEventCalendarType.CursosOtros,
      eEventCalendarType.IncompanyOtros,
      eEventCalendarType.MasterTribunalEntregas,
      eEventCalendarType.MasterTribunalStudent,
    ];
    return typesWithEdition.includes(this.event.type);
  }

  hasStudent(event): event is iEventCalendar & { student: number } {
    const typesWithStudent = [
      eEventCalendarType.MasterTutoriaStudent,
      eEventCalendarType.MasterTribunalStudent,
    ];
    return typesWithStudent.includes(this.event.type);
  }

  getIcon(
    option:
      | 'info'
      | 'dialog'
      | 'videoCam'
      | 'location'
      | 'school'
      | 'outlinedPencil'
      | 'delete'
      | 'work'
      | 'person'
      | 'penOutside'
      | 'modality'
      | 'formation'
      | 'student'
  ): string {
    const iconSE = IconsHandlerService;
    let icon = '';
    switch (option) {
      case 'info':
        icon = iconSE.getInfoIcon();
        break;
      case 'dialog':
        icon = iconSE.getDialogIcon();
        break;
      case 'videoCam':
        icon = iconSE.getVideoCam();
        break;
      case 'location':
        icon = iconSE.getLocationIcon();
        break;
      case 'school':
        icon = iconSE.getSchoolIcon();
        break;
      case 'outlinedPencil':
        icon = iconSE.getOutlinedPencil();
        break;
      case 'delete':
        icon = iconSE.getDeleteGreyIcon();
        break;
      case 'work':
        icon = iconSE.getWorkIcon();
        break;
      case 'person':
        icon = iconSE.getPersonIcon();
        break;
      case 'penOutside':
        icon = iconSE.getPenOutside();
        break;
      case 'modality':
        icon = iconSE.getModality();
        break;
      case 'formation':
        icon = iconSE.getFormationIcon();
        break;
      case 'student':
        icon = iconSE.getStudentIcon();
        break;

      default:
        break;
    }
    return icon;
  }




  /**
   * API CALLS
   */

  getTeacherSelector() {
    this.teacherSE.getTeachersSelector();
    this.teacherSE
      .getResult()
      .pipe(
        takeUntil(this._unsubInd),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.sucessGetTeacherSelectorHandler(res);
      });
  }

  create(objectToPass: any) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.landmarkSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
          {
            method: () => this.conflictsSE.manageConflicts(res),
            error: true,
          },
        ]);
      });
  }

  getFormationSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    this.formationSE.getFormationsSelectorAll();
    this.formationSE
      .getResult()
      .pipe(
        takeUntil(this._unsubInd2),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.successGetFormationSelectorHandler(res);
        this._unsubInd2.next('');
        this._unsubInd2.complete();
      });
  }

  promotionByFormationId(formationId: number) {
    this.generalLoaderSE.addToLoaderAmount();
    this.promotionSE.getPromotionsByFormationId(formationId);
    this.promotionSE
      .getResultPromotionsByFormationId()
      .pipe(
        takeUntil(this._unsubInd3),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.successpromotionByFormationIdHandler(res);
        this._unsubInd3.next('');
        this._unsubInd3.complete();
      });
  }

  getClassroomSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.classroomSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.GetClassroomSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getSessionStateSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sessionSE.getSessionStateSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetSessionStateSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateLandmark(objectToPass) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.landmarkSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateLandmarkHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
          {
            method: () => this.conflictsSE.manageConflicts(res),
            error: true,
          },

        ]);
      });
  }

  getCompanySelector() {
    const subject = StaticUtilitiesService.createSubject();
    this.generalLoaderSE.addToLoaderAmount();
    this.companySE.getAllCompaniesForFilter();
    this.companySE
      .getResult()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.successGetCompanySelectorHandler(res);
        subject.next('');
        subject.complete();
      });
    this.companySE
      .getResultError()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        StaticUtilitiesService.showError(res.message);
        subject.next('');
        subject.complete();
      });
  }

  deleteLandmark(id) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.landmarkSE.delete(behaviorSubject, id);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteLandmarkHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
