<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>{{modalData.data.accion}} {{modalData.data.elemento}}</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <p>¿Estás seguro que quieres {{modalData.data.accion.toLowerCase()}} {{modalData.data.elemento}}?</p>
    <p>Este paso no tiene vuelta atrás</p>

    En caso afirmativo, por favor rellene el motivo de la pérdida:
    <div class="customArea">
      <customTextArea [(ngModel)]="data.reason"></customTextArea>
    </div>
  </div>
  <div class="footer">
    <btn classList="principalButton" (click)="forceClosePopup()">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn classList="specialButton" (click)="switchTypes()">
      <ng-container>
        {{modalData.data.accion}}
      </ng-container>
    </btn>
  </div>
</div>
