<div class="fullContainer">
  <div class="filtersLine">
    <toggleSubmenu class="secondary" [elements]="['Todas','Pendiente','P. de pago','Pagada','P. de rectificación']"
      [(ngModel)]="extraClass.filterTypeSubmenuSelected"
      (change)="sendTypeToFilter(extraClass.filterTypeSubmenuSelected)"></toggleSubmenu>
    <div class="searchAndFilterButton">
      <customSearcher (change)="getSearch($event)"></customSearcher>
      <btn classList="generalbutton violetButton" (click)="openAddBillPopup()">Añadir factura</btn>
    </div>
  </div>
  <div class="tableContainer">
    <main-table [headerData]="extraClass.headerData" [dataSource]="extraClass.teacherBills"></main-table>
    <ng-container *ngFor="let item of extraClass.teacherBills | paginate:{
      itemsPerPage: extraClass.filtroBill.num_devoluciones,
      currentPage: extraClass.filtroBill.num_pagina,
      totalItems: extraClass.total
    }"></ng-container>

    <pagination-controls [autoHide]="true" previousLabel="Anterior" nextLabel="Siguiente"
      (pageChange)="onPageChange($event)"></pagination-controls>

  </div>
</div>