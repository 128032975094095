import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticUtilitiesService, iResultHttp, iUnsubscribeDestroy, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { StudentService } from 'src/app/Services/Api/Student.service';
import { takeUntil } from 'rxjs';
@Component({
  selector: 'app-AutomatriculaView',
  templateUrl: './AutomatriculaView.component.html',
  styleUrls: ['./AutomatriculaView.component.scss']
})
export class AutomatriculaViewComponent extends iUnsubscribeDestroy implements OnInit {

  constructor(private staticUtilitiesSE: StaticUtilitiesService, private activatedRoute: ActivatedRoute, private studentSE: StudentService) {
    super();
  }

  ngOnInit() {
    this.getTokenFromParams();
    
  }
  getTokenFromParams(){
    let token = this.activatedRoute.snapshot.queryParamMap.get('token')!.replaceAll(' ', '+');
    sessionStorage.setItem('token', token);
  }
  
  goToFormulario() {
    this.staticUtilitiesSE.goTo("/automatricula/form", slideNavigationTypes.slideToLeft, null)
  }

  

}
