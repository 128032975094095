import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopupService, StaticUtilitiesService, iResultHttp, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AutoenrollmentTokenService } from 'src/app/Services/Api/AutoenrollmentToken.service';
import { EnrollmentService } from 'src/app/Services/Api/Enrollment.service';
import { CambiarFormacionPromocionMatriculaPopupComponent } from '../CambiarFormacionPromocionMatricula-Popup/CambiarFormacionPromocionMatricula-Popup.component';

@Component({
  selector: 'app-CursoMasterPreMatricula-Popup',
  templateUrl: './CursoMasterPreMatricula-Popup.component.html',
  styleUrls: ['./CursoMasterPreMatricula-Popup.component.scss']
})
export class CursoMasterPreMatriculaPopupComponent extends iUnsubscribeDestroy implements OnInit {

  constructor(public dialogRef: MatDialogRef<CursoMasterPreMatriculaPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,private autoenrollmentTokenSE:AutoenrollmentTokenService, private enrollmentSE:EnrollmentService, private popupSE:PopupService) {
    super();
  }

  ngOnInit() {
     
  }

    /**
  * FUNCTIONALITY
  */
    closePopup(returnValue?: any) {
      let p = { returnValue: returnValue };
      this.dialogRef.close(p);
    }
    forceClosePopup() {
      this.dialogRef.close();
    }

    reSendEmail() {
      this.enrollmentSE.reSendEmail({enrollmentId: this.modalData['data'].id[0]});
      this.enrollmentSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
        if (!res) return;
        StaticUtilitiesService.showFeedback("Email enviado correctamente");
        this._unsubInd.next("");
        this.forceClosePopup();
      })
      this.enrollmentSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(res => {
        if (!res) return;
        StaticUtilitiesService.showError("Error al enviar el email");
        this._unsub.next("");
      })
    }

    getURLAutoenrollment(){
      const dataFromChild = this.modalData['data']
      let data:{enrollments:number[],email:string} = {
        enrollments: dataFromChild.id,
        email: dataFromChild.mail,
      };
       
      this.autoenrollmentTokenSE.getAutoenrollmentToken(data);
      this.autoenrollmentTokenSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res)=>{
        if(!res){return}
        let {data} = res;
        window.open(data, '_blank');
        this._unsubInd.next('')
      })
      this.autoenrollmentTokenSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
        if(!res){return}
        if(res.status != 401){
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('')
      })
    }


}
