import { environment } from 'src/environments/environment';
import { headerData } from '../Components/main-table/main-table.component';
import { iPendingBillsTeacherFilters } from '../Interfaces/Utils/iPendingBillsTeacherFilters';
import {
  ITeacherPendingBills,
  iTeacherPendingBills,
} from '../Interfaces/Utils/iTeacherPendingBills';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';

export class GestionUsuariosProfesoresDetallesPendienteFacturacionClass {
  constructor(private openEditHonorarioPopup: (any)=> void) {}

  //   dataTeacher
  _dataTeacher: any = null;

  // teacherFullName
  teacherFullName: string = '';

  // IconHandlerService
  IconHandlerService = IconsHandlerService

  // pendingBills
  pendingBills: iTeacherPendingBills[] = [
    ITeacherPendingBills.getEmptyObject(),
  ];

  // filterTypeSubmenuSelected
  filterTypeSubmenuSelected: number = 0;

  //   pendingBillsFilters
  pendingBillsFilters: iPendingBillsTeacherFilters = {
    num_pagina: 1,
    num_devoluciones: environment.numDevolucionesCalls,
  };

  // totalPendingBills
  totalPendingBills: number = 0;

  headerData: headerData = [
    {
      variant: 'bold',
      display: 'Tipo',
      key: 'type',
      className: 'truncateText maxWidth-100',
      width: '100px',
      alignment:'start'
    },
    {
      variant: 'bold',
      display: 'Formación',
      key: 'formation',
      className: 'truncateText maxWidth-200',
      width: '200px',
      alignment:'start'
    },
    {
      variant: 'bold',
      display: 'Concepto',
      key: 'concept',
      className: 'truncateText maxWidth-200',
      width: '200px',
      alignment:'start'
    },
    {
      variant: 'bold',
      display: 'Fecha',
      key: 'date',
      className: 'truncateText maxWidth-150',
      width: '150px',
      alignment: 'center',
      date:{
        date:true,
        pipeTemplate:'dd/MM/yyyy'
      }
    },
    {
      variant: 'bold',
      display: 'Horas totales',
      key: 'totalHours',
      className: 'truncateText maxWidth-100',
      width: '100px',
      alignment: 'center',
    },
    {
      variant: 'bold',
      display: 'Honorario €/h',
      key: 'hourlyFee',
      className: 'truncateText maxWidth-100',
      width: '100px',
      alignment: 'center',
    },
    {
      variant: 'bold',
      display: 'Honorarios',
      key: 'fee',
      className: 'truncateText maxWidth-100',
      width: '100px',
      alignment: 'center',
      euros: true,
    },
    {
      variant:'icons',
      display:'',
      key:'',
      width:'50px',
      icons:[
        {
          action:(row)=>{
            this.openEditHonorarioPopup(row)
          },
          image: this.IconHandlerService.getEditBlueIcon(),
        }
      ]
    }
  ];
}
