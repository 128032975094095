<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="closePopup(null)">close</mat-icon>
    <p >Editar honorario</p>
    <mat-icon (click)="closePopup(null)">close</mat-icon>
  </div>
  <div class="body" >
    <div class="formLine">
      <customInput style="width: 100%;" [options]='hourlyFeeCustomInputOptions' [(ngModel)]="ngModels.hourlyFee" *ngIf="ngModels.hourlyFee !== null" (change)="setFeeToZeroIfEmpty('hourlyFee')"></customInput>
      <customInput style="width: 100%;" [options]='generalFeeCustomInputOptions' [(ngModel)]="ngModels.generalFee" *ngIf="ngModels.hourlyFee === null" (change)="setFeeToZeroIfEmpty('generalFee')" ></customInput>
    </div>
  </div>

  <div class="footer">
    <btn classList="principalButton" (click)="closePopup(null)">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn classList="specialButton" (click)="onFinalizar()">
      <ng-container>
        Finalizar
      </ng-container>
    </btn>
  </div>
</div>
