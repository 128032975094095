import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  PopupService,
  StaticUtilitiesService,
  SubjectGeneratorService,
  iResultHttp,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { SubirArchivoPopupComponent } from 'src/app/Popups/SubirArchivo-Popup/SubirArchivo-Popup.component';
import { GubrtService } from 'src/app/Services/Api/Gubrt.service';

@Component({
  selector: 'Precio',
  templateUrl: './Precio.component.html',
  styleUrls: ['./Precio.component.scss'],
})
export class PrecioComponent extends SubjectGeneratorService implements OnInit {
  @Input() countriesList: any[] = [];
  @Input() dataFromFather: any = null;
  @Input() studentData: any = null;
  @Input() token: any = null;

  @Output() isMetodosDePagoNeeded: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() dataFromStepFive: EventEmitter<any> = new EventEmitter<any>();
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  isDocumentLoading: boolean = false;
  studentDataStepThree: any = null;
  condicionesEspeciales: any = null;
  wayToPay: any = 1;
  file: any = null;
  documentos: any[] = [];
  isDragging: boolean = false;
  precioTotal: number = 0;
  reserva: number = 0;
  prices: any = [
    {
      price: 0,
    },
    {
      price: 0,
    },
    {
      price: 0,
    },
    {
      price: 0,
    },
  ];

  objectToPass: any = {
    enrollment: {},
  };

  listOfPrices: any = [];

  listOfNotas: any = [
    {
      header: 'Nota 1:',
      body: 'Precio asignado al producto que indicamos manualmente (predeterminado el precio base). Documentación - DNI en vigor, Justificante titularidad cuenta bancaria, última nómina.',
    },
    {
      header: 'Nota 2:',
      body: 'Para la opción 1 y 2: envío de email directo si escogen estas opciones con el link a adjuntar documentación.',
    },
    {
      header: 'Nota 3:',
      body: 'Para la opción 1 y 2: envío de email directo si escogen estas opciones con el link a adjuntar documentación.',
    },
  ];

  constructor(private popupSE: PopupService, private gubrtSE: GubrtService) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['countriesList']) {
      if (changes['countriesList'].currentValue) {
        let nacionalidades = changes['countriesList'].currentValue.map(
          (country) => country.ethnicity
        );
        let paises = changes['countriesList'].currentValue.map(
          (country) => country.name
        );
      }
    }
    if (changes['studentData']) {
      if (changes['studentData'].currentValue) {
        this.studentDataStepThree = changes['studentData'].currentValue;
        this.wayToPay = Number(
          this.studentDataStepThree.enrollments[0].wayToPay
        );
        this.documentos = this.studentDataStepThree.user.student.documents;
        this.isDocumentLoading = false;
        this.condicionesEspeciales =
          this.studentDataStepThree.enrollments[0].specialConditions;
        let precios =
          this.studentDataStepThree.prices[0][
            Object.keys(this.studentDataStepThree.prices[0])[0]
          ];
        this.prices = [...precios];
        this.reserva = this.prices[1]?.price;
      }
    }
    if (changes['dataFromFather']) {
      if (changes['dataFromFather'].currentValue) {
        this.wayToPay = changes['dataFromFather'].currentValue.wayToPay;
        this.documentos = changes['dataFromFather'].currentValue.documentos;
      }
    }
    if (changes['token']) {
      if (changes['token'].currentValue) {
        this.objectToPass.token = changes['token'].currentValue.token;
      }
    }
  }

  /**
   * FUNCTIONALITY
   */

  emit() {
    let objectToEmit: any = {
      wayToPay: this.wayToPay,
      documentos: this.documentos,
    };
    this.dataFromStepFive.emit(objectToEmit);
  }
  dragEnter(key) {
    if (key == 'document') this.isDragging = true;
  }
  dragLeave(key) {
    if (key == 'document') this.isDragging = false;
  }
  updateFile(event) {
    let noFormat = this.file.filter(
      (file) => file.file.type !== 'application/pdf'
    );
    let noSize = this.file.filter((file) => file.file.size > 5000000);
    if (noFormat.length > 0) {
      StaticUtilitiesService.showError('Formato de documento no permitido');
      this.file = null;
      return;
    }
    if (noSize.length > 0) {
      StaticUtilitiesService.showError('Archivo demasiado pesado');
      this.file = null;
      return;
    }
    this.documentos = this.file.map((file) => {
      return {
        name: file.file.name,
        url: file.base64,
        student: this.studentDataStepThree.user.student.id,
        typeOf: 'Bancario',
      };
    });
    this.sendToUpdate(this.documentos, 'document', 'student');
    this.isDragging = false;
  }

  uploadDocumento() {
    const unsubSubject = this.createSubject();

    this.popupSE.openPopup(SubirArchivoPopupComponent, {
      accion: 'Subir documentos al profesor',
      formatos: ['application/pdf'],
      size: 10485760,
      multiple: true,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res) => {
        if (res == null) {
          return;
        }

        this.documentos = res.returnValue;
        this.emit();
        this.sendDataToSubject(unsubSubject);
      });
  }
  removeDocumento(documento, index) {
    this.deleteDocument([documento.id]);
    this.emit();
  }

  sendToUpdate(value, key, option) {
    switch (option) {
      case 'enrrollment':
        this.objectToPass.enrollment.wayToPay = value.value;
        this.studentDataStepThree.enrollments.forEach((element) => {
          this.objectToPass.enrollment.id = element.id;
          this.update();
          delete this.objectToPass.enrollment.id;
        });
        // this.sendIsNeededMetodoDePago();
        break;
      case 'student':
        delete this.objectToPass.enrollment;
        this.objectToPass.student = {};
        this.objectToPass.student.documents = value;
        this.subirDocumento(value);
        break;
      default:
        break;
    }
  }

  sendIsNeededMetodoDePago(paymentModalityId) {
    this.wayToPay = paymentModalityId;
    if (paymentModalityId === 1) {
      this.isMetodosDePagoNeeded.emit(true);
    } else {
      this.isMetodosDePagoNeeded.emit(false);
    }
    this.emit();
  }

  /**
   * API CALLS
   */

  update() {
    const unsubSubject = this.createSubject();
    this.gubrtSE.editGubrtOldVersion(this.objectToPass);
    this.gubrtSE
      .getResultUpdate()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        //  this.refresh.emit(true);
        this.sendDataToSubject(unsubSubject);
      });
    this.gubrtSE
      .getResultUpdateError()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          window.open('/', '_self');
          StaticUtilitiesService.showError(
            'No tienes permisos para estar aquí'
          );
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this.sendDataToSubject(unsubSubject);
      });
  }
  deleteDocument(ids: number[]) {
    let objectToPass: any = {
      token: this.token,
      ids: ids,
    };
    this.isDocumentLoading = true;

    const unsubSubject = this.createSubject();
    const deleteDocument = this.gubrtSE.deleteGubrt(objectToPass);
    deleteDocument.succes.pipe(takeUntil(unsubSubject)).subscribe((res) => {
      if (!res) return;
      StaticUtilitiesService.showFeedback('Documento eliminado correctamente');
      this.refresh.emit(true);
      this.isDocumentLoading = false;
      this.sendDataToSubject(unsubSubject);
    });
    deleteDocument.error.pipe(takeUntil(unsubSubject)).subscribe((res) => {
      if (!res) return;
      StaticUtilitiesService.apiErrorHandler(res.status);
      this.isDocumentLoading = false;
      window.open('/', '_self');
      StaticUtilitiesService.showError('No tienes permisos para estar aquí');
      this.sendDataToSubject(unsubSubject);
    });
  }
  subirDocumento(data) {
    this.isDocumentLoading = true;
    let objectToPass: any = {
      token: this.token,
      document: data,
    };
    const unsubSubject = this.createSubject();
    this.gubrtSE.postGubrtOldVersion(objectToPass);
    this.gubrtSE
      .getResultUpdate()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showFeedback('Documento subido correctamente');
        this.refresh.emit(true);
        this.sendDataToSubject(unsubSubject);
      });
    this.gubrtSE
      .getResultUpdateError()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          window.open('/', '_self');
          StaticUtilitiesService.showError(
            'No tienes permisos para estar aquí'
          );
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this.sendDataToSubject(unsubSubject);
      });
  }
}
