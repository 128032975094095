import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { StaticUtilitiesService, iResultHttp } from '@quasar-dynamics/basic-designsystem';
import { PopupService, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { PersonalizarColumnasPopupComponent } from 'src/app/Popups/PersonalizarColumnas-Popup/PersonalizarColumnas-Popup.component';
import { AutoenrollmentTokenService } from 'src/app/Services/Api/AutoenrollmentToken.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'Incompanies-List',
  templateUrl: './Incompanies-List.component.html',
  styleUrls: ['./Incompanies-List.component.scss']
})
export class IncompaniesListComponent extends iUnsubscribeDestroy implements OnInit {
  @Input() incompanies: Array<any> = [];
  @Input() filters: any = {};
  @Input() total: number = 0;
  @Input() navigateActive: boolean = true;
  @Input() changePage: boolean = false;
  @Input() openPrerferencesAlumnoPopup: boolean = false;
  @Input() downloadExcelFile: boolean = false;
  @Input() isDataLoaded: boolean = false;
  @Output() selectedAlumnos = new EventEmitter<any>();
  @Output() changeAlumnoBooleanToFalse = new EventEmitter<any>();
  @Output() changeExcelBooleanToFalse = new EventEmitter<any>();
  
  // Pagination
  currentPage: number = 1;
  incompaniesSelected: Array<any> = [];
  preferences: any = [];
  allSelected: boolean = false;
  filtros: Array<any> = [
    { position: 1, label: "Nombre", realFilter: "nombre", checked: true },
    { position: 2, label: "Fase", realFilter: "phase", checked: true },
    { position: 3, label: "Formacion", realFilter: "formacion", checked: true },
    { position: 4, label: "Empresa", realFilter: "empresa", checked: true },
    { position: 5, label: "Estado", realFilter: "state", checked: true },
    { position: 6, label: "Última actualización", realFilter: "lastStateUpdate", checked: true },
  ];

  displayedColumns: any[] = [
    { displayName: "Nombre", property: 'name' },
    { displayName: "Fase", property: 'phase' },
    { displayName: "Empresa", property: 'empresa' },
    { displayName: "Formacion", property: 'formacion' },
    { displayName: "Última actualización", property: 'lastStateUpdate' },
    { displayName: "Estado", property: 'state' },
  ];

  dataSource: Array<any> = [];
  disabledColumns: string[] = []
  constructor(private popupSE:PopupService, private autoenrollmentTokenSE:AutoenrollmentTokenService) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
     
    if (changes['downloadExcelFile']) {
      if (changes['downloadExcelFile'].currentValue) {
        this.exportexcel();
      }
    }
    if(changes['total']){
       
    }
  }

  addSelected(formacion) {
    !this.incompaniesSelected.includes(formacion) ? this.incompaniesSelected.push(formacion) : this.incompaniesSelected.splice(this.incompaniesSelected.indexOf(formacion), 1);
    this.incompaniesSelected.length == this.incompanies.length ? this.allSelected = true : this.allSelected = false;
    this.selectedAlumnos.emit(this.incompaniesSelected);
  }
  selecteAll(event) {
    if (event.checked) {
      this.incompaniesSelected = [...this.incompanies]
      this.allSelected = true;
    } else {
      this.incompaniesSelected = [];
      this.allSelected = false;
    }
    this.selectedAlumnos.emit(this.incompaniesSelected);
  }

  checkUncheckedColumns(res) {
    res.map(element => {
      if (!element.checked) {
        Array.from(document.querySelectorAll(".mat-column-" + element.realFilter)).map(column => {
          (column as HTMLElement).style.display = "none"
        })
      } else {
        Array.from(document.querySelectorAll(".mat-column-" + element.realFilter)).map(column => {
          (column as HTMLElement).style.display = "revert"
        })
      }
    })
  }

  onRowClick(matriculacion){
    let objectToPass:{enrollments:number[],email:string} = {
      enrollments:[matriculacion.id],
      email:matriculacion.student.user.email
    };
    this.getAutoenrollmentLink(objectToPass);
  }

    // Descargar excel

    exportexcel(): void {
      let table = document.createElement("table");
      table.append((document.querySelector("table thead") as HTMLElement).cloneNode(true));
      table.querySelectorAll("thead th mat-icon").forEach((element) => {
        element.remove();
      });
      table.querySelector("thead th:first-of-type")?.remove();
      let rows = Array.from(document.querySelectorAll("table tbody tr "));
       
      let filteredRows = rows.filter((row) => {
        if(row.className === 'loaderLine' ) return null;
        return row.querySelector('mat-checkbox')!.getAttribute('ng-reflect-checked') === "true";
      });
      if (filteredRows.length > 0) {
        filteredRows.forEach((row) => {
          let newRow = row.cloneNode(true) as HTMLElement;
          newRow.querySelector('td:first-of-type')?.remove();
          table.append(newRow);
        });
      } else {
        table.append((document.querySelector("table tbody") as HTMLElement).cloneNode(true));
        table.querySelectorAll("tbody tr td:first-of-type").forEach((element) => {
          element.remove();
        });
      }
      /* pass here the table id */
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, 'Incompanies.xlsx');
    }
  
    /**
     * API CALLS
     */

    getAutoenrollmentLink(data){
      this.autoenrollmentTokenSE.getAutoenrollmentToken(data);
      this.autoenrollmentTokenSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
        if(!res){return}
        let {data} = res;
        window.open(data,'_blank');
        this._unsubInd.next('')
      })
      this.autoenrollmentTokenSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
        if(!res){return}
        if(res.status != 401){
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('')
      })
    }
  

}
