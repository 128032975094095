<div class="fullSection">
  <div class="subColumnLong">
    <label for="selector">Buscador de empresas</label>
    <selector [options]="selectorOptionsCompany" ngDefaultControl (change)=" emitData()" [(ngModel)]="selectorCompany" (change)="selectedUser($event); emitData()"></selector>
  </div>
  <div class="rowFirstSection">
    <CustomInput [readOnly]="isSelectedCompany" [placeHolder]="'Nombre'" ngDefaultControl (change)=" emitData()" [(ngModel)]="data.name" [label]="'Nombre'"></CustomInput>
    <CustomInput [readOnly]="isSelectedCompany" [placeHolder]="'CIF'" ngDefaultControl (change)=" emitData()" [(ngModel)]="data.cif" [label]="'CIF'"></CustomInput>
  </div>
  
  <div class="rowFirstSection">
    <CustomInput [readOnly]="isSelectedCompany" [placeHolder]="'Persona de contacto'" ngDefaultControl (change)=" emitData()" [(ngModel)]="data.contactWith" [label]="'Persona de contacto'"></CustomInput>
    <div class="telefonoDeContacto">
      <div class="subColumn">
        <label for="selector">Telefono de contacto</label>
        <CountrySelector ngDefaultControl [country]="data.phonePrefix" (emitCountry)="addPhonePrefix($event)" [(ngModel)]="data.phonePrefix" *ngIf="!isSelectedCompany"></CountrySelector>
        <CustomInput class="width100" [readOnly]="isSelectedCompany" ngDefaultControl (change)=" emitData()" [(ngModel)]="data.dataFromParent.phonePrefix"*ngIf="isSelectedCompany"></CustomInput>
      </div>
      <div class="inputDiv">
        <CustomInput [readOnly]="isSelectedCompany" [placeHolder]="'Teléfono'" ngDefaultControl (change)=" emitData()" [(ngModel)]="data.phone"></CustomInput>
      </div>
    </div>
  </div>
  <div class="longInput">
    <CustomInput [readOnly]="isSelectedCompany" [placeHolder]="'Correo electrónico de contacto'" ngDefaultControl (change)=" emitData()" [(ngModel)]="data.email" [label]="'Correo electrónico de contacto'"></CustomInput>
  </div>
</div>