
export interface iPromotionBill {
  bills: Bill[];
  teachers: Teacher[];
}
interface Bill {
  id: number;
  teacher: string;
  concept: string;
  amount: number;
  number: string;
  date: string | null;
  paymentDate: string | null;
  billState: string;
  billStateColor: string;
  document: string;
}
interface Teacher {
  name: string;
}


export class IPromotionBill {
  static getEmptyObject(): iPromotionBill {
    return {
      bills: [
        {
          id: 0,
          teacher: "",
          concept: "",
          amount: 0,
          number: "",
          date: "",
          paymentDate: "",
          billState: "",
          billStateColor: "",
          document: "",
        }
      ],
      teachers: [
        {
          name: "",
        }
      ],
    };
  }
}