import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'clearable-date',
  templateUrl: './clearable-date.component.html',
  styleUrls: ['./clearable-date.component.scss']
})
export class ClearableDateComponent implements OnInit {

  @Output() OnXClick:EventEmitter<any> = new EventEmitter<any>();

  @Input()showX:boolean = true;

  constructor() { }

  ngOnInit() {
  }

  sendOnXClick(){
    this.OnXClick.emit(true);
  }

}
