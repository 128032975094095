import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
} from '@quasar_dynamics/basic-designsystem';

@Injectable({
  providedIn: 'root',
})
export class AutoenrollmentTokenService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAutoenrollmentToken(data) {
    this.http
      .post(this.urlApi + '/api/autoenrollment/token', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.getAutoenrollmentToken,
            args: [data],
          });
          this.checkStatusError(error);
        },
      });
  }
}
