import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ClassroomService } from 'src/app/Services/Api/Classroom.service';
import { CourseService } from 'src/app/Services/Api/Course.service';
import { LandmarkService } from 'src/app/Services/Api/Landmark.service';
import { MasterService } from 'src/app/Services/Api/Master.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { LocalStorageHandlerService } from 'src/app/Services/Utils/LocalStorageHandler.service';
import { CalendarFiltersPopupClass } from 'src/app/Shared/Classes/CalendarFiltersPopupClass';

@Component({
  selector: 'CalendarFilters-Popup',
  templateUrl: './CalendarFilters-Popup.component.html',
  styleUrls: ['./CalendarFilters-Popup.component.scss'],
})
export class CalendarFiltersPopupComponent implements OnInit {
  extraClass: CalendarFiltersPopupClass = new CalendarFiltersPopupClass();

  constructor(
    public dialogRef: MatDialogRef<CalendarFiltersPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private landmarkSE: LandmarkService,
    private generalLoaderSE: GeneralLoaderService,
    private teacherSE: TeacherService,
    private classroomSE: ClassroomService,
    private masterSE: MasterService,
    private courseSE: CourseService,
    private promotionSE: PromotionFormationService
  ) {}

  ngOnInit() {
    this.getDataFromLocalStorage();
    this.setClassListToPopup();
    this.getSelectorTypes();
    this.teacherSelector();
    this.classroomSelector();
    this.masterSelector();
    this.getCourseSelector();
  }

  /**
   * HANDLERS
   */

  successGetSelectorTypesHandler(res: iResultHttp) {
    const { data } = res;
    // Filtramos los tipos de eventos que no queremos mostrar
    this.extraClass.mainCalendarioSelectorOptions.items = data.filter(
      (type) =>
        type.id !== 1 && type.id !== 6 && type.id !== 7 && type.id !== 10
    );
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successTeacherSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.profesorSelectorOptions.items = data.map((teacher) => {
      return {
        ...teacher,
        name: teacher.user.name + ' ' + teacher.user.surname,
      };
    });
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successClassroomSlectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.aulaSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successMasterSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.formacionmasterSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetCourseSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.formacioncourseSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetPromotionSelectorByMasterOrCourseIdHandler(
    res: iResultHttp,
    selectorArrayName,
    index
  ) {
    const { data } = res.data;
    this.extraClass.selectorOptionsArrayContainer[selectorArrayName][
      index
    ].items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   *
   * FUNCTIONALITY
   */

  getDataFromLocalStorage() {
    const calendarFilters =
      LocalStorageHandlerService.getEncodedItem('calendarFilters');
    const calendarFiltersArray = LocalStorageHandlerService.getEncodedItem(
      'calendarFiltersArray'
    );
    if (calendarFilters) {
      this.extraClass.ngModels = calendarFilters;
    } 
    if (calendarFiltersArray) {
      this.extraClass.selectorOptionsArrayContainer = calendarFiltersArray;
    }
  }

  setClassListToPopup() {
    // Hacemos esto para poder poner el modal con el background en transparente y no influir en el resto de modales
    const thisPopup: Element | null = document.querySelector(
      'mat-dialog-container'
    );
    thisPopup?.classList.add('CalendarFilters-Popup');
  }

  applyFilters() {
    LocalStorageHandlerService.setEncodedItem(
      'calendarFilters',
      this.extraClass.ngModels
    );
    LocalStorageHandlerService.setEncodedItem(
      'calendarFiltersArray',
      this.extraClass.selectorOptionsArrayContainer
    );

    const filters: any = {
      types: [],
      teachers: [],
      classrooms: [],
      promotions: [],
    };

    const addFilters = (key, value) => {
      if (value.length > 0) {
        filters[key] = value;
      }
    };

    const addPromotionsToFilters = (promotionsArray) => {
      const promotions = promotionsArray
        .filter((promotion) => promotion !== null)
        .flat();
      if (promotions.length > 0) {
        filters['promotions'] = filters['promotions']
          ? [...filters['promotions'], ...promotions]
          : [...promotions];
      }
    };

    addFilters('types', this.extraClass.ngModels.events);
    addFilters('teachers', this.extraClass.ngModels.teachers);
    addFilters('classrooms', this.extraClass.ngModels.classrooms);
    addPromotionsToFilters(
      this.extraClass.ngModels.selectedcourseNgModelsArray
    );
    addPromotionsToFilters(
      this.extraClass.ngModels.selectedmasterNgModelsArray
    );

    this.closePopup(filters);
  }

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  getEventTypes(event) {}

  getSelectedItems(selectedItems, itemType) {
    if (selectedItems.length === 0)
      this.extraClass.ngModels[`selected${itemType}NgModelsArray`] = [];
    // Limpiamos el array que contiene los selectorOptions del tipo seleccionado
    this.extraClass.selectorOptionsArrayContainer[
      `selected${itemType}SelectorOptionsArray`
    ] = [];
    // Iteramos en los elementos que recogemos desde el selector de tipo de formación
    selectedItems.forEach((itemId, index) => {
      // Buscamos el item seleccionado en el array de items del selector de tipo de formación
      const item = this.extraClass[
        `formacion${itemType}SelectorOptions`
      ].items.find((item) => item.id === itemId);
      // Creamos un selectorOptions con la información del item seleccionado
      const selectorOptions = { ...this.extraClass.templateSelectorOptions };
      selectorOptions.label = item.name;
      selectorOptions.placeholder = `Selecciona una promoción`;
      // Añadimos un elemento al array de ngModels para poder recoger los valores seleccionados
      this.extraClass.ngModels[`selected${itemType}NgModelsArray`].push(null);
      // Añadimos el selectorOptions al array de selectorOptions del tipo seleccionado
      this.extraClass.selectorOptionsArrayContainer[
        `selected${itemType}SelectorOptionsArray`
      ].push(selectorOptions);
      // Hacemos la llamada al servicio para obtener las promociones del item seleccionado
      this.getPromotionSelectorByMasterOrCourseId(
        itemId,
        itemType,
        `selected${itemType}SelectorOptionsArray`,
        index
      );
    });
  }

  onClearFilters() {
    // Limpiamos los ngModels de los selectores
    for (const key in this.extraClass.ngModels) {
      if (Object.prototype.hasOwnProperty.call(this.extraClass.ngModels, key)) {
        if (Array.isArray(this.extraClass.ngModels[key])) {
          this.extraClass.ngModels[key] = [];
        } else {
          this.extraClass.ngModels[key] = null;
        }
      }
    }
    // Limpiamos los arrays de selectorOptions
    for (const key in this.extraClass.selectorOptionsArrayContainer) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.extraClass.selectorOptionsArrayContainer,
          key
        )
      ) {
        this.extraClass.selectorOptionsArrayContainer[key] = [];
      }
    }

    LocalStorageHandlerService.setEncodedItem('calendarFilters', {
      ...this.extraClass.ngModels,
      events: this.extraClass.mainCalendarioSelectorOptions.items.map(
        (event) => event.id
      ),
    });
    LocalStorageHandlerService.setEncodedItem(
      'calendarFiltersArray',
      this.extraClass.selectorOptionsArrayContainer
    );
  }

  /**
   * TRACKBY
   */

  trackByMaster(index: number, item: any) {
    return item.id;
  }

  trackByCourse(index: number, item: any) {
    return item.id;
  }

  /**
   * API CALLS
   */

  getSelectorTypes() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.landmarkSE.getSelectorTypes(behaviorSubject, { all: true });
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetSelectorTypesHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  teacherSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    this.teacherSE.getTeachersSelector();
    this.teacherSE
      .getResult()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.successTeacherSelectorHandler(res);
        subject.next('');
      });
    this.teacherSE
      .getResultError()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        subject.next('');
      });
  }

  classroomSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.classroomSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successClassroomSlectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  masterSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    this.masterSE.getMastersForFilter();
    this.masterSE
      .getResult()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.successMasterSelectorHandler(res);
        subject.next('');
      });
    this.masterSE
      .getResultError()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        subject.next('');
      });
  }

  getCourseSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.courseSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetCourseSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getPromotionSelectorByMasterOrCourseId(
    formationTypeofId,
    formationTypeOf: 'master' | 'course',
    selectorArrayName: string,
    index: number
  ) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.getPromotionsByMasterOrCourseId(
      behaviorSubject,
      formationTypeofId,
      { formationTypeOf: formationTypeOf }
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successGetPromotionSelectorByMasterOrCourseIdHandler(
                res,
                selectorArrayName,
                index
              ),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
