import { Component, OnInit } from '@angular/core';
import {
  PopupService,
  iResultHttp,
  iUnsubscribeDestroy,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { SubirArchivoPopupComponent } from 'src/app/Popups/SubirArchivo-Popup/SubirArchivo-Popup.component';
import { StudentService } from 'src/app/Services/Api/Student.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-GestionUsuarios-Alumnos-Detalles',
  templateUrl: './GestionUsuarios-Alumnos-Detalles.component.html',
  styleUrls: ['./GestionUsuarios-Alumnos-Detalles.component.scss'],
})
export class GestionUsuariosAlumnosDetallesComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  idAlumno: number = 0;
  isDataLoaded: boolean = false;
  optionSubmenuSelected: number = 0;
  dataStudent: any = null;
  constructor(
    private staticUtilities: StaticUtilitiesService,
    private studentSE: StudentService,
    private popupSE: PopupService,
    private userSE: UserService
  ) {
    super();
  }
  override ngOnDestroy(): void {
    localStorage.removeItem('mensaje');
  }

  ngOnInit() {
    if (this.staticUtilities.getParams().alumno == undefined) {
      this.staticUtilities.goTo(
        '/alumnos/mis-alumnos/listado-alumnos',
        slideNavigationTypes.slideToBottom
      );
      StaticUtilitiesService.showError('No se ha encontrado el alumno');
    } else {
      this.idAlumno = Number(this.staticUtilities.getParams().alumno);
      this.getAlumnoById();
    }
  }

  /**
   * FUNCTIONALITY
   */

  refresh(event) {
    if (event) {
      this.getAlumnoById();
    }
  }

  editImage() {
    this.popupSE.openPopup(SubirArchivoPopupComponent, {
      multiple: false,
      formatos: ['image/png', 'image/jpeg', 'image/jpg'],
      size: 5000000,
      accion: 'Añadir imagen de perfil',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }

        let objToPass: any = {
          id: this.dataStudent.user.id,
          photo: res['returnValue'][0].base64,
        };
        this.updatePhoto(objToPass);
        this._unsubInd2.next('');
      });
  }
  irAEditarAlumno(id) {
    this.staticUtilities.goTo(
      `/alumnos/mis-alumnos/crear-alumno/`,
      slideNavigationTypes.slideToTop,
      { isEdit: true, idStudent: id }
    );
  }
  onToggleChange(event) {
    setTimeout(() => {
      this.optionSubmenuSelected = Number(event);
    }, 1);
  }

  /**
   * API CALLS
   */

  getAlumnoById() {
    this.studentSE.getSingle(this.idAlumno);
    this.studentSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;
        data.user.addresses.forEach((address) => {
          if (address.name == 'Dirección personal') {
            data.user.address = address;
          }
          if (address.name == 'Dirección fiscal') {
            data.user.bankData = {
              ...data.user.bankData,
              fiscalAddress: address,
            };
          }
        });

        localStorage.setItem(
          'mensaje',
          `Ficha de alumno: ${data.user.name} ${data.user.surname}`
        );
        this.dataStudent = data;
        this.isDataLoaded = true;
        this._unsubInd.next('');
      });
    this.studentSE
      .getResultIndividualError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.staticUtilities.goTo('', slideNavigationTypes.slideToRight);
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  updatePhoto(data) {
    this.userSE.update(data);
    this.userSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Imagen actualizada correctamente');
        this.getAlumnoById();
        this._unsubInd3.next('');
      });
    this.userSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
