import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  StaticUtilitiesService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import {
  iEnrollment,
  iEnrollmentFilter,
} from 'src/app/Shared/Interfaces/iEnrollment';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  protected _EnrollmentPhases = new BehaviorSubject<any>(null);
  getResultEnrollmentPhases() {
    return this._EnrollmentPhases;
  }
  clearResultEnrollmentPhases() {
    this._EnrollmentPhases.next(null);
  }
  protected _EnrollmentPhasesError = new BehaviorSubject<any>(null);
  getResultEnrollmentPhasesError() {
    return this._EnrollmentPhasesError;
  }
  clearResultEnrollmentPhasesError() {
    this._EnrollmentPhasesError.next(null);
  }

  protected _GenerateAutoEnrollment = new BehaviorSubject<any>(null);
  getResultGenerateAutoEnrollment() {
    return this._GenerateAutoEnrollment;
  }
  clearResultGenerateAutoEnrollment() {
    this._GenerateAutoEnrollment.next(null);
  }

  protected _GetEnrollmentsIncompaniesList = new BehaviorSubject<any>(null);
  getResultGetEnrollmentsIncompaniesList() {
    return this._GetEnrollmentsIncompaniesList;
  }
  clearResultGetEnrollmentsIncompaniesList() {
    this._GetEnrollmentsIncompaniesList.next(null);
  }
  protected _GetEnrollmentsIncompaniesListError = new BehaviorSubject<any>(
    null
  );
  getResultGetEnrollmentsIncompaniesListError() {
    return this._GetEnrollmentsIncompaniesListError;
  }
  clearResultGetEnrollmentsIncompaniesListError() {
    this._GetEnrollmentsIncompaniesListError.next(null);
  }

  protected _GetEnrollmentsIncompaniesThirdColumn = new BehaviorSubject<any>(
    null
  );
  getResultGetEnrollmentsIncompaniesThirdColumn() {
    return this._GetEnrollmentsIncompaniesThirdColumn;
  }
  clearResultGetEnrollmentsIncompaniesThirdColumn() {
    this._GetEnrollmentsIncompaniesThirdColumn.next(null);
  }
  protected _GetEnrollmentsIncompaniesThirdColumnError =
    new BehaviorSubject<any>(null);
  getResultGetEnrollmentsIncompaniesThirdColumnError() {
    return this._GetEnrollmentsIncompaniesThirdColumnError;
  }
  clearResultGetEnrollmentsIncompaniesThirdColumnError() {
    this._GetEnrollmentsIncompaniesThirdColumnError.next(null);
  }

  protected _ConfirmEnrollmentsMasterCourse = new BehaviorSubject<any>(null);
  getResultConfirmEnrollmentsMasterCourse() {
    return this._ConfirmEnrollmentsMasterCourse;
  }
  clearResultConfirmEnrollmentsMasterCourse() {
    this._ConfirmEnrollmentsMasterCourse.next(null);
  }
  protected _ConfirmEnrollmentsMasterCourseError = new BehaviorSubject<any>(
    null
  );
  getResultConfirmEnrollmentsMasterCourseError() {
    return this._ConfirmEnrollmentsMasterCourseError;
  }
  clearResultConfirmEnrollmentsMasterCourseError() {
    this._ConfirmEnrollmentsMasterCourseError.next(null);
  }

  getAll(queryParams: iEnrollmentFilter) {
    this.http
      .get(
        this.urlApi + '/api/enrollment' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedEnrollment = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedEnrollment, total: data.total },
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [queryParams],
          });
          this.checkStatusError(error);
        },
      });
  }

  getEnrollmentsIncompaniesList(queryParams: iEnrollmentFilter) {
    this.http
      .get(
        this.urlApi +
          '/api/enrollment/incompany' +
          this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedEnrollment = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedEnrollment, total: data.total },
          });
          this.sendNextObservable(
            this._GetEnrollmentsIncompaniesList,
            this.res
          );
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._GetEnrollmentsIncompaniesListError,
            this.res,
            true,
            { method: this.getEnrollmentsIncompaniesList, args: [queryParams] }
          );
          this.checkStatusError(error);
        },
      });
  }

  getEnrollmentPhases(queryParams: any) {
    this.http
      .get(
        this.urlApi + '/api/enrollment/phases' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedEnrollment = data;
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedEnrollment,
          });
          this.sendNextObservable(this._EnrollmentPhases, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._EnrollmentPhasesError, this.res, true, {
            method: this.getEnrollmentPhases,
            args: [queryParams],
          });
          this.checkStatusError(error);
        },
      });
  }

  getEnrollmentIncompaniesPhases(queryParams: any) {
    this.http
      .get(
        this.urlApi +
          '/api/enrollment/incompany/phases' +
          this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedEnrollment =
            iStaticUtilities.normalizeNamesCollection(data);

          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedEnrollment,
          });
          this.sendNextObservable(
            this._GetEnrollmentsIncompaniesThirdColumn,
            this.res
          );
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._GetEnrollmentsIncompaniesThirdColumnError,
            this.res,
            true,
            { method: this.getEnrollmentIncompaniesPhases, args: [queryParams] }
          );
          this.checkStatusError(error);
        },
      });
  }

  getFundaeSelector(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/enrollment/fundae/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getFundaeSelector,
            args: [behaviorSubject],
          });
        },
      });
  }

  getEnnrollmentPDF(behaviorSubject: BehaviorSubject<any>, enrollmentId) {
    this.http
      .get(
        `${this.urlApi}/api/enrollment/pdf/${enrollmentId}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getEnnrollmentPDF,
            args: [behaviorSubject, enrollmentId],
          });
        },
      });
  }

  getEnrollmentData(behaviorSubject: BehaviorSubject<any>, enrollmentId) {
    this.http
      .get(
        `${this.urlApi}/api/enrollment/data/${enrollmentId}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getEnrollmentData,
            args: [behaviorSubject, enrollmentId],
          });
        },
      });
  }

  sendEnnrollmentPDF(
    behaviorSubject: BehaviorSubject<any>,
    data: { enrollmentId: number; email: string }
  ) {
    this.http
      .post(`${this.urlApi}/api/enrollment/send`, data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.sendEnnrollmentPDF,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  getEnrollmentStateSelector(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/enrollment/state/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getEnrollmentStateSelector,
            args: [behaviorSubject],
          });
        },
      });
  }

  getSingle(id: number) {
    this.http
      .get(this.urlApi + '/api/enrollment/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedEnrollment = iEnrollment.convertFromObject(
            iStaticUtilities.normalizeNames(data)
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedEnrollment,
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  create(enrollment: any) {
    this.http
      .post(this.urlApi + '/api/enrollment', enrollment, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [enrollment],
          });
          this.checkStatusError(error);
        },
      });
  }

  createEnrollmentIncompany(enrollment: any) {
    this.http
      .post(
        this.urlApi + '/api/enrollment/incompany',
        enrollment,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [enrollment],
          });
          this.checkStatusError(error);
        },
      });
  }

  generateAutoenrollment(email: any) {
    this.http
      .post(this.urlApi + '/generate/autoenrollment', email, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextObservable(this._GenerateAutoEnrollment, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._GenerateAutoEnrollment,
            this.res,
            true,
            { method: this.generateAutoenrollment, args: [email] }
          );
          this.checkStatusError(error);
        },
      });
  }

  reSendEmail(enrollment: any) {
    this.http
      .post(this.urlApi + '/api/enrollment/email', enrollment, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.reSendEmail,
            args: [enrollment],
          });
          this.checkStatusError(error);
        },
      });
  }

  sendPaymentEmail(enrollment: iEnrollment) {
    let enrollmentId = enrollment.id[0];
    this.http
      .post(
        this.urlApi + '/api/enrollment/payment/email/' + enrollmentId,
        {},
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.sendPaymentEmail,
            args: [enrollment],
          });
          this.checkStatusError(error);
        },
      });
  }

  update(enrollment: any) {
    this.http
      .put(
        this.urlApi + '/api/enrollment/' + enrollment.id,
        enrollment,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [enrollment],
          });
          this.checkStatusError(error);
        },
      });
  }

  updateIncompany(enrollment: any) {
    this.http
      .put(
        this.urlApi + '/api/enrollment/incompany/' + enrollment.id,
        enrollment,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.updateIncompany,
            args: [enrollment],
          });
          this.checkStatusError(error);
        },
      });
  }

  validateIncompany(id: any) {
    this.http
      .put(
        this.urlApi + '/api/enrollment/incompany/validate/' + id,
        null,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.validateIncompany,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  updatePhase(behaviorSubject: BehaviorSubject<any>, enrollment: any) {
    this.http
      .put(this.urlApi + '/api/enrollment/phase', enrollment, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.updatePhase,
            args: [behaviorSubject, enrollment],
          });
        },
      });
  }

  confirmEnrollmentMasterCourse(enrollment: iEnrollment) {
    this.http
      .put(
        this.urlApi + '/api/enrollment/confirm',
        enrollment,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.updatePhase,
            args: [enrollment],
          });
          this.checkStatusError(error);
        },
      });
  }

  delete(ids: Array<number | string>) {
    let idsObjs = this.convertArrayObject(ids, 'ids');
    this.http
      .delete(this.urlApi + '/api/enrollment', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [ids],
          });
          this.checkStatusError(error);
        },
      });
  }
}
