<article
  class="baseContainer"
  ngClass="baseContainer--{{bgColor}}"
  [ngClass]="{
    'baseContainer--white': bgColor === 'white',
    'baseContainer--gray': bgColor === 'gray',
    'baseContainer--fullHeight': fullHeight,
    'baseContainer--editMode': isEditing
  }"
>
  <div class="baseContainer__header">
    <h2 class="baseContainer__title">{{title}}</h2>

    <ng-container *ngIf=" this.componentOptions">
      <div class="componentDiv display-flex" appAnchor ></div>
    </ng-container>

    <button (click)="handleClickedEdit()" *ngIf="canEdit">
      <mat-icon *ngIf="!isEditing">edit</mat-icon>
      <mat-icon *ngIf="isEditing">check_circle</mat-icon>
    </button>
  </div>
  <ng-content></ng-content>
</article>