import { iOptionsSelector } from '@quasar-dynamics/basic-designsystem';
import { IStar } from '../Interfaces/iStars';
import { iChipSelectorOptions } from '../Interfaces/iChipSelectorOptions';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

export class DetailViewDetailsClass {
  // Formation Data
  public _formationData: any = {
    id: 23,
    formationId: 30,
    name: null,
    description: null,
    active: true,
    objectives: null,
    duration: null,
    language: null,
    topicId: null,
    topicName: null,
  };

  // categorySelectorOptions
  categorySelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    clearable: true,
    placeholder: 'Categoría',
    readonly: true,
    search: true,
    classList: 'blueSelector',
  };
  stateSelectorOptions: iChipSelectorOptions = {
    items: [],
    bold: false,
    readonly: true,
    size: 'sm',
    bindValue: 'id',
  };

  _modalitiesData: any[] = [];

  // topicArray
  topicArray: any[] = [];

  // Editable Controller
  editableController: { [key: string]: boolean } = {};

  // Test Data
  testDirectors: string[] = ['Director 1', 'Director 2', 'Director 3'];

  // Stars
  stars: IStar = {
    fontSize: 24,
    stars: 5,
    name: 'star',
    rating: 0.5,
  };

  // object to pass
  objectToPass: any = {
    id: null,
  };

  // numberRegex
  numberRegex = /^-?\d*([.,]\d*)?$/;

  // SelectorOptions
  languageSelectorOptions: iOptionsSelector = {
    items: StaticDataHandlerService.getLanguages(),
    append: true,
    clearable: false,
    placeholder: 'Idioma',
    search: true,
  };

  // Attendant modality array
  attendantModalityArray: any[] = [];

  // SelectedAttendantModality
  SelectedAttendantModality: number[] = [];

  // functions
  setAsReadonly() {
    if (this.editableController['Características generales']) {
      this.categorySelectorOptions.readonly = false;
    } else {
      this.categorySelectorOptions.readonly = true;
      this.stateSelectorOptions.readonly = true;
    }
    if (this.editableController['Descripción']) {
      this.stateSelectorOptions.readonly = false;
    } else {
      this.stateSelectorOptions.readonly = true;
    }
  }
}
