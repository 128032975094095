import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  SubjectManagerService,
  iBehaviourSubject,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GubrtService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  subjectManager: iBehaviourSubject = new iBehaviourSubject();

  constructor(
    private http: HttpClient,
    private SubjectManagerService: SubjectManagerService
  ) {
    super();
  }

  // editGubrt(body: any) {
  //   const _bs = this.SubjectManagerService.createBehaviorSubject();
  //   const _bsError = this.SubjectManagerService.createBehaviorSubject();

  //   this.http.put(this.urlApi + '/eebrt', body).subscribe({
  //     next: (data: any) => {
  //       let normalizedFormation = data;
  //       this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedFormation });
  //       this.subjectManager = new iBehaviourSubject({ error: false, data: this.res, currentBS: _bs, bsList: [_bs, _bsError] });
  //       this.SubjectManagerService.sendDataToBehaviorSubject(this.subjectManager)
  //     },
  //     error: (error: any) => {
  //
  //       this.res = new iResultHttp({ status: error.status, ok: false, message: error.message });
  //       this.subjectManager = new iBehaviourSubject({ error: true, data: this.res, currentBS: _bsError, bsList: [_bs, _bsError], methodCall: { method: this.editGubrt, args: [body] } });
  //       this.SubjectManagerService.sendDataToBehaviorSubject(this.subjectManager)
  //     },
  //   });
  //   return { succes: _bs, error: _bsError };
  // }

  // editGubrtOV(body: any) {
  //   const _bs = this.SubjectManagerService.createBehaviorSubject();
  //   const _bsError = this.SubjectManagerService.createBehaviorSubject();

  //   this.http.put(this.urlApi + '/eebrt', body).subscribe({
  //     next: (data: any) => {
  //       let normalizedFormation = data;
  //       this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedFormation });
  //       this.subjectManager = new iBehaviourSubject({ error: false, data: this.res, currentBS: _bs, bsList: [_bs, _bsError] });
  //       this.SubjectManagerService.sendDataToBehaviorSubject(this.subjectManager)
  //     },
  //     error: (error: any) => {
  //
  //       this.res = new iResultHttp({ status: error.status, ok: false, message: error.message });
  //       this.subjectManager = new iBehaviourSubject({ error: true, data: this.res, currentBS: _bsError, bsList: [_bs, _bsError], methodCall: { method: this.editGubrt, args: [body] } });
  //       this.SubjectManagerService.sendDataToBehaviorSubject(this.subjectManager)
  //     },
  //   });
  //   return { succes: _bs, error: _bsError };
  // }

  editGubrtOldVersion(body: any) {
    this.http.put(this.urlApi + '/eebrt', body, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: data['data'],
        });
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.message,
        });
        this.sendNextResult('resultUpdateError', this.res, {
          method: this.editGubrtOldVersion,
          args: [body],
        });
        this.checkStatusError(error);
      },
    });
  }

  // postGubrt(body: any) {
  //   const _bs = this.SubjectManagerService.createBehaviorSubject();
  //   const _bsError = this.SubjectManagerService.createBehaviorSubject();

  //   this.http.post(this.urlApi + '/pebrt', body).subscribe({
  //     next: (data: any) => {
  //       let normalizedFormation = data;
  //       this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedFormation });
  //       this.subjectManager = new iBehaviourSubject({ error: false, data: this.res, currentBS: _bs, bsList: [_bs, _bsError] });
  //       this.SubjectManagerService.sendDataToBehaviorSubject(this.subjectManager)
  //     },
  //     error: (error: any) => {
  //
  //       this.res = new iResultHttp({ status: error.status, ok: false, message: error.message });
  //       this.subjectManager = new iBehaviourSubject({ error: true, data: this.res, currentBS: _bsError, bsList: [_bs, _bsError], methodCall: { method: this.postGubrt, args: [body] } });
  //       this.SubjectManagerService.sendDataToBehaviorSubject(this.subjectManager)
  //     },
  //   });
  //   return { succes: _bs, error: _bsError };
  // }

  postGubrtOldVersion(body: any) {
    this.http.post(this.urlApi + '/pebrt', body, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: data['data'],
        });
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextResult('resultUpdateError', this.res, {
          method: this.postGubrtOldVersion,
          args: [body],
        });
        this.checkStatusError(error);
      },
    });
  }

  deleteGubrt(ids: any) {
    const _bs = this.SubjectManagerService.createBehaviorSubject();
    const _bsError = this.SubjectManagerService.createBehaviorSubject();

    this.http
      .delete(this.urlApi + '/debrt', this.sendBodyOptions(ids))
      .subscribe({
        next: (data: any) => {
          let normalizedFormation = data;
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedFormation,
          });
          this.subjectManager = new iBehaviourSubject({
            error: false,
            data: this.res,
            currentBS: _bs,
            bsList: [_bs, _bsError],
          });
          this.SubjectManagerService.sendDataToBehaviorSubject(
            this.subjectManager
          );
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.message,
          });
          this.subjectManager = new iBehaviourSubject({
            error: true,
            data: this.res,
            currentBS: _bsError,
            bsList: [_bs, _bsError],
            methodCall: { method: this.deleteGubrt, args: [ids] },
          });
          this.SubjectManagerService.sendDataToBehaviorSubject(
            this.subjectManager
          );
        },
      });
    return { succes: _bs, error: _bsError };
  }

  getAmpid(
    BehaviorSubject: BehaviorSubject<any>,
    body: { token: string; promotionId: number }
  ) {
    this.http.post(this.urlApi + '/ampid', body, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: data,
        });
        this.sendNextObservable(BehaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextObservable(BehaviorSubject, this.res, true, {
          method: this.getAmpid,
          args: [BehaviorSubject, body],
        });
      },
    });
  }
}
