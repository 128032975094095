import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { PopupService, StaticUtilitiesService, iDate, iOptionsCustomInput, iOptionsSelector, iResultHttp, iUnsubscribeDestroy, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

@Component({
  selector: 'Promotions-Crear',
  templateUrl: './Promotions-Crear.component.html',
  styleUrls: ['./Promotions-Crear.component.scss']
})
export class PromotionsCrearComponent extends iUnsubscribeDestroy implements OnInit {

  optionSelected: number = 0;
  isActive: boolean = false;
  isLoading: boolean = false;
  isEdit: boolean = false;
  formationId: number | null = null;
  promotionId: number | null = null;
  data: any = {
    startDate: new Date(),
    endDate: new Date(),
    schedule: "",
    state: null,
    edition: "",
    formation: null,
    teachers: null,
    coordinators: null,
    directors: null,
  };
  dataToPass: any = {
    teachers: [],
    coordinators: [],
    teacherPromotions: []
  };

  // Inputs
  opcionesEstado: iOptionsSelector = {
    items: StaticDataHandlerService.getEstadosPromociones(),
    placeholder: "Estado",
    search: true,
  }
  opcionesProfesores: iOptionsSelector = {
    items: [],
    placeholder: "Profesor",
    search: true,
    bindLabel: "name",
    bindValue: "id",
    multiple: {
      isMultiple: true,
      multipleLimitReplacer: -1,

    }
  }
  opcionesCoordinadores: iOptionsSelector = {
    items: [],
    placeholder: "Profesor",
    search: true,
    bindLabel: "name",
    bindValue: "id",
    multiple: {
      isMultiple: true,
    }
  }
  opcionesDirector: iOptionsSelector = {
    items: [],
    placeholder: "Profesor",
    search: true,
    bindValue: "id",
    bindLabel: "name",
  }

  constructor(private router: Router, private popupSE: PopupService, private staticUtilitiesSE: StaticUtilitiesService, private teacherSE: TeacherService, private promotionSE: PromotionFormationService) {
    super();
  }

  ngOnInit() {
    this.getParams();
    this.getAllProfesores();
  }

  ngAfterViewInit(): void {
    (document.querySelector(".form") as HTMLElement).addEventListener("scroll", (e) => {
      if ((document.querySelector(".contactoContainer") as HTMLElement).getBoundingClientRect().top < 0) {
        this.optionSelected = 1;
      }
      if ((document.querySelector(".contactoContainer") as HTMLElement).getBoundingClientRect().top > 0) {
        this.optionSelected = 0;
      }
    });
  }

  /**
   * GETTERS && SETTERS
   */

  stateSelector(event) {
    this.data.state = event;
    this.isCompleted();
  }
  teacherSelector(event, key) {
    switch (key) {
      case 'coordinators':
        this.data.coordinators = event;
        break;
      case 'teachers':
        this.data.teachers = event;
        break;
      case 'directors':
        this.data.directors = event;
        break;

      default:
        break;
    }
    this.isCompleted();
  }

  /**
   * FUNCTIONALITY
   */

  scroll(type: string) {
    switch (type) {
      case "informacion":
        (document.querySelector(".form") as HTMLElement).scroll({ top: 0, behavior: "smooth" })
        break;
      case "descripcion":
        (document.querySelector(".form") as HTMLElement).scroll({ top: (document.querySelector(".form") as HTMLElement).scrollHeight / 3.7, behavior: "smooth" });
        break;
    }
  }
  confirmPopup() {
    if (this.router.url.includes("crear-profesor")) {
      this.popupSE.openPopup(ConfirmarAccionPopupComponent, { accion: 'Descartar', elemento: 'la creación de la formación' });
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
        this.staticUtilitiesSE.goTo("/formaciones/crear-formaciones", slideNavigationTypes.slideToBottom)
        this._unsubInd.next("")
      })
    } else {
      this.staticUtilitiesSE.goTo("/formaciones/crear-formaciones", slideNavigationTypes.slideToBottom)

    }
  }

  goBack() {
    this.staticUtilitiesSE.goTo("/formaciones/promocion-detalle", slideNavigationTypes.slideToBottom, { promotion: this.promotionId })
  }
  isCompleted() {

    if (this.data.state && this.data.teachers && this.data.teachers.length > 0 && this.data.startDate && this.data.endDate && this.data.schedule && this.data.edition && this.data.coordinators && this.data.directors) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }
  updateLine(event, key, type) {

    switch (type) {
      case 'none':
        this.dataToPass[key] = event;
        this.updatePromotion();
        delete this.dataToPass[key];
        break;
      case 'delete':
        this.dataToPass["teacherPromotions"] = [this.data.teacherPromotions.find(teacherPromotion => teacherPromotion.position == key && teacherPromotion.teacher.id == event.value.id)?.id ?? -1];
        this.updatePromotion();
        this.dataToPass.teacherPromotions = [];
        break;
      case 'teachers':
        this.dataToPass['teachers'] = [...this.dataToPass.teachers, event.id];
        this.updatePromotion();
        this.dataToPass.teachers = [];
        break;
      case 'coordinators':
        this.dataToPass.coordinators = [...this.dataToPass.coordinators, event.id];
        this.updatePromotion();
        this.dataToPass.coordinators = [];
        break;
      case 'directors':
        this.dataToPass['directors'] = event;
        this.dataToPass['teacherPromotions'] = [this.data.teacherPromotions.find(teacherPromotion => teacherPromotion.position == key)?.id ?? -1];
        this.updatePromotion();
        this.dataToPass.teacherPromotions = [];
        break;
      case 'date':
        this.dataToPass[key] = new iDate(iDate.toPhp(event)).toStringDate('JAP');
        this.updatePromotion();
        delete this.dataToPass[key];
        break;

    }
  }

  getParams() {

    if (this.staticUtilitiesSE.getParams().isEdit) {
      this.promotionId = this.staticUtilitiesSE.getParams().promotion;
      this.isEdit = true;
      this.dataToPass.id = this.promotionId;
      this.getPromotion()
    } else {
      this.formationId = this.staticUtilitiesSE.getParams().formation;
      this.data.formation = this.formationId;
    }
  }
  setStartDate(event) {
    this.data.startDate = event;
    this.isCompleted();
  }
  setEndDate(event) {
    this.data.endDate = event;
    this.isCompleted();
  }

  setTeachers() {
    this.data.teacherPromotions.filter(element => element.position == "Director").map(director => {
      this.data.directors = director.teacher.id
    }
    )
    this.data.coordinators = [];
    this.data.teacherPromotions.filter(element => element.position == 'Coordinador').map(coordinator => {
      this.data.coordinators = [...this.data.coordinators, coordinator.teacher.id]
    })

    this.data.teachers = [];
    this.data.teacherPromotions.filter(element => element.position == 'Profesor').map(teacher => {
      this.data.teachers = [...this.data.teachers, teacher.teacher.id]
    })
  }


  /**
   * API CALLS
   */

  getAllProfesores() {
    this.teacherSE.getTeachersSelector();
    this.teacherSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      let { data } = res;
      let mappedData = res.data.map(teacher => { return { ...teacher, name: teacher.user.name + ' ' + teacher.user.surname } })

      this.opcionesProfesores.items = mappedData;
      this.opcionesCoordinadores.items = mappedData;
      this.opcionesDirector.items = mappedData;
      this._unsubInd.next('')
    })
    this.teacherSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  createPromotion() {
    this.data.formation = this.formationId;
    this.promotionSE.create(this.data);
    this.promotionSE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Promoción creada correctamente');
      this.staticUtilitiesSE.goTo("/formaciones/formaciones-detalle", slideNavigationTypes.slideToBottom, { formation: this.formationId })
      this._unsubInd2.next('')
    })
    this.promotionSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  getPromotion() {
    this.promotionSE.getSingle(this.promotionId);
    this.promotionSE.getResultIndividual().pipe(takeUntil(this._unsubInd3)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      let { data } = res;

      this.data = data;

      this.setTeachers();
      this._unsubInd3.next('')
    })
    this.promotionSE.getResultIndividualError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    });
  }

  updatePromotion() {
    this.promotionSE.update(this.dataToPass);
    this.promotionSE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Promoción actualizada correctamente');
      this._unsubInd2.next('')
    })
    this.promotionSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }


}
