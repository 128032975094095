import { ChipSelectorComponent } from '../Components/chip-selector/chip-selector.component';
import { headerData } from '../Components/main-table/main-table.component';
import {
  IPromotionTutoring,
  iPromotionTutoring,
} from '../Interfaces/Utils/iPromotionTutoring';

export class DetailViewTutoriasClass {
  // tutoring
  tutoring: any[] = [
    {
      schedule: 'Lunes 10:00 - 12:00',
      studentName: 'Juan',
      phone: '123456789',
      email: 's@s.com',
      modality: 'Presencial',
      classroom: 'Aula 1',
      link: 'https://www.google.com',
      status: 'Activo',
      comments: 'Comentario de prueba',
    },
  ];

  // HeaderData
  headerData: headerData = [
    {
      variant: 'chip',
      display: 'Horario',
      key: 'hours',
      width: '200px',
      className: 'justify-center moduleChipLightBlue',
      classNameGenerator: () => 'moduleChipLightBlue',
    },
    {
      variant: 'bold',
      display: 'Alumno',
      key: 'studentName',
      width: '200px',
      alignment: 'start',
    },
    {
      variant: 'bold',
      display: 'Teléfono',
      key: 'phone',
      width: '200px',
      alignment: 'start',
    },
    {
      variant: 'bold',
      display: 'Email',
      key: 'email',
      width: '200px',
      alignment: 'start',
    },
    {
      variant: 'bold',
      display: 'Modalidad',
      key: 'tutoringAttendance',
      width: '150px',
      alignment: 'start',
    },
    {
      variant: 'bold',
      display: 'Aula',
      key: 'classroom',
      width: '150px',
      alignment: 'start',
    },
    {
      variant: 'button',
      display: 'Enlace a zoom',
      key: 'meetingUrl',
      width: '150px',
      alignment: 'center',
      action: (item, index) => {
      },
      buttonStyle: `principalButton fitWidth bold`,
      text: 'Enlace Zoom',
      displayGenerator: (item) => (item.meetingUrl ? true : false),
    },
    {
      variant: 'chip',
      display: 'Estado',
      key: 'tutoringStudentState',
      width: '150px',
      alignment: 'center',
      className: 'justify-center fwBold minWidth-150',
      classNameGenerator: (item, index) => {
        return this.statusColor[item];
      },
    },
    {
      variant: 'bold',
      display: 'Comentarios',
      key: 'comment',
      width: '250px',
      alignment: 'start',
    },
  ];

  // _promotionId
  _promotionId: number = 0;

  // tutoringPromotion
  tutoringPromotion: iPromotionTutoring[] = [
    IPromotionTutoring.getEmptyObject(),
  ];

  // Color estado
  statusColor: any = {
    Libre: '--greyState',
    Confirmada: '--greenState',
    Cancelada: '--brightRedState',
  };
}
