import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { IStar } from '../Interfaces/iStars';
import { iChipSelectorOptions } from '../Interfaces/iChipSelectorOptions';
import { StaticSelectorHandlersService } from 'src/app/Services/Utils/StaticSelectorHandlers.service';
import {
  IPromotionInfoForView,
  iPromotionInfoForView,
} from '../Interfaces/Utils/iPromotionInfoForView';

export class DetailViewInformationClass {
  // emptyNgModel
  emptyNgModel = '';

  // PromotionInfo
  _promotionInfo: iPromotionInfoForView =
    IPromotionInfoForView.getEmptyObject();

  // Spreaded PromotionInfo
  promotionId: iPromotionInfoForView['promotionId'] = 0;
  coordinators: iPromotionInfoForView['coordinators'] = [];
  director: iPromotionInfoForView['director'] = {
    directorFullName: '',
    directorId: 0,
  };
  documents: iPromotionInfoForView['documents'] = [];
  generalInfo: iPromotionInfoForView['generalInfo'] = {
    classroom: '',
    classroomId: '',
    disposition: '',
    dispositionId: '',
    endDate: new Date(),
    formativeAction: '',
    formativeGroup: '',
    shift: 0,
    startDate: new Date(),
    endHour: '',
    startHour: '',
    days: [],
  };
  header: iPromotionInfoForView['header'] = {
    edition: '',
    formation: '',
    sessions: 0,
    state: '',
    students: 0,
    stateId: 0,
  };
  modules: iPromotionInfoForView['modules'] = [];
  amounts: iPromotionInfoForView['amounts'] = {
    coordinationAmount: 0,
    managementAmount: 0,
    projectAmount: 0,
    teachingAmount: 0,
    tutoringAmount: 0,
  };

  // Stars
  stars: IStar = {
    fontSize: 20,
    stars: 5,
    name: 'star',
    rating: 0.5,
  };

  editableController: { [key: string]: boolean } = {};

  // ngModels
  ngModels = {
    status: 'Confirmada',
  };

  // Custom Input Options
  startScheduleCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Hora de inicio',
    type: 'time',
    classList: 'slim',
  };
  endScheduleCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Hora de fin',
    type: 'time',
    classList: 'slim',
  };
  startDateSelectorCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Fecha de inicio',
    type: 'date',
    classList: 'slim',
  };
  endDateSelectorCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Fecha de fin',
    type: 'date',
    classList: 'slim',
  };
  accionFormativaCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Acción Formativa',
    type: 'text',
    classList: 'slim',
  };
  groupCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Grupo',
    type: 'text',
    classList: 'slim',
  };
  teacherHourAmountCustomInputOptions: iOptionsCustomInput = {
    label: 'Precio hora sesión (€)',
    placeholder: 'Precio hora sesión (€)',
    type: 'number',
    readOnly: true,
  };
  projectHourAmountCustomInputOptions: iOptionsCustomInput = {
    label: 'Precio hora tribunal (€)',
    placeholder: 'Precio hora tribunal (€)',
    type: 'number',
    readOnly: true,
  };
  tutoringCustomInputOptions: iOptionsCustomInput = {
    label: 'Precio hora tutoría (€)',
    placeholder: 'Precio hora tutoría (€)',
    type: 'number',
    readOnly: true,
  };
  managementCustomInputOptions: iOptionsCustomInput = {
    label: 'Remuneración por dirección(€)',
    placeholder: 'Remuneración por dirección(€)',
    type: 'number',
    readOnly: true,
  };
  coordinatorCustomInputOptions: iOptionsCustomInput = {
    label: 'Remuneración por coordinación (€)',
    placeholder: 'Remuneración por coordinación (€)',
    type: 'number',
    readOnly: true,
  };

  // selector Options
  aulaSelectorOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Aula',
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    classList: 'slim',
    clearable: false,
    search: true,
  };
  directorSelectorOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Director',
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    classList: 'slim',
    clearable: false,
    search: true,
  };
  coordinatorSelectorOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Coordinador',
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    classList: 'slim',
    clearable: true,
    search: true,
  };
  shiftSelectorOptions: iOptionsSelector = {
    items: [
      { id: 1, name: 'LyX de 16:00 a 21.00h' },
      { id: 2, name: 'LyJ de 15:00 a 22.00h' },
      { id: 3, name: 'MyV de 15:00 a 20.00h' },
      { id: 4, name: 'L,M,X,JyV de 16:00 a 21.00h' },
    ],
    search: true,
    clearable: false,
    bindLabel: 'name',
    bindValue: 'id',
    classList: 'slim',
  };
  disposicionSelectorOptions: iOptionsSelector = {
    items: [
      { id: 1, name: 'LyX de 16:00 a 21.00h' },
      { id: 2, name: 'LyJ de 15:00 a 22.00h' },
      { id: 3, name: 'MyV de 15:00 a 20.00h' },
      { id: 4, name: 'L,M,X,JyV de 16:00 a 21.00h' },
    ],
    search: true,
    clearable: false,
    bindLabel: 'name',
    bindValue: 'id',
    classList: 'slim',
  };

  // chipSelectorOptions
  chipSelectorStatusOptions: iChipSelectorOptions = {
    bold: false,
    items: StaticSelectorHandlersService.eventStatus,
    size: 'md',
    readonly: true,
    bindValue: 'id',
  };

  // supportedFormats
  supportedFormats: string[] = ['.pdf'];

  // ngFiles
  ngFiles: any = null;

  // multipleFiles
  multiple: boolean = true;

  // Functions
  setEditableAmounts(boolean: boolean) {
    const booleanToSet = !boolean;
    this.projectHourAmountCustomInputOptions.readOnly = booleanToSet;
    this.teacherHourAmountCustomInputOptions.readOnly = booleanToSet;
    this.tutoringCustomInputOptions.readOnly = booleanToSet;
    this.managementCustomInputOptions.readOnly = booleanToSet;
    this.coordinatorCustomInputOptions.readOnly = booleanToSet;
  }
}
