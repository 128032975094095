import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { slideNavigationTypes } from '@quasar-dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { headerData } from 'src/app/Shared/Components/main-table/main-table.component';

@Component({
  selector: 'formacionesNewVersion-Master-Table',
  templateUrl: './formacionesNewVersion-Master-Table.component.html',
  styleUrls: ['./formacionesNewVersion-Master-Table.component.scss'],
})
export class FormacionesNewVersionMasterTableComponent implements OnInit {
  @Input() allMasters: any = [];
  @Input() totalMasters: number = 0;
  @Input() areReadyAllMasters: boolean = false;
  @Input() filterAllMasters: any = null;

  @Output() pageChange: EventEmitter<boolean> = new EventEmitter();

  tableHead: headerData = [
    {
      variant: 'bold',
      display: 'Nombre',
      key: 'name',
      alignment: 'start',
    },
    {
      variant: 'standard',
      display: 'Horas',
      key: 'duration',
    },
    {
      variant: 'tooltip',
      display: 'Modalidad ',
      key: 'attendanceModalities',
      itemToDisplay(row) {
        if (row.attendanceModalities.length === 0) return '-';
        return row.attendanceModalities.length === 1
          ? row.attendanceModalities[0].name
          : row.attendanceModalities.length + ' modalidades';
      },
      tooltipItems(row) {
        return row.attendanceModalities.map((item) => item.name);
      },
      tooltipKeyToDisplay: '',
      tooltipItemsLength: (row) => row.attendanceModalities.length,
    },
    {
      variant: 'standard',
      display: 'Precio general',
      key: 'price',
      euros: true,
    },
    {
      variant: 'chip',
      display: 'Estado',
      key: 'activeLabel',

      classNameGenerator: (state: any, index, row) => {
        return state === 'Activo' ? '--greenState' : '--redState';
      },
    },
  ];

  constructor(private staticUtilitiesSE: StaticUtilitiesService) {}

  ngOnInit(): void {}

  handleClickRow(formation) {
    this.goToDetailView(formation);
  }

  goToDetailView(formation: any) {
    this.staticUtilitiesSE.goTo(
      'formaciones/formaciones-detalle/',
      slideNavigationTypes.slideToTop,
      { master: formation.masterId }
    );
  }

  pageChangeEmmiter(event) {
    this.pageChange.emit(event);
  }
}
