<div class="popupGeneralContainer">
  <div class="header">
    <p class="title">Crear evento de tribunal</p>
  </div>

  <div class="body">
    <div class="formLine">
      <customInput
        style="width: 100%"
        [(ngModel)]="ngModels.name"
        [options]="nameCustonInputOptions"
      ></customInput>
    </div>
    <div class="formLine doble">
      <customInput
        [selectedDate]="ngModels.startDate"
        (change)="getSelectedDate($event); checkFullObject()"
        [options]="dateCustomInputOptions"
      ></customInput>
      <selector
        [(ngModel)]="ngModels.slotId"
        class="popupAddProject"
        [options]="slotsSelectorOptions"
        (change)="checkFullObject()"
      ></selector>
    </div>
    <div class="formLine doble">
      <div class="formLine doble">
        <customInput
          class="hour"
          (change)="checkFullObject()"
          [(ngModel)]="ngModels.startHour"
          [options]="startHourCustomInputOptions"
        ></customInput>
        <customInput
          class="hour"
          (change)="checkFullObject()"
          [(ngModel)]="ngModels.endHour"
          [options]="endHourCustomInputOptions"
        ></customInput>
      </div>
      <selector
        (change)="checkFullObject()"
        [(ngModel)]="ngModels.classroomId"
        class="popupAddProject"
        [options]="aulaSelectorOptions"
      ></selector>
    </div>
  </div>

  <div class="footer">
    <btn classList="violetButton" (click)="forceClosePopup()">
      <ng-container> Cancelar </ng-container>
    </btn>
    <btn
      classList="principalButton"
      [ngClass]="{ disabled: !isObjectComplete }"
      (click)="formatBody()"
    >
      <ng-container> Finalizar </ng-container>
    </btn>
  </div>
</div>
