import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iFormation } from "./iFormation";
import { iModuleFormation } from "./iModuleFormation";

export interface iPromotionFormation {
    id: number,
    startDate?: iDate,
    endDate?: iDate,
    schedule?: string,
    state?: string,
    edition?: string,
    turn?: string,
    formation?: iFormation,
    moduleFormations?: iModuleFormation[],
}

export class iPromotionFormation extends iBaseObject {
    static convertFromCollection(ob: any): Array<iPromotionFormation> {
        let iPromotionFormationCollection: Array<iPromotionFormation> = [];
        ob.forEach((element: any) => {
            iPromotionFormationCollection.push(this.convertFromObject(element));
        });
        return iPromotionFormationCollection;
    }

    static convertFromObject(ob: any): iPromotionFormation {
        let iPromotionFormationObj = new iPromotionFormation();
        iPromotionFormationObj.id = ob.id;
        iPromotionFormationObj.startDate = iDate.phpConvert(ob.startDate);
        iPromotionFormationObj.endDate = iDate.phpConvert(ob.endDate);
        iPromotionFormationObj.schedule = ob.schedule;
        iPromotionFormationObj.state = ob.state;
        iPromotionFormationObj.edition = ob.edition;
        iPromotionFormationObj.turn = ob.turn;
        iPromotionFormationObj.formation = ob.formation ? iFormation.convertFromObject(ob.formation) : iFormation.createEmptyObject();
        iPromotionFormationObj.moduleFormations = ob.moduleFormations ? iModuleFormation.convertFromCollection(ob.moduleFormations) : [];
        return iPromotionFormationObj;
    }

    static createEmptyObject(): iPromotionFormation {
        let iPromotionFormationObj = new iPromotionFormation();
        iPromotionFormationObj.startDate = iDate.javascriptConvert(new Date());
        iPromotionFormationObj.endDate = iDate.javascriptConvert(new Date());
        iPromotionFormationObj.schedule = '';
        iPromotionFormationObj.state = '';
        iPromotionFormationObj.edition = '';
        iPromotionFormationObj.turn = '';
        iPromotionFormationObj.formation = iFormation.createEmptyObject();
        iPromotionFormationObj.moduleFormations = [];
        return iPromotionFormationObj;
    }
}

export interface iPromotionFormationFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    startDate?: string,
    endDate?: string,
    type?: string,
    classroom?:number,
    academicState?:number
}