<div class="popupGeneralContainer">
  <div class="header">
    <p class="title">{{ isEdit ? "Editar" : "Crear" }} aula</p>
  </div>

  <div class="body">
    <div class="fullSection">
      <div class="formLine doble">
        <customInput
          (change)="isCompleted()"
          [options]="nameCustomInputOptions"
          [(ngModel)]="ngModels.name"
        ></customInput>
        <selector
          class="popupAddProject"
          (change)="isCompleted()"
          [(ngModel)]="ngModels.dispositionId"
          [options]="dispositionSelectorOptions"
        ></selector>
      </div>

      <div class="formLine">
        <customTextArea
          style="width: 100%"
          (change)="isCompleted()"
          [options]="observationsCustomTextAreaOptions"
          [(ngModel)]="ngModels.observations"
        ></customTextArea>
      </div>
    </div>
  </div>

  <div class="footer">
    <btn classList="violetButton" (click)="closePopup(false)">
      <ng-container> Cancelar </ng-container>
    </btn>
    <btn
      classList="principalButton"
      [class.disabled]="!isActive"
      (click)="finishButton()"
    >
      <ng-container> Finalizar </ng-container>
    </btn>
  </div>
</div>
