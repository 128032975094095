<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>Personalizar columnas de la tabla</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="filtrosContainer">
        <mat-checkbox class="filtroColumnas" [checked]="filtro.checked" (change)="filtro.checked = $event.checked" *ngFor="let filtro of modalData.filtros" >{{filtro.displayName}}</mat-checkbox>
    </div>
  </div>
  <div class="footer">
    <btn classList="principalButton" (click)="forceClosePopup()">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn classList="specialButton" (click)="closePopup(modalData.filtros)">
      <ng-container>
        Aplicar filtros
      </ng-container>
    </btn>
  </div>
</div>
