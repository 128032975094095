import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { iResultHttp } from '@quasar-dynamics/basic-designsystem';
import {
  iUnsubscribeDestroy,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { EnrollmentDetailPopupComponent } from 'src/app/Popups/EnrollmentDetail-Popup/EnrollmentDetail-Popup.component';
import { PersonalizarColumnasPopupComponent } from 'src/app/Popups/PersonalizarColumnas-Popup/PersonalizarColumnas-Popup.component';
import { AutoenrollmentTokenService } from 'src/app/Services/Api/AutoenrollmentToken.service';
import { SessionService } from 'src/app/Services/Api/Session.service';
import { LocalStorageHandlerService } from 'src/app/Services/Utils/LocalStorageHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'matriculacionesTabla',
  templateUrl: './GestionUsuarios-Matriculaciones-Tabla.component.html',
  styleUrls: ['./GestionUsuarios-Matriculaciones-Tabla.component.scss'],
})
export class GestionUsuariosMatriculacionesTablaComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input() rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  // Pagination
  currentPage: number = 1;
  // Seleccion xls
  formacionSelected: any = [];
  formacionesSelected: Array<any> = [];

  preferences: any = [];
  isDataLoaded: boolean = false;
  allSelected: boolean = false;

  matriculacionesSelected: Array<any> = [];
  filtros: Array<any> = [
    { position: 1, label: 'Nombre', realFilter: 'nombre', checked: true },
    { position: 2, realFilter: 'email', label: 'Email', checked: true },
    {
      position: 3,
      realFilter: 'telefono',
      label: 'Teléfono de contacto',
      checked: true,
    },
    {
      position: 4,
      realFilter: 'tipo',
      label: 'Tipo de formación',
      checked: true,
    },
    {
      position: 5,
      realFilter: 'lastPhaseUpdate',
      label: 'Fecha',
      checked: true,
    },
    { position: 6, realFilter: 'phase', label: 'Fase', checked: true },
  ];
  @Input() matriculaciones: Array<any> = [];
  @Input() filters: any = {};
  @Input() total: number = 0;
  @Input() navigateActive: boolean = true;
  @Input() changePage: boolean = false;
  @Input() downloadExcelFile: boolean = false;
  @Output() selectedMatriculaciones = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<true>();

  displayedColumns: any[] = [
    { displayName: 'Nombre', property: 'name', checked: true },
    { displayName: 'Email', property: 'email', checked: true },
    { displayName: 'Teléfono', property: 'phone', checked: true },
    { displayName: 'Formación', property: 'formation', checked: true },
    { displayName: 'Edición', property: 'edition', checked: true },
    { displayName: 'Modalidad', property: 'modality', checked: true },
    { displayName: 'Tipo de matrícula', property: 'type', checked: true },
    {
      displayName: 'Rezón de cancelación',
      property: 'reasonCancellation',
      checked: true,
    },
    { displayName: 'Fecha', property: 'lastPhaseUpdate', checked: true },
    { displayName: 'Fase', property: 'phase', checked: true },
  ];

  dataSource: Array<any> = [];
  disabledColumns: string[] = [];
  constructor(
    private popupSE: PopupService,
    private autoenrollmentTokenSE: AutoenrollmentTokenService
  ) {
    super();
  }

  ngOnInit() {
    this.dataSource = [...this.matriculaciones];
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['changePage'] != undefined) {
      this.matriculacionesSelected = [];
      this.allSelected = false;
      this.selectedMatriculaciones.emit(this.matriculacionesSelected);
    }
    if (changes['matriculaciones'] != undefined) {
      this.isDataLoaded = true;
    }
    if (changes['downloadExcelFile'] != undefined) {
      if (changes['downloadExcelFile'].currentValue) {
        this.exportexcel();
      }
    }
  }
  /**
   * FUNCTIONALITIES
   */
  checkRolesAndPermissions() {
    return (
      this.rolesAndPermissions.roles.includes('ROLE_ADMIN') ||
      this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') ||
      this.rolesAndPermissions.permissions.includes(6)
    );
  }

  onRowClick(matriculacion) {
    let objectToPass: { enrollments: number[]; email: string } = {
      enrollments: [matriculacion.id],
      email: matriculacion.student.user.email,
    };
    this.getAutoenrollmentLink(objectToPass);
  }

  addSelected(matriculacion) {
    !this.matriculacionesSelected.includes(matriculacion)
      ? this.matriculacionesSelected.push(matriculacion)
      : this.matriculacionesSelected.splice(
          this.matriculacionesSelected.indexOf(matriculacion),
          1
        );
    this.matriculacionesSelected.length == this.matriculaciones.length
      ? (this.allSelected = true)
      : (this.allSelected = false);
    this.selectedMatriculaciones.emit(this.matriculacionesSelected);
  }
  selecteAll(event) {
    if (event.checked) {
      this.matriculacionesSelected = [...this.matriculaciones];
      this.allSelected = true;
    } else {
      this.matriculacionesSelected = [];
      this.allSelected = false;
    }
    this.selectedMatriculaciones.emit(this.matriculacionesSelected);
  }
  personalizarColumnas() {
    this.popupSE.openPopup(PersonalizarColumnasPopupComponent, {
      filtros: this.filtros,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'Se han modificado las preferencias correctamente'
        );
        this.filtros = res.returnValue;
        this.checkUncheckedColumns(res.returnValue);
        this._unsubInd.next('');
      });
  }
  checkUncheckedColumns(res) {
    res.map((element) => {
      if (!element.checked) {
        Array.from(
          document.querySelectorAll('.mat-column-' + element.realFilter)
        ).map((column) => {
          (column as HTMLElement).style.display = 'none';
        });
      } else {
        Array.from(
          document.querySelectorAll('.mat-column-' + element.realFilter)
        ).map((column) => {
          (column as HTMLElement).style.display = 'revert';
        });
      }
    });
  }
  changeOrder(event) {}
  enrollmentDetailPopup(enrollment) {
    this.popupSE.openPopup(EnrollmentDetailPopupComponent, {
      data: enrollment,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        this.refresh.emit(true);
        this._unsubInd2.next('');
      });
  }
  // Descargar excel

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (document.querySelector('table thead') as HTMLElement).cloneNode(true)
    );
    table.querySelectorAll('thead th mat-icon').forEach((element) => {
      element.remove();
    });
    table.querySelector('thead th:first-of-type')?.remove();
    let rows = Array.from(document.querySelectorAll('table tbody tr '));
    let filteredRows = rows.filter((row) => {
      if (row.className === 'loaderLine') return null;
      return (
        row
          .querySelector('mat-checkbox')!
          .getAttribute('ng-reflect-checked') === 'true'
      );
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (document.querySelector('table tbody') as HTMLElement).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Matriculaciones.xlsx');
  }

  /**
   * API CALLS
   */

  getAutoenrollmentLink(data) {
    this.autoenrollmentTokenSE.getAutoenrollmentToken(data);
    this.autoenrollmentTokenSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;
        window.open(data, '_blank');
        this._unsubInd.next('');
      });
    this.autoenrollmentTokenSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
