<Tutorias-Alumnos-Layout>


  <div class="content">
    <div class="topText">
      <p class="reserva">
        RESERVA TU TUTORÍA
      </p>
      <p class="quieresLlevar">
        ¿QUIERES LLEVAR TU PROYECTO AL SIGUIENTE NIVEL?
      </p>
      <p class="reservaTuTutoria">
        Reserva tu tutoría con uno de nuestros profesores.
      </p>
    </div>

    <div class="inputNIF">
      <form class="input">
        <customInput style="width: 100%;" name="nif" [(ngModel)]="nif" [options]="documentoDeIdentidadCustomInputOptions"></customInput>
        <btn [class.disabled]="!nif" classList="generalbutton principalButton fitWidth height48" (click)="prepareNIFToCheck(nif)" >
          Siguiente
        </btn>
      </form>
      <p class="redText" *ngIf="isError">
        El documento de identidad introducido, no corresponde a una formación compatible con la reserva de tutorías. Por favor, asegúrate de que has ingresado el número correcto o contacta con la escuela.       </p>
    </div>
  </div>






</Tutorias-Alumnos-Layout>
