import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iResultHttp } from '@quasar-dynamics/basic-designsystem';
import { DefaultBaseService } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StudentPromotionService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  update(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(
        `${this.urlApi}/api/student/promotion/${data.id}`,
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: data.data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.update,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  getState(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(`${this.urlApi}/api/student/promotion/${id}/state`, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getState,
            args: [behaviorSubject, id],
          });
        },
      });
  }
}
