import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { GeneralLoaderService } from '@quasar-dynamics/basic-designsystem';
import {
  PopupService,
  iPipeLine,
  iResultHttp,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { AddExcelPopupComponent } from 'src/app/Popups/AddPopup/AddExcel-Popup/AddExcel-Popup.component';
import { AddStudentEnrollmentPopupComponent } from 'src/app/Popups/AddPopup/AddStudentEnrollment-Popup/AddStudentEnrollment-Popup.component';
import { CambiarFormacionPromocionMatriculaPopupComponent } from 'src/app/Popups/CambiarFormacionPromocionMatricula-Popup/CambiarFormacionPromocionMatricula-Popup.component';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { ConfirmarAccionPerderPopupComponent } from 'src/app/Popups/ConfirmarAccionPerder-Popup/ConfirmarAccionPerder-Popup.component';
import { CursoMasterMatriculaConfirmaPopupComponent } from 'src/app/Popups/CursoMasterMatriculaConfirma-Popup/CursoMasterMatriculaConfirma-Popup.component';
import { CursoMasterMatriculaRevisaPopupComponent } from 'src/app/Popups/CursoMasterMatriculaRevisa-Popup/CursoMasterMatriculaRevisa-Popup.component';
import { CursoMasterPreMatriculaPopupComponent } from 'src/app/Popups/CursoMasterPreMatricula-Popup/CursoMasterPreMatricula-Popup.component';
import { AutoenrollmentTokenService } from 'src/app/Services/Api/AutoenrollmentToken.service';
import { EnrollmentService } from 'src/app/Services/Api/Enrollment.service';
import { PipedriveService } from 'src/app/Services/Api/Pipedrive.service';
import { StaticUtilitiesService as OldStaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { StaticUtilitiesService } from '@quasar-dynamics/basic-designsystem';
import { CancelEnrollmentPopupComponent } from 'src/app/Popups/CancelEnrollmentPopup/CancelEnrollmentPopup.component';

@Component({
  selector: 'matriculacionesCursosPipeline',
  templateUrl:
    './GestionUsuarios-Matriculaciones-Cursos-Pipeline.component.html',
  styleUrls: [
    './GestionUsuarios-Matriculaciones-Cursos-Pipeline.component.scss',
  ],
})
export class GestionUsuariosMatriculacionesCursosPipelineComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @HostListener('window:scroll', ['$event']) onWindowScroll(event) {}

  @ViewChild('bubble', { static: true }) bubble!: ElementRef;

  @Input() matriculaciones: Array<any> = [];
  @Input() crmPipeDrive: Array<any> = [];
  @Input() dataPhasesEnrollment: Array<any> = [];
  @Input() rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  loadingElements: any[] = [];
  loaderUp: boolean = false;
  pipeList: Array<iPipeLine> = [
    { title: 'Pre-matricula', items: [] },
    { title: 'Por revisar', items: [] },
    { title: 'Matriculado oficial', items: [] },
  ];
  placeholder = './assets/Images/Placeholder/placeholder.jpg';
  constructor(
    private enrollmentSE: EnrollmentService,
    private popupSE: PopupService,
    private autoenrollmentTokenSE: AutoenrollmentTokenService,
    private pipedriveSE: PipedriveService,
    private generalLoaderSE: GeneralLoaderService
  ) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataPhasesEnrollment']) {
      if (changes['dataPhasesEnrollment'].currentValue) {
        this.loaderUp = true;
        let { pending, preEnrolled, toRevise } =
          changes['dataPhasesEnrollment'].currentValue;
        this.clearData();
        if (preEnrolled && pending) {
          //PRE-ENROLLED
          for (const key in preEnrolled['double']) {
            let obj = {
              id: preEnrolled['double'][key].map((elem: any) => {
                return elem.id;
              }),
              nombre:
                preEnrolled['double'][key][0].students_group?.name ??
                preEnrolled['double'][key][0].student?.user.name +
                  ' ' +
                  preEnrolled['double'][key][0].student?.user.surname,
              mail: preEnrolled['double'][key][0].student?.user.email ?? '-',
              prefijo:
                preEnrolled['double'][key][0].student?.user.prefix ?? '-',
              telefono:
                preEnrolled['double'][key][0].student?.user.phone ?? '-',
              formacion: preEnrolled['double'][key].map((elem: any) => {
                return elem.promotion?.formation?.name;
              }),
              edicion: preEnrolled['double'][key].map((elem: any) => {
                return elem.promotion?.edition;
              }),
              fase: 'preEnrolled',
              initials: preEnrolled['double'][key][0].students_group?.name
                ? 'G'
                : preEnrolled['double'][key][0].student?.user.name[0][0] +
                  preEnrolled['double'][key][0].student?.user.surname[0][0],
              isGroup: preEnrolled['double'][key][0].students_group?.name
                ? true
                : false,
              pipedriveDealId:
                preEnrolled['double'][key][0].pipedrive_deal_id ?? null,
              student: preEnrolled['double'][key][0].student.id,
            };
            this.pipeList[0].items.push(obj);
          }
          preEnrolled['single'].forEach((elem: any) => {
            let obj = {
              id: [elem.id],
              nombre:
                elem.students_group?.name ??
                elem.student?.user.name + ' ' + elem.student?.user.surname,
              mail: elem.student?.user.email ?? '-',
              prefijo: elem.student?.user.prefix ?? '-',
              telefono: elem.student?.user.phone ?? '-',
              formacion: [elem.promotion?.formation?.name] ?? ['-'],
              formacionId: [elem.promotion?.formation?.id] ?? ['-'],
              formacionModality: [elem.promotion?.formation?.mode] ?? ['-'],
              edicion: [elem.promotion?.edition] ?? ['-'],
              fase: 'preEnrolled',
              isGroup: elem.students_group?.name ? true : false,
              initials: elem.students_group?.name
                ? 'G'
                : elem.student?.user.name[0] + elem.student?.user.surname[0],
              pipedriveDealId: elem.pipedrive_deal_id ?? null,
              student: elem.student.id,
            };
            this.pipeList[0].items.push(obj);
          });
          for (const key in toRevise['double']) {
            let obj = {
              id: toRevise['double'][key].map((elem: any) => {
                return elem.id;
              }),
              nombre:
                toRevise['double'][key][0].students_group?.name ??
                toRevise['double'][key][0].student?.user.name +
                  ' ' +
                  toRevise['double'][key][0].student?.user.surname,
              mail: toRevise['double'][key][0].student?.user.email ?? '-',
              prefijo: toRevise['double'][key][0].student?.user.prefix ?? '-',
              telefono: toRevise['double'][key][0].student?.user.phone ?? '-',
              formacion: toRevise['double'][key].map((elem: any) => {
                return elem.promotion?.formation?.name;
              }),
              edicion: toRevise['double'][key].map((elem: any) => {
                return elem.promotion?.edition;
              }),
              fase: 'toRevise',
              isGroup: toRevise['double'][key][0].students_group?.name
                ? true
                : false,
              initials: toRevise['double'][key][0].students_group?.name
                ? 'G'
                : toRevise['double'][key][0].student?.user.name[0] +
                  toRevise['double'][key][0].student?.user.surname[0],
              pipedriveDealId:
                toRevise['double'][key][0].pipedrive_deal_id ?? null,
            };
            this.pipeList[1].items.push(obj);
          }
          toRevise['single'].forEach((elem: any) => {
            let obj = {
              id: [elem.id],
              nombre:
                elem.students_group?.name ??
                elem.student?.user.name + ' ' + elem.student?.user.surname,
              mail: elem.student?.user.email ?? '-',
              prefijo: elem.student?.user.prefix ?? '-',
              telefono: elem.student?.user.phone ?? '-',
              formacion: [elem.promotion?.formation?.name] ?? ['-'],
              edicion: [elem.promotion?.edition] ?? ['-'],
              fase: 'toRevise',
              isGroup: elem.students_group?.name ? true : false,
              initials: elem.students_group?.name
                ? 'G'
                : elem.student?.user.name[0] + elem.student?.user.surname[0],
              pipedriveDealId: elem.pipedrive_deal_id ?? null,
            };
            this.pipeList[1].items.push(obj);
          });
          for (const key in pending['double']) {
            let obj = {
              id: pending['double'][key].map((elem: any) => {
                return elem.id;
              }),
              nombre:
                pending['double'][key][0].students_group?.name ??
                pending['double'][key][0].student?.user.name +
                  ' ' +
                  pending['double'][key][0].student?.user.surname,
              mail: pending['double'][key][0].student?.user.email ?? '-',
              prefijo: pending['double'][key][0].student?.user.prefix ?? '-',
              telefono: pending['double'][key][0].student?.user.phone ?? '-',
              formacion: pending['double'][key].map((elem: any) => {
                return elem.promotion?.formation?.name;
              }),
              edicion: preEnrolled['double'][key].map((elem: any) => {
                return elem.promotion?.edition;
              }),
              fase: 'pending',
              isGroup: pending['double'][key][0].students_group?.name
                ? true
                : false,
              initials: pending['double'][key][0].students_group?.name
                ? 'G'
                : pending['double'][key][0].student?.user.name[0] +
                  pending['double'][key][0].student?.user.surname[0],
              pipedriveDealId:
                pending['double'][key][0].pipedrive_deal_id ?? null,
            };
            this.pipeList[2].items.push(obj);
          }
          pending['single'].forEach((elem: any) => {
            let obj = {
              id: [elem.id],
              nombre:
                elem.students_group?.name ??
                elem.student?.user.name + ' ' + elem.student?.user.surname,
              mail: elem.student?.user.email ?? '-',
              prefijo: elem.student?.user.prefix ?? '-',
              telefono: elem.student?.user.phone ?? '-',
              formacion: [elem.promotion?.formation?.name] ?? ['-'],
              edicion: [elem.promotion?.edition] ?? ['-'],
              fase: 'pending',
              isGroup: elem.students_group?.name ? true : false,
              initials: elem.students_group?.name
                ? 'G'
                : elem.student?.user.name[0] + elem.student?.user.surname[0],
              pipedriveDealId: elem.pipedrive_deal_id ?? null,
            };
            this.pipeList[2].items.push(obj);
          });

          this.loaderUp = false;
        }
      }
    }
  }

  ngAfterViewInit() {
    (document.querySelector('.pageContainer') as HTMLElement).addEventListener(
      'scroll',
      (e: any) => {
        const scrollPosition = e.target.scrollTop;
        if (scrollPosition > 0) {
          this.bubble.nativeElement.classList.add('visible');
        } else {
          this.bubble.nativeElement.classList.remove('visible');
        }
      }
    );
  }

  /**
   * HANDLERS
   */

  successeditPhaseEnrollmentHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback('Fase actualizada correctamente');
    this.loadingElements.length = 0;
    this.refresh.emit(true);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  erroreditPhaseEnrollmentHandler(res: iResultHttp) {
    this.refresh.emit(true);
    this.loadingElements.length = 0;
    StaticUtilitiesService.showError('Error al actualizar la fase');
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */
  clearData() {
    this.pipeList[0].items = [];
    this.pipeList[1].items = [];
    this.pipeList[2].items = [];
  }
  assignToPipelist() {}
  isLoadingElem(elem: any) {
    if (this.loadingElements.includes(elem.id)) {
      return true;
    }
    return null;
  }
  async changeItem(elem: any) {
    let objectToPass = {
      id: elem.item.id,
    };
    this.loadingElements.push(elem.item.id);

    switch (elem.currentColumn.column.title) {
      case 'Pre-matricula':
        objectToPass['phase'] = 'preEnrolled';
        break;
      case 'Matriculado oficial':
        objectToPass['phase'] = 'pending';
        break;
    }

    this.editPhaseEnrollment(objectToPass);
  }

  change(event: any) {}
  onImgError(event: any) {
    event.target.src = this.placeholder;
  }

  checkRolesAndPermissions() {
    return (
      this.rolesAndPermissions.roles.includes('ROLE_ADMIN') ||
      this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') ||
      this.rolesAndPermissions.permissions.includes(6)
    );
  }

  openCard(item) {
    if (item.fase == 'preEnrolled' && item.isGroup) {
      this.popupSE.openPopup(AddExcelPopupComponent, { data: item });
      this.popupSE
        .returnData()
        .pipe(takeUntil(this._unsubInd2))
        .subscribe((res) => {
          if (!res) return;
          this._unsubInd2.next('');
        });
    }
  }

  lossCardNoPipedrive(event, item) {
    event.stopPropagation();
    if (!this.checkRolesAndPermissions()) return;

    this.popupSE.openPopup(CancelEnrollmentPopupComponent);
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd8))
      .subscribe((res) => {
        if (res === undefined) return;
        if (!res?.returnValue) {
          this._unsubInd8.next('');
          return;
        }
        let objectToPass = {
          id: item.id,
          phase: 'lost',
          ...res.returnValue,
        };
        this.editPhaseEnrollment(objectToPass);
        this._unsubInd8.next('');
      });
  }
  lossCard(event, item, type) {
    if (!this.checkRolesAndPermissions()) return;

    event.stopPropagation();
    this.popupSE.openPopup(ConfirmarAccionPerderPopupComponent, {
      data: {
        accion: 'Perder',
        elemento: 'la propuesta',
        id: item.id,
        item: item,
      },
      type: type,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (!res) return;

        this.refresh.emit(true);
        this._unsubInd9.next('');
      });
  }

  goUp() {
    (document.querySelector('.pageContainer') as HTMLElement).scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  clickCRM(event) {
    this.popupSE.openPopup(AddStudentEnrollmentPopupComponent, { data: event });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (!res) return;
        this.refresh.emit(true);
        this._unsubInd9.next('');
      });
  }

  openAutomatriculaIem(event) {
    let objectToPass: { enrollments: number[]; email: string } = {
      enrollments: event.id,
      email: event.mail,
    };
    this.getAutoenrollmentLink(objectToPass);
  }

  openMatriculaRevisaPopup(event) {
    if (!this.checkRolesAndPermissions()) return;
    this.popupSE.openPopup(CursoMasterMatriculaRevisaPopupComponent, {
      data: event,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (!res) return;
        let objectToPass = {
          id: event.id[0],
          phase: 'pending',
        };
        this.editPhaseEnrollment(objectToPass);
        this.refresh.emit(true);
        this._unsubInd9.next('');
      });
  }

  openPreMatriculaPopup(event) {
    if (!this.checkRolesAndPermissions()) return;
    this.popupSE.openPopup(CursoMasterPreMatriculaPopupComponent, {
      data: event,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (!res) return;
        this.refresh.emit(true);
        this._unsubInd9.next('');
      });
  }

  openChangePromotionPopup(event, item) {
    event.stopPropagation();
    this.popupSE.openPopup(CambiarFormacionPromocionMatriculaPopupComponent, {
      data: item,
      type: 'course',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (!res) return;
        this.refresh.emit(true);
        this._unsubInd9.next('');
      });
  }

  openMatriculaConfirmPopup(event) {
    if (!this.checkRolesAndPermissions()) return;
    this.popupSE.openPopup(CursoMasterMatriculaConfirmaPopupComponent, {
      data: event,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (!res) return;

        this.confirmEnrollment(event);
        this._unsubInd9.next('');
      });
  }
  /**
   * API CALLS
   */

  confirmEnrollment(data) {
    let objectToPass: any = {
      email: data.mail,
      enrollments: data.id,
    };
    this.enrollmentSE.confirmEnrollmentMasterCourse(objectToPass);
    this.enrollmentSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        this.refresh.emit(true);
        OldStaticUtilitiesService.showFeedback(
          'Matriculación confirmada correctamente'
        );
        this._unsubInd.next('');
      });
    this.enrollmentSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            OldStaticUtilitiesService.showError(
              'No se han encontrado resultados'
            );
          } else if (res.status == 500) {
            OldStaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            OldStaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  editPhaseEnrollment(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();

    this.enrollmentSE.updatePhase(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successeditPhaseEnrollmentHandler(res),
          },
          {
            method: () => this.erroreditPhaseEnrollmentHandler(res),
            error: true,
          },
        ]);
      });
  }

  deleteEnrollment(ids: number[]) {
    this.enrollmentSE.delete(ids);
    this.enrollmentSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd7))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showFeedback(
          'Matriculaciones eliminadas correctamente'
        );
        this.refresh.emit(true);
        this._unsubInd7.next('');
      });
    this.enrollmentSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showError(
          'Las matriculaciones no se han podido eliminar'
        );
        this._unsub.next('');
      });
  }
  getAutoenrollmentLink(data) {
    this.autoenrollmentTokenSE.getAutoenrollmentToken(data);
    this.autoenrollmentTokenSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;
        window.open(data, '_blank');
        this._unsubInd.next('');
      });
    this.autoenrollmentTokenSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  perderCard(data) {
    this.pipedriveSE.lossCRMCard(data);
    this.pipedriveSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd8))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback(String(res.message));
        this.refresh.emit(true);
        this._unsubInd8.next('');
      });
    this.pipedriveSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
