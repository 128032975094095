import { environment } from 'src/environments/environment';
import { iBillFilter, iBillTeacherFilter } from '../Interfaces/iBill';
import { headerData } from '../Components/main-table/main-table.component';
import { iBill, Ibill } from '../Interfaces/Utils/iBill';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';

export class GestionUsuariosProfesoresDetallesFacturasClass {
  constructor(
    private openAddBillPopup: (edit: boolean, billId: number | null) => void,
    private openConfirmationPopup: (id: number) => void
  ) {}
  _dataTeacher: any;
  teacherFullName: string = '';
  isDataLoaded: boolean = false;
  total: number = 0;
  filterTypeSubmenuSelected: number = 0;

  IconsHandlerService = IconsHandlerService;

  teacherBills: iBill[] = [Ibill.getEmptyObject()];

  filtroBill: iBillTeacherFilter = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  };

  headerData: headerData = [
    {
      variant: 'bold',
      display: 'Concepto',
      key: 'concept',
      alignment: 'start',
      width: '250px',
      className: 'truncateText maxWidth-250',
    },
    {
      variant: 'bold',
      display: 'Importe',
      key: 'amount',
      alignment: 'center',
      width: '100px',
      className: 'truncateText maxWidth-100',
      euros: true,
    },
    {
      variant: 'bold',
      display: 'Nº Factura',
      key: 'number',
      alignment: 'center',
      width: '150px',
      className: 'truncateText maxWidth-150',
    },
    {
      variant: 'bold',
      display: 'Fecha',
      key: 'date',
      alignment: 'center',
      width: '150px',
      className: 'truncateText maxWidth-150',
      date: {
        date: true,
        pipeTemplate: 'dd/MM/yyyy',
      },
    },
    {
      variant: 'bold',
      display: 'Fecha pago',
      key: 'paymentDate',
      alignment: 'center',
      width: '150px',
      className: 'truncateText maxWidth-150',
      date: {
        date: true,
        pipeTemplate: 'dd/MM/yyyy',
      },
    },
    {
      variant: 'chip',
      display: 'Estado',
      key: 'billState',
      alignment: 'center',
      width: '150px',
      className: 'fwBold',
      classNameGenerator(any, index, row) {
        return row.billStateColor;
      },
    },
    {
      variant: 'button',
      display: 'PDF',
      key: 'url',
      displayGenerator(row) {
        return row.url !== null && row.url !== undefined && row.url !== '';
      },
      action: (any) => this.openUrl(any),
      buttonStyle: 'principalButton onlyIcon',
      icon: 'attach_file',
      width: '50px',
    },
    {
      variant: 'icons',
      display: '',
      key: '',
      width: '100px',
      icons: [
        {
          action: (row) => this.openAddBillPopup(true, row.id),
          image: IconsHandlerService.getEditBlueIcon(),
        },
        {
          action: (row) => this.openConfirmationPopup(row.id),
          image: IconsHandlerService.getDeleteIcon(),
        },
      ],
      alignment: 'center',
    },
  ];

  openUrl(row: iBill) {
    window.open(row.url, '_blank');
  }
}
