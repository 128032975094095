<div class="sidebarGeneralContainer" [class.opened]="isOpened">
  <div class="botonDesplegable" (click)="controlOpenSidebar()">
    <span>
      <mat-icon>chevron_right</mat-icon>
    </span>
  </div>
  <div class="menu">
    <span class="logo">
      <a
        tooltip
        [text]="'Home'"
        [delay]="0"
        [routerLink]="['/home']"
        routerLinkActive="active"
        routerAnimationLeft
      >
        <img src="/assets/Images/Logos/Logo Oficial IEM.svg" alt="" />
      </a>
      <div class="titleLogo" [class.opened]="isOpened">Cerebro IEM</div>
    </span>
    <div class="subnav">
      <a
        class="subnav_item"
        tooltip
        [text]="'Formaciones'"
        [delay]="0"
        [routerLink]="['/formaciones']"
        routerLinkActive="active"
        routerAnimationLeft
        *ngIf="
          userData.permissions.includes(3) ||
          userData.permissions.includes(4) ||
          userData.roles.includes('ROLE_ADMIN') ||
          userData.roles.includes('ROLE_SUPER_ADMIN')
        "
      >
        <img src="/assets/Icons/Sidebar/formaciones.svg" alt="" />
        <span class="itemTitle" [class.opened]="isOpened">Formaciones</span>
      </a>
      <a
        class="subnav_item"
        tooltip
        [text]="'Mis alumnos'"
        [delay]="0"
        [routerLink]="['/alumnos/mis-alumnos/listado-alumnos']"
        routerLinkActive="active"
        routerAnimationLeft
        *ngIf="
          userData.permissions.includes(3) ||
          userData.permissions.includes(4) ||
          userData.roles.includes('ROLE_ADMIN') ||
          userData.roles.includes('ROLE_SUPER_ADMIN')
        "
      >
        <img src="/assets/Icons/Sidebar/misAlumnos.svg" alt="" />
        <span class="itemTitle" [class.opened]="isOpened">Mis alumnos</span>
      </a>

      <a
        class="subnav_item"
        tooltip
        [text]="'Matriculaciones'"
        [class.desplegado]="isUnfoldedEnrolls"
        [delay]="0"
        (click)="isOpened ? controlDesplegadoEnrolls() : controlOpenSidebar()"
        [class.active]="url.includes('matriculaciones')"
        routerLinkActive="active"
        *ngIf="
          userData.permissions.includes(5) ||
          userData.permissions.includes(6) ||
          userData.roles.includes('ROLE_ADMIN') ||
          userData.roles.includes('ROLE_SUPER_ADMIN')
        "
      >
        <div class="icon_with_arrow">
          <mat-icon>recent_actors</mat-icon>
          <mat-icon class="absolute" *ngIf="!isOpened">expand_more</mat-icon>

          <!-- <mat-icon class="arrow_icon" *ngIf="!isOpened">expand_more</mat-icon> -->
        </div>
        <div
          class="info"
          [class.opened]="isOpened"
          [class.desplegado]="isUnfoldedEnrolls"
        >
          <span>Matriculaciones</span>
          <mat-icon>expand_more</mat-icon>
        </div>
      </a>
      <div
        class="unfoldedContainer"
        [class.desplegado]="isUnfoldedEnrolls"
        *ngIf="isOpened"
      >
        <a
          [routerLink]="[
            '/matriculaciones/masters-cursos/listado-matriculaciones'
          ]"
          routerAnimationLeft
          >Masters</a
        >
        <a
          [routerLink]="['/matriculaciones/cursos/listado-matriculaciones']"
          routerAnimationLeft
          >Cursos</a
        >
        <a
          [routerLink]="[
            '/matriculaciones/incompanies/listado-matriculaciones'
          ]"
          routerAnimationLeft
          >Incompany</a
        >
      </div>

      <a
        class="subnav_item"
        tooltip
        [text]="'Mis profesores'"
        [delay]="0"
        [routerLink]="['/profesores/mis-profesores/listado-profesores']"
        routerLinkActive="active"
        routerAnimationLeft
        [class.active]="
          url.includes('ficha-profesor') || url.includes('crear-profesor')
        "
        *ngIf="
          userData.permissions.includes(1) ||
          userData.permissions.includes(2) ||
          userData.roles.includes('ROLE_ADMIN') ||
          userData.roles.includes('ROLE_SUPER_ADMIN')
        "
      >
        <img src="/assets/Icons/Sidebar/profesores.svg" alt="" />
        <span class="itemTitle" [class.opened]="isOpened">Mis profesores</span>
      </a>

      <a
        class="subnav_item"
        tooltip
        [text]="'Calendario'"
        [delay]="0"
        [routerLink]="['/calendario-general']"
        routerLinkActive="active"
        routerAnimationLeft
        [class.active]="url.includes('calendario-general')"
        *ngIf="
          userData.permissions.includes(1) ||
          userData.permissions.includes(2) ||
          userData.roles.includes('ROLE_ADMIN') ||
          userData.roles.includes('ROLE_SUPER_ADMIN')
        "
      >
        <img src="/assets/Icons/Sidebar/calendario.svg" alt="" />
        <span class="itemTitle" [class.opened]="isOpened">Calendario</span>
      </a>

      <a
        class="subnav_item"
        tooltip
        [text]="'Configuración'"
        [delay]="0"
        [routerLink]="['/configuracion/configuracion-general']"
        routerLinkActive="active"
        routerAnimationLeft
        [class.active]="url.includes('configuracion')"
      >
        <mat-icon>settings</mat-icon>
        <span class="itemTitle" [class.opened]="isOpened">Configuración</span>
      </a>
    </div>

    <!-- <div class="subnav">
      <a class="subnav_item especial" >
        <img src="/assets/Icons/Sidebar/añadir.svg" alt="">
        <span class="itemTitle" [class.opened]="isOpened">Nuevo...</span>
      </a>
    </div> -->
  </div>
</div>
