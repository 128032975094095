import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import { iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { FormationService } from '../Api/Formation.service';

@Injectable({
  providedIn: 'root',
})
export class EditFormationService {

  constructor(
    private formationSE: FormationService,
    private generalLoaderSE: GeneralLoaderService
  ) {}

  editFormacion(objectToPass: any,bs: BehaviorSubject<any>) {
    const subject: Subject<any> = StaticUtilitiesService.createSubject();
    this.formationSE.update(objectToPass);
    this.formationSE
      .getResultUpdate()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        bs.next(res);
        subject.next('');
      });

    this.formationSE
      .getResultUpdateError()
      .pipe(takeUntil(subject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        subject.next('');
      });
  }
}
