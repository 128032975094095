<div
  class="ratingPill"
  (click)="$event.stopPropagation(); $event.stopImmediatePropagation()"
>
  <p
    class="textInsidePill"
    [canEdit]="!options.readonly"
    text-editable
    (input)="getEditableContent($event)"
  >
    {{ mark ?? "0" }}
  </p>
</div>
