<div class="informacionGeneralContainer">
  <pill classList="informacion" skeleton [isDataLoaded]="isDataLoaded">
    <ng-container>
      <div class="info">
        <div class="bio">
          <label for="">Bio</label>
          <p>{{ dataStudent?.user?.biography }}</p>
        </div>
        <div class="otrosDatos">
          <div>
            <label for="">Sexo</label>
            <p>{{ dataStudent?.user?.gender }}</p>
          </div>
          <div>
            <label for="">Fecha de nacimiento</label>
            <p>{{ dataStudent?.user?.dateOfBirth | date : "dd/MM/YYYY" }}</p>
          </div>
          <div>
            <label for="">Edad</label>
            <p>{{ dataStudent?.user?.age }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
  <pill classList="informacion" skeleton [isDataLoaded]="isDataLoaded">
    <div class="info">
      <div class="otrosDatos">
        <div>
          <label for="">País</label>
          <p>{{ dataStudent?.user?.address?.country?.nombre }}</p>
        </div>
        <div>
          <label for="">Nacionalidad</label>
          <p>{{ dataStudent?.user?.nationality.ethnicity }}</p>
        </div>
        <div>
          <label for="">Provincia</label>
          <p>{{ dataStudent?.user?.address?.province }}</p>
        </div>
        <div>
          <label for="">Población</label>
          <p>{{ dataStudent?.user?.address?.municipality }}</p>
        </div>
        <div>
          <label for="">Dirección</label>
          <p>
            {{ dataStudent?.user?.address?.street }} ,
            {{ dataStudent?.user?.address?.number }}
          </p>
        </div>
        <div>
          <label for="">DNI / NIE / Pasaporte</label>
          <p>{{ dataStudent?.user?.dni }}</p>
        </div>
      </div>
    </div>
  </pill>
  <pill classList="informacion" skeleton [isDataLoaded]="isDataLoaded">
    <div class="info">
      <div class="otrosDatos">
        <div>
          <label for="">Tipo de alumno</label>
          <p>{{ dataStudent?.user?.typeOf }}</p>
        </div>
        <div>
          <label for="">Empresa</label>
          <p>{{ dataStudent?.user?.company }}</p>
        </div>
        <div>
          <label for="">Situación laboral</label>
          <p>
            {{
              dataStudent?.user?.employmentStatus === "Asalariado"
                ? "Asalariado"
                : "Autónomo"
            }}
          </p>
        </div>
        <div>
          <label for="">Cargo</label>
          <p>{{ dataStudent?.user?.jobPosition }}</p>
        </div>
        <div>
          <label for="">Interesado en prácticas</label>
          <p>{{ dataStudent?.intershipInterested ? "Si" : "No" }}</p>
        </div>
        <div>
          <label for="">Idiomas</label>
          <div class="lineaDeIdiomas">
            <p *ngFor="let language of dataStudent?.user?.languages">
              {{ language }}
            </p>
          </div>
        </div>
        <div>
          <label for="">Redes sociales</label>
          <div class="iconsRedesSociales">
            <span
              class="iconoRedSocial"
              (click)="
                openSocialMedia(dataStudent?.user?.socialMedia?.linkedin)
              "
              *ngIf="dataStudent?.user?.socialMedia?.linkedin"
            >
              <img
                src="/assets/Icons/SocialMedia/linkedin.svg"
                alt="linkedinIcon"
              />
            </span>
            <span
              class="iconoRedSocial"
              (click)="
                openSocialMedia(dataStudent?.user?.socialMedia?.instagram)
              "
              *ngIf="dataStudent?.user?.socialMedia?.instagram"
            >
              <img
                src="/assets/Icons/SocialMedia/instagram.svg"
                alt="instagramIcon"
              />
            </span>
            <span
              class="iconoRedSocial"
              (click)="
                openSocialMedia(dataStudent?.user?.socialMedia?.facebook)
              "
              *ngIf="dataStudent?.user?.socialMedia?.facebook"
            >
              <img
                src="/assets/Icons/SocialMedia/facebook.svg"
                alt="facebookIcon"
              />
            </span>
            <span
              class="iconoRedSocial"
              (click)="openSocialMedia(dataStudent?.user?.socialMedia?.tiktok)"
              *ngIf="dataStudent?.user?.socialMedia?.tiktok"
            >
              <img
                src="/assets/Icons/SocialMedia/tiktok.svg"
                alt="tiktokIcon"
              />
            </span>
            <span
              class="iconoRedSocial"
              (click)="openSocialMedia(dataStudent?.user?.socialMedia?.x)"
              *ngIf="dataStudent?.user?.socialMedia?.x"
            >
              <img src="/assets/Icons/SocialMedia/x.svg" alt="xIcon" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </pill>
  <pill classList="informacion" skeleton [isDataLoaded]="isDataLoaded">
    <div class="info" [ngStyle]="{ 'justify-content': 'flex-start' }">
      <div class="datosBancarios">
        <label for="">Datos fiscales</label>
        <div>
          <label for="">Dirección</label>
          <p>
            {{ dataStudent?.user?.bankData?.fiscalAddress?.street ?? "" }}
            ,
            {{ dataStudent?.user?.bankData?.fiscalAddress?.number ?? "" }}
            <br />
            {{ dataStudent?.user?.bankData?.fiscalAddress?.municipality ?? "" }}
            (
            {{ dataStudent?.user?.bankData?.fiscalAddress?.postalCode ?? "" }}
            ),
            {{
              dataStudent?.user?.bankData?.fiscalAddress?.country?.nombre ?? ""
            }}
          </p>
        </div>
      </div>
      <div class="datosBancarios">
        <label for="">Datos bancarios</label>
        <div>
          <label for="">IBAN</label>
          <p>{{ dataStudent?.user?.bankData?.iban }}</p>
        </div>
      </div>
      <div class="datosBancarios">
        <div [ngStyle]="{ 'border-bottom': 'none' }">
          <label for="">BIC/SWIFT</label>
          <p>{{ dataStudent?.user?.bankData?.bic }}</p>
        </div>
      </div>
    </div>
  </pill>
  <pill
    classList="informacion"
    *ngIf="!isEditing"
    skeleton
    [isDataLoaded]="isDataLoaded"
  >
    <div class="info" [ngStyle]="{ 'justify-content': 'flex-start' }">
      <div class="textAndIcon">
        <label for="">Observaciones y anotaciones</label>
        <mat-icon (click)="changeEdit()">
          <span class="tag">Editar</span>
          edit</mat-icon
        >
      </div>
      <div class="area">
        <div>{{ dataStudent?.user?.observation }}</div>
      </div>
    </div>
  </pill>
  <pill
    classList="informacion"
    *ngIf="isEditing"
    skeleton
    [isDataLoaded]="isDataLoaded"
  >
    <div class="info" [ngStyle]="{ 'justify-content': 'flex-start' }">
      <div class="textAndIcon">
        <label for="">Observaciones y anotaciones</label>
        <mat-icon (click)="changeEdit()">
          <span class="tag">Cerrar</span>
          close</mat-icon
        >
      </div>
      <div class="area">
        <textarea
          (change)="editObresvaciones()"
          [(ngModel)]="dataStudent.user.observation"
        ></textarea>
      </div>
    </div>
  </pill>
</div>
