<div class="fullView">
  <div class="formLine equalFive">
    <selector
      style="width: 100%"
      [options]="conceptSelectorOptions"
      [(ngModel)]="conceptSelected"
      (change)="getConceptSelected(conceptSelected)"
    ></selector>

    <div class="priceDisplay" *ngIf="conceptSelected">
      <p class="label">Total a pagar</p>
      <p class="value">{{ amounts.totalPrice | currency : "EUR" }}</p>
    </div>

    <div class="priceDisplay" *ngIf="conceptSelected">
      <p class="label">Total pagado</p>
      <p class="value">{{ amounts.totalPaid | currency : "EUR" }}</p>
    </div>

    <div class="priceDisplay" *ngIf="conceptSelected">
      <p class="label">Pendiente de pago</p>
      <p class="value">{{ amounts.pendingAmount | currency : "EUR" }}</p>
    </div>

    <btn
      style="width: fit-content"
      classList="genericbutton principalButton"
      *ngIf="conceptSelected"
      (click)="openPopupSubirFactura()"
      >Añadir factura</btn
    >
  </div>

  <main-table [headerData]="headerData" [dataSource]="facturas"></main-table>
  <pagination-controls
    [autoHide]="true"
    previousLabel="Anterior"
    nextLabel="Siguiente"
    (pageChange)="pageChange($event)"
    *ngIf="total > filtroBill.num_devoluciones"
  ></pagination-controls>
</div>
