import { Component, OnInit } from '@angular/core';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-GestionUsuarios-Configuracion',
  templateUrl: './GestionUsuarios-Configuracion.component.html',
  styleUrls: ['./GestionUsuarios-Configuracion.component.scss'],
})
export class GestionUsuariosConfiguracionComponent implements OnInit {
  rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  selectedButton: number = 0;

  arrayLines: { title: string }[] = [
    { title: 'Cerrar sesión' },
    { title: 'Permisos' },
    { title: 'Aulas' },
    { title: 'Disposición' },
    { title: 'Certificados' },
  ];

  constructor() {}

  ngOnInit() {
    this.rolesAndPermissions =
      StaticUtilitiesService.getUserPermissionsAndRoles();
    this.checkIfRoleAllows();
  }

  /**
   * FUNCTIONALITY
   */

  checkIfRoleAllows() {
    if (!this.checkRolesAndPermissions()) {
      this.arrayLines = [{ title: 'Cerrar sesión' }];
    }
  }

  activateLine(index: number) {
    this.selectedButton = index;
  }

  checkRolesAndPermissions() {
    return (
      this.rolesAndPermissions.roles.includes('ROLE_ADMIN') ||
      this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN')
    );
  }
}
