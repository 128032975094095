export interface iTutoring {
    teacherId: number;
    teacherName: string;
    tutorings: iTutoringSingle[];
  
}

export interface iTutoringSingle {
    tutoringId: number;
    teacherId: number;
    teacherName: string;
    landmarkParentId: number;
    startDate: string;
    hours: string;
    slotMinutes: number;
    tutoringAttendanceName: string;
    classroomName: string | null;
    tutoringStateName: string;
    tutoringStateColor: string;
    observations: string;
    formationName: string;
    topicName: string;
    formationNames: string[];
    topicNames: string[];
  }

  
  export class ITutoring {
    static getEmptyObject(): iTutoring {
        return {
            teacherId: 0,
            teacherName: '',
            tutorings: [{
                teacherId: 0,
                teacherName: '',
                tutoringId: 0,
                landmarkParentId: 0,
                startDate: '',
                hours: '',
                slotMinutes: 0,
                tutoringAttendanceName: '',
                classroomName: '',
                tutoringStateName: '',
                tutoringStateColor: '',
                observations: '',
                formationName: '',
                topicName: '',
                formationNames: [''],
                topicNames: ['']
            }]
        };
        }
  }