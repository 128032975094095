<div class="tableContainer">

    <table class="mainTable" >
      <thead class="thead">
        <tr class="tr">
          <th class="th"> <mat-checkbox [checked]="allSelected" (change)="selecteAll($event)"></mat-checkbox></th>
          <th class="th" *ngFor="let item of displayedColumns"
            [ngClass]="{justifyContentFlexStart:item?.displayName === 'Nombre', estadoHeaderTable:item?.displayName === 'Estado'}">
            {{item.displayName}}  </th>
        </tr>
      </thead>
      <tbody class="tbody">
        <tr class="loaderLine">
          <loaderIcon *ngIf="!isDataLoaded"></loaderIcon >
        </tr>
        <div class="noItems" *ngIf="alumnos.length == 0">
          No hay items que mostrar
        </div>
        <tr class="tr" *ngFor="let alumno of alumnos | paginate:{
        itemsPerPage: filters.num_devoluciones,
        currentPage: filters.num_pagina,
        totalItems: total
      }" (click)="goToDetailView(alumno)">
          <td class="td"> <mat-checkbox (click)="$event.stopPropagation()" (change)="addSelected(alumno)"
              [checked]="alumnosSelected.includes(alumno)"></mat-checkbox></td>

          <td class="td" *ngFor="let column of displayedColumns; let index = index;"
            [ngClass]="{fwBold: column?.property === 'name',justifyContentFlexStart:column?.property === 'name' ,estadoTable:column?.property === 'state',dFlexGap1em:column?.property === 'enrollments' }">
            <p
              [ngClass]="{timeBox: column?.property === 'startDate' || column?.property === 'endDate', estadoTabla: column?.property === 'state', formacionTabla: column?.property === 'enrollments', estadoTablaCursando:column?.property === 'state' && alumno.state == 'cursando' }">
              {{alumno[column?.property] ?? '-'}} </p> <span
              *ngIf="column?.property === 'enrollments' && alumno?.extraEnrollments > 0"
              class="plusEnrollments">+{{alumno?.extraEnrollments}} </span>
          </td>
        </tr>
      </tbody>
    </table>


</div>