<div class="fullPage">
  <div class="loginSection">
    <form class="loginBlock">
      <div class="logoIEM">
        <img src="/assets/Images/Logos/LogoIEMLogin.svg" alt="LogoIem">
      </div>
      <div class="textLogin">
        <p>¡Hola!</p>
        <p>Bienvenido al cerebro de IEM</p>
      </div>
      <div class="separador">
        <span class="line"></span>
      </div>
      <div class="inputsLogin">
        <CustomInput class="customInputLogin"  [type]="'text'" [icon]="'person'" [placeHolder]="'Usuario'" [(ngModel)]="data.username"
        name="user" (change)="checkEmail(data.username)" ></CustomInput>
        <CustomInput class="customInputLogin"  [type]="'password'" [icon]="'lock'" [placeHolder]="'Contraseña'" [(ngModel)]="data.password"
        name="password" (keyup)="checkDisabled()" ></CustomInput>
      </div>
      <div class="forgottenPassword" (click)="goToForgottenPassword()">
        <p >He olvidado mi contraseña</p>
      </div>
      <div class="buttonLogin">
        <btn id='btn' (click)=" (disabled) ? $event.preventDefault() : submitLogin()" classList="loginButton" [ngClass]="{disabled: disabled}" > <loaderIcon class="loaderIconWhite" [size]="'small'" *ngIf="isLogging"></loaderIcon> <p *ngIf="!isLogging"> Iniciar sesión </p> </btn>
      </div>
    </form>
  </div>
  <div class="imagesSection">
    <div class="textImageBlock">
      <div class="text">
        <p>Profesionales</p>
        <p>que forman profesionales</p>
      </div>
      <div class="textDecoration">
        <img src="/assets/Icons/Decoracion/decoracionLoginIEM.svg" alt="decoracion">
      </div>
    </div>
    <div class="imagesGrid">
      <div class="azulVerdoso"></div>
      <div class="imageTop">
        <img src="/assets/Images/Login/womanTyping.png" alt="Mujer al ordenador">
      </div>
      <div class="imageBottom">
        <img src="/assets/Images/Login/handsTyping.png" alt="Mujer al ordenador">
      </div>
      <div class="grey"></div>
      <div class="violet"></div>

    </div>
  </div>
</div>