import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iOptionsSelector,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { EnrollmentService } from 'src/app/Services/Api/Enrollment.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { iPromotionFormationFilter } from 'src/app/Shared/Interfaces/iPromotionFormation';

@Component({
  selector: 'app-AddStudentEnrollment-Popup',
  templateUrl: './AddStudentEnrollment-Popup.component.html',
  styleUrls: ['./AddStudentEnrollment-Popup.component.scss'],
})
export class AddStudentEnrollmentPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  dataFromCRM: any = null;
  optionSubmenuSelected: number = 0;
  unAlumnoSelected: number = 0;
  variosAlumnosSelected: number = 0;
  dataUnAlumnoSelectedFirstStep: any = null;
  dataUnAlumnoSelectedSecondStep: any = null;
  dataUnVariosAlumnosFirstStep: any = null;
  dataVariosAlumnosSecondStep: any = null;

  disabledButton: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<AddStudentEnrollmentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private promotionSE: PromotionFormationService,
    private enrollmentSE: EnrollmentService
  ) {
    super();
  }

  ngOnInit() {
    if (this.modalData?.data) {
      this.dataFromCRM = this.modalData?.data;
    }
  }

  /**
   * GETTERS && SETTERS
   */

  getDataUnAlumnoSelectedFirstStep(event) {
    this.dataUnAlumnoSelectedFirstStep = event;
    this.dataUnAlumnoSelectedFirstStep = {
      ...this.dataUnAlumnoSelectedFirstStep,
      type: this.modalData.type,
    };

    let emptyValues = Object.values(
      this.dataUnAlumnoSelectedFirstStep.data
    ).filter((x) => x == null || x == '');
    if (emptyValues.length == 0) {
      this.disabledButton = false;
    }
  }

  getDataUnAlumnoSelectedSecondStep(event) {
    this.dataUnAlumnoSelectedSecondStep = event;
    let emptyValues = Object.values(event).filter(
      (x) => x == null || x == undefined
    );
    if (
      emptyValues.length > 0 ||
      (emptyValues.length == 1 && emptyValues[0] != false)
    ) {
      this.disabledButton = true;
    }
    if (
      emptyValues.length == 1 ||
      (emptyValues.length == 2 && emptyValues[0] == false)
    ) {
      this.disabledButton = false;
    }
  }

  getDataUnVariosAlumnosFirstStep(event) {
    this.dataUnVariosAlumnosFirstStep = event;
    let emptyValues = Object.values(
      this.dataUnVariosAlumnosFirstStep.data
    ).filter((x) => x == null || x == '');
    if (emptyValues.length == 0) {
      this.disabledButton = false;
    }
  }
  getDataVariosAlumnosSecondStep(event) {
    this.dataVariosAlumnosSecondStep = event;
    let emptyValues = Object.values(event).filter((x) => x == null);
    if (
      emptyValues.length > 0 ||
      (emptyValues.length == 1 && emptyValues[0] != false)
    ) {
      this.disabledButton = true;
    }
    if (
      emptyValues.length == 0 ||
      (emptyValues.length == 1 && emptyValues[0] == false)
    ) {
      this.disabledButton = false;
    }
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  nextButton() {
    if (this.optionSubmenuSelected == 0 && this.unAlumnoSelected == 1) {
      this.enrollOneUser(true);
      return;
    }
    if (this.optionSubmenuSelected == 0) {
      this.unAlumnoSelected = 1;
      this.disabledButton = true;
    }
    if (this.optionSubmenuSelected == 1) {
      this.variosAlumnosSelected = 1;
      this.disabledButton = true;
    }
  }
  backButton() {
    if (this.optionSubmenuSelected == 0) {
      this.unAlumnoSelected = 0;
      this.disabledButton = false;
    }
    if (this.optionSubmenuSelected == 1) {
      this.variosAlumnosSelected = 0;
      this.disabledButton = false;
    }
  }
  clearSelecteds() {
    this.unAlumnoSelected = 0;
    this.variosAlumnosSelected = 0;
  }

  prepareDataForOneUser(toSend) {
    let enrolls: any = [];
    let finalObject: any = {};

    this.dataUnAlumnoSelectedSecondStep.promotion.forEach((element, index) => {
      let obj: any = {
        state: 'correo enviado',
        promotion: this.dataUnAlumnoSelectedSecondStep.promotion[index],
        student: {
          user: {
            name: this.dataUnAlumnoSelectedFirstStep.data.name,
            surname: this.dataUnAlumnoSelectedFirstStep.data.surname,
            email: this.dataUnAlumnoSelectedFirstStep.data.email,
            phone: this.dataUnAlumnoSelectedFirstStep.data.phone,
            phonePrefix: this.dataUnAlumnoSelectedFirstStep.data.phonePrefix,
            dni: this.dataUnAlumnoSelectedFirstStep.data.dni,
          },
        },
        modality: this.dataUnAlumnoSelectedSecondStep.modality[index],
        type: this.dataUnAlumnoSelectedSecondStep.type,
        specialConditions:
          this.dataUnAlumnoSelectedSecondStep.specialConditions,
      };
      if (this.modalData?.data) {
        obj.dealId = this.modalData?.data?.id;
      }
      enrolls.push(obj);
    });
    finalObject = {
      ...finalObject,
      enrolls: enrolls,
      isDouble: this.dataUnAlumnoSelectedSecondStep.isDouble,
      doubleTitulation: this.dataUnAlumnoSelectedSecondStep.doubleTitulation,
      toSend: toSend,
    };
    return finalObject;
  }

  /**
   * API CALLS
   */

  async enrollOneUser(toSend) {
    let data: any = this.prepareDataForOneUser(toSend);

    this.enrollmentSE.create(data);
    this.enrollmentSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showFeedback('Alumno matriculado correctamente');
        if (!toSend) {
          let url = res.data;
          window.open(url, '_blank');
        }
        this.closePopup();
        this._unsubInd.next('');
      });
    this.enrollmentSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) return;

        StaticUtilitiesService.showError(res.message);
        this._unsub.next('');
      });
  }

  enrollGroup() {}

  openAutomatricula() {}
}
