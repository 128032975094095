<div class="fullContainer">
  <div class="filtersLine">
    <toggleSubmenu
      class="secondary"
      [elements]="[
        'Todo',
        'Másters',
        'Cursos',
        'InCompany',
        'Tutorías',
        'Tribunales'
      ]"
      [(ngModel)]="extraClass.filterTypeSubmenuSelected"
      (change)="sendTypeToFilter()"
      (onValueChange)="onToggleChange($event)"
    ></toggleSubmenu>
    <div class="searchAndFilterButton">
      <customSearcher (change)="getSearch($event)"></customSearcher>
      <btn classList="generalbutton violetButton" (click)="openAddBillPopup()"
        >Añadir factura</btn
      >
    </div>
  </div>
  <div class="tableContainer">
    <main-table
      [headerData]="extraClass.headerData"
      [dataSource]="extraClass.pendingBills"
    ></main-table>
    <ng-container
      *ngFor="
        let item of extraClass.pendingBills
          | paginate
            : {
                itemsPerPage: extraClass.pendingBillsFilters.num_devoluciones,
                currentPage: extraClass.pendingBillsFilters.num_pagina,
                totalItems: extraClass.totalPendingBills
              }
      "
    ></ng-container>

    <pagination-controls
      [autoHide]="true"
      previousLabel="Anterior"
      nextLabel="Siguiente"
      (pageChange)="onPageChange($event)"
    ></pagination-controls>
  </div>
</div>
