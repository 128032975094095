<div 
  cdkDropList 
  class="detailViewContent__modules" 
  [cdkDropListDisabled]="!isEditing" 
  (cdkDropListDropped)="handleDrop($event)" 
>
  <ng-container *ngFor="let module of modules; let moduleIndex = index; trackBy:moduleTracking">
    <div cdkDrag cdkDragLockAxis="y" >
      <div class="detailViewContent__moduleTitle" >
        <mat-icon 
          cdkDragHandle 
          *ngIf="isEditing" 
          class="detailViewContent__sessionDrag"
        >drag_indicator</mat-icon>
        <h3>Módulo {{moduleIndex + 1}}: 
          <span
            text-editable
            [canEdit]="isEditing"
            [ngModel]="module.moduleTitle"
            placeholder="Nombre del módulo"
            (handlerChange)="handleModuleNameChange($event, module)"
          >
            {{module.moduleTitle}}
          </span>
        </h3>
        <button 
          *ngIf="isEditing"
          (click)="handleClickDeleteModule(module.moduleId)" 
          class="detailViewContent__moduleDelete"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <DetailView-Content-Module
        [sessions]="module.sessions"
        [isEditing]="isEditing"
        [module]="module"
        (sessionsChange)="changesInSessions($event, moduleIndex)"
        (onCreate)="handleCreateSession($event)"
      ></DetailView-Content-Module>
    </div>
  </ng-container>
  <button 
    class="detailViewContent__addModule" 
    *ngIf="isEditing" 
    (click)="addModule()"
  ><mat-icon>add_circle_outline</mat-icon></button>
</div>