import {
  IOptionCustomTextArea,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';

export class EditTutoringPopupClass {
    topicIds: any[] = []
  observations: any = null;
  topicDisplay: any = null;
  tutoringStateId:any = null;

  isActive: boolean = false;

  // selectorOptions
  tematicasSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleTextReplacer: 'Varias temáticas seleccionadas',
    },
    bindLabel: 'name',
    bindValue: 'id',
    clearable: false,
    label: 'Temáticas *',
    placeholder: 'Seleccione temáticas',
    search: true,
  };
  tutoringStatusSelectorOptions: iOptionsSelector = {
    items: [],
    bindLabel: 'name',
    bindValue: 'id',
    clearable: false,
    label: 'Estado *',
    placeholder: 'Seleccione estado',
    search: false,
  };

  //   customInputOptions
  tematicasDisplaySelectorOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Seleccione temáticas',
    readOnly: true,
  };

  //   Custom textarea options
  observacionesCustomTextareaOptions: IOptionCustomTextArea = {
    cols: 30,
    rows: 10,
    label: 'Observaciones',
    placeholder: 'Introduzca observaciones',
  };
}
