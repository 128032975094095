import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  NgFooterTemplateDirective,
  NgHeaderTemplateDirective,
  NgLabelTemplateDirective,
  NgLoadingSpinnerTemplateDirective,
  NgLoadingTextTemplateDirective,
  NgMultiLabelTemplateDirective,
  NgNotFoundTemplateDirective,
  NgOptgroupTemplateDirective,
  NgOptionTemplateDirective,
  NgSelectComponent,
  NgTagTemplateDirective,
  NgTypeToSearchTemplateDirective,
} from '@ng-select/ng-select';
import { VariablesDesignSystemService } from '@quasar-dynamics/basic-designsystem';
import { iOptionsSelector } from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'add-and-search-selector',
  templateUrl: './add-and-search-selector.component.html',
  styleUrls: ['./add-and-search-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddAndSearchSelectorComponent),
      multi: true,
    },
  ],
})
export class AddAndSearchSelectorComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelect?: NgSelectComponent;

  //Events
  @Output('add') add = new EventEmitter();
  @Output('remove') remove = new EventEmitter();
  @Output('close') close = new EventEmitter();
  @Output('clear') clear = new EventEmitter();
  @Output() readonly change: EventEmitter<any> = new EventEmitter<any>();

  @Output() selectedOptionOutput: EventEmitter<any> = new EventEmitter<any>();

  @Output() addElementToSelector: EventEmitter<any> = new EventEmitter<any>();
  
  @Output() deleteSelector: EventEmitter<any> = new EventEmitter<any>();

  @Input() selectorOptions: iOptionsSelector = {
    items: [1, 2, 3, 4],
    search: true,
    clearable: false,
    bindLabel: 'name',
    bindValue: 'id',
  };

  @Input() model: any = null;
  @Input() reverse: boolean = false;

  // Templates para poder hacer un override a las templates puestas en este selector
  @ContentChild(NgOptionTemplateDirective, { read: TemplateRef })
  optionTemplate: TemplateRef<any> | undefined;
  @ContentChild(NgOptgroupTemplateDirective, { read: TemplateRef })
  optgroupTemplate: TemplateRef<any> | undefined;
  @ContentChild(NgLabelTemplateDirective, { read: TemplateRef }) labelTemplate:
    | TemplateRef<any>
    | undefined;
  @ContentChild(NgMultiLabelTemplateDirective, { read: TemplateRef })
  multiLabelTemplate: TemplateRef<any> | undefined;
  @ContentChild(NgHeaderTemplateDirective, { read: TemplateRef })
  headerTemplate: TemplateRef<any> | undefined;
  @ContentChild(NgFooterTemplateDirective, { read: TemplateRef })
  footerTemplate: TemplateRef<any> | undefined;
  @ContentChild(NgNotFoundTemplateDirective, { read: TemplateRef })
  notFoundTemplate: TemplateRef<any> | undefined;
  @ContentChild(NgTypeToSearchTemplateDirective, { read: TemplateRef })
  typeToSearchTemplate: TemplateRef<any> | undefined;
  @ContentChild(NgLoadingTextTemplateDirective, { read: TemplateRef })
  loadingTextTemplate: TemplateRef<any> | undefined;
  @ContentChild(NgTagTemplateDirective, { read: TemplateRef }) tagTemplate:
    | TemplateRef<any>
    | undefined;
  @ContentChild(NgLoadingSpinnerTemplateDirective, { read: TemplateRef })
  loadingSpinnerTemplate: TemplateRef<any> | undefined;

  // create a random name for the component
  name: string = Math.random().toString(36).substring(7);

  selectorText: any = null;

  selectedFromList: any = null;

  //INTERNAL VALUES
  private _onChange = (_: any) => {};
  private _onTouched = () => {};
  private _oldData;
  _disabled: boolean = false;

  //#region Manejo de ngModel

  /**
   * Controlar la actualización del ngModel desde el componente
   * @param {any} obj - Dato que se actualiza
   */
  writeValue(obj: any): void {
    this.model = obj;
  }
  /**
   * Controla automáticamente el estado disabled
   * @param {boolean} isDisabled - Cambiar el estado del disabled
   */
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }
  /**
   * Registrar el onChange del ngModel. Actualiza el dato desde el selector, es muy parecido al EventEmitter
   * @param {Fuction} fn - Función onChange
   */
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  /**
   * Registra el onTouched. Es muy parecido al EventEmitter
   * @param {Fuction} fn - Funcion onTouched
   */
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  //#endregion

  constructor() {}

  ngOnInit() {}

  getSelectorText(event: any) {
    // We get the input value from the selector and we store it in the selectorText variable
    const value = event.target.value;
    this.selectorText = value;
  }

  getChangeSelector(event) {
    // We get the selected value from the selector and we store it in the selectedOption variable
    this.selectedFromList = event;
    this.selectedOptionOutput.emit(
      this.selectorOptions.bindValue
        ? this.selectedFromList[this.selectorOptions.bindValue]
        : this.selectedFromList
    );
  }

  getSelector(event) {
    // If we have a selected value from the list we set it as the selectedOption
    if (this.selectedFromList) {
      this.model = this.selectorOptions.bindValue
      ? this.selectedFromList[this.selectorOptions.bindValue]
      : this.selectedFromList;
      this.selectedFromList = null;
      return;
    }
    // If not, we store the selected value from the input in the selectorText variable
    const value = this.selectorText;
    // If the value is empty we return
    if (!value) return;
    // We check if the value is already in the items array
    const itemFound = this.selectorOptions.items.find((item) => item === value);
    // If the value is already in the items array we set it as the selectedOption
    if (itemFound) {
      this.model = itemFound;
      this.selectedOptionOutput.emit(this.model);
    } else {
      // If the value is not in the items array we add it to the items array and we set it as the selectedOption
      // Here will be the logic to add the value to the items array
      this.model = value;
      this.addElementToSelector.emit(value);
    }
    // We reset the selectorText variable
    this.selectorText = null;
  }
  
  /**
   * Controla cuando el ngModel del ng-select cambia
   * @param {any} event - Elemento que actualiza
   */
  onNgModelChange(event) {
    this._oldData = this.model;
    this.model = event;
  }

  //#region Funcionamiento interno

  /**
   * FUNCIONAMIENTO INTERNO - Soluciona un problema el cual debería de admitir nulos y no admite nulos debido a Angular 13 y sus restricciones de tipo
   * @param {any} value - Valor
   * @returns {any}
   */
  returnTypeAny(value): any {
    return value;
  }

  /**
   * FUNCIONAMIENTO INTERNO - Devuelve a la propiedad al append del ng-select si se va ha añadir o no al body
   * @returns
   */
  checkAppendTo() {
    if (this.selectorOptions.append) {
      return 'body';
    }
    return null;
  }

  /**
   * FUNCIONAMIENTO INTERNO - Devuelve el valor por defecto configurado en el servicio de variables del sistema de diseño cuando está vació los items
   * @returns {string}
   */
  getDefaultEmptyText() {
    return VariablesDesignSystemService.getDefaultSelectorEmpty();
  }

  /**
   * FUNCIONAMIENTO INTERNO - Devuelve el valor por defecto configurado en el servicio de variables del sistema de diseño cuando no encuentra nada lo que busca
   * @returns {string}
   */
  getDefaultNotFound() {
    return VariablesDesignSystemService.getDefaultSelectorNotFound();
  }

  /**
   * FUNCIONAMIENTO INTERNO - Devuelve el valor por defecto configurado en el servicio de variables del sistema de diseño del texto "más..."
   * @returns {string}
   */
  getSelectorMoreText() {
    return VariablesDesignSystemService.getDefaultSelectorMore();
  }

  /**
   * FUNCIONAMIENTO INTERNO - Devuelve el valor por defecto configurado en el servicio de variables del sistema de diseño del texto "Añadir elemento"
   * @returns {string}
   */
  getSelectorAddTag() {
    return VariablesDesignSystemService.getDefaultSelectorAddTag();
  }

  /**
   * FUNCIONAMIENTO INTERNO. Sirve para coger el label o el bindLabel configurado en la template del multiple
   * @param {Object} item - Elemento del bucle
   * @returns {string}
   */
  checkBindLabel(item) {
    if (typeof item == 'string') {
      return item;
    }
    if (this.selectorOptions.bindLabel != null) {
      return item[this.selectorOptions.bindLabel];
    } else {
      return item['label'];
    }
  }

  /**
   * FUNCIONAMIENTO INTERNO. Sirve para cuando no esta definida la variable "multipleTextReplacer" que el elemento "x más..." tenga un title con todos los nombres seleccionados restantes
   * @param {Array} item - Array de elementos
   * @returns {string}
   */
  getListItems(array) {
    let text = '';
    let i = 0;
    array.forEach((element) => {
      if (i != 0) {
        text += ',';
      }
      text += this.checkBindLabel(element);
      i++;
    });
    return text;
  }

  getNullValueDefault(valueDef) {
    if (valueDef == '' || valueDef == null) {
      return null;
    }
    return valueDef;
  }

  /**
   * FUNCIONAMIENTO INTERNO - Refactorización de código para no poner 2 condiciones en el html debido a que en el objeto de OptionsSelector las propiedades que son otro tipo de objetos puede ser nulos
   * @param {any} objectKey - El objeto dentro del OptionsSelector
   * @param {string} key - La key del objeto dentro de la opción pasada en ObjectKey
   * @param {any} defaultValue - El valor por defecto si el objeto es nulo
   * @returns {any}
   */
  checkOptionsKey(objectKey, key, defaultValue): any {
    if (objectKey == null) {
      return defaultValue;
    }
    if (objectKey[key] == null) {
      return defaultValue;
    }
    return objectKey[key];
  }

  //#endregion

  //#region Eventos hacia al componente superior

  /** Envía una actualización del ngModel al componente superior */
  sendOnChange() {
    this._onChange(this.model);
    if (this.selectorOptions.clearOnClick && this.model == null) return;
    this.change.emit(this.model);
    if (this.selectorOptions.clearOnClick && this.ngSelect)
      this.ngSelect.handleClearClick();
  }
  /** Envía el evento cuando se presiona la X de un selector. Envía la información que había añadida */
  sendClear() {
    this.clear.emit({ oldData: this._oldData });
    if (this.selectorOptions.multiple != null) {
      this.remove.emit({ multiple: true, value: this._oldData });
    }
  }
  /**
   * Evento que se activa cuando seleccionas un elemento. Este evento depende de la propiedad multiple que esté activada
   * @param {Event} event - evento
   */
  sendAdd(event) {
    this.add.emit(event);
  }
  /**
   * Evento que se activa cuando desseleccionas un elemento. Este evento depende de la propiedad multiple que esté activada
   * @param {Event} event - evento
   */
  sendRemove(event) {
    this.remove.emit(event);
  }
  /** Envía un evento cuando se cierra el dropdown del selector */
  sendClose() {
    this.close.emit(this.model);
  }

  //#endregion

  deleteSelectorFunction(item) {
    this.deleteSelector.emit(item);
  }
}
