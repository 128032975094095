import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'table-item-button',
  templateUrl: './table-item-button.component.html',
  styleUrls: ['./table-item-button.component.scss']
})
export class TableItemButtonComponent implements OnInit {

  @Input() icon?: string = '';
  @Input() buttonStyle: string  = 'principalButton';
  @Input() text?: string = '';
  @Input() item:any = null
  @Input() index: number = 0;
  @Input() action: any = () => {};
  @Input() displayGenerator?: (...any) => boolean = () => true;

  constructor() { }

  ngOnInit() {
  }

}
