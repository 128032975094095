export interface iPromotionTutoring {
  teacherId: number;
  teacherName: string;
  tutorings: Tutoring[];
}

interface TutoringStudent {
  tutoringStudentId: number;
  hours: string;
  studentId: number | null;
  studentName: string | null;
  phone: string | null;
  email: string | null;
  tutoringAttendance: string;
  meetingUrl: string | null;
  tutoringStudentState: string;
  tutoringStudentStateColor: string;
  comment: string | null;
}

interface Tutoring {
  tutoringId: number;
  teacherId: number;
  teacherName: string;
  landmarkParentId: number;
  startDate: string;
  hours: string;
  slotMinutes: number;
  tutoringAttendanceName: string;
  classroomName: string | null;
  tutoringStateName: string;
  tutoringStateColor: string;
  observations: string;
  students: TutoringStudent[];
}

export class IPromotionTutoring {
  static getEmptyObject(): iPromotionTutoring {
    return {
      teacherId: 0,
      teacherName: '',
      tutorings: [
        {
          tutoringId: 0,
          teacherId: 0,
          teacherName: '',
          landmarkParentId: 0,
          startDate: '',
          hours: '',
          slotMinutes: 0,
          tutoringAttendanceName: '',
          classroomName: '',
          tutoringStateName: '',
          observations: '',
          tutoringStateColor: '',
          students: [
            {
              tutoringStudentId: 0,
              hours: '',
              studentId: 0,
              studentName: '',
              phone: '',
              email: '',
              tutoringAttendance: '',
              meetingUrl: '',
              tutoringStudentState: '',
              tutoringStudentStateColor: '',
              comment: '',
            },
          ],
        },
      ],
    };
  }
}
