<fullContainer>
  <ng-container>
    <header></header>

    <TutoringByTeacher
      [tutorings]="extraClass.tutorings"
      [teacherName]="extraClass.params?.teacherName ?? ''"
      [isDataLoaded]="extraClass.isDataLoaded"
      (onDeleteTutoringTeacher)="onDeleteClick($event.tutoringId)"
      (onEditTutoringTeacher)="openEditPopup($event.tutoringId)"
      (onRowClick)="onRowClick($event)"
      (resetTutoringStudent)="resetTutoringStudent($event)"
      (onDeleteTutoringStudent)="onDeleteTutoringStudentClick($event)"
    ></TutoringByTeacher>
  </ng-container>
</fullContainer>
