import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  iMockedDataItem,
  iSession,
} from '../DetailView-Content/DetailView-Content.component';
import {
  CdkDragDrop,
  CdkDragEnter,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { GeneralLoaderService, StaticUtilitiesService } from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { SessionService } from 'src/app/Services/Api/Session.service';
import { ModuleService } from 'src/app/Services/Api/Module.service';

@Component({
  selector: 'DetailView-Content-Module',
  templateUrl: './DetailView-Content-Module.component.html',
  styleUrls: ['./DetailView-Content-Module.component.scss'],
})
export class DetailViewContentModuleComponent implements OnInit {
  // @Output() sessionsChange = new EventEmitter<iSession[]>();

  @Output() onCreate = new EventEmitter();

  @Input() sessions: any[] = [];
  @Input() module: any = null;
  @Input() isEditing: boolean = false;

  constructor(
    private sessionSE: SessionService,
    private moduleSE: ModuleService,
    private generalLoaderSE: GeneralLoaderService
  ) {}

  ngOnInit() {}

  /**
   * HANDLERS
   */

  successCreateSessionHandler(res: iResultHttp) {
    let { data } = res;
    this.onCreate.emit(data);
  }
  
  successReorderSessionsHandler(res: iResultHttp) {
    let {data} = res;
    this.onCreate.emit(data);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateSessionHandler(res: iResultHttp) {
  }

  successDeleteSessionHandler(res: iResultHttp) {
    let {data} = res;
    this.generalLoaderSE.removeFromLoaderAmount();
    this.onCreate.emit(data);
  }

  /**
   * FUNCTIONALITIES
   */

  handleClickDeleteSession(session: any) {
    const ids = [session.sessionId];
    this.deleteSession(ids); 
  }

  handleDrop(event: CdkDragDrop<any, any, any>) {
    const { previousIndex, currentIndex } = event;
    if (previousIndex === currentIndex) return;

    const newSessions = [...this.sessions];
    moveItemInArray(newSessions, previousIndex, currentIndex);
    const reorderedSessions = newSessions.map((session, index) => {
      return {
        ...session,
        sequence: index,
      };
    });
    this.reorderSessions({
      id: this.module.moduleId,
      sessions: reorderedSessions,
    });
    // this.onCreate.emit(reorderedSessions);
    // this.sessionsChange.emit(newSessions);
  }

  sessionsTracking(index: number) {
    return index;
  }

  handleSessionNameChange(newValue: string, session: any) {
    if(newValue === session.sessionTitle) return;
    const objecToPass = {
      id: session.sessionId,
      title: newValue,
    }
    this.updateSession(objecToPass);
    session.sessionTitle = newValue;
    // this.sessionsChange.emit(newSessions);
  }

  addSession() {
    const objectToPass = {
      moduleId: this.module.moduleId,
    };
    this.createSession(objectToPass);
  }

  /**
   * API CALLS
   */

  createSession(objectToPass) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sessionSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successCreateSessionHandler(res),
        });
      });
  }

  updateSession(objectToPass) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sessionSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successUpdateSessionHandler(res),
        });
      });
  }

  reorderSessions(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.moduleSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successReorderSessionsHandler(res),
        });
      });
  }

  deleteSession(ids) {
    this.generalLoaderSE.addToLoaderAmount();
  const subject = StaticUtilitiesService.createSubject();
  const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
  this.sessionSE.delete(behaviorSubject, ids);
  behaviorSubject
    .pipe(
      takeUntil(subject),
      filter((res) => res)
    )
    .subscribe((res: iResultHttp) => {
      StaticUtilitiesService.apiResponseHandler(res, subject, {
        method: () => this.successDeleteSessionHandler(res),
      });
    });
  }
}
