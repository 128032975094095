import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'Tutorias-Alumnos-Layout',
  templateUrl: './Tutorias-Alumnos-Layout.component.html',
  styleUrls: ['./Tutorias-Alumnos-Layout.component.scss']
})
export class TutoriasAlumnosLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
