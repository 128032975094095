<div class="gridAndPagination">

  <div class="fullView" skeleton [isDataLoaded]="areReadyAllFormations">

    <p class="noData" *ngIf="allFormations.length === 0">No hay elementos para mostrar</p>

    <!-- Tarjetas -->

    <div class="card" (click)="goToDetailView(formation)" *ngFor="let formation of allFormations | paginate:{
      itemsPerPage: filterAllFormations.num_devoluciones,
      currentPage: filterAllFormations.num_pagina,
      totalItems: totalFormations
    }">
      <div class="spaceBetween">
        <p class="title">{{formation.name ?? '-'}}</p>
        <status-pill [active]="formation.active"></status-pill>
      </div>
      <p class="textBody" *ngIf="!formation.masterOne">
        {{formation.description ?? '-'}}
      </p>
      <div class="mastersBlock" *ngIf="formation.masterOne">
        <p>{{formation.masterOne.formation.name ?? '-'}}</p>
        <p>{{formation.masterTwo.formation.name ?? '-'}}</p>
      </div>
      <div *ngIf="formation.masterOne"></div>
      <div class="spaceBetween" *ngIf="!formation.masterOne">
        <p class="title">{{formation.duration ?? '-'}} H</p>
        <span class="modality">

          <ng-container *ngFor="let modality of formation?.attendanceModalities; index as index">

            <span class="modality-pill" *ngIf="index <= 1 ">
              <mat-icon>done</mat-icon>
              <p class="text">{{modality.name ?? '-'}}</p>
            </span>

          </ng-container>

          <ng-container *ngIf="formation.attendanceModalities?.length > 2">
            <span class="modality-pill">
              +{{formation.attendanceModalities.length - 2}}
            </span>
          </ng-container>


        </span>
      </div>
    </div>

  </div>
  <pagination-controls [autoHide]="true" previousLabel="Anterior"  nextLabel="Siguiente" (pageChange)="pageChangeEmmiter($event)" *ngIf="allFormations.length > 0"></pagination-controls>

</div>