<div class="fullContainer">

  <div class="firstLine">

    <span class="back" (click)="goBack()">
      <mat-icon>chevron_left</mat-icon>
    </span>

    <p class="titlePermisions">{{userSelected.name + ' ' + userSelected.surname}}</p>

  </div>

  <div class="rolesContainer">
    <div class="topLine">
      <p>Secciones de funcionalidad</p>
      <p>Ver</p>
      <p>Ver y editar</p>
    </div>
    <div class="line" *ngFor="let permision of permisionList ; let index = index">
      <p>{{permision.family}}</p>
      <mat-checkbox [checked]="matrizCheckboxes[index][indexCheckBox] == 'selectedTwo'"
        [disabled]="matrizCheckboxes[index][indexCheckBox] == 'selectedTwo'"
        [(ngModel)]="matrizCheckboxes[index][indexCheckBox]"
        (change)="checkBoxChange($event, index, indexCheckBox, element)"
        *ngFor="let element of permision.permissions; let indexCheckBox = index"></mat-checkbox>
    </div>

    
  </div>
  <div class="button">
    <btn classList="generalbutton principalButton" (click)="saveRoles()"> Guardar permisos </btn>
  </div>
</div>