import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iAddress } from "./iAddress";

export interface iBankData {
    id: number,
    bank?: string,
    IBAN?: string,
    BIC?: string,
    owner?: string,
    retentionType?: string,
    fiscalAddress?: iAddress,
}

export class iBankData extends iBaseObject {
    static convertFromCollection(ob: any): Array<iBankData> {
        let iBankDataCollection: Array<iBankData> = [];
        ob.forEach((element: any) => {
            iBankDataCollection.push(this.convertFromObject(element));
        });
        return iBankDataCollection;
    }

    static convertFromObject(ob: any): iBankData {
        let iBankDataObj = new iBankData();
        iBankDataObj.id = ob.id;
        iBankDataObj.bank = ob.bank;
        iBankDataObj.IBAN = ob.IBAN;
        iBankDataObj.BIC = ob.BIC;
        iBankDataObj.owner = ob.owner;
        iBankDataObj.retentionType = ob.retentionType;
        iBankDataObj.fiscalAddress = ob.fiscalAddress ? iAddress.convertFromObject(ob.fiscalAddress) : iAddress.createEmptyObject();
        return iBankDataObj;
    }

    static createEmptyObject(): iBankData {
        let iBankDataObj = new iBankData();
        iBankDataObj.bank = '';
        iBankDataObj.IBAN = '';
        iBankDataObj.BIC = '';
        iBankDataObj.owner = '';
        iBankDataObj.retentionType = '';
        iBankDataObj.fiscalAddress = iAddress.createEmptyObject();
        return iBankDataObj;
    }
}

export interface iBankDataFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    bank?: string,
    IBAN?: string,
    BIC?: string,
    owner?: string,
    retentionType?: string,
    fiscalAddress?: number,
}