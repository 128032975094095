<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.elemento !== 'el formulario de automatrícula'">{{modalData.accion}} {{modalData.elemento}}</p>
    <p *ngIf="modalData.elemento === 'el formulario de automatrícula'">{{modalData.accion}} matrícula</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body" *ngIf="modalData.elemento !== 'el formulario de automatrícula'">
    <p>¿Estás seguro que quieres {{modalData.accion.toLowerCase()}} {{modalData.elemento}}?</p>
    <p>Este paso no tiene vuelta atrás</p>
  </div>

  <div class="body" *ngIf="modalData.elemento === 'el formulario de automatrícula'">
    <p>¿Quieres {{modalData.accion.toLowerCase()}} {{modalData.elemento}}?</p>
  </div>
  <div class="footer">
    <btn classList="principalButton" (click)="closePopup(false)">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn classList="specialButton" (click)="closePopup(true)">
      <ng-container>
        {{modalData.accion}}
      </ng-container>
    </btn>
  </div>
</div>
