import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'AddExcel-Popup',
  templateUrl: './AddExcel-Popup.component.html',
  styleUrls: ['./AddExcel-Popup.component.scss']
})
export class AddExcelPopupComponent implements OnInit {

  isExcelLoaded: boolean = false;
  formaciones: any = null;
  studentList: any = null;
  grupoName: string = '';

  constructor(public dialogRef: MatDialogRef<AddExcelPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) { }

  ngOnInit() {
    this.setter();
  }

  /**
   * GETTERS && SETTERS
   */

  getIsExcelLoaded(event) {
    this.isExcelLoaded = event.isExcelLoaded;
    this.studentList = event.data;
  }

  setter(){
    let data = this.modalData.data;
    this.grupoName = data.nombre;
    this.formaciones = data.formacion;
    
    if(data.studentList){
       
      this.studentList = data.studentList.map(item => item.student.user);
      this.isExcelLoaded = true;
    }
  }

  /**
   * FUNCTIONALITY
   */

  forceClosePopup() {
    this.dialogRef.close();
  }

  nextButton() {
    this.dialogRef.close({ isExcelLoaded: this.isExcelLoaded, data: this.studentList });
  }
  backButton() {

  }
}
