import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TSendIsEditing } from '../DetailView-baseContainer/DetailView-baseContainer.component';
import { DetailViewDetailsClass } from 'src/app/Shared/Classes/DetailViewDetailsClass';
import { EditFormationService } from 'src/app/Services/Utils/EditFormation.service';
import {
  DebouncerService,
  GeneralLoaderService,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { ModalityPriceService } from 'src/app/Services/Api/ModalityPrice.service';
import { AttendanceService } from 'src/app/Services/Api/Attendance.service';
import { TopicService } from 'src/app/Services/Api/Topic.service';
import { AcademicService } from 'src/app/Services/Api/Academic.service';

@Component({
  selector: 'DetailView-details',
  templateUrl: './DetailView-details.component.html',
  styleUrls: ['./DetailView-details.component.scss'],
})
export class DetailViewDetailsComponent implements OnInit {
  @Output() onActiveToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  set formationData(value: any) {
    this.extraClass._formationData = value;
    this.getAttendanceModalitySelector();
    this.getTopicSelector();
  }
  get formationData(): any {
    return this.extraClass._formationData;
  }

  @Input()
  get modalitiesData() {
    return this.extraClass._modalitiesData;
  }
  set modalitiesData(value) {
    value.forEach((modality) => {
      this.extraClass.SelectedAttendantModality.push(modality.id);
    });
    this.extraClass._modalitiesData = value;
  }

  @Input() modulesData: any = [];
  @Input() directorsData: any[] = [];
  @Input() pricesData: any[] = [];
  @Input() formationType: string = '';
  @Input() promotionTemplateId: number = 0;

  extraClass: DetailViewDetailsClass = new DetailViewDetailsClass();

  constructor(
    private editFormationSE: EditFormationService,
    private modalityPriceSE: ModalityPriceService,
    private generalLoaderSE: GeneralLoaderService,
    private attendanceSE: AttendanceService,
    private topicSE: TopicService,
    private academicSE: AcademicService
  ) {}

  ngOnInit(): void {
    this.getAcademicStateSelector();
  }

  /**
   * HANDLERS
   */

  successgetAttendanceModalitySelectorHandler(res: iResultHttp) {
    let { data } = res;
    const mappedData = data.map((topic) => {
      return {
        ...topic,
        active: this.extraClass.SelectedAttendantModality.includes(topic.id),
      };
    });
    this.extraClass.attendantModalityArray = mappedData;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successEditModalityAttendanceHandler(res: iResultHttp) {}

  successcreateTopicHandler(res: iResultHttp) {
    this.getTopicSelector();
  }

  successgetTopicSelectorHandler(res: iResultHttp) {
    let { data } = res;
    this.extraClass.categorySelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successdeleteTopicHandler(res: iResultHttp) {
    this.getTopicSelector();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successgetAcademicStateSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.stateSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */

  handleCreateModule(event) {
    this.modulesData = event;
  }

  handelChangeEdit(event: TSendIsEditing) {
    this.extraClass.editableController = {
      ...this.extraClass.editableController,
      ...event,
    };

    this.extraClass.setAsReadonly();
  }

  toggleActive() {
    if (!this.extraClass.editableController['Descripción']) return;
    // this.onActiveToggle.emit(true);
    this.extraClass._formationData.active =
      !this.extraClass._formationData.active;
    this.prepareObjectToPass(
      this.extraClass._formationData.active,
      'active',
      this.extraClass._formationData.formationId,
      'formacion'
    );
  }

  checkIfNumberAndEdit(event: any, key: string, type: string, index?) {
    const { text, isNumber } = this.checkIfNumber(event);
    // If it is a number
    if (isNumber) {
      let id = 0;
      if (type === 'formacion') {
        id = this.extraClass._formationData.formationId;
      } else {
        id = this.pricesData[index].id;
      }
      DebouncerService.formDebouncer(
        () => this.prepareObjectToPass(text, key, id, type),
        key,
        text
      );
    } else {
      // We are filtering the text to only allow numbers
      event.target.innerHTML = text.replace(/[^0-9.-]/g, '');
    }
  }

  checkIfNumber(event: any): { text: string; isNumber: boolean } {
    let text = event.target.innerHTML.replace(',', '.').trim();
    // If it is a number
    if (this.extraClass.numberRegex.test(text)) {
      return { text, isNumber: true };
    } else {
      // We are filtering the text to only allow numbers
      event.target.innerHTML = text.replace(/[^0-9.-]/g, '');
      return { text, isNumber: false };
    }
  }

  editInfo(value, key) {
    DebouncerService.formDebouncer(
      () =>
        this.prepareObjectToPass(
          value,
          key,
          this.extraClass._formationData.formationId,
          'formacion'
        ),
      key,
      value,
      1000
    );
  }

  editInfoNoDebounce(value, key) {
    this.prepareObjectToPass(
      value,
      key,
      this.extraClass._formationData.formationId,
      'formacion'
    );
  }

  prepareObjectToPass(value, key, id: number, type: string) {
    let objectToPass = this.extraClass.objectToPass;
    objectToPass.id = id;
    objectToPass[key] = value;
    if (type === 'formacion') this.editFormacion(objectToPass);
    if (type === 'modalityPrice') this.editModalityPrice(objectToPass);
    delete objectToPass.id;
    delete objectToPass[key];
  }

  isModalityActive(id: number) {
    return this.extraClass.SelectedAttendantModality.includes(id);
  }

  editModality(modality: any) {
    if (!this.extraClass.editableController['Características generales'])
      return;
    modality.active = !modality.active;
    if (modality.active) {
      this.extraClass.SelectedAttendantModality.push(modality.id);
    } else {
      this.extraClass.SelectedAttendantModality =
        this.extraClass.SelectedAttendantModality.filter(
          (item) => item !== modality.id
        );
    }
    let objectToPass = {
      id: this.extraClass._formationData.formationId,
      modalities: this.extraClass.SelectedAttendantModality,
    };
    this.editModalityAttendance(objectToPass);
  }

  addTopic(event) {
    this.createTopic({ name: event, teacher: false });
  }

  deleteTopicFromSelector(id) {
    this.deleteTopic([id]);
  }

  /**
   * API CALLS
   */

  editFormacion(objectToPass) {
    const bs = StaticUtilitiesService.createBehaviorSubject();
    const subject = StaticUtilitiesService.createSubject();
    this.editFormationSE.editFormacion(objectToPass, bs);
    bs.pipe(
      takeUntil(subject),
      filter((res) => res)
    ).subscribe((res: iResultHttp) => {});
  }

  editModalityPrice(objectToPass: any) {
    const subject = StaticUtilitiesService.createSubject();
    this.modalityPriceSE.update(objectToPass);
    this.modalityPriceSE
      .getResultUpdate()
      .pipe(takeUntil(subject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Formación editada correctamente');
        subject.next('');
      });
    this.modalityPriceSE
      .getResultUpdateError()
      .pipe(takeUntil(subject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        subject.next('');
      });
  }

  getAttendanceModalitySelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.attendanceSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successgetAttendanceModalitySelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  editModalityAttendance(objectToPass) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.attendanceSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successEditModalityAttendanceHandler(res),
        });
      });
  }

  getTopicSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.topicSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successgetTopicSelectorHandler(res),
        });
      });
  }

  createTopic(objectToPass) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.topicSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successcreateTopicHandler(res),
        });
      });
  }

  deleteTopic(ids) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.topicSE.delete(behaviorSubject, ids);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successdeleteTopicHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getAcademicStateSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.academicSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successgetAcademicStateSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
