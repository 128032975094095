export interface iBillProformaTable {
  id: number;
  concept: string;
  amount: number;
  number: string;
  date: string;
  paymentDate: string;
  payBy: string | null;
  paymentMethod: string | null;
  billState: number;
  billStateColor: string;
  document: string | null;
  promotionEdition: string | null;
  formation: string | null;
}

export class IBillProformaTable {
  public static getEmptyObject(): iBillProformaTable {
    return {
      id: 0,
      concept: '',
      amount: 0,
      number: '',
      date: '',
      paymentDate: '',
      payBy: '',
      paymentMethod: '',
      billState: 0,
      billStateColor: '',
      document: '',
      promotionEdition: '',
      formation: '',
    };
  }
}
