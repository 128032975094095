import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StaticUtilitiesService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';

@Component({
  selector: 'FormacionesNewVersion-Curso',
  templateUrl: './FormacionesNewVersion-Curso.component.html',
  styleUrls: ['./FormacionesNewVersion-Curso.component.scss']
})
export class FormacionesNewVersionCursoComponent implements OnInit {

  @Input() allCursos:any = [];
  @Input() totalCursos:number = 0;
  @Input() areReadyAllCursos:boolean = false;
  @Input() filterAllCursos:any = null;

  @Output() pageChange:EventEmitter<boolean> = new EventEmitter()



  constructor(private staticUtilitiesSE:StaticUtilitiesService) { }

  ngOnInit() {
  }

  goToDetailView(id:number){
    this.staticUtilitiesSE.goTo('formaciones/formaciones-detalle/', slideNavigationTypes.slideToTop, {curso:id});
  }

  pageChangeEmmiter(event) {
    this.pageChange.emit(event);
  }

}
