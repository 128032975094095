<ContainerAutomatricula [displayButton]="false">
  <ng-container>
    <div class="fullSite">
      <div class="disclaimer">
        <div class="titleAndButtons">
          <p class="fwBold">Bienvenidos a IEM. &nbsp;</p>
          <p>
            Estamos muy ilusionados de contar contigo para nuestro próximo
            curso, pero antes &nbsp;
          </p>
          <p class="fwBold">rellena tus datos personales y bancarios.</p>
        </div>
        <div class="warning">
          <img src="/assets/Icons/General/warning.svg" alt="" />
          <p class="fwBold">
            Este formulario se guarda automáticamente, los datos se conservan
            aunque salgas de la página
          </p>
        </div>
      </div>

      <div class="listAndForm" *ngIf="!isModalShown">
        <div class="list">
          <div class="lines">
            <div
              class="line"
              [class.active]="optionSelected === 0"
              (click)="optionSelected = 0"
            >
              <span>1</span>
              <p>Validación de matrícula</p>
            </div>
            <div
              class="line"
              [class.active]="optionSelected === 1"
              (click)="optionSelected = 1"
            >
              <span>2</span>
              <p>Información personal</p>
            </div>
            <div
              class="line"
              [class.active]="optionSelected === 2"
              (click)="optionSelected = 2"
            >
              <span>3</span>
              <p>Datos de facturación</p>
            </div>
            <div
              class="line"
              [class.active]="optionSelected === 3"
              (click)="optionSelected = 3"
            >
              <span>4</span>
              <p>Bonificación</p>
            </div>
            <div
              class="line"
              [class.active]="optionSelected === 4"
              (click)="optionSelected = 4"
            >
              <span>5</span>
              <p>Precio</p>
            </div>
            <div
              class="line"
              [ngClass]="{
                active: optionSelected === 5 && isMetodosDePagoNeeded,
                disappear: !isMetodosDePagoNeeded
              }"
              (click)="optionSelected = 5"
            >
              <span>6</span>
              <p>Métodos de pago</p>
            </div>
            <div
              class="warningData"
              *ngIf="
                (optionSelected === 5 && !isChecked) ||
                (optionSelected === 4 && !isMetodosDePagoNeeded && !isChecked)
              "
            >
              <img src="/assets/Icons/General/warning_red.svg" alt="" />
              <p>Revisa tu información personal</p>
            </div>
          </div>

          <div class="buttons">
            <btn
              classList="generalbutton secondaryButtonAutomatricula"
              (click)="previous()"
              >Atrás</btn
            >
            <btn
              classList="generalbutton secondaryButtonAutomatricula"
              [ngClass]="{
                disabled:
                  (optionSelected === 5 && !isChecked) ||
                  (optionSelected === 4 && !isMetodosDePagoNeeded && !isChecked)
              }"
              (click)="next()"
              >{{
                optionSelected === 5 ||
                (optionSelected === 4 && !isMetodosDePagoNeeded)
                  ? "Finalizar"
                  : "Siguiente"
              }}</btn
            >
          </div>
        </div>
        <div class="form">
          <FormInformacionPersonal
            (refresh)="refresh($event)"
            (dataFromStepOne)="getDataFromStepOne($event)"
            [token]="token"
            [dataFromFather]="dataFromStepOne"
            [studentData]="studentData"
            [countriesList]="countriesList"
            *ngIf="optionSelected === 0"
          ></FormInformacionPersonal>
          <InformacionPersonal
            (refresh)="refresh($event)"
            (dataFromStepTwo)="getDataFromStepTwo($event)"
            [token]="token"
            [studentData]="studentData"
            [countriesList]="countriesList"
            *ngIf="optionSelected === 1"
          >
          </InformacionPersonal>
          <DatosDeFacturacion
            (sendSameAddressBoolean)="getSendSameAddressBoolean($event)"
            (refresh)="refresh($event)"
            (dataFromStepThree)="getDataFromStepThree($event)"
            [sameAddress]="sameAddress"
            [token]="token"
            [studentData]="studentData"
            [countriesList]="countriesList"
            *ngIf="optionSelected === 2"
          >
          </DatosDeFacturacion>
          <app-Bonificacion
            (refresh)="refresh($event)"
            (dataFromStepFour)="getDataFromStepFour($event)"
            [token]="token"
            [studentData]="studentData"
            *ngIf="optionSelected === 3"
          >
          </app-Bonificacion>
          <Precio
            (refresh)="refresh($event)"
            (dataFromStepFive)="getDataFromStepFive($event)"
            (isMetodosDePagoNeeded)="getIsMetodosDePagoNeeded($event)"
            [token]="token"
            [studentData]="studentData"
            [dataFromFather]="dataFromStepFive"
            [studentData]="studentData"
            [countriesList]="countriesList"
            *ngIf="optionSelected === 4"
          ></Precio>
          <MetodosDePago
            (refresh)="refresh($event)"
            [token]="token"
            [studentData]="studentData"
            *ngIf="optionSelected === 5"
          ></MetodosDePago>
        </div>
      </div>
      <div class="timeout" *ngIf="isModalShown">
        <div class="timerDisplay">
          <p class="messageHeader">
            <b> Matrícula guardada correctamente </b><br />
            Por favor, cierre esta ventana
          </p>
        </div>
      </div>
      <div class="fondo" *ngIf="isModalShown">
        <img
          src="/assets/Images/Automatricula/fondoDeAutomatricula.png"
          alt=""
        />
      </div>
    </div>
  </ng-container>
</ContainerAutomatricula>
