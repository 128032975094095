<div class="gridAndPagination">
  <div class="fullView" skeleton [isDataLoaded]="areReadyAllMasters">
    <p class="noData" *ngIf="allMasters.length === 0">
      No hay elementos para mostrar
    </p>

    <!-- Tarjetas -->

    <div
      class="card"
      (click)="goToDetailView(master)"
      *ngFor="
        let master of allMasters
          | paginate
            : {
                itemsPerPage: filterAllMasters.num_devoluciones,
                currentPage: filterAllMasters.num_pagina,
                totalItems: totalMasters
              }
      "
    >
      <div class="spaceBetween">
        <p class="title">{{ master.name ?? "-" }}</p>
        <Chip
          [type]="master.active ? '--greenState' : '--redState'"
          [value]="master.activeLabel"
        ></Chip>
      </div>
      <p class="textBody">
        {{ master.description ?? "-" }}
      </p>
      <div class="spaceBetween">
        <p class="title">{{ master.duration ?? 0 }} H</p>
        <p class="title end">{{ master.price ?? 0 }} €</p>
      </div>
      <div class="attendanceModalitiesLine">
        <ng-container *ngFor="let modality of master.attendanceModalities">
          <span class="modality-pill">
            <mat-icon>done</mat-icon>
            <p class="text">{{ modality.name ?? "-" }}</p>
          </span>
        </ng-container>
      </div>
    </div>
  </div>

  <pagination-controls
    [autoHide]="true"
    previousLabel="Anterior"
    nextLabel="Siguiente"
    (pageChange)="pageChangeEmmiter($event)"
  ></pagination-controls>
</div>
