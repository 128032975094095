import {
  IOptionCustomTextArea,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';

export class EditTutoringreservationClass {
  // ngModels
  ngModels: {
    promotionId: null | number;
    studentId: null | number;
    startDate: null | string;
    hours: null | string;
    tutoringAttendanceId: null | number;
    tutoringStudentStateId: null | number;
    comment?: null | string;
    meetingUrl?: null | string;
  } = {
    promotionId: null,
    studentId: null,
    startDate: null,
    hours: null,
    tutoringAttendanceId: null,
    tutoringStudentStateId: null,
    comment: null,
    meetingUrl: null,
  };

//   boolean
  canFinish: boolean = false;

  // selectorOptions
  masterSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    placeholder: 'Selecciona un máster',
    label: 'Máster',
    bindLabel: 'promotionName',
    bindValue: 'promotionId',
    search: true,
  };
  studentSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    placeholder: 'Selecciona un alumno',
    label: 'Alumno',
    bindLabel: 'studentName',
    bindValue: 'studentId',
    search: true,
    clearable:false
  };
  modalitySelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    placeholder: 'Selecciona una modalidad',
    label: 'Modalidad',
    bindLabel: 'name',
    bindValue: 'id',
    search: true,
    clearable:false
  };
  stateSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    placeholder: 'Selecciona un estado',
    label: 'Estado',
    bindLabel: 'name',
    bindValue: 'id',
    search: true,
    clearable:false
  };

  // CustomInputOptions
  dateCustomInputOptions: iOptionsCustomInput = {
    label: 'Fecha',
    placeholder: '',
    type: 'text',
    readOnly: true,
  };
  timeCustomInputOptions: iOptionsCustomInput = {
    label: 'Horario',
    placeholder: '',
    type: 'text',
    readOnly: true,
  };
  meetingUrlCustomInputOptions: iOptionsCustomInput = {
    label: 'URL de la reunión',
    placeholder: 'URL de la reunión',
    type: 'text',
  };


  // customTextArea
  commentsCustomTextAreaOptions: IOptionCustomTextArea = {
    cols: 30,
    rows: 3,
    label: 'Comentarios',
    placeholder: 'Escribe aquí tus comentarios',
  };
}
