import { Component, OnInit } from '@angular/core';
import { StaticUtilitiesService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';

@Component({
  selector: 'Configuracion-LogOut',
  templateUrl: './Configuracion-LogOut.component.html',
  styleUrls: ['./Configuracion-LogOut.component.scss']
})
export class ConfiguracionLogOutComponent implements OnInit {

  constructor(private staticUtilitiesSE:StaticUtilitiesService) { }

  ngOnInit() {
  }

  /**
   * API CALLS
   */

  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('nmsr');
    localStorage.removeItem('formalProposal');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('crm');
    this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight)
  }

}
