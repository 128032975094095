<!-- FILEPATH: /c:/IEM-Frontend/src/app/Shared/Components/searcher-and-filter/searcher-and-filter.component.html -->

<!--
  This HTML template represents the searcher and filter component.
  It consists of two main sections: the searcher and the filter.

  The searcher section contains a customSearcher component, which is a custom input field for searching.
  It has the following attributes:
    - ngDefaultControl: Specifies that the customSearcher component should be treated as a default form control.
    - placeHolder: Specifies the placeholder text for the input field.
    - (change): Event handler for the change event of the input field.
    - [(ngModel)]: Two-way data binding for the searchModel property.

  The filter section is initially hidden and can be toggled by clicking on the "Filtrar" button.
  It has the following attributes:
    - [ngClass]: Binds the "active" CSS class to the wrapper div based on the value of the "active" property.

  Inside the filter section, there is a list of customFormContainer components, each representing a filter selector.
  Each customFormContainer component has the following attributes:
    - [(ngModel)]: Two-way data binding for the model property of the selector.
    - [options]: Specifies the options for the selector.
    - (change): Event handler for the change event of the selector.

-->
<div class="searcherAndFilter">
  <customSearcher ngDefaultControl [placeHolder]="placeholder"
  (change)="searchChange()" [(ngModel)]="searchModel"></customSearcher>
  <btn classList="secondaryButton" (click)="active = !active" > Filtrar <mat-icon>filter_list</mat-icon> </btn>
</div>

<div id="wrapper" [ngClass]="{active: active}">
  <div class="hiddenFilters">
    <!-- <ng-container *ngFor="let selector of selectors" >
      <div class="customFormContainer">
        <label>{{selector.label}}</label>
        <selector [(ngModel)]="selector.model" [options]="selector.options" (change)="filterChange(selector.model,selector.label)" ></selector>
      </div>
    </ng-container>  -->
    <ng-content></ng-content>
  </div>
</div>