import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ConfirmarAccionSobreescribirFechas-Popup',
  templateUrl: './ConfirmarAccionSobreescribirFechas-Popup.component.html',
  styleUrls: ['./ConfirmarAccionSobreescribirFechas-Popup.component.scss'],
})
export class ConfirmarAccionSobreescribirFechasPopupComponent
  implements OnInit
{
  constructor(
    public dialogRef: MatDialogRef<ConfirmarAccionSobreescribirFechasPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit() {}

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
}
