<fullContainer>
  <header></header>
  <div class="fullSite">
    <div class="toggleAndButtons">
      <toggleSubmenu
        class="secondary"
        [elements]="[
          'Masters',
          'Cursos',
          'Incompany',
          'Dobles Titulaciones',
          'Tutorías'
        ]"
        [(ngModel)]="optionSubmenuSelected"
        (onValueChange)="onToggleSubmenuChange($event)"
      ></toggleSubmenu>
      <div class="toggleAndButtons__buttonsContainer">
        <btn
          (click)="handleClickSwitchTable()"
          classList="generalbutton principalButton toggleAndButtons__switchBtn"
          *ngIf="optionSubmenuSelected !== 4"
        >
          <ng-container *ngIf="gridView">
            <mat-icon>view_headline</mat-icon>
            Tabla
          </ng-container>
          <ng-container *ngIf="!gridView">
            <mat-icon>grid_view</mat-icon>
            Grid
          </ng-container>
        </btn>
        <!-- Boton de la vista de Master -->
        <btn
          classList="generalbutton principalButton"
          *ngIf="optionSubmenuSelected == 0 && checkRolesAndPermissions()"
          (click)="goTo('master')"
        >
          <mat-icon>add_circle_outline</mat-icon>
          <p>Añadir nuevo master</p>
        </btn>
        <!-- Boton de la vista de Cursos -->
        <btn
          classList="generalbutton principalButton"
          *ngIf="optionSubmenuSelected == 1 && checkRolesAndPermissions()"
          (click)="goTo('curso')"
        >
          <mat-icon>add_circle_outline</mat-icon>
          <p>Añadir nuevo curso</p>
        </btn>
        <!-- Boton de la vista de Incompany -->
        <!-- <btn classList="generalbutton principalButton" *ngIf="optionSubmenuSelected == 2 && checkRolesAndPermissions()"
          (click)="goTo('incompany')">
          <mat-icon>add_circle_outline</mat-icon>
          <p>Añadir nueva incompany</p>
        </btn> -->
        <!-- Boton de la vista de Doble Titulacion -->
        <btn
          classList="generalbutton principalButton"
          *ngIf="optionSubmenuSelected == 3 && checkRolesAndPermissions()"
          (click)="openNuevaTitulacionPopup()"
        >
          <mat-icon>add_circle_outline</mat-icon>
          <p>Añadir nueva doble titulación</p>
        </btn>

        <btn
          classList="generalbutton principalButton"
          *ngIf="optionSubmenuSelected == 4 && checkRolesAndPermissions()"
          (click)="goToNuevaTutoria()"
        >
          <mat-icon>add_circle_outline</mat-icon>
          <p>Crear nueva tutoría</p>
        </btn>

        <btn
          classList="generalbutton principalButton"
          *ngIf="optionSubmenuSelected == 5 && checkRolesAndPermissions()"
        >
          <img [src]="IconsHandlerService.getSendDatesIcon()" alt="" />
          <p>Enviar fechas</p>
        </btn>
      </div>
    </div>
    <!-- <customSearcher ngDefaultControl placeHolder="Busca aquí las formaciones" [(ngModel)]="searcher"
    (change)="getFilter(searcher,'genericFilter')"></customSearcher> -->

    <searcher-and-filter
      (onSearchChange)="getFilter($event, 'genericFilter')"
      [searchModel]="searcher"
      [selectors]="selectorArray"
      (onFilterChange)="getFilters($event)"
      [placeholder]="placeholdersArray[optionSubmenuSelected]"
    >
      <div class="filterLine">
        <ng-container
          *ngIf="
            optionSubmenuSelected == 0 ||
            optionSubmenuSelected === 1 ||
            optionSubmenuSelected === 3
          "
        >
          <customInput
            [options]="extraClass.minPrinceCustomInputOptions"
            [(ngModel)]="extraClass.filtersNgModels.minPrice"
            style="width: 100%"
            (change)="
              getFilter(extraClass.filtersNgModels.minPrice, 'minPrice')
            "
          ></customInput>
        </ng-container>

        <ng-container
          *ngIf="
            optionSubmenuSelected == 0 ||
            optionSubmenuSelected === 1 ||
            optionSubmenuSelected === 3
          "
        >
          <customInput
            [options]="extraClass.maxPrinceCustomInputOptions"
            [(ngModel)]="extraClass.filtersNgModels.maxPrice"
            style="width: 100%"
            (change)="
              getFilter(extraClass.filtersNgModels.maxPrice, 'maxPrice')
            "
          ></customInput>
        </ng-container>

        <ng-container
          *ngIf="optionSubmenuSelected == 0 || optionSubmenuSelected === 1"
        >
          <selector
            [options]="extraClass.modalityAttendanceSelectorOptions"
            style="width: 100%"
            (change)="
              getFilter(
                extraClass.filtersNgModels.modalityAttendance,
                'attModalities'
              )
            "
            [(ngModel)]="extraClass.filtersNgModels.modalityAttendance"
          ></selector>
        </ng-container>

        <ng-container
          *ngIf="
            optionSubmenuSelected == 0 ||
            optionSubmenuSelected === 1 ||
            optionSubmenuSelected === 3
          "
        >
          <selector
            [options]="extraClass.academicStateSelectorOptions"
            style="width: 100%"
            (change)="
              getFilter(extraClass.filtersNgModels.academicState, 'active')
            "
            [(ngModel)]="extraClass.filtersNgModels.academicState"
          ></selector>
        </ng-container>

        <ng-container *ngIf="optionSubmenuSelected === 4">
          <span class="clearableDate">
            <customInput
              style="width: 100%"
              [selectedDate]="extraClass.filtersNgModels.startDate"
              [options]="extraClass.startDateTutoringCustomInputOptions"
              (change)="dateTreatment($event, 'startDate')"
            >
            </customInput>
            <span
              class="closex"
              (click)="
                getFilter(null, 'startDate');
                this.extraClass.filtersNgModels.startDate = null
              "
              *ngIf="extraClass.filtersNgModels.startDate"
            >
              <mat-icon>close</mat-icon>
            </span>
          </span>

          <selector
            [options]="extraClass.masterSelectorOptions"
            style="width: 100%"
            (change)="
              getFilter(extraClass.filtersNgModels.masterId, 'masterId')
            "
            [(ngModel)]="extraClass.filtersNgModels.masterId"
          ></selector>

          <selector
            [options]="extraClass.promotionSelectorOptions"
            style="width: 100%"
            (change)="
              getFilter(extraClass.filtersNgModels.promotionId, 'promotionId')
            "
            [(ngModel)]="extraClass.filtersNgModels.promotionId"
          ></selector>

          <selector
            [options]="extraClass.classroomSelectorOptions"
            style="width: 100%"
            (change)="
              getFilter(extraClass.filtersNgModels.classroomId, 'classroomId')
            "
            [(ngModel)]="extraClass.filtersNgModels.classroomId"
          ></selector>

          <selector
            [options]="extraClass.tutoringStateSelectorOptions"
            style="width: 100%"
            (change)="
              getFilter(extraClass.filtersNgModels.tutoringState, 'stateId')
            "
            [(ngModel)]="extraClass.filtersNgModels.tutoringState"
          ></selector>
        </ng-container>
      </div>
    </searcher-and-filter>

    <div class="body">
      <!-- Todas las formaciones -->
      <!-- <FormacionesNewVersion-Todos 
        *ngIf="optionSubmenuSelected == 0 && gridView" 
        [filterAllFormations]="filterAllFormations" 
        [totalFormations]="totalFormations"
        [areReadyAllFormations]="areReadyAllFormations" 
        [allFormations]="allFormations"
        (pageChange)="pageChange($event)"
        
        ></FormacionesNewVersion-Todos>
        <formacionesNewVersion-Todos-Table 
        *ngIf="optionSubmenuSelected == 0 && !gridView" 
        [filterAllFormations]="filterAllFormations" 
        [totalFormations]="totalFormations"
        [areReadyAllFormations]="areReadyAllFormations" 
        [allFormations]="allFormations"
        (nextPage)="nextPage('all')"
        (pageChange)="pageChange($event)"
      ></formacionesNewVersion-Todos-Table> -->
      <!-- Masters -->
      <FormacionesNewVersion-Master
        *ngIf="optionSubmenuSelected == 0 && gridView"
        [filterAllMasters]="filterAllMasters"
        [totalMasters]="totalMasters"
        [areReadyAllMasters]="areReadyAllMasters"
        [allMasters]="allMasters"
        (pageChange)="pageChange($event)"
      ></FormacionesNewVersion-Master>
      <formacionesNewVersion-Master-Table
        *ngIf="optionSubmenuSelected == 0 && !gridView"
        [filterAllMasters]="filterAllMasters"
        [totalMasters]="totalMasters"
        [areReadyAllMasters]="areReadyAllMasters"
        [allMasters]="allMasters"
        (pageChange)="pageChange($event)"
      ></formacionesNewVersion-Master-Table>
      <!-- Cursos -->
      <FormacionesNewVersion-Curso
        *ngIf="optionSubmenuSelected == 1 && gridView"
        [filterAllCursos]="filterAllCursos"
        [totalCursos]="totalCursos"
        [areReadyAllCursos]="areReadyAllCursos"
        [allCursos]="allCursos"
        (pageChange)="pageChange($event)"
      ></FormacionesNewVersion-Curso>
      <FormacionesNewVersion-Curso-Table
        *ngIf="optionSubmenuSelected == 1 && !gridView"
        [filterAllCursos]="filterAllCursos"
        [totalCursos]="totalCursos"
        [areReadyAllCursos]="areReadyAllCursos"
        [allCursos]="allCursos"
        (pageChange)="pageChange($event)"
      ></FormacionesNewVersion-Curso-Table>
      <!-- Incompanies -->
      <p class="noData" *ngIf="optionSubmenuSelected == 2">
        Este apartado estará en la siguiente fase
      </p>
      <!-- <FormacionesNewVersion-Incompany *ngIf="optionSubmenuSelected == 2 && gridView"
        [filterAllIncompanies]="filterAllIncompanies" [totalIncompanies]="totalIncompanies"
        [areReadyAllIncompanies]="areReadyAllIncompanies" [allIncompanies]="allIncompanies"
        (pageChange)="pageChange($event)"></FormacionesNewVersion-Incompany>
      <FormacionesNewVersion-Incompany-Table *ngIf="optionSubmenuSelected == 2 && !gridView"
        [filterAllIncompanies]="filterAllIncompanies" [totalIncompanies]="totalIncompanies"
        [areReadyAllIncompanies]="areReadyAllIncompanies" [allIncompanies]="allIncompanies"
        (pageChange)="pageChange($event)"></FormacionesNewVersion-Incompany-Table> -->
      <!-- Doble Titulacion -->
      <FormacionesNewVersion-DobleTitulacion
        *ngIf="optionSubmenuSelected == 3 && gridView"
        (refresh)="refresh($event)"
        [filterAllDobleTitulacion]="filterAllDobleTitulacion"
        [totalDobleTitulacion]="totalDobleTitulacion"
        [areReadyAllDobleTitulacion]="areReadyAllDobleTitulacion"
        [allDobleTitulacion]="allDobleTitulacion"
        (pageChange)="pageChange($event)"
      ></FormacionesNewVersion-DobleTitulacion>
      <app-formaciones-new-version-doble-titulacion-table
        *ngIf="optionSubmenuSelected == 3 && !gridView"
        (refresh)="refresh($event)"
        [paginationOptions]="filterAllDobleTitulacion"
        [total]="totalDobleTitulacion"
        [filterAllDobleTitulacion]="filterAllDobleTitulacion"
        [totalDobleTitulacion]="totalDobleTitulacion"
        [areReadyAllDobleTitulacion]="areReadyAllDobleTitulacion"
        [allDobleTitulacion]="allDobleTitulacion"
        (pageChange)="pageChange($event)"
      ></app-formaciones-new-version-doble-titulacion-table>

      <ng-container>
        <FormacionesNewVersion-Tutorias
          [filterTutorings]="filterTutorings"
          [refreshTutorings]="refreshTutorings"
          *ngIf="optionSubmenuSelected == 4"
        ></FormacionesNewVersion-Tutorias>
      </ng-container>
    </div>
  </div>
</fullContainer>
