<div class="popupGeneralContainer">
  <div class="header">
    <p class="title">Añadiendo alumno a NOMBRE DE GRUPO PLACEHOLDER</p>
  </div>

  <div class="body">

    <div class="fullSection">
      <div class="subColumnLong">
        <div class="labelLine">
          <label for="selector">Buscador de alumnos</label>
          <label for="selector"> </label>
        </div>
        <selector [options]="selectorOptionsStudent" ngDefaultControl [(ngModel)]="selectorStudent" (change)="selectedUser($event) "></selector>
      </div>
      <div class="rowFirstSection">
        <CustomInput [readOnly]="isSelectedUser" (change)="isCompleted()" [placeHolder]="'Nombre'" ngDefaultControl  [(ngModel)]="data.name" [label]="'Nombre'"></CustomInput>
        <CustomInput [readOnly]="isSelectedUser" (change)="isCompleted()" [placeHolder]="'Apellidos'" ngDefaultControl  [(ngModel)]="data.surname" [label]="'Apellidos'"></CustomInput>
      </div>
    
      <div class="rowFirstSection">
        <CustomInput [readOnly]="isSelectedUser" (change)="isCompleted()" [placeHolder]="'DNI'" ngDefaultControl  [(ngModel)]="data.dni" [label]="'DNI'"></CustomInput>
        <div class="telefonoDeContacto">
          <div class="subColumn">
            <label for="selector">Teléfono de contacto</label>
            <CountrySelector ngDefaultControl (emitCountry)="addPhonePrefix($event)" [(ngModel)]="data.phonePrefix" *ngIf="!isSelectedUser"></CountrySelector>
            <CustomInput [type]="'number'" (change)="isCompleted()" class="width100" [readOnly]="isSelectedUser" ngDefaultControl  [(ngModel)]="data.phonePrefix" *ngIf="isSelectedUser"></CustomInput>
          </div>
          <div class="inputDiv">
            <CustomInput [readOnly]="isSelectedUser" (change)="isCompleted()" [placeHolder]="'Teléfono'" ngDefaultControl  [(ngModel)]="data.phone"></CustomInput>
          </div>
        </div>
      </div>
      <div class="longInput">
        <CustomInput [readOnly]="isSelectedUser" (change)="isCompleted()" [placeHolder]="'Correo electrónico'" ngDefaultControl  [(ngModel)]="data.email" [label]="'Correo electrónico'"></CustomInput>
      </div>
    </div>


  </div>

  <div class="footer">
    <btn classList="violetButton" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="principalButton" [class.disabled]="!isActive" (click)="finishButton()" >
      <ng-container>
        Finalizar
      </ng-container>
    </btn>
  </div>

</div>