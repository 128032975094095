import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StaticUtilitiesService } from '@quasar-dynamics/basic-designsystem';
import { iBase64File } from 'src/app/Shared/Interfaces/Utils/iBase64File';

@Component({
  selector: 'AddCertificate-Popup',
  templateUrl: './AddCertificate-Popup.component.html',
  styleUrls: ['./AddCertificate-Popup.component.scss']
})
export class AddCertificatePopupComponent implements OnInit {

  filesArray:iBase64File[] = [];

  isActive: boolean = false;

  acceptedFileTypesArray:string[] = ['application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

  maxFileSizeMb:number = 5;

  constructor(public dialogRef: MatDialogRef<AddCertificatePopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) { }

  ngOnInit() {
  }

  
  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  onAddedFile(filesArray){
    filesArray.forEach((file) => {
      if(!this.acceptedFileTypesArray.includes(file.file.type)){
        StaticUtilitiesService.showWarning('Tipo de archivo no permitido', 'El archivo que intenta subir no es un archivo de tipo .doc o .docx');
        return;
      }
      if(file.file.size > this.maxFileSizeMb * 1024 * 1024){
        StaticUtilitiesService.showWarning('Tamaño de archivo excedido', `El archivo que intenta subir excede el tamaño máximo permitido de ${this.maxFileSizeMb}MB`);
        return;
      }
      this.filesArray.push(file);
    });
    this.checkIsComplete()
  }

  checkIsComplete(){
    this.isActive = this.filesArray.length > 0;
  }

  downloadCurrentCertificate(url){
    window.open(url, '_blank');
  }

}
