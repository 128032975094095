<div class="fullContainer">

  <div class="formLine flexEnd">
    <btn classList="generalbutton principalButton" (click)="createClassroom()">Crear aula</btn>
  </div>
  <div class="tableContainerCustom" skeleton [isDataLoaded]="isClassroomListLoaded">
    <table class="mainTableCustom">
      <thead class="theadCustom">
        <tr class="tr">
          <th class="th"> </th>
          <th class="th" *ngFor="let item of displayedColumns   | paginate:{
          itemsPerPage: classroomFilters.num_devoluciones,
          currentPage: classroomFilters.num_pagina,
          totalItems: total
        }"
            [ngClass]="{justifyContentFlexStart:item?.displayName === 'Nombre', estadoHeaderTable:item?.displayName === 'Estado'}">
            {{item.displayName}} </th>
          <th></th>
        </tr>
      </thead>
      <div class="loaderLine" *ngIf="!isClassroomListLoaded">
        <loaderIcon [class.active]="!isClassroomListLoaded"></loaderIcon>
      </div>
      <tbody class="tbodyCustom" *ngIf="isClassroomListLoaded">
        <div class="noItems" *ngIf="classroomList.length == 0">
          No hay items que mostrar
        </div>
        <tr class="tr" (click)="openClassroomPopup(classroom)" *ngFor="let classroom of classroomList">
          <td></td>
          <td class="td" *ngFor="let column of displayedColumns; let index = index;"
            [ngClass]="{fwBold: column?.property === 'name' || column?.property === 'fullName' ,justifyContentFlexStart:column?.property === 'name' || column?.property === 'fullName'  ,estadoTable:column?.property === 'state',dFlexGap1em:column?.property === 'enrollments', textCenter: column?.property === 'schedule' }">
            <p [title]="classroom[column?.property] ?? '-'"
              [ngClass]="{timeBox: column?.property === 'registerDate' || column?.property === 'endDate', estadoTabla: column?.property === 'state', formacionTabla: column?.property === 'enrollments', estadoTablaCursando:column?.property === 'state' && classroom.state == 'cursando' }">
              {{classroom[column?.property] ?? '-'}} </p>
          </td>
          <td class="icons">
            <mat-icon class="material-icons-outlined" *ngIf="classroom.active"
              (click)="$event.stopPropagation();toggleBlock(classroom)">lock</mat-icon>
            <mat-icon *ngIf="!classroom.active"
              (click)="$event.stopPropagation();toggleBlock(classroom)">lock_open</mat-icon>
          </td>
        </tr>
      </tbody>
    </table>

    <pagination-controls [autoHide]="true" previousLabel="Anterior" nextLabel="Siguiente"
      (pageChange)="pageChanged($event)" class="pagination"></pagination-controls>

  </div>
</div>