import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { PopupService } from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { EditTutoringPopupComponent } from 'src/app/Popups/EditTutoring-Popup/EditTutoring-Popup.component';
import { EditTutoringreservationComponent } from 'src/app/Popups/EditTutoringreservation-Popup/EditTutoringreservation.component';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { TutoringService } from 'src/app/Services/Api/Tutoring.service';
import { GestionUsuariosProfesoresDetallesTutoriasClass } from 'src/app/Shared/Classes/GestionUsuariosProfesoresDetallesTutoriasClass';
import { iTutoringSession } from 'src/app/Shared/Interfaces/Utils/iTutoringSession';

@Component({
  selector: 'GestionUsuarios-Profesores-Detalles-Tutorias',
  templateUrl: './GestionUsuarios-Profesores-Detalles-Tutorias.component.html',
  styleUrls: ['./GestionUsuarios-Profesores-Detalles-Tutorias.component.scss'],
})
export class GestionUsuariosProfesoresDetallesTutoriasComponent
  implements OnInit
{
  @Input() get dataTeacher() {
    return this.extraClass._dataTeacher;
  }
  set dataTeacher(value) {
    this.extraClass._dataTeacher = value;
    this.extraClass.teacherFullName = `${value.user.name} ${value.user.surname}`;
    if (value && value.id) {
      this.getTutoringsByTeacher();
    }
  }

  extraClass: GestionUsuariosProfesoresDetallesTutoriasClass =
    new GestionUsuariosProfesoresDetallesTutoriasClass();

  constructor(
    private popupSE: PopupService,
    private generalLoaderSE: GeneralLoaderService,
    private tutoringSE: TutoringService,
    private teacherSE: TeacherService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isDataLoadedHandler();
  }

  /**
   * HANDLERS
   */

  successEditTutoringHandler(res: iResultHttp) {
    const { data } = res;
    this.getTutoringsByTeacher();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteTutoringHandler(res: iResultHttp) {
    const { data } = res;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTutoringsByTeacherHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.tutorings = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateTutoringStudentHandler(res: iResultHttp) {
    const { data } = res;
    this.getTutoringsByTeacher();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successResetTutoringStudentHandler(res: iResultHttp) {
    const { data } = res;
    this.getTutoringsByTeacher();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */

  onRowClick(row) {
    this.openReservaTutoringPopup(row);
  }

  openEditPopup(tutoring) {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(EditTutoringPopupComponent, {
      data: { tutoringId: tutoring.tutoringId, teacherId: this.dataTeacher.id },
    });
    this.popupSE
      .returnData()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        const returnValue = res.returnValue;
        if (returnValue) {
          this.editTutoring(returnValue);
        }
        subject.next(true);
      });
  }

  onDeleteClick(tutoringId: number) {
    this.deleteTutoring(tutoringId);
  }

  ifCannotDeleteBecauseTutoringInUse(id) {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      accion: 'Eliminar',
      elemento: 'la tutoría aunque tenga alumnos asignados',
    });
    this.popupSE
      .returnData()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.deleteTutoring(id, true);
        subject.next('');
        subject.complete();
      });
  }

  openReservaTutoringPopup(row: iTutoringSession['students'][0]) {
    const subject = StaticUtilitiesService.createSubject();
    const { hours, date, tutoringStudentId } = row;
    this.popupSE.openPopup(EditTutoringreservationComponent, {
      data: {
        hours,
        date,
        tutoringStudentId,
        teacherName: this.extraClass.teacherFullName,
      },
    });
    this.popupSE
      .returnData()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        const returnValue = res.returnValue;
        if (returnValue) {
          this.updateTutoringStudent(returnValue);
        }
        subject.next(true);
      });
  }

  isDataLoadedHandler() {
    this.generalLoaderSE
      .getLoaderAmount()
      .pipe(takeUntil(this.extraClass.generalDataLoaderSubject))
      .subscribe((res) => {
        this.extraClass.isDataLoaded = res === 0;
        this.cdr.detectChanges();
      });
  }

  /**
   * API CALLS
   */
  editTutoring(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successEditTutoringHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteTutoring(id: number, forceDelete: boolean = false) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.delete(behaviorSubject, id, forceDelete);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteTutoringHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
          {
            method: () => this.ifCannotDeleteBecauseTutoringInUse(id),
            error: true,
          },
        ]);
      });
  }

  getTutoringsByTeacher() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.teacherSE.getTutoringsByTeacherId(
      behaviorSubject,
      this.extraClass._dataTeacher.id
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetTutoringsByTeacherHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateTutoringStudent(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.updateTutoringStudent(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateTutoringStudentHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  resetTutoringStudent(id) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.resetTutoringStudent(behaviorSubject, id);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResetTutoringStudentHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
