<main-table
  [dataSource]="allMasters"
  [headerData]="tableHead"
  [isDataLoaded]="areReadyAllMasters"
  (clickOnRow)="handleClickRow($event)"
>

</main-table>

<ng-container *ngFor="let item of allMasters | paginate: { itemsPerPage: filterAllMasters.num_devoluciones, currentPage: filterAllMasters.num_pagina,  totalItems: totalMasters }"></ng-container>

<pagination-controls [autoHide]="true" previousLabel="Anterior"  nextLabel="Siguiente" (pageChange)="pageChangeEmmiter($event)" ></pagination-controls>