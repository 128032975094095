import { Component, ElementRef, HostListener, forwardRef, AfterViewInit, Host, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Custom directive for creating a text editable component.
 * This directive allows the user to edit the text content of an element.
 * It implements the ControlValueAccessor interface to enable two-way data binding.
 *
 * @example
 * <div 
 *  text-editable 
 *  [(ngModel)]="text"
 *  (handlerChange)="changeTest($event)"
 *  placeholder="Patata frita"
 * ></div>
 *
 * @remarks
 * This directive should be used with an element that has the `contenteditable` attribute set to `true`.
 * It listens to input, blur, and keydown events to update the value and emit changes.
 * It also supports disabling the editable behavior by setting the `disabled` property.
 */
@Component({
  selector: '[text-editable]',
  template: `
    <ng-content></ng-content>
  `,
  providers: [ { 
    provide: NG_VALUE_ACCESSOR, 
    useExisting: forwardRef(() => TextEditableComponent), multi: true 
  }],
  styleUrls: ['./text-editable.directive.scss']
})
export class TextEditableComponent implements ControlValueAccessor, AfterViewInit {
  @HostListener('input') callOnChange() {
    this.onChange(this.el.nativeElement.textContent);
  }
  @HostListener('blur') callOnTouched() { 
    this.onTouched(); 
    this.handlerChange.emit(this.el.nativeElement.textContent);
  }

  @HostListener('keydown.enter', ['$event']) onEnter(event: KeyboardEvent) {
    event.preventDefault();
    this.el.nativeElement.blur();
  }

  private _canEdit:boolean = false

  @Output() handlerChange: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  set canEdit(value: boolean) {
    this.el.nativeElement.setAttribute('contenteditable', String(value));
    this._canEdit = value;
  }
  get canEdit(): boolean {
    return this._canEdit;
  }

  onChange: (value: string) => void = () => {}; // initialize onChange property
  onTouched: () => void = () => {}; // initialize onTouched property

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.el.nativeElement.setAttribute('contenteditable', String(this.canEdit));
  }

  // called when model is written to view. (model -> view)
  writeValue(value) {
    this.el.nativeElement.textContent = value || '';
  }

  registerOnChange(fn) { this.onChange = fn; }
  registerOnTouched(fn) { this.onTouched = fn; }

  // called when element property disabled is changed 
  setDisabledState(val: boolean): void {
    this.el.nativeElement.setAttribute('disabled', String(val));
    this.el.nativeElement.setAttribute('contenteditable', String(!val));
  }

}