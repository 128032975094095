import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iOptionsSelector,
  iResultHttp,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { MasterService } from 'src/app/Services/Api/Master.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-AddDobleTitulacion-Popup',
  templateUrl: './AddDobleTitulacion-Popup.component.html',
  styleUrls: ['./AddDobleTitulacion-Popup.component.scss'],
})
export class AddDobleTitulacionPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  isCompleted: boolean = false;
  masters: any = null;
  optionsSelectorMasters: iOptionsSelector = {
    items: [],
    search: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Selecciona dos masters',
    multiple: {
      isMultiple: true,
      maxSelectedItems: 2,
      checkbox: true,
    },
  };
  objectToPass: any = {};

  data: any = {
    name: '',
    masterOneId: null,
    masterTwoID: null,
    modality: [
      {
        paymentModality: 1,
        price: null,
      },
      {
        paymentModality: 2,
        price: null,
      },
      {
        paymentModality: 3,
        price: null,
      },
      {
        paymentModality: 4,
        price: null,
      },
      {
        paymentModality: 5,
        price: null,
      },
    ],
  };

  constructor(
    public dialogRef: MatDialogRef<AddDobleTitulacionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private masterSE: MasterService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllMasters();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  selectedMasters(event: any) {
    if (event.length === 1 || event.length == 0) return;
    this.data.masterOneId = event[0];
    this.data.masterTwoId = event[1];
    this.checkIfCompleted();
  }
  checkIfCompleted() {
    if (
      this.data.name &&
      this.data.masterOneId &&
      this.data.masterTwoId &&
      this.data.modality.length > 0 &&
      this.data.modality[0].price &&
      this.data.modality[1].price &&
      this.data.modality[2].price &&
      this.data.modality[3].price
    ) {
      this.isCompleted = true;
    } else {
      this.isCompleted = false;
    }
  }

  /**
   * API CALLS
   */

  getAllMasters() {
    this.masterSE.getActiveMasters();
    this.masterSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;

        this.optionsSelectorMasters.items = data;
        this._unsubInd.next('');
      });
    this.masterSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  createDobleTitulation() {
    this.masterSE.createDoubleMaster(this.data);
    this.masterSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'Doble titulación creada correctamente'
        );
        this.closePopup(res.data);
        this._unsubInd2.next('');
      });
    this.masterSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
