<div class="tableContainerCustom" skeleton [isDataLoaded]="isUserListLoaded">
  <table class="mainTableCustom" >
    <thead class="theadCustom">
      <tr class="tr">
        <th class="th"> </th>
        <th class="th" *ngFor="let item of displayedColumns"
          [ngClass]="{justifyContentFlexStart:item?.displayName === 'Nombre', estadoHeaderTable:item?.displayName === 'Estado'}">
          {{item.displayName}}  </th>
          <th></th>
      </tr>
    </thead>
    <div class="loaderLine" *ngIf="!isUserListLoaded">
      <loaderIcon [class.active]="!isUserListLoaded"></loaderIcon >
      </div>
    <tbody class="tbodyCustom" *ngIf="isUserListLoaded">
      <div class="noItems" *ngIf="userList.length == 0">
        No hay items que mostrar
      </div>
      <tr class="tr" (click)="userDetail(user)" *ngFor="let user of userList | paginate:{
      itemsPerPage: filtersUsers.num_devoluciones,
      currentPage: filtersUsers.num_pagina,
      totalItems: total
    }" >
        <td class="td"> <span class="circleWithInitials">{{user.name?.charAt(0) + user.surname?.charAt(0) }}</span> </td>

        <td class="td" *ngFor="let column of displayedColumns; let index = index;"
          [ngClass]="{fwBold: column?.property === 'name' || column?.property === 'fullName' ,justifyContentFlexStart:column?.property === 'name' || column?.property === 'fullName'  ,estadoTable:column?.property === 'state',dFlexGap1em:column?.property === 'enrollments', textCenter: column?.property === 'schedule' }">
          <p
            [ngClass]="{timeBox: column?.property === 'registerDate' || column?.property === 'endDate', estadoTabla: column?.property === 'state', formacionTabla: column?.property === 'enrollments', estadoTablaCursando:column?.property === 'state' && user.state == 'cursando' }">
            {{user[column?.property] ?? '-'}}  </p> 
        </td>
        <td class="icons">
          <mat-icon class="material-icons-outlined" *ngIf="user.state === 'Activo'" (click)="$event.stopPropagation();toggleBlock(user)">lock</mat-icon >
          <mat-icon *ngIf="user.state === 'Inactivo'" (click)="$event.stopPropagation();toggleBlock(user)">lock_open</mat-icon>
          <!-- <mat-icon>lock_reset</mat-icon> -->
        </td>
      </tr>
    </tbody>
  </table>

</div>