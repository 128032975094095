import { Component, Input, OnInit } from '@angular/core';
import { iUnsubscribeDestroy } from '@quasar-dynamics/basic-designsystem';
import { StaticUtilitiesService, iOptionsSelector, iResultHttp } from '@quasar-dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';

@Component({
  selector: 'DetailView-Director',
  templateUrl: './DetailView-Director.component.html',
  styleUrls: ['./DetailView-Director.component.scss']
})
export class DetailViewDirectorComponent extends iUnsubscribeDestroy implements OnInit {

  private _directors: any[] = [];
  private _isEditing: boolean = false;
  private _hydrated: boolean = false;
  ngModelSelected:any = null;
  DirectorOptions: iOptionsSelector={
    items:[],
    bindLabel:'name',
    placeholder:'Selecciona un director',
    clearOnClick: true,
  };

  @Input() 
  get directors(): any[] {
    return this._directors;
  }
  set directors(value: any[]) {
    this._directors = value;
  }

  @Input() 
  get isEditing(): boolean {
    return this._isEditing;
  }
  set isEditing(value: boolean) {
    this._isEditing = value;
  }

  constructor(
    private teachersSE: TeacherService
  ) { 
    super();
  }

  ngOnInit() {
  }

  trackby(index:number, _){
    return index
  }


}