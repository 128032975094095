import { Component, OnInit } from '@angular/core';
import { TSendIsEditing } from '../Formaciones-DetailView/_holder/DetailView-baseContainer/DetailView-baseContainer.component';
import { DobleTitulacionDetailViewClass } from 'src/app/Shared/Classes/DobleTitulacionDetailViewClass';
import {
  DebouncerService,
  GeneralLoaderService,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import { DoubleMasterService } from 'src/app/Services/Api/DoubleMaster.service';
import { filter, takeUntil } from 'rxjs';
import {
  iResultHttp,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { iDoubleMaster } from 'src/app/Shared/Interfaces/Utils/iDoubleMaster';
import { MasterService } from 'src/app/Services/Api/Master.service';

@Component({
  selector: 'DobleTitulacion-DetailView',
  templateUrl: './DobleTitulacion-DetailView.component.html',
  styleUrls: ['./DobleTitulacion-DetailView.component.scss'],
})
export class DobleTitulacionDetailViewComponent implements OnInit {
  extraClass: DobleTitulacionDetailViewClass =
    new DobleTitulacionDetailViewClass();

  constructor(
    private staticUtilitiesSE: StaticUtilitiesService,
    private doubleMasterSE: DoubleMasterService,
    private generalLoaderSE: GeneralLoaderService,
    private masterSE: MasterService
  ) {}

  async ngOnInit() {
    this.extraClass.doubleMasterId = await this.getparams();
    this.getDoubleMasterById();
    this.getMasterSelector();
  }

  /**
   * HANDLERS
   */
  successGetDoubleMasterByIdHandler(res: iResultHttp) {
    const { data } = res;
    this.splitResponseDoubleMasterById(data);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateDoubleMasterHandler(res: iResultHttp) {
    const { data } = res;
  }

  /**
   * FUNCTIONALITIES
   */

  splitResponseDoubleMasterById(data: iDoubleMaster) {
    const { description, hours, includedMasters, prices, students } = data;
    this.extraClass.description = description;
    this.extraClass.hours = hours;
    this.extraClass.includedMasters = includedMasters;
    this.extraClass.prices = prices;
    this.extraClass.students = students;
  }

  handelChangeEdit(event: TSendIsEditing) {
    this.extraClass.editableController = {
      ...this.extraClass.editableController,
      ...event,
    };

    this.extraClass.setAsReadonly();
  }

  toggleActive() {
    if(!this.extraClass.editableController['Descripción']) return;
    const isActiva = this.extraClass.description.activeLabel === 'Activa';
    this.extraClass.description.activeLabel = isActiva ? 'Inactiva' : 'Activa';
    this.prepareToUpdateTitleAndActive(!isActiva, 'active');

  }

  checkIfNumberAndEdit(event: any, key: string, index?) {
    const { text, isNumber } = this.checkIfNumber(event);
    // If it is a number
    if (isNumber) {
      DebouncerService.formDebouncer(
        () => this.prepareToUpdatePrices(text, key, index),
        key,
        text
      );
    } else {
      // We are filtering the text to only allow numbers
      event.target.innerHTML = text.replace(/[^0-9.-]/g, '');
    }
  }

  checkIfNumber(event: any): { text: string; isNumber: boolean } {
    let text = event.target.innerHTML.replace(',', '.').trim();
    // If it is a number
    if (this.extraClass.numberRegex.test(text)) {
      return { text, isNumber: true };
    } else {
      // We are filtering the text to only allow numbers
      event.target.innerHTML = text.replace(/[^0-9.-]/g, '');
      return { text, isNumber: false };
    }
  }

  getparams(): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = this.staticUtilitiesSE.getParams();
      resolve(params.doubleMaster);
    });
  }

  formDebouncer(value, key: string) {
    DebouncerService.formDebouncer(
      () => this.prepareToUpdateTitleAndActive(value, key),
      key,
      value
    );
  }

  prepareToUpdateTitleAndActive(value, key: string) {
    const objectToPass = {
      id: this.extraClass.doubleMasterId,
      [key]: value,
    };
    this.updateDoubleMaster(objectToPass);
  }

  prepareToUpateMasters(value, key: string) {
    const objectToPass = {
      id: this.extraClass.doubleMasterId,
      ...this.extraClass.includedMasters,
      [key]: value,
    };
    this.updateDoubleMaster(objectToPass);
  }

  prepareToUpdatePrices(value, key: string, index: number) {
    const objectToPass = {
      id: this.extraClass.doubleMasterId,
      modality: this.extraClass.prices,
    };
    objectToPass.modality[index][key] = value;
    this.updateDoubleMaster(objectToPass);
  }

  /**
   * API CALLS
   */

  getDoubleMasterById() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.doubleMasterSE.getDoubleMasterById(
      behaviorSubject,
      this.extraClass.doubleMasterId
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetDoubleMasterByIdHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getMasterSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    this.masterSE.getMastersForFilter();
    this.masterSE
      .getResult()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        let { data } = res;
        this.extraClass.masterSelectorOptions.items = data;
        this.generalLoaderSE.removeFromLoaderAmount();
        subject.next('');
      });
    this.masterSE
      .getResultError()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        if (res.status != 401) {
          if (res.status == 404) {
            this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        subject.next('');
      });
  }

  updateDoubleMaster(objectToPass) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.doubleMasterSE.updateDoubleMaster(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateDoubleMasterHandler(res),
          },

        ]);
      });
  }
}
