import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iOptionsSelector } from '@quasar_dynamics/basic-designsystem';

export interface iOptionsFilters {
  label: string;
  options: iOptionsSelector;
  model: any;
}

export interface iFilterReturn {
  label: string;
  value: any;
}

/**
 * Component for a searcher and filter functionality.
 */
@Component({
  selector: 'searcher-and-filter',
  templateUrl: './searcher-and-filter.component.html',
  styleUrls: ['./searcher-and-filter.component.scss'],
})
export class SearcherAndFilterComponent implements OnInit {
  /**
   * Event emitter for search change.
   */
  @Output() onSearchChange: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Event emitter for filter change.
   */
  @Output() onFilterChange: EventEmitter<iFilterReturn> =
    new EventEmitter<iFilterReturn>();

  /**
   * The search model.
   */
  @Input() searchModel: string = '';
  @Input() placeholder: string = 'Busca aquí...';

  /**
   * The options for the selectors.
   */
  @Input() selectors: iOptionsFilters[] = [
    {
      label: '',
      options: {
        items: [],
      },
      model: null,
    },
  ];

  /**
   * Indicates if the component is active.
   */
  active: boolean = false;

  constructor() {}

  ngOnInit() {}

  /**
   * Handles the search change event.
   */
  searchChange() {
    this.onSearchChange.emit(this.searchModel);
  }

  /**
   * Handles the filter change event.
   * @param model - The selected model.
   * @param label - The label of the selected model.
   */
  filterChange(model, label) {
    this.onFilterChange.emit({ value: model, label: label });
  }
}
