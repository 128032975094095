<!-- <div class="tableContainer">

</div> -->

<main-table
  [isDataLoaded]="isDataPromotionLoaded"
  [headerData]="tableHead"
  [dataSource]="promotions"
  (clickOnRow)="goToPromocionDetailView($event)"
>
</main-table>

<ng-container *ngFor="let promotion of promotions  | paginate:{
  itemsPerPage: filters.num_devoluciones,
  currentPage: filters.num_pagina,
  totalItems: total
}" ></ng-container>



<pagination-controls [autoHide]="true" previousLabel="Anterior"  nextLabel="Siguiente" (pageChange)="pageChange($event)"></pagination-controls>
