import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'detailView-TeacherCard',
  templateUrl: './DetailView-TeacherCard.component.html',
  styleUrls: ['./DetailView-TeacherCard.component.scss']
})
export class DetailViewTeacherCardComponent implements OnInit {

  @Input() fakeId: string = "123";

  fakeIdLoaded:boolean = false;

  open = false;



  startsOptions = {
    fontSize: 20,
    stars: 5,
    // Cambiar por el id del profesor
    name: 'a' + String(Math.floor(Math.random() * 1001)),
    rating: 1.5
  }

  arrayToTest = new Array(5);

  constructor() { }

  ngOnInit() {
  }

  switchOpen(){
    this.open = !this.open;
  }

}
