import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'enviar-fechas-button',
  templateUrl: './enviar-fechas-button.component.html',
  styleUrls: ['./enviar-fechas-button.component.scss']
})
export class EnviarFechasButtonComponent implements OnInit {

  classList: string = 'generalbutton sendDates';

  constructor() { }

  ngOnInit() {
    this.classList = 'generalbutton sendDates'
  }

}
