import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { Observable } from 'rxjs';
import { LoginService } from '../../Api/Login.service';

@Injectable({
  providedIn: 'root'
})

export class HasTokenGuard implements CanActivate {

  constructor( private router:Router, private notificationSE:NotificationsService) { }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(!localStorage.getItem('token')){
      this.router.navigate(['']);
      this.notificationSE.showWarning("No tienes permisos para acceder a esta página");
      return false;
    }    
    return true;
  }
  

}