import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-GestionUsuarios-Home',
  templateUrl: './GestionUsuarios-Home.component.html',
  styleUrls: ['./GestionUsuarios-Home.component.scss'],
})
export class GestionUsuariosHomeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
