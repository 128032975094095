import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormalizedProposalService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  protected _GetFormalizedProposal = new BehaviorSubject<any>(null);
  getResultGetFormalizedProposal() { return this._GetFormalizedProposal }
  clearResultGetFormalizedProposal() { this._GetFormalizedProposal.next(null) }
  protected _GetFormalizedProposalError = new BehaviorSubject<any>(null);
  getResultGetFormalizedProposalError() { return this._GetFormalizedProposalError }
  clearResultGetFormalizedProposalError() { this._GetFormalizedProposalError.next(null) }

  protected _CreateFormalizedProposal = new BehaviorSubject<any>(null);
  getResultCreateFormalizedProposal() { return this._CreateFormalizedProposal }
  clearResultCreateFormalizedProposal() { this._CreateFormalizedProposal.next(null) }
  protected _CreateFormalizedProposalError = new BehaviorSubject<any>(null);
  getResultCreateFormalizedProposalError() { return this._CreateFormalizedProposalError }
  clearResultCreateFormalizedProposalError() { this._CreateFormalizedProposalError.next(null) }

  constructor(private http: HttpClient) {
    super();
  }

  getList(params: any) {
    this.http.get(this.urlApi + '/api/formalized/proposal' + this.optionsGet(params), this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data });
        this.sendNextObservable(this._GetFormalizedProposal, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._GetFormalizedProposalError, this.res, true, { method: this.getList, args: [params] });
        this.checkStatusError(error);
      },
    });
  }

  create(enrollment:any){
    this.http.post(this.urlApi+"/api/formalized/proposal", enrollment, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextObservable(this._CreateFormalizedProposal, this.res);
      },
      error: (error:any) => {
         
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._CreateFormalizedProposalError, this.res,true ,{ method: this.create, args: [enrollment] });
        this.checkStatusError(error);
      },
    });
  }
}

