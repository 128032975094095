import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iResultHttp } from '@quasar-dynamics/basic-designsystem';
import { DefaultBaseService } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimelineService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll(behaviorSubject: BehaviorSubject<any>, params: any) {
    this.http
      .get(
        this.urlApi + '/api/timeline' + this.optionsGet(params),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAll,
            args: [behaviorSubject, params],
          });
        },
      });
  }


}
