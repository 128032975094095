<div class="fullSection">
  <div class="subColumnLong">
    <div class="labelLine">
      <label for="selector">Buscador de alumnos</label>
      <label for="selector" *ngIf="dataFromCRM">{{dataFromCRM.name}}</label>
    </div>
    <selector [options]="selectorOptionsStudent" ngDefaultControl [(ngModel)]="selectorStudent" (change)="selectedUser($event) ; emitData()"></selector>
  </div>
  <div class="rowFirstSection">
    <CustomInput [readOnly]="isSelectedUser" [placeHolder]="'Nombre'" ngDefaultControl (change)="emitData()" [(ngModel)]="data.name" [label]="'Nombre (*)'"></CustomInput>
    <CustomInput [readOnly]="isSelectedUser" [placeHolder]="'Apellidos'" ngDefaultControl (change)="emitData()" [(ngModel)]="data.surname" [label]="'Apellidos (*)'"></CustomInput>
  </div>

  <div class="rowFirstSection">
    <div class="telefonoDeContacto">
      <div class="subColumn">
        <label for="selector">Teléfono de contacto (*)</label>
        <CountrySelector ngDefaultControl (emitCountry)="addPhonePrefix($event)" [(ngModel)]="data.phonePrefix" [country]="data?.phonePrefix" *ngIf="!isSelectedUser"></CountrySelector>
        <CustomInput [type]="'text'" class="width100" [readOnly]="isSelectedUser" ngDefaultControl (change)="emitData()" [(ngModel)]="data.phonePrefix" *ngIf="isSelectedUser"></CustomInput>
      </div>
      <div class="inputDiv">
        <CustomInput [readOnly]="isSelectedUser" [placeHolder]="'Teléfono'" ngDefaultControl (change)="emitData()" [(ngModel)]="data.phone"></CustomInput>
      </div>
    </div>
      <CustomInput [readOnly]="isSelectedUser" [placeHolder]="'Correo electrónico'" ngDefaultControl (change)="emitData()" [(ngModel)]="data.email" [label]="'Correo electrónico (*)'"></CustomInput>
  </div>
  
</div>