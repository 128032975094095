import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iBehaviourSubject,
  iResultHttp,
  iStaticUtilities,
  SubjectManagerService,
} from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import {
  iPromotionFormation,
  iPromotionFormationFilter,
} from 'src/app/Shared/Interfaces/iPromotionFormation';
import { iTeacherPromotion } from 'src/app/Shared/Interfaces/iTeacherPromotion';

@Injectable({
  providedIn: 'root',
})
export class PromotionFormationService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  subjectManager: iBehaviourSubject = new iBehaviourSubject();

  protected _resultPromotionList = new BehaviorSubject<any>(null);
  getResultPromotionList() {
    return this._resultPromotionList;
  }
  clearResultPromotionList() {
    this._resultPromotionList.next(null);
  }
  protected _resultPromotionListError = new BehaviorSubject<any>(null);
  getResultPromotionListError() {
    return this._resultPromotionListError;
  }
  clearResultPromotionListError() {
    this._resultPromotionListError.next(null);
  }

  protected _resultPromotionsByFormationId = new BehaviorSubject<any>(null);
  getResultPromotionsByFormationId() {
    return this._resultPromotionsByFormationId;
  }
  clearResultPromotionsByFormationId() {
    this._resultPromotionsByFormationId.next(null);
  }
  protected _resultPromotionsByFormationIdError = new BehaviorSubject<any>(
    null
  );
  getResultPromotionsByFormationIdError() {
    return this._resultPromotionsByFormationIdError;
  }
  clearResultPromotionsByFormationIdError() {
    this._resultPromotionsByFormationIdError.next(null);
  }

  protected _resultPromotionsByFormationId2 = new BehaviorSubject<any>(null);
  getResultPromotionsByFormationId2() {
    return this._resultPromotionsByFormationId2;
  }
  clearResultPromotionsByFormationId2() {
    this._resultPromotionsByFormationId2.next(null);
  }
  protected _resultPromotionsByFormationId2Error = new BehaviorSubject<any>(
    null
  );
  getResultPromotionsByFormationId2Error() {
    return this._resultPromotionsByFormationId2Error;
  }
  clearResultPromotionsByFormationId2Error() {
    this._resultPromotionsByFormationId2Error.next(null);
  }

  protected _resultStudentsPromotions = new BehaviorSubject<any>(null);
  getResultStudentsPromotions() {
    return this._resultStudentsPromotions;
  }
  clearResultStudentsPromotions() {
    this._resultStudentsPromotions.next(null);
  }
  protected _resultStudentsPromotionsError = new BehaviorSubject<any>(null);
  getResultStudentsPromotionsError() {
    return this._resultStudentsPromotionsError;
  }
  clearResultStudentsPromotionsError() {
    this._resultStudentsPromotions.next(null);
  }

  protected _resultTeachersPromotions = new BehaviorSubject<any>(null);
  getResultTeachersPromotions() {
    return this._resultTeachersPromotions;
  }
  clearResultTeachersPromotions() {
    this._resultTeachersPromotions.next(null);
  }
  protected _resultTeachersPromotionsError = new BehaviorSubject<any>(null);
  getResultTeachersPromotionsError() {
    return this._resultTeachersPromotionsError;
  }
  clearResultTeachersPromotionsError() {
    this._resultTeachersPromotions.next(null);
  }

  constructor(
    private http: HttpClient,
    private SubjectManagerService: SubjectManagerService
  ) {
    super();
  }

  getAll(queryParams: iPromotionFormationFilter) {
    this.http
      .get(
        this.urlApi + '/api/promotion/formation' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedPromotionFormation =
            iPromotionFormation.convertFromCollection(
              iStaticUtilities.normalizeNamesCollection(data)
            );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedPromotionFormation,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [queryParams],
          });
          this.checkStatusError(error);
        },
      });
  }

  getPromotionDetailStudentsMasterCourse(
    id: number,
    queryParams: iPromotionFormationFilter
  ) {
    this.http
      .get(
        this.urlApi +
          '/api/promotion/students/detail/' +
          id +
          this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedPromotionFormation =
            iStaticUtilities.normalizeNamesCollection(data.data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedPromotionFormation, total: data.total },
          });
          this.sendNextObservable(this._resultStudentsPromotions, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._resultStudentsPromotionsError,
            this.res,
            true,
            {
              method: this.getPromotionDetailStudentsMasterCourse,
              args: [id, queryParams],
            }
          );
          this.checkStatusError(error);
        },
      });
  }

  getPromotionDetailTeachers(
    id: number,
    queryParams: iPromotionFormationFilter
  ) {
    this.http
      .get(
        this.urlApi +
          '/api/promotion/teachers/detail/' +
          id +
          this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedPromotionFormation =
            iPromotionFormation.convertFromCollection(
              iStaticUtilities.normalizeNamesCollection(data)
            );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedPromotionFormation,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getPromotionDetailTeachers,
            args: [id, queryParams],
          });
          this.checkStatusError(error);
        },
      });
  }

  getPromotionList(
    behaviorSubject: BehaviorSubject<any>,
    queryParams: iPromotionFormationFilter
  ) {
    this.http
      .get(
        this.urlApi + '/api/promotion' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: data.data, total: data.total },
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getPromotionList,
            args: [behaviorSubject, queryParams],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSingle(id: number | null) {
    this.http
      .get(this.urlApi + '/api/promotion/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedPromotionFormation =
            iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedPromotionFormation,
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  getPromotionsByFormationId(id: number) {
    this.http
      .get(
        this.urlApi + '/api/promotion/selector/formation/' + id,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedPromotionFormation =
            iStaticUtilities.normalizeNamesCollection(data.data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedPromotionFormation,
          });
          this.sendNextObservable(
            this._resultPromotionsByFormationId,
            this.res
          );
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._resultPromotionsByFormationIdError,
            this.res,
            true,
            { method: this.getPromotionsByFormationId, args: [id] }
          );
          this.checkStatusError(error);
        },
      });
  }

  getPromotionsByFormationId2(id: number) {
    this.http
      .get(
        this.urlApi + '/api/promotion/selector/formation/' + id,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedPromotionFormation =
            iStaticUtilities.normalizeNamesCollection(data.data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedPromotionFormation,
          });
          this.sendNextObservable(
            this._resultPromotionsByFormationId2,
            this.res
          );
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._resultPromotionsByFormationId2Error,
            this.res,
            true,
            { method: this.getPromotionsByFormationId, args: [id] }
          );
          this.checkStatusError(error);
        },
      });
  }

  getPromotionsByFormationAndTeacherId(formationId: number, teacherId: number) {
    this.http
      .get(
        this.urlApi +
          '/api/promotion/selector/formation/' +
          formationId +
          '/' +
          teacherId,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedPromotionFormation =
            iStaticUtilities.normalizeNamesCollection(data.data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedPromotionFormation,
          });
          this.sendNextObservable(this._resultTeachersPromotions, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._resultTeachersPromotionsError,
            this.res,
            true,
            {
              method: this.getPromotionsByFormationAndTeacherId,
              args: [formationId, teacherId],
            }
          );
          this.checkStatusError(error);
        },
      });
  }

  getPromotionsByFormationIdWithSearch(id: number, queryParams: any) {
    this.http
      .get(
        this.urlApi +
          '/api/promotion/formation/' +
          id +
          this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedPromotionFormation =
            iStaticUtilities.normalizeNamesCollection(data.data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedPromotionFormation, total: data.total },
          });
          this.sendNextObservable(
            this._resultPromotionsByFormationId,
            this.res
          );
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._resultPromotionsByFormationIdError,
            this.res,
            true,
            { method: this.getPromotionsByFormationId, args: [id] }
          );
          this.checkStatusError(error);
        },
      });
  }

  getPromotionsByFormationIdWithSearch2(id: number, queryParams: any) {
    this.http
      .get(
        this.urlApi +
          '/api/promotion/formation/' +
          id +
          this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedPromotionFormation =
            iStaticUtilities.normalizeNamesCollection(data.data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedPromotionFormation,
          });
          this.sendNextObservable(
            this._resultPromotionsByFormationId2,
            this.res
          );
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._resultPromotionsByFormationId2Error,
            this.res,
            true,
            { method: this.getPromotionsByFormationId, args: [id] }
          );
          this.checkStatusError(error);
        },
      });
  }

  getPromotionsByMasterOrCourseId(
    behaviorSubject: BehaviorSubject<any>,
    formationTypeofId: number,
    params: { formationTypeOf: 'course' | 'master' }
  ) {
    this.http
      .get(
        this.urlApi +
          '/api/promotion/selector/formationTypeofId/' +
          formationTypeofId +
          this.optionsGet(params),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getPromotionsByMasterOrCourseId,
            args: [behaviorSubject, formationTypeofId, params],
          });
        },
      });
  }

  assignTeacherToPromotion(teacherPromotion: iTeacherPromotion) {
    this.http
      .post(
        this.urlApi + '/api/promotion/teacher',
        teacherPromotion,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedPromotionFormation =
            iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedPromotionFormation,
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.assignTeacherToPromotion,
            args: [teacherPromotion],
          });
          this.checkStatusError(error);
        },
      });
  }

  duplicatePromotion(behaviorSubject: BehaviorSubject<any>, data) {
    this.http
      .post(this.urlApi + '/api/promotion/duplicate', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: data.data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.duplicatePromotion,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  getPromotionsByAllMastersSelector(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/promotion/all/master/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          data;
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getPromotionsByAllMastersSelector,
            args: [behaviorSubject],
          });
        },
      });
  }

  getPromotionDocuments(
    behaviorSubject: BehaviorSubject<any>,
    promotionId: number
  ) {
    this.http
      .get(
        this.urlApi + '/api/promotion/documents/' + promotionId,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getPromotionDocuments,
            args: [behaviorSubject, promotionId],
          });
        },
      });
  }
  getPromotionCoordinators(
    behaviorSubject: BehaviorSubject<any>,
    promotionId: number
  ) {
    this.http
      .get(
        this.urlApi + '/api/promotion/coordinators/' + promotionId,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getPromotionDocuments,
            args: [behaviorSubject, promotionId],
          });
        },
      });
  }

  getInfoDetailView(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/promotion/info/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getInfoDetailView,
            args: [behaviorSubject, id],
          });
        },
      });
  }

  getPromotionDates(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/promotion/dates/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getPromotionDates,
            args: [behaviorSubject, id],
          });
        },
      });
  }

  getPromotionTutoringSelector(
    behaviorSubject: BehaviorSubject<any>,
    tutoringStudentId: number
  ) {
    this.http
      .get(
        `${this.urlApi}/api/promotions/tutoring/${tutoringStudentId}/selector`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getPromotionTutoringSelector,
            args: [behaviorSubject, tutoringStudentId],
          });
        },
      });
  }

  getPromotionStudents(
    behaviorSubject: BehaviorSubject<any>,
    id: number,
    params: any
  ) {
    this.http
      .get(
        this.urlApi +
          '/api/promotion/students/detail/' +
          id +
          this.optionsGet(params),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getPromotionStudents,
            args: [behaviorSubject, id, params],
          });
        },
      });
  }

  getDataForFundae(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/promotion/fundae/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getDataForFundae,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  getStudentsByPromotionSelector(
    behaviorSubject: BehaviorSubject<any>,
    id: number
  ) {
    this.http
      .get(
        this.urlApi + '/api/promotion/student/selector/' + id,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getStudentsByPromotionSelector,
            args: [behaviorSubject, id],
          });
        },
      });
  }

  getDiplomas(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(
        this.urlApi + '/api/promotion/diplomas/' + data.id,
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.createPromotionByDuplicateTemplate,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  convocatoriaPorMastersSelector(
    behaviorSubject: BehaviorSubject<any>,
    data: { formationsId: number[] }
  ) {
    this.http
      .post(
        this.urlApi + '/api/promotion/dates/selector',
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.convocatoriaPorMastersSelector,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  edicionPorMasterYConvocatoriaSelector(
    behaviorSubject: BehaviorSubject<any>,
    data: { formationsId: number[]; dates: string[] }
  ) {
    this.http
      .post(
        this.urlApi + '/api/promotion/dates/formations/selector',
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.edicionPorMasterYConvocatoriaSelector,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  sendDiplomas(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(
        this.urlApi + '/api/promotion/diplomas/send/' + data.id,
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data.data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.createPromotionByDuplicateTemplate,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  createPromotionByDuplicateTemplate(
    behaviorSubject: BehaviorSubject<any>,
    data: any
  ) {
    this.http
      .post(
        this.urlApi + '/api/promotion/formation/' + data.id,
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data.data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.createPromotionByDuplicateTemplate,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  sendDatesToTeachers(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(
        this.urlApi + '/api/promotion/send/dates/' + data.id,
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data.data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.sendDatesToTeachers,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  deleteArchievePromotion(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(
        this.urlApi + '/api/promotion/formation/delete/' + data.id,
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data.data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.deleteArchievePromotion,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  create(promotionformation: iPromotionFormation) {
    this.http
      .post(
        this.urlApi + '/api/promotion',
        promotionformation,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [promotionformation],
          });
          this.checkStatusError(error);
        },
      });
  }

  updateDates(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(
        this.urlApi + '/api/promotion/dates/update/' + data.id,
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.updateDates,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  update(promotionformation: any) {
    this.http
      .put(
        this.urlApi + '/api/promotion/' + promotionformation.id,
        promotionformation,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [promotionformation],
          });
          this.checkStatusError(error);
        },
      });
  }

  delete(ids: Array<number | string>) {
    let idsObjs = this.convertArrayObject(ids, 'ids');
    this.http
      .delete(this.urlApi + '/api/promotion', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [ids],
          });
          this.checkStatusError(error);
        },
      });
  }
}
