<div class="fullView">
  <div class="topBar">
    <p class="fwBold">Datos de facturación</p>
    <!-- <div>
      <mat-checkbox [(ngModel)]="sameAddress" (change)="checkBoxSelect($event)"></mat-checkbox>
      <label for="">Misma dirección que dirección de contacto</label>
    </div> -->
  </div>
  <div class="loaderdiv" *ngIf="!studentDataStepThree">
    <loaderIcon></loaderIcon>
  </div>
  <div class="inputs" *ngIf="studentDataStepThree ">

    <div class="checkboxesRow">
      <div class="checkBoxLine">
        <mat-checkbox [(ngModel)]="isBusiness" (change)="isBusinessSelect($event)"></mat-checkbox>
        <label for="">Soy empresa</label>
      </div>
      <div class="checkBoxLine" >
        <mat-checkbox [(ngModel)]="sameAddress" (change)="sameDirectionSelect($event)"></mat-checkbox>
        <label for="">Misma dirección que dirección de contacto</label>
      </div>
    </div>

    <div class="dobleColumn" *ngIf="!sameAddress && isBusiness">
      <CustomInput  [(ngModel)]="studentData.user.bankData.company"  label="Razón social" [placeHolder]="'Razón social'" (change)="sendToUpdate(studentData.user.bankData.company, 'company', 'bankData')"></CustomInput>
      <CustomInput  [(ngModel)]="studentData.user.bankData.cif" label="CIF" [placeHolder]="'CIF'" (change)="sendToUpdate(studentData.user.bankData.cif, 'cif', 'bankData')"></CustomInput>
    </div>

    <div class="dobleColumn" *ngIf="sameAddress">
      <CustomInput  [readOnly]="true" [(ngModel)]="studentData.user.name"  label="Nombre" [placeHolder]="'Nombre'" (change)="sendToUpdate(studentData.user.bankData.owner , 'owner ', 'bankData')"></CustomInput>
      <CustomInput  [readOnly]="true" [(ngModel)]="studentData.user.surname" label="Apellidos" [placeHolder]="'Apellidos'" (change)="sendToUpdate(studentData.user.bankData.ownerDni, 'ownerDni', 'bankData')"></CustomInput>
    </div>

    <div class="dobleColumn" *ngIf="!isBusiness">
      <CustomInput  [(ngModel)]="studentData.user.bankData.owner"  label="Nombre completo" [placeHolder]="'Nombre'" (change)="sendToUpdate(studentData.user.bankData.owner , 'owner', 'bankData')"></CustomInput>
      <CustomInput  [(ngModel)]="studentData.user.bankData.ownerDni" label="DNI" [placeHolder]="'DNI'" (change)="sendToUpdate(studentData.user.bankData.ownerDni, 'ownerDni', 'bankData')"></CustomInput>
    </div>

    <div class="dobleColumn">
      <CustomInput  [(ngModel)]="studentData.user.bankData.fiscalAddress.street" [readOnly]="sameAddress" class="calleAvenida" label="Calle / Avenida" [placeHolder]="'Calle / Avenida'" (change)="sendToUpdate(studentData.user.bankData.fiscalAddress.street, 'street', 'fiscalAddress')"></CustomInput>
      <div class="tricolumn">
        <CustomInput  [(ngModel)]="studentData.user.bankData.fiscalAddress.number" [readOnly]="sameAddress" label="Número" [placeHolder]="'Número'" (change)="sendToUpdate(studentData.user.bankData.fiscalAddress.number, 'number', 'fiscalAddress')"></CustomInput>
        <CustomInput  [(ngModel)]="studentData.user.bankData.fiscalAddress.door" [readOnly]="sameAddress" label="Puerta" [placeHolder]="'Puerta'" (change)="sendToUpdate(studentData.user.bankData.fiscalAddress.door, 'door', 'fiscalAddress')"></CustomInput>
        <CustomInput  [(ngModel)]="studentData.user.bankData.fiscalAddress.postalCode" [readOnly]="sameAddress" label="Código Postal" [placeHolder]="'Código Postal'" (change)="sendToUpdate(studentData.user.bankData.fiscalAddress.postalCode, 'postalCode', 'fiscalAddress')"></CustomInput>
      </div>
      
    </div>
    <div class="tricolumn" >
      <CustomInput  [(ngModel)]="studentData.user.bankData.fiscalAddress.municipality" [readOnly]="sameAddress" label="Localidad" [placeHolder]="'Localidad'" (change)="sendToUpdate(studentData.user.bankData.fiscalAddress.municipality, 'municipality', 'fiscalAddress')"></CustomInput>
      <CustomInput  [(ngModel)]="studentData.user.bankData.fiscalAddress.province" [readOnly]="sameAddress" label="Provincia" [placeHolder]="'Provincia'" (change)="sendToUpdate(studentData.user.bankData.fiscalAddress.province, 'province', 'fiscalAddress')"></CustomInput>
      <div class="subColumnIdiomas" *ngIf="!sameAddress">
        <label for="">País</label>
        <selector [options]="opcionesPais" [(ngModel)]="studentData.user.bankData.fiscalAddress.country" (change)="sendToUpdate(studentData.user.bankData.fiscalAddress.country, 'country', 'fiscalAddress')" ></selector>
      </div>
      <CustomInput *ngIf="sameAddress" [(ngModel)]="studentData.user.bankData.fiscalAddress.country.nombre" [readOnly]="sameAddress" label="País"></CustomInput>
    </div>
    <div class="dobleColumn">
      <CustomInput  [(ngModel)]="studentData.user.bankData.fiscalAddress.email" [readOnly]="sameAddress" label="Email" [placeHolder]="'Email'" (change)="sendToUpdate(studentData.user.bankData.fiscalAddress.email, 'email', 'fiscalAddress')"></CustomInput>
      <CustomInput  [(ngModel)]="studentData.user.bankData.fiscalAddress.phone" [readOnly]="sameAddress" label="Teléfono" [placeHolder]="'Teléfono'" (change)="sendToUpdate(studentData.user.bankData.fiscalAddress.phone, 'phone', 'fiscalAddress')"></CustomInput>
  </div>
  </div>