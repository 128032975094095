import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IOptionCustomTextArea,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'CancelEnrollmentPopup',
  templateUrl: './CancelEnrollmentPopup.component.html',
  styleUrls: ['./CancelEnrollmentPopup.component.scss'],
})
export class CancelEnrollmentPopupComponent implements OnInit {
  ngModels = {
    reason: null,
    reasonOther: null,
  };

  reasonSelectorOptions: iOptionsSelector = {
    items: ['Económicos', 'Empresa', 'Horario', 'Visado', 'NS/NC', 'Otro'],
    append: true,
    clearable: false,
    placeholder: 'Selecciona una razón para cancelar la matrícula',
    search: true,
  };

  reasonOtherCustomTextareaOptions: IOptionCustomTextArea = {
    cols: 30,
    rows: 3,
    placeholder: 'Especifica la razón',
  };

  constructor(
    public dialogRef: MatDialogRef<CancelEnrollmentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit() {}

  /**
   * FUNCTIONALITIES
   */

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
}
