import { Component, Input, OnInit, Output } from '@angular/core';
import {
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { PermissionService } from 'src/app/Services/Api/Permission.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { EventEmitter } from '@angular/core';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import {
  GeneralLoaderService,
  iUnsubscribeDestroy,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ClassroomService } from 'src/app/Services/Api/Classroom.service';
import { environment } from 'src/environments/environment';
import { AddClassroomPopupComponent } from 'src/app/Popups/AddPopup/AddClassroom-Popup/AddClassroom-Popup.component';

@Component({
  selector: 'Configuracion-Aulas',
  templateUrl: './Configuracion-Aulas.component.html',
  styleUrls: ['./Configuracion-Aulas.component.scss'],
})
export class ConfiguracionAulasComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  classroomFilters: any = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  };
  total: number = 0;
  classroomList: any[] = [
    {
      name: 'Aula 1',
      observations: 'Aula 1 Observaciones',
    },
    {
      name: 'Aula 2',
      observations: 'Aula 2 Observaciones',
    },
    {
      name: 'Aula 3',
      observations: 'Aula 3 Observaciones',
    },
    {
      name: 'Aula 4',
      observations: 'Aula 4 Observaciones',
    },
    {
      name: 'Aula 5',
      observations: 'Aula 5 Observaciones',
    },
  ];
  isClassroomListLoaded: boolean = false;

  displayedColumns: any[] = [
    { displayName: 'Nombre', property: 'name' },
    { displayName: 'Observaciones', property: 'observations' },
  ];

  constructor(
    private popupSE: PopupService,
    private generalLoaderSE: GeneralLoaderService,
    private classroomSE: ClassroomService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllClassroom();
  }

  /**
   * HANDLERS
   */

  successCreateClassroomHandler(res: iResultHttp) {
    const { data } = res;
    this.getAllClassroom();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   *
   * API RESPONSES
   */

  successGetAllClassroomHandler(res: iResultHttp) {
    const { data } = res;
    this.classroomList = data;
    this.isClassroomListLoaded = true;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateHandler(res: iResultHttp) {
    const { data } = res;
    this.getAllClassroom();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */

  openClassroomPopup(classroom) {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(AddClassroomPopupComponent, {
      data: classroom,
      edit: true,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        const returnValue = res.returnValue;
        const objectToPass = {
          id: classroom.id,
          ...returnValue,
        };
        this.update(objectToPass);
        subject.next('');
      });
  }

  createClassroom() {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(AddClassroomPopupComponent);
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        if (res.returnValue === false) {
          subject.next('');
          return;
        }
        const returnValue = res.returnValue;

        this.createClassroomCall(returnValue);
        subject.next('');
      });
  }

  pageChanged(event) {
    this.classroomFilters.page = event;
    this.getAllClassroom();
  }

  toggleBlock(classroom) {
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      accion: classroom.active ? 'Bloquear' : 'Desbloquear',
      elemento: `el aula ${classroom.name}`,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd8))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        const objectToPass = {
          id: classroom.id,
          active: !classroom.active,
        };
        this.update(objectToPass);
        this._unsubInd8.next('');
      });
  }

  /**
   * API CALLS
   */

  getAllClassroom() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.classroomSE.getAll(behaviorSubject, this.classroomFilters);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetAllClassroomHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  update(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.classroomSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  createClassroomCall(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.classroomSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateClassroomHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
