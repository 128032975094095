import { environment } from 'src/environments/environment';
import { iAcademicRegisterFilters } from '../Interfaces/Utils/iAcademicRegisterFilters';
import {
  IAcademicRegister,
  iAcademicRegister,
} from '../Interfaces/Utils/iAcademicRegister';
import { headerData } from '../Components/main-table/main-table.component';
import { iOptionsCustomInput, iOptionsSelector } from '@quasar-dynamics/basic-designsystem';

export class GestionUsuariosProfesoresDetallesRegistroAcademicoClass {
  // Array
  academicRegister: iAcademicRegister[] = [
    IAcademicRegister.createEmptyObject(),
  ];

  //   Total
  totalAcademicRegister: number = 0;

  // Filters
  academicRegisterFilters: iAcademicRegisterFilters = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  };

  //   dataTeacher
  _dataTeacher: any = null;

  // filterTypeSubmenuSelected
  filterTypeSubmenuSelected: number = 0;

  /**+
   * TABLE
   */

  // headerData
  headerData: headerData = [
    {
      variant: 'bold',
      display: 'Tipo',
      key: 'type',
      width: '100px',
    },
    {
      variant: 'bold',
      display: 'Formación',
      key: 'formationName',
      width: '250px',
      className: 'truncateText maxWidth-250',
    },
    {
      variant: 'bold',
      display: 'Concepto',
      key: 'concept',
      width: '250px',
      className: 'truncateText maxWidth-250',
    },
    {
      variant: 'bold',
      display: 'Fecha',
      key: 'date',
      date: {
        date: true,
        pipeTemplate: 'dd/MM/yyyy',
      },
      width: '100px',
      className: 'truncateText maxWidth-100',
      alignment: 'center',
    },
    {
      variant: 'bold',
      display: 'Hora inicio',
      key: 'startHour',
      width: '100px',
      className: 'truncateText maxWidth-100',
      alignment: 'center',
    },
    {
      variant: 'bold',
      display: 'Hora fin',
      key: 'endHour',
      width: '100px',
      className: 'truncateText maxWidth-100',
      alignment: 'center',
    },
    {
      variant: 'bold',
      display: 'Horas totales',
      key: 'totalHours',
      width: '100px',
      className: 'truncateText maxWidth-100',
      alignment: 'center',
    },
    {
      variant: 'chip',
      display: 'Estado formación',
      key: 'formationState',
      alignment: 'center',
      classNameGenerator: (any, index) =>
        this.academicRegister[index].formationStateColor,
      size: 'small',
      width: '100px',
    },
    {
      variant: 'chip',
      display: 'Estado fecha',
      key: 'dateState',
      alignment: 'center',
      classNameGenerator: (any, index) =>
        this.academicRegister[index].dateStateColor,
      size: 'small',
      width: '100px',
    },
  ];

  // customInputOptions
  startHourCustomInputOptions:iOptionsCustomInput = {
    placeholder: 'Hora inicio',
    type: 'time',
    label: 'Hora inicio',
  }
  endHourCustomInputOptions:iOptionsCustomInput = {
    placeholder: 'Hora fin',
    type: 'time',
    label: 'Hora fin',
  }
  dateCustomInputOptions:iOptionsCustomInput = {
    placeholder: 'Fecha',
    type: 'date',
    label: 'Fecha',
  }

  // selectorOptions
  dateStateSelectorOptions:iOptionsSelector = {
    items:[],
    label: 'Estado fecha',
    placeholder: 'Estado fecha',
    append: true,
    search: true,

  }

}
