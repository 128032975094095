import { Subject } from 'rxjs';
import { headerData } from '../Components/main-table/main-table.component';
import {
  ITutoringSession,
  iTutoringSession,
} from '../Interfaces/Utils/iTutoringSession';

export class TutoriaByTeacherClass {
  tutorings: iTutoringSession[] = [ITutoringSession.getEmptyObject()];

  isDataLoaded: boolean = false;

  generalDataLoaderSubject: Subject<boolean> = new Subject<boolean>();

  params: any = null;


}
