import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';

@Injectable({
  providedIn: 'root'
})
export class ProposalService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();
  
  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: any){
    this.http.get(this.urlApi + '/api/proposal' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedProposal =  iStaticUtilities.normalizeNamesCollection(data.data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:normalizedProposal,total:data.total}});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAll, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  winFormalizedProposal(formalizedProposal:any){
    this.http.put(this.urlApi+"/api/won/"+formalizedProposal.id, formalizedProposal, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.winFormalizedProposal, args: [formalizedProposal] });
        this.checkStatusError(error);
      },
    });
  }

}
