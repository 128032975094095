<div class="tableContainer">

  <table class="mainTable" *ngIf="isDataLoaded">
    <thead class="thead">
      <tr class="tr">
        <th class="th"> <mat-checkbox [checked]="allSelected" (change)="selecteAll($event)"></mat-checkbox></th>
        <th class="th" *ngFor="let item of displayedColumns"
          [ngClass]="{justifyContentFlexStart:item?.displayName === 'Nombre'}" (click)="changeOrder(item.displayName)">
          {{item.displayName}} </th>
      </tr>
    </thead>
    <tbody class="tbody">
      <tr class="tr" (click)="onRowClick(matriculacion)" *ngFor="let matriculacion of matriculaciones | paginate:{
      itemsPerPage: filters.num_devoluciones,
      currentPage: filters.num_pagina,
      totalItems: total
    }">
        <td class="td"> <mat-checkbox (click)="$event.stopPropagation()" (change)="addSelected(matriculacion)"
            [checked]="matriculacionesSelected.includes(matriculacion)"></mat-checkbox></td>
        <td class="td" *ngFor="let column of displayedColumns; let index = index;"
          [ngClass]="{fwBold: column?.property === 'name',justifyContentFlexStart:column?.property === 'name' ,estadoTable:column?.property === 'state' }">
          <p
            [ngClass]="{crm:column?.property === 'phase' && matriculacion[column?.property] === 'CRM', prematricula:column?.property === 'phase' && matriculacion[column?.property] === 'Pre Matrícula', revision:column?.property === 'phase' && matriculacion[column?.property] === 'Pendiente de revisión', verificacion:column?.property === 'phase' && matriculacion[column?.property] === 'Pendiente de verificación', matriculado:column?.property === 'phase' && matriculacion[column?.property] === 'Matriculado oficial', perdida:column?.property === 'phase' && matriculacion[column?.property] === 'Perdida', timeBox: column?.property === 'phase' || column?.property === 'endDate', estadoTabla: column?.property === 'state', pendienteVerificar:matriculacion.state === 'pendiente verificar' && column?.property === 'state', restoState:matriculacion.state !== 'pendiente verificar' && column?.property === 'state' }">
            {{matriculacion[column?.property] ?? '-'}} </p>
        </td>
      </tr>
    </tbody>
  </table>


</div>