<ContainerAutomatricula [displayButton]="true">
  <ng-container>

    <div class="fullSite">
      <!-- <i class="country_flags" country='ES'></i> -->

      <div class="centerContainer">
        <div class="imagenes">
          <img class="imagen" src="/assets/Images/Automatricula/FormaAutomatricula.png" alt="Forma">
        </div>
        <div class="texto">
          <p class="boldSubtitle">¡Bienvenido a IEM Digital Business School!</p>
          <p>Ya estás más cerca de vivir la #ExperienciaIEM y convertirte en alumno. El primer paso es cumplimentar la
            matrícula.</p>
          <p>Haz clic en "Empezar" para iniciar el proceso.</p>
          <p>Este formulario se guarda automáticamente, así que no te preocupes porque los datos se conservan aunque
            salgas de la página.
          </p>
        </div>
        <div class="titleAndButtons">
          <p class="fwBold">Recuerda que tan sólo cuando hagas clic en "Finalizar" podremos validar
          </p>
          <p class="fwBold">la matrícula y
            asegurar tu plaza en la formación.</p>
        </div>
        <btn classList="generalbutton principalButton" (click)="goToFormulario()">Empezar</btn>

      </div>
    </div>

  </ng-container>
</ContainerAutomatricula>