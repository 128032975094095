<div class="fullDetailView-Dates">
  <!-- Programacion table -->
  <Editablebox
    [componentOptions]="programacionButtonEnviar"
    [title]="'Programación'"
    [bgColor]="'gray'"
    [fullHeight]="true"
    (sendIsEditing)="handelChangeEdit($event)"
  >
    <ng-container *ngIf="extraClass.displayTable">
      <main-table
        [selectMultiple]="true"
        [dataSource]="extraClass.promotionDatesProgram"
        [headerData]="extraClass.headerData"
        (changeSelectedRows)="handlerSelectedRows($event)"
      ></main-table>
    </ng-container>
  </Editablebox>

  <!-- Tutorias table -->
  <Editablebox
    [title]="'Tutorías'"
    [canEdit]="false"
    [bgColor]="'gray'"
    [fullHeight]="true"
    *ngIf="isMaster"
  >
    <main-table
      [headerData]="extraClass.headerDataTutorias"
      [dataSource]="extraClass.promotionDatesTutorings"
    ></main-table>
  </Editablebox>

  <!-- Tribunal table -->
  <Editablebox
    [title]="'Tribunal'"
    [canEdit]="false"
    [bgColor]="'gray'"
    [fullHeight]="true"
    *ngIf="isMaster"
  >
    <main-table
      [headerData]="extraClass.headerDataTribunal"
      [dataSource]="extraClass.promotionDatesTribunals"
    ></main-table>
  </Editablebox>
</div>
