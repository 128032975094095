import { Component, OnInit } from '@angular/core';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { BookTutoringService } from 'src/app/Services/Api/BookTutoring.service';
import { LocalStorageHandlerService } from 'src/app/Services/Utils/LocalStorageHandler.service';

@Component({
  selector: 'Tutoria-Alumno-Success',
  templateUrl: './Tutoria-Alumno-Success.component.html',
  styleUrls: ['./Tutoria-Alumno-Success.component.scss'],
})
export class TutoriaAlumnoSuccessComponent implements OnInit {
  token: string = '';

  constructor(
    private staticUtilitiesSE: StaticUtilitiesService,
    private bookTutoringSE: BookTutoringService,
    private generalLoaderSE: GeneralLoaderService
  ) {}

  ngOnInit() {
    this.getTokenFromLocalStorage();
  }

  /**
   * HANDLERS
   */

  successBookAnotherTutoringHandler(res: iResultHttp) {
    const { data } = res;
    this.setTokenOnLocalStorageAndMoveForward(data);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */

  getTokenFromLocalStorage() {
    const token = LocalStorageHandlerService.getItem('unt');
    if (token) {
      this.token = token;
    } else {
      this.staticUtilitiesSE.goTo(
        '/tutoria-alumno',
        slideNavigationTypes.slideToRight
      );
      StaticUtilitiesService.showError(
        'No tienes permisos para acceder a esta página'
      );
    }
  }

  setTokenOnLocalStorageAndMoveForward(token){
    LocalStorageHandlerService.setItem('unt', token);
    this.staticUtilitiesSE.goTo('/tutoria-alumno/reserva', slideNavigationTypes.slideToLeft);
   }


  /**
   * API CALLS
   */

  bookAnotherTutoring() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.bookTutoringSE.bookOtherTutoring(behaviorSubject, { tk: this.token });
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successBookAnotherTutoringHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
