import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iResultHttp } from '@quasar-dynamics/basic-designsystem';
import { DefaultBaseService } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TutoringService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getTutoringByPromotion(
    behaviorSubject: BehaviorSubject<any>,
    promotionId: number
  ) {
    this.http
      .get(
        this.urlApi + '/api/tutoring/promotion/' + promotionId,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getTutoringByPromotion,
            args: [behaviorSubject],
          });
        },
      });
  }

  getAll(behaviorSubject: BehaviorSubject<any>, filterTutorings: any) {
    this.http
      .get(
        this.urlApi + '/api/tutoring' + this.optionsGet(filterTutorings),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAll,
            args: [behaviorSubject],
          });
        },
      });
  }
  getSingle(behaviorSubject: BehaviorSubject<any>, tutoringId: number) {
    this.http
      .get(`${this.urlApi}/api/tutoring/${tutoringId}`, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingle,
            args: [behaviorSubject, tutoringId],
          });
        },
      });
  }

  getTutoringInfoForDuplicate(
    behaviorSubject: BehaviorSubject<any>,
    tutoringId: number
  ) {
    this.http
      .get(`${this.urlApi}/api/tutoring/info/${tutoringId}`, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getTutoringInfoForDuplicate,
            args: [behaviorSubject, tutoringId],
          });
        },
      });
  }

  getTutoringAttendanceSelector(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/tutoring/attendance/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getTutoringAttendanceSelector,
            args: [behaviorSubject],
          });
        },
      });
  }
  getTutoringStateSelector(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/tutoring/state/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getTutoringStateSelector,
            args: [behaviorSubject],
          });
        },
      });
  }

  getTutoringStudentStateSelector(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(
        this.urlApi + '/api/tutoring/student/state/selector',
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getTutoringStudentStateSelector,
            args: [behaviorSubject],
          });
        },
      });
  }

  getTutoringStudentById(
    behaviorSubject: BehaviorSubject<any>,
    tutoringStudentId: number
  ) {
    this.http
      .get(
        this.urlApi + '/api/tutoring/student/' + tutoringStudentId,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getTutoringStudentById,
            args: [behaviorSubject, tutoringStudentId],
          });
        },
      });
  }

  getStudentTutoringAttendanceSelector(
    behaviorSubject: BehaviorSubject<any>,
    studentTutoringId: number
  ) {
    this.http
      .get(
        `${this.urlApi}/api/tutoring/student/att/${studentTutoringId}/selector`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getStudentTutoringAttendanceSelector,
            args: [behaviorSubject, studentTutoringId],
          });
        },
      });
  }

  create(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(this.urlApi + '/api/tutoring', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  update(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(this.urlApi + '/api/tutoring/' + data.id, data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.update,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  resetTutoringStudent(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .put(
        this.urlApi + '/api/tutoring/student/reset/' + id,
        {},
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.resetTutoringStudent,
            args: [behaviorSubject, id],
          });
        },
      });
  }

  updateTutoringStudent(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(
        this.urlApi + '/api/tutoring/student/' + data.id,
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.updateTutoringStudent,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  delete(
    behaviorSubject: BehaviorSubject<any>,
    id: number,
    forceDelete: boolean = false
  ) {
    this.http
      .delete(
        this.urlApi +
          '/api/tutoring/' +
          id +
          this.optionsGet({ force: forceDelete }),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.delete,
            args: [behaviorSubject, id, forceDelete],
          });
        },
      });
  }
  deleteTutoringStudent(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .delete(this.urlApi + '/api/tutoring/student/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.deleteTutoringStudent,
            args: [behaviorSubject, id],
          });
        },
      });
  }
}
