import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getCountries(){
    this.http.get(this.urlApi + '/api/country/selector' , this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedCourse = iStaticUtilities.normalizeNamesCollection(data);
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedCourse});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getCountries, args: [] });
        this.checkStatusError(error);
      },
    });
  }

}
