import { Component, OnInit } from '@angular/core';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import {
  iDate,
  iOptionsSelector,
  iResultHttp,
  iUnsubscribeDestroy,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { FormationService } from 'src/app/Services/Api/Formation.service';
import { MasterService } from 'src/app/Services/Api/Master.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { iPromotionFormationFilter } from 'src/app/Shared/Interfaces/iPromotionFormation';
import { environment } from 'src/environments/environment';
import { StaticUtilitiesService as StaticUtilitiesServiceProject } from 'src/app/Services/Utils/StaticUtilities.service';
import { CourseService } from 'src/app/Services/Api/Course.service';
import { RoleGlobalStateService } from 'src/app/Services/Utils/RoleGlobalState.service';

@Component({
  selector: 'Formaciones-DetailView',
  templateUrl: './Formaciones-DetailView.component.html',
  styleUrls: ['./Formaciones-DetailView.component.scss'],
})
export class FormacionesDetailViewComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  toggleElements: string[] = ['Información', 'Ediciones'];
  isAddPromotionVisible: boolean = true;

  formationData: any = {};
  modulesData: any[] = [];
  directorsData: any = [];
  modalitiesData: any = [];
  pricesData: any = [];
  promotionTemplateId: number = 0;

  promotionsTotal: number = 0;

  toggleModel: number = 0;

  formationId: number = 0;
  formationTypeId: number = 0;
  formationType: string = '';
  formationActive: boolean = false;
  formationModality: any[] = [];
  isDataLoaded: boolean = false;
  buttonClassList: string = 'generalButton';
  isOpenSelector: boolean = false;
  searcher: string = '';
  filters: iPromotionFormationFilter = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  };

  // Promociones
  promotions: any[] = [
    {
      edition: 'Edición 1',
      schedule: 'Lunes y miércoles de 10:00 a 12:00',
      students: 15,
      classroom: 'Aula 1',
      startDate: '2021-07-01',
      endDate: '2021-08-01',
      state: 'Nueva',
    },
    {
      edition: 'Edición 2',
      schedule: 'Martes y jueves de 10:00 a 12:00',
      students: 15,
      classroom: 'Aula 2',
      startDate: '2021-07-01',
      endDate: '2021-08-01',
      state: 'Matrícula abierta',
    },
  ];
  isDataPromotionLoaded: boolean = false;

  // Selector

  ActiveOptions: iOptionsSelector = {
    items: [
      { id: true, label: 'Activa' },
      { id: false, label: 'Inactiva' },
    ],
    placeholder: 'Estado',
    bindValue: 'id',
    clearable: false,
    classList: ['rounded'],
  };

  dataToPass: any = {};

  constructor(
    private promotionSE: PromotionFormationService,
    private formationSE: FormationService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private generalLoaderSE: GeneralLoaderService,
    private masterSE: MasterService,
    private courseSE: CourseService,
    private roleGlobalStateSE: RoleGlobalStateService
  ) {
    super();
  }

  async ngOnInit() {
    this.rolesAndPermissions =
      StaticUtilitiesServiceProject.getUserPermissionsAndRoles();
    this.setToggleElementsDependingOnRoles();
    this.formationTypeId = await this.getFormationIdFromParams();
    this.getInfoByFormationType();
  }

  /**
   * HANDLERS
   */

  successGetMasterByIdHandler(res: iResultHttp) {
    let { data } = res;
    let {
      modules,
      directors,
      master,
      modalities,
      prices,
      promotionTemplateId,
    } = data;
    this.formationId = master.formationId;
    this.formationTypeId = master.id;
    this.modulesData = modules;
    this.directorsData = directors;
    this.formationData = master;
    this.modalitiesData = modalities;
    this.pricesData = prices;
    this.promotionTemplateId = promotionTemplateId;
    this.isDataLoaded = true;
    this.generalLoaderSE.removeFromLoaderAmount();
    this.getPromotionsByFormationId();
  }

  successGetCourseByIdHandler(res: iResultHttp) {
    let { data } = res;
    let {
      course,
      directors,
      modalities,
      modules,
      prices,
      promotionTemplateId,
    } = data;
    this.formationId = course.formationId;
    this.modulesData = modules;
    this.formationTypeId = course.id;
    this.directorsData = directors;
    this.formationData = course;
    this.modalitiesData = modalities;
    this.pricesData = prices;
    this.promotionTemplateId = promotionTemplateId;
    this.isDataLoaded = true;
    this.generalLoaderSE.removeFromLoaderAmount();
    this.getPromotionsByFormationId();
  }

  successCreatePromotionHandler(res: iResultHttp) {
    const { data } = res;
    this.staticUtilitiesSE.goTo(
      'formaciones/promocion-detalle',
      slideNavigationTypes.slideToLeft,
      { promotion: data.promotionId, typeOf: this.formationType }
    );
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */

  getFormationIdFromParams(): Promise<number> {
    return new Promise((resolve, reject) => {
      let params = this.staticUtilitiesSE.getParams();
      const formationType = Object.keys(params)[0];
      this.formationType = formationType;
      resolve(params[formationType]);
      reject(null);
    });
  }

  getInfoByFormationType() {
    switch (this.formationType) {
      case 'master':
        this.getMasterById();
        break;

      case 'curso':
        this.getCourseById();
        break;
    }
  }

  return() {
    this.staticUtilitiesSE.goTo(
      '/formaciones/listado-formaciones',
      slideNavigationTypes.slideToBottom
    );
  }
  nuevaPromocion() {
    const objectToPass = {
      id: this.formationId,
    };

    this.createPromotion(objectToPass);
  }
  setButtonClassList() {
    this.formationActive
      ? (this.buttonClassList = 'generalButton onlyIconRed')
      : (this.buttonClassList = 'generalButton onlyIconGreen');
  }
  changeIsOpenSelector() {
    this.isOpenSelector = !this.isOpenSelector;
  }
  editIsActive() {
    this.formationActive = !this.formationActive;
    this.isOpenSelector = false;
    this.dataToPass = {
      id: this.formationTypeId,
      active: this.formationActive,
    };
    this.turnFormationOnOff();
  }
  getFilter(event, key) {
    if (!event) {
      delete this.filters[key];
      this.getPromotionsByFormationId();
      return;
    }
    this.filters[key] = event;
    this.getPromotionsByFormationId();
  }
  checkRolesAndPermissions() {
    return (
      this.rolesAndPermissions.roles.includes('ROLE_ADMIN') ||
      this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') ||
      this.rolesAndPermissions.permissions.includes(4)
    );
  }

  onPagePromotionChange(event) {
    this.filters.num_pagina = event;
    this.getPromotionsByFormationId();
  }

  onRefreshPromotions() {
    this.getPromotionsByFormationId();
  }

  onToggleChange($event) {
    setTimeout(() => {
      this.toggleModel = Number($event);
    }, 1);
  }

  setToggleElementsDependingOnRoles() {
    if (
      !this.rolesAndPermissions.roles.includes('ROLE_ADMIN') &&
      !this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') &&
      !this.rolesAndPermissions.permissions.includes(5) &&
      !this.rolesAndPermissions.permissions.includes(6)
    ) {
      this.toggleElements = ['Información'];
    }
    if (
      !this.rolesAndPermissions.roles.includes('ROLE_ADMIN') &&
      !this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') &&
      !this.rolesAndPermissions.permissions.includes(6)
    ) {
      this.isAddPromotionVisible = false;
    }
  }
  /**
   * API CALLS
   */

  getPromotionsByFormationId() {
    this.generalLoaderSE.addToLoaderAmount();
    this.promotionSE.getPromotionsByFormationIdWithSearch(
      this.formationId,
      this.filters
    );
    this.promotionSE
      .getResultPromotionsByFormationId()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.promotions = data.map((promotion: any) => {
          return {
            ...promotion,
            endDate: iDate
              .javascriptConvert(new Date(promotion.endDate))
              .toStringDate('EU'),
            startDate: iDate
              .javascriptConvert(new Date(promotion.startDate))
              .toStringDate('EU'),
          };
        });
        this.promotionsTotal = total;
        this.isDataPromotionLoaded = true;
        this.generalLoaderSE.removeFromLoaderAmount();
        this._unsubInd2.next('');
      });
    this.promotionSE
      .getResultPromotionsByFormationIdError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
  turnFormationOnOff() {
    this.generalLoaderSE.addToLoaderAmount();
    this.formationSE.update(this.dataToPass);
    this.formationSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'Formación actualizada correctamente'
        );
        this.setButtonClassList();
        this.generalLoaderSE.removeFromLoaderAmount();
        this._unsubInd3.next('');
      });
    this.formationSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getMasterById() {
    this.generalLoaderSE.addToLoaderAmount();
    this.masterSE.getSingle(this.formationTypeId);
    this.masterSE
      .getResultIndividual()
      .pipe(
        takeUntil(this._unsubInd),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.successGetMasterByIdHandler(res);
        this._unsubInd.next('');
      });
    this.masterSE
      .getResultIndividualError()
      .pipe(
        takeUntil(this._unsub),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  createPromotion(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.createPromotionByDuplicateTemplate(
      behaviorSubject,
      objectToPass
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successCreatePromotionHandler(res),
        });
      });
  }

  getCourseById() {
    this.generalLoaderSE.addToLoaderAmount();
    this.courseSE.getSingle(this.formationTypeId);
    this.courseSE
      .getResultIndividual()
      .pipe(
        takeUntil(this._unsubInd),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.successGetCourseByIdHandler(res);
        this._unsubInd.next('');
      });
    this.courseSE
      .getResultIndividualError()
      .pipe(
        takeUntil(this._unsub),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
