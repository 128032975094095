<div class="popupGeneralContainer">
  <div class="header">
    <p>Añadir matriculación</p>
  </div>
  <div class="body">
    <div class="bodyUnAlumno" *ngIf="optionSubmenuSelected == 0">
      <div class="indicadorLinea">
        <div class="indicator" [ngClass]="{active: unAlumnoSelected == 0}">
          <span class="dot" [ngClass]="{active: unAlumnoSelected == 0}"></span>
          <p>Datos de alumno</p>
        </div>
        <div class="indicator" [ngClass]="{active: unAlumnoSelected == 1}">
          <span class="dot" [ngClass]="{active: unAlumnoSelected == 1}"></span>
          <p>Escoger formación</p>
        </div>
        <span class="line"></span>
      </div>
      <div class="unAlumnoFirstSection" *ngIf="unAlumnoSelected == 0">
        <UnAlumnoSelectedFirstStep [dataFromCRM]="dataFromCRM" (dataUnAlumnoSelectedFirstStep)="getDataUnAlumnoSelectedFirstStep($event)" [dataFromParent]="dataUnAlumnoSelectedFirstStep" ></UnAlumnoSelectedFirstStep>
      </div>
      <div class="unAlumnoFirstSection" *ngIf="unAlumnoSelected == 1">
        <AlumnoSelectedSecondStep [dataUnAlumnoSelectedFirstStep]="dataUnAlumnoSelectedFirstStep" (dataUnAlumnoSelectedSecondStep)="getDataUnAlumnoSelectedSecondStep($event)"></AlumnoSelectedSecondStep>
      </div>
    </div>
    <div class="bodyUnAlumno" *ngIf="optionSubmenuSelected == 1">
      <div class="indicadorLinea">
        <div class="indicator" [ngClass]="{active: variosAlumnosSelected == 0}">
          <span class="dot" [ngClass]="{active: variosAlumnosSelected == 0}"></span>
          <p>Datos de empresa</p>
        </div>
        <div class="indicator" [ngClass]="{active: variosAlumnosSelected == 1}">
          <span class="dot" [ngClass]="{active: variosAlumnosSelected == 1}"></span>
          <p>Escoger formación</p>
        </div>
        <span class="line"></span>
      </div>
      <div class="unAlumnoFirstSection" *ngIf="variosAlumnosSelected == 0">
        <VariosAlumnosFirstStep (dataUnVariosAlumnosFirstStep)="getDataUnVariosAlumnosFirstStep($event)" [dataFromParent]="dataUnVariosAlumnosFirstStep"></VariosAlumnosFirstStep>
      </div>
      <div class="unAlumnoFirstSection" *ngIf="variosAlumnosSelected == 1">
        <VariosAlumnosSecondStep (dataVariosAlumnosSecondStep)="getDataVariosAlumnosSecondStep($event)"></VariosAlumnosSecondStep>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn classList="violetButton" (click)="forceClosePopup()" *ngIf="(optionSubmenuSelected == 0 && unAlumnoSelected == 0) || (optionSubmenuSelected == 1 && variosAlumnosSelected == 0)">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="violetButton" (click)="backButton()" *ngIf="(optionSubmenuSelected == 0 && unAlumnoSelected == 1) || (optionSubmenuSelected == 1 && variosAlumnosSelected == 1)">
      <ng-container>
        Volver
      </ng-container>
    </btn>
    <btn classList="principalButton" (click)="openAutomatricula()" *ngIf="optionSubmenuSelected == 0 && unAlumnoSelected == 1 " [ngClass]="{disabled: disabledButton}" (click)="enrollOneUser(false)">
      <ng-container>
        Rellenar ficha
      </ng-container>
    </btn>
    <btn classList="principalButton" (click)="nextButton()" *ngIf="optionSubmenuSelected == 0" [ngClass]="{disabled: disabledButton}">
      <ng-container>
        {{unAlumnoSelected == 0 ? 'Siguiente' : 'Enviar propuesta de matrícula'}}
      </ng-container>
    </btn>
    <btn classList="principalButton" (click)="nextButton()" *ngIf="optionSubmenuSelected == 1" [ngClass]="{disabled: disabledButton}">
      <ng-container>
        {{variosAlumnosSelected == 0 ? 'Siguiente' : 'Enviar Excel'}}
      </ng-container>
    </btn>
  </div>
</div>