import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iIncompany } from "./iIncompany";
import { iMaster } from "./iMaster";
import { iCourse } from "./iCourse";

export interface iFormation {
    id: number,
    name?: string,
    objectives?: string,
    description?: string,
    creationDate?: iDate,
    price?: number,
    active?: boolean,
    duration?: number,
    mode?: string,
    language?: string,
    typeOf?: string,
    incompany?: iIncompany | any,
    master?: iMaster | any,
    course?: iCourse | any,
    activeLabel?: string | null,
}

export class iFormation extends iBaseObject {
    static convertFromCollection(ob: any): Array<iFormation> {
        let iFormationCollection: Array<iFormation> = [];
        ob.forEach((element: any) => {
            iFormationCollection.push(this.convertFromObject(element));
        });
        return iFormationCollection;
    }

    static convertFromObject(ob: any): iFormation {
        let iFormationObj = new iFormation();
        iFormationObj.id = ob.id;
        iFormationObj.name = ob.name;
        iFormationObj.objectives = ob.objectives;
        iFormationObj.description = ob.description;
        iFormationObj.creationDate = iDate.phpConvert(ob.creationDate);
        iFormationObj.price = ob.price;
        iFormationObj.active = ob.active;
        iFormationObj.duration = ob.duration;
        iFormationObj.mode = ob.mode;
        iFormationObj.language = ob.language;
        iFormationObj.typeOf = ob.typeOf;
        iFormationObj.incompany = ob.incompany ? iIncompany.convertFromObject(ob.incompany) : {};
        iFormationObj.master = ob.master ? iMaster.convertFromObject(ob.master) : {};
        iFormationObj.course = ob.course ? iCourse.convertFromObject(ob.course) : {};
        iFormationObj.activeLabel = ob.activeLabel;
        return iFormationObj;
    }

    static createEmptyObject(): iFormation {
        let iFormationObj = new iFormation();
        iFormationObj.name = '';
        iFormationObj.objectives = '';
        iFormationObj.description = '';
        iFormationObj.creationDate = iDate.javascriptConvert(new Date());
        iFormationObj.price = -1;
        iFormationObj.active = false;
        iFormationObj.duration = -1;
        iFormationObj.mode = '';
        iFormationObj.language = '';
        iFormationObj.typeOf = '';
        iFormationObj.incompany = {};
        iFormationObj.master = {};
        iFormationObj.course = {};
        iFormationObj.activeLabel = null;
        return iFormationObj;
    }
}

export interface iFormationFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    name?: string,
    objectives?: string,
    description?: string,
    creationDate?: string,
    price?: number,
    active?: boolean,
    duration?: number,
    mode?: string,
    language?: string,
    typeOf?: string,
    incompany?: number,
    master?: number,
    course?: number,
}