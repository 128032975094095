export interface iFinanceTable {
  id: number;
  formationName: string;
  year: number;
  month: string;
  edition: string;
  price: number;
  paymentModality: string;
  paymentMethod: string;
  totalPaid: number;
  isPaid: boolean;
}

export class IFinanceTable {
  public static getEmptyObject(): iFinanceTable {
    return {
      id: 0,
      formationName: '',
      year: 0,
      month: '',
      edition: '',
      price: 0,
      paymentModality: '',
      paymentMethod: '',
      totalPaid: 0,
      isPaid: false,
    };
  }
}
