import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StaticUtilitiesService } from '@quasar-dynamics/basic-designsystem';
import { interval, takeUntil } from 'rxjs';
import { StaticSelectorHandlersService } from 'src/app/Services/Utils/StaticSelectorHandlers.service';
import { iChipSelectorOptions } from '../../Interfaces/iChipSelectorOptions';

@Component({
  selector: 'chip-selector',
  templateUrl: './chip-selector.component.html',
  styleUrls: ['./chip-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipSelectorComponent), // replace name as appropriate
      multi: true,
    },
  ],
})
export class ChipSelectorComponent implements OnInit, ControlValueAccessor {
  private _chipSelectorOptions: iChipSelectorOptions = {
    bold: false,
    items: [],
    size: 'md',
    readonly: false,
    bindValue: 'name',
  };

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() get chipSelectorOptions() {
    return this._chipSelectorOptions;
  }
  set chipSelectorOptions(value: iChipSelectorOptions) {
    this._chipSelectorOptions = value;
    this.cdr.detectChanges();

    // I need an if statment here to check if the model exists. if not, I need to set a timeout to wait for the model to be set
      const intervalCheck = interval(1);
      const subject = StaticUtilitiesService.createSubject();
      intervalCheck.pipe(takeUntil(subject)).subscribe(() => {
        this.cdr.detectChanges();
        if (
          this.model &&
          typeof (this.model === 'string' || typeof this.model === 'number') &&
          this.chipSelectorOptions.bindValue &&
          this.chipSelectorOptions.items.length > 0
        ) {
          this.mapInput(this.model.toString().toLowerCase());
          subject.next('');
          subject.complete();
        }
      });
      setTimeout(() => {
        subject.next('');
        subject.complete();
      }, 1000);
  }

  private _options;

  private _onChange = (_: any) => {};
  private _onTouched = () => {};
  private _oldData;

  _disabled: boolean = false;
  model;

  writeValue(obj: any): void {
    if (obj !== this.model) {
      this.model = obj;
      this.cdr.detectChanges();
      this._onChange(obj);
      if (
        this.model &&
        typeof (this.model === 'string' || typeof this.model === 'number') &&
        this.chipSelectorOptions.bindValue
      ) {
        this.cdr.detectChanges();
        this.mapInput(this.model.toString().toLowerCase());
      }
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
    this._onChange(this.model);
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onNgModelChange(event) {
    this._oldData = this.model;
    this.model = event;
  }

  onModelChange($event) {
    this.model = $event;
    this._onChange(this.model);
  }

  name: string = new Date().valueOf().toString();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  sendNgModel(event) {
    this.change.emit(event[this._chipSelectorOptions.bindValue ?? '']);
  }

  mapInput(obj: string) {
    let selected: any = null;
    if (this._chipSelectorOptions.bindValue) {
      selected = this.chipSelectorOptions.items.find(
        (option) =>
          option[this._chipSelectorOptions.bindValue ?? '']
            .toString()
            .toLowerCase() == obj
      );
    }
    if (selected) {
      this.model = selected;
      this.cdr.detectChanges();
    }
  }
}
