<div class="fullContainer">
  <div class="filtersLine">
    <toggleSubmenu
      class="secondary"
      [elements]="[
        'Todo',
        'Másters',
        'Cursos',
        'InCompany',
        'Tutorías',
        'Tribunales'
      ]"
      [(ngModel)]="extraClass.filterTypeSubmenuSelected"
      (change)="sendTypeToFilter(extraClass.filterTypeSubmenuSelected)"
    ></toggleSubmenu>
    <div class="searchAndFilterButton">
      <searcher-and-filter
        placeholder="Busca aquí por nombre de la formación o nombre de la promoción"
        style="width: 100%"
        (onSearchChange)="getSearch($event)"
      >
        <div class="filterLine">
          <customInput
            style="width: 100%"
            [options]="extraClass.startHourCustomInputOptions"
            [(ngModel)]="extraClass.academicRegisterFilters.startHour"
            (change)="
              getFilters(
                extraClass.academicRegisterFilters.startHour,
                'startHour'
              )
            "
          ></customInput>
          <customInput
            style="width: 100%"
            [options]="extraClass.endHourCustomInputOptions"
            [(ngModel)]="extraClass.academicRegisterFilters.endHour"
            (change)="
              getFilters(extraClass.academicRegisterFilters.endHour, 'endHour')
            "
          ></customInput>
          {{ extraClass.academicRegisterFilters.date }}
          <clearable-date
            style="width: 100%"
            (OnXClick)="getFilters(null, 'date')"
          >
            <customInput
              style="width: 100%"
              [options]="extraClass.dateCustomInputOptions"
              [selectedDate]="extraClass.academicRegisterFilters.date ?? null"
              (change)="getFilters($event, 'date')"
            ></customInput>
          </clearable-date>
          <selector
            class="popupAddProject"
            style="width: 100%"
            [options]="extraClass.dateStateSelectorOptions"
            [(ngModel)]="extraClass.academicRegisterFilters.dateState"
            (change)="
              getFilters(
                extraClass.academicRegisterFilters.dateState,
                'dateState'
              )
            "
          ></selector>
        </div>
      </searcher-and-filter>
    </div>
  </div>
  <div class="tableContainer">
    <main-table
      [headerData]="extraClass.headerData"
      [dataSource]="extraClass.academicRegister"
    ></main-table>
    <ng-container
      *ngFor="
        let item of extraClass.academicRegister
          | paginate
            : {
                itemsPerPage:
                  extraClass.academicRegisterFilters.num_devoluciones,
                currentPage: extraClass.academicRegisterFilters.num_pagina,
                totalItems: extraClass.totalAcademicRegister
              }
      "
    ></ng-container>

    <pagination-controls
      [autoHide]="true"
      previousLabel="Anterior"
      nextLabel="Siguiente"
      (pageChange)="onPageChange($event)"
    ></pagination-controls>
  </div>
</div>
