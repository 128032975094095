import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//EXTERNAL MODULES
import { NgSelectModule } from '@ng-select/ng-select';
import { MatIconModule } from '@angular/material/icon';
import { QuasarLibraryModule } from '@quasar-dynamics/basic-designsystem';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module
import { MatRadioModule } from '@angular/material/radio';
import { QdChartsModule } from '@quasar_dynamics/qd-charts';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

/**
 * LAYAOUT COMPONENTS
 */
import { FullContainerComponent } from './Shared/Layout/FullContainer/FullContainer.component';
import { SidebarComponent } from './Shared/Layout/Sidebar/Sidebar.component';
import { HeaderComponent } from './Shared/Layout/Header/Header.component';
import { ContainerAutomatriculaComponent } from './Shared/Layout/ContainerAutomatricula/ContainerAutomatricula.component';

/**
 * COMPONENTS
 */
import { CustomInputComponent } from './Shared/Components/CustomInput/CustomInput.component';
import { CountrySelectorComponent } from './Shared/Components/CountrySelector/CountrySelector.component';
import { CustomTextAreaComponent } from './Shared/Components/CustomTextArea/CustomTextArea.component';
import { MainTableComponent } from './Shared/Components/main-table/main-table.component';
import { TableItemBoxComponent } from './Shared/Components/main-table/_holders/table-item-box/table-item-box.component';
import { StatusPillComponent } from './Shared/Components/status-pill/status-pill.component';
import { TableItemButtonComponent } from './Shared/Components/main-table/_holders/table-item-button/table-item-button.component';
import { ChipComponent } from './Shared/Components/chip/chip.component';
import { DetailViewStudentsComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-promotions/Promotions-DetailView/DetailView-Students/DetailView-Students.component';
import { StarsComponent } from './Shared/Components/Stars/Stars.component';
import { BlueSelectorComponent } from './Shared/Components/blue-selector/blue-selector.component';
import { BlueDataPickerComponent } from './Shared/Components/blue-data-picker/blue-data-picker.component';
import { ChipSelectorComponent } from './Shared/Components/chip-selector/chip-selector.component';
import { SmallInputComponent } from './Shared/Components/small-input/small-input.component';
import { MultipleSelectorComponent } from './Shared/Components/multiple-selector/multiple-selector.component';
import { OptionsMenuComponent } from './Shared/Components/options-menu/options-menu.component';
import { DetailViewDirectorComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-details/_holder/DetailView-Director/DetailView-Director.component';
import { DetailViewContentModuleComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-details/_holder/DetailView-Content-Module/DetailView-Content-Module.component';
import { DetailViewContentModulesContainerComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-details/_holder/DetailView-Content-ModulesContainer/DetailView-Content-ModulesContainer.component';

/**
 * ADAPTERS
 */
import { CustomDateAdapter } from './Shared/Adapters/CustomDateAdapter';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
/**
 * DIRECTIVES
 */
import { SkeletonDirective } from './Shared/Directives/Skeleton.directive';
import { TextEditableComponent } from './Shared/Directives/text-editable.directive';

/**
 * PAGES
 */

/**
 * LOGIN
 */
import { LoginComponent } from './Pages/Login/Login/Login.component';

/**
 * AUTOMATRICULA
 */

import { AutomatriculaViewComponent } from './Pages/Automatricula/AutomatriculaView/AutomatriculaView.component';
import { AutomatriculaFormularioComponent } from './Pages/Automatricula/AutomatriculaFormulario/AutomatriculaFormulario.component';
import { FormInformacionPersonalComponent } from './Pages/Automatricula/AutomatriculaFormulario/MatriculaNormal/Validacion de matricula/FormInformacionPersonal.component';
import { InformacionPersonalComponent } from './Pages/Automatricula/AutomatriculaFormulario/MatriculaNormal/InformacionPersonal/InformacionPersonal.component';
import { DatosDeFacturacionComponent } from './Pages/Automatricula/AutomatriculaFormulario/MatriculaNormal/DatosDeFacturacion/DatosDeFacturacion.component';
import { PrecioComponent } from './Pages/Automatricula/AutomatriculaFormulario/MatriculaNormal/Precio/Precio.component';
import { MetodosDePagoComponent } from './Pages/Automatricula/AutomatriculaFormulario/MatriculaNormal/MetodosDePago/MetodosDePago.component';
import { BonificacionComponent } from './Pages/Automatricula/AutomatriculaFormulario/MatriculaNormal/Bonificacion/Bonificacion.component';

/**
 * GESTIÓN USUARIOS
 */

/**
 * PROFESORES
 */
import { GestionUsuariosProfesoresListadoComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Listado/GestionUsuarios-Profesores-Listado.component';
import { GestionUsuariosProfesoresDetallesComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Detalles/GestionUsuarios-Profesores-Detalles.component';
import { GestionUsuariosProfesoresTablaComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Listado/_Holders/GestionUsuarios-Profesores-Tabla/GestionUsuarios-Profesores-Tabla.component';
import { GestionUsuariosProfesoresDetallesInformacionComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Detalles/_Holders/GestionUsuarios-Profesores-Detalles-Informacion/GestionUsuarios-Profesores-Detalles-Informacion.component';
import { GestionUsuariosProfesoresDetallesProductosComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Detalles/_Holders/GestionUsuarios-Profesores-Detalles-Productos/GestionUsuarios-Profesores-Detalles-Productos.component';
import { GestionUsuariosProfesoresDetallesDocumentosComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Detalles/_Holders/GestionUsuarios-Profesores-Detalles-Documentos/GestionUsuarios-Profesores-Detalles-Documentos.component';
import { GestionUsuariosProfesoresDetallesFacturasComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Detalles/_Holders/GestionUsuarios-Profesores-Detalles-Facturas/GestionUsuarios-Profesores-Detalles-Facturas.component';
import { GestionUsuariosProfesoresCrearComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Crear/GestionUsuarios-Profesores-Crear.component';
import { GestionUsuariosProfesoresDetallesHorasComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Detalles/_Holders/GestionUsuarios-Profesores-Detalles-Horas/GestionUsuarios-Profesores-Detalles-Horas.component';

/**
 * CONFIGURACIÓN
 */

import { GestionUsuariosConfiguracionComponent } from './Pages/GestionUsuarios/GestionUsuarios-Configuracion/GestionUsuarios-Configuracion.component';

/**
 * MATRICULACIONES
 */
import { GestionUsuariosMatriculacionesComponent } from './Pages/GestionUsuarios/GestionUsuarios-Matriculaciones/GestionUsuarios-Matriculaciones.component';
import { GestionUsuariosMatriculacionesPipelineComponent } from './Pages/GestionUsuarios/GestionUsuarios-Matriculaciones/_Holders/GestionUsuarios-Matriculaciones-Pipeline/GestionUsuarios-Matriculaciones-Pipeline.component';
import { GestionUsuariosMatriculacionesTablaComponent } from './Pages/GestionUsuarios/GestionUsuarios-Matriculaciones/_Holders/GestionUsuarios-Matriculaciones-Tabla/GestionUsuarios-Matriculaciones-Tabla.component';
import { GestionUsuariosMatriculacionesCursosComponent } from './Pages/GestionUsuarios/GestionUsuarios-Matriculaciones-Cursos/GestionUsuarios-Matriculaciones-Cursos.component';
import { GestionUsuariosMatriculacionesCursosPipelineComponent } from './Pages/GestionUsuarios/GestionUsuarios-Matriculaciones-Cursos/_Holders/GestionUsuarios-Matriculaciones-Cursos-Pipeline/GestionUsuarios-Matriculaciones-Cursos-Pipeline.component';
import { GestionUsuariosMatriculacionesCursosTablaComponent } from './Pages/GestionUsuarios/GestionUsuarios-Matriculaciones-Cursos/_Holders/GestionUsuarios-Matriculaciones-Cursos-Tabla/GestionUsuarios-Matriculaciones-Cursos-Tabla.component';

/**+
 * INCOMPANIES
 */

import { GestionUsuariosIncompaniesComponent } from './Pages/GestionUsuarios/GestionUsuarios-Incompanies/GestionUsuarios-Incompanies.component';
import { IncompaniesListComponent } from './Pages/GestionUsuarios/GestionUsuarios-Incompanies/_holder/Incompanies-List/Incompanies-List.component';

/**
 * ALUMNOS
 */
import { GestionUsuariosAlumnosListadoComponent } from './Pages/GestionUsuarios/GestionUsuarios-Alumnos/GestionUsuarios-Alumnos-Listado/GestionUsuarios-Alumnos-Listado.component';
import { GestionUsuariosAlumnosListadoTablaComponent } from './Pages/GestionUsuarios/GestionUsuarios-Alumnos/GestionUsuarios-Alumnos-Listado/_Holders/GestionUsuarios-Alumnos-Listado-Tabla/GestionUsuarios-Alumnos-Listado-Tabla.component';
import { GestionUsuariosAlumnosListadoTablaAlumniComponent } from './Pages/GestionUsuarios/GestionUsuarios-Alumnos/GestionUsuarios-Alumnos-Listado/_Holders/GestionUsuarios-Alumnos-Listado-Tabla-Alumni/GestionUsuarios-Alumnos-Listado-Tabla-Alumni.component';
import { GestionUsuariosAlumnosDetallesComponent } from './Pages/GestionUsuarios/GestionUsuarios-Alumnos/GestionUsuarios-Alumnos-Detalles/GestionUsuarios-Alumnos-Detalles.component';
import { GestionUsuariosAlumnosDetallesInformacionComponent } from './Pages/GestionUsuarios/GestionUsuarios-Alumnos/GestionUsuarios-Alumnos-Detalles/_Holders/GestionUsuarios-Alumnos-Detalles-Informacion/GestionUsuarios-Alumnos-Detalles-Informacion.component';
import { GestionUsuariosAlumnosDetallesFormacionesComponent } from './Pages/GestionUsuarios/GestionUsuarios-Alumnos/GestionUsuarios-Alumnos-Detalles/_Holders/GestionUsuarios-Alumnos-Detalles-Formaciones/GestionUsuarios-Alumnos-Detalles-Formaciones.component';
import { GestionUsuariosAlumnosDetallesFacturasComponent } from './Pages/GestionUsuarios/GestionUsuarios-Alumnos/GestionUsuarios-Alumnos-Detalles/_Holders/GestionUsuarios-Alumnos-Detalles-Facturas/GestionUsuarios-Alumnos-Detalles-Facturas.component';
import { GestionUsuariosAlumnosDetallesDocumentacionComponent } from './Pages/GestionUsuarios/GestionUsuarios-Alumnos/GestionUsuarios-Alumnos-Detalles/_Holders/GestionUsuarios-Alumnos-Detalles-Documentacion/GestionUsuarios-Alumnos-Detalles-Documentacion.component';
import { GestionUsuariosAlumnosCrearComponent } from './Pages/GestionUsuarios/GestionUsuarios-Alumnos/GestionUsuarios-Alumnos-Crear/GestionUsuarios-Alumnos-Crear.component';

/**
 * FORMACIONES
 */
import { FormacionesNewVersionTodosComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/FormacionesNewVersion-Todos/FormacionesNewVersion-Todos.component';
import { FormacionesNewVersionMasterComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/FormacionesNewVersion-Master/FormacionesNewVersion-Master.component';
import { FormacionesNewVersionIncompanyComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/FormacionesNewVersion-Incompany/FormacionesNewVersion-Incompany.component';
import { GestionUsuariosFormacionesComponentNewVersion } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/GestionUsuarios-Formaciones.component';
import { FormacionesNewVersionDobleTitulacionComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/FormacionesNewVersion-DobleTitulacion/FormacionesNewVersion-DobleTitulacion.component';
import { FormacionesNewVersionCursoComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/FormacionesNewVersion-Curso/FormacionesNewVersion-Curso.component';
import { FormacionesCrearComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-Crear/Formaciones-Crear.component';
import { FormacionesDetailViewComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/Formaciones-DetailView.component';
import { DetailViewPromotionsComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-promotions/DetailView-promotions.component';
import { PromotionsCrearComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-promotions/Promotions-Crear/Promotions-Crear.component';
import { DetailViewBaseContainerComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-baseContainer/DetailView-baseContainer.component';
import { DetailViewInformationComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-promotions/Promotions-DetailView/DetailView-Information/DetailView-Information.component';
import { DetailViewTeacherCardComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-promotions/Promotions-DetailView/DetailView-Facturacion/_holder/DetailView-TeacherCard/DetailView-TeacherCard.component';
import { DetailViewProjectsComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-promotions/Promotions-DetailView/detailView-Projects/detailView-Projects.component';
import { DetailViewDetailsComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-details/DetailView-details.component';
import { DetailViewContentComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-details/_holder/DetailView-Content/DetailView-Content.component';

/**
 * POPUPS
 */
import { PersonalizarColumnasPopupComponent } from './Popups/PersonalizarColumnas-Popup/PersonalizarColumnas-Popup.component';
import { SubirArchivoPopupComponent } from './Popups/SubirArchivo-Popup/SubirArchivo-Popup.component';
import { ConfirmarAccionPopupComponent } from './Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { EnrollmentDetailPopupComponent } from './Popups/EnrollmentDetail-Popup/EnrollmentDetail-Popup.component';
import { AddStudentEnrollmentPopupComponent } from './Popups/AddPopup/AddStudentEnrollment-Popup/AddStudentEnrollment-Popup.component';
import { UnAlumnoSelectedFirstStepComponent } from './Popups/AddPopup/AddStudentEnrollment-Popup/_holder/UnAlumnoSelected/UnAlumnoSelectedFirstStep/UnAlumnoSelectedFirstStep.component';
import { AlumnoSelectedSecondStepComponent } from './Popups/AddPopup/AddStudentEnrollment-Popup/_holder/UnAlumnoSelected/UnAlumnoSelectedSecondStep/AlumnoSelectedSecondStep.component';
import { VariosAlumnosSubirExcelComponent } from './Popups/AddPopup/AddExcel-Popup/_holder/VariosAlumnosSubirExcel/VariosAlumnosSubirExcel.component';
import { VariosAlumnosFirstStepComponent } from './Popups/AddPopup/AddStudentEnrollment-Popup/_holder/VariosAlumnos/VariosAlumnosFirstStep/VariosAlumnosFirstStep.component';
import { VariosAlumnosSecondStepComponent } from './Popups/AddPopup/AddStudentEnrollment-Popup/_holder/VariosAlumnos/VariosAlumnosSecondStep/VariosAlumnosSecondStep.component';
import { AddExcelPopupComponent } from './Popups/AddPopup/AddExcel-Popup/AddExcel-Popup.component';
import { ListaDeStudentsDeExcelComponent } from './Popups/AddPopup/AddExcel-Popup/_holder/ListaDeStudentsDeExcel/ListaDeStudentsDeExcel.component';
import { SubirFacturaPopupComponent } from './Popups/SubirFactura-Popup/SubirFactura-Popup.component';
import { AddDobleTitulacionPopupComponent } from './Popups/AddPopup/AddDobleTitulacion-Popup/AddDobleTitulacion-Popup.component';
import { ConfirmarAccionPerderPopupComponent } from './Popups/ConfirmarAccionPerder-Popup/ConfirmarAccionPerder-Popup.component';
import { CursoMasterMatriculaConfirmaPopupComponent } from './Popups/CursoMasterMatriculaConfirma-Popup/CursoMasterMatriculaConfirma-Popup.component';
import { CursoMasterMatriculaRevisaPopupComponent } from './Popups/CursoMasterMatriculaRevisa-Popup/CursoMasterMatriculaRevisa-Popup.component';
import { CursoMasterPreMatriculaPopupComponent } from './Popups/CursoMasterPreMatricula-Popup/CursoMasterPreMatricula-Popup.component';
import { AsociarProductoProfesorPopupComponent } from './Popups/AsociarProductoProfesor-Popup/AsociarProductoProfesor-Popup.component';
import { CambiarFormacionPromocionMatriculaPopupComponent } from './Popups/CambiarFormacionPromocionMatricula-Popup/CambiarFormacionPromocionMatricula-Popup.component';

/**
 * LOCALIZATION
 */
import localEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { PromotionsDetailViewComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-promotions/Promotions-DetailView/Promotions-DetailView.component';
import { PromotionsStudentsComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-promotions/Promotions-DetailView/_holder/Promotions-Students/Promotions-Students.component';
import { IncompaniesPipelineComponent } from './Pages/GestionUsuarios/GestionUsuarios-Incompanies/_holder/Incompanies-Pipeline/Incompanies-Pipeline.component';
import { IncompaniesCreateComponent } from './Pages/GestionUsuarios/GestionUsuarios-Incompanies/_holder/Incompanies-Create/Incompanies-Create.component';
import { IncompaniesCreatePromotionsComponent } from './Pages/GestionUsuarios/GestionUsuarios-Incompanies/_holder/Incompanies-Create-Promotions/Incompanies-Create-Promotions.component';
import { IncompaniesCheckThirdColumnComponent } from './Pages/GestionUsuarios/GestionUsuarios-Incompanies/_holder/Incompanies-CheckThirdColumn/Incompanies-CheckThirdColumn.component';
import { AddStudentToGroupPopupComponent } from './Popups/AddPopup/AddStudentToGroup-Popup/AddStudentToGroup-Popup.component';
import { ConfiguracionPermisosComponent } from './Pages/GestionUsuarios/GestionUsuarios-Configuracion/_Holders/Configuracion-Permisos/Configuracion-Permisos.component';
import { PermisosUserListComponent } from './Pages/GestionUsuarios/GestionUsuarios-Configuracion/_Holders/Configuracion-Permisos/_holder/Permisos-User-list/Permisos-User-list.component';
import { ConfiguracionLogOutComponent } from './Pages/GestionUsuarios/GestionUsuarios-Configuracion/_Holders/Configuracion-LogOut/Configuracion-LogOut.component';
import { PermisosPermisosComponent } from './Pages/GestionUsuarios/GestionUsuarios-Configuracion/_Holders/Configuracion-Permisos/_holder/Permisos-Permisos/Permisos-Permisos.component';
import { AddIEMUserComponent } from './Popups/AddPopup/AddIEMUser/AddIEMUser.component';
import { GestionUsuariosMainViewComponent } from './Pages/GestionUsuarios/GestionUsuarios-MainView/GestionUsuarios-MainView.component';
import { PropuestaFormalAccionPopupComponent } from './Popups/PropuestaFormalAccion-Popup/PropuestaFormalAccion-Popup.component';
import { HeOlvidadoMiContrasenaComponent } from './Pages/Login/HeOlvidadoMiContrasena/HeOlvidadoMiContrasena.component';
import { RecoverPasswordComponent } from './Pages/Login/RecoverPassword/RecoverPassword.component';
import { TableIconComponent } from './Shared/Components/table-icon/table-icon.component';
import { TestComponent } from './Pages/Test/Test.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarComponent } from './Shared/Components/Calendar/Calendar.component';
import { IEMLoaderComponent } from './Shared/Components/IEMLoader/IEMLoader.component';
import { EventCalendarContainerComponent } from './Shared/Components/EventCalendarContainer/EventCalendarContainer.component';
import { FormattedSelectorComponent } from './Shared/Components/formatted-selector/formatted-selector.component';
import { InputTestComponent } from './Shared/Components/inputTest/inputTest.component';
import { JoinSessionButtonDirective } from './Shared/Directives/joinSessionButton.directive';
import { TableItemChipComponent } from './Shared/Components/main-table/_holders/table-item-chip/table-item-chip.component';
import { FormacionesNewVersionTodosTableComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/formacionesNewVersion-Todos-Table/formacionesNewVersion-Todos-Table.component';
import { FormacionesNewVersionMasterTableComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/formacionesNewVersion-Master-Table/formacionesNewVersion-Master-Table.component';
import { FormacionesNewVersionCursoTableComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/FormacionesNewVersion-Curso-Table/FormacionesNewVersion-Curso-Table.component';
import { FormacionesNewVersionIncompanyTableComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/FormacionesNewVersion-Incompany-Table/FormacionesNewVersion-Incompany-Table.component';
import { FormacionesNewVersionDobleTitulacionTableComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/FormacionesNewVersion-DobleTitulacion-Table/FormacionesNewVersion-DobleTitulacion-Table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CustomDatepickerComponent } from './Shared/Components/CustomDatepicker/CustomDatepicker.component';
import { EditableboxComponent } from './Shared/Components/Editablebox/Editablebox.component';
import { SearcherAndFilterComponent } from './Shared/Components/searcher-and-filter/searcher-and-filter.component';
import { AddAndSearchSelectorComponent } from './Shared/Components/add-and-search-selector/add-and-search-selector.component';
import { ButtonAddComponent } from './Shared/Components/button-add/button-add.component';
import { DetailViewDatesComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-promotions/Promotions-DetailView/DetailView-Dates/DetailView-Dates.component';
import { MultipleSelectorColumnComponent } from './Shared/Components/multiple-selector-column/multiple-selector-column.component';
import { SessionProgramacionTableComponent } from './Shared/Components/session-programacion-table/session-programacion-table.component';
import { ScheduleTimeSelectorComponent } from './Shared/Components/schedule-time-selector/schedule-time-selector.component';
import { RatingTextEditableComponent } from './Shared/Components/rating-text-editable/rating-text-editable.component';
import { EnviarFechasButtonComponent } from './Shared/Components/enviar-fechas-button/enviar-fechas-button.component';
import { SelectorAndSimpleDisplayComponent } from './Shared/Components/selector-and-simple-display/selector-and-simple-display.component';
import { ChooseEventTypePopupComponent } from './Popups/ChooseEventType-Popup/ChooseEventType-Popup.component';
import { GestionUsuariosCalendarioGeneralComponent } from './Pages/GestionUsuarios/GestionUsuarios-CalendarioGeneral/GestionUsuarios-CalendarioGeneral.component';
import { CustomCheckboxComponent } from './Shared/Components/custom-checkbox/custom-checkbox.component';
import { CalendarFiltersPopupComponent } from './Popups/CalendarFilters-Popup/CalendarFilters-Popup.component';
import { GanttComponent } from './Shared/Components/gantt/gantt.component';
import { TareaComponent } from './Shared/Components/gantt/tarea/tarea.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MarksTextEditableComponent } from './Shared/Components/marks-text-editable/marks-text-editable.component';
import { CalendarioGeneralTimelineComponent } from './Pages/GestionUsuarios/GestionUsuarios-CalendarioGeneral/CalendarioGeneral-Timeline/CalendarioGeneral-Timeline.component';
import { TerminarButtonComponentComponent } from './Shared/Components/terminar-button-component/terminar-button-component.component';
import { DetailViewCalendarComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-promotions/Promotions-DetailView/DetailView-Calendar/DetailView-Calendar.component';
import { TutoriasAlumnosLayoutComponent } from './Shared/Layout/Tutorias-Alumnos-Layout/Tutorias-Alumnos-Layout.component';
import { TutoriaAlumnoComponent } from './Pages/Tutoria-Alumno/Tutoria-Alumno.component';
import { TutoriaAlumnoRellenaDatosReservaComponent } from './Pages/Tutoria-Alumno/_holder/Tutoria-Alumno-RellenaDatosReserva/Tutoria-Alumno-RellenaDatosReserva.component';
import { AddTribunalPopupComponent } from './Popups/AddTribunal-Popup/AddTribunal-Popup.component';
import { DetailViewTutoriasComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-promotions/Promotions-DetailView/DetailView-Tutorias/DetailView-Tutorias.component';
import { DobleTitulacionDetailViewComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/DobleTitulacion-DetailView/DobleTitulacion-DetailView.component';
import { ReloadDatesButtonComponent } from './Shared/Components/reload-dates-button/reload-dates-button.component';
import { TutoriaAlumnoSuccessComponent } from './Pages/Tutoria-Alumno/_holder/Tutoria-Alumno-Success/Tutoria-Alumno-Success.component';
import { ConfiguracionAulasComponent } from './Pages/GestionUsuarios/GestionUsuarios-Configuracion/_Holders/Configuracion-Aulas/Configuracion-Aulas.component';
import { AddClassroomPopupComponent } from './Popups/AddPopup/AddClassroom-Popup/AddClassroom-Popup.component';
import { ConfiguracionDisposicionComponent } from './Pages/GestionUsuarios/GestionUsuarios-Configuracion/_Holders/Configuracion-Disposicion/Configuracion-Disposicion.component';
import { AddDispositionPopupComponent } from './Popups/AddPopup/AddDisposition-Popup/AddDisposition-Popup.component';
import { FormacionesNewVersionTutoriasComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/FormacionesNewVersion-Tutorias/FormacionesNewVersion-Tutorias.component';
import { TutoriaCrearComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/FormacionesNewVersion-Tutorias/_holder/Tutoria-Crear/Tutoria-Crear.component';
import { ConfiguracionCertificadosComponent } from './Pages/GestionUsuarios/GestionUsuarios-Configuracion/_Holders/Configuracion-Certificados/Configuracion-Certificados.component';
import { EditTutoringPopupComponent } from './Popups/EditTutoring-Popup/EditTutoring-Popup.component';
import { TutoriaByTeacherComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/FormacionesNewVersion-Tutorias/_holder/Tutoria-By-Teacher/Tutoria-By-Teacher.component';
import { GestionUsuariosProfesoresDetallesRegistroAcademicoComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Detalles/_Holders/GestionUsuarios-Profesores-Detalles-RegistroAcademico/GestionUsuarios-Profesores-Detalles-RegistroAcademico.component';
import { EditTutoringreservationComponent } from './Popups/EditTutoringreservation-Popup/EditTutoringreservation.component';
import { GestionUsuariosProfesoresDetallesTutoriasComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Detalles/_Holders/GestionUsuarios-Profesores-Detalles-Tutorias/GestionUsuarios-Profesores-Detalles-Tutorias.component';
import { TutoringByTeacherComponent } from './Shared/Components/TutoringByTeacher/TutoringByTeacher.component';
import { GestionUsuariosProfesoresDetallesPendienteFacturacionComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Detalles/_Holders/GestionUsuarios-Profesores-Detalles-PendienteFacturación/GestionUsuarios-Profesores-Detalles-PendienteFacturación.component';
import { EditHonorarioPopupComponent } from './Popups/EditHonorario-Popup/EditHonorario-Popup.component';
import { AddTeacherBillPopupComponent } from './Popups/AddPopup/AddTeacherBill-Popup/AddTeacherBill-Popup.component';
import { AddFileComponent } from './Shared/Components/Add-file/Add-file.component';
import { GestionUsuariosProfesoresDetallesCalendarioComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Detalles/_Holders/GestionUsuarios-Profesores-Detalles-Calendario/GestionUsuarios-Profesores-Detalles-Calendario.component';
import { DetailViewTeachersComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-promotions/Promotions-DetailView/DetailView-Facturacion/DetailView-Facturacion.component';
import { AddCertificatePopupComponent } from './Popups/AddPopup/AddCertificate-Popup/AddCertificate-Popup.component';
import { ShowConflictsPopupComponent } from './Popups/ShowConflicts-Popup/ShowConflicts-Popup.component';
import { ClearableDateComponent } from './Shared/Components/clearable-date/clearable-date.component';
import { ChangeStatusEnrollmentPopupComponent } from './Popups/ChangeStatusEnrollment-Popup/ChangeStatusEnrollment-Popup.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { GestionUsuariosHomeComponent } from './Pages/GestionUsuarios/GestionUsuarios-Home/GestionUsuarios-Home.component';
import { FillDatesButtonComponent } from './Shared/Components/fillDatesButton/fillDatesButton.component';
import { CancelEnrollmentPopupComponent } from './Popups/CancelEnrollmentPopup/CancelEnrollmentPopup.component';
import { GestionUsuariosAlumnosDetallesFinancieroComponent } from './Pages/GestionUsuarios/GestionUsuarios-Alumnos/GestionUsuarios-Alumnos-Detalles/_Holders/GestionUsuarios-Alumnos-Detalles-Financiero/GestionUsuarios-Alumnos-Detalles-Financiero.component';
import { ConfirmarAccionSobreescribirFechasPopupComponent } from './Popups/ConfirmarAccionSobreescribirFechas-Popup/ConfirmarAccionSobreescribirFechas-Popup.component';

registerLocaleData(localEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    //LAYOUT
    FullContainerComponent,
    SidebarComponent,
    HeaderComponent,
    ContainerAutomatriculaComponent,
    //PAGES
    GestionUsuariosProfesoresListadoComponent,
    GestionUsuariosAlumnosListadoComponent,
    GestionUsuariosProfesoresDetallesComponent,
    GestionUsuariosProfesoresDetallesFacturasComponent,
    GestionUsuariosProfesoresDetallesHorasComponent,
    GestionUsuariosProfesoresCrearComponent,
    GestionUsuariosAlumnosDetallesComponent,
    GestionUsuariosMatriculacionesComponent,
    GestionUsuariosMatriculacionesCursosComponent,
    GestionUsuariosAlumnosCrearComponent,
    TestComponent,
    // Automatricula
    AutomatriculaViewComponent,
    AutomatriculaFormularioComponent,
    FormInformacionPersonalComponent,
    IncompaniesListComponent,
    DatosDeFacturacionComponent,
    PrecioComponent,
    MetodosDePagoComponent,
    BonificacionComponent,
    // LOGIN,
    LoginComponent,
    //PAGES HOLDER COMPONENTS
    GestionUsuariosProfesoresTablaComponent,
    GestionUsuariosProfesoresDetallesInformacionComponent,
    GestionUsuariosProfesoresDetallesProductosComponent,
    GestionUsuariosProfesoresDetallesDocumentosComponent,
    GestionUsuariosAlumnosListadoTablaComponent,
    GestionUsuariosAlumnosListadoTablaAlumniComponent,
    GestionUsuariosAlumnosDetallesInformacionComponent,
    GestionUsuariosAlumnosDetallesFormacionesComponent,
    GestionUsuariosAlumnosDetallesFacturasComponent,
    GestionUsuariosAlumnosDetallesDocumentacionComponent,
    GestionUsuariosMatriculacionesTablaComponent,
    GestionUsuariosMatriculacionesPipelineComponent,
    GestionUsuariosMatriculacionesCursosPipelineComponent,
    GestionUsuariosMatriculacionesCursosTablaComponent,
    GestionUsuariosIncompaniesComponent,
    InformacionPersonalComponent,
    GestionUsuariosFormacionesComponentNewVersion,
    FormacionesNewVersionTodosComponent,
    FormacionesNewVersionMasterComponent,
    FormacionesNewVersionIncompanyComponent,
    FormacionesNewVersionDobleTitulacionComponent,
    FormacionesNewVersionCursoComponent,
    FormacionesCrearComponent,
    FormacionesDetailViewComponent,
    DetailViewPromotionsComponent,
    PromotionsCrearComponent,
    PromotionsDetailViewComponent,
    PromotionsStudentsComponent,
    IncompaniesPipelineComponent,
    IncompaniesCreateComponent,
    IncompaniesCheckThirdColumnComponent,
    GestionUsuariosMainViewComponent,
    HeOlvidadoMiContrasenaComponent,
    RecoverPasswordComponent,
    //CONFIGURACIÓN
    GestionUsuariosConfiguracionComponent,
    ConfiguracionPermisosComponent,
    PermisosUserListComponent,
    ConfiguracionLogOutComponent,
    PermisosPermisosComponent,
    //DIRECTIVES
    SkeletonDirective,
    JoinSessionButtonDirective,
    TextEditableComponent,
    //POPUPS
    PersonalizarColumnasPopupComponent,
    SubirArchivoPopupComponent,
    ConfirmarAccionPopupComponent,
    EnrollmentDetailPopupComponent,
    AddStudentEnrollmentPopupComponent,
    UnAlumnoSelectedFirstStepComponent,
    AlumnoSelectedSecondStepComponent,
    VariosAlumnosSubirExcelComponent,
    VariosAlumnosFirstStepComponent,
    VariosAlumnosSecondStepComponent,
    AddExcelPopupComponent,
    ListaDeStudentsDeExcelComponent,
    SubirFacturaPopupComponent,
    AddDobleTitulacionPopupComponent,
    ConfirmarAccionPerderPopupComponent,
    CursoMasterMatriculaConfirmaPopupComponent,
    IncompaniesCreatePromotionsComponent,
    AddStudentToGroupPopupComponent,
    AddIEMUserComponent,
    PropuestaFormalAccionPopupComponent,
    CursoMasterMatriculaRevisaPopupComponent,
    CursoMasterPreMatriculaPopupComponent,
    AsociarProductoProfesorPopupComponent,
    CambiarFormacionPromocionMatriculaPopupComponent,
    //COMPONENTS
    CustomInputComponent,
    CountrySelectorComponent,
    CustomTextAreaComponent,
    TableIconComponent,
    MainTableComponent,
    TableItemBoxComponent,
    TableItemChipComponent,
    FormacionesNewVersionTodosTableComponent,
    FormacionesNewVersionMasterTableComponent,
    FormacionesNewVersionCursoTableComponent,
    FormacionesNewVersionIncompanyTableComponent,
    FormacionesNewVersionDobleTitulacionTableComponent,
    DetailViewDetailsComponent,
    DetailViewBaseContainerComponent,
    StatusPillComponent,
    TableItemButtonComponent,
    ChipComponent,
    DetailViewInformationComponent,
    DetailViewStudentsComponent,
    DetailViewTeachersComponent,
    DetailViewTeacherCardComponent,
    StarsComponent,
    DetailViewProjectsComponent,
    BlueSelectorComponent,
    FormattedSelectorComponent,
    BlueDataPickerComponent,
    ChipSelectorComponent,
    SmallInputComponent,
    MultipleSelectorComponent,
    OptionsMenuComponent,
    DetailViewDirectorComponent,
    DetailViewContentComponent,
    CalendarComponent,
    IEMLoaderComponent,
    EventCalendarContainerComponent,
    InputTestComponent,
    CustomDatepickerComponent,
    DetailViewContentModuleComponent,
    DetailViewContentModulesContainerComponent,
    EditableboxComponent,
    SearcherAndFilterComponent,
    AddAndSearchSelectorComponent,
    ButtonAddComponent,
    DetailViewDatesComponent,
    MultipleSelectorColumnComponent,
    SessionProgramacionTableComponent,
    ScheduleTimeSelectorComponent,
    RatingTextEditableComponent,
    EnviarFechasButtonComponent,
    SelectorAndSimpleDisplayComponent,
    ChooseEventTypePopupComponent,
    GestionUsuariosCalendarioGeneralComponent,
    CustomCheckboxComponent,
    CalendarFiltersPopupComponent,
    GanttComponent,
    TareaComponent,
    MarksTextEditableComponent,
    CalendarioGeneralTimelineComponent,
    TerminarButtonComponentComponent,
    DetailViewCalendarComponent,
    TutoriasAlumnosLayoutComponent,
    TutoriaAlumnoComponent,
    TutoriaAlumnoRellenaDatosReservaComponent,
    AddTribunalPopupComponent,
    DetailViewTutoriasComponent,
    DobleTitulacionDetailViewComponent,
    ReloadDatesButtonComponent,
    TutoriaAlumnoSuccessComponent,
    ConfiguracionAulasComponent,
    AddClassroomPopupComponent,
    ConfiguracionDisposicionComponent,
    AddDispositionPopupComponent,
    FormacionesNewVersionTutoriasComponent,
    TutoriaCrearComponent,
    ConfiguracionCertificadosComponent,
    EditTutoringPopupComponent,
    TutoriaByTeacherComponent,
    GestionUsuariosProfesoresDetallesRegistroAcademicoComponent,
    EditTutoringreservationComponent,
    GestionUsuariosProfesoresDetallesTutoriasComponent,
    TutoringByTeacherComponent,
    GestionUsuariosProfesoresDetallesPendienteFacturacionComponent,
    EditHonorarioPopupComponent,
    AddTeacherBillPopupComponent,
    AddFileComponent,
    GestionUsuariosProfesoresDetallesCalendarioComponent,
    AddCertificatePopupComponent,
    ShowConflictsPopupComponent,
    ClearableDateComponent,
    ChangeStatusEnrollmentPopupComponent,
    GestionUsuariosHomeComponent,
    FillDatesButtonComponent,
    CancelEnrollmentPopupComponent,
    GestionUsuariosAlumnosDetallesFinancieroComponent,
    ConfirmarAccionSobreescribirFechasPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    QuasarLibraryModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxPaginationModule,
    MatRadioModule,
    QdChartsModule,
    MatProgressSpinnerModule,
    FullCalendarModule,
    DragDropModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    NgApexchartsModule,
    MatSlideToggleModule,
    ScrollingModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
