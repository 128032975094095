<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>{{modalData.accion}}</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="container">
      <button fileInput [multiple]="multiple" [accept]="modalData.formatos" [(ngModel)]="file"
      (change)="updateFile(file)"></button>
      <img src="/assets/Icons/General/subirFichero.svg" alt="">
    </div>
    <div class="documentosSubidos">
      <div class="documento" *ngFor="let documento of file; let index = index">
        <span>
          <img src="/assets/Icons/General/pdf.svg">
          {{documento.file.name}}
        </span>
        <mat-icon (click)="removeDocumento(documento, index)">close</mat-icon>
      </div>
    </div>

  </div>
  <div class="footer">
    <btn classList="specialButton" (click)="forceClosePopup()">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn classList="principalButton" (click)="closePopup((file.length >= 1) ?file : [])" [class.disabled]="file == null">
      <ng-container>
        Subir
      </ng-container>
    </btn>
  </div>
</div>
