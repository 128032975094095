import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  iResultHttp,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { FeeService } from 'src/app/Services/Api/Fee.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'GestionUsuarios-Profesores-Detalles-Horas',
  templateUrl: './GestionUsuarios-Profesores-Detalles-Horas.component.html',
  styleUrls: ['./GestionUsuarios-Profesores-Detalles-Horas.component.scss'],
})
export class GestionUsuariosProfesoresDetallesHorasComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input() dataTeacher: any = null;
  @Input() isDataLoaded: boolean = false;
  rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  hours: any = {};
  master: boolean = false;

  dataToPass: any = {
    id: 0,
  };

  constructor(private feeSE: FeeService) {
    super();
  }

  ngOnInit() {
    this.rolesAndPermissions =
      StaticUtilitiesService.getUserPermissionsAndRoles();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isDataLoaded']) {
      if (changes['isDataLoaded'].currentValue) {
        this.getHoursTeacher();
      }
    }
    if (changes['dataTeacher']) {
      if (changes['dataTeacher'].currentValue) {
        this.dataToPass.id = this.dataTeacher.idFee;
      }
    }
  }

  ngAfterViewInit() {}

  /**
   * FUNCTIONALITY
   */

  checkRolesAndPermissions() {
    return (
      this.rolesAndPermissions.roles.includes('ROLE_ADMIN') ||
      this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') ||
      this.rolesAndPermissions.permissions.includes(2)
    );
  }

  addRateToAccordeon() {
    let acordeones = document.querySelectorAll('.accorHeader');
    const divsInfoConainers = document.querySelectorAll('.divInfo');
    if (divsInfoConainers.length > 0) {
      divsInfoConainers.forEach((element) => {
        element.remove();
      });
    }
    acordeones.forEach((acordeon: any, index: number) => {
      let divInfo = document.createElement('div');
      divInfo.classList.add('divInfo');
      switch (index) {
        case 0:
          divInfo.innerHTML = `<span class="spanInfo">${this.hours?.teacherMasterEuroPerHour} €/h</span>`;
          break;
        case 1:
          divInfo.innerHTML = `<span class="spanInfo">${this.hours?.teacherEnglishMasterEuroPerHour} €/h</span>`;
          break;
        case 2:
          divInfo.innerHTML = `<span class="spanInfo">${this.hours?.marketingMasterEuroPerHour} €/h</span>`;
          break;
        default:
          break;
      }
      acordeon.appendChild(divInfo);
    });
  }

  updateValue(value: any, key: string) {
    this.dataToPass[key] = value;
    this.updateHoursTeacher();
    delete this.dataToPass[key];
  }

  /**
   * API CALLS
   */

  getHoursTeacher() {
    this.feeSE.getFeesByTeacher(this.dataTeacher.id);
    this.feeSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;

        this.hours = { ...data };
        this.addRateToAccordeon();
        this._unsubInd.next('');
      });
    this.feeSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  updateHoursTeacher() {
    this.feeSE.update(this.dataToPass);
    this.feeSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Se ha actualizado correctamente');
        this.getHoursTeacher();
        this._unsubInd3.next('');
      });
    this.feeSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
