import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopupService, StaticUtilitiesService, iResultHttp, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { PermissionService } from 'src/app/Services/Api/Permission.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { takeUntil } from 'rxjs/operators';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';

@Component({
  selector: 'Permisos-User-list',
  templateUrl: './Permisos-User-list.component.html',
  styleUrls: ['./Permisos-User-list.component.scss']
})
export class PermisosUserListComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() filtersUsers: any = null;
  @Input() total: number = 0;
  @Input() userList: any[] = StaticDataHandlerService.getIEMUserList();
  @Input() isUserListLoaded: boolean = false;

  @Output() userSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();


  displayedColumns: any[] = [
    { displayName: "Nombre", property: 'fullName' },
    { displayName: "Correo electrónico", property: 'email' },
    { displayName: "Fecha de alta", property: 'registerDate' },
    { displayName: "Estado", property: 'state' },
  ];


  constructor(private permissionSE: PermissionService, private popupSE: PopupService) {
    super();
  }

  ngOnInit() {
  }

  /**
   * FUNCTIONALITY
   */

  userDetail(event) {
     
    this.userSelected.emit(event);
  }

  toggleBlock(user) {
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, { accion: (user.state === 'Activo') ? 'Bloquear' : 'Desbloquear', elemento: `al usuario ${user.fullName}` });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd8)).subscribe((res: any) => {
      if (!res) { return }
      this.toggleBlockUser(user.id)
      this._unsubInd8.next('');
    })
  }

  /**
   * API CALLS
   */

  toggleBlockUser(userId) {
    this.permissionSE.toggleBlockUser(userId);
    this.permissionSE.getResultUpdate().pipe(takeUntil(this._unsubInd9)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      let { message } = res;
      StaticUtilitiesService.showFeedback(String(message));
      this.refresh.emit(true);
      this._unsubInd9.next('')
    })
    this.permissionSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }
}
