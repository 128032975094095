import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StaticUtilitiesService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';

@Component({
  selector: 'FormacionesNewVersion-Master',
  templateUrl: './FormacionesNewVersion-Master.component.html',
  styleUrls: ['./FormacionesNewVersion-Master.component.scss']
})
export class FormacionesNewVersionMasterComponent implements OnInit {

  @Output() pageChange:EventEmitter<boolean> = new EventEmitter()

  @Input() allMasters:any = [];
  @Input() totalMasters:number = 0;
  @Input() areReadyAllMasters:boolean = false;
  @Input() filterAllMasters:any = null;

  constructor(private staticUtilitiesSE:StaticUtilitiesService) { }

  ngOnInit() {
  }

  goToDetailView(formation: any) {
    this.staticUtilitiesSE.goTo(
      'formaciones/formaciones-detalle/',
      slideNavigationTypes.slideToTop,
      { master: formation.masterId }
    );
  }

  pageChangeEmmiter(event) {
    this.pageChange.emit(event);
  }
}
