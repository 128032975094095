<fullContainer>
  <ng-container>
    <header></header>
    <div class="userInfoContainer" skeleton [isDataLoaded]="isDataLoaded">
      <div class="imagenDePerfil">
        <image
          class="profileImage circleImage"
          [image]="dataStudent?.user?.photo"
          [placeHolderImage]="'/assets/Images/Placeholder/placeholder.jpg'"
        ></image>
        <div class="coverImage">
          <div class="iconos" (click)="editImage()">
            <mat-icon>edit</mat-icon>
          </div>
        </div>
      </div>
      <div class="info">
        <div class="titles">
          <div class="nombreYAlias">
            <p class="nombre">
              {{ dataStudent?.user?.name + " " + dataStudent?.user?.surname }}
            </p>
            <p class="nombre">·</p>
            <p class="alias">{{ dataStudent?.user?.alias }}</p>
          </div>
          <btn
            classList="specialButton"
            (click)="irAEditarAlumno(dataStudent?.id)"
          >
            <ng-container> Editar alumno </ng-container>
          </btn>
        </div>
        <div class="otherInfo">
          <div class="data">
            <span>
              {{ dataStudent?.user?.email }}
            </span>
            <span>
              ({{ dataStudent?.user.phonePrefix }})
              {{ dataStudent?.user?.phone }}
            </span>
          </div>
          <div class="data fechas">
            <span>{{
              dataStudent?.user?.dataStudent | date : "dd/MM/YYYY"
            }}</span>
            <!-- <span>Activo hace 2 meses</span> -->
          </div>
        </div>
        <div class="footer">
          <!-- <span class="cursando" [class.active]="isDataLoaded">#Marketing Digital</span> -->
          <span class="country" [class.active]="isDataLoaded">
            <i
              class="country_flags"
              size="ultra_small"
              [attr.country]="dataStudent?.user?.address?.country?.isoCode"
            ></i>
            {{ dataStudent?.user?.address?.province ?? "-" }},
            {{ dataStudent?.user?.address?.country?.nombre ?? "-" }}
          </span>
        </div>
      </div>
    </div>
    <div class="submenu">
      <toggleSubmenu
        [elements]="[
          'Información',
          'Formaciones',
          'Financiero',
          'Facturas',
          'Documentación'
        ]"
        class="ficha_toggleSubmenu"
        [(ngModel)]="optionSubmenuSelected"
        (onValueChange)="onToggleChange($event)"
      ></toggleSubmenu>
    </div>
    <informacionAlumno
      [dataStudent]="dataStudent"
      [isDataLoaded]="isDataLoaded"
      *ngIf="optionSubmenuSelected == 0"
    >
    </informacionAlumno>
    <formacionesAlumno
      [dataStudent]="dataStudent"
      [isDataLoaded]="isDataLoaded"
      *ngIf="optionSubmenuSelected == 1"
      (refresh)="refresh($event)"
    >
    </formacionesAlumno>
    <financiero-alumno
      [dataStudent]="dataStudent"
      *ngIf="optionSubmenuSelected == 2"
      (refresh)="refresh($event)"
    >
    </financiero-alumno>
    <facturasAlumno
      [dataStudent]="dataStudent"
      *ngIf="optionSubmenuSelected == 3"
      (refresh)="refresh($event)"
    >
    </facturasAlumno>
    <documentacionAlumno
      [dataStudent]="dataStudent"
      [isDataLoaded]="isDataLoaded"
      (refresh)="refresh($event)"
      *ngIf="optionSubmenuSelected == 4"
    ></documentacionAlumno>
  </ng-container>
</fullContainer>
