import { Component, OnInit } from '@angular/core';
import { iOptionsSelector, iResultHttp, iUnsubscribeDestroy, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { StudentService } from 'src/app/Services/Api/Student.service';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iTeacherFilter } from 'src/app/Shared/Interfaces/iTeacher';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-GestionUsuarios-Profesores-Listado',
  templateUrl: './GestionUsuarios-Profesores-Listado.component.html',
  styleUrls: ['./GestionUsuarios-Profesores-Listado.component.scss']
})
export class GestionUsuariosProfesoresListadoComponent extends iUnsubscribeDestroy implements OnInit {
  
  rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  lineCounter: number = 0;
  filter: string = "";
  filters: iTeacherFilter = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  }
  total:number = 0;
  filterLineArray: any = [];
  profesores: Array<any> = [];
  isFilterDisplayed: boolean = false;
  openPrefernecesPopup: boolean = false;
  downloadExcelPopup: boolean = false;
  formacionSelector: any = null;
  formacionSelectorDos: any = null;
  formacionSelectorTres: any = null;
  yearSelectorOptions: iOptionsSelector = {
    items: [],
    bindValue: 'year',
    clearable: true,
    bindLabel: 'year',
    append: true,
    placeholder: "Selecciona un año"
  }
  isDataLoaded: boolean = false;
  cursosSelectorOptions: iOptionsSelector = {
    items: [
      { id: 'masters', label: 'Master' },
      { id: 'courses', label: 'Curso' },
      { id: 'incompanies', label: 'Incompanies' },
    ],
    clearable: true,

    placeholder: "Formación",
    classList: 'roundedSelector '

  }
  optionsSecondSelector: iOptionsSelector = {
    items: [],
    bindLabel: 'name',
    clearable: true,
    placeholder: "Edición",
    classList: 'roundedSelector '
  }
  optionsThirdSelector: iOptionsSelector = {
    items: [],
    bindLabel: 'edition',
    clearable: true,
    placeholder: "Promoción",
    multiple: {
      isMultiple: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varias promociones seleccionadas'
    },
    classList: 'roundedSelector '
  }
  constructor(private staticUtilitiesSE: StaticUtilitiesService, private teacherSE:TeacherService, private studentSE:StudentService, private promotionFormationSE:PromotionFormationService) {
    super();
  }

  ngOnInit() {
    this.rolesAndPermissions = StaticUtilitiesService.getUserPermissionsAndRoles()
    this.getAllTeachers();
    this.getFilterInfo();

  }

  /**
   * GETTERS & SETTERS
   */
  getFilter(event,key){
    if(!event){delete this.filters[key]; this.getAllTeachers() ;return}
    this.filters[key] = event;
    this.getAllTeachers();
  }
  
  /**
   * FUNCTIONALITIES
   */
  goTo(){
    this.staticUtilitiesSE.goTo("/profesores/mis-profesores/crear-profesor", slideNavigationTypes.slideToTop)
  }
  
  displayFilters(){
    this.isFilterDisplayed = !this.isFilterDisplayed;

  }

  addNewFilterLine() {
    let newLine = {
      id: this.lineCounter,
      type: this.formacionSelector.label,
      formation: this.formacionSelectorDos.name,
      promotions: this.formacionSelectorTres,
    }
    this.lineCounter++;
    this.filterLineArray.push(newLine);
    this.sendFilter();
  }

  deleteLineFilter(id) {
    this.filterLineArray = this.filterLineArray.filter((line) => line.id != id);
    this.sendFilter();
  }

  sendFilter(){
    this.filters['promotions'] = this.filterLineArray.map((promotion) => promotion.promotions.map((item) => item.id));
    if(this.filters['promotions'].length == 0){
      delete this.filters['promotions'];
    }
    this.getAllTeachers();
  }


  openPreferences(){
    this.openPrefernecesPopup = true;
    setTimeout(() => {
      this.openPrefernecesPopup = false;
    }, 1000);
  }
  downloadExcel(){
    this.downloadExcelPopup = true;
    setTimeout(() => {
      this.downloadExcelPopup = false;
    }, 1000);
  }

  callCourseType(event) {
    if (!event) {
      this.formacionSelector = null;
      return;
    }
    this.formacionSelectorDos = null;
    this.formacionSelectorTres = null;
    this.getFormationInfo(event.id);
  }
  callPromotion(event) {
    if (!event) {
      this.formacionSelectorDos = null;
      this.formacionSelectorTres = null;
      return;
    }
    this.formacionSelectorTres = null;
    this.getPromotionsInfo(event.formation);
  }
  deletePromotionFilter(event, index) {
     
     
    this.filterLineArray[index].promotions = this.filterLineArray[index].promotions.filter((promotion) => promotion.id != event);
  }

  pageChange(event){
    this.filters.num_pagina = event;
    this.getAllTeachers();
   }

   checkRolesAndPermissions(){
    return this.rolesAndPermissions.roles.includes('ROLE_ADMIN') || this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') || this.rolesAndPermissions.permissions.includes(2) 
  }
 
  /**
   * API CALLS
   */

  getFilterInfo() {
    this.studentSE.getFilterInfo({ filter: 'years' });
    this.studentSE.getResultfilterInfo().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
      if (res == null) { return };
      let { data } = res;
      this.yearSelectorOptions.items = data;
      this._unsubInd4.next("");
    })
    this.studentSE.getResultfilterInfoError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('');
    })
  }


  getFormationInfo(event) {
    this.studentSE.getFilterInfo({ filter: event });
    this.studentSE.getResultfilterInfo().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (res == null) { return };
      let { data } = res;

      this.optionsSecondSelector.items = data;
       
      this._unsubInd3.next("");
    })
    this.studentSE.getResultfilterInfoError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('');
    })
  }

  getAllTeachers(){
    this.teacherSE.getAll(this.filters);
    this.teacherSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return};
      let {data,total} = res.data;
      this.profesores = data.map((profesor:any)=>{ return {...profesor, 
        name: `${profesor?.user?.name} ${profesor?.user?.surname}`, 
        mail: profesor?.user?.email, 
        phone: profesor?.user?.phone, 
        state: profesor?.user?.state,
        dni: profesor?.user?.dni,
        type: profesor?.typeOf,
        company: profesor?.user?.company,
        jobPosition: profesor?.user?.jobPosition,
        employmentStatus: profesor?.user?.employmentStatus,
        linkedIn: profesor?.user?.socialMedia?.linkedin,
        instagram: profesor?.user?.socialMedia?.instagram,
        facebook: profesor?.user?.socialMedia?.facebook,
        x: profesor?.user?.socialMedia?.x,
        tiktok: profesor?.user?.socialMedia?.tiktok,
        otras: profesor?.user?.socialMedia?.otras,
        retentionType: profesor?.user?.bankData?.retentionType,

          }});
      this.total = total;
      this.isDataLoaded = true;
       
      this._unsubInd.next("");
    })
    this.teacherSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  getPromotionsInfo(event) {
    this.promotionFormationSE.getPromotionsByFormationId(event);
    this.promotionFormationSE.getResultPromotionsByFormationId().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (!res) return;
      let { data } = res;
      this.optionsThirdSelector.items = data;
       
      this._unsubInd3.next("");
    })
    this.promotionFormationSE.getResultPromotionsByFormationIdError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    });
  }

}
