<fullContainer>
  <ng-container>
    <header></header>

    <div class="subHeader">
      <toggleSubmenu
        class="secondary toggleSubmenu"
        [elements]="toggleSubmenuElements"
        [(ngModel)]="toggleModel"
        (onValueChange)="onToggleChange($event)"
      ></toggleSubmenu>
      <!-- <btn classList="generalbutton violetButton">Editar</btn> -->
    </div>

    <ng-container *ngIf="toggleModel === getIndexOfElement('Información')">
      <detailView-Information
        [promotionId]="params?.promotion"
        [isMaster]="isMaster"
        [promotionInfo]="extraClass.promotionInfoForView"
        (update)="getPromotionInfo()"
      ></detailView-Information>
    </ng-container>

    <ng-container *ngIf="toggleModel === getIndexOfElement('Fechas')">
      <DetailView-Dates
        (refreshDates)="onRefreshDates()"
        [isMaster]="isMaster"
        [promotionId]="params?.promotion"
        [promotionDates]="extraClass.promotionDates"
      ></DetailView-Dates>
    </ng-container>

    <ng-container *ngIf="toggleModel === getIndexOfElement('Alumnos')">
      <detailView-Students
        [promotionId]="params?.promotion"
        [promotionStudent]="extraClass.promotionStudent"
      ></detailView-Students>
    </ng-container>

    <ng-container *ngIf="toggleModel === getIndexOfElement('Facturación')">
      <detailView-Facturacion
        [promotionBilling]="extraClass.promotionBilling"
      ></detailView-Facturacion>
    </ng-container>

    <ng-container *ngIf="toggleModel === getIndexOfElement('Calendario')">
      <DetailView-Calendar
        [promotionId]="params?.promotion"
        [isMaster]="isMaster"
        [isCurso]="isCurso"
      ></DetailView-Calendar>
    </ng-container>

    <ng-container *ngIf="toggleModel === getIndexOfElement('Proyecto')">
      <detailView-Projects
        [promotionId]="params?.promotion"
        [tribunalPromotion]="extraClass.tribunalPromotion"
        (update)="onRefreshData()"
      ></detailView-Projects>
    </ng-container>

    <ng-container *ngIf="toggleModel === getIndexOfElement('Tutorías')">
      <DetailView-Tutorias
        [promotionId]="params?.promotion"
        [tutoringPromotion]="extraClass.tutoringPromotion"
      ></DetailView-Tutorias>
    </ng-container>
  </ng-container>
</fullContainer>
