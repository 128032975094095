<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>CONFIRMAR LA MATRÍCULA</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <btn classList="principalButton" (click)="getURLAutoenrollment()">
      <ng-container>
        Revisar
      </ng-container>
    </btn>
    <btn classList="principalButton" (click)="sendEmail()">
      <ng-container>
        Enviar email pago
      </ng-container>
    </btn>
    <btn classList="principalButton" (click)="closePopup('UserEnrolled')">
      <ng-container>
        Matricular
      </ng-container>
    </btn>

  </div>
  <!-- <div class="footer">
  </div> -->
</div>