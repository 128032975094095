<div class="projects__container">
  <Editablebox title="Fechas entrega" bgColor="gray" (sendIsEditing)="handelChangeEdit($event)">
    <div class="projects__date">
      <ng-container *ngFor="let date of extraClass.masterDeliveryDates; index as index; trackBy: trackByFunction">
        <div>
          <p text-editable (input)="updateTitleCustomDeliveryDate($event, 'newTitle',index)"
            [canEdit]="extraClass.editableController['Fechas entrega'] && date.editable">
            {{date.title}}</p>

          <div class="datePickerAndDelete">

            <blue-data-picker [date]="date.startDate" (change)="prepareDateToUpdate($event, 'startDate',index)"
              [optionsCustomInput]="extraClass.optionsBlueDataPicker"></blue-data-picker>

            <span class="deleteIcon" *ngIf="extraClass.editableController['Fechas entrega']"
              (click)="deleteCustomDeliveryDate([date.customDeliveryDateId])">
              <img [src]="IconHandlerService.getDeleteIcon()" alt="delete">
            </span>

          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="extraClass.editableController['Fechas entrega']">
        <button-add (click)="prepareCreateCustomDeliveryDate()"></button-add>
      </ng-container>
    </div>
  </Editablebox>

  <aside>
    <Editablebox title="Profesores tribunal" bgColor="gray" (sendIsEditing)="handelChangeEdit($event)"
      [componentOptions]="extraClass.profesoresTribunalComponentOptions">
      <div class="profesoresTribunalContainer">
        <div class="whiteContainer">

          <ng-container *ngFor="let teacher of extraClass.teachers; index as index ; trackBy:trackByFunctionTeacher">
            <div class="contentLine">
              <mat-checkbox (change)="onCheckboxChange($event,index,teacher)"></mat-checkbox>
              <div class="contentLine__name" [ngClass]="{'conflict': teacher.conflict}">
                <p>{{teacher.teacherName}}</p>
                <ng-container *ngIf="extraClass.editableController['Profesores tribunal']">
                  <img [src]="IconsHandlerService.getDeleteIcon()" (click)="deleteProjectTeacher([teacher.id])">
                </ng-container>

              </div>
              <chip-selector [chipSelectorOptions]="extraClass.teacherChipSelectorOptions"
                [(ngModel)]="teacher.stateId" (change)="prepareUpdateTeacherState($event,'projectTeacherStateId',teacher.id)" ></chip-selector>
            </div>
          </ng-container>


        </div>
        <div class="selectorLine" *ngIf="extraClass.editableController['Profesores tribunal']">
          <selector [options]="extraClass.addTeacherSelectorOptions" (change)="getSelectedTeacher($event)"></selector>
        </div>
      </div>
    </Editablebox>

    <Editablebox title="Tribunales" bgColor="gray" (sendIsEditing)="handelChangeEdit($event)"
      [componentOptions]="extraClass.studentTribunalComponentOptions">
      <div class="tribunalesContainer">


        <ng-container *ngFor="let tribunal of extraClass.tribunals; index as tribunalIndex">
          <div class="tribunalContainer">
            <div class="tribunalContainer__header">
              <p>
                Tribunal {{tribunalIndex + 1}} {{tribunal.tribunalName}}
              </p>

              <span style="cursor: pointer;" *ngIf="extraClass.editableController['Tribunales']"
              (click)="deleteTribunal(tribunal.tribunalId)">
              <img [src]="IconHandlerService.getDeleteIcon()" alt="delete">
            </span>

            </div>

            <div class="tribunalContainer__blueChips">
              <div class="chip">
                <p>
                  {{tribunal.startDate}}
                </p>
              </div>

              <div class="chip">
                <p>
                  {{tribunal.hours}}
                </p>
              </div>

              <div class="chip">
                <p>
                  {{tribunal.slotMinutes}}
                </p>
              </div>

              <div class="chip">
                <p>
                  {{tribunal.classroomName}}
                </p>
              </div>

            </div>

            <div class="tribunalContainer__studentsBox">
              <div class="tribunalContainer__studentsBox--header">
                <p>Alumnos</p>
              </div>
              <ng-container *ngFor="let student of tribunal.students; index as studentIndex">

                <div class="tribunalContainer__studentsBox--studentLine" *ngIf="extraClass.matrixOptionsStudents.length > 0">
                  <span class="studentSelector">
                    <selector
                      [options]=" extraClass.matrixOptionsStudents[tribunalIndex][studentIndex] ?? extraClass.studentSelectorOptions"
                      [(ngModel)]="student.studentId"
                      (change)="prepareUpdateStudent($event, 'studentId', student.tribunalStudentId)"
                      >
                    </selector>
                  </span>
                  <span class="time">
                    <p> {{student.hours}} </p>
                  </span>
                  <chip-selector
                    [chipSelectorOptions]="extraClass.matrixOptionsStudentsStatus[tribunalIndex][studentIndex] ?? extraClass.studentStatusSelectorOptions"
                    [(ngModel)]="student.tribunalStudentStateId"
                    (change)="prepareUpdateStudent($event, 'tribunalStudentStateId', student.tribunalStudentId)"
                    ></chip-selector>
                </div>

              </ng-container>

            </div>

          </div>
        </ng-container>

        <button class="detailViewContent__addModule" *ngIf="extraClass.editableController['Tribunales']"
          (click)="openAddProjectPopup()"><mat-icon>add_circle_outline</mat-icon></button>

      </div>
    </Editablebox>
  </aside>
</div>