import { iEventCalendar } from '../Interfaces/Utils/iEventCalendar';

export class TestComponentClass {
  events: any = [];

  currentYear: number = new Date().getFullYear();

  event: iEventCalendar = {
    type: 2,
    // modality: 1,
    // allDay: false,
    // asistant: 1,
    // student: 1,

    // edition: 1,
    // formation: 1,
    // location: 'Calle de fer',
    // url: 'https://www.google.com',
    id: 1,
    edition: 1,
    formation: 1,
    url: 'https://www.google.com',
    // allDay:true,
    // company: 1,
    // location: 'Calle de fer',
    className: 'incompany',
    // company: 1,
    startDate: new Date().toISOString(),
    title: 'Ay que tio!',
    observations: 'Observations',
    classroom: 1,
    startHour: new Date().getUTCHours().toString() + ':' + new Date().getUTCMinutes().toString(),
    status: 1,
    subtitle: 'Evento de test',
    teachers: [1,2],
    // sede: 1,
    endHour: Number(new Date().getUTCHours().toString()) + 2 + ':' + new Date().getUTCMinutes().toString(),
    // modality: 1,
  };
}
