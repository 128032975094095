<p
  class="tableItemChip {{className}}"
  [ngClass]="{
    'tableItemChip--small': size === 'sm',
    'tableItemChip--big': size === 'lg',
    'tableItemChip--bold': bold
  }"
  [ngStyle]="border ? {'border': 'var(' + type + ') 4px solid', 'padding': '4px 1rem'} : {'background-color': 'var(' + type + ')'}"
  >
<ng-container *ngIf="date?.date">
  <ng-container *ngIf="value">
    {{value | date: date?.pipeTemplate}}
    </ng-container>
  <ng-container *ngIf="!value">
    {{'-'}}
    </ng-container>

  
</ng-container>
<ng-container *ngIf="!date?.date">
  {{value ?? '-'}}
</ng-container>

  
</p>
