import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  StaticUtilitiesService,
  SubjectGeneratorService,
  iOptionsCustomInput,
  iOptionsSelector,
  iResultHttp,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { GubrtService } from 'src/app/Services/Api/Gubrt.service';

@Component({
  selector: 'app-Bonificacion',
  templateUrl: './Bonificacion.component.html',
  styleUrls: ['./Bonificacion.component.scss'],
})
export class BonificacionComponent
  extends SubjectGeneratorService
  implements OnInit
{
  @Input() studentData: any = null;
  @Input() token: any = null;
  @Output() dataFromStepFour: EventEmitter<any> = new EventEmitter<any>();

  studentDataStepFour: any = null;
  fundae: any = null;
  objectToSend: any = {};

  opcionesBonificacionFundae: iOptionsSelector = {
    items: [
      { label: 'Sí, lo tramita IEM' },
      { label: 'Sí, lo tramita mi empresa' },
      { label: 'No lo sé todavía' },
      { label: 'No, no quiero bonificar' },
    ],
    append: true,
    placeholder: 'Bonificación FUNDAE',
    bindValue: 'label',
    bindLabel: 'label',
    clearable: false,
  };

  constructor(private gubrtSE: GubrtService) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['studentData']) {
      if (changes['studentData'].currentValue) {
        this.studentDataStepFour = changes['studentData'].currentValue;
        this.fundae = this.studentDataStepFour.enrollments.map(
          (enrollment) => enrollment.fundae
        );
      }
    }

    if (changes['token']) {
      if (changes['token'].currentValue) {
        this.objectToSend.token = changes['token'].currentValue;
      }
    }
  }

  emit() {
    let objectToPass: any = {
      fundae: this.fundae,
    };
    this.dataFromStepFour.emit(objectToPass);
  }

  sendToUpdate(value, key, option, index?) {
    switch (option) {
      case 'enrollment':
        this.objectToSend.enrollment = {};
        this.objectToSend.enrollment.id =
          this.studentDataStepFour.enrollments[index].id;
        this.objectToSend.enrollment[key] = value;
        this.update();
        delete this.objectToSend[key];
        delete this.objectToSend.enrollment.fundae;
        this.objectToSend.enrollment;
        break;
      default:
        break;
    }

    this.emit();
  }

  update() {
    const unsubSubject = this.createSubject();
    this.gubrtSE.editGubrtOldVersion(this.objectToSend);
    this.gubrtSE
      .getResultUpdate()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        //  this.refresh.emit(true);
        this.sendDataToSubject(unsubSubject);
      });
    this.gubrtSE
      .getResultUpdateError()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) return;
        // this.isDocumentLoading = false;
        window.open('/', '_self');
        StaticUtilitiesService.showError('No tienes permisos para estar aquí');
        this.sendDataToSubject(unsubSubject);
      });
  }
}
