import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventCalendarHandlerService {

  private static eventCalendarTypes = {
    2: 'Máster - Clases',
    3: 'Máster - Tutoria - Profesor',
    4: 'Máster - Tribunal',
    5: 'Máster - Otros',
    6: 'Curso - Clases',
    7: 'Incompany - Clases',
    8: 'Alquiler',
    9: 'Evento',
    10: 'Festivo',
    11: 'No lectivo',
    12: 'Curso - Clases',
    13: 'Curso - Otros',
    14: 'Incompany - Otros',
    16: 'Máster - Tutoria - Alumno',
    17: 'Máster - Tribunal - Alumno',
    18: 'Máster - Tribunal - Entregas'

  };

constructor() { }

  public static  getEventCalendarType(eventType: number) {
    return this.eventCalendarTypes[eventType];
  }

}
