import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'Promotions-Students',
  templateUrl: './Promotions-Students.component.html',
  styleUrls: ['./Promotions-Students.component.scss']
})
export class PromotionsStudentsComponent implements OnInit {

  @Input() students: Array<any> = [];
  @Input() filters: any = {};
  @Input() total: number = 0;
  @Input() navigateActive: boolean = true;
  @Input() changePage: boolean = false;
  @Input() isDownloadExcelStudents: boolean = false;
  @Input() isDataStudentLoaded: boolean = false;

  @Output() selectedStudents: any = new EventEmitter<any>();

  rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  allSelected: boolean = false;
  studentsSelected: Array<any> = [];

  displayedColumns: any[] = [
    { displayName: "Nombre", property: 'name' },
    { displayName: "Apellidos", property: 'surname' },
  ];

  constructor(private staticUtilitiesSE: StaticUtilitiesService) { }

  ngOnInit() {
    this.rolesAndPermissions = StaticUtilitiesService.getUserPermissionsAndRoles()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isDownloadExcelStudents']) { if (changes['isDownloadExcelStudents'].currentValue) this.exportexcel(); }
  }

  addSelected(formacion) {
    !this.studentsSelected.includes(formacion) ? this.studentsSelected.push(formacion) : this.studentsSelected.splice(this.studentsSelected.indexOf(formacion), 1);
    this.studentsSelected.length == this.students.length ? this.allSelected = true : this.allSelected = false;
    this.selectedStudents.emit(this.studentsSelected);
  }
  selectAll(event) {
    if (event.checked) {
      this.studentsSelected = [...this.students]
      this.allSelected = true;
    } else {
      this.studentsSelected = [];
      this.allSelected = false;
    }
    this.selectedStudents.emit(this.studentsSelected);
  }

  checkUncheckedColumns(res) {
    res.map(element => {
      if (!element.checked) {
        Array.from(document.querySelectorAll(".mat-column-" + element.realFilter)).map(column => {
          (column as HTMLElement).style.display = "none"
        })
      } else {
        Array.from(document.querySelectorAll(".mat-column-" + element.realFilter)).map(column => {
          (column as HTMLElement).style.display = "revert"
        })
      }
    })
  }

  goToStudentDetailView(id) {
    this.staticUtilitiesSE.goTo('alumnos/mis-alumnos/ficha-alumno/', slideNavigationTypes.slideToTop, { alumno: id })
  }

  // Descargar excel

  exportexcel(): void {
    let table = document.createElement("table");
    table.append((document.querySelector("table thead") as HTMLElement).cloneNode(true));
    table.querySelectorAll("thead th mat-icon").forEach((element) => {
      element.remove();
    });
    table.querySelector("thead th:first-of-type")?.remove();
    let rows = Array.from(document.querySelectorAll("table tbody tr "));
    let filteredRows = rows.filter((row) => {
      if(row.className === 'loaderLine' ) return null;
      return row.querySelector('mat-checkbox')!.getAttribute('ng-reflect-checked') === "true";
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append((document.querySelector("table tbody") as HTMLElement).cloneNode(true));
      table.querySelectorAll("tbody tr td:first-of-type").forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'AlumnosPromocion.xlsx');
  }

  checkRolesAndPermissions(){
    return this.rolesAndPermissions.roles.includes('ROLE_ADMIN') || this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') || this.rolesAndPermissions.permissions.includes(4) 
  }


}
