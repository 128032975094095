import { Component, OnInit } from '@angular/core';
import {
  StaticUtilitiesService,
  iResultHttp,
  iUnsubscribeDestroy,
} from '@quasar-dynamics/basic-designsystem';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { filter, takeUntil } from 'rxjs';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { GeneralLoaderService } from '@quasar-dynamics/basic-designsystem';
import { PromotionsDetailViewClass } from 'src/app/Shared/Classes/PromotionsDetailViewClass';
import { MasterService } from 'src/app/Services/Api/Master.service';
import { TutoringService } from 'src/app/Services/Api/Tutoring.service';
import { BillService } from 'src/app/Services/Api/Bill.service';
import { IPromotionDates } from 'src/app/Shared/Interfaces/Utils/iPromotionDates';
import { GetPermisionsService } from 'src/app/Services/Utils/GetPermisions.service';
import {
  IUserData,
  iUserData,
} from 'src/app/Shared/Interfaces/Utils/iUserData';

@Component({
  selector: 'Promotions-DetailView',
  templateUrl: './Promotions-DetailView.component.html',
  styleUrls: ['./Promotions-DetailView.component.scss'],
})
export class PromotionsDetailViewComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  isDownloadExcelStudents: boolean = false;

  dataStudent: any[] = [
    {
      id: 1,
      name: 'Alumno 1',
      surname: 'Apellido 1',
      email: ' a@a.com',
      phone: '123456789',
      modalityIds: [1, 2, 3],
      fundaeId: 1,
    },
  ];

  isMaster: boolean = false;
  isCurso: boolean = false;

  dataPromocionDetail: any = StaticDataHandlerService.getStudentsSkelleton();

  userData: iUserData = IUserData.getEmptyObject();

  params: any = null;

  studentsTotal: number = 0;

  toggleModel: number = 0;

  selectedProgrammes: any[] = [];

  extraClass: PromotionsDetailViewClass = new PromotionsDetailViewClass();

  toggleSubmenuElements: string[] = [
    'Información',
    'Fechas',
    'Alumnos',
    'Facturación',
    'Calendario',
    'Proyecto',
    'Tutorías',
  ];

  // Filters

  constructor(
    private staticUtilitiesSE: StaticUtilitiesService,
    private promotionSE: PromotionFormationService,
    private generalLoaderSE: GeneralLoaderService,
    private masterSE: MasterService,
    private tutoringSE: TutoringService,
    private billSE: BillService,
    private getPermisionsSE: GetPermisionsService
  ) {
    super();
  }

  async ngOnInit() {
    this.params = await this.getParams();
    this.getPromotionInfo();
    this.getPromotionDates();
    this.getPromotionStudents();
    this.getPermisionsSE.getDataFromLocalStorage().then((res) => {
      this.userData = res;
      this.clearElementsDependingOnPermisions(res);
    });
    if (this.isMaster) {
      this.getTribunalPromotion();
      this.getTutoringByPromotionId();
    }
  }

  /**
   * GETTERS AND SETTERS
   */
  getParams(): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = this.staticUtilitiesSE.getParams();
      if (params?.typeOf === 'master') {
        this.isMaster = true;
      }
      if (params?.typeOf === 'curso') {
        this.isCurso = true;
        this.toggleSubmenuElements = this.toggleSubmenuElements.filter(
          (el) => el !== 'Proyecto' && el !== 'Tutorías'
        );
      }
      resolve(params);
    });
  }

  /**
   * HANDLERS
   */

  successGetPromotionInfoHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.promotionInfoForView = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetPromotionDatesHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.promotionDates = IPromotionDates.getEmptyObject();
    this.extraClass.promotionDates = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetPromotionStudentsHandler(res: iResultHttp) {
    const { data, total } = res.data;
    this.extraClass.promotionStudent = data;
    this.extraClass.promotionStudentTotal = total;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTribunalPromotionHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.tribunalPromotion = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successgetTutoringByPromotionIdHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.tutoringPromotion = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetPromotionBillingHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.promotionBilling = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */

  onRefreshDates() {
    this.getPromotionDates();
  }
  onRefreshData() {
    this.getTribunalPromotion();
    this.getPromotionDates();
  }

  onToggleChange(event) {
    setTimeout(() => {
      this.toggleModel = Number(event);
    }, 1);
  }

  clearElementsDependingOnPermisions(userData: iUserData) {
    if (
      userData.permissions.includes(1) ||
      userData.permissions.includes(2) ||
      userData.roles.includes('ROLE_ADMIN') ||
      userData.roles.includes('ROLE_SUPER_ADMIN')
    ) {
      this.getPromotionBilling();
      return;
    } else {
      this.toggleSubmenuElements = this.toggleSubmenuElements.filter(
        (el) => el !== 'Facturación'
      );
    }
  }

  getIndexOfElement(element: string): number {
    return this.toggleSubmenuElements.indexOf(element);
  }

  /**
   * API CALLS
   */

  getPromotionInfo() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.getInfoDetailView(behaviorSubject, this.params.promotion);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetPromotionInfoHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getPromotionDates() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.getPromotionDates(behaviorSubject, this.params.promotion);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetPromotionDatesHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getPromotionStudents() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.getPromotionStudents(
      behaviorSubject,
      this.params.promotion,
      this.extraClass.promotionStudentsParams
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetPromotionStudentsHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getTribunalPromotion() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.masterSE.getPromotionTribunal(behaviorSubject, this.params.promotion);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetTribunalPromotionHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getTutoringByPromotionId() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.getTutoringByPromotion(
      behaviorSubject,
      this.params.promotion
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successgetTutoringByPromotionIdHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getPromotionBilling() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.billSE.getPromotionBilling(behaviorSubject, this.params.promotion);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetPromotionBillingHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
