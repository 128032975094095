<div class="documentacionGeneralContainer" *ngFor="let tipo of types">
  <div class="titulo" *ngIf="documentos.length !== 0 && isDataLoaded">{{tipo.name}}</div>
  <div class="documentos" skeleton [isDataLoaded]="isDataLoaded">
    <p class="noData" *ngIf="tipo.value.length == 0 && isDataLoaded">Todavía no hay documentos del tipo {{tipo.name}}
    </p>
    <div class="documento" *ngFor="let documento of tipo.value" (click)="openFile(documento.url)">
      <div class="image">
        <img src="/assets/Icons/General/pdf.svg" alt="">
      </div>
      <p>{{documento.name}}</p>
      <div class="coverDocument">
        <div class="icono">
          <mat-icon id="closeIcon" (click)="$event.stopPropagation();eliminarDocumentoPopup(documento)">close</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <btn classList="principalButton" (click)="uploadDocumento(tipo.name)">
    <ng-container>
      Subir {{tipo.name}}
    </ng-container>
  </btn>
</div>