<div class="fullView">
  <p class="fwBold">Método de pago</p>

  <p class="fs14">En este paso tan sólo debes seleccionar el método de pago, una vez finalices el proceso de matrícula, recibirás un email con indicaciones sobre próximos pasos según tu opción elegida.</p>

  
  <div class="buttonLine">
    <div class="button" [class.active]="buttonSelected === 1"
      (click)="buttonSelected = 1 ; sendToUpdate('Transferencia bancaria','paymentMethod','enrollment')">
      <img src="/assets/Images/Automatricula/lock.png" alt="Lock">
      <p class="fwBold">Transferencia bancaria</p>
    </div>
    <div class="button" [class.active]="buttonSelected === 2"
      (click)="buttonSelected = 2 ; sendToUpdate('Domiciliación bancaria','paymentMethod','enrollment')">
      <img src="/assets/Images/Automatricula/crazyMoney.png" alt="Lock">
      <p class="fwBold">Domiciliación bancaria</p>
    </div>
  </div>
  <div class="buttonLine" *ngIf="enrrollmentType === 'Internacional'">
    <div class="button" [class.active]="buttonSelected === 3" (click)="buttonSelected = 3 ; sendToUpdate('Flywire','paymentMethod','enrollment')">
      <img src="/assets/Images/Automatricula/flywire.png" alt="Lock">
      <p class="fwBold">Flywire</p>
    </div>
    <div class="button" [class.active]="buttonSelected === 4"
      (click)="buttonSelected = 4; sendToUpdate('Tarjeta bancaria','paymentMethod','enrollment')">
      <img src="/assets/Images/Automatricula/tarjeta.png" alt="Lock">
      <p class="fwBold">Tarjeta bancaria</p>
    </div>
  </div>

  


</div>