<fullContainer>
  <ng-container>
    <header></header>
    <div class="subHeader">
      <toggleSubmenu
        class="secondary"
        [elements]="['Alumnado activo', 'Alumni']"
        (change)="selectAPICall()"
        (onValueChange)="onToggleChange($event)"
        [(ngModel)]="optionSubmenuSelected"
      ></toggleSubmenu>
      <div class="buttonContainer">
        <btn classList="principalButton" (click)="downloadExcel()">
          <ng-container> Descargar listado en .XLS </ng-container>
        </btn>
        <btn classList="principalButton" (click)="goToCreateStudent()">
          <ng-container> Añadir alumnos </ng-container>
        </btn>
        <btn
          classList="onlyIcon gearButton"
          class="settings"
          (click)="openPreferencesPopup()"
        >
          <ng-container>
            <mat-icon>settings</mat-icon>
          </ng-container>
        </btn>
      </div>
    </div>
    <div class="filters">
      <customSearcher
        ngDefaultControl
        [placeHolder]="placeholdersArray[optionSubmenuSelected]"
        [(ngModel)]="filter"
        (change)="getFilter(filter, 'genericFilter')"
      ></customSearcher>
      <div
        (click)="displayFilters()"
        class="filterButton"
        *ngIf="!isFilterDisplayed"
      >
        <span>Filtrar alumnos</span>
        <mat-icon>filter_list</mat-icon>
      </div>
      <div
        (click)="displayFilters()"
        class="filterButton active"
        *ngIf="isFilterDisplayed"
      >
        <span>Filtrar alumnos</span>
        <mat-icon>clear</mat-icon>
      </div>
    </div>
    <div class="filterContent" *ngIf="isFilterDisplayed">
      <div class="topBar">
        <div class="addProducts">
          <p class="fwBold">Añade productos al filtrado de la lista</p>
          <div class="lineaDeFiltros">
            <div class="widthSelector primero">
              <selector
                class="roundedSelector"
                [options]="cursosSelectorOptions"
                [(ngModel)]="formacionSelector"
                (change)="callCourseType(formacionSelector)"
              ></selector>
            </div>
            <div class="widthSelector">
              <selector
                class="roundedSelector"
                [options]="optionsSecondSelector"
                [(ngModel)]="formacionSelectorDos"
                (change)="callPromotion(formacionSelectorDos)"
                *ngIf="formacionSelector"
              ></selector>
            </div>
            <div class="widthSelector">
              <selector
                class="roundedSelector"
                [options]="optionsThirdSelector"
                [(ngModel)]="formacionSelectorTres"
                *ngIf="formacionSelector && formacionSelectorDos"
              ></selector>
            </div>
            <btn
              classList="generalbutton filterButton"
              (click)="addNewFilterLine()"
              *ngIf="
                formacionSelector &&
                formacionSelectorDos &&
                formacionSelectorTres
              "
              ><mat-icon>add</mat-icon></btn
            >
          </div>
        </div>
        <div class="tipoandyear">
          <div class="year">
            <p class="fwBold">Año académico</p>
            <selector
              [options]="yearSelectorOptions"
              (change)="getFilter($event, 'year')"
            ></selector>
          </div>
        </div>
      </div>
      <div class="bottomBar">
        <div
          class="filterLine"
          *ngFor="let line of filterLineArray; let indexLine = index"
        >
          <btn
            classList="genericButton verySmall trashLineFilterButton"
            (click)="deleteLineFilter(line.id)"
            ><mat-icon class="deleteButtonFilterLine">delete</mat-icon></btn
          >
          <div class="selectoresFilterLine">
            <!-- <selector [options]="cursosSelectorOptions" [(ngModel)]="line.type"></selector>
          <selector [options]="optionsSecondSelector" [(ngModel)]="line.formation"></selector> -->
            <p class="blockLineFilter">
              {{ line.type }}
            </p>
            <p class="blockLineFilter">
              {{ line.formation }}
            </p>
            <p class="fwBold">
              {{ line.promotions.length == 1 ? "Edición: " : "Ediciones: " }}
            </p>
            <span
              class="seleccionesPromociones"
              *ngFor="let promotion of line.promotions"
            >
              {{ promotion.edition }}
              <!-- <mat-icon (click)="deletePromotionFilter(promotion.id, indexLine)">close</mat-icon> -->
            </span>
          </div>
        </div>
      </div>
    </div>

    <gestionUsuariosAlumnosListadoTabla
      *ngIf="optionSubmenuSelected == 0"
      [alumnos]="alumnos"
      [filters]="filters"
      [total]="total"
      [openPrerferencesAlumnoPopup]="openPrerferencesAlumnoPopup"
      (changeAlumnoBooleanToFalse)="changeAlumnoBooleanToFalse($event)"
      [downloadExcelFile]="downloadExcelFile"
      (changeExcelBooleanToFalse)="changeExcelBooleanToFalse($event)"
    ></gestionUsuariosAlumnosListadoTabla>
    <gestionUsuariosAlumnosListadoTablaAlumni
      *ngIf="optionSubmenuSelected == 1"
      [filters]="filtersAlumni"
      (changeExcelBooleanToFalseAlumni)="
        changeExcelBooleanToFalseAlumni($event)
      "
      (changeAlumniBooleanToFalse)="changeAlumniBooleanToFalse($event)"
      [alumnos]="alumni"
      [total]="totalAlumni"
      [openPrerferencesAlumniPopup]="openPrerferencesAlumniPopup"
      [downloadExcelFileAlumni]="downloadExcelFileAlumni"
    >
    </gestionUsuariosAlumnosListadoTablaAlumni>
    <pagination-controls
      [autoHide]="true"
      previousLabel="Anterior"
      nextLabel="Siguiente"
      *ngIf="optionSubmenuSelected === 0"
      (pageChange)="pageChange($event)"
    ></pagination-controls>
    <pagination-controls
      [autoHide]="true"
      previousLabel="Anterior"
      nextLabel="Siguiente"
      *ngIf="optionSubmenuSelected === 1"
      (pageChange)="pageChangeAlumni($event)"
    ></pagination-controls>
  </ng-container>
</fullContainer>
