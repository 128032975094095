import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  protected _setStudentSettings = new BehaviorSubject<any>(null);
  getResultsetStudentSettings() {return this._setStudentSettings}
  clearResultsetStudentSettings() {this._setStudentSettings.next(null)}
  
  constructor(private http: HttpClient) {
    super();
  }

setStudentSettings(options){
  this.http.post(this.urlApi+"/api/settings", options, this.getHeader()).subscribe({
    next: (data:any) => {
      this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
      this.sendNextResult('resultUpdate', this.res);
    },
    error: (error:any) => {
      this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
      this.sendNextResult('resultUpdateError', this.res, { method: this.setStudentSettings, args: [options] });
      this.checkStatusError(error);
    },
  });
}

getAllSettings(queryParams: any){
  this.http.get(this.urlApi + '/api/settings/user' + this.optionsGet(queryParams), this.getHeader()).subscribe({
    next: (data:any) => {
      let normalizedPricePerHour = data ;
      this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedPricePerHour});
      this.sendNextResult('result', this.res);
    },
    error: (error:any) => {
      this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
      this.sendNextResult('resultError', this.res, { method: this.getAllSettings, args: [queryParams] });
      this.checkStatusError(error);
    },
  });
}

}
