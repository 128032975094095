import { Component, OnInit } from '@angular/core';
import {
  PopupService,
  iDate,
  iOptionsSelector,
  iResultHttp,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AddStudentEnrollmentPopupComponent } from 'src/app/Popups/AddPopup/AddStudentEnrollment-Popup/AddStudentEnrollment-Popup.component';
import { EnrollmentService } from 'src/app/Services/Api/Enrollment.service';
import { FormationService } from 'src/app/Services/Api/Formation.service';
import { PipedriveService } from 'src/app/Services/Api/Pipedrive.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iEnrollmentFilter } from 'src/app/Shared/Interfaces/iEnrollment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-GestionUsuarios-Matriculaciones',
  templateUrl: './GestionUsuarios-Matriculaciones.component.html',
  styleUrls: ['./GestionUsuarios-Matriculaciones.component.scss'],
})
export class GestionUsuariosMatriculacionesComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  placeholdersArray: string[] = [
    'Busca por nombre de la formación, nombre y apellidos del alumno, email, teléfono o fecha de última actualización',
    'Busca por nombre y apellido del alumno, email o teléfono',
  ];

  optionSubmenuSelected: number = 0;
  filter: string = '';
  isFilterDisplayed: boolean = false;
  crmPipeDrive: any[] = [];
  filters: iEnrollmentFilter = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
    typeOf: 'master',
  };
  phasesFilter = {
    typeOf: 'master',
  };

  selectorFilter = {
    typeOf: 'master',
  };
  selectorFormationOptions: iOptionsSelector = {
    items: [],
    append: true,
    clearable: true,
    search: true,
    placeholder: 'Selecciona la formación',
    bindLabel: 'name',
    bindValue: 'id',
  };
  objectToPass: any = {};
  selectorPromotionOptions: iOptionsSelector = {
    items: [],
    append: true,
    clearable: true,
    search: true,
    placeholder: 'Selecciona la edición',
    bindLabel: 'edition',
    bindValue: 'id',
  };
  selectorPhaseOptions: iOptionsSelector = {
    items: [
      { id: 1, name: 'crm', label: 'CRM' },
      { id: 2, name: 'pre_enrolled', label: 'Pre-matricula' },
      { id: 3, name: 'enrolled', label: 'Matriculado oficial' },
      { id: 4, name: 'to_revise', label: 'Pendiente de revisión' },
      { id: 5, name: 'pending', label: 'Pendiente de verificación' },
      { id: 6, name: 'lost', label: 'Perdida' },
    ],
    append: true,
    clearable: true,
    search: true,
    placeholder: 'Selecciona la fase',
    bindValue: 'name',
  };
  dataPhasesEnrollment: any = null;
  total = 0;
  matriculacionesLista: Array<any> = [];
  downloadExcelFile: boolean = false;
  constructor(
    private enrollmentSE: EnrollmentService,
    private popupSE: PopupService,
    private pipeDriveSE: PipedriveService,
    private formationSE: FormationService,
    private promotionSE: PromotionFormationService
  ) {
    super();
  }

  ngOnInit() {
    this.rolesAndPermissions =
      StaticUtilitiesService.getUserPermissionsAndRoles();
    this.getAllEnrollments();
    this.getEnrollmentsPhases();
    this.getCoursePD();
    this.getMasterPD();
    this.getAllFormationsSelector();
  }

  /**
   * HANDLERS
   */

  successGetAllFormationsSelectorHandler(res: iResultHttp) {
    let { data } = res;
    this.selectorFormationOptions.items = data;
  }

  successGetAllPromotionsForFormationHandler(res: iResultHttp) {
    let { data } = res;
    this.selectorPromotionOptions.items = data;
  }

  /**
   * GETTERS & SETTERS
   */
  getSearcher(filter) {
    switch (this.optionSubmenuSelected) {
      case 0:
        if (!filter) {
          delete this.phasesFilter['genericFilters'];
          this.getAllEnrollments();
          return;
        }
        this.phasesFilter['genericFilter'] = filter;
        this.getEnrollmentsPhases();
        break;
      case 1:
        if (!filter) {
          delete this.filters['genericFilters'];
          this.getAllEnrollments();
          return;
        }
        this.filters['genericFilter'] = filter;
        this.getAllEnrollments();
        break;
    }
  }

  getFilter(filter, key) {
    if (this.optionSubmenuSelected == 0) {
      if (!filter) {
        delete this.phasesFilter[key];
        this.getEnrollmentsPhases();
        return;
      }
      this.phasesFilter[key] = filter;
      this.getEnrollmentsPhases();
    }
    if (this.optionSubmenuSelected == 1) {
      if (!filter) {
        delete this.filters[key];
        this.getAllEnrollments();
        return;
      }
      this.filters[key] = filter;
      this.getAllEnrollments();
    }
  }

  getFormationAndPromotion(filter, key) {
    if (!filter) {
      delete this.filters[key];
      this.objectToPass.id = 0;
      this.getAllEnrollments();
      return;
    }
    this.filters[key] = filter;
    this.getAllEnrollments();
    this.objectToPass.id = filter;
    this.getAllPromotionsForFormation(this.objectToPass.id);
  }

  /**
   * FUNCTIONALITY
   */

  pageChange(event) {
    this.filters.num_pagina = event;
    this.getAllEnrollments();
  }

  downloadExcel() {
    this.downloadExcelFile = true;
    setTimeout(() => {
      this.downloadExcelFile = false;
    }, 1500);
  }

  refreshData(event) {
    if (event) {
      this.crmPipeDrive = [];
      setTimeout(() => {
        this.getEnrollmentsPhases();
        this.getCoursePD();
        this.getMasterPD();
      }, 200);
    }
  }
  refreshTabla(event) {
    if (event) {
      this.getAllEnrollments();
    }
  }

  phaseDisplay(phase: string) {
    switch (phase) {
      case 'pre_enrolled':
        return 'Pre Matrícula';
      case 'to_revise':
        return 'Pendiente de revisión';
      case 'pending':
        return 'Pendiente de verificación';
      case 'enrolled':
        return 'Matriculado oficial';
      case 'lost':
        return 'Perdida';
      default:
        return 'No definido';
    }
  }

  displayFilters() {
    this.isFilterDisplayed = !this.isFilterDisplayed;
  }
  openAddStudentPopup(type: string) {
    this.popupSE.openPopup(AddStudentEnrollmentPopupComponent, { type: type });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) return;
        this.getAllEnrollments();
        this.getEnrollmentsPhases();
        this._unsubInd2.next('');
      });
  }

  checkRolesAndPermissions() {
    return (
      this.rolesAndPermissions.roles.includes('ROLE_ADMIN') ||
      this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') ||
      this.rolesAndPermissions.permissions.includes(6)
    );
  }

  onToggleChange(event) {
    setTimeout(() => {
      this.optionSubmenuSelected = Number(event);
    }, 1);
  }

  /**
   * API CALLS
   */

  getAllEnrollments() {
    this.enrollmentSE.getAll(this.filters);
    this.enrollmentSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) return;
        let { data, total } = res.data;
        this.matriculacionesLista = data.map((item) => {
          return {
            ...item,

            phase: this.phaseDisplay(item?.phase),
            lastPhaseUpdate: item?.lastPhaseUpdate
              ? iDate
                  .javascriptConvert(new Date(item?.lastPhaseUpdate))
                  .toStringDate('EU')
              : '-',
          };
        });
        this.total = total;
        this._unsubInd.next('');
      });
    this.enrollmentSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getEnrollmentsPhases() {
    this.enrollmentSE.getEnrollmentPhases(this.phasesFilter);
    this.enrollmentSE
      .getResultEnrollmentPhases()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) return;
        let { data } = res;

        this.dataPhasesEnrollment = null;
        this.dataPhasesEnrollment = data;
        this._unsubInd2.next('');
      });
    this.enrollmentSE
      .getResultEnrollmentPhasesError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getCoursePD() {
    this.pipeDriveSE.getCoursePD();
    this.pipeDriveSE
      .getResultCoursePipeDrive()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res) => {
        if (!res) return;
        let { data } = res;
        this.crmPipeDrive.push(...data);
        this._unsubInd4.next('');
      });
    this.pipeDriveSE
      .getResultCoursePipeDriveError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getMasterPD() {
    this.pipeDriveSE.getMasterPD();
    this.pipeDriveSE
      .getResultMasterPipeDrive()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) return;
        let { data } = res;
        this.crmPipeDrive.push(...data);
        this._unsubInd3.next('');
      });
    this.pipeDriveSE
      .getResultMasterPipeDriveError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getAllFormationsSelector() {
    this.formationSE.getAllFormationsForSelectorOV(this.selectorFilter);
    this.formationSE
      .getResultAllFormationListOV()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res: iResultHttp) => {
        if (!res) return;
        this.successGetAllFormationsSelectorHandler(res);
        this._unsubInd5.next('');
      });
  }

  getAllPromotionsForFormation(id: number) {
    this.promotionSE.getPromotionsByFormationId(id);
    this.promotionSE
      .getResultPromotionsByFormationId()
      .pipe(takeUntil(this._unsubInd6))
      .subscribe((res: iResultHttp) => {
        if (!res) return;
        this.successGetAllPromotionsForFormationHandler(res);
        this._unsubInd6.next('');
      });
  }
}
