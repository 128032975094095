<div class="tableContainer">
  <table class="mainTable" >
    <thead class="thead">
      <tr class="tr">
        <th class="th"> <mat-checkbox [checked]="allSelected" (change)="selecteAll($event)"></mat-checkbox></th>
        <th class="th" *ngFor="let item of displayedColumns"
          [ngClass]="{justifyContentFlexStart:item?.displayName === 'Nombre', estadoHeaderTable:item?.displayName === 'Estado'}">
          {{item.displayName}}  </th>
      </tr>
    </thead>
    <div class="loaderLine">
      <loaderIcon [class.active]="!isDataLoaded"></loaderIcon >
      </div>
    <tbody class="tbody" *ngIf="isDataLoaded">
      <div class="noItems" *ngIf="incompanies.length == 0">
        No hay items que mostrar
      </div>
      <tr class="tr" (click)="onRowClick(incompany)" *ngFor="let incompany of incompanies | paginate:{
      itemsPerPage: filters.num_devoluciones,
      currentPage: filters.num_pagina,
      totalItems: total
    }" >
        <td class="td"> <mat-checkbox (click)="$event.stopPropagation()" (change)="addSelected(incompany)"
            [checked]="incompaniesSelected.includes(incompany)"></mat-checkbox></td>

        <td class="td" *ngFor="let column of displayedColumns; let index = index;"
          [ngClass]="{fwBold: column?.property === 'name',justifyContentFlexStart:column?.property === 'name' ,estadoTable:column?.property === 'state',dFlexGap1em:column?.property === 'enrollments' }">
          <p
            [ngClass]="{timeBox: column?.property === 'startDate' || column?.property === 'endDate', estadoTabla: column?.property === 'state', formacionTabla: column?.property === 'enrollments', estadoTablaCursando:column?.property === 'state' && incompany.state == 'cursando' }">
            {{incompany[column?.property] ?? '-'}} </p> <span
            *ngIf="column?.property === 'enrollments' && incompany?.extraEnrollments > 0"
            class="plusEnrollments">+{{incompany?.extraEnrollments}} </span>
        </td>
      </tr>
    </tbody>
  </table>

</div>