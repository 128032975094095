import { Component, OnInit } from '@angular/core';
import { StaticUtilitiesService, iResultHttp, iStaticUtilities, iUnsubscribeDestroy, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { LoginService } from 'src/app/Services/Api/Login.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-HeOlvidadoMiContrasena',
  templateUrl: './HeOlvidadoMiContrasena.component.html',
  styleUrls: ['./HeOlvidadoMiContrasena.component.scss']
})
export class HeOlvidadoMiContrasenaComponent extends iUnsubscribeDestroy implements OnInit {

  isLogging: boolean = false;

  data: any = {
    email:null
  };
  regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  disabled: boolean = true;
  emailCorrect: boolean = false;


  constructor(private loginSE: LoginService, private staticUtilitiesSE:StaticUtilitiesService) {
    super();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getButtonSize();
  }


  /**
   * FUNCTIONALITY
   */

  checkEmail(event){
    if(!this.regex.test(event)){
      StaticUtilitiesService.showError("El formato de email no es válido")
      this.emailCorrect = false;
      this.checkDisabled();
    }else{
      this.emailCorrect = true;
      this.checkDisabled();
    }
  }

  checkDisabled(){
    if(this.emailCorrect){
      this.disabled = false;
    }else{
      this.disabled = true;
    }
  }

  getButtonSize() {
    let input = document.querySelector(".customInputLogin")!;
    let inputComputedStyle = window.getComputedStyle(input);
    let inputSize = parseFloat(inputComputedStyle.getPropertyValue("width"));
    (document.querySelector('#btn')! as HTMLElement).style.width = inputSize + 'px';
  }

  setMeLocalStorage(data){
    let stringified = JSON.stringify(data);
    let cripted = iStaticUtilities.simpleCrypt(stringified);
    localStorage.setItem('usr', cripted);

  }

  /**
   * API CALLS
   */  

  sendRecoveryToken(){
    this.loginSE.recoverAccount(this.data.email);
    this.loginSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Si su correo se encuentra en nuestro sistema le habremos enviado un correo para recuperar su contraseña');
      this._unsubInd.next('')
    })
    this.loginSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }
  
}

