import { IOptionCustomTextArea } from '@quasar-dynamics/basic-designsystem';
import { iOptionsSelector } from '@quasar-dynamics/basic-designsystem';
import { StaticSelectorHandlersService } from 'src/app/Services/Utils/StaticSelectorHandlers.service';
import { iChipSelectorOptions } from '../Interfaces/iChipSelectorOptions';
import { iEventTypesToRedirect } from 'src/app/Services/Utils/EventTypes.service';

export class EventCalendarClass {
  
  canEdit: boolean = true;
  isEditing: boolean = true;

  redirectTo:iEventTypesToRedirect | null = null;
  
  teacherSelectorOptions: iOptionsSelector = {
    items: [
    ],
    multiple: {
      
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 3,
      multipleTextReplacer: 'Varios profesores dos',
    },
    placeholder: ' profesores',
    search: true,
    bindLabel: 'name',
    bindValue: 'id',
    clearable: false,
    classList: 'event',
    readonly: true,

  };

  companySelectorOptions: iOptionsSelector = {
    items: [

    ],
    
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Empresa',
    search: true,
    clearable: false,
    classList: 'event',
    readonly: true,

  };
  formationSelectorOptions: iOptionsSelector = {
    items: [

    ],
    
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Formación',
    search: true,
    clearable: false,
    classList: 'event',
    readonly: true,

  };
  editionSelectorOptions: iOptionsSelector = {
    items: [

    ],
    
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Edición',
    search: true,
    clearable: false,
    classList: 'event',
    readonly: true,

  };

  placeSelectorOptions: iOptionsSelector = {
    items: [

    ],
    
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Aula',
    search: true,
    clearable: false,
    classList: 'event',
    readonly: true,
  };

  sedeSelectorOptions: iOptionsSelector = {
    items: [

    ],
    bindValue: 'id',
    bindLabel: 'name',
    placeholder: 'Sede',
    search: true,
    clearable: false,
    classList: 'event',
    readonly: true,

  };

  modalitySelectorOptions: iOptionsSelector = {
    items: [

    ],
    bindValue: 'id',
    bindLabel: 'name',
    placeholder: 'Modalidad',
    search: true,
    clearable: false,
    classList: 'event',
    readonly: true,

  };
  studentSelectorOptions: iOptionsSelector = {
    items: [

    ],
    bindValue: 'id',
    bindLabel: 'name',
    placeholder: 'Estudiante',
    search: true,
    clearable: false,
    classList: 'event',
    readonly: true,

  };

  ngModels: any = {
    observations: '',
    teacher: null,
    company: null,
  };

  // Chip selector options
  chipSelectorOptions:iChipSelectorOptions = {
    items:[],
    bold: false,
    size: 'sm',
    readonly: false,
    bindValue: 'id',

  };

  // Custom text area options
  ovservationsCustomTextAreaOptions:IOptionCustomTextArea = {
    cols: 20,
    rows: 3,
    placeholder: 'Observaciones',
    classList: 'event',
  };
  sedeCustomTextAreaOptions:IOptionCustomTextArea = {
    cols: 20,
    rows: 3,
    placeholder: 'Sede de impartición',
    classList: 'event',
  };
  locationCustomTextAreaOptions:IOptionCustomTextArea = {
    cols: 20,
    rows: 3,
    placeholder: 'Ubicación',
    classList: 'event',
  };

  toggleReadOnly(boolean:boolean){
    const readonly = !boolean;
    this.teacherSelectorOptions.readonly = readonly;
    this.companySelectorOptions.readonly = readonly;
    this.placeSelectorOptions.readonly = readonly;
    this.sedeSelectorOptions.readonly = readonly;
    this.chipSelectorOptions.readonly = readonly;
    this.modalitySelectorOptions.readonly = readonly;
    this.studentSelectorOptions.readonly = readonly;
    this.formationSelectorOptions.readonly = readonly;
    this.editionSelectorOptions.readonly = readonly;
  }
}
