import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { iOptionsSelector } from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'multiple-selector-column',
  templateUrl: './multiple-selector-column.component.html',
  styleUrls: ['./multiple-selector-column.component.scss'],
})
export class MultipleSelectorColumnComponent implements OnInit {
  private _isEditing: { boolean: boolean } = { boolean: false };
  private _model: number[] = [1, 2, 3];
  private _options: iOptionsSelector = {
    items: [],
    bindLabel: 'name',
    bindValue: 'id',
    search: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      maxSelectedItems: 100,
      hideSelected: false,
      multipleLimitReplacer: 100,
    },
    append: true,
  };
  labelItemArray: string[] = [];

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() get options() {
    return this._options;
  }
  set options(value: iOptionsSelector) {
    this._options = value;
    setTimeout(() => {
      this.getLabelItem(this.model);
    }, 1000);
  }

  @Input() get model() {
    return this._model;
  }
  set model(value: number[]) {
    this._model = value;
    this.getLabelItem(value);
  }
  name: string = Math.random().toString(36).substring(7);

  constructor() {}

  ngOnInit() {}

  sendNgModel(event) {
    this.change.emit(event);
    this.getLabelItem(event);
  }

  getLabelItem(idsArray: number[]) {
    this.labelItemArray = [];
    idsArray.forEach((id, index) => {
      if (
        this._options?.bindLabel &&
        this._options?.bindValue &&
        this._options?.items.length > 0
      ) {
        this.labelItemArray.push(
          this._options.items.find(
            (itemFromOptions) =>
              itemFromOptions[this._options?.bindValue!] === id
          )[this._options?.bindLabel] ?? ''
        );
      }
    });
    this.labelItemArray = [...new Set(this.labelItemArray)];
  }

  labelsTrackBy(index, item) {
    return index;
  }
}
