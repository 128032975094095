import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StaticSelectorHandlersService {
  private static _eventStatus: { name: string; color: string, id:number }[] = [
    {
      name: 'pendiente',
      color: '--greyState',
      id: 0
    },
    {
      name: 'enviada',
      color: '--yellowState',
      id: 1
    },
    {
      name: 'rechazada',
      color: '--redState',
      id: 2
    },
    {
      name: 'confirmada',
      color: '--greenState',
      id: 3
    },
    {
      name: 'conflicto',
      color: '--orangeState',
      id: 4
    },
    {
      name: 'finalizada',
      color: '--turquoiseState',
      id: 5
    },
  ];

  constructor() {}

  public static get eventStatus(): { name: string; color: string, id:number }[] {
    return StaticSelectorHandlersService._eventStatus;
  }
}
