import { Component, OnInit } from '@angular/core';
import { iDate, iResultHttp, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { EnrollmentService } from 'src/app/Services/Api/Enrollment.service';
import { IncompanyService } from 'src/app/Services/Api/Incompany.service';
import { PipedriveService } from 'src/app/Services/Api/Pipedrive.service';
import { ProposalService } from 'src/app/Services/Api/Proposal.service';
import { FormalizedProposalService } from 'src/app/Services/Api/formalizedProposal.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iEnrollmentFilter } from 'src/app/Shared/Interfaces/iEnrollment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-GestionUsuarios-Incompanies',
  templateUrl: './GestionUsuarios-Incompanies.component.html',
  styleUrls: ['./GestionUsuarios-Incompanies.component.scss']
})
export class GestionUsuariosIncompaniesComponent extends iUnsubscribeDestroy implements OnInit {

  rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  optionSubmenuSelected: number = 1;
  downloadExcelFile: boolean = false;
  isFilterDisplayed: boolean = false;
  isDataLoaded: boolean = false;
  // Pipeline booleans
  isPropuestaFormalLoaded: boolean = false;
  isCRMIncompanies:boolean = false;
  isThirdColumn:boolean = false;

  filter: string = "";
  incompanies: any[] = [];
  total: number = 0;
  // Filters
  filters: iEnrollmentFilter = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  }
  filterFormalizedProposal: iEnrollmentFilter = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  }
  filterThirdColumn: any = {
  }
  // For pipeline
  incompanyCRM: any = [];
  formalizedProposals: any = [];
  thirdColumnIncompanies: any = [];

  constructor(private proposalSE: ProposalService, private incompanySE: IncompanyService, private enrollmentSE: EnrollmentService, private pipedriveSE: PipedriveService, private formalizedProposalSE: FormalizedProposalService) {
    super();
  }

  ngOnInit() {
    this.rolesAndPermissions = StaticUtilitiesService.getUserPermissionsAndRoles()
    this.getAllIncompanies();
    this.getIncompaniesCRM();
    this.getFormalizedProposals();
    this.getThirdColumn();
  }

  /**
   * FUNCTIONALITIES
   */

  downloadExcel() {
    this.downloadExcelFile = true;
     
    setTimeout(() => {
      this.downloadExcelFile = false;
    }, 1500);
  }

  openAddIncompany() { }

  pageChange(event) {
    this.filters.num_pagina = event;
    this.getAllIncompanies();
  }

  getFilter(filter, key) {
     
    switch (this.optionSubmenuSelected) {
      case(0):
        if (!filter) { delete this.filters[key]; this.getAllIncompanies(); return };
        this.filters[key] = filter;
        this.getAllIncompanies();
        break;
      case(1):
        if (!filter) { delete this.filterFormalizedProposal[key]; delete this.filterThirdColumn[key] ;this.getFormalizedProposals();this.getThirdColumn();return };
        this.filterFormalizedProposal[key] = filter;
        this.filterThirdColumn[key] = filter;
        this.getFormalizedProposals();
        this.getThirdColumn();
        break;
      
    }
  }

  checkRolesAndPermissions(){
    return this.rolesAndPermissions.roles.includes('ROLE_ADMIN') || this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') || this.rolesAndPermissions.permissions.includes(6) 
  }

  displayFilters() {
    this.isFilterDisplayed = !this.isFilterDisplayed;
  }

  refresh(event){
    if(event)this.ngOnInit();
  }

  /**
   * API CALLS
   */

  getAllIncompanies() {
    this.enrollmentSE.getEnrollmentsIncompaniesList(this.filters);
    this.enrollmentSE.getResultGetEnrollmentsIncompaniesList().pipe(takeUntil(this._unsubInd)).subscribe((res: iResultHttp) => {
      if (!res) return;
      let { data, total } = res.data;
       
       
      this.incompanies = data.map(incompany => { return { ...incompany, lastStateUpdate: iDate.javascriptConvert(new Date(incompany.lastStateUpdate)).toStringDate('EU'), formacion: incompany.promotionFormation?.formation?.name ?? 'Sin formación asignada', empresa: incompany.studentsGroup?.company?.name ?? 'Sin empresa asignada', name: incompany.studentsGroup?.name ?? 'Sin grupo asignado' } });
      this.total = total;
      this.isDataLoaded = true;
      this._unsubInd.next('');
    });

    this.enrollmentSE.getResultGetEnrollmentsIncompaniesListError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  // Pipeline

  getIncompaniesCRM() {
    this.pipedriveSE.getIncompanyPD();
    this.pipedriveSE.getResultIncompanyPipeDrive().pipe(takeUntil(this._unsubInd2)).subscribe((res: iResultHttp) => {
      if (!res) return;
      let { data } = res;
       
      this.incompanyCRM = data;
      this.isCRMIncompanies = true;
      this._unsubInd2.next('');
    });
    this.pipedriveSE.getResultIncompanyPipeDriveError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    });
  }

  getFormalizedProposals() {
    this.formalizedProposalSE.getList(this.filterFormalizedProposal);
    this.formalizedProposalSE.getResultGetFormalizedProposal().pipe(takeUntil(this._unsubInd3)).subscribe((res: iResultHttp) => {
      if (!res) return;
      let { data } = res;
       
      this.formalizedProposals = data;
      this.isPropuestaFormalLoaded = true;
      this._unsubInd3.next('');
    }
    );
    this.formalizedProposalSE.getResultGetFormalizedProposalError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    }
    );
  }

  getThirdColumn() {
    this.enrollmentSE.getEnrollmentIncompaniesPhases(this.filterThirdColumn);
    this.enrollmentSE.getResultGetEnrollmentsIncompaniesThirdColumn().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
      if (!res) return;
      let { data } = res;
       
      this.thirdColumnIncompanies = data;
      this.isThirdColumn = true;
      this._unsubInd4.next("");
    })
    this.enrollmentSE.getResultGetEnrollmentsIncompaniesThirdColumnError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (!res) return;
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }


}
