import { iEditableBoxComponentOptions } from "../Components/Editablebox/Editablebox.component";
import { headerData } from "../Components/main-table/main-table.component";
import { TerminarButtonComponentComponent } from "../Components/terminar-button-component/terminar-button-component.component";
import { IPromotionBill, iPromotionBill } from "../Interfaces/Utils/iPromotionBill";
import { iChipSelectorOptions } from "../Interfaces/iChipSelectorOptions";

export class DetailViewTeachersClass {

    // promotionBilling
    promotionBilling:iPromotionBill = IPromotionBill.getEmptyObject();

    billingBills:iPromotionBill['bills'] = IPromotionBill.getEmptyObject().bills;
    billingTeachers:iPromotionBill['teachers'] = IPromotionBill.getEmptyObject().teachers;

    today = new Date();

    // chipSelectorOptions
    chipSelectorOptions:iChipSelectorOptions = {
        items:[],
        bold:true,
        readonly:true,
        size:'md',
        bindValue:'name',
    }

    // component options
    componentOptions:iEditableBoxComponentOptions = {
        component:TerminarButtonComponentComponent,
        callback: () => {}
    }

    // headerDatas

    headerDataTableFacturas:headerData = [
        {
            variant:'bold',
            display:'Profesor',
            key:'teacher',
            alignment:'start',
            className:'truncateText maxWidth-200',
            width:'200px',
        },
        {
            variant:'bold',
            display:'Concepto',
            key:'concept',
            alignment:'start',
            className:'truncateText maxWidth-250',
            width:'250px',
        },
        {
            variant:'bold',
            display:'Importe',
            key:'amount',
            alignment:'center',
            className:'truncateText maxWidth-150',
            width:'150px',
            euros:true,
        },
        {
            variant:'bold',
            display:'Nº factura',
            key:'number',
            alignment:'center',
            className:'truncateText maxWidth-100',
            width:'100px',
        },
        {
            variant:'bold',
            display:'Fecha factura',
            key:'date',
            alignment:'center',
            className:'truncateText maxWidth-150',
            width:'150px',
            date:{
                pipeTemplate:'dd/MM/yyyy',
                date:true,
            }
        },
        {
            variant:'bold',
            display:'Fecha de pago',
            key:'paymentDate',
            alignment:'center',
            className:'truncateText maxWidth-150',
            width:'150px',
            date:{
                pipeTemplate:'dd/MM/yyyy',
                date:true,
            }
        },
        {
            variant:'chip',
            display:'Estado',
            key:'billState',
            width:'150px',
            classNameGenerator: (item,index,row) => row.billStateColor,
            className: 'fwBold' 
        },
        {
            variant:'button',
            display:'PDF',
            key:'document',
            action: (row) => window.open(row.document, '_blank'),
            buttonStyle: 'principalButton onlyIcon',
            icon: 'attach_file',
            width: '50px',
            displayGenerator: (row) => row.document !== null && row.document !== undefined && row.document !== ''                  
        },

    ]
    headerDataTableProfesores:headerData = [
        {
            variant:'bold',
            display:'Profesor',
            key:'name',
            alignment:'start',
            className:'truncateText maxWidth-200',
            width:'200px',
        }
    ]
}
