interface Price {
  id: number;
  price: number;
  modality: boolean;

}

interface Student {
  studentName: string;
  formationOne: string;
  modalityOne: string;
  stateOneId: number;
  stateOne: string;
  formationTwo: string;
  modalityTwo: string;
  stateTwoId: number;
  stateTwo: string;
  doubleMasterState: string;
  stateOneColor: string;
  stateTwoColor: string;
  doubleMasterStateColor: string;
}

export interface iDoubleMaster {
  description: {
    id: number;
    name: string;
    activeLabel: string;
  };
  includedMasters: {
    masterOneId: number;
    formationOne: string;
    masterTwoId: number;
    formationTwo: string;
  };
  prices: Price[]; 
  hours: number;
  students: Student[];
}

export class IDoubleMaster {

  static createEmptyObject(): iDoubleMaster {
    let iDoubleMasterObj = {
      description: {
        id: 0,
        name: '',
        activeLabel: '',
      },
      includedMasters: {
        masterOneId: 0,
        formationOne: '',
        masterTwoId: 0,
        formationTwo: '',
      },
      prices: [
        {
          id: 0,
          price: 0,
          modality: false,
        }
      ],
      hours: 0,
      students: [
        {
          studentName: '',
          formationOne: '',
          modalityOne: '',
          stateOneId: 0,
          stateOne: '',
          formationTwo: '',
          modalityTwo: '',
          stateTwoId: 0,
          stateTwo: '',
          doubleMasterState: '',
          stateOneColor: '',
          stateTwoColor: '',
          doubleMasterStateColor: '',
        }
      ],
    };
    return iDoubleMasterObj;
  }
}