import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { iDate } from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { BillService } from 'src/app/Services/Api/Bill.service';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import {
  AddTeacherBillPopupClass,
  iCreateBill,
} from 'src/app/Shared/Classes/AddTeacherBillPopupClass';
import { iBase64File } from 'src/app/Shared/Interfaces/Utils/iBase64File';

@Component({
  selector: 'AddTeacherBill-Popup',
  templateUrl: './AddTeacherBill-Popup.component.html',
  styleUrls: ['./AddTeacherBill-Popup.component.scss'],
})
export class AddTeacherBillPopupComponent implements OnInit {
  extraClass: AddTeacherBillPopupClass = new AddTeacherBillPopupClass();

  constructor(
    public dialogRef: MatDialogRef<AddTeacherBillPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private teacherSE: TeacherService,
    private generalLoaderSE: GeneralLoaderService,
    private billSE: BillService
  ) {}

  ngOnInit() {
    this.setModalData();
    this.getBillStateSelector();
    this.getConceptTeacherBillSelector();
    if (this.extraClass.isEditing) {
      this.getBilById();
    }
  }

  /**
   * HANDLERS
   */

  successGetConceptTeacherBillSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.conceptSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetBillStateSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.billStatusSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetBillByIdHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.ngModels.amount = data.amount;
    this.extraClass.ngModels.billState = data.billState;
    this.extraClass.conceptLabel = data.concept;
    this.extraClass.ngModels.date = data.date;
    this.extraClass.ngModels.number = data.number;
    this.extraClass.ngModels.paymentDate = data.paymentDate;
    this.extraClass.ngModels.document.name = data.docName;
    this.extraClass.ngModels.document.url = data.url;
    this.extraClass.files.push({
      base64: data.url,
      file: {
        name: data.docName,
        type: '',
        lastModified: 0,
        lastModifiedDate: new Date(),
        size: 0,
        webkitRelativePath: '',
      },
    });
    this.checkComplete();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  onFinalizar() {
    let returnValue: any = { ...this.extraClass.ngModels };

    if (this.extraClass.files.length > 0) {
      const file: iBase64File = this.extraClass.files[0];
      returnValue = {
        ...returnValue,
        document: { name: file.file.name, url: file.base64 },
      };
    }

    if (this.extraClass.isEditing) {
      
      delete returnValue.concepts;
      
      const startOfUrlDocument = returnValue.document.url.slice(0, 4);

      if (startOfUrlDocument === 'http') delete returnValue.document;
      
      returnValue = {
        ...returnValue,
        id: this.extraClass.billId,
      };

    }
    this.closePopup(returnValue);
  }

  getFiles(files: iBase64File[]) {
    this.extraClass.files = files;
    this.extraClass.ngModels.document = {
      name: files[0].file.name,
      url: files[0].base64,
    };
  }

  getConceptAndCalculateAmount(concepts) {
    this.extraClass.ngModels.amount = concepts.reduce(
      (acc, curr) => acc + curr.fee,
      0
    );
  }

  getDate(value, key) {
    const formattedDate = iDate
      .javascriptConvert(new Date(value))
      .toStringDate('JAP');
    this.extraClass.ngModels[key] = formattedDate;
  }

  checkComplete() {
    const objectToCheck: iCreateBill = { ...this.extraClass.ngModels };
    if (this.extraClass.isEditing) {
      delete objectToCheck.concepts;
    }
    this.extraClass.canFinish = Object.values(objectToCheck).every((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      if (typeof value === 'object') {
        return (
          value?.name !== null &&
          value?.name !== '' &&
          value?.name !== undefined &&
          value?.url !== null &&
          value?.url !== '' &&
          value?.url !== undefined
        );
      }
      return value !== null && value !== '' && value !== undefined;
    });
  }

  setModalData() {
    this.extraClass.ngModels.teacherId = this.modalData.data.teacherId;
    this.extraClass.isEditing = this.modalData.data.edit;
    this.extraClass.billId = this.modalData.data.billId;
  }

  /**
   * API CALLS
   */

  getConceptTeacherBillSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.teacherSE.getConceptTeacherBillSelector(
      behaviorSubject,
      this.modalData.data.teacherId
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetConceptTeacherBillSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
  getBillStateSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.billSE.getBillStateSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetBillStateSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getBilById() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    this.billSE.getSingle(this.extraClass.billId!);
    this.billSE
      .getResultIndividual()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.successGetBillByIdHandler(res);
        this.generalLoaderSE.removeFromLoaderAmount();
        subject.next('');
      });
    this.billSE
      .getResultIndividualError()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        subject.next('');
      });
  }
}
