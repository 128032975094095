import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  iStaticUtilities,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iUserData } from '../../Interfaces/Utils/iUserData';

@Component({
  selector: 'sidebar',
  templateUrl: './Sidebar.component.html',
  styleUrls: ['./Sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isOpened: boolean = true;
  isUnfoldedStudents: boolean = false;
  isUnfoldedEnrolls: boolean = false;

  userData: iUserData = {
    email: '',
    id: 0,
    name: '',
    surname: '',
    permissions: [],
    roles: [],
  };

  url: string = '';

  @Output() resize = new EventEmitter<any>();

  constructor(private staticUtilities: StaticUtilitiesService) {}

  async ngOnInit() {
    this.url = this.staticUtilities.getActualRoute();
    this.userData = await this.getDataFromLocalStorage();
  }

  /**
   * FUNCTIONALITY
   */
  controlOpenSidebar() {
    this.isOpened ? (this.isOpened = false) : (this.isOpened = true);
    this.resize.emit(this.isOpened);
  }
  controlDesplegadoStudent() {
    this.isUnfoldedStudents
      ? (this.isUnfoldedStudents = false)
      : (this.isUnfoldedStudents = true);
  }
  controlDesplegadoEnrolls() {
    this.isUnfoldedEnrolls
      ? (this.isUnfoldedEnrolls = false)
      : (this.isUnfoldedEnrolls = true);
  }
  goTo(url) {
    this.staticUtilities.goTo(url, slideNavigationTypes.slideToLeft);
  }
  getDataFromLocalStorage(): Promise<iUserData> {
    return new Promise((resolve, reject) => {
      let dataFromLocal = localStorage.getItem('usr')!;
      let decripted = iStaticUtilities.simpleDecrypt(dataFromLocal);
      let userData = JSON.parse(decripted);
      resolve(userData);
    });
  }
}
