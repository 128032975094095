<div class="popupGeneralContainer">
  <div class="header">
    <p class="title">{{grupoName}}</p>
    <div class="formacionesDiv">
      <span class="box" *ngFor="let formacion of formaciones">{{formacion}}</span>
    </div>
  </div>
  <div class="body">
    <div class="bodyUnAlumno">

      <div class="unAlumnoFirstSection">
        <VariosAlumnosSubirExcel (isExcelLoaded)="getIsExcelLoaded($event)" *ngIf="!isExcelLoaded"></VariosAlumnosSubirExcel>
        <ListaDeStudentsDeExcel [studentList]="studentList" *ngIf="isExcelLoaded"></ListaDeStudentsDeExcel>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn classList="violetButton" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="principalButton" (click)="nextButton()" *ngIf="isExcelLoaded">
      <ng-container>
        Guardar
      </ng-container>
    </btn>
  </div>
</div>