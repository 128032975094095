import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.scss'],
})
export class GanttComponent implements OnInit {
  constructor(private cdr: ChangeDetectorRef) {}

  private _fases: Array<any> = [];

  isReadyToLoad:boolean = false;

  @Input() get fases(){
    return this._fases;
  }
  set fases(value){
    this._fases = value;
    if(value){
      this.isReadyToLoad = false;
      this.promiseToLoad.then(()=> 
        this.prepararDatos()
    ).then(()=>
      this.calcularPosiones()
      ).then(()=>
        this.calcularMesesString()
      ).then(()=>
        this.calcularHoy()
      ).then(()=>
        this.cdr.detectChanges()
      ).then(()=>
        this.isReadyToLoad = true
      )
      // this.calcularMesesString()
      // this.calcularHoy()
      // this.cdr.detectChanges()
      // this.isReadyToLoad = true

    }
  }
  @Input() hoy = true;
  @Input() height = '500px';
  @Input() anchoDia = 5;
  @Input() yearColors = ['#8286F5', '#3F43AD'];
  @Input() startDate:Date = new Date()
  @Input() endDate:Date = new Date()

  today = new Date();

  anchoFases = '0px';
  fechaInicio = new Date('2020-04-20');
  fechaFin = new Date('2020-04-20');
  taskHeight = 30;
  taskHeightComprimida = 10;
  margen = 7;
  tareasPosicion: Array<any> = [];
  altoTodasTareas = 0;

  ngOnInit(): void {
    this.isReadyToLoad = false;
    this.prepararDatos();
    this.calcularPosiones();
    this.isReadyToLoad = true;
  }


  promiseToLoad = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('Promise resolved');
    }, 1);
  })

  prepararDatos() {

    if (this.fases.length > 0) {
      let fechaMin = this.startDate;
      let fechaMax = this.endDate;

      for (let fase of this.fases) {
        let orden = 0;
        for (let tarea of fase.tareas) {
          let t: any = tarea;
          t.order = orden;
          tarea = t;
          orden++;

        }
      }
      //restar dos dias a la fecha minima
      // fechaMin.setDate(fechaMin.getDate() - 5);
      this.fechaInicio = fechaMin;

      this.fechaFin = fechaMax;
    }
  }

  calcularPosiones() {
    this.tareasPosicion = [];
    let totComprimidas = 0;
    let totNoComprimidas = 0;

    // Sacamos el total de tareas comprimidas y no comprimidas
    for (let fase of this.fases) {
      let tareasComrpimidas = 0;
      for (let tarea of fase.tareas) {
        // Calculamos la posición de la tarea
        this.tareasPosicion.push(
          this.calcularPosicion(tarea, tareasComrpimidas)
        );
        if (tarea.comprimida) {
          tareasComrpimidas++;
          totComprimidas++;
        } else {
          totNoComprimidas++;
        }
      }
    }

    this.altoTodasTareas =
      totNoComprimidas * (this.taskHeight + this.margen) +
      totComprimidas * (this.taskHeightComprimida + this.margen) +
      30 +
      50 * this.fases.length;
  }

  calcularHoy() {
    let inicio = this.today;
    let diferencia = inicio.getTime() - this.fechaInicio.getTime();
    diferencia = this.segundosToDias(diferencia);
    return diferencia * this.anchoDia + 200 + 'px';
  }
  mesesOrden = [
    'ENE',
    'FEB',
    'MAR',
    'ABR',
    'MAY',
    'JUN',
    'JUL',
    'AGO',
    'SEP',
    'OCT',
    'NOV',
    'DIC',
  ];
  mesesString: Array<any> = [];

  calcularMesesString() {
    let meses: Array<any> = [];
    let mes = this.fechaInicio.getMonth();
    let anio = this.fechaInicio.getFullYear();
    let fecha = new Date(anio, mes, this.fechaInicio.getDate());
    let margen = 0;
    let count = 0;
    const yearsContainer: HTMLElement | null = document.getElementById('years');
    let years: { year: number; positionIni: number; positionEnd?: number }[] =
      [];
      this.removeAllYearsDivs();
    while (fecha < this.fechaFin) {
      // Sacamos el año
      const year = fecha.getFullYear();
      // Buscamos si el año ya esta en el array
      const foundYear = years.find((yearInArray) => yearInArray.year === year);
      // Si no lo está
      if (!foundYear) {
        // Si hay años en el array
        if (years.length > 0) {
          // Ponemos la posición final del año anterior como la posición actual - 1
          years[years.length - 1].positionEnd = count - 1;
        }
        // Añadimos el año al array
        years.push({ year: year, positionIni: count });
      }
      let dias = this.diasDuracionMes(
        fecha.getDate(),
        fecha.getMonth(),
        fecha.getFullYear()
      );
      meses.push({
        left: margen * this.anchoDia,
        width: dias * this.anchoDia - 1,
        nombre: this.mesesOrden[fecha.getMonth()],
      });
      margen += dias;
      fecha.setDate(1);
      fecha.setMonth(fecha.getMonth() + 1);
      count++;
    }

    let diferencia = fecha.getTime() - this.fechaInicio.getTime();
    diferencia = this.segundosToDias(diferencia);
    this.anchoFases = diferencia * this.anchoDia + 'px';
    this.mesesString = meses;

    years.forEach((year, index) => {
      const yearElement = document.createElement('div');
      let yearWidth: string = '';
      if (year.positionEnd !== undefined) {
        const initialPosition = year.positionIni;
        const finalPosition = year.positionEnd;
        yearWidth = this.getYearWidthFromMothWidth(
          initialPosition,
          finalPosition
        );
      } else {
        yearWidth = this.getYearWidthFromMothWidth(year.positionIni);
      }
      yearElement.classList.add('year');
      yearElement.style.backgroundColor =
        this.yearColors[index % this.yearColors.length];
      yearElement.style.width = yearWidth;
      const p = document.createElement('p');
      p.innerHTML = year.year.toString();
      yearElement.appendChild(p);
      yearsContainer?.appendChild(yearElement);
    });
  }

  getYearWidthFromMothWidth(initialPosition, finalPosition?) {
    const monthArray = this.mesesString;
    let endPosition =
      finalPosition !== undefined ? finalPosition + 1 : undefined;
    let slicedArray = monthArray.slice(initialPosition, endPosition);
    let width = 0;
    for (let index = 0; index < slicedArray.length; index++) {
      const monthWidth = slicedArray[index].width + 3;
      width += monthWidth;
    }
    return width + 'px';
  }

  diasDuracionMes(dia, mes, anio) {
    let fecha = new Date(anio, mes, dia);
    let dias = 0;
    while (fecha.getMonth() == mes) {
      dias++;
      fecha.setDate(fecha.getDate() + 1);
    }
    return dias;
  }
  calcularPosicion(tarea, tareasComrpimidas) {
    // Si empieza antes de la fecha de inicio
    let startsBefore:boolean = false;
    // Si termina después de la fecha de fin
    let endsAfter:boolean = false;
    // Convertimos la fecha de inicio de la tarea a Date
    let inicio = new Date(tarea.start);
    // Convertimos la fecha de fin de la tarea a Date
    let fin = new Date(tarea.end)

    // Si la tarea empieza antes de la fecha de inicio entonces seteamos el startsBefore a true y la fecha de inicio de la tarea a la fecha de inicio
    if( new Date(inicio) < new Date(this.fechaInicio)){
      inicio = this.fechaInicio;
      startsBefore = true;
    }
    // Si la tarea termina después de la fecha de fin entonces seteamos el endsAfter a true y la fecha de fin de la tarea a la fecha de fin
    if( new Date(tarea.end) > new Date(this.fechaFin)){
      fin = this.fechaFin;
      endsAfter = true;
    }

    // Calculamos la duración de la tarea
    let duracion = fin.getTime() - inicio.getTime();

    //duracion a dias
    let dias = this.segundosToDias(duracion) + 1;

    // Calculamos la diferencia entre la fecha de inicio de la tarea y la fecha de inicio del gantt
    let diferencia = inicio.getTime() - this.fechaInicio.getTime();

    // Convertimos la diferencia a días
    diferencia = this.segundosToDias(diferencia);

    // Sacamos los meses para poder multiplicarlos por el tamaño del borde de cada mes
    const months = this.calcularMesesEntreFechas(inicio, fin);
    let alto = this.taskHeight;
    if (tarea.comprimida) {
      alto = this.taskHeightComprimida;
    }
    let arriba =
      (tarea.order - tareasComrpimidas) * (this.taskHeight + this.margen) +
      tareasComrpimidas * (this.taskHeightComprimida + this.margen);
    return {
      endsAfter: endsAfter,
      startsBefore: startsBefore,
      left: diferencia * this.anchoDia + 'px',
      top: arriba + 'px',
      width: this.returnWidth(dias,this.anchoDia,months),
      height: alto + 'px',
    };
  }

  returnWidth(dias,anchoDia,months):string{
    const monthsPlusBorder = (months - 1 ) * 2;
    const totalWidth = dias * anchoDia + monthsPlusBorder;
    const width = totalWidth + 'px';
    return width;
  }


  calcularMesesEntreFechas(fechaInicio: Date, fechaFin: Date): number {
    const añosDiferencia = fechaFin.getFullYear() - fechaInicio.getFullYear();
    const mesesDiferencia = fechaFin.getMonth() - fechaInicio.getMonth();

    return añosDiferencia * 12 + mesesDiferencia;
  }

  comprimirFase(fase) {
    this.fases[fase].comprimida = !this.fases[fase].comprimida;
    this.fases[fase].tareas.forEach((tarea) => {
      tarea.comprimida = this.fases[fase].comprimida;
    });
    this.calcularPosiones();
  }
  calcularTamFase(fase) {
    let tam = 0;
    this.fases[fase].tareas.forEach((tarea) => {
      if (tarea.comprimida) {
        tam += this.taskHeightComprimida + this.margen;
      } else {
        tam += this.taskHeight + this.margen;
      }
    });
    return tam + 'px';
  }

  segundosToDias(segundos) {
    return segundos / (1000 * 3600 * 24);
  }

  indiceFaseTarea(fase, tarea) {
    let cont = 0;
    for (let i = 0; i < fase; i++) {
      cont += this.fases[i].tareas.length;
    }
    cont += tarea;
    return cont;
  }

  removeAllYearsDivs(){
    const yearsContainers: NodeListOf<Element>  | null = document.querySelectorAll('.year');
    if(yearsContainers){
      yearsContainers.forEach((yearContainer) => {
        yearContainer.remove();
      });
    }
  }
}
