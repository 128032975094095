import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PopupService, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';

@Component({
  selector: 'ListaDeStudentsDeExcel',
  templateUrl: './ListaDeStudentsDeExcel.component.html',
  styleUrls: ['./ListaDeStudentsDeExcel.component.scss']
})
export class ListaDeStudentsDeExcelComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() studentList: any = null;

  constructor(private popupSE:PopupService) {
    super();
  }

  ngOnInit() {
  }


  deleteUserFromList(index:number){
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {accion: "Eliminar", elemento: "el alumno de la lista"})
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res =>{
      if(!res)return
      this.studentList.splice(index, 1);
      this._unsubInd.next('')
    })
  }

}
