<fullContainer gradient="bajo">
  <ng-container>
    <header></header>
    <div class="formGeneralContainer">
      <div class="timeline">
        <div class="container">
          <p class="title" *ngIf="!extraClass.isEdit">Añadiendo tutoría</p>
          <p class="title" *ngIf="extraClass.isEdit">Editando tutoría</p>

          <div class="options">
            <span
              [class.active]="extraClass.optionSelected == 0"
              (click)="scroll('informacion')"
            >
              <mat-icon>school</mat-icon>
              Formación
            </span>
            <span
              [class.active]="extraClass.optionSelected == 1"
              (click)="scroll('descripcion')"
            >
              <mat-icon>today</mat-icon>
              Fechas
            </span>
          </div>
        </div>
        <div class="footer">
          <btn
            *ngIf="!extraClass.isEdit"
            classList="principalButton small fitWidth"
            (click)="confirmPopup()"
          >
            <ng-container>
              <p>Cancelar</p>
            </ng-container>
          </btn>

          <btn
            *ngIf="extraClass.isEdit"
            classList="principalButton small fitWidth"
            (click)="goBack()"
          >
            <ng-container>
              <p>Volver</p>
            </ng-container>
          </btn>
          <btn
            *ngIf="!extraClass.isEdit"
            classList="specialButton small fitWidth loading"
            [class.disabled]="!extraClass.isActive"
            (click)="onFinalizarClick()"
          >
            <ng-container> Finalizar </ng-container>
          </btn>
        </div>
      </div>
      <div class="form">
        <div class="contactoContainer">
          <div class="header">
            <p class="title">
              <mat-icon>school</mat-icon>
              Formación
            </p>
          </div>
          <div class="body">
            <div class="formLine doble">
              <selector
                class="popupAddProject"
                [options]="extraClass.teacherSelectorOptions"
                [(ngModel)]="extraClass.ngModels.teacher"
                (change)="checkIsComplete(); getTopicsByTeacherId()"
              ></selector>
            </div>

            <div class="formLine">
              <selector
                class="popupAddProject"
                style="width: 100%"
                [options]="extraClass.masterSelectorOptions"
                [(ngModel)]="extraClass.ngModels.master"
                (change)="
                  getConvocatorias(extraClass.ngModels.master, 'formationIds');
                  checkIsComplete()
                "
              ></selector>
            </div>

            <div class="formLine doble">
              <selector
                class="popupAddProject"
                (change)="
                  getEditions(extraClass.ngModels.convocatoria, 'dates');
                  checkIsComplete()
                "
                [options]="extraClass.convocatoriaSelectorOptions"
                [(ngModel)]="extraClass.ngModels.convocatoria"
              ></selector>
              <selector
                class="popupAddProject"
                [options]="extraClass.edicionesSelectorOptions"
                [(ngModel)]="extraClass.ngModels.promotion"
                (change)="checkIsComplete()"
              ></selector>
            </div>
          </div>
        </div>
        <div class="datosFiscales">
          <div class="header">
            <p class="title">
              <mat-icon>today</mat-icon>
              Fechas
            </p>
          </div>
          <div class="body">
            <div class="formLine doble">
              <selector
                class="popupAddProject"
                [options]="extraClass.modalidadSelectorOptions"
                [(ngModel)]="extraClass.ngModels.modality"
                (change)="checkIsComplete()"
              ></selector>
              <selector
                class="popupAddProject"
                [options]="extraClass.aulaSelectorOptions"
                [(ngModel)]="extraClass.ngModels.classroom"
                (change)="checkIsComplete()"
              ></selector>
            </div>

            <div class="formLine doble">
              <div class="formLine doble">
                <customInput
                  [options]="extraClass.fechaSelectorOptions"
                  [selectedDate]="extraClass.ngModels.startDate"
                  (change)="
                    setNgModelDate($event, 'startDate'); checkIsComplete()
                  "
                ></customInput>
                <customInput
                  [options]="extraClass.horaInicioCustomInputOptions"
                  [(ngModel)]="extraClass.ngModels.startTime"
                  (change)="checkIsComplete()"
                ></customInput>
              </div>
              <div class="formLine doble">
                <customInput
                  [options]="extraClass.horaFinCustomInputOptions"
                  [(ngModel)]="extraClass.ngModels.endTime"
                  (change)="checkIsComplete()"
                ></customInput>
                <selector
                  class="popupAddProject"
                  [options]="extraClass.slotsSelectorOptions"
                  [(ngModel)]="extraClass.ngModels.slots"
                  (change)="checkIsComplete()"
                ></selector>
              </div>
            </div>

            <div class="formLine">
              <add-and-search-selector
                style="width: 100%"
                [selectorOptions]="extraClass.tematicasSelectorOptions"
                [(ngModel)]="extraClass.ngModels.topic"
                (deleteSelector)="deleteTopic($event)"
                (change)="onTopicChange($event); checkIsComplete()"
              ></add-and-search-selector>
            </div>

            <div class="formLine">
              <customInput
                style="width: 100%"
                [options]="extraClass.tematicasDisplaySelectorOptions"
                [(ngModel)]="extraClass.ngModels.topicDisplay"
                (change)="checkIsComplete()"
              ></customInput>
            </div>

            <div class="formLine">
              <customTextArea
                style="width: 100%"
                [options]="extraClass.observacionesCustomTextareaOptions"
                [(ngModel)]="extraClass.ngModels.observations"
                (change)="checkIsComplete()"
              ></customTextArea>
            </div>
          </div>
        </div>

        <div
          class="datosFiscales"
          [ngStyle]="{ opacity: '0', height: '27vh' }"
        ></div>
      </div>
    </div>
  </ng-container>
</fullContainer>
