import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fullContainer',
  templateUrl: './FullContainer.component.html',
  styleUrls: ['./FullContainer.component.scss']
})
export class FullContainerComponent implements OnInit {

  @Input() backgroundColor = "#FFFFFF";
  opened: boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
