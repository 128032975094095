import { Component, OnInit } from '@angular/core';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { BookTutoringService } from 'src/app/Services/Api/BookTutoring.service';
import { LocalStorageHandlerService } from 'src/app/Services/Utils/LocalStorageHandler.service';
import {
  TutoriaAlumnoRellenaDatosReservaClass,
  iReserva,
} from 'src/app/Shared/Classes/TutoriaAlumnoRellenaDatosReservaClass';

@Component({
  selector: 'app-Tutoria-Alumno-RellenaDatosReserva',
  templateUrl: './Tutoria-Alumno-RellenaDatosReserva.component.html',
  styleUrls: ['./Tutoria-Alumno-RellenaDatosReserva.component.scss'],
})
export class TutoriaAlumnoRellenaDatosReservaComponent implements OnInit {
  token: string = '';

  extraClass: TutoriaAlumnoRellenaDatosReservaClass =
    new TutoriaAlumnoRellenaDatosReservaClass();

  constructor(
    private staticUtilitiesSE: StaticUtilitiesService,
    private generalLoaderSE: GeneralLoaderService,
    private bookTutoringSE: BookTutoringService
  ) {}

  ngOnInit() {
    this.getTokenFromLocalStorage();
  }

  /**
   * HANDLERS
   */

  successGetMastersByPublicTokenHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.masterSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTeachersByMastersByPublicTokenHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.tutorSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetDatesByPublicTokenHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.fechaSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetSlotsByPublicTokenHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.horaSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTutoringByPublicTokenHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.modalidadSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successBookStudentTutoringHandler(res:iResultHttp){
    const { data } = res;
    this.staticUtilitiesSE.goTo('/tutoria-alumno/success', slideNavigationTypes.slideToLeft);
    this.generalLoaderSE.removeFromLoaderAmount();
   }

  /**
   * FUNCTIONALITY
   */

  getTokenFromLocalStorage() {
    const token = LocalStorageHandlerService.getItem('unt');
    if (token) {
      this.token = token;
      this.apiCalls();
    } else {
      this.staticUtilitiesSE.goTo(
        '/tutoria-alumno',
        slideNavigationTypes.slideToRight
      );
      StaticUtilitiesService.showError(
        'No tienes permisos para acceder a esta página'
      );
    }
  }

  apiCalls() {
    this.getMastersByPublicToken();
  }

  onErrorSendToInit() {
    this.staticUtilitiesSE.goTo(
      '/tutoria-alumno',
      slideNavigationTypes.slideToRight
    );
    StaticUtilitiesService.showError(
      'Se ha caducado el tiempo de sesión, por favor introduzca su NIF nuevamente.'
    );
  }

  prepareBodyToSend() {
    const objectToPass = {
      tk: this.token,
      slotId: this.extraClass.reserva.time,
      tutoringAttendanceId: this.extraClass.reserva.modality,
      comment: this.extraClass.reserva.comments,
    }
    this.bookStudentTutoring(objectToPass);
  }



  /**
   * API CALLS
   */

  getMastersByPublicToken() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.bookTutoringSE.getMastersByPublicToken(behaviorSubject, {
      tk: this.token,
    });
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetMastersByPublicTokenHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
          {
            method: () => this.onErrorSendToInit(),
            error: true,
          },
        ]);
      });
  }

  getTeachersByMastersByPublicToken() {
    this.generalLoaderSE.addToLoaderAmount();
    const params = { tk: this.token, mid: this.extraClass.reserva.master };
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.bookTutoringSE.getTeachersByMastersByPublicToken(
      behaviorSubject,
      params
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successGetTeachersByMastersByPublicTokenHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
          {
            method: () => this.onErrorSendToInit(),
            error: true,
          },
        ]);
      });
  }

  getDatesByPublicToken() {
    this.generalLoaderSE.addToLoaderAmount();
    const params = {
      tk: this.token,
      tid: this.extraClass.reserva.tutor,
      mid: this.extraClass.reserva.master,
    };
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.bookTutoringSE.getDatesByPublicToken(behaviorSubject, params);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetDatesByPublicTokenHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
          {
            method: () => this.onErrorSendToInit(),
            error: true,
          },
        ]);
      });
  }

  getSlotsByPublicToken() {
    const params = { tk: this.token, sd: this.extraClass.reserva.date };
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.bookTutoringSE.getSlotsByPublicToken(behaviorSubject, params);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetSlotsByPublicTokenHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
          {
            method: () => this.onErrorSendToInit(),
            error: true,
          },
        ]);
      });
  }

  getTutoringByPublicToken() {
    this.generalLoaderSE.addToLoaderAmount();
    const params = { tk: this.token, sid: this.extraClass.reserva.time };
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.bookTutoringSE.getTutoringByPublicToken(behaviorSubject, params);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetTutoringByPublicTokenHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
          {
            method: () => this.onErrorSendToInit(),
            error: true,
          },
        ]);
      });
  }

  bookStudentTutoring(objectToPass) {
  this.generalLoaderSE.addToLoaderAmount();
  const subject = StaticUtilitiesService.createSubject();
  const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
  this.bookTutoringSE.bookStudentTutoring(behaviorSubject, objectToPass);
  behaviorSubject
    .pipe(
      takeUntil(subject),
      filter((res) => res)
    )
    .subscribe((res: iResultHttp) => {
      StaticUtilitiesService.apiResponseHandler(res, subject, [
      {
        method: () => this.successBookStudentTutoringHandler(res),
      },
      {
        method: () => this.generalLoaderSE.removeFromLoaderAmount(),
        error:true
      },
      {
        method: () => this.onErrorSendToInit(),
        error: true,
      },
     ]);
    });
  }
  

}
