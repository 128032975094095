import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';
import { iCustomCheckboxOptions } from '../../Interfaces/Utils/iCustomCheckboxOptions';

@Component({
  selector: 'custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss'],
})
export class CustomCheckboxComponent implements OnInit {

  @Output() change:EventEmitter<iCustomCheckboxOptions> = new EventEmitter<iCustomCheckboxOptions>();

  @Input() options: iCustomCheckboxOptions = {
    active: false,
    text: 'Custom Checkbox',
    color: '#000000',
    landmarkTypeId: null,
  };

  IconsHandlerService = IconsHandlerService;
  name: string = new Date().getTime().toString();

  constructor() {}

  ngOnInit() {}

  toggleActive() {
    this.options.active = !this.options.active;
    this.change.emit(this.options);
  }
}
