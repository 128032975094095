<div class="headerTutoriaAlumno">
  <img class="logoIEM" src="/assets/Images/Logos/IEMDigitalBussinessSchool.svg" alt="logo IEM">
  <p class="reservaTutorias">RESERVA TUTORIAS</p>
</div>
<div class="contentContainer">
  <ng-content></ng-content>

</div>



