<fullContainer>
  <ng-container>
    <header></header>

    <div class="fullView">
      <!-- Subheader  -->
      <div class="subHeader">
        <div class="firstRow">
          <div class="toggleSubmenu__container">
            <toggleSubmenu
              [id]="formationType"
              class="secondary"
              [elements]="toggleElements"
              [(ngModel)]="toggleModel"
              (onValueChange)="onToggleChange($event)"
            >
            </toggleSubmenu>
          </div>
          <div class="buttons" *ngIf="checkRolesAndPermissions()">
            <!-- <btn classList="generalbutton principalButton" (click)="editFormation()">Editar</btn> -->
            <btn
              *ngIf="isAddPromotionVisible"
              classList="generalbutton principalButton"
              (click)="nuevaPromocion()"
            >
              <mat-icon>add_circle_outline</mat-icon>
              <p>Añadir nueva promocion</p>
            </btn>
          </div>
        </div>
      </div>
      <ng-container *ngIf="toggleModel === 0">
        <DetailView-details
          (onActiveToggle)="editIsActive()"
          skeleton
          [isDataLoaded]="isDataLoaded"
          [formationData]="formationData"
          [modulesData]="modulesData"
          [directorsData]="directorsData"
          [modalitiesData]="modalitiesData"
          [pricesData]="pricesData"
          [formationType]="formationType"
          [promotionTemplateId]="promotionTemplateId"
          [formationType]="formationType"
        ></DetailView-details>
      </ng-container>
      <ng-container *ngIf="toggleModel === 1">
        <DetailView-promotions
          (refresh)="onRefreshPromotions()"
          (onPageChange)="onPagePromotionChange($event)"
          [isDataPromotionLoaded]="isDataPromotionLoaded"
          [typeOf]="this.formationType"
          [total]="promotionsTotal"
          [filters]="filters"
          [promotions]="promotions"
          [formationId]="formationId"
        ></DetailView-promotions>
      </ng-container>
    </div>
  </ng-container>
</fullContainer>
