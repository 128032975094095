import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  PopupService,
  StaticUtilitiesService,
  iOptionsSelector,
  iResultHttp,
  iUnsubscribeDestroy,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { CourseService } from 'src/app/Services/Api/Course.service';
import { FormationService } from 'src/app/Services/Api/Formation.service';
import { IncompanyService } from 'src/app/Services/Api/Incompany.service';
import { MasterService } from 'src/app/Services/Api/Master.service';
import { ModalityPriceService } from 'src/app/Services/Api/ModalityPrice.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

@Component({
  selector: 'app-Formaciones-Crear',
  templateUrl: './Formaciones-Crear.component.html',
  styleUrls: ['./Formaciones-Crear.component.scss'],
})
export class FormacionesCrearComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  optionSelected: number = 0;
  isActive: boolean = false;
  isLoading: boolean = false;
  isEdit: boolean = false;
  disableSelector: boolean = false;
  dataFromFormationId: any = null;

  whatAmIAdding: string = 'una nueva formación';
  action: string = 'Añadiendo';

  params: any = null;

  data: any = {
    formation: {
      name: '',
      mode: null,
      language: null,
      typeOf: null,
      objectives: '',
      description: '',
      duration: null,
      modalityPrice: [
        {
          paymentModality: 1,
          price: null,
        },
        {
          paymentModality: 2,
          price: null,
        },
        {
          paymentModality: 3,
          price: null,
        },
        {
          paymentModality: 4,
          price: null,
        },
        {
          paymentModality: 5,
          price: null,
        },
      ],
    },
  };

  opcionesIdiomas: iOptionsSelector = {
    items: StaticDataHandlerService.getLanguages(),
    clearable: true,
    search: true,
    placeholder: 'Selecciona el idioma',
    append: true,
  };

  opcionesModos: iOptionsSelector = {
    items: ['Online', 'Presencial', 'Mixto'],
    placeholder: 'Selecciona el modo',
    search: true,
  };
  opcionesTipo: iOptionsSelector = {
    items: ['Curso', 'Master', 'Incompany'],
    placeholder: 'Selecciona el modo',
    search: true,
  };

  objectToPass: any = {
    id: null,
  };
  objectToPassMP: any = {};

  constructor(
    private router: Router,
    private popupSE: PopupService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private masterSE: MasterService,
    private courseSE: CourseService,
    private formationSE: FormationService,
    private modalityPriceSE: ModalityPriceService,
    private incompanySE: IncompanyService
  ) {
    super();
  }

  ngOnInit() {
    this.params = this.staticUtilitiesSE.getParams();
    if (this.params['type']) {
      if (this.params['type'] === 'master') {
        this.data.formation.typeOf = 'Master';
        this.whatAmIAdding = 'un nuevo master';
        this.disableSelector = true;
      }
      if (this.params['type'] === 'curso') {
        this.data.formation.typeOf = 'Curso';
        this.whatAmIAdding = 'un nuevo curso';
        this.disableSelector = true;
      }
      if (this.params['type'] === 'incompany') {
        this.data.formation.typeOf = 'Incompany';
        this.whatAmIAdding = 'un nuevo incompany';
        this.disableSelector = true;
      }
    }
    if (this.params['isEdit']) {
      this.isEdit = true;
      this.action = 'Editando';
      this.whatAmIAdding = 'la formación';
      this.objectToPass.id = this.params['formation'];
      this.getFormationById();
    }
  }

  ngAfterViewInit(): void {
    (document.querySelector('.form') as HTMLElement).addEventListener(
      'scroll',
      (e) => {
        if (
          (
            document.querySelector('.contactoContainer') as HTMLElement
          ).getBoundingClientRect().top < 0
        ) {
          this.optionSelected = 1;
        }
        if (
          (
            document.querySelector('.contactoContainer') as HTMLElement
          ).getBoundingClientRect().top > 0
        ) {
          this.optionSelected = 0;
        }
        if (
          (
            document.querySelector('.datosFiscales') as HTMLElement
          ).getBoundingClientRect().top < 0
        ) {
          this.optionSelected = 2;
        }
      }
    );
  }
  scroll(type: string) {
    switch (type) {
      case 'informacion':
        (document.querySelector('.form') as HTMLElement).scroll({
          top: 0,
          behavior: 'smooth',
        });
        break;
      case 'descripcion':
        (document.querySelector('.form') as HTMLElement).scroll({
          top:
            (document.querySelector('.form') as HTMLElement).scrollHeight / 3.7,
          behavior: 'smooth',
        });
        break;
      case 'precio':
        (document.querySelector('.form') as HTMLElement).scroll({
          top:
            (document.querySelector('.form') as HTMLElement).scrollHeight / 1.5,
          behavior: 'smooth',
        });
        break;
    }
  }
  confirmPopup() {
    if (this.router.url.includes('crear-formaciones')) {
      this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
        accion: 'Descartar',
        elemento: 'la creación de la formación',
      });
      this.popupSE
        .returnData()
        .pipe(takeUntil(this._unsubInd))
        .subscribe((data) => {
          this.staticUtilitiesSE.goTo(
            '/formaciones/listado-formaciones',
            slideNavigationTypes.slideToBottom
          );
          this._unsubInd.next('');
        });
    } else {
      this.staticUtilitiesSE.goTo(
        '/formaciones/listado-formaciones',
        slideNavigationTypes.slideToBottom
      );
    }
  }

  updateLine(event, key, option, number) {
    switch (option) {
      case 'formation':
        this.objectToPass[key] = event;
        this.editFormation();
        delete this.objectToPass[key];
        break;
      case 'modalityPrice':
        this.objectToPassMP.id =
          this.dataFromFormationId.modalityPriceFormations[number].id;
        this.objectToPassMP.price = event;
        this.editModalityPrice();
        delete this.objectToPassMP.price;
        delete this.objectToPassMP.id;
        break;
      default:
        break;
    }
  }

  isCompleted() {
    if (
      this.data.formation.name &&
      this.data.formation.mode &&
      this.data.formation.language &&
      this.data.formation.typeOf &&
      this.data.formation.objectives &&
      this.data.formation.description &&
      this.data.formation.duration &&
      this.data.formation.modalityPrice[0].price
    ) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }

  crearFormacion() {
    switch (this.data.formation.typeOf) {
      case 'Curso':
        // this.createCourse(this.data);
        break;

      case 'Master':
        // this.createMaster(this.data);
        break;

      case 'Incompany':
        this.data.formation.modalityPrice = [
          {
            paymentModality: 1,
            price: this.data.formation.modalityPrice[0].price,
          },
        ];
        this.createIncompany(this.data);
        break;

      default:
        StaticUtilitiesService.showError('Seleccione el tipo de formación');
        break;
    }
  }

  idiomasSelector(event) {
    this.data.formation.language = event;
    this.isCompleted();
  }
  tipoSelector(event) {
    this.data.formation.typeOf = event;
    this.isCompleted();
  }
  modalidadSelector(event) {
    this.data.formation.mode = event;
    this.isCompleted();
  }
  setter(data) {
    let newData: any = {
      formation: {
        name: data.name,
        mode: data.mode,
        language: data.language,
        typeOf: data.typeOf,
        objectives: data.objectives,
        description: data.description,
        duration: data.duration,
        modalityPrice: [
          {
            paymentModality: 1,
            price: data.modalityPriceFormations[0].price,
          },
          {
            paymentModality: 2,
            price: data.modalityPriceFormations[1].price,
          },
          {
            paymentModality: 3,
            price: data.modalityPriceFormations[2].price,
          },
          {
            paymentModality: 4,
            price: data.modalityPriceFormations[3].price,
          },
          {
            paymentModality: 5,
            price: data.modalityPriceFormations[4].price,
          },
        ],
      },
    };
    this.data = newData;
  }

  finishEdition() {
    this.staticUtilitiesSE.goTo(
      'formaciones/listado-formaciones',
      slideNavigationTypes.slideToBottom
    );
  }

  /**
   * API CALLS
   */

  // createMaster(data) {
  //   this.masterSE.create(data);
  //   this.masterSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: iResultHttp) => {
  //     if (!res) { return }
  //     StaticUtilitiesService.showFeedback('Formación creada correctamente');
  //     this.staticUtilitiesSE.goTo('formaciones/listado-formaciones', slideNavigationTypes.slideToBottom)
  //     this._unsubInd.next('')
  //   })
  //   this.masterSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
  //     if (!res) { return }
  //     if (res.status != 401) {
  //       if (res.status == 404) {
  //         StaticUtilitiesService.showError('No se han encontrado resultados');
  //       } else if (res.status == 500) {
  //         StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
  //       } else {
  //         StaticUtilitiesService.showError(res.message);
  //       }
  //     }
  //     this._unsub.next('')
  //   })
  // }

  // createCourse(data) {
  //   this.courseSE.create(data);
  //   this.courseSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: iResultHttp) => {
  //     if (!res) { return }
  //     StaticUtilitiesService.showFeedback('Formación creada correctamente');
  //     this.staticUtilitiesSE.goTo('formaciones/listado-formaciones', slideNavigationTypes.slideToBottom)
  //     this._unsubInd.next('')
  //   })
  //   this.courseSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
  //     if (!res) { return }
  //     if (res.status != 401) {
  //       if (res.status == 404) {
  //         StaticUtilitiesService.showError('No se han encontrado resultados');
  //       } else if (res.status == 500) {
  //         StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
  //       } else {
  //         StaticUtilitiesService.showError(res.message);
  //       }
  //     }
  //     this._unsub.next('')
  //   })
  // }

  createIncompany(data) {
    this.incompanySE.create(data);
    this.incompanySE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Formación creada correctamente');
        this.staticUtilitiesSE.goTo(
          'formaciones/listado-formaciones',
          slideNavigationTypes.slideToBottom
        );
        this._unsubInd.next('');
      });
    this.incompanySE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getFormationById() {
    this.formationSE.getSingle(this.params['formation']);
    this.formationSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.setter(data);
        this.dataFromFormationId = data;
        this._unsubInd.next('');
      });
    this.formationSE
      .getResultIndividualError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  editFormation() {
    this.formationSE.update(this.objectToPass);
    this.formationSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Formación editada correctamente');
        this._unsubInd3.next('');
      });
    this.formationSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  editModalityPrice() {
    this.modalityPriceSE.update(this.objectToPassMP);
    this.modalityPriceSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Formación editada correctamente');
        this._unsubInd2.next('');
      });
    this.modalityPriceSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
