import { iOptionsSelector } from '@quasar-dynamics/basic-designsystem';
import {
  IDoubleMaster,
  iDoubleMaster,
} from '../Interfaces/Utils/iDoubleMaster';
import { headerData } from '../Components/main-table/main-table.component';

export class DobleTitulacionDetailViewClass {
  // doubleMasterId
  doubleMasterId: number = 0;

  //   SplittedData
  description: iDoubleMaster['description'] =
    IDoubleMaster.createEmptyObject().description;
  hours: iDoubleMaster['hours'] = IDoubleMaster.createEmptyObject().hours;
  includedMasters: iDoubleMaster['includedMasters'] =
    IDoubleMaster.createEmptyObject().includedMasters;
  prices: iDoubleMaster['prices'] = IDoubleMaster.createEmptyObject().prices;
  students: iDoubleMaster['students'] =
    IDoubleMaster.createEmptyObject().students;

    // SelectorOptions
    masterSelectorOptions: iOptionsSelector = {
        items:[],
        append:true,
        bindLabel:'name',
        bindValue:'id',
        clearable:false,
        search:true,
        placeholder:'Selecciona un máster',
        readonly:true

    };

  editableController: { [key: string]: boolean } = {};

  pricesData: any[] = [];

  // numberRegex
  numberRegex = /^-?\d*([.,]\d*)?$/;

//   headerData
studentsHeaderData:headerData = [
    {
        variant: 'bold',
        display: 'Alumno',
        key:'studentName',
        alignment: 'start',
        width: '200px',

    },
    {
        variant: 'standard',
        display: 'Máster 1',
        key:'formationOne',
        alignment: 'start',
        width: '250px',

    },
    {
        variant: 'standard',
        display: 'Modalidad 1',
        key:'modalityOne',
        alignment: 'center',
        width: '100px',
    },
    {
        variant: 'chip',
        display: 'Estado 1',
        key:'stateOne',
        alignment: 'center',
        width: '150px',
        border: true,
        className: 'minWidth-150 fwBold',

        classNameGenerator: (data: any,index:number) => {
            if (!data) return '--redState';
            return this.students[index].stateOneColor;
          },
          
    },
    {
        variant: 'standard',
        display: 'Máster 2',
        key:'formationTwo',
        alignment: 'start',
        width: '250px',

    },
    {
        variant: 'standard',
        display: 'Modalidad 2',
        key:'modalityOne',
        alignment: 'center',
        width: '100px',
    },
    {
        variant: 'chip',
        display: 'Estado 2',
        key:'stateOne',
        alignment: 'center',
        width: '150px',
        border: true,
        className: 'minWidth-150 fwBold',

        classNameGenerator: (data: any,index:number) => {
            if (!data) return '--redState';
            return this.students[index].stateTwoColor;
          },
          
    },
    {
        variant:'chip',
        display:'Estado DT',
        key:'doubleMasterState',
        alignment:'center',
        width:'150px',
        className: 'minWidth-150 fwBold',
        classNameGenerator: (data: any,index:number) => {
            if (!data) return '--redState';
            return this.students[index].doubleMasterStateColor;
          },
    }
    
    
]


  /**
   * FUNCTIONS
   */

  setAsReadonly() {
    if(this.editableController['Másteres incluidos']){
        this.masterSelectorOptions.readonly = false;
    } else {
        this.masterSelectorOptions.readonly = true;
    }

  }
}
