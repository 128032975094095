<div class="fullContainer">
  <sidebar (resize)="opened = $event"></sidebar>
  <div
    class="pageContainer"
    id="fullContainer"
    cdkScrollable
    [class.opened]="opened"
    [style.background]="backgroundColor"
  >
    <ng-content></ng-content>
  </div>
</div>
