import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupService, StaticUtilitiesService, iResultHttp, iUnsubscribeDestroy, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AddStudentToGroupPopupComponent } from 'src/app/Popups/AddPopup/AddStudentToGroup-Popup/AddStudentToGroup-Popup.component';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { EnrollmentService } from 'src/app/Services/Api/Enrollment.service';
import { GroupService } from 'src/app/Services/Api/Group.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

@Component({
  selector: 'app-Incompanies-CheckThirdColumn',
  templateUrl: './Incompanies-CheckThirdColumn.component.html',
  styleUrls: ['./Incompanies-CheckThirdColumn.component.scss']
})
export class IncompaniesCheckThirdColumnComponent extends iUnsubscribeDestroy implements OnInit {

  optionSelected: number = 0;

  isActive: boolean = false;
  isLoading: boolean = false;
  isEdit: boolean = false;
  isDataLoaded: boolean = false;

  params: any = null;

  data: any = {
    name: '',
    groupStudents: StaticDataHandlerService.getEmptyGroupStudents(),
    groupPrice: null,
  }


  constructor(private staticUtilitiesSE: StaticUtilitiesService, private groupSE: GroupService, private popupSE: PopupService, private enrolmentSE: EnrollmentService, private router: Router, private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.params = this.staticUtilitiesSE.getParams();
    this.setGroupPrice();
    this.getGroupById();
  }

  ngAfterViewInit(): void {
    (document.querySelector(".form") as HTMLElement).addEventListener("scroll", (e) => {
      if ((document.querySelector(".contactoContainer") as HTMLElement).getBoundingClientRect().top < 0) {
        this.optionSelected = 1;
      }
      if ((document.querySelector(".contactoContainer") as HTMLElement).getBoundingClientRect().top > 0) {
        this.optionSelected = 0;
      }
    });
  }

  /**
   * GETTERS && SETTERS
   */

  setterGroupId(data) {
    this.data.name = data.name;
    this.data.groupStudents = data.groupStudents;
    this.isDataLoaded = true;
    this.isCompleted();
  }

  setGroupPrice() {
    this.data.groupPrice = this.params.groupPrice;
    this.isCompleted();
  }

  /**
 * 
 * FUNCTIONALITY
 */

  scroll(type: string) {
    switch (type) {
      case "informacion":
        (document.querySelector(".form") as HTMLElement).scroll({ top: 0, behavior: "smooth" })
        break;
      case "descripcion":
        (document.querySelector(".form") as HTMLElement).scroll({ top: (document.querySelector(".form") as HTMLElement).scrollHeight / 4.5, behavior: "smooth" });
        break;
    }
  }

  confirmCancelPopup() { 
    if (this.router.url.includes("confirmar-matriculacion")) {
      this.popupSE.openPopup(ConfirmarAccionPopupComponent, { accion: 'Descartar', elemento: 'la validacio' });
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
        this.staticUtilitiesSE.goTo("/matriculaciones/incompanies/listado-matriculaciones", slideNavigationTypes.slideToBottom)
        this._unsubInd.next("")
      })
    } else {
      this.staticUtilitiesSE.goTo("/matriculaciones/incompanies/listado-matriculaciones", slideNavigationTypes.slideToBottom)

    }
   }

  confirmEnroll() { 
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, { accion: 'Matricular', elemento: 'el grupo' });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd5)).subscribe((res) => {
      if(!res) return;
      this.enrollGroup();
      this._unsubInd5.next("")
    });
   }

  isCompleted() { 
    if(this.data.groupStudents.length > 0 && this.data.name && this.data.groupPrice){
      this.isActive = true;
    }else{
      this.isActive = false;
    }

   }

  updateLine(event, key, option) {
    switch (option) {
      case 'enrolment':
        this.editEnrollmentGroupPrice();
        this.isCompleted();
        break;

      case 'group':
        this.editGroup();
        this.isCompleted();
        break;

      default:
        break;
    }
  }

  deleteUserFromList(event) {
     
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, { accion: 'Eliminar', elemento: `el estudiante ${event.student.user.name + event.student.user.surname} del grupo` });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd4)).subscribe((res) => {
      if (!res) { return }
      this.deleteStudentFromGroupList([event.id]);
      this._unsubInd4.next('')
    });
  }

  updateParamsGroupPrice() {
    this.params = { ...this.params, groupPrice: this.data.groupPrice };
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { ...this.params }, queryParamsHandling: 'merge' })
  }

  openAddStudentPopup() {
    this.popupSE.openPopup(AddStudentToGroupPopupComponent);
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if (!res) { return }
       
      this.addStudentToGroup(res);
      this._unsubInd3.next('')
    });
  }

  /**
   * API CALLS
   */

  getGroupById() {
    this.isDataLoaded = false;
    this.groupSE.getSingle(this.params.groupId);
    this.groupSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      let { data } = res;
       
      this.setterGroupId(data);
      this._unsubInd.next('')
    })
    this.groupSE.getResultIndividualError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  deleteStudentFromGroupList(ids: number[]) {
    this.groupSE.deleteStudent(ids);
    this.groupSE.getResultDelete().pipe(takeUntil(this._unsubInd5)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      this.getGroupById();
      StaticUtilitiesService.showFeedback('Estudiante eliminado del grupo');
      this._unsubInd5.next('')
    })
    this.groupSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  editEnrollmentGroupPrice() {
    let objectToPass: { id: number, groupPrice?: number } = {
      id: this.params.enrolId,
      groupPrice: this.data.groupPrice
    };
    this.enrolmentSE.updateIncompany(objectToPass);
    this.enrolmentSE.getResultUpdate().pipe(takeUntil(this._unsubInd3)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Precio actualizado');
      this.updateParamsGroupPrice();
      this._unsubInd3.next('')
    })
    this.enrolmentSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  editGroup() {
    let objectToPass: { id: number, name?: string } = {
      id: this.params.groupId,
      name: this.data.name
    }
    this.groupSE.update(objectToPass);
    this.groupSE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Nombre actualizado correctamente');
      this._unsubInd2.next('')
    })
    this.groupSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  addStudentToGroup(student) {
    let objectToPass: { group: number, student?: any } = {
      group: this.params.groupId,
      student: [student]
    }; 
    this.groupSE.vinculateUserToGroup(objectToPass);
    this.groupSE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Estudiante añadido correctamente');
      this.getGroupById();
      this._unsubInd2.next('')
    })
    this.groupSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  enrollGroup() {
    this.enrolmentSE.validateIncompany(this.params.enrolId);
    this.enrolmentSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Matriculado correctamente');
      this.staticUtilitiesSE.goTo('/matriculaciones/incompanies/listado-matriculaciones', slideNavigationTypes.slideToRight);
      this._unsubInd.next('')
    })
    this.enrolmentSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }
}
