export interface iPromotionInfoForView {
  promotionId: number;
  header: {
    edition: string | null;
    formation: string | null;
    students: number;
    sessions: number;
    state: string;
    stateId: number;
  };
  generalInfo: {
    startDate: string | Date | null;
    endDate: string | Date | null;
    shift: number | null;
    formativeAction: any;
    formativeGroup: any;
    classroomId: any;
    classroom: any;
    dispositionId: any;
    disposition: any;
    startHour: string | null;
    endHour: string | null;
    days: number[];
  };
  coordinators: any[];
  director: { directorFullName: string; directorId: number };
  documents: {
    documentId: number;
    documentUrl: string | null;
    documentName: string | null;
  }[];
  modules: {
    moduleId: number | null;
    moduleTitle: string | null;
    sequence: number;
    sessions: {
      sessionId: number;
      sessionTitle: string;
      sequence: number;
    }[];
  }[];
  amounts: {
    coordinationAmount: number;
    managementAmount: number;
    projectAmount: number;
    teachingAmount: number;
    tutoringAmount: number;
  };
}

export class IPromotionInfoForView {
  static getEmptyObject(): iPromotionInfoForView {
    return {
      promotionId: 0,
      header: {
        edition: null,
        formation: null,
        students: 0,
        sessions: 0,
        state: '',
        stateId: 0,
      },
      generalInfo: {
        startDate: null,
        endDate: null,
        shift: null,
        formativeAction: null,
        formativeGroup: null,
        classroomId: null,
        classroom: null,
        dispositionId: null,
        disposition: null,
        startHour: null,
        days: [],
        endHour: null,
      },
      coordinators: [],
      director: { directorFullName: '', directorId: 0 },
      documents: [],
      modules: [],
      amounts: {
        coordinationAmount: 0,
        managementAmount: 0,
        projectAmount: 0,
        teachingAmount: 0,
        tutoringAmount: 0,
      },
    };
  }
}
