import { Component, Input, OnInit } from '@angular/core';

interface item {
  label: string;
  value: string;
}

@Component({
  selector: 'multiple-selector',
  templateUrl: './multiple-selector.component.html',
  styleUrls: ['./multiple-selector.component.scss']
})
export class MultipleSelectorComponent implements OnInit {
  @Input() readonly: boolean = false;

  items:item[] = [
    {
      label: 'Presencial 100%',
      value: 'presencial'
    },
    {
      label: 'Online 100%',
      value: 'online'
    },
    {
      label: 'Streaming 100%',
      value: 'straeming'
    },
    {
      label: 'Mixta',
      value: 'mixta'
    },
    {
      label: 'Remoto',
      value: 'remoto'
    },
    {
      label: 'En vivo',
      value: 'envivo'
    },
    {
      label: 'Flex',
      value: 'flex'
    }
  ];

  selectedOptions:item[] = [];

  constructor() { }

  ngOnInit() {
  }

  handleClickButton(value:item) {
    if (this.checkSelected(value)) {
      this.selectedOptions = this.selectedOptions.filter(item => item !== value);
    } else {
      this.selectedOptions.push(value);
    }
  }

  checkSelected(value:item) {
    return this.selectedOptions.includes(value);
  }
  
}
