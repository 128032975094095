export interface iBill {
    id: number;
    concept: string;
    amount: number;
    number: string;
    date: string | null;
    paymentDate: string;
    billState: number;
    billStateColor: string;
    url: string;
}

export class Ibill {
    static getEmptyObject():iBill {
        return {
            id: 0,
            concept: '',
            amount: 0,
            number: '',
            date: '',
            paymentDate: '',
            billState: 0,
            billStateColor: '',
            url: ''
        }
    }
}
