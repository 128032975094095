<fullContainer>
  <ng-container>
    <header></header>

    <div class="fullContainer">
      <div class="listSection">
        <div class="lineSelector" [class.active]="selectedButton == index" *ngFor="let line of arrayLines; let index = index" (click)="activateLine(index)">
          <div class="insideLine">
            <p>{{line.title}}</p>
            <mat-icon>chevron_right</mat-icon>
          </div>
        </div>
        <p></p>
      </div>
      <div class="contentSection">
        <!-- Cerrar sesión -->
        <Configuracion-LogOut style="height: 100%;" *ngIf="  selectedButton === 0 "></Configuracion-LogOut>
        
        <!-- Permisos -->
        <Configuracion-Permisos *ngIf="selectedButton === 1 && checkRolesAndPermissions()"  ></Configuracion-Permisos>

        <!-- Aulas -->
        <ng-container *ngIf="selectedButton === 2 && checkRolesAndPermissions()" >
          <Configuracion-Aulas></Configuracion-Aulas>
        </ng-container>

        <!-- Disposición -->
        <ng-container *ngIf="selectedButton === 3 && checkRolesAndPermissions()" >
          <Configuracion-Disposicion></Configuracion-Disposicion>
        </ng-container>

        <!-- Certificados -->
        <ng-container *ngIf="selectedButton === 4 && checkRolesAndPermissions()" >
          <Configuracion-Certificados></Configuracion-Certificados>
        </ng-container>
        
      </div>
    </div>

  </ng-container>
</fullContainer>