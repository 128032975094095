import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DetailViewDatesClass } from 'src/app/Shared/Classes/DetailViewDatesClass';
import { TSendIsEditing } from '../../../DetailView-baseContainer/DetailView-baseContainer.component';
import { OutputsService } from 'src/app/Services/Utils/Outputs.service';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { iPromotionDates } from 'src/app/Shared/Interfaces/Utils/iPromotionDates';
import { SessionService } from 'src/app/Services/Api/Session.service';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import {
  iDate,
  iUnsubscribeDestroy,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { ClassroomService } from 'src/app/Services/Api/Classroom.service';
import { DispositionService } from 'src/app/Services/Api/Disposition.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { iEditableBoxComponentOptions } from 'src/app/Shared/Components/Editablebox/Editablebox.component';
import { EnviarFechasButtonComponent } from 'src/app/Shared/Components/enviar-fechas-button/enviar-fechas-button.component';
import { ReloadDatesButtonComponent } from 'src/app/Shared/Components/reload-dates-button/reload-dates-button.component';
import { ConflictsService } from 'src/app/Services/Utils/Conflicts.service';
import { FillDatesButtonComponent } from 'src/app/Shared/Components/fillDatesButton/fillDatesButton.component';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { ConfirmarAccionSobreescribirFechasPopupComponent } from 'src/app/Popups/ConfirmarAccionSobreescribirFechas-Popup/ConfirmarAccionSobreescribirFechas-Popup.component';

@Component({
  selector: 'DetailView-Dates',
  templateUrl: './DetailView-Dates.component.html',
  styleUrls: ['./DetailView-Dates.component.scss'],
})
export class DetailViewDatesComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Output() refreshDates: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input()
  get promotionDates() {
    return this.extraClass._promotionDates;
  }
  set promotionDates(value: iPromotionDates) {
    this.extraClass.displayTable = false;
    this.spreadPromotionDates(value);
    this.extraClass._promotionDates = value;
  }

  @Input() promotionId: number = 0;

  @Input() isMaster: boolean = false;

  programacionButtonEnviar: iEditableBoxComponentOptions[] = [
    {
      callback: () => this.sendSelected(),
      component: EnviarFechasButtonComponent,
    },
    {
      callback: () => this.refreshDates.emit(true),
      component: ReloadDatesButtonComponent,
    },
    {
      callback: () => this.updateInfo(),
      component: FillDatesButtonComponent,
    },
  ];

  isDataLoaded: BehaviorSubject<boolean> = this.generalLoaderSE.isDataLoaded();

  extraClass: DetailViewDatesClass = new DetailViewDatesClass();

  SubjectArray: any[] = [];

  constructor(
    private outputsSE: OutputsService,
    private sessionSE: SessionService,
    private generalLoaderSE: GeneralLoaderService,
    private teacherSE: TeacherService,
    private classroomSE: ClassroomService,
    private dispositionSE: DispositionService,
    private promotionSE: PromotionFormationService,
    private conflictsSE: ConflictsService,
    private popupSE: PopupService
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeToOutputs();
    this.getTeacherSelector();
    this.getClassroomSelector();
    this.getDispositionSelector();
    this.getSessionStateSelector();
  }

  override ngOnDestroy(): void {
    this.outputsSE.clearOutputs();
    this.destroySubscriptions();
    this.extraClass.mustSubscribeSubject.next('');
  }

  /**
   * HANDLERS
   */

  successGetTeacherSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.options.items = data.map((teacher) => {
      return {
        id: teacher.id,
        name: `${teacher.user.name} ${teacher.user.surname}`,
      };
    });
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetClassroomSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.optionsClassroom.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetDispositionSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.optionsDisposition.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successSendDatesHandler(res: iResultHttp) {
    const { data } = res;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetSessionStateSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.chipSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateDatesHandler(res: iResultHttp) {
    const { data } = res;
    this.refreshDates.emit(true);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * OUTPUTS
   */

  /**
   * Este es el método que hay que llamar en el NgOnInit para que se suscriba a los outputs de los componentes que se insertan dinámicamente en la tabla.
   */
  subscribeToOutputs() {
    this.outputsSE.mustSubscribe
      .pipe(takeUntil(this.extraClass.mustSubscribeSubject))
      .subscribe((res) => {
        if (!res) return;
        this.destroySubscriptions();
        this.subscribeToOutputsarray();
      });
  }

  /**
   * Este método se encarga de suscribirse a los outputs de los componentes que se insertan dinámicamente en la tabla.
   */
  subscribeToOutputsarray() {
    this.outputsSE.outputsArray.forEach((outputObject, index) => {
      this.extraClass.SubjectArray.push(new Subject());
      outputObject.value
        .pipe(
          takeUntil(this.extraClass.SubjectArray[index]),
          filter((res) => res)
        )
        .subscribe((res) => {
          const { key, component, propertyName, line } = outputObject;
          const { promotionDatesProgram } = this.extraClass;
          const session = promotionDatesProgram[line];
          switch (key) {
            case 'date':
              this.manageUpdateSession(
                iDate.javascriptConvert(new Date(res)).toStringDate('JAP'),
                'startDate',
                session.id,
                'landmark'
              );
              break;
            case 'schedule':
              if (propertyName === 'ngModel1Change') {
                this.manageUpdateSession(
                  res,
                  'startHour',
                  session.id,
                  'landmark'
                );
              }
              if (propertyName === 'ngModel2Change') {
                this.manageUpdateSession(
                  res,
                  'endHour',
                  session.id,
                  'landmark'
                );
              }
              break;
            case 'teachers':
              this.manageUpdateSession(res, key, session.id, 'landmark');
              break;
            case 'classroomId':
              this.manageUpdateSession(res, key, session.id, 'landmark');
              break;
            case 'classroomDispositionId':
              this.manageUpdateSession(res, key, session.id, 'landmark');
              break;
            case 'stateId':
              this.manageUpdateSession(res, key, session.id);
              break;
          }
        });

      //  outputObject.value
      //   .pipe(
      //     takeUntil(this.extraClass.SubjectArray[index]),
      //     filter((res) => res)
      //   )
      //   .subscribe((res) => {
      //     console.log('res', res);

      //     const { key, component, propertyName, line } = outputObject;
      //     const { promotionDatesProgram } = this.extraClass;
      //     const session = promotionDatesProgram[line];

      //     switch (key) {
      //       case 'date':
      //         this.manageUpdateSession(
      //           iDate.javascriptConvert(new Date(res)).toStringDate('JAP'),
      //           'startDate',
      //           session.id,
      //           'landmark'
      //         );
      //         break;
      //       case 'schedule':
      //         if (propertyName === 'ngModel1Change') {
      //           this.manageUpdateSession(
      //             res,
      //             'startHour',
      //             session.id,
      //             'landmark'
      //           );
      //         }
      //         if (propertyName === 'ngModel2Change') {
      //           this.manageUpdateSession(
      //             res,
      //             'endHour',
      //             session.id,
      //             'landmark'
      //           );
      //         }
      //         break;
      //       case 'teachers':
      //         this.manageUpdateSession(res, key, session.id, 'landmark');
      //         break;
      //       case 'classroomId':
      //         this.manageUpdateSession(res, key, session.id, 'landmark');
      //         break;
      //       case 'classroomDispositionId':
      //         this.manageUpdateSession(res, key, session.id, 'landmark');
      //         break;
      //       case 'stateId':
      //         this.manageUpdateSession(res, key, session.id);
      //         break;
      //     }
      //   });
    });
  }

  /**
   * Este método se encarga de destruir las suscripciones a los outputs de los componentes que se insertan dinámicamente en la tabla.
   * Hay que ponerlo en el NgOnDestroy.
   */
  destroySubscriptions() {
    this.extraClass.SubjectArray.forEach((subject) => {
      subject.next('');
      subject.complete();
      subject.unsubscribe();
    });
    this.extraClass.SubjectArray = [];
  }
  /**
   * HANDLERS
   */

  successUpdateSessionHandler(res: iResultHttp) {
    let { data } = res;
    this.extraClass.promotionDatesProgram = data;
  }

  /**
   * FUNCTIONALITY
   */

  handelChangeEdit(event: TSendIsEditing) {
    this.extraClass.editableController = {
      ...this.extraClass.editableController,
      ...event,
    };
    this.extraClass.setAsEditing();
  }

  spreadPromotionDates(promotionDates: iPromotionDates) {
    const { program, tribunals, tutorings } = promotionDates;
    this.extraClass.promotionDatesProgram = program;
    this.extraClass.promotionDatesTribunals = tribunals;
    this.extraClass.promotionDatesTutorings = tutorings;
    setTimeout(() => {
      this.extraClass.displayTable = true;
    }, 10);
  }

  manageUpdateSession(
    event: any,
    key: string,
    id: number,
    option?: 'landmark'
  ) {
    let objectToPass: any = {
      id,
    };

    if (option === 'landmark') {
      objectToPass = {
        ...objectToPass,
        landmark: {
          [key]: event,
        },
      };
    } else {
      objectToPass = {
        ...objectToPass,
        [key]: event,
      };
    }

    this.updateSession(objectToPass);
  }

  handlerSelectedRows(event) {
    this.extraClass.selectedRows = event;
  }

  sendSelected() {
    const { selectedRows } = this.extraClass;
    const objectToPass = {
      sessions: selectedRows.map((row) => row.id),
      id: this.promotionId,
    };
    this.sendDates(objectToPass);
  }
  updateInfo() {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ConfirmarAccionSobreescribirFechasPopupComponent);
    this.popupSE.returnData().subscribe((res) => {
      if (res === undefined) return;
      if (!res?.returnValue) {
        subject.next('');
        subject.complete();
        return;
      }
      this.updateDates(this.promotionId);
      subject.next('');
      subject.complete();
    });
  }

  /**
   * API CALLS
   */

  updateSession(objectToPass) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sessionSE.updateDates(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateSessionHandler(res),
          },
          {
            method: () => this.conflictsSE.manageConflicts(res),
            error: true,
          },
        ]);
      });
  }

  getTeacherSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    this.teacherSE.getTeachersSelector();
    this.teacherSE
      .getResult()
      .pipe(
        takeUntil(this._unsubInd),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.successGetTeacherSelectorHandler(res);
        this._unsubInd.next('');
      });
    this.teacherSE
      .getResultError()
      .pipe(
        takeUntil(this._unsub),
        filter((res) => res)
      )
      .subscribe((res) => {
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        this._unsub.next('');
      });
  }

  getClassroomSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.classroomSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetClassroomSelectorHandler(res),
        });
      });
  }

  getDispositionSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.dispositionSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetDispositionSelectorHandler(res),
        });
      });
  }

  sendDates(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.sendDatesToTeachers(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successSendDatesHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getSessionStateSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sessionSE.getSessionStateSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetSessionStateSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateDates(promotionId: number) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.updateDates(behaviorSubject, { id: promotionId });
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateDatesHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
