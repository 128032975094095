<section class="formationDetail">
  <Editablebox
    (sendIsEditing)="handelChangeEdit($event)"
    class="formationDetail__description__container"
    title="Descripción"
    bgColor="white"
    [fullHeight]="true"
  >
    <div class="formationDetail__description">
      <status-pill
        class="formationDetail__descriptionChip"
        [active]="formationData?.active"
        (click)="toggleActive()"
      ></status-pill>
      <!-- <chip-selector
        [(ngModel)]="formationData.formationState"
        [chipSelectorOptions]="extraClass.stateSelectorOptions"
        class="justify-content-start"
        (change)="editInfo($event, 'formationState')"
      ></chip-selector> -->

      <span
        class="title"
        text-editable
        [canEdit]="extraClass.editableController['Descripción']"
        [(ngModel)]="formationData.name"
        (input)="editInfo(formationData.name, 'name')"
        >{{ formationData?.name }}</span
      >
      <p
        text-editable
        [canEdit]="extraClass.editableController['Descripción']"
        [(ngModel)]="formationData.description"
        (input)="editInfo(formationData.description, 'description')"
      >
        {{ formationData?.description }}
      </p>
    </div>
  </Editablebox>
  <Editablebox
    title="Objetivos"
    bgColor="white"
    [fullHeight]="true"
    (sendIsEditing)="handelChangeEdit($event)"
  >
    <div class="formationDetail__objectives">
      <p
        text-editable
        [canEdit]="extraClass.editableController['Objetivos']"
        [(ngModel)]="formationData.objectives"
        (input)="editInfo(formationData.objectives, 'objectives')"
      >
        {{ formationData?.objectives }}
      </p>
    </div>
  </Editablebox>

  <Editablebox
    class="formationDetail__director__Container"
    title="Directores/as del máster"
    bgColor="white"
    [canEdit]="false"
    (sendIsEditing)="handelChangeEdit($event)"
    *ngIf="formationType === 'master'"
  >
    <DetailView-Director
      [directors]="directorsData"
      [isEditing]="extraClass.editableController['Directores/as del máster']"
    ></DetailView-Director>
  </Editablebox>

  <span *ngIf="formationType !== 'master'"></span>

  <Editablebox
    title="Características generales"
    bgColor="gray"
    (sendIsEditing)="handelChangeEdit($event)"
  >
    <div class="formationDetail__general">
      <div class="formationDetail__general__modality">
        <span class="title">Modalidad:</span>
        <ng-container
          *ngFor="let modality of extraClass.attendantModalityArray"
        >
          <p
            [ngClass]="{ active: modality.active }"
            (click)="editModality(modality)"
          >
            {{ modality.name }}
          </p>
        </ng-container>
      </div>
      <div class="formationDetail__general__prices">
        <span class="title">Precios</span>
        <br />
        <ng-container
          *ngFor="let modality of pricesData; first as first; index as index"
        >
          <ng-container>
            <p class="formationDetail__general__prices__type">
              {{ modality.modality }}
            </p>
            <div class="priceWithEuro">
              <p
                class="formationDetail__general__prices__price"
                text-editable
                [canEdit]="
                  extraClass.editableController['Características generales']
                "
                (input)="
                  checkIfNumberAndEdit($event, 'price', 'modalityPrice', index)
                "
              >
                {{ modality?.price }}
              </p>
              <p class="bold">€</p>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="formationDetail__general__duration">
        <span class="title"> Horas totales:</span>
        <p
          text-editable
          [canEdit]="extraClass.editableController['Características generales']"
          (input)="checkIfNumberAndEdit($event, 'duration', 'formacion')"
        >
          {{ formationData?.duration }}
        </p>
      </div>
      <div class="formationDetail__general__categorie">
        <span class="title">Categoría:</span>

        <add-and-search-selector
          class="blueSelector"
          [reverse]="true"
          [model]="formationData.topicId"
          [selectorOptions]="extraClass.categorySelectorOptions"
          (change)="editInfoNoDebounce($event, 'topic')"
          (addElementToSelector)="addTopic($event)"
          (deleteSelector)="deleteTopicFromSelector($event)"
        ></add-and-search-selector>
      </div>
      <div class="formationDetail__general__lang">
        <span class="title">Idioma:</span>
        <blue-selector
          [readonly]="
            !extraClass.editableController['Características generales']
          "
          [optionsSelector]="extraClass.languageSelectorOptions"
          [(ngModel)]="formationData.language"
          (change)="editInfo(formationData.language, 'language')"
        ></blue-selector>
      </div>
    </div>
  </Editablebox>
  <Editablebox
    class="formationDetail__content__Container"
    title="Contenidos Generales"
    bgColor="gray"
    (sendIsEditing)="handelChangeEdit($event)"
  >
    <DetailView-Content
      [modules]="modulesData"
      [promotion]="promotionTemplateId"
      [isEditing]="extraClass.editableController['Contenidos Generales']"
      (onCreate)="handleCreateModule($event)"
    ></DetailView-Content>
  </Editablebox>
</section>
