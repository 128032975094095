import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { slideNavigationTypes } from '@quasar-dynamics/basic-designsystem';
import {
  StaticUtilitiesService,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar_dynamics/basic-designsystem';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { iBlueDataPicker } from 'src/app/Shared/Components/blue-data-picker/blue-data-picker.component';
import { headerData } from 'src/app/Shared/Components/main-table/main-table.component';
import { iOptionMenu } from 'src/app/Shared/Components/options-menu/options-menu.component';

@Component({
  selector: 'formacionesNewVersion-Todos-Table',
  templateUrl: './formacionesNewVersion-Todos-Table.component.html',
  styleUrls: ['./formacionesNewVersion-Todos-Table.component.scss'],
})
export class FormacionesNewVersionTodosTableComponent implements OnInit {
  @Input() allFormations: any =
    StaticDataHandlerService.getFormacionesSkelleton();
  @Input() totalFormations: number = 0;
  @Input() areReadyAllFormations: boolean = false;
  @Input() filterAllFormations: any = null;

  @Output() pageChange: EventEmitter<boolean> = new EventEmitter();

  tableHead: headerData = [
    {
      variant: 'bold',
      display: 'Nombre',
      key: 'name',
      alignment: 'start',
    },
    {
      variant: 'standard',
      display: 'Horas',
      key: 'duration',
    },
    {
      variant: 'standard',
      display: 'Modalidad ',
      key: 'mode',
    },
    {
      variant: 'chip',
      display: 'Estado',
      key: 'activeLabel',
      classNameGenerator: (data: any, index: number, row: any) => {
        if (!row.active) return '--redState';
        return row.active === true ? '--greenState' : '--redState';
      },
    },
    {
      variant: 'dropdown',
      key: 'actions',
      display: '',
      options: [
        {
          action: 'edit',
          label: 'Editar',
          icon: 'edit',
        },
        {
          action: 'delete',
          label: 'Eliminar',
          icon: 'delete',
        },
      ],
    },
  ];

  constructor(private staticUtilitiesSE: StaticUtilitiesService) {}

  ngOnInit() {}

  /**
   * HANDLERS
   */

  handleClickRow(formation) {
    this.goToDetailView(formation);
  }

  goToDetailView(formation: any) {
    switch (formation.typeOf) {
      case 'master':
        this.staticUtilitiesSE.goTo(
          'formaciones/formaciones-detalle/',
          slideNavigationTypes.slideToTop,
          { master: formation.idMaster }
        );
        break;

      case 'course':
        this.staticUtilitiesSE.goTo(
          'formaciones/formaciones-detalle/',
          slideNavigationTypes.slideToTop,
          { curso: formation.idCourse }
        );
        break;

      default:
        break;
    }
  }

  pageChangeEmmiter(event) {
    this.pageChange.emit(event);
  }
}
