import { Component, Input, OnInit } from '@angular/core';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import { iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { TutoringService } from 'src/app/Services/Api/Tutoring.service';
import { DetailViewTutoriasClass } from 'src/app/Shared/Classes/DetailViewTutoriasClass';
import { iPromotionTutoring } from 'src/app/Shared/Interfaces/Utils/iPromotionTutoring';

@Component({
  selector: 'DetailView-Tutorias',
  templateUrl: './DetailView-Tutorias.component.html',
  styleUrls: ['./DetailView-Tutorias.component.scss'],
})
export class DetailViewTutoriasComponent implements OnInit {
   
  @Input() get promotionId() {
    return this.extraClass._promotionId;
  }
  set promotionId(val) {
    if (!val) return;
    this.extraClass._promotionId = val;
  }

  @Input() get tutoringPromotion(){
    return this.extraClass.tutoringPromotion;
  }
  set tutoringPromotion(val:iPromotionTutoring[]){
    if(!val) return;
    this.extraClass.tutoringPromotion = val;
  }



  extraClass: DetailViewTutoriasClass = new DetailViewTutoriasClass();

  constructor(
    private generalLoaderSE: GeneralLoaderService,
    private tutoringSE: TutoringService
  ) {}

  ngOnInit() {}

  /**
   * HANDLERS
   */

  /**
   * FUNCTIONALITIES
   */

  /**
   * TRACK BY
   */

  tutoringTrackBy(index: number, item: any) {
    return item.tutoringId;
  }

  teacherTrackBy(index: number, item: any) {
    return item.id;
  }


  /**
   * API CALLS
   */

}
