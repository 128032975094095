import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
  iUnsubscribeDestroy,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil, timer } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { CountryService } from 'src/app/Services/Api/Country.service';
import { EnrollmentService } from 'src/app/Services/Api/Enrollment.service';
import { GubrtService } from 'src/app/Services/Api/Gubrt.service';
import { StudentService } from 'src/app/Services/Api/Student.service';

declare let window: Window; // Importa la interfaz Window

@Component({
  selector: 'app-AutomatriculaFormulario',
  templateUrl: './AutomatriculaFormulario.component.html',
  styleUrls: ['./AutomatriculaFormulario.component.scss'],
})
export class AutomatriculaFormularioComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @HostListener('window:beforeunload', ['$event'])
  countriesList: any[] = [];
  studentData: any = null;
  optionSelected: number = 0;
  isMetodosDePagoNeeded: boolean = false;
  timer: number = 0;
  isModalShown: boolean = false;
  isChecked: boolean = false;

  dataFromStepOne: any = null;
  dataFromStepFour: any = null;
  dataFromStepFive: any = null;
  token: any = null;

  sameAddress: boolean = false;

  constructor(
    private countrySE: CountryService,
    private activatedRoute: ActivatedRoute,
    private studentSE: StudentService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private popupSE: PopupService,
    private enrollmentSE: EnrollmentService,
    private gubrtSE: GubrtService
  ) {
    super();
  }

  ngOnInit() {
    this.getInfo();
    this.getCountries();
  }

  /**
   * GETTERS && SETTERS
   */

  async getInfo() {
    let token = await this.getToken();
    this.token = token;
    this.getStudentByToken(token);
  }
  getToken(): Promise<any> {
    return new Promise((res, rej) => {
      let token = sessionStorage.getItem('token');

      if (token) {
        res({ token: token });
      } else {
        StaticUtilitiesService.showError(
          'No se ha encontrado el token, vuelva a intentarlo.'
        );
        this.staticUtilitiesSE.goTo(
          '/login',
          slideNavigationTypes.slideToRight,
          null
        );
        rej();
      }
    });
  }
  getSendSameAddressBoolean(event) {
    this.sameAddress = event;
  }
  getDataFromStepOne(data: any) {
    this.dataFromStepOne = data;
    this.checkIfComplete(this.studentData.user);
  }
  getDataFromStepTwo(data: any) {
    this.studentData = data;
    this.checkIfComplete(this.studentData.user);
  }
  getDataFromStepThree(data: any) {
    this.studentData = data;
    this.checkIfComplete(this.studentData.user);
  }
  getDataFromStepFour(data: any) {
    this.dataFromStepFour = data;
    this.checkIfComplete(this.studentData.user);
  }
  getDataFromStepFive(data: any) {
    this.dataFromStepFive = data;
    this.checkIfComplete(this.studentData.user);
  }
  next() {
    if (!this.isMetodosDePagoNeeded) {
      if (this.optionSelected == 4) {
        this.confirmarPopup();
        return;
      }
    }
    if (this.optionSelected == 5) {
      this.confirmarPopup();
      return;
    }
    this.optionSelected++;
  }
  previous() {
    if (this.optionSelected == 0) return;
    this.optionSelected--;
  }
  refresh(event) {
    if (!event) return;
    this.getInfo();
  }

  checkIfComplete(data: any = null) {
    let objectToCheck: any = {
      name: data.name,
      surname: data.surname,
      email: data.email,
      alias: data.alias,
      gender: data.gender,
      dateOfBirth: data.dateOfBirth,
      phone: data.phone,
      phonePrefix: data.phonePrefix,
      dni: data.dni,
      nationality: data.nationality,
      employmentStatus: data.employmentStatus,
    };
    if (data.employmentStatus === 'Activo') {
      objectToCheck.company = data.company;
      objectToCheck.jobPosition = data.jobPosition;
    }
    this.isChecked = StaticUtilitiesService.isCompleteObject(objectToCheck);
  }

  setAddresses(data) {
    data.user.addresses.forEach((address) => {
      if (address.name == 'Dirección personal') {
        data.user.address = address;
        if (!address.country) {
          address.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
        }
      }
      if (address.name == 'Dirección personal 02') {
        data.user.address2 = address;
        if (!address.country) {
          address.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
        }
      }
      if (address.name == 'Dirección fiscal') {
        data.user.bankData.fiscalAddress = address;
        if (!address.country) {
          address.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
        }
      }
    });
  }

  /**
   * API CALLS
   */

  getStudentByToken(token: string) {
    this.studentSE.getUserByRecoveryToken(token);
    this.studentSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.studentData = data;
        this.setAddresses(this.studentData);
        this.checkIfComplete(data.user);
        this._unsubInd2.next('');
      });
    this.studentSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }

        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
  getIsMetodosDePagoNeeded(data: boolean) {
    this.isMetodosDePagoNeeded = data;
  }
  showModal() {
    this.isModalShown = true;
    sessionStorage.removeItem('token');
  }
  confirmarPopup() {
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      accion: 'Finalizar',
      elemento: 'el formulario de automatrícula',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (!res) return;
        this.studentData.enrollments.forEach((enrollment) => {
          let dataToPass: any = {
            token: this.token,
            enrollment: {
              id: enrollment.id,
              phase: 'to_revise',
            },
          };
          this.editPhase(dataToPass);
        });
        this._unsubInd9.next('');
      });
  }

  /**
   * API CALLS
   */

  getCountries() {
    this.countrySE.getCountries();
    this.countrySE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) return;
        let { data } = res;

        this.countriesList = data;
        this._unsubInd.next('');
      });
  }

  editPhase(data) {
    this.gubrtSE.editGubrtOldVersion(data);
    this.gubrtSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        this.showModal();
        this._unsubInd2.next('');
      });
    this.gubrtSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          window.open('/', '_self');
          StaticUtilitiesService.showError(
            'No tienes permisos para estar aquí'
          );
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
