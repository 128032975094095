import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GestionUsuariosAlumnosDetallesComponent } from './Pages/GestionUsuarios/GestionUsuarios-Alumnos/GestionUsuarios-Alumnos-Detalles/GestionUsuarios-Alumnos-Detalles.component';
import { GestionUsuariosAlumnosListadoComponent } from './Pages/GestionUsuarios/GestionUsuarios-Alumnos/GestionUsuarios-Alumnos-Listado/GestionUsuarios-Alumnos-Listado.component';
import { GestionUsuariosMatriculacionesComponent } from './Pages/GestionUsuarios/GestionUsuarios-Matriculaciones/GestionUsuarios-Matriculaciones.component';
import { GestionUsuariosProfesoresCrearComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Crear/GestionUsuarios-Profesores-Crear.component';
import { GestionUsuariosProfesoresDetallesComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Detalles/GestionUsuarios-Profesores-Detalles.component';
import { GestionUsuariosProfesoresListadoComponent } from './Pages/GestionUsuarios/GestionUsuarios-Profesores/GestionUsuarios-Profesores-Listado/GestionUsuarios-Profesores-Listado.component';
import { LoginComponent } from './Pages/Login/Login/Login.component';
import { AutomatriculaViewComponent } from './Pages/Automatricula/AutomatriculaView/AutomatriculaView.component';
import { AutomatriculaFormularioComponent } from './Pages/Automatricula/AutomatriculaFormulario/AutomatriculaFormulario.component';
import { GestionUsuariosAlumnosCrearComponent } from './Pages/GestionUsuarios/GestionUsuarios-Alumnos/GestionUsuarios-Alumnos-Crear/GestionUsuarios-Alumnos-Crear.component';
import { GestionUsuariosIncompaniesComponent } from './Pages/GestionUsuarios/GestionUsuarios-Incompanies/GestionUsuarios-Incompanies.component';
import { GestionUsuariosConfiguracionComponent } from './Pages/GestionUsuarios/GestionUsuarios-Configuracion/GestionUsuarios-Configuracion.component';
import { GestionUsuariosFormacionesComponentNewVersion } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/GestionUsuarios-Formaciones.component';
import { FormacionesCrearComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-Crear/Formaciones-Crear.component';
import { FormacionesDetailViewComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/Formaciones-DetailView.component';
import { PromotionsCrearComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-promotions/Promotions-Crear/Promotions-Crear.component';
import { PromotionsDetailViewComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-promotions/Promotions-DetailView/Promotions-DetailView.component';
import { IncompaniesCreateComponent } from './Pages/GestionUsuarios/GestionUsuarios-Incompanies/_holder/Incompanies-Create/Incompanies-Create.component';
import { IncompaniesCreatePromotionsComponent } from './Pages/GestionUsuarios/GestionUsuarios-Incompanies/_holder/Incompanies-Create-Promotions/Incompanies-Create-Promotions.component';
import { IncompaniesCheckThirdColumnComponent } from './Pages/GestionUsuarios/GestionUsuarios-Incompanies/_holder/Incompanies-CheckThirdColumn/Incompanies-CheckThirdColumn.component';
import { HasTokenGuard } from './Services/Utils/Guards/has-token.guard';
import { GestionUsuariosMainViewComponent } from './Pages/GestionUsuarios/GestionUsuarios-MainView/GestionUsuarios-MainView.component';
import { CanActivateFormationGuard } from './Services/Utils/Guards/can-activate-formation.guard';
import { HeOlvidadoMiContrasenaComponent } from './Pages/Login/HeOlvidadoMiContrasena/HeOlvidadoMiContrasena.component';
import { RecoverPasswordComponent } from './Pages/Login/RecoverPassword/RecoverPassword.component';
import { GestionUsuariosMatriculacionesCursosComponent } from './Pages/GestionUsuarios/GestionUsuarios-Matriculaciones-Cursos/GestionUsuarios-Matriculaciones-Cursos.component';
import { TestComponent } from './Pages/Test/Test.component';
import { GestionUsuariosCalendarioGeneralComponent } from './Pages/GestionUsuarios/GestionUsuarios-CalendarioGeneral/GestionUsuarios-CalendarioGeneral.component';
import { CalendarioGeneralTimelineComponent } from './Pages/GestionUsuarios/GestionUsuarios-CalendarioGeneral/CalendarioGeneral-Timeline/CalendarioGeneral-Timeline.component';
import { TutoriaAlumnoComponent } from './Pages/Tutoria-Alumno/Tutoria-Alumno.component';
import { TutoriaAlumnoRellenaDatosReservaComponent } from './Pages/Tutoria-Alumno/_holder/Tutoria-Alumno-RellenaDatosReserva/Tutoria-Alumno-RellenaDatosReserva.component';
import { DobleTitulacionDetailViewComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/DobleTitulacion-DetailView/DobleTitulacion-DetailView.component';
import { TutoriaAlumnoSuccessComponent } from './Pages/Tutoria-Alumno/_holder/Tutoria-Alumno-Success/Tutoria-Alumno-Success.component';
import { TutoriaCrearComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/FormacionesNewVersion-Tutorias/_holder/Tutoria-Crear/Tutoria-Crear.component';
import { TutoriaByTeacherComponent } from './Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/FormacionesNewVersion-Tutorias/_holder/Tutoria-By-Teacher/Tutoria-By-Teacher.component';
import { GestionUsuariosHomeComponent } from './Pages/GestionUsuarios/GestionUsuarios-Home/GestionUsuarios-Home.component';

const routes: Routes = [
  /**
   * GESTION USUARIOS
   */
  { path: '', redirectTo: '/login', pathMatch: 'full' },

  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'he-olvidado-mi-contrasena',
    component: HeOlvidadoMiContrasenaComponent,
  },
  {
    path: 'recupera-pass',
    component: RecoverPasswordComponent,
  },

  /**
   * AUTOMATRICULA
   */

  {
    path: 'automatricula',
    data: { title: 'Automatricula' },
    children: [
      { path: '', redirectTo: '/automatricula/view', pathMatch: 'full' },
      {
        path: 'view',
        component: AutomatriculaViewComponent,
        data: {
          title: 'Vista',
          breadcrumb: [{ name: 'Automatricula', link: './', bold: true }],
        },
      },
      {
        path: 'form',
        component: AutomatriculaFormularioComponent,
        data: {
          title: 'Formulario',
          breadcrumb: [
            { name: 'Automatricula', link: '/automatricula', bold: false },
            { name: 'Formulario', link: './', bold: true },
          ],
        },
      },
    ],
  },

  /**
   * HOME
   */

  {
    path: 'home',
    data: { title: 'Home' },
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      {
        path: '',
        component: GestionUsuariosHomeComponent,
        data: {
          title: 'Home',
          breadcrumb: [{ name: 'Home', link: './', bold: true }],
        },
      },
    ],
  },

  {
    path: 'calendario-general',
    data: { title: 'Calendario general' },
    children: [
      { path: '', redirectTo: '/calendario-general', pathMatch: 'full' },
      {
        path: '',
        component: GestionUsuariosCalendarioGeneralComponent,
        data: {
          title: 'Calendario',
          breadcrumb: [{ name: 'Calendario', link: './', bold: true }],
        },
      },
      {
        path: 'timeline',
        component: CalendarioGeneralTimelineComponent,
        data: {
          title: 'Calendario-timeline',
          breadcrumb: [
            { name: 'Calendario', link: '/calendario-general' },
            { name: 'Timeline', link: './', bold: true },
          ],
        },
      },
    ],
  },

  /**
   * TUTORIA ALUMNO
   */

  {
    path: 'tutoria-alumno',
    data: { title: 'Tutoria del alumno' },
    children: [
      { path: '', redirectTo: '/tutoria-alumno', pathMatch: 'full' },
      {
        path: '',
        component: TutoriaAlumnoComponent,
        data: {
          title: 'Tutoria del alumno',
          breadcrumb: [{ name: 'Tutoria del alumno', link: './', bold: true }],
        },
      },
      {
        path: 'reserva',
        component: TutoriaAlumnoRellenaDatosReservaComponent,
        data: {
          title: 'Reserva de tutoria',
          breadcrumb: [{ name: 'Reserva de tutoria', link: './', bold: true }],
        },
      },
      {
        path: 'success',
        component: TutoriaAlumnoSuccessComponent,
        data: {
          title: 'Reserva de tutoria',
          breadcrumb: [{ name: 'Reserva de tutoria', link: './', bold: true }],
        },
      },
    ],
  },

  /**
   * PROFESORES
   */
  {
    path: 'profesores',
    data: { title: 'Exploración del listado de los profesores' },
    canActivate: [HasTokenGuard],
    children: [
      {
        path: '',
        redirectTo: '/profesores/mis-profesores/listado-profesores',
        pathMatch: 'full',
      },
      {
        path: 'mis-profesores/listado-profesores',
        component: GestionUsuariosProfesoresListadoComponent,
        data: {
          title: 'Exploración del listado de los profesores',
          breadcrumb: [
            { name: 'Listado de profesores', link: './', bold: true },
          ],
        },
      },
      {
        path: 'mis-profesores/ficha-profesor',
        component: GestionUsuariosProfesoresDetallesComponent,
        data: {
          title: 'Ficha del profesor',
          breadcrumb: [
            {
              name: 'Listado de profesores',
              link: '/profesores/mis-profesores/listado-profesores',
              bold: false,
            },
            { name: 'Detalles de profesor', link: './', bold: true },
          ],
        },
      },
      {
        path: 'mis-profesores/crear-profesor',
        component: GestionUsuariosProfesoresCrearComponent,
        data: {
          title: 'Crear nuevo profesor',
          breadcrumb: [
            {
              name: 'Listado de profesores',
              link: '/profesores/mis-profesores/listado-profesores',
              bold: false,
            },
            { name: 'Crear profesor', link: './', bold: true },
          ],
        },
      },
    ],
  },

  /**
   * ALUMNOS
   */
  {
    path: 'alumnos',
    data: { title: 'Exploración del listado de los estudiantes' },
    canActivate: [HasTokenGuard],
    children: [
      {
        path: '',
        redirectTo: '/alumnos/mis-alumnos/listado-estudiantes',
        pathMatch: 'full',
      },
      {
        path: 'mis-alumnos/listado-alumnos',
        component: GestionUsuariosAlumnosListadoComponent,
        data: {
          title: 'Exploración del listado de los alumnos',
          breadcrumb: [{ name: 'Listado de alumnos', link: './', bold: true }],
        },
      },
      {
        path: 'mis-alumnos/ficha-alumno',
        component: GestionUsuariosAlumnosDetallesComponent,
        data: {
          title: 'Ficha del alumno',
          breadcrumb: [
            {
              name: 'Listado de alumnos',
              link: '/alumnos/mis-alumnos/listado-alumnos',
              bold: false,
            },
            { name: 'Detalles del alumno', link: './', bold: true },
          ],
        },
      },
      {
        path: 'mis-alumnos/crear-alumno',
        component: GestionUsuariosAlumnosCrearComponent,
        data: {
          title: 'Crear alumno',
          breadcrumb: [
            {
              name: 'Listado de alumnos',
              link: '/alumnos/mis-alumnos/listado-alumnos',
              bold: false,
            },
            { name: 'Crear alumno', link: './', bold: true },
          ],
        },
      },
    ],
  },
  /**
   * MATRICULACIONES
   */
  {
    path: 'matriculaciones',
    data: { title: 'Exploración de las matriculaciones' },
    canActivate: [HasTokenGuard],
    children: [
      {
        path: '',
        redirectTo: 'masters-cursos/listado-matriculaciones',
        pathMatch: 'full',
      },
      {
        path: 'masters-cursos/listado-matriculaciones',
        component: GestionUsuariosMatriculacionesComponent,
        data: {
          title: 'Exploración del listado de las matriculaciones',
          breadcrumb: [
            {
              name: 'Listado de alumnos',
              link: '/alumnos/mis-alumnos/listado-alumnos',
              bold: false,
            },
            { name: 'Matriculaciones Master', link: './', bold: true },
          ],
        },
      },
      {
        path: 'cursos/listado-matriculaciones',
        component: GestionUsuariosMatriculacionesCursosComponent,
        data: {
          title: 'Exploración del listado de las matriculaciones',
          breadcrumb: [
            {
              name: 'Listado de alumnos',
              link: '/alumnos/mis-alumnos/listado-alumnos',
              bold: false,
            },
            { name: 'Matriculaciones Cursos', link: './', bold: true },
          ],
        },
      },
      {
        path: 'incompanies/listado-matriculaciones',
        component: GestionUsuariosIncompaniesComponent,
        data: {
          title: 'Exploración del listado de incompanies',
          breadcrumb: [
            {
              name: 'Listado de alumnos',
              link: '/alumnos/mis-alumnos/listado-alumnos',
              bold: false,
            },
            { name: 'Matriculaciones Incompanies', link: './', bold: true },
          ],
        },
      },
      {
        path: 'incompanies/confirmar-matriculacion',
        component: IncompaniesCheckThirdColumnComponent,
        data: {
          title: 'Verificación de la matrícula del grupo de incompany',
          breadcrumb: [
            {
              name: 'matriculaciones Incompany',
              link: '/matriculaciones/incompanies/listado-matriculaciones',
              bold: false,
            },
            {
              name: 'Verificación de matrícula de grupo incompany',
              link: './',
              bold: true,
            },
          ],
        },
      },
    ],
  },
  /**
   * CONFIGURACIÓN
   */
  {
    path: 'configuracion',
    data: { title: 'Configuracion del cerebro de IEM' },
    canActivate: [HasTokenGuard],
    children: [
      { path: '', redirectTo: 'configuracion-general', pathMatch: 'full' },
      {
        path: 'configuracion-general',
        component: GestionUsuariosConfiguracionComponent,
        data: {
          title: 'Configuración general del cerebro de IEM',
          breadcrumb: [
            {
              name: 'Configuración',
              link: '/configuracion/configuracion-general',
              bold: true,
            },
          ],
        },
      },
    ],
  },
  /**
   * FORMACIONES
   */
  {
    path: 'formaciones',
    data: { title: 'Exploración del listado de las formaciones' },
    canActivate: [HasTokenGuard, CanActivateFormationGuard],
    children: [
      { path: '', redirectTo: 'listado-formaciones', pathMatch: 'full' },
      {
        path: 'listado-formaciones',
        component: GestionUsuariosFormacionesComponentNewVersion,
        data: {
          title: 'Listado de formaciones',
          breadcrumb: [
            {
              name: 'Formaciones',
              link: '/formaciones/listado-formaciones',
              bold: true,
            },
          ],
        },
      },
      {
        path: 'crear-formaciones',
        component: FormacionesCrearComponent,
        data: {
          title: 'Crear formación',
          breadcrumb: [
            {
              name: 'Formaciones',
              link: '/formaciones/listado-formaciones',
              bold: true,
            },
            {
              name: 'Formaciones',
              link: './',
              bold: true,
            },
          ],
        },
      },
      {
        path: 'formaciones-detalle',
        component: FormacionesDetailViewComponent,
        data: {
          title: 'Detalle de la formación',
          breadcrumb: [
            {
              name: 'Formaciones',
              link: '/formaciones/listado-formaciones',
              bold: false,
            },
            { name: 'Detalle de la formación', link: './', bold: true },
          ],
        },
      },
      {
        path: 'doble-matricula-detalle',
        component: DobleTitulacionDetailViewComponent,
        data: {
          title: 'Detalle de la doble matricula',
          breadcrumb: [
            {
              name: 'Formaciones',
              link: '/formaciones/listado-formaciones',
              bold: false,
            },
            { name: 'Detalle de la doble matrícula', link: './', bold: true },
          ],
        },
      },
      {
        path: 'tutoria-profesor',
        component: TutoriaByTeacherComponent,
        data: {
          title: 'Tutoría por frofesor',
          breadcrumb: [
            {
              name: 'Formaciones',
              link: '/formaciones/listado-formaciones',
              bold: false,
            },
            { name: 'Tutoría por profesor', link: './', bold: true },
          ],
        },
      },
      {
        path: 'crear-promocion',
        component: PromotionsCrearComponent,
        data: {
          title: 'Crear promoción',
          breadcrumb: [
            {
              name: 'Formaciones',
              link: '/formaciones/listado-formaciones',
              bold: false,
            },
            { name: 'Crear promoción', link: './', bold: true },
          ],
        },
      },
      {
        path: 'promocion-detalle',
        component: PromotionsDetailViewComponent,
        data: {
          title: 'Detalle de la promoción',
          breadcrumb: [
            {
              name: 'Formaciones',
              link: '/formaciones/listado-formaciones',
              bold: false,
            },
            { name: 'Promoción detalle', link: './', bold: true },
          ],
        },
      },
      {
        path: 'crear-tutoria',
        component: TutoriaCrearComponent,
        data: {
          title: 'Crear tutoria',
          breadcrumb: [
            {
              name: 'Formaciones',
              link: '/formaciones/listado-formaciones',
              bold: false,
            },
            { name: 'Crear tutoria', link: './', bold: true },
          ],
        },
      },
      {
        path: 'crear-incompany',
        component: IncompaniesCreateComponent,
        data: {
          title: 'Crear incompany',
          breadcrumb: [
            {
              name: 'Formaciones',
              link: '/formaciones/listado-formaciones',
              bold: false,
            },
            { name: 'Crear incompany', link: './', bold: true },
          ],
        },
      },
      {
        path: 'crear-incompany-promociones',
        component: IncompaniesCreatePromotionsComponent,
        data: {
          title: 'Crear promociones incompany',
          breadcrumb: [
            {
              name: 'Formaciones',
              link: '/formaciones/listado-formaciones',
              bold: false,
            },
            { name: 'Crear promoción incompany', link: './', bold: true },
          ],
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
