import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iOptionsRating } from '../../Interfaces/iOptionsRating';
import { DebouncerService } from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'rating-text-editable',
  templateUrl: './rating-text-editable.component.html',
  styleUrls: ['./rating-text-editable.component.scss'],
})
export class RatingTextEditableComponent implements OnInit {
  // numberRegex
  private numberRegex = /^-?\d*([.,]\d*)?$/;

  @Output() ratingChange = new EventEmitter();

  @Input() options: iOptionsRating = {
    rating: '',
    readonly: true,
    maxRating: 5,
  };

  constructor() {}

  ngOnInit() {}

  getEditableContent(event) {
    let text = event.target.innerHTML.replace(',', '.');
    // If it is a number
    if (this.numberRegex.test(text)) {
      const finalText = this.checkIfNumberIsGreaterThanMaxRatingOrBelowZero(event, text);
      DebouncerService.simpleDebouncer(() => this.emitChange(finalText));
    } else {
      // We are filtering the text to only allow numbers
      event.target.innerHTML = text.replace(/[^0-9.-]/g, '');
    }
  }

  checkIfNumberIsGreaterThanMaxRatingOrBelowZero(event, text) {
    let num = parseFloat(text);
    if (num > 10) {
      num = 10;
      event.target.innerHTML = num.toString();
      return num.toString();
    } else if(num < 0) {
      num = 0;
      event.target.innerHTML = num.toString();
      return num.toString();
    }
     else {
      return text;
    }
  }

  emitChange(text) {
    this.ratingChange.emit(text);
  }
}
