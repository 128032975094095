import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HoldingService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  protected _GetHoldingList = new BehaviorSubject<any>(null);
  getResultGetHoldingList() {return this._GetHoldingList}
  clearResultGetHoldingList() {this._GetHoldingList.next(null)}
  protected _GetHoldingListError = new BehaviorSubject<any>(null);
  getResultGetHoldingListError() {return this._GetHoldingListError}
  clearResultGetHoldingListError() {this._GetHoldingListError.next(null)}
  
  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: any){
    this.http.get(this.urlApi + '/api/holding' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormation =  iStaticUtilities.normalizeNamesCollection(data.data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:normalizedFormation,total:data.total}});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAll, args: [queryParams] });
        this.checkStatusError(error);
      },
    });    
  }

  getList(){
    this.http.get(this.urlApi + '/api/holding/selector'  , this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormation =  iStaticUtilities.normalizeNamesCollection(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedFormation});
        this.sendNextObservable(this._GetHoldingList, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._GetHoldingListError, this.res,true, { method: this.getList, args: [] });
        this.checkStatusError(error);
      },
    });    
  }

  getSingle(id:number){
    this.http.get(this.urlApi + '/api/holding/' + id  , this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormation =  iStaticUtilities.normalizeNames(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedFormation});
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getSingle, args: [id] });
        this.checkStatusError(error);
      },
    });    
  }


}
