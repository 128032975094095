import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PopupService, StaticUtilitiesService, iPipeLine, iResultHttp, iStaticUtilities, iUnsubscribeDestroy, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AddExcelPopupComponent } from 'src/app/Popups/AddPopup/AddExcel-Popup/AddExcel-Popup.component';
import { AddStudentEnrollmentPopupComponent } from 'src/app/Popups/AddPopup/AddStudentEnrollment-Popup/AddStudentEnrollment-Popup.component';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { ConfirmarAccionPerderPopupComponent } from 'src/app/Popups/ConfirmarAccionPerder-Popup/ConfirmarAccionPerder-Popup.component';
import { CursoMasterMatriculaConfirmaPopupComponent } from 'src/app/Popups/CursoMasterMatriculaConfirma-Popup/CursoMasterMatriculaConfirma-Popup.component';
import { PropuestaFormalAccionPopupComponent } from 'src/app/Popups/PropuestaFormalAccion-Popup/PropuestaFormalAccion-Popup.component';
import { AutoenrollmentTokenService } from 'src/app/Services/Api/AutoenrollmentToken.service';
import { EnrollmentService } from 'src/app/Services/Api/Enrollment.service';
import { GroupService } from 'src/app/Services/Api/Group.service';
import { ProposalService } from 'src/app/Services/Api/Proposal.service';

@Component({
  selector: 'Incompanies-Pipeline',
  templateUrl: './Incompanies-Pipeline.component.html',
  styleUrls: ['./Incompanies-Pipeline.component.scss']
})
export class IncompaniesPipelineComponent extends iUnsubscribeDestroy implements OnInit {
 
 
  @HostListener('window:scroll', ['$event']) onWindowScroll(event) {
     
  }
  
  
  @ViewChild('bubble', { static: true }) bubble!: ElementRef;


  @Input() matriculaciones: Array<any> = [];
  
  @Input() incompanyCRM: any = null;
  @Input() formalizedProposals: Array<any> = [];
  @Input() thirdColumnIncompanies: Array<any> = [];

  @Input() rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  @Input() isPropuestaFormalLoaded: boolean = false;
  @Input() isCRMIncompanies: boolean = false;
  @Input() isThirdColumn: boolean = false;

  @Input() dataPhasesEnrollment: Array<any> = [];
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  // delete
  interval: any;

  conexionCRMFallida: boolean = true;
  loadingElements: any[] = [];
  loaderUp: boolean = false;
  pipeList: Array<iPipeLine> = [
    { title: 'CRM', items: [] },
    { title: 'Propuesta Formal', items: [] },
    { title: 'Pendiente de verificación', items: [] },
  ];
  placeholder = "./assets/Images/Placeholder/placeholder.jpg";
  constructor(private enrollmentSE: EnrollmentService, private popupSE:PopupService, private autoenrollmentTokenSE:AutoenrollmentTokenService, private staticUtilitiesSE: StaticUtilitiesService, private groupSE:GroupService, private proposalSE:ProposalService) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['thirdColumnIncompanies']){
      if(changes['thirdColumnIncompanies'].currentValue){
         
      }
    }
  }

  ngAfterViewInit() {
    (document.querySelector(".pageContainer") as HTMLElement).addEventListener("scroll", (e:any) => {
      const scrollPosition = e.target.scrollTop;
      if( scrollPosition > 0 ){
        this.bubble.nativeElement.classList.add("visible");
      }
      else{
        this.bubble.nativeElement.classList.remove("visible");
      }
    })
  }


  /**
   * FUNCTIONALITIES
   */
  clearData() {
  }
  assignToPipelist() {
  }
  isLoadingElem(elem: any) {
  }
   changeItem(elem: any) {
  }

  change(event: any) {
  }
  onImgError(event: any) {
    event.target.src = this.placeholder;
  }

  openCard(item) {
  }
  checkRolesAndPermissions(){
    return this.rolesAndPermissions.roles.includes('ROLE_ADMIN') || this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') || this.rolesAndPermissions.permissions.includes(4) 
  }


  lossCard(item,type) {
    this.popupSE.openPopup(ConfirmarAccionPerderPopupComponent, {data:{accion:'Perder',elemento:'la propuesta', id:item.id, item:item}, type:type});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd9)).subscribe(res => {
      if(!res)return;
      this.refresh.emit(true);
      this._unsubInd9.next("");
    });
  }

  goUp(){
    (document.querySelector(".pageContainer") as HTMLElement).scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  clickCRM(event){
    if(! this.checkRolesAndPermissions()) return;
    let stringifiedData = JSON.stringify(event);
    let cripted = iStaticUtilities.simpleCrypt(stringifiedData)
    localStorage.setItem('crm', cripted);
    this.staticUtilitiesSE.goTo('/formaciones/crear-incompany', slideNavigationTypes.slideToTop, {crmId:event.id});
  }

  openCreatePromociones(event){
    if(! this.checkRolesAndPermissions()) return;
  let stringified = JSON.stringify(event);
  let cripted = iStaticUtilities.simpleCrypt(stringified)
  localStorage.setItem('formalProposal', cripted);
  this.staticUtilitiesSE.goTo('/formaciones/crear-incompany-promociones', slideNavigationTypes.slideToTop);
  }
  openMatriculaConfirmPopup(event){
    if(! this.checkRolesAndPermissions()) return;
     
    this.staticUtilitiesSE.goTo('/matriculaciones/incompanies/confirmar-matriculacion', slideNavigationTypes.slideToTop, {enrolId:event.id, groupId:event.studentsGroup.id, groupPrice:event.groupPrice });
  }
  deleteGroup(group){
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {accion:'Eliminar',elemento:'el grupo seleccionado',});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd9)).subscribe(res => {
      if(!res)return;
      this.deleteEnrollmentMethod(group);
      this._unsubInd9.next("");
    });
  }
  openEnsurementPopup(event){
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {accion:'Ganar',elemento:'la propuesta seleccionada',});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd8)).subscribe(res => {
      if(!res)return;
      this.winFormalizedProposal(event);
      this._unsubInd8.next("");
    });
  }
  openPopupSelectorOfAction(event){
    this.popupSE.openPopup(PropuestaFormalAccionPopupComponent, {data:event});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd9)).subscribe(res => {
      if(!res)return;
       
      switch(res.returnValue){
        case 'Ganada':
          this.openEnsurementPopup(event)
          break;
        case 'Perdida':
          this.lossCard(event,'formalizedProposal');
          break;
        case 'Group':
           
          this.openCreatePromociones(event);
          break;

      }
      this._unsubInd9.next("");
    });
  }
  /**
   * API CALLS
   */


  // deleteGroupMethod(group){
  //   this.groupSE.delete([group.studentsGroup.id]);
  //   this.groupSE.getResultDelete().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
  //     if(!res){return}
  //     StaticUtilitiesService.showFeedback('Grupo eliminado correctamente');
  //     this.refresh.emit(true);
  //     this._unsubInd.next('')
  //   })
  //   this.groupSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
  //     if(!res){return}
  //     if(res.status != 401){
  //       if (res.status == 404) {
  //         StaticUtilitiesService.showError('No se han encontrado resultados');
  //       } else if (res.status == 500) {
  //         StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
  //       } else {
  //         StaticUtilitiesService.showError(res.message);
  //       }
  //     }
  //     this._unsub.next('')
  //   })
  // }

  deleteEnrollmentMethod(group){
    this.enrollmentSE.delete([group.id]);
    this.enrollmentSE.getResultDelete().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Grupo eliminado correctamente');
      this.refresh.emit(true);
      this._unsubInd.next('')
    })
    this.enrollmentSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  winFormalizedProposal(event){
     
    let objectToPass: any = {
      id: event.pipedrive_deal_id,
      formalizedProposal: event.id
    };
    this.proposalSE.winFormalizedProposal(objectToPass);
    this.proposalSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Propuesta ganada correctamente');
      this.refresh.emit(true);
      this._unsubInd.next('')
    })
    this.proposalSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

}

