<div class="tableContainer">
  <table class="mainTable" >
    <thead class="thead">
      <tr class="tr">
        <th class="th"> <mat-checkbox [checked]="allSelected" (change)="selectAll($event)"></mat-checkbox></th>
        <th class="th" *ngFor="let item of displayedColumns"
          [ngClass]="{justifyContentFlexStart:item?.displayName === 'Nombre', estadoHeaderTable:item?.displayName === 'Estado'}">
          {{item.displayName}}  </th>
          <th></th>
      </tr>
    </thead>
    <div class="loaderLine">
      <loaderIcon [class.active]="!isDataStudentLoaded"></loaderIcon >
      </div>
    <tbody class="tbody" *ngIf="isDataStudentLoaded">
      <div class="noItems" *ngIf="students?.length == 0">
        No hay items que mostrar
      </div>
      <tr class="tr" (click)="goToStudentDetailView(student.id)" *ngFor="let student of students | paginate:{
      itemsPerPage: filters.num_devoluciones,
      currentPage: filters.num_pagina,
      totalItems: total
    }" >
        <td class="td"> <mat-checkbox (click)="$event.stopPropagation()" (change)="addSelected(student)"
            [checked]="studentsSelected.includes(student)"></mat-checkbox></td>

        <td class="td" *ngFor="let column of displayedColumns; let index = index;"
          [ngClass]="{fwBold: column?.property === 'name',justifyContentFlexStart:column?.property === 'name' ,estadoTable:column?.property === 'state',dFlexGap1em:column?.property === 'enrollments', textCenter: column?.property === 'schedule' }">
          <p
            [ngClass]="{timeBox: column?.property === 'startDate' || column?.property === 'endDate', estadoTabla: column?.property === 'state', formacionTabla: column?.property === 'enrollments', estadoTablaCursando:column?.property === 'state' && student.state == 'cursando' }">
            {{student[column?.property] ?? '-'}} </p> <span
            *ngIf="column?.property === 'enrollments' && student?.extraEnrollments > 0"
            class="plusEnrollments">+{{student?.extraEnrollments}} </span>
        </td>
        <td><mat-icon *ngIf="checkRolesAndPermissions()" >delete</mat-icon></td>
      </tr>
    </tbody>
  </table>

</div>