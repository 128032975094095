import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'status-pill',
  templateUrl: './status-pill.component.html',
  styleUrls: ['./status-pill.component.scss']
})
export class StatusPillComponent implements OnInit {

  @Input() active: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
