import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeneralLoaderService } from '@quasar-dynamics/basic-designsystem';
import { SubjectGeneratorService, iOptionsSelector, iResultHttp, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from '@quasar-dynamics/basic-designsystem';
import { StaticUtilitiesService as NewStaticUtilitiesService } from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { FormacionesNewVersionDobleTitulacionComponent } from 'src/app/Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/_holder/FormacionesNewVersion-DobleTitulacion/FormacionesNewVersion-DobleTitulacion.component';
import { EnrollmentService } from 'src/app/Services/Api/Enrollment.service';
import { FormationService } from 'src/app/Services/Api/Formation.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { iPromotionFormationFilter } from 'src/app/Shared/Interfaces/iPromotionFormation';
import { AttendanceService } from 'src/app/Services/Api/Attendance.service';

@Component({
  selector: 'app-CambiarFormacionPromocionMatricula-Popup',
  templateUrl: './CambiarFormacionPromocionMatricula-Popup.component.html',
  styleUrls: ['./CambiarFormacionPromocionMatricula-Popup.component.scss']
})
export class CambiarFormacionPromocionMatriculaPopupComponent extends SubjectGeneratorService implements OnInit {

  
  enrollmentOption: any = null;

  enrollmentId: number = 0;
  promotionId: number = 0;
  formationId: number = 0;
  typeOfFormation: any = {
    type: '',
    all:true
  }
  objectToPass: any = {};
  indexSelectores: number = 0;
  selectorOptionsformation: iOptionsSelector = {
    items: [],
    bindValue:'id',
    bindLabel: 'name',
    append: true,
    placeholder: 'Seleccione una formación',
  }
  optionsSelectorTipoMatricula: iOptionsSelector = {
    items: ['General', 'Internacional', 'Solo proyecto'],
    bindLabel: 'name',
    append: true,
    placeholder: 'Seleccione un tipo de matrícula',
  }
  arrayFormatos: any = [null, null]
  opcionesSelectorModalidad: iOptionsSelector = {
    items: [],
    append: true,
    placeholder: 'Seleccione una modalidad',
    clearable: false,
    bindValue: 'id',
    bindLabel: 'name'
  }
  formationFilter: iPromotionFormationFilter = {
    num_devoluciones: 5000000000000000,
    num_pagina: 1,
  }
  promotionFilter: iPromotionFormationFilter = {
    num_devoluciones: 5000000000000000,
    num_pagina: 1,
  }
  staticUtilitiesSE: any;
  specialConditions: boolean = false;
  conditionsText: string = "";

  // 

  selectorFormation: any = null;
  formationArray: any = [];
  fundaeRadioButtons: any = null;
  arrayModos: any = [];
  arrayCounter: number = 0;
  arraySelectorPromotion: any = [];
  selectorOptionsPromotionArray: iOptionsSelector[] | any = [];
  attendanceModality:any = null;




  constructor(public dialogRef: MatDialogRef<CambiarFormacionPromocionMatriculaPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private promotionSE: PromotionFormationService, private formationSE: FormationService, private enrollmentSE: EnrollmentService, private generalLoaderSE:GeneralLoaderService, private attendanceSE:AttendanceService) { super() }

  ngOnInit() {
    let studentId = this.modalData.data.student;
    this.enrollmentId = this.modalData.data.id[0];
    this.typeOfFormation.type = this.modalData.type;
    this.getAllFormations(studentId);
    this.getEnrollmentData()
  }

  /**
   * HANDLERS
   */

  successGetSelectorByPromotionIdHandler(res: iResultHttp) {
    const { data } = res;
    this.opcionesSelectorModalidad.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

    
  successGetEnrollmentDataHandler(res:iResultHttp){
    const { data } = res;
    this.selectorFormation = data.formationId;
    this.arraySelectorPromotion.push({ selector:  data.promotionId });  
    this.selectorOptionsPromotionArray?.push({
      items: [],
      append: true,
      placeholder: 'Seleccione una edición',
      bindValue: 'id',
      bindLabel: 'edition'
    });
    this.getPromotionsByFormationId(data.formationId, 0);
    this.getSelectorByPromotionId(data.promotionId);
    this.attendanceModality = data.modalityAssistance;
    this.enrollmentOption = data.type;
    this.generalLoaderSE.removeFromLoaderAmount();
   }

  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  emitData(){
  }

  cleanSelectors() {
    this.arraySelectorPromotion = [];
    this.selectorFormation = null;
    this.selectorOptionsPromotionArray.length = 0;
    this.arrayCounter = 0;
    this.arrayFormatos = [null, null];
    this.arrayModos = [];
  }


  detectHowManyFormationsSelected(event) {
    if (!event) return;
    this.cleanSelectors();

    const selectedFormation = this.selectorOptionsformation.items.find((formation) => formation.id == event);

    if (selectedFormation.masters) {
      this.formationArray = [selectedFormation.masters.masterOne, selectedFormation.masters.masterTwo];
      let formaciones = this.formationArray;
      formaciones.forEach((formacion, index) => {
        this.eachPromotionSelected(formacion, index);
      });
      return;
    }

    this.formationArray.push(selectedFormation);
    this.pushSelector();
    this.getPromotionsByFormationId(selectedFormation.id,0);
    this.arrayModos.push(selectedFormation.modality);
  }

  async eachPromotionSelected(formacion, index) {
    const selectorIndex = await this.pushSelector();
    if (index == 0) {
      this.getPromotionsByFormationId(formacion.idFormation);
    }
    if (index == 1) {
      this.getPromotionsByFormationId2(formacion.idFormation);
    }
    this.arrayModos.push(formacion.modality);
  }

  pushSelector(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.indexSelectores == 1
        ? (this.indexSelectores = 0)
        : (this.indexSelectores = 1);
      this.selectorOptionsPromotionArray?.push({
        items: [],
        append: true,
        placeholder: 'Seleccione una edición',
        bindValue: 'id',
        bindLabel: 'edition'
      });
      this.arraySelectorPromotion.push({ selector: null });
      resolve(this.indexSelectores);
    });
  }
  detectSpecialConditions(event){
    this.specialConditions = event.checked;
  }

  selectFormation(event) {
    let formationId = event.id;
    this.getPromotionsByFormationId(formationId);
    this.cleanSelectors();
  }

  

  getAllFormations(studentId) {
    this.formationSE.getFormationsByStudentId(studentId, this.typeOfFormation);
    const unsubSubject = this.createSubject();

    this.formationSE.getResultAllFormationByStudentId().pipe(takeUntil(unsubSubject)).subscribe(res => {
      if (!res) return;
      let { data } = res;
      this.selectorOptionsformation.items = [...this.selectorOptionsformation.items, ...data];
      this.sendDataToSubject(unsubSubject);
    });
    this.formationSE.getResultAllFormationByStudentIdError().pipe(takeUntil(unsubSubject)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this.sendDataToSubject(unsubSubject);
    });
  }


  getPromotionsByFormationId(id,index?) {
    const unsubSubject = this.createSubject();
    this.promotionSE.getPromotionsByFormationId(id);
    this.promotionSE.getResultPromotionsByFormationId().pipe(takeUntil(unsubSubject)).subscribe(res => {
      if (!res) return;
      let { data } = res;
      if(index != undefined){        
        this.selectorOptionsPromotionArray[index].items = data.map((item: any) => { return { ...item, label: `${item.edition} - ${item.schedule}` } });
      } else {
        this.selectorOptionsPromotionArray.items = data.map((item: any) => { return { ...item, label: `${item.edition} - ${item.schedule}` } });
      }
      this.sendDataToSubject(unsubSubject);
    });
    this.promotionSE.getResultPromotionsByFormationIdError().pipe(takeUntil(unsubSubject)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this.sendDataToSubject(unsubSubject);
    }
    );
  }

 

  updateEnrollment() {
    this.objectToPass = {
      formation: this.selectorFormation,
      promotion: this.arraySelectorPromotion[0].selector,
      type: this.enrollmentOption,
      modality: this.attendanceModality,
      id: this.modalData.data.id[0],
      specialConditions: this.conditionsText,
    }
    const unsubSubject = this.createSubject();
    this.enrollmentSE.update(this.objectToPass);
    this.enrollmentSE.getResultUpdate().pipe(takeUntil(unsubSubject)).subscribe(res => {
      if (!res) return;
      StaticUtilitiesService.showFeedback('Matrícula actualizada');
      this.closePopup(true);
      this.sendDataToSubject(unsubSubject);
    });
    this.enrollmentSE.getResultUpdateError().pipe(takeUntil(unsubSubject)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this.sendDataToSubject(unsubSubject);
    });
  }

  getSelectorByPromotionId(promotionId: number) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = NewStaticUtilitiesService.createSubject();
    const behaviorSubject = NewStaticUtilitiesService.createBehaviorSubject();
    this.attendanceSE.getSelectorByPromotionId(behaviorSubject, promotionId);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        NewStaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetSelectorByPromotionIdHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getPromotionsByFormationId2(id) {
    const unsubSubject = this.createSubject();
    this.promotionSE.getPromotionsByFormationId2(id);
    this.promotionSE
      .getResultPromotionsByFormationId2()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res) => {
        if (!res) return;
        let { data } = res;
        this.selectorOptionsPromotionArray![1].items = data.map((item: any) => {
          return { ...item, label: `${item.edition} - ${item.schedule}` };
        });
        this.sendDataToSubject(unsubSubject);
      });
    this.promotionSE
      .getResultPromotionsByFormationId2Error()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this.sendDataToSubject(unsubSubject);
      });
  }

  getEnrollmentData() {
  this.generalLoaderSE.addToLoaderAmount();
  const subject = StaticUtilitiesService.createSubject();
  const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
  this.enrollmentSE.getEnrollmentData(behaviorSubject, this.enrollmentId);
  behaviorSubject
    .pipe(
      takeUntil(subject),
      filter((res) => res)
    )
    .subscribe((res: iResultHttp) => {
      StaticUtilitiesService.apiResponseHandler(res, subject, [
      {
        method: () => this.successGetEnrollmentDataHandler(res),
      },
      {
        method: () => this.generalLoaderSE.removeFromLoaderAmount(),
        error:true
      }
     ]);
    });
  }



}