import { Injectable } from '@angular/core';
import { PopupService, StaticUtilitiesService, iResultHttp } from '@quasar-dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ShowConflictsPopupComponent } from 'src/app/Popups/ShowConflicts-Popup/ShowConflicts-Popup.component';

@Injectable({
  providedIn: 'root'
})
export class ConflictsService {



constructor(private popupSE:PopupService) { }

public manageConflicts(res:iResultHttp){
  const {message, status } = res;
  if(status !== 409) return;
  const parsedMessage = JSON.parse(message.toString());
  this.openConflictsPopup(parsedMessage);
}


private openConflictsPopup(conflictsData: iConflictsData){
  const subject = StaticUtilitiesService.createSubject();
  this.popupSE.openPopup(ShowConflictsPopupComponent, conflictsData );
  this.popupSE.returnData().pipe(takeUntil(subject)).subscribe((res) => {
    subject.next('');
    subject.complete();
  })

}

}

export interface iConflictsData {
  classroom:string[],
  teacher:string[],
}