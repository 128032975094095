import { Component, OnInit } from '@angular/core';
import {
  PopupService,
  iResultHttp,
  iUnsubscribeDestroy,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { takeUntil } from 'rxjs/operators';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { SubirArchivoPopupComponent } from 'src/app/Popups/SubirArchivo-Popup/SubirArchivo-Popup.component';
import { UserService } from 'src/app/Services/Api/User.service';

@Component({
  selector: 'app-GestionUsuarios-Profesores-Detalles',
  templateUrl: './GestionUsuarios-Profesores-Detalles.component.html',
  styleUrls: ['./GestionUsuarios-Profesores-Detalles.component.scss'],
})
export class GestionUsuariosProfesoresDetallesComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  idProfesor: number = 0;
  isDataLoaded: boolean = false;
  optionSubmenuSelected: number = 0;
  dataTeacher: any = StaticDataHandlerService.topInfoProfe();
  activoDesde: string = '';
  constructor(
    private staticUtilities: StaticUtilitiesService,
    private teacherSE: TeacherService,
    private popupSE: PopupService,
    private userSE: UserService
  ) {
    super();
  }
  override ngOnDestroy(): void {
    localStorage.removeItem('mensaje');
  }
  ngOnInit() {
    this.rolesAndPermissions =
      StaticUtilitiesService.getUserPermissionsAndRoles();

    if (this.staticUtilities.getParams().profesor == undefined) {
      this.staticUtilities.goTo(
        '/profesores/mis-profesores/listado-profesores',
        slideNavigationTypes.slideToBottom
      );
      StaticUtilitiesService.showError('No se ha encontrado el profesor');
    } else {
      this.idProfesor = Number(this.staticUtilities.getParams().profesor);
      this.getTeacherById();
    }
  }

  /**
   * FUNCTIONALITY
   */

  editImage() {
    this.popupSE.openPopup(SubirArchivoPopupComponent, {
      multiple: false,
      formatos: ['image/png', 'image/jpeg', 'image/jpg'],
      size: 5000000,
      accion: 'Añadir imagen de perfil',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }

        let objToPass: any = {
          id: this.dataTeacher.user.id,
          photo: res['returnValue'][0].base64,
        };
        this.updatePhoto(objToPass);
        this._unsubInd2.next('');
      });
  }

  checkRolesAndPermissions() {
    return (
      this.rolesAndPermissions.roles.includes('ROLE_ADMIN') ||
      this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') ||
      this.rolesAndPermissions.permissions.includes(2)
    );
  }
  checkRolesAndPermissionsBill() {
    return (
      this.rolesAndPermissions.roles.includes('ROLE_ADMIN') ||
      this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') ||
      this.rolesAndPermissions.permissions.includes(8)
    );
  }

  refresh(event) {
    if (event) this.getTeacherById();
  }

  irAEditarProfesor() {
    this.staticUtilities.goTo(
      `/profesores/mis-profesores/crear-profesor/`,
      slideNavigationTypes.slideToTop,
      { isEdit: true, idProfesor: this.idProfesor }
    );
  }

  onToggleChange(event) {
    setTimeout(() => {
      this.optionSubmenuSelected = Number(event);
    }, 1);
  }

  /**
   * API CALLS
   */

  getTeacherById() {
    this.teacherSE.getSingle(this.idProfesor);
    this.teacherSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;
        data.user.addresses.forEach((address) => {
          if (address.name == 'Dirección personal') {
            data.user.address = address;
          }
          if (address.name == 'Dirección fiscal') {
            data.user.bankData = {
              ...data.user.bankData,
              fiscalAddress: address,
            };
          }
        });
        this.dataTeacher = data;
        this.dataTeacher.languages = data.user.languages.join(', ');
        // this.getActivoDesde(data);
        localStorage.setItem(
          'mensaje',
          `Ficha de profesor: ${data.user.name} ${data.user.surname}`
        );
        this.isDataLoaded = true;
        this._unsubInd.next('');
      });
    this.teacherSE
      .getResultIndividualError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.staticUtilities.goTo('', slideNavigationTypes.slideToRight);
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  updatePhoto(data) {
    this.userSE.update(data);
    this.userSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Imagen actualizada correctamente');
        this.getTeacherById();
        this._unsubInd3.next('');
      });
    this.userSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
