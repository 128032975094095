import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StaticUtilitiesService } from '@quasar-dynamics/basic-designsystem';
import {
  iOptionsSelector,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { LandmarkService } from 'src/app/Services/Api/Landmark.service';

@Component({
  selector: 'app-ChooseEventType-Popup',
  templateUrl: './ChooseEventType-Popup.component.html',
  styleUrls: ['./ChooseEventType-Popup.component.scss'],
})
export class ChooseEventTypePopupComponent implements OnInit {

  selectedType:number | null = null; 

  eventTypeSelectorOptions: iOptionsSelector = {
    items: [],
    search: true,
    clearable: false,
    placeholder: 'Selecciona un tipo de evento',
    bindValue: 'id',
    bindLabel: 'name',
    label: 'Tipo de evento',
    append: true,
  };

  constructor(
    public dialogRef: MatDialogRef<ChooseEventTypePopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public modalData: any,
    private landmarkSE: LandmarkService
  ) {}

  ngOnInit() {
    
    this.getEventTypesSelector()

  }

  /**
   * HANDLERS
   */

  successGetEventTypesSelectorHandler(res: iResultHttp) {
    let { data } = res;
    this.eventTypeSelectorOptions.items = data;
  }

  /**
   * FUNCTIONALITY
   */

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * API CALLS
   */

  getEventTypesSelector() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.landmarkSE.getSelectorTypes(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetEventTypesSelectorHandler(res),
        });
      });
  }
}
