import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { iOptionsSelector } from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'selector-and-simple-display',
  templateUrl: './selector-and-simple-display.component.html',
  styleUrls: ['./selector-and-simple-display.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectorAndSimpleDisplayComponent), // replace name as appropriate
      multi: true,
    },
  ],
})
export class SelectorAndSimpleDisplayComponent implements OnInit {
  private _optionsSelector: iOptionsSelector = {
    items: [
      {
        id: 1,
        name: 'Presencial',
      },
      {
        id: 2,
        name: 'Online',
      },
      {
        id: 3,
        name: 'Mixto',
      },
    ],
    readonly: false,
    search: true,
    clearable: false,
    append: true,
    bindLabel: 'name',
    classList: 'slim',
    bindValue: 'id',
    multiple: {
      isMultiple: true,
      checkbox: true,
    },
  };
  
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() 
    get optionsSelector() {
      return this._optionsSelector;
    }
    set optionsSelector(value) {
      this._optionsSelector = value;
      this.cdr.detectChanges();
    }

  //INTERNAL VALUES

  private _onChange = (_: any) => {};
  private _onTouched = () => {};
  private _oldData;

  _disabled: boolean = false;
  model;

  writeValue(obj: any): void {
    if (obj !== this.model) {
      this.model = obj;
      this._onChange(obj);
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
    // this._onChange(this.model);
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onNgModelChange(event) {
    this._oldData = this.model;
    this.model = event;
  }

  onModelChange($event) {
    this.model = $event;
    this._onChange(this.model);
  }

  constructor(private cdr:ChangeDetectorRef) {}

  ngOnInit() {}

  sendNgModel(event) {
    this.change.emit(event);
  }

  findLabel() {
    const { bindLabel, bindValue, multiple, placeholder, items } = this.optionsSelector;
  
    if (!bindLabel || !bindValue) {
      return this.model;
    }
  
    if (multiple?.isMultiple) {
      if (placeholder === 'Modalidad' && this.model?.length > 1) {
        return 'Varios';
      }
  
      const values = this.model;
      return items
        .filter(item => values?.includes(item[bindValue]))
        .map(item => item[bindLabel])
        .join(', ');
    }
  
    return items?.find(item => item[bindValue] === this.model)?.[bindLabel] ?? '';
  }}
