<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>Sobreescribir fechas</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <p>
      ¿Estás seguro que quieres rellenar la infromación de las fechas
      sobreescribiendo la información ya existente?
    </p>
    <p>Este paso no tiene vuelta atrás</p>
  </div>

  <div class="footer">
    <btn classList="principalButton" (click)="closePopup(false)">
      <ng-container> Cancelar </ng-container>
    </btn>
    <btn classList="specialButton" (click)="closePopup(true)">
      <ng-container> Sobreescribir </ng-container>
    </btn>
  </div>
</div>
