<fullContainer>
  <ng-container>
    <header></header>
    <div class="fullView">




      <div class="toggleAndButton">
        <btn classList="generalbutton principalButton" (click)="goToFormations()">Ir a Formaciones</btn>
      </div>

      <searcher-and-filter (onSearchChange)="getFilters($event,'genericFilter')">
        <div class="filterLine">

          <selector [options]="extraClass.typeSelectorOptions" (change)="getFilters($event,'type')"></selector>

          <span class="clearableDate">
            <customInput [selectedDate]="extraClass.filterModels.startDate"
              [options]='extraClass.startDateCustomInputOptions'
              (change)="getFilters($event, 'startDate');handleDatesFilter($event, 'startDate')"></customInput>
            <span class="closex" (click)="getFilters(null, 'startDate');handleDatesFilter(null, 'startDate')"
              *ngIf="extraClass.filterModels.startDate">
              <mat-icon>close</mat-icon>
            </span>
          </span>

          <span class="clearableDate">
            <customInput [selectedDate]="extraClass.filterModels.endDate"
              [options]='extraClass.endDateCustomInputOptions'
              (change)="getFilters($event, 'endDate');handleDatesFilter($event, 'endDate')"></customInput>
            <span class="closex" (click)="getFilters(null, 'endDate');handleDatesFilter(null, 'endDate')"
              *ngIf="extraClass.filterModels.endDate">
              <mat-icon>close</mat-icon>
            </span>
          </span>

          <selector [options]="extraClass.classroomSelectorOptions" (change)="getFilters($event,'classroom')">
          </selector>

          <selector [options]="extraClass.academicStateSelectorOptions" (change)="getFilters($event,'academicState')">
          </selector>
        </div>
      </searcher-and-filter>

      <main-table skeleton [isDataLoaded]="extraClass.isDataLoaded" [headerData]="extraClass.headerData"
        [dataSource]="extraClass.mainViewPromotions" (clickOnRow)="goToPromotion($event)" ></main-table>

      <ng-container *ngFor="let item of extraClass.mainViewPromotions | paginate:{
        itemsPerPage: extraClass.promotionFilter.num_devoluciones,
        currentPage: extraClass.promotionFilter.num_pagina,
        totalItems: extraClass.totalMainViewPromotions
      }">
      </ng-container>

      <pagination-controls [autoHide]="true" previousLabel="Anterior"  nextLabel="Siguiente" (pageChange)="pageChanged($event)" class="pagination"></pagination-controls>

    </div>
  </ng-container>
</fullContainer>