import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  GeneralLoaderService,
  iResultHttp,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { StudentService } from 'src/app/Services/Api/Student.service';
import { GestionUsuariosAlumnosDetallesFinancieroClass } from 'src/app/Shared/Classes/GestionUsuariosAlumnosDetallesFinancieroClass';
import { iFinanceTable } from 'src/app/Shared/Interfaces/Utils/iFinanceTable';

@Component({
  selector: 'financiero-alumno',
  templateUrl: './GestionUsuarios-Alumnos-Detalles-Financiero.component.html',
  styleUrls: ['./GestionUsuarios-Alumnos-Detalles-Financiero.component.scss'],
})
export class GestionUsuariosAlumnosDetallesFinancieroComponent
  implements OnInit
{
  @Input() get dataStudent() {
    return this.extraClass.dataStudent;
  }
  set dataStudent(value: iFinanceTable[]) {
    this.extraClass.dataStudent = value;
    if (value) {
      this.getStudentFinance();
    }
  }
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  extraClass: GestionUsuariosAlumnosDetallesFinancieroClass =
    new GestionUsuariosAlumnosDetallesFinancieroClass();

  constructor(
    private studentSE: StudentService,
    private generalLoaderSE: GeneralLoaderService
  ) {}

  ngOnInit() {}

  /**
   * HANDLERS
   */

  successGetStudentFinanceHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.finance = data.map((finance) => {
      return {
        ...finance,
        status: finance.status ? 'Pagado' : 'Pendiente',
        statusColor: finance.status ? '--greenState' : '--redState',
      };
    });
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * API CALLS
   */

  getStudentFinance() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.studentSE.getStudentFinance(
      behaviorSubject,
      this.extraClass.dataStudent.id
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetStudentFinanceHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
