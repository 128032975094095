import { iOptionsSelector } from '@quasar-dynamics/basic-designsystem';
import { eEventCalendarType } from '../Interfaces/Utils/iEventCalendar';
import { iModelCalendarFiltersPopup } from '../Interfaces/Utils/iModelCalendarFiltersPopup';

export class CalendarFiltersPopupClass {
  mainCalendarioSelectorOptions: iOptionsSelector = {
    items: [Array(eEventCalendarType)],
    label: 'Calendario',
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Selecciona un tipo de evento',
    classList: 'calendarFilters',
    search: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varios eventos seleccionados',
    },
  };

  formacionmasterSelectorOptions: iOptionsSelector = {
    items: [
      {
        name: 'any',
        id: 0,
      },
      {
        name: 'all',
        id: 1,
      },
    ],
    label: 'Formación Máster',
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Selecciona una formación Máster',
    classList: 'calendarFilters',
    search: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varios seleccionados',
    },
  };

  formacioncourseSelectorOptions: iOptionsSelector = {
    items: [
      {
        name: 'Curso de excel avanzado',
        id: 0,
      },
      {
        name: 'all',
        id: 1,
      },
    ],
    label: 'Formación Cursos',
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Selecciona una formación Cursos',
    classList: 'calendarFilters',
    search: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varios seleccionados',
    },
  };

  formacionIncompanySelectorOptions: iOptionsSelector = {
    items: [
      {
        name: 'Curso de excel avanzado',
        id: 0,
      },
      {
        name: 'all',
        id: 1,
      },
    ],
    label: 'Formación Incompany',
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Selecciona una formación Incompany',
    classList: 'calendarFilters',
    search: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varios seleccionados',
    },
  };

  profesorSelectorOptions: iOptionsSelector = {
    items: [],
    label: 'Profesor',
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Selecciona un Profesor',
    classList: 'calendarFilters',
    search: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varios seleccionados',
    },
  };

  aulaSelectorOptions: iOptionsSelector = {
    items: [
      {
        name: 'A - 1',
        id: 0,
      },
      {
        name: 'B - 1',
        id: 1,
      },
    ],
    label: 'Aula',
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Selecciona una aula',
    classList: 'calendarFilters',
    search: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varias seleccionados',
    },
  };

  empresaSelectorOptions: iOptionsSelector = {
    items: [
      {
        name: 'fennech',
        id: 0,
      },
      {
        name: 'josegimenez s.l.',
        id: 1,
      },
    ],
    label: 'Empresa',
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Selecciona una empresa',
    classList: 'calendarFilters',
    search: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varias seleccionados',
    },
  };

  estadoSelectorOptions: iOptionsSelector = {
    items: [
      {
        name: 'Bien',
        id: 0,
      },
      {
        name: 'Español',
        id: 1,
      },
    ],
    label: 'Estado',
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Selecciona una Estado',
    classList: 'calendarFilters',
    search: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varios seleccionados',
    },
  };
  templateSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    label: 'Plantilla',
    bindLabel: 'edition',
    bindValue: 'id',
    classList: 'calendarFilters',
    clearable: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varios seleccionados',
    },
    placeholder: 'Selecciona una plantilla',
    search: true,
  };

  ngModels: iModelCalendarFiltersPopup= {
    selectedMasters: [],
    selectedCourses: [],
    selectedmasterNgModelsArray: [],
    selectedcourseNgModelsArray: [],
    events: [],
    teachers: [],
    classrooms: [],
    status: [],
  };

  selectorOptionsArrayContainer: {
    selectedmasterSelectorOptionsArray: iOptionsSelector[];
    selectedcourseSelectorOptionsArray: iOptionsSelector[];
  } = {
    selectedmasterSelectorOptionsArray: [],
    selectedcourseSelectorOptionsArray: [],
  };
}


