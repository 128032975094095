<div class="formationDetail__directors">
  <ng-container *ngFor="let director of directors; let i = index; trackBy: trackby">
    <div class="formationDetail__director">
      <p>{{director.teacherName}}</p>
      <button *ngIf="isEditing"><mat-icon>delete</mat-icon></button>
    </div>
  </ng-container>
  <ng-container *ngIf="isEditing">
    <!-- <ng-container *ngIf="DirectorOptions.items.length > 0">
      <selector
        [(ngModel)]="ngModelSelected"
        [options]="DirectorOptions"
        (change)="handlerSelectDirector($event)"
      ></selector>
    </ng-container> -->
    <ng-container class="formationDetail__directorsLoadingSelector" *ngIf="!(DirectorOptions.items.length > 0)">
      <div class="formationDetail__directorsLoadingSelector" ></div>
    </ng-container>
  </ng-container>
</div>