import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  PopupService,
  StaticUtilitiesService,
  iOptionsSelector,
  iResultHttp,
  iStaticUtilities,
  iUnsubscribeDestroy,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { FormationService } from 'src/app/Services/Api/Formation.service';
import { HoldingService } from 'src/app/Services/Api/Holding.service';
import { IncompanyService } from 'src/app/Services/Api/Incompany.service';
import { FormalizedProposalService } from 'src/app/Services/Api/formalizedProposal.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

@Component({
  selector: 'app-Incompanies-Create',
  templateUrl: './Incompanies-Create.component.html',
  styleUrls: ['./Incompanies-Create.component.scss'],
})
export class IncompaniesCreateComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  optionSelected: number = 0;

  isActive: boolean = false;
  isLoading: boolean = false;
  isEdit: boolean = false;
  disableSelector: boolean = false;
  isVinculadoAGrupoEmpresarial: boolean = false;

  isDataSelectedFomHoldings: boolean = false;
  isDataSelectedFomFormations: boolean = false;
  isDataSelectedFomCompanies: boolean = false;
  isDataSelectedFromAddress: boolean = false;

  dataFromFormationId: any = null;

  dataFromCRM: any = null;

  whatAmIAdding: string = 'una nueva propuesta formal';
  action: string = 'Añadiendo';

  params: any = null;

  companyOrHoldingFromCrm: any = null;
  contactPersonFromCrm: any = null;
  emailFromCrm: any = null;

  empresaSelected: any = null;
  addressSelected: any = null;
  formationSelected: any = null;
  holdingSelected: any = null;

  objectToPass: any = null;

  formationData: any = {
    name: '',
    mode: null,
    language: null,
    objectives: '',
    description: '',
    duration: null,
    modalityPrice: [
      {
        paymentModality: 1,
        price: null,
      },
    ],
  };

  holdingData: any = {
    name: '',
    email: '',
    cif: '',
    phone: '',
    contactWith: '',
    phonePrefix: null,
    address: {
      street: '',
      number: '',
      door: '',
      postalCode: '',
      province: '',
      municipality: '',
      country: null,
    },
  };

  newData: any = {
    amount: null,
    dealId: null,
    name: '',
    state: null,
    company: {
      name: '',
      cif: '',
      address: {
        country: null,
        province: '',
        municipality: '',
        street: '',
        number: '',
        door: '',
        postalCode: '',
        name: '',
        email: null,
        contactWith: null,
        phonePrefix: null,
        phone: null,
      },
    },
  };
  opcionesIdiomas: iOptionsSelector = {
    items: StaticDataHandlerService.getLanguages(),
    clearable: true,
    search: true,
    placeholder: 'Selecciona el idioma',
    append: true,
  };

  opcionesModos: iOptionsSelector = {
    items: ['Online', 'Presencial', 'Mixto'],
    placeholder: 'Selecciona el modo',
    search: true,
  };
  opcionesEstado: iOptionsSelector = {
    items: ['Estado 1', 'Estado 2', 'Estado 3'],
    placeholder: 'Selecciona el estado',
    search: true,
  };
  opcionesEmpresa: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona la empresa',
    search: true,
    bindLabel: 'name',
    bindValue: 'id',
  };
  opcionesHolding: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona el grupo empresarial',
    search: true,
    bindLabel: 'name',
    bindValue: 'id',
  };
  opcionesAddress: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona la sucursal',
    search: true,
    bindLabel: 'name',
  };
  opcionesFormacion: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona la formación',
    search: true,
    bindLabel: 'name',
    bindValue: 'idFormation',
  };
  opcionesCountry: iOptionsSelector = {
    items: [...StaticDataHandlerService.getPaises()],
    placeholder: 'Selecciona el país',
    search: true,
  };

  constructor(
    private staticUtilitiesSE: StaticUtilitiesService,
    private router: Router,
    private popupSE: PopupService,
    private companySE: CompanyService,
    private formationSE: FormationService,
    private holdingSE: HoldingService,
    private formalizedProposalSE: FormalizedProposalService,
    private incompanySE: IncompanyService
  ) {
    super();
  }

  ngOnInit() {
    this.params = this.staticUtilitiesSE.getParams();
    this.dataFromCRM = this.getDataFromLocalStorage();
    this.getCompaniesForSelector();
    this.getFormacionesForSelector();
    this.getHoldingList();
  }

  ngAfterViewInit(): void {
    (document.querySelector('.form') as HTMLElement).addEventListener(
      'scroll',
      (e) => {
        if (
          (
            document.querySelector('.contactoContainer') as HTMLElement
          ).getBoundingClientRect().top < 0
        ) {
          this.optionSelected = 1;
        }
        if (
          (
            document.querySelector('.contactoContainer') as HTMLElement
          ).getBoundingClientRect().top > 0
        ) {
          this.optionSelected = 0;
        }
        if (
          (
            document.querySelector('.datosFiscales') as HTMLElement
          ).getBoundingClientRect().top < 0
        ) {
          this.optionSelected = 2;
        }
      }
    );
  }

  /**
   * GETTERS && SETTERS
   */

  getDataFromLocalStorage(): any {
    let data = localStorage.getItem('crm')!;
    let decripted = iStaticUtilities.simpleDecrypt(data);
    let response = {};
    if (decripted) response = JSON.parse(decripted);
    this.setterCRM(response);
    return response;
  }
  setterCRM(data: any) {
    this.newData.amount = data.amount;
    this.newData.dealId = data.id;
    this.newData.state = data.state;
    this.newData.name = data.name;
    this.isCompleted();
  }
  setterHolding(data) {
    this.holdingData = {
      name: data.name,
      email: data.email,
      cif: data.cif,
      phone: data.phone,
      contactWith: data.contactWith,
      phonePrefix: data.phonePrefix,
      address: {
        street: data.address[0].street,
        number: data.address[0].number,
        door: data.address[0].door,
        postalCode: data.address[0].postalCode,
        province: data.address[0].province,
        municipality: data.address[0].municipality,
        country: data.address[0].country,
      },
    };
    this.isDataSelectedFomHoldings = true;
    this.isCompleted();
  }
  setterFormation(data) {
    this.formationData = {
      name: data.name,
      mode: data.mode,
      language: data.language,
      objectives: data.objectives,
      description: data.description,
      duration: data.duration,
      modalityPrice: [
        {
          paymentModality: 1,
          price: data.modalityPriceFormations[0].price,
        },
      ],
    };
    this.isDataSelectedFomFormations = true;
    this.isCompleted();
  }
  setterCompany(data) {
    this.newData.company = {
      name: data.name,
      cif: data.Cif,
      address: {
        country: null,
        province: '',
        municipality: '',
        street: '',
        number: '',
        door: '',
        postalCode: '',
        name: '',
        email: null,
        contactWith: null,
        phonePrefix: null,
        phone: null,
      },
    };
    this.opcionesAddress.items = data.addresses;
    this.isDataSelectedFomCompanies = true;
    this.isCompleted();
  }
  setterAddress(data) {
    this.newData.company.address = {
      country: data.country,
      province: data.province,
      municipality: data.municipality,
      street: data.street,
      number: data.number,
      door: data.door,
      postalCode: data.postalCode,
      name: data.name,
      email: data.email,
      contactWith: data.contactWith,
      phonePrefix: data.phonePrefix,
      phone: data.phone,
    };
    this.isDataSelectedFromAddress = true;
    this.isCompleted();
  }
  idiomasSelector(event) {
    this.formationData.language = event;
    this.isCompleted();
  }
  stateSelector(event) {
    this.newData.state = event;
    this.isCompleted();
  }
  modalidadSelector(event) {
    this.formationData.mode = event;
    this.isCompleted();
  }
  companySelector(event) {
    if (!event) {
      this.companyClear();
      return;
    }
    this.getCompanyById(event);
  }
  addressSelector(event) {
    this.setterAddress(event);
  }
  holdingSelector(event) {
    if (!event) {
      this.holdingClear();
      return;
    }
    this.getHoldingById(event);
  }

  selectedFormation(formationSelected) {
    if (!formationSelected) {
      this.formationClear();
      return;
    }
    this.getFormationById(formationSelected);
  }

  getPrefix(event, key) {
    switch (key) {
      case 'sucursal':
        this.newData.company.address.phonePrefix = event.code;
        this.isCompleted();
        break;
      case 'holding':
        this.holdingData.phonePrefix = event.code;
        this.isCompleted();
        break;

      default:
        break;
    }
  }

  /**
   *
   * FUNCTIONALITY
   */

  scroll(type: string) {
    switch (type) {
      case 'informacion':
        (document.querySelector('.form') as HTMLElement).scroll({
          top: 0,
          behavior: 'smooth',
        });
        break;
      case 'descripcion':
        (document.querySelector('.form') as HTMLElement).scroll({
          top:
            (document.querySelector('.form') as HTMLElement).scrollHeight / 3.7,
          behavior: 'smooth',
        });
        break;
      case 'precio':
        (document.querySelector('.form') as HTMLElement).scroll({
          top:
            (document.querySelector('.form') as HTMLElement).scrollHeight / 1.5,
          behavior: 'smooth',
        });
        break;
    }
  }
  confirmPopup() {
    if (this.router.url.includes('crear-incompany')) {
      this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
        accion: 'Descartar',
        elemento: 'la creación de la formación',
      });
      this.popupSE
        .returnData()
        .pipe(takeUntil(this._unsubInd))
        .subscribe((data) => {
          this.staticUtilitiesSE.goTo(
            '/matriculaciones/incompanies/listado-matriculaciones',
            slideNavigationTypes.slideToBottom
          );
          this._unsubInd.next('');
        });
    } else {
      this.staticUtilitiesSE.goTo(
        '/matriculaciones/incompanies/listado-matriculaciones',
        slideNavigationTypes.slideToBottom
      );
    }
  }

  crearFormacion() {
    this.objectToPass = this.newData;
    if (this.formationSelected) {
      this.objectToPass.formation = this.formationSelected;
    } else {
      this.objectToPass.formation = this.formationData;
    }
    if (
      this.empresaSelected ||
      (this.objectToPass.company.name && this.objectToPass.company.cif)
    ) {
      this.objectToPass.company['holding'] = this.holdingData;
    } else {
      this.objectToPass.holding = this.holdingData;
      delete this.objectToPass.company;
    }

    this.createFormalizedProposal();
  }

  updateLine(event, key, option) {}

  isCompleted() {
    if (
      StaticUtilitiesService.isCompleteObject(this.newData) &&
      StaticUtilitiesService.isCompleteObject(this.formationData)
    ) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }

  vinculadoAGrupoEmpresarial(event) {
    this.isVinculadoAGrupoEmpresarial = event.checked;
  }
  holdingClear() {
    this.holdingData = {
      name: '',
      email: '',
      cif: '',
      phone: '',
      contactWith: '',
      phonePrefix: '',
      address: {
        street: '',
        number: '',
        door: '',
        postalCode: '',
        province: '',
        municipality: '',
        country: null,
      },
    };
    this.isDataSelectedFomHoldings = false;
  }
  formationClear() {
    this.formationData = {
      name: '',
      mode: null,
      language: null,
      objectives: '',
      description: '',
      duration: null,
      modalityPrice: [
        {
          paymentModality: 1,
          price: null,
        },
      ],
    };
    this.isDataSelectedFomFormations = false;
  }
  companyClear() {
    this.newData.company = {
      name: '',
      cif: '',
      address: {
        country: null,
        province: '',
        municipality: '',
        street: '',
        number: '',
        door: '',
        postalCode: '',
        name: '',
        email: null,
        contactWith: null,
        phonePrefix: null,
        phone: null,
      },
    };
    this.opcionesAddress.items = [];
    this.isDataSelectedFomCompanies = false;
  }

  /**
   * API CALLS
   */

  getCompaniesForSelector() {
    this.companySE.getAllCompaniesForFilter();
    this.companySE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.opcionesEmpresa.items = data;
        this._unsubInd.next('');
      });
    this.companySE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getFormacionesForSelector() {
    this.incompanySE.getIncompanyListForSelector();
    this.incompanySE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;

        this.opcionesFormacion.items = data;
        this._unsubInd2.next('');
      });
    this.incompanySE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getFormationById(id) {
    this.formationSE.getSingle(id);
    this.formationSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;

        this.dataFromFormationId = data;
        this.setterFormation(data);
        this._unsubInd3.next('');
      });
    this.formationSE
      .getResultIndividualError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getCompanyById(id) {
    this.companySE.getIndividual(id);
    this.companySE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;

        this.setterCompany(data);
        this._unsubInd4.next('');
      });
    this.companySE
      .getResultIndividualError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getHoldingList() {
    this.holdingSE.getList();
    this.holdingSE
      .getResultGetHoldingList()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.opcionesHolding.items = data;
        this._unsubInd5.next('');
      });
    this.holdingSE
      .getResultGetHoldingListError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getHoldingById(id) {
    this.holdingSE.getSingle(id);
    this.holdingSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;

        this.setterHolding(data);
        this._unsubInd.next('');
      });
    this.holdingSE
      .getResultIndividualError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  createFormalizedProposal() {
    this.formalizedProposalSE.create(this.objectToPass);
    this.formalizedProposalSE
      .getResultCreateFormalizedProposal()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Propuesta formalizada con éxito');
        this.staticUtilitiesSE.goTo(
          '/matriculaciones/incompanies/listado-matriculaciones',
          slideNavigationTypes.slideToBottom
        );
        this._unsubInd.next('');
      });
    this.formalizedProposalSE
      .getResultCreateFormalizedProposalError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
