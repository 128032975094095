import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'header',
  templateUrl: './Header.component.html',
  styleUrls: ['./Header.component.scss']
})
export class HeaderComponent implements OnInit {

  breadCrums: Array<any> = []
  queryParams: any = null;
  message: string = ""
  constructor(private activeRoute: ActivatedRoute, private staticUtilitiesSE: StaticUtilitiesService, private router: Router) { }

  ngOnInit() {
    this.breadCrums = this.activeRoute.snapshot.data["breadcrumb"];
    this.queryParams = this.staticUtilitiesSE.getParams();
    this.setMessage();
  }

  /**
   * FUNCTIONALITIES
   */
  setMessage(){
    if (this.router.url.includes("/listado-profesores")) {
      this.message = "Base de datos profesores"
      return;
    }
    if (this.router.url.includes("/listado-alumnos")) {
      this.message = "Base de datos alumnos"
      return;
    }
    if (this.router.url.includes("/ficha-profesor")) {
      this.message = localStorage.getItem("mensaje")!;
      return;
    }
    if (this.router.url.includes("/formaciones")) {
      this.message = "Base de datos de formaciones"
      return;
    }
    if (this.router.url.includes("/listado-matriculaciones")) {
      this.message = "Matriculaciones"
      return;
    }
  }
}
