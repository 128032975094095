import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iFormation } from "./iFormation";

export interface iIncompany {
    id: number,
    formation?: iFormation | any,
}

export class iIncompany extends iBaseObject {
    static convertFromCollection(ob: any): Array<iIncompany> {
        let iIncompanyCollection: Array<iIncompany> = [];
        ob.forEach((element: any) => {
            iIncompanyCollection.push(this.convertFromObject(element));
        });
        return iIncompanyCollection;
    }

    static convertFromObject(ob: any): iIncompany {
        let iIncompanyObj = new iIncompany();
        iIncompanyObj.id = ob.id;
        iIncompanyObj.formation = ob.formation ? iFormation.convertFromObject(ob.formation) : {};
        return iIncompanyObj;
    }

    static createEmptyObject(): iIncompany {
        let iIncompanyObj = new iIncompany();
        iIncompanyObj.formation = {};
        return iIncompanyObj;
    }
}

export interface iIncompanyFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    formation?: number,
}