<div class="popupGeneralContainer">
  <div class="header">
    <p class="title">Reserva de tutoría</p>
    <p class="subtitle">Profesor: {{modalData.data.teacherName}}</p>
  </div>
  <div class="body">
    <div class="formLine doble">
      <selector class="popupAddProject" [options]="extraClass.masterSelectorOptions" [(ngModel)]="extraClass.ngModels.promotionId" (change)="onMasterChange(extraClass.ngModels.promotionId);checkIfComplete()" ></selector>
      <selector class="popupAddProject" [options]="extraClass.studentSelectorOptions" (change)="checkIfComplete()" [(ngModel)]="extraClass.ngModels.studentId" ></selector>
    </div>
    <div class="formLine doble">
      
      <div class="formLine doble">
        <customInput [options]='extraClass.dateCustomInputOptions' (change)="checkIfComplete()" [(ngModel)]="extraClass.ngModels.startDate" ></customInput>
        <customInput [options]='extraClass.timeCustomInputOptions' (change)="checkIfComplete()" [(ngModel)]="extraClass.ngModels.hours"></customInput>
      </div>

      <div class="formLine doble">
        <selector class="popupAddProject" (change)="checkIfComplete()" [options]="extraClass.modalitySelectorOptions" [(ngModel)]="extraClass.ngModels.tutoringAttendanceId" ></selector>
        <selector class="popupAddProject" (change)="checkIfComplete()" [options]="extraClass.stateSelectorOptions" [(ngModel)]="extraClass.ngModels.tutoringStudentStateId" ></selector>  
      </div>

    </div>
    <div class="formLine">
      <customInput style="width: 100%;" (input)="checkIfComplete()" [options]='extraClass.meetingUrlCustomInputOptions' [(ngModel)]="extraClass.ngModels.meetingUrl" ></customInput>
    </div>

    <div class="formLine">
      <customTextArea (input)="checkIfComplete()" [options]="extraClass.commentsCustomTextAreaOptions" style="width:100%" [(ngModel)]="extraClass.ngModels.comment" ></customTextArea>
    </div>

  </div>


  <div class="footer">
    <btn classList="principalButton" (click)="closePopup(null)">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="specialButton" [class.disabled]="!extraClass.canFinish" (click)="onFinalizar()">
      <ng-container>
        Finalizar
      </ng-container>
    </btn>
  </div>
</div>