import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  protected _GetPermisionByUserId = new BehaviorSubject<any>(null);
  getResultGetPermisionByUserId() {return this._GetPermisionByUserId}
  clearResultGetPermisionByUserId() {this._GetPermisionByUserId.next(null)}
  protected _GetPermisionByUserIdError = new BehaviorSubject<any>(null);
  getResultGetPermisionByUserIdError() {return this._GetPermisionByUserIdError}
  clearResultGetPermisionByUserIdError() {this._GetPermisionByUserIdError.next(null)}
  
  constructor(private http: HttpClient) {
    super();
  }

  
  getPermissionList(){
    this.http.get(this.urlApi + '/api/permission', this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedModuleFormation =  iStaticUtilities.normalizeNamesCollection(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedModuleFormation});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getPermissionList, args: [] });
        this.checkStatusError(error);
      },
    });
  }
  getPermissionByUserId(id:number){
    this.http.get(this.urlApi + '/api/user/permission/' + id, this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedModuleFormation =  data ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedModuleFormation});
        this.sendNextObservable(this._GetPermisionByUserId, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._GetPermisionByUserIdError, this.res,true, { method: this.getPermissionByUserId, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  toggleBrainAccess(id:number){
    this.http.put(this.urlApi + '/api/toggle/brain/user/' + id, {}, this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedModuleFormation =  iStaticUtilities.normalizeNames(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedModuleFormation});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.toggleBrainAccess, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  toggleBlockUser(id:number){
    this.http.put(this.urlApi + '/api/toggle/block/user/' + id, {}, this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedModuleFormation =  iStaticUtilities.normalizeNames(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: data.message, data: normalizedModuleFormation});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.toggleBrainAccess, args: [id] });
        this.checkStatusError(error);
      },
    });
  }
  
  assignPermissions(permission){
    this.http.post(this.urlApi+"/api/assign/permissions", permission, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.assignPermissions, args: [permission] });
        this.checkStatusError(error);
      },
    });
  }



}
