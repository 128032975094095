import { LoginComponent } from 'src/app/Pages/Login/Login/Login.component';
import { headerData } from '../Components/main-table/main-table.component';
import { SelectorAndSimpleDisplayComponent } from '../Components/selector-and-simple-display/selector-and-simple-display.component';
import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { SmallInputComponent } from '../Components/small-input/small-input.component';
import {
  MarksTextEditableComponent,
  iOptionsMarks,
} from '../Components/marks-text-editable/marks-text-editable.component';
import { ChipSelectorComponent } from '../Components/chip-selector/chip-selector.component';
import { StaticSelectorHandlersService } from 'src/app/Services/Utils/StaticSelectorHandlers.service';
import { iChipSelectorOptions } from '../Interfaces/iChipSelectorOptions';
import { Subject } from 'rxjs';
import {
  IPromotionStudent,
  iPromotionStudent,
} from '../Interfaces/Utils/iPromotionStudent';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';

export class DetailViewStudentsClass {
  // subjectsArray
  SubjectArray: Subject<any>[] = [];

  // mustSubscribeSubject
  mustSubscribeSubject: Subject<any> = new Subject();

  // SelectedRows
  selectedRows: any[] = [];

  // booleans
  isEditing: boolean = false;

  // displayTable
  displayTable: boolean = false;

  // selectorOptions
  modalitySelectorOptions: iOptionsSelector = {
    items: [
      {
        id: 1,
        name: 'Presencial',
      },
      {
        id: 2,
        name: 'Online',
      },
      {
        id: 3,
        name: 'Mixto',
      },
    ],
    readonly: true,
    search: true,
    clearable: false,
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    classList: 'slim',
    placeholder: 'Modalidad',
  };
  fundaeSelectorOptions: iOptionsSelector = {
    items: [
      {
        id: 1,
        name: 'Solo bonifica',
      },
      {
        id: 2,
        name: 'No bonifica',
      },
      {
        id: 3,
        name: 'Bonifica IEM',
      },
    ],
    readonly: true,
    search: true,
    clearable: false,
    append: true,
    classList: 'slim',
    placeholder: 'Fundae',
  };
  internshipSelectorOptions: iOptionsSelector = {
    items: [
      'Sí',
      'Sí. al inicio del máster',
      'Si, al final del máster',
      'No lo sabe',
      'No',
    ],
    readonly: true,
    search: true,
    clearable: false,
    append: true,
    classList: 'slim',
    placeholder: 'Prácticas',
  };

  // CustomInputOptions
  smallInputRateOptions: iOptionsCustomInput = {
    placeholder: 'Escriba la nota',
    type: 'number',
    readOnly: false,
    classList: 'smallInput--rate',
  };

  // MarkOptions
  markOptions: iOptionsMarks = {
    readonly: true,
  };

  // chipSelectorOptions
  chipSelectorOptions: iChipSelectorOptions = {
    bold: false,
    items: [],
    size: 'md',
    readonly: true,
    bindValue: 'id',
  };
  chipBorderSelectorOptions: iChipSelectorOptions = {
    bold: true,
    items: [],
    size: 'md',
    readonly: true,
    bindValue: 'id',
    border: true,
  };

  // TableData
  promotionStudent: iPromotionStudent[] = [IPromotionStudent.getEmptyObject()];

  // headerData
  tableHead: headerData = [
    {
      variant: 'bold',
      display: 'Alumno',
      key: 'studentName',
      width: '200px',
    },
    {
      variant: 'standard',
      display: 'Email',
      key: 'email',
      width: '200px',
    },
    {
      variant: 'standard',
      display: 'Teléfono',
      key: 'phone',
      width: '200px',
    },
    {
      variant: 'component',
      display: 'Modalidad',
      key: 'modalityId',
      alignment: 'center',
      cellClassName: 'justify-center',
      component: SelectorAndSimpleDisplayComponent,
      width: '200px',
      inputs: [
        {
          callback: (index, key) => this.simpleCallback(index, key),
          inputKey: 'model',
          key: 'modalityId',
        },
        {
          callback: (index, key) => this.modalitySelectorOptions,
          inputKey: 'optionsSelector',
          key: 'optionsSelector',
        },
      ],
    },
    {
      variant: 'component',
      display: 'Fundae',
      key: 'fundae',
      alignment: 'center',
      cellClassName: 'justify-center',
      component: SelectorAndSimpleDisplayComponent,
      width: '200px',
      inputs: [
        {
          callback: (index, key) => this.simpleCallback(index, key),
          inputKey: 'model',
          key: 'fundae',
        },
        {
          callback: (index, key) => this.fundaeSelectorOptions,
          inputKey: 'optionsSelector',
          key: 'optionsSelector',
        },
      ],
    },
    {
      variant: 'component',
      display: 'Prácticas',
      key: 'internship',
      alignment: 'center',
      cellClassName: 'justify-center',
      component: SelectorAndSimpleDisplayComponent,
      width: '200px',
      inputs: [
        {
          callback: (index, key) => this.simpleCallback(index, key),
          inputKey: 'model',
          key: 'internship',
        },
        {
          callback: (index, key) => this.internshipSelectorOptions,
          inputKey: 'optionsSelector',
          key: 'optionsSelector',
        },
      ],
    },
    {
      variant: 'component',
      display: 'Matrícula',
      key: 'enrollmentState',
      component: ChipSelectorComponent,
      width: '250px',
      cellClassName: 'justify-center',
      inputs: [
        {
          callback: (index: number, key: string) =>
            this.chipBorderSelectorOptions,
          inputKey: 'chipSelectorOptions',
          key: 'chipSelectorOptions',
        },
        {
          callback: (index: number, key: string) =>
            this.simpleCallback(index, key),
          inputKey: 'model',
          key: 'enrollmentState',
        },
      ],
    },
    {
      variant: 'component',
      display: 'Nota asistencia',
      key: 'attendanceQualification',
      component: MarksTextEditableComponent,
      cellClassName: 'justify-center',
      width: '100px',
      inputs: [
        {
          callback: (index, key) => this.simpleCallback(index, key),
          inputKey: 'mark',
          key: 'attendanceQualification',
        },
        {
          callback: (index, key) => this.markOptions,
          inputKey: 'options',
          key: 'options',
        },
      ],
    },
    {
      variant: 'component',
      display: 'Nota proyecto',
      key: 'projectQualification',
      component: MarksTextEditableComponent,
      width: '100px',
      cellClassName: 'justify-center',
      inputs: [
        {
          callback: (index, key) => this.simpleCallback(index, key),
          inputKey: 'mark',
          key: 'projectQualification',
        },
        {
          callback: (index, key) => this.markOptions,
          inputKey: 'options',
          key: 'options',
        },
      ],
    },
    {
      variant: 'component',
      display: 'Total',
      key: 'finalQualification',
      component: MarksTextEditableComponent,
      width: '100px',
      cellClassName: 'justify-center',
      inputs: [
        {
          callback: (index, key) => this.simpleCallback(index, key),
          inputKey: 'mark',
          key: 'finalQualification',
        },
        {
          callback: (index, key) => this.markOptions,
          inputKey: 'options',
          key: 'options',
        },
      ],
    },
    {
      variant: 'component',
      display: 'Estado TFM',
      key: 'projectState',
      component: ChipSelectorComponent,
      width: '250px',
      cellClassName: 'justify-center',
      inputs: [
        {
          callback: (index: number, key: string) => this.chipSelectorOptions,
          inputKey: 'chipSelectorOptions',
          key: 'chipSelectorOptions',
        },
        {
          callback: (index: number, key: string) =>
            this.simpleCallback(index, key),
          inputKey: 'model',
          key: 'projectState',
        },
      ],
    },
  ];

  // Functions

  simpleCallback(index, key) {
    return this.promotionStudent[index][key];
  }

  setAsEditing(boolean: boolean) {
    const readonly = !boolean;
    this.modalitySelectorOptions.readonly = readonly;
    this.fundaeSelectorOptions.readonly = readonly;
    this.smallInputRateOptions.readOnly = readonly;
    this.markOptions.readonly = readonly;
    this.chipSelectorOptions.readonly = readonly;
    this.chipBorderSelectorOptions.readonly = readonly;
    this.internshipSelectorOptions.readonly = readonly;
  }
}
