import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { StudentService } from 'src/app/Services/Api/Student.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-AddStudentToGroup-Popup',
  templateUrl: './AddStudentToGroup-Popup.component.html',
  styleUrls: ['./AddStudentToGroup-Popup.component.scss']
})
export class AddStudentToGroupPopupComponent extends iUnsubscribeDestroy implements OnInit {
  
  isSelectedUser: boolean = false;
  selectorStudent: any = null;
  isActive: boolean = false;

  selectorOptionsStudent: iOptionsSelector = {
    items: [],
    search: true,
    placeholder: "Busque un alumno por correo electrónico o DNI",
  }
  data: any = {
    name: "",
    surname: "",
    phone: "",
    email: "",
    phonePrefix: '+34'
  }



  constructor(public dialogRef: MatDialogRef<AddStudentToGroupPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private studentSE: StudentService) {
    super();
  }

  ngOnInit() {
    this.getAlumnosForSelector();
  }

  /**
   * FUNCTIONALITY
   */

  forceClosePopup() {
    this.dialogRef.close();
  }

  finishButton() {
    this.dialogRef.close(this.data);
  }

  isCompleted() {
    if(StaticUtilitiesService.isCompleteObject(this.data)){
      this.isActive = true;
    }else{
      this.isActive = false;
    }
  }

  selectedUser(event) {
    if (!event) {
      Object.keys(this.data).forEach((item: any) => {
        this.data[item] = "";
      });
       
      this.isSelectedUser = false;
      this.isCompleted();
      return;
    }
    this.isSelectedUser = true;
    this.data.name = event.name;
    this.data.surname = event.surname;
    this.data.phone = event.phone;
    this.data.email = event.email;
    this.data.phonePrefix = event.phonePrefix;
    this.data.dni = event.dni;
    this.isCompleted();
  }

  addPhonePrefix(event){
    this.data.phonePrefix = event.code;
    this.isCompleted();
  }


  /**
   * API CALLS
   */

  getAlumnosForSelector() {
    this.studentSE.getAllStudents({});
    this.studentSE.getResultallStudents().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) return;
      let { data } = res;
      this.selectorOptionsStudent.items = data.map((item: any) => { return { ...item, label: item.email + " - " + (item.dni ?? "") } });
       
      this._unsubInd.next('');
    });
    this.studentSE.getResultallStudentsError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    });
  }


}
