import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DebouncerService } from '@quasar-dynamics/basic-designsystem';

export interface iOptionsMarks {
  readonly: boolean;
}

@Component({
  selector: 'marks-text-editable',
  templateUrl: './marks-text-editable.component.html',
  styleUrls: ['./marks-text-editable.component.scss'],
})
export class MarksTextEditableComponent implements OnInit {
  // numberRegex
  private numberRegex = /^-?\d*([.,]\d*)?$/;
  private _options: iOptionsMarks = {
    readonly: true,
  };
  private;

  @Output() markChange = new EventEmitter();
  @Input() mark: string | null = null;
  @Input()
  get options() {
    return this._options;
  }
  set options(value) {
    this._options = value;
    this.cdr.detectChanges();
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  getEditableContent(event) {
    let text = event.target.innerHTML.replace(',', '.');
    // If it is a number
    if (this.numberRegex.test(text)) {
      const finalText = this.checkIfNumberIsGreaterThanMaxRatingOrBelowZero(event, text);
      DebouncerService.simpleDebouncer(() => this.emitChange(finalText));
    } else {
      // We are filtering the text to only allow numbers
      event.target.innerHTML = text.replace(/[^0-9.-]/g, '');
    }
  }

  checkIfNumberIsGreaterThanMaxRatingOrBelowZero(event, text) {
    let num = parseFloat(text);
    if (num > 10) {
      num = 10;
      event.target.innerHTML = num.toString();
      return num.toString();
    } else if(num < 0) {
      num = 0;
      event.target.innerHTML = num.toString();
      return num.toString();
    }
     else {
      return text;
    }
  }

  emitChange(text) {
    this.markChange.emit(text);
  }
}
