import { Injectable } from '@angular/core';
import { iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { iUserData } from 'src/app/Shared/Interfaces/Utils/iUserData';

@Injectable({
  providedIn: 'root',
})
export class GetPermisionsService {
  constructor() {}

  getDataFromLocalStorage(): Promise<iUserData> {
    return new Promise((resolve, reject) => {
      let dataFromLocal = localStorage.getItem('usr')!;
      let decripted = iStaticUtilities.simpleDecrypt(dataFromLocal);
      let userData = JSON.parse(decripted);
      resolve(userData);
    });
  }
}
