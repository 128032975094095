import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iMaster, iMasterFilter } from 'src/app/Shared/Interfaces/iMaster';

@Injectable({
  providedIn: 'root',
})
export class MasterService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  protected _GetAllDobleTitulacion = new BehaviorSubject<any>(null);
  getResultGetAllDobleTitulacion() {
    return this._GetAllDobleTitulacion;
  }
  clearResultGetAllDobleTitulacion() {
    this._GetAllDobleTitulacion.next(null);
  }
  protected _GetAllDobleTitulacionError = new BehaviorSubject<any>(null);
  getResultGetAllDobleTitulacionError() {
    return this._GetAllDobleTitulacionError;
  }
  clearResultGetAllDobleTitulacionError() {
    this._GetAllDobleTitulacionError.next(null);
  }
  protected _GetAllDobleTitulacionTable = new BehaviorSubject<any>(null);
  getResultGetAllDobleTitulacionTable() {
    return this._GetAllDobleTitulacionTable;
  }
  clearResultGetAllDobleTitulacionTable() {
    this._GetAllDobleTitulacionTable.next(null);
  }
  protected _GetAllDobleTitulacionTableError = new BehaviorSubject<any>(null);
  getResultGetAllDobleTitulacionTableError() {
    return this._GetAllDobleTitulacionTableError;
  }
  clearResultGetAllDobleTitulacionTableError() {
    this._GetAllDobleTitulacionTableError.next(null);
  }
  protected _GetAllMasters = new BehaviorSubject<any>(null);
  getResultGetAllMasters() {
    return this._GetAllMasters;
  }
  clearResultGetAllMasters() {
    this._GetAllMasters.next(null);
  }
  protected _GetAllMastersError = new BehaviorSubject<any>(null);
  getResultGetAllMastersError() {
    return this._GetAllMastersError;
  }
  clearResultGetAllMastersError() {
    this._GetAllMastersError.next(null);
  }

  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: iMasterFilter) {
    this.http
      .get(
        this.urlApi + '/api/master' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedMaster = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedMaster, total: data.total },
          });
          this.sendNextObservable(this._GetAllMasters, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [queryParams],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAllTable(queryParams: iMasterFilter) {
    this.http
      .get(
        this.urlApi + '/api/master/table' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: data.data, total: data.total },
          });
          this.sendNextObservable(this._GetAllMasters, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAllTable,
            args: [queryParams],
          });
          this.checkStatusError(error);
        },
      });
  }

  getMastersForFilter() {
    this.http
      .get(this.urlApi + '/api/masters/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedMaster =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedMaster,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getMastersForFilter,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getMastersForFilterWithFormationId() {
    this.http
      .get(this.urlApi + '/api/masters/selector/formation', this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedMaster =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedMaster,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getMastersForFilterWithFormationId,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }
  getActiveMasters() {
    this.http
      .get(this.urlApi + '/api/masters/active/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedMaster =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedMaster,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getMastersForFilter,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getPromotionTribunal(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(
        this.urlApi + `/api/master/promotion/${id}/project`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getPromotionTribunal,
            args: [behaviorSubject, id],
          });
        },
      });
  }

  getSingle(id: number) {
    this.http
      .get(this.urlApi + '/api/master/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  create() {
    this.http
      .post(this.urlApi + '/api/master', {}, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  update(master: iMaster) {
    this.http
      .put(this.urlApi + '/api/master/' + master.id, master, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [master],
          });
          this.checkStatusError(error);
        },
      });
  }

  delete(ids: Array<number | string>) {
    let idsObjs = this.convertArrayObject(ids, 'ids');
    this.http
      .delete(this.urlApi + '/api/master', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [ids],
          });
          this.checkStatusError(error);
        },
      });
  }

  // Doble titulacion

  createDoubleMaster(master: any) {
    this.http
      .post(this.urlApi + '/api/double/master', master, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.createDoubleMaster,
            args: [master],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAllDobleTitulacion(queryParams: any) {
    this.http
      .get(
        this.urlApi + '/api/double/master' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedMaster = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedMaster, total: data.total },
          });
          this.sendNextObservable(this._GetAllDobleTitulacion, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._GetAllDobleTitulacionError,
            this.res,
            true,
            { method: this.getAllDobleTitulacion, args: [queryParams] }
          );
          this.checkStatusError(error);
        },
      });
  }

  getAllDobleTitulacionTable(queryParams: any) {
    this.http
      .get(
        this.urlApi + '/api/double/master/table' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedMaster = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedMaster, total: data.total },
          });
          this.sendNextObservable(this._GetAllDobleTitulacionTable, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._GetAllDobleTitulacionTableError,
            this.res,
            true,
            { method: this.getAll, args: [queryParams] }
          );
          this.checkStatusError(error);
        },
      });
  }

  updateDobleTitulacion(dobleMaster: any) {
    this.http
      .put(
        this.urlApi + '/api/double/master/' + dobleMaster.id,
        dobleMaster,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.createDoubleMaster,
            args: [dobleMaster],
          });
          this.checkStatusError(error);
        },
      });
  }
}
