<div class="tutorias">
  <ng-container *ngIf="extraClass.tutoringPromotion.length > 0;else noData">


    <ng-container
      *ngFor="let teacher of extraClass.tutoringPromotion; index as tutoringIndex; trackBy:teacherTrackBy; ">


      <div class="tutoria__teacherBlock">

        <div class="tutoria__teacherBlock--title">
          {{teacher.teacherName}}
        </div>

        <ng-container *ngFor="let tutoring of teacher.tutorings">


          <div class="tutoria__teacherBlock--infoLine">

            <div class="tutoria__teacherBlock--infoLine--bluePill">
              {{tutoring.startDate}}
            </div>

            <div class="tutoria__teacherBlock--infoLine--bluePill">
              {{tutoring.hours}}
            </div>

            <div class="tutoria__teacherBlock--infoLine--bluePill">
              {{tutoring.slotMinutes}} minutos
            </div>

            <div class="tutoria__teacherBlock--infoLine--bluePill">
              {{tutoring.tutoringAttendanceName}}
            </div>

            <div class="tutoria__teacherBlock--infoLine--bluePill">
              {{tutoring.classroomName ?? '-'}}
            </div>

            <Chip [value]="tutoring.tutoringStateName" [type]="tutoring.tutoringStateColor"></Chip>

          </div>

          <div class="tutoria__teacherBlock--whiteBox">
            <main-table [dataSource]="tutoring.students" [headerData]="extraClass.headerData"></main-table>
          </div>

        </ng-container>


      </div>

    </ng-container>
  </ng-container>

</div>

<ng-template #noData>
  <div class="noData center-in-screen">
    No hay tutorias de esta promoción
  </div>
</ng-template>