<div class="fullSection">
  <div class="line" *ngFor="let student of studentList; let index = index">
    <div class="personalInfo">
      <p class="fwBold fsBig"> {{student.name + ' ' + student.surname}} </p>
      <p class="special fsSmall"> {{student.email}} </p>
      <span class="lineTelefono">
        <p class="special fsSmall">({{student.phonePrefix}})</p>
        <p class="special fsSmall"> {{student.phone}}</p>
      </span>
    </div>
    <btn classList="generalbutton trashLineFilterButton onlyIconLineaExcelAlumnos" (click)="deleteUserFromList(index)">
      <mat-icon>delete</mat-icon>
    </btn>
  </div>
</div>