import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-PropuestaFormalAccion-Popup',
  templateUrl: './PropuestaFormalAccion-Popup.component.html',
  styleUrls: ['./PropuestaFormalAccion-Popup.component.scss']
})
export class PropuestaFormalAccionPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PropuestaFormalAccionPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) { }

  ngOnInit() {
     
  }

  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
}
