import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  GeneralLoaderService,
  PopupService,
  StaticUtilitiesService,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { EditTutoringPopupComponent } from 'src/app/Popups/EditTutoring-Popup/EditTutoring-Popup.component';
import { TutoringService } from 'src/app/Services/Api/Tutoring.service';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';
import { SelectedDataTableBridgeService } from 'src/app/Services/Utils/SelectedDataTableBridge.service';
import { FormacionesNewVersionTutoriasClass } from 'src/app/Shared/Classes/FormacionesNewVersionTutoriasClass';

@Component({
  selector: 'FormacionesNewVersion-Tutorias',
  templateUrl: './FormacionesNewVersion-Tutorias.component.html',
  styleUrls: ['./FormacionesNewVersion-Tutorias.component.scss'],
})
export class FormacionesNewVersionTutoriasComponent implements OnInit {
  @Input() get refreshTutorings() {
    return this.extraClass.refreshTutorings;
  }

  set refreshTutorings(value) {
    this.extraClass.refreshTutorings = value;
    if (value) {
      this.getAllTutoring();
    }
  }

  @Input() filterTutorings: any = null;

  IconsHandlerService = IconsHandlerService;

  extraClass: FormacionesNewVersionTutoriasClass =
    new FormacionesNewVersionTutoriasClass();

  constructor(
    private tutoringSE: TutoringService,
    private generalLoaderSE: GeneralLoaderService,
    private popupSE: PopupService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private selectedDataTableBridgeSE: SelectedDataTableBridgeService
  ) {}

  ngOnInit() {
    this.getAllTutoring();
  }

  /**
   * HANDLERS
   */

  successGetAllTutoringHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.tutorings = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successEditTutoringHandler(res: iResultHttp) {
    const { data } = res;
    this.getAllTutoring();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteTutoringHandler(res: iResultHttp) {
    const { data } = res;
    this.getAllTutoring();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * TRACK BY
   */

  tutoringTrackBy(index: number, item: any): number {
    return item.id;
  }

  /**
   * FUNCTIONALITY
   */

  openEditPopup(tutoring) {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(EditTutoringPopupComponent, {
      data: { tutoringId: tutoring.tutoringId, teacherId: tutoring.teacherId },
    });
    this.popupSE
      .returnData()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        const returnValue = res.returnValue;
        if (returnValue) {
          this.editTutoring(returnValue);
        }
        subject.next(true);
      });
  }

  onDeleteClick(tutoringId: number) {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      elemento: 'la tutoría',
      accion: 'Eliminar',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res) => {
        const returnValue = res?.returnValue;
        if (undefined === returnValue) return;
        if (returnValue) this.deleteTutoring(tutoringId);
        subject.next('');
        subject.complete();
      });
    // this.deleteTutoring(tutoringId);
  }

  ifCannotDeleteBecauseTutoringInUse(id) {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      accion: 'Eliminar',
      elemento: 'la tutoría aunque tenga alumnos asignados',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res) => {
        const returnValue = res?.returnValue;
        if (returnValue) this.deleteTutoring(id, true);
        subject.next('');
        subject.complete();
      });
  }

  goToTeacherDetail(teacher, event: any) {
    const isCheckbox = event?.target.className.includes('mat-checkbox');
    if (isCheckbox) return;
    this.staticUtilitiesSE.goTo(
      'formaciones/tutoria-profesor',
      slideNavigationTypes.slideToLeft,
      { teacherId: teacher.teacherId, teacherName: teacher.teacherName }
    );
  }

  handlerSelectRow(
    teacherIndex: number,
    tutoringIndex: number,
    event: MatCheckboxChange
  ) {
    // event.source.;
    const element =
      this.extraClass.tutorings[teacherIndex].tutorings[tutoringIndex];
    this.selectedDataTableBridgeSE.setSelectedData(element);
    this.extraClass.selectedData =
      this.selectedDataTableBridgeSE.getSelectedData();
    // this.submitSelecteds();
  }

  onDuplicateClick(tutoringId: number) {
    this.staticUtilitiesSE.goTo(
      '/formaciones/crear-tutoria',
      slideNavigationTypes.slideToLeft,
      { duplicate: tutoringId }
    );
  }

  /**
   * API CALLS
   */

  getAllTutoring() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.getAll(behaviorSubject, this.filterTutorings);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetAllTutoringHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  editTutoring(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successEditTutoringHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteTutoring(id: number, forceDelete: boolean = false) {
    this.generalLoaderSE.addToLoaderAmount();
    const subjectDelete = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.delete(behaviorSubject, id, forceDelete);
    behaviorSubject
      .pipe(
        takeUntil(subjectDelete),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subjectDelete, [
          {
            method: () => this.successDeleteTutoringHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
          {
            method: () => this.ifCannotDeleteBecauseTutoringInUse(id),
            error: true,
          },
        ]);
      });
  }
}
