<div class="fullView">
  <p class="fwBold">Bonificación</p>

  <div class="loaderdiv" *ngIf="!studentDataStepFour">
    <loaderIcon></loaderIcon>
  </div>
  <div class="tricolumn" *ngFor="let enrollment of studentDataStepFour.enrollments; let index = index">
  <div class="subColumn especial">
    <label>Bonificación FUNDAE - {{enrollment.promotion.formation.name}}</label>
    <selector [(ngModel)]="fundae[index]" [options]="opcionesBonificacionFundae" (change)="sendToUpdate($event, 'fundae', 'enrollment', index)"></selector>
  </div>
</div>
  <div class="disclaimerTextContainer">
    <p class="disclaimerText">Cada empresa, por tener trabajadores a su cargo, paga una cuota en concepto de formación
      profesional. De esa cuota y esa plantilla va a depender el importe que cada empresa tiene disponible para la
      formación de sus empleados, pudiéndote bonificar hasta el 100% de la formación. Gestionamos de manera gratuita la
      bonificación de la formación a través de la Fundación Estatal para el Empleo (FUNDAE) para que tú no tengas que
      preocuparte por nada.</p>

    <p class="disclaimerText">Si tu empresa va a utilizar el crédito disponible para tu formación, marca:</p>

    <ul>
      <li class="disclaimerText"> <span class="fwBold">Sí, lo tramita IEM</span> en caso de que gestionemos nosotros la
        tramitación</li>
      <li class="disclaimerText"> <span class="fwBold">Sí, lo tramita mi empresa</span> en caso de que la bonificación
        sea gestionada por tu empresa</li>
      <li class="disclaimerText"> <span class="fwBold">No lo sé todavía</span> si aún no lo tienes claro</li>
      <li class="disclaimerText"> <span class="fwBold">No, no quiero bonificar</span> en caso de no bonificar la
        formación</li>
    </ul>
  </div>
</div>