import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iUser } from "./iUser";

export interface iStudent {
    id: number,
    intershipInterested?: boolean,
    user?: iUser | any,
}

export class iStudent extends iBaseObject {
    static convertFromCollection(ob: any): Array<iStudent> {
        let iStudentCollection: Array<iStudent> = [];
        ob.forEach((element: any) => {
            iStudentCollection.push(this.convertFromObject(element));
        });
        return iStudentCollection;
    }

    static convertFromObject(ob: any): iStudent {
        let iStudentObj = new iStudent();
        iStudentObj.id = ob.id;
        iStudentObj.intershipInterested = ob.intershipInterested;
        iStudentObj.user = ob.user ? iUser.convertFromObject(ob.user) : {};
        return iStudentObj;
    }

    static createEmptyObject(): iStudent {
        let iStudentObj = new iStudent();
        iStudentObj.intershipInterested = false;
        iStudentObj.user = {};
        return iStudentObj;
    }
}

export interface iStudentFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    intershipInterested?: boolean,
    user?: number,
}