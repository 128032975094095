import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleGlobalStateService {
  $roleGlobalState = new BehaviorSubject<string[]>([]);
  $permissionsGlobalState = new BehaviorSubject<number[]>([]);

  constructor() {}

  setRoleGlobalState(roles: string[]) {
    this.$roleGlobalState.next(roles);
  }

  getRoleGlobalState() {
    return this.$roleGlobalState;
  }

  hasRole(role: string) {
    return this.$roleGlobalState.value.includes(role);
  }

  hasAnyRole(roles: string[]) {
    return roles.some((role) => this.hasRole(role));
  }

  hasAllRoles(roles: string[]) {
    return roles.every((role) => this.hasRole(role));
  }

  clearRoleGlobalState() {
    this.$roleGlobalState.next([]);
  }

  addRole(role: string) {
    this.$roleGlobalState.next([...this.$roleGlobalState.value, role]);
  }

  addRoles(roles: string[]) {
    this.$roleGlobalState.next([...this.$roleGlobalState.value, ...roles]);
  }

  removeRole(role: string) {
    this.$roleGlobalState.next(
      this.$roleGlobalState.value.filter((r) => r !== role)
    );
  }

  removeRoles(roles: string[]) {
    this.$roleGlobalState.next(
      this.$roleGlobalState.value.filter((r) => !roles.includes(r))
    );
  }

  // Permissions
  setPermissionsGlobalState(permissions: number[]) {
    this.$permissionsGlobalState.next(permissions);
  }

  getPermissionsGlobalState() {
    return this.$permissionsGlobalState;
  }

  hasPermission(permission: number) {
    return this.$permissionsGlobalState.value.includes(permission);
  }

  hasAnyPermission(permissions: number[]): boolean {
    return permissions.some((permission) => this.hasPermission(permission));
  }

  hasAllPermissions(permissions: number[]) {
    return permissions.every((permission) => this.hasPermission(permission));
  }

  clearPermissionsGlobalState() {
    this.$permissionsGlobalState.next([]);
  }

  addPermission(permission: number) {
    this.$permissionsGlobalState.next([
      ...this.$permissionsGlobalState.value,
      permission,
    ]);
  }

  addPermissions(permissions: number[]) {
    this.$permissionsGlobalState.next([
      ...this.$permissionsGlobalState.value,
      ...permissions,
    ]);
  }

  removePermission(permission: number) {
    this.$permissionsGlobalState.next(
      this.$permissionsGlobalState.value.filter((p) => p !== permission)
    );
  }

  removePermissions(permissions: number[]) {
    this.$permissionsGlobalState.next(
      this.$permissionsGlobalState.value.filter((p) => !permissions.includes(p))
    );
  }

  clearAllGlobalState() {
    this.clearRoleGlobalState();
    this.clearPermissionsGlobalState();
  }
}
