import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { iOptionsScheduleTimeSelector } from '../../Interfaces/iOptionsScheduleTimeSelector';
import { DebouncerService } from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'schedule-time-selector',
  templateUrl: './schedule-time-selector.component.html',
  styleUrls: ['./schedule-time-selector.component.scss'],
})
export class ScheduleTimeSelectorComponent implements OnInit {

  previousValue: any = null;
  previousKey: string = '';

  @Output() ngModel1Change: EventEmitter<string> = new EventEmitter<string>();
  @Output() ngModel2Change: EventEmitter<string> = new EventEmitter<string>();

  @Input() options: iOptionsScheduleTimeSelector = {
    readonly: false,
    input1Options: {
      label: 'Desde',
      type: 'time',
      placeholder: 'Desde',
      required: true,
      readOnly: false,
    },
    input2Options: {
      label: 'Hasta',
      type: 'time',
      placeholder: 'Hasta',
      required: true,
      readOnly: false,
    },
  };

  @Input() ngModel1: string = '';
  @Input() ngModel2: string = '';

  time1: string = '';
  time2: string = '';

  constructor() {}

  ngOnInit() {}

  getChange($event, key: string) {
    if (typeof $event === 'string') {
      DebouncerService.formDebouncer(
        () => this.sendChange($event, key),
        $event,
        key,
        1000
      );
    } else {
      this[key] = $event.target.value;
      DebouncerService.formDebouncer(
        () => this.sendChange($event.target.value, key),
        $event.target.value,
        key,
        1000
      );
    }
  }

  sendChange(value, key) {
    if (value === this.previousValue && key === this.previousKey ) return;
    this[`${key}Change`].emit(value);
    this.previousValue = value;
    this.previousKey = key;
  }
}
