<div class="fullContainer">

  <div class="formLine flexEnd">
    <btn classList="generalbutton principalButton" (click)="createDisposition()" >Crear disposiciones</btn>
  </div>
  <div class="tableContainerCustom" skeleton [isDataLoaded]="isDispositionListLoaded">
    <table class="mainTableCustom" >
      <thead class="theadCustom">
        <tr class="tr">
          <th class="th"> </th>
        <th class="th" *ngFor="let item of displayedColumns "
          [ngClass]="{justifyContentFlexStart:item?.displayName === 'Nombre', estadoHeaderTable:item?.displayName === 'Estado'}">
          {{item.displayName}}  </th>
          <th></th>
        </tr>
      </thead>
      <div class="loaderLine" *ngIf="!isDispositionListLoaded">
        <loaderIcon [class.active]="!isDispositionListLoaded"></loaderIcon >
        </div>
        <tbody class="tbodyCustom" *ngIf="isDispositionListLoaded">
          <div class="noItems" *ngIf="dispositionList.length == 0">
        No hay items que mostrar
      </div>
      <tr class="tr" (click)="openDispositionPopup(disposition)" *ngFor="let disposition of dispositionList" >
        <td></td>
        <td class="td" *ngFor="let column of displayedColumns; let index = index;"
        [ngClass]="{fwBold: column?.property === 'name' || column?.property === 'fullName' ,justifyContentFlexStart:column?.property === 'name' || column?.property === 'fullName'  ,estadoTable:column?.property === 'state',dFlexGap1em:column?.property === 'enrollments', textCenter: column?.property === 'schedule' }">
        <p
        [ngClass]="{timeBox: column?.property === 'registerDate' || column?.property === 'endDate', estadoTabla: column?.property === 'state', formacionTabla: column?.property === 'enrollments', estadoTablaCursando:column?.property === 'state' && disposition.state == 'cursando' }">
        {{disposition[column?.property] ?? '-'}}  </p> 
        </td>
        <td class="icons">
          <mat-icon class="material-icons-outlined" (click)="$event.stopPropagation();deleteDispositionAassurer(disposition)">delete</mat-icon >
          </td>
        </tr>
      </tbody>
  </table>
  
  
</div>
</div>