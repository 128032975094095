<div class="popupGeneralContainer">
  <div class="header">
    <p class="title">Añadir factura</p>
    <p class="subtitle">Profesor: {{ modalData.data.teacherName }}</p>
  </div>
  <div class="body">
    <div class="formLine">
      <ng-container *ngIf="!this.extraClass.isEditing">
        <selector
          class="popupAddProject"
          style="width: 100%"
          [options]="extraClass.conceptSelectorOptions"
          [(ngModel)]="extraClass.ngModels.concepts"
          (change)="
            getConceptAndCalculateAmount(extraClass.ngModels.concepts);
            checkComplete()
          "
        ></selector>
      </ng-container>
      <ng-container *ngIf="this.extraClass.isEditing">
        <customInput
          style="width: 100%"
          [options]="extraClass.readonlyConceptCustomInputOptions"
          [(ngModel)]="extraClass.conceptLabel"
        ></customInput>
      </ng-container>
    </div>
    <div class="formLine equalThree">
      <customInput
        [options]="extraClass.billNumberCustomInputOptions"
        [(ngModel)]="extraClass.ngModels.number"
        (change)="checkComplete()"
      ></customInput>
      <customInput
        [options]="extraClass.importCustomInputOptions"
        [(ngModel)]="extraClass.ngModels.amount"
        (change)="checkComplete()"
      ></customInput>
      <customInput
        [options]="extraClass.billDateCustomInputOptions"
        [selectedDate]="extraClass.ngModels.date"
        [(ngModel)]="extraClass.ngModels.date"
        (change)="getDate($event, 'date'); checkComplete()"
      ></customInput>
    </div>
    <div class="formLine equalThree">
      <customInput
        [options]="extraClass.paymentDateCustomInputOptions"
        [selectedDate]="extraClass.ngModels.paymentDate ?? null"
        [(ngModel)]="extraClass.ngModels.paymentDate"
        (change)="getDate($event, 'paymentDate'); checkComplete()"
      ></customInput>
      <selector
        class="popupAddProject"
        style="width: 100%"
        [options]="extraClass.billStatusSelectorOptions"
        [(ngModel)]="extraClass.ngModels.billState"
        (change)="checkComplete()"
      ></selector>
    </div>

    <div class="formLine">
      <add-file
        [filesArray]="this.extraClass.files"
        (emitFiles)="getFiles($event); checkComplete()"
        [maxFileSizeMB]="10"
        [acceptedFileTypesArray]="['application/pdf']"
        style="width: 100%"
      ></add-file>
    </div>
  </div>

  <div class="footer">
    <btn classList="principalButton" (click)="closePopup(null)">
      <ng-container> Cancelar </ng-container>
    </btn>
    <btn
      classList="specialButton"
      [class.disabled]="!extraClass.canFinish"
      (click)="onFinalizar()"
    >
      <ng-container> Finalizar </ng-container>
    </btn>
  </div>
</div>
