<fullContainer>
  <ng-container>
    <header></header>
    <div class="formGeneralContainer">
      <div class="timeline">
        <div class="container">
          <p class="title"> Verificación del grupo {{data.name}}</p>
          <div class="options">
            <span [class.active]="optionSelected == 0" (click)="scroll('informacion')">
              <mat-icon>info</mat-icon>
              Información del grupo
            </span>
            <span [class.active]="optionSelected == 1" (click)="scroll('descripcion')">
              <mat-icon>groups</mat-icon>
              Listado de alumnos
            </span>
          </div>
        </div>
        <div class="footer">
          <!-- <btn classList="principalButton small fitWidth" *ngIf="!isEdit" (click)="confirmCancelPopup()">
            <ng-container>
              Cancelar
            </ng-container>
          </btn> -->
          <btn classList="specialButton small fitWidth loading" *ngIf="!isEdit" (click)="confirmEnroll()"
            [class.disabled]="!isActive">
            <ng-container *ngIf="!isLoading">
              Finalizar
            </ng-container>
            <ng-container *ngIf="isLoading">
              <loaderIcon class="white" size="small"></loaderIcon>
            </ng-container>
          </btn>
        </div>

      </div>
      <div class="form">

        <div class="contactoContainer">
          <div class="header">
            <p class="title">
              <mat-icon>info</mat-icon>
              Información del grupo
            </p>
          </div>
          <div class="body">
            <div class="doubleColumn">

              <CustomInput [type]="'text'" class="longInput" [label]="'Nombre de la propuesta'"
              [placeHolder]="'Nombre de la propuesta'" [(ngModel)]="data.name" name="nombre"
              (change)=" updateLine(data.name, 'name', 'group') "></CustomInput>              

              <div class="inputSimulatorWithLabel">
                <p class="fakeLabel">Precio</p>
                <div class="inputSimulator">
                  <div class="input" [class.placeHolder]="data.groupPrice == 0 || data.groupPrice == null">
                    <ng-container *ngIf="data.groupPrice == 0 || data.groupPrice == null">
                      <label>Precio</label>
                    </ng-container>
                    <ng-container *ngIf="data.groupPrice != 0">{{data.groupPrice
                      | currency:'EUR'}}</ng-container>
                  </div>
                  <CustomInput [type]="'number'" [(ngModel)]="data.groupPrice" [placeHolder]="'Precio'"
                    (change)=" updateLine(data.groupPrice, 'amount', 'enrolment')" name="number">
                  </CustomInput>
                </div>
              </div>

            </div>
              
          </div>
        </div>


        <div class="datosFiscales">
          <div class="header">
            <p class="title">
              <mat-icon>groups</mat-icon>
              Listado de alumnos
            </p>
            
            <btn classList="generalbutton principalButton" (click)="openAddStudentPopup()"> <mat-icon>add_circle_outline</mat-icon> Añadir alumno</btn>
          </div>
          <div class="body">

              
            <div class="studentsListContainer" skeleton [isDataLoaded]="isDataLoaded">

              <div class="line" *ngFor="let student of data.groupStudents let index = index">
                <div class="personalInfo">
                  <p class="fwBold fsBig"> {{student.student.user.name + ' ' + student.student.user.surname}} </p>
                  <p class="special fsSmall"> {{student.student.user.email}} </p>
                  <span class="lineTelefono">
                    <p class="special fsSmall">({{student.student.user.phonePrefix}})</p>
                    <p class="special fsSmall"> {{student.student.user.phone}}</p>
                  </span>
                </div>
                <btn classList="generalbutton trashLineFilterButton onlyIconLineaExcelAlumnos" (click)="deleteUserFromList(student)">
                  <mat-icon>delete</mat-icon>
                </btn>
              </div>
            


            </div>
          </div>
        </div>

        <div class="datosFiscales" [ngStyle]="{'opacity': '0', 'height': '27vh'}"></div>

      </div>
    </div>

  </ng-container>
</fullContainer>