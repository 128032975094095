import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { iDate } from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { StudentService } from 'src/app/Services/Api/Student.service';
import { TutoringService } from 'src/app/Services/Api/Tutoring.service';
import { EditTutoringreservationClass } from 'src/app/Shared/Classes/EditTutoringreservationClass';

@Component({
  selector: 'EditTutoringreservation',
  templateUrl: './EditTutoringreservation.component.html',
  styleUrls: ['./EditTutoringreservation.component.scss'],
})
export class EditTutoringreservationComponent implements OnInit {
  extraClass: EditTutoringreservationClass = new EditTutoringreservationClass();

  constructor(
    public dialogRef: MatDialogRef<EditTutoringreservationComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private promotionSE: PromotionFormationService,
    private generalLoaderSE: GeneralLoaderService,
    private studentSE: StudentService,
    private tutoringSE: TutoringService
  ) {}

  ngOnInit() {
    this.setModalData();
    this.getTutoringStudentById();
    this.checkIfItemsAndReadOnly();
    this.getPromotionTutoringSelector();
    this.getTutoringStudentStateSelector();
    this.getStudentTutoringAttendanceSelector();
  }

  /**
   * HANDLERS
   */

  successGetPromotionTutoringSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.masterSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetStudentsByPromotionSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.studentSelectorOptions.items = data;
    this.checkIfItemsAndReadOnly();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTutoringStudentStateSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.stateSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetStudentTutoringAttendanceSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.modalitySelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

    
  successGetTutoringStudentByIdHandler(res:iResultHttp){
    const { data } = res;
    this.setDataFromTutoringStudentById(data);
    this.generalLoaderSE.removeFromLoaderAmount();
   }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  setModalData() {
    this.extraClass.ngModels.hours = this.modalData.data.hours;
    this.extraClass.ngModels.startDate = iDate
      .javascriptConvert(new Date(this.modalData.data.date))
      .toStringDate('EU')
      .replaceAll('-', '/');
  }

  onMasterChange(promotionId) {
    this.extraClass.ngModels.studentId = null;
    this.extraClass.studentSelectorOptions.items = [];
    this.checkIfItemsAndReadOnly();
    this.getStudentsByPromotionSelector(promotionId);
  }

  checkIfItemsAndReadOnly() {
    this.extraClass.studentSelectorOptions.readonly = this.extraClass.studentSelectorOptions.items.length > 0 ? false : true;
  }

  checkIfComplete(){
    const copyOfModels = { ...this.extraClass.ngModels };
    delete copyOfModels.comment;
    delete copyOfModels.meetingUrl;
    const values = Object.values(copyOfModels);
    const isComplete = values.every((value) => value);
    this.extraClass.canFinish = isComplete;
  }

  onFinalizar(){
    const returnValue = {...this.extraClass.ngModels, id: this.modalData.data.tutoringStudentId}
    this.closePopup(returnValue);
  }

  setDataFromTutoringStudentById(data){
    this.extraClass.ngModels = data;
    this.getStudentsByPromotionSelector(data.promotionId);
  }

  /**
   * API Calls
   */

  getPromotionTutoringSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.getPromotionTutoringSelector(
      behaviorSubject,
      this.modalData.data.tutoringStudentId
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetPromotionTutoringSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getStudentsByPromotionSelector(promotionId: number) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.studentSE.getStudentsByPromotionSelector(behaviorSubject, promotionId);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successGetStudentsByPromotionSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getTutoringStudentStateSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.getTutoringStudentStateSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successGetTutoringStudentStateSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getStudentTutoringAttendanceSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.getStudentTutoringAttendanceSelector(
      behaviorSubject,
      this.modalData.data.tutoringStudentId
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successGetStudentTutoringAttendanceSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getTutoringStudentById() {
  this.generalLoaderSE.addToLoaderAmount();
  const subject = StaticUtilitiesService.createSubject();
  const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
  this.tutoringSE.getTutoringStudentById(behaviorSubject, this.modalData.data.tutoringStudentId);
  behaviorSubject
    .pipe(
      takeUntil(subject),
      filter((res) => res)
    )
    .subscribe((res: iResultHttp) => {
      StaticUtilitiesService.apiResponseHandler(res, subject, [
      {
        method: () => this.successGetTutoringStudentByIdHandler(res),
      },
      {
        method: () => this.generalLoaderSE.removeFromLoaderAmount(),
        error:true
      }
     ]);
    });
  }


}
