<div class="popupGeneralContainer">
  <div class="header">
    <p>Crear doble titulación</p>
  </div>

  <div class="body">
    <!-- <div class="subColumnLong" *ngIf="isEdit">
      <CustomInput [readOnly]="isEdit" [(ngModel)]="masters" [label]="'Masters incluidos'"></CustomInput>
    </div> -->
    <div class="subColumnLong">
      <label>Seleccione Masters</label>
      <selector
        [options]="optionsSelectorMasters"
        [(ngModel)]="masters"
        (change)="selectedMasters(masters)"
      ></selector>
    </div>

    <CustomInput
      [type]="'text'"
      class="width100"
      [placeHolder]="'Nombre de la doble titulación'"
      [label]="'Nombre de la doble titulación'"
      ngDefaultControl
      (keyup)="checkIfCompleted()"
      [(ngModel)]="data.name"
    >
    </CustomInput>
    <div class="quintucolumn">
      <div class="inputSimulatorWithLabel">
        <p class="fakeLabel">Cuota Única</p>
        <div class="inputSimulator">
          <div
            class="input"
            [class.placeHolder]="
              data.modality[0]?.price == 0 || data.modality[0]?.price == null
            "
          >
            <ng-container
              *ngIf="
                data.modality[0]?.price == 0 || data.modality[0]?.price == null
              "
            >
              <label>Cuota Única</label>
            </ng-container>
            <ng-container *ngIf="data.modality[0]?.price != 0">{{
              data.modality[0]?.price | currency : "EUR"
            }}</ng-container>
          </div>
          <CustomInput
            [type]="'number'"
            [(ngModel)]="data.modality[0].price"
            [placeHolder]="'Cuota Única'"
            (keyup)="checkIfCompleted()"
            name="number"
          ></CustomInput>
        </div>
      </div>

      <div class="inputSimulatorWithLabel">
        <p class="fakeLabel">Reserva</p>
        <div class="inputSimulator">
          <div
            class="input"
            [class.placeHolder]="
              data.modality[1].price == 0 || data.modality[1].price == null
            "
          >
            <ng-container
              *ngIf="
                data.modality[1].price == 0 || data.modality[1].price == null
              "
            >
              <label>Reserva</label>
            </ng-container>
            <ng-container *ngIf="data.modality[1].price != 0">{{
              data.modality[1].price | currency : "EUR"
            }}</ng-container>
          </div>
          <CustomInput
            [type]="'number'"
            [(ngModel)]="data.modality[1].price"
            [placeHolder]="'Reserva'"
            (keyup)="checkIfCompleted()"
            name="number"
          ></CustomInput>
        </div>
      </div>

      <div class="inputSimulatorWithLabel">
        <p class="fakeLabel">6 Cuotas</p>
        <div class="inputSimulator">
          <div
            class="input"
            [class.placeHolder]="
              data.modality[4].price == 0 || data.modality[4].price == null
            "
          >
            <ng-container
              *ngIf="
                data.modality[4].price == 0 || data.modality[4].price == null
              "
            >
              <label>6 Cuotas</label>
            </ng-container>
            <ng-container *ngIf="data.modality[4].price != 0">{{
              data.modality[4].price | currency : "EUR"
            }}</ng-container>
          </div>
          <CustomInput
            [type]="'number'"
            [(ngModel)]="data.modality[4].price"
            [placeHolder]="'6 Cuotas'"
            (keyup)="checkIfCompleted()"
            name="number"
          ></CustomInput>
        </div>
      </div>

      <div class="inputSimulatorWithLabel">
        <p class="fakeLabel">9 Cuotas</p>
        <div class="inputSimulator">
          <div
            class="input"
            [class.placeHolder]="
              data.modality[2].price == 0 || data.modality[2].price == null
            "
          >
            <ng-container
              *ngIf="
                data.modality[2].price == 0 || data.modality[2].price == null
              "
            >
              <label>9 Cuotas</label>
            </ng-container>
            <ng-container *ngIf="data.modality[2].price != 0">{{
              data.modality[2].price | currency : "EUR"
            }}</ng-container>
          </div>
          <CustomInput
            [type]="'number'"
            [(ngModel)]="data.modality[2].price"
            [placeHolder]="'9 Cuotas'"
            (keyup)="checkIfCompleted()"
            name="number"
          ></CustomInput>
        </div>
      </div>

      <div class="inputSimulatorWithLabel">
        <p class="fakeLabel">12 Cuotas</p>
        <div class="inputSimulator">
          <div
            class="input"
            [class.placeHolder]="
              data.modality[3].price == 0 || data.modality[3].price == null
            "
          >
            <ng-container
              *ngIf="
                data.modality[3].price == 0 || data.modality[3].price == null
              "
            >
              <label>12 Cuotas</label>
            </ng-container>
            <ng-container *ngIf="data.modality[3].price != 0">{{
              data.modality[3].price | currency : "EUR"
            }}</ng-container>
          </div>
          <CustomInput
            [type]="'number'"
            [(ngModel)]="data.modality[3].price"
            [placeHolder]="'12 Cuotas'"
            (keyup)="checkIfCompleted()"
            name="number"
          ></CustomInput>
        </div>
      </div>
    </div>

    <div class="footer">
      <btn classList="violetButton" (click)="forceClosePopup()">
        <ng-container> Cancelar </ng-container>
      </btn>

      <btn
        classList="principalButton"
        [class.disabled]="!isCompleted"
        (click)="createDobleTitulation()"
      >
        <ng-container> Crear </ng-container>
      </btn>
    </div>
  </div>
</div>
