import { TSendIsEditing } from 'src/app/Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-baseContainer/DetailView-baseContainer.component';
import { iBlueDataPicker } from '../Components/blue-data-picker/blue-data-picker.component';
import { iEditableBoxComponentOptions } from '../Components/Editablebox/Editablebox.component';
import { EnviarFechasButtonComponent } from '../Components/enviar-fechas-button/enviar-fechas-button.component';
import { iChipSelectorOptions } from '../Interfaces/iChipSelectorOptions';
import { iOptionsScheduleTimeSelector } from '../Interfaces/iOptionsScheduleTimeSelector';
import { iOptionsSelector } from '@quasar-dynamics/basic-designsystem';
import { IPromotionTribunal, iPromotionTribunal } from '../Interfaces/Utils/iPromotionTribunal';

export class DetailViewProjectsClass {

  constructor(private sendTeacherTribunalDates: (objectToPass:any) => void , private sendStudentDates: (objectToPass:any) => void ) {}

  // _tribunalPromotion
  _tribunalPromotion:iPromotionTribunal = IPromotionTribunal.getEmptyObject();

  // splittedTribunalPromotion
  masterDeliveryDates:iPromotionTribunal['masterDeliveryDates'] = IPromotionTribunal.getEmptyObject()['masterDeliveryDates'];
  projectId:iPromotionTribunal['projectId'] = IPromotionTribunal.getEmptyObject()['projectId'];
  teachers:iPromotionTribunal['teachers'] = IPromotionTribunal.getEmptyObject()['teachers'];
  tribunals:iPromotionTribunal['tribunals'] = IPromotionTribunal.getEmptyObject()['tribunals'];

  time1: string = '08:30';
  time2: string = '09:30';
  // ComponentOptions
  profesoresTribunalComponentOptions: iEditableBoxComponentOptions = {
    callback: () => {
      const selectedTeachers:number[] = this.selectedItemsArray.map((teacher) => teacher.teacherId)
      const objectToPass:any = {
        projectId: this.projectId,
        teacherIds: selectedTeachers
      }
      this.sendTeacherTribunalDates(objectToPass)
    },
    component: EnviarFechasButtonComponent,
  };
  studentTribunalComponentOptions: iEditableBoxComponentOptions = {
    callback: () => {
      const objectToPass:any = {
        projectId: this.projectId,
      }
      this.sendStudentDates(objectToPass)
    },
    component: EnviarFechasButtonComponent,
  };

  // addTeacherSelectorOptions
  addTeacherSelectorOptions: iOptionsSelector = {
    items: [{ name: 'Federico García', id: 0 }],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    clearable: false,
    placeholder: 'Seleccione un profesor',
    search: true,
    clearOnClick: true,
  };

  //   selectedItemsArray
  selectedItemsArray: any = [];

  // Chip selector options
  teacherChipSelectorOptions: iChipSelectorOptions = {
    bold: true,
    items: [],
    size: 'md',
    readonly: true,
    bindValue: 'id',
  };

  // profesoresTribunalArray
  profesoresTribunalArray: any = [
    {
      name: 'Federico García',
      id: 0,
      state: 1,
      conflict: false,
    },
  ];

  // editableController
  editableController: TSendIsEditing[] = [];

  // TribunalEditablecontroller
  tribunalEditablecontroller: TSendIsEditing[] = [];

  // Options blue data picker
  optionsBlueDataPicker: iBlueDataPicker = {
    placeholder: 'Fecha',
    readonly: true,
    type: 'date',
  };

  // Tribunal blue data picker array
  tribunalBlueDataPickerArray: iBlueDataPicker[] = [];

  // Tribunal schedule time selector array
  tribunalScheduleTimeSelectorArray: iOptionsScheduleTimeSelector[] = [];

  // Tribunal blue selector duration array
  tribunalBlueSelectorDurationArray: iOptionsSelector[] = [];

  // Tribunal blue selector aula array
  tribunalBlueSelectorAulaArray: iOptionsSelector[] = [];

  // matrixOptionsStudents
  matrixOptionsStudents: any = [];

  // matrixOptionsStudentsStatus
  matrixOptionsStudentsStatus: any = [];

  // studentSelectorOptions
  studentSelectorOptions: iOptionsSelector = {
    items: [
      {
        name: 'Federico García',
        id: 1,
      },
      {
        name: 'Federico García',
        id: 2,
      },
    ],
    append: true,
    bindLabel: 'fullName',
    bindValue: 'id',
    clearable: false,
    placeholder: 'Seleccionar',
    search: true,
    readonly: true,
    classList:'slim tribunal'
  };

  // studentStatusSelectorOptions
  studentStatusSelectorOptions: iChipSelectorOptions = {
    items: [],
    size: 'md',
    readonly: true,
    bindValue: 'id',
    bold: true,
    
  };

  datesArray: any = [
    {
      date: new Date('2020-01-01'),
      title: 'Títulok y breve descripción del proyecto',
      canEditTitle: false,
    },
    {
      date: '2020-01-02',
      title: 'Memoria del proyecto',
      canEditTitle: false,
    },
    {
      date: '2020-01-03',
      title: 'Presentación del proyecto',
      canEditTitle: false,
    },
    {
      date: '',
      title: 'Custom',
      canEditTitle: true,
    },
  ];

  // sheduleTimeSelectorOptions
  sheduleTimeSelectorOptions: iOptionsScheduleTimeSelector = {
    input1Options: {
      placeholder: 'Desde',
      readOnly: false,
      type: 'time',
    },
    input2Options: {
      placeholder: 'Hasta',
      readOnly: false,
      type: 'time',
    },
    readonly: true,
  };

  // optionsBlueSelectorDuration
  optionsBlueSelectorDuration: iOptionsSelector = {
    items: [
      { name: '15 min', id: 0 },
      { name: '30 min', id: 1 },
      { name: '45 min', id: 2 },
      { name: '1 hora', id: 3 },
    ],
    append: true,
    readonly: true,
    placeholder: 'Duración',
    search: true,
    clearable: false,
    bindLabel: 'name',
    bindValue: 'id',
  };
  // optionsBlueSelectorAula
  optionsBlueSelectorAula: iOptionsSelector = {
    items: [
      { name: 'Aula 1', id: 0 },
      { name: 'Aula 2', id: 1 },
      { name: 'Aula 3', id: 2 },
      { name: 'Aula 4', id: 3 },
    ],
    append: true,
    readonly: true,
    placeholder: 'Aula',
    search: true,
    clearable: false,
    bindLabel: 'name',
    bindValue: 'id',
  };

  // tribunalesArray
  tribunalesArray: any = [
    {
      name: 'Federico García',
      id: 1,
      state: 1,
      conflict: false,
      date: '2020-01-01',
      start: '08:30',
      end: '09:30',
      aula: 1,
      duration: 1,
    },
  ];

  //   functions
  setReadonly() {
    this.optionsBlueDataPicker.readonly = this.editableController[
      'Fechas entrega'
    ]
      ? false
      : true;
    this.teacherChipSelectorOptions.readonly = this.editableController[
      'Profesores tribunal'
    ]
      ? false
      : true;

  }

  setReadonlyTribunal(boolean: boolean) {
    const readonly = !boolean;
    this.tribunalBlueDataPickerArray.forEach((element) => {
      element.readonly = readonly;
    });
    this.tribunalScheduleTimeSelectorArray.forEach((element) => {
      element.readonly = readonly;
    });
    this.tribunalBlueSelectorDurationArray.forEach((element) => {
      element.readonly = readonly;
    });
    this.tribunalBlueSelectorAulaArray.forEach((element) => {
      element.readonly = readonly;
    });
    this.matrixOptionsStudents.forEach((element) => {
      element.forEach((studentOption) => {
        studentOption.readonly = readonly;
      })
    })
    this.matrixOptionsStudentsStatus.forEach((element) => {
      element.forEach((studentStatusOption) => {
        studentStatusOption.readonly = readonly;
      })
    })

  }

  printConsolelog() {
  }
}
