import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp } from '@quasar_dynamics/basic-designsystem';

@Injectable({
  providedIn: 'root'
})
export class ModalityPriceService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  update(modalityPrice){
    this.http.put(this.urlApi+"/api/modality/price/formation/"+modalityPrice.id, modalityPrice, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [modalityPrice] });
        this.checkStatusError(error);
      },
    });

  }

}
