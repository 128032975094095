import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';
import {
  DebouncerService,
  GeneralLoaderService,
  StaticUtilitiesService,
  iDate,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { iDateChangeCalendar } from '../../Interfaces/Utils/full-calendar/iDateChangeCalendar';
import { iDateClickCalendar } from '../../Interfaces/Utils/full-calendar/iDateClickCalendar';
import { iEventClickCalendar } from '../../Interfaces/Utils/full-calendar/iEventClickCalendar';
import { EventImpl } from '@fullcalendar/core/internal';
import { EventCalendar } from '../../Classes/EventCalendar';
import { LandmarkService } from 'src/app/Services/Api/Landmark.service';
import { filter, takeUntil } from 'rxjs';
import { iCustomCheckboxOptions } from '../../Interfaces/Utils/iCustomCheckboxOptions';
import { ConflictsService } from 'src/app/Services/Utils/Conflicts.service';

@Component({
  selector: 'Calendar',
  templateUrl: './Calendar.component.html',
  styleUrls: ['./Calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  private _publicHolidays: any = [];
  private _events: any = [];
  private _checkBoxesArray: iCustomCheckboxOptions[] = [];

  @ViewChild('calendar') calendarComponent?: FullCalendarComponent;
  @Input() isMaster: boolean = false;
  @Input() isCurso: boolean = false;
  @Input() isTeacher: boolean = false;

  @Output() dateChange: EventEmitter<iDateChangeCalendar> =
    new EventEmitter<iDateChangeCalendar>();
  @Output() dateClick: EventEmitter<iDateClickCalendar> =
    new EventEmitter<iDateClickCalendar>();
  @Output() eventClick: EventEmitter<iEventClickCalendar> =
    new EventEmitter<iEventClickCalendar>();
  @Output() refreshLandmarks: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() get checkBoxesArray() {
    return this._checkBoxesArray;
  }
  set checkBoxesArray(value) {
    this._checkBoxesArray = value;
  }

  @Input() get events() {
    return this._events;
  }

  set events(value) {
    this._events = value;
    if (Array.isArray(this.calendarOptions.events)) {
      this.calendarOptions.events = [];
      this.calendarOptions.events.push(value);
      if (this.publicHolidays) {
        this.calendarOptions.events.push(this.publicHolidays);
      }
      this.calendarOptions.events = this.calendarOptions.events.flat();
      this.cdr.detectChanges();
    }
  }

  @Input() get publicHolidays() {
    return this._publicHolidays;
  }
  set publicHolidays(value) {
    this._publicHolidays = value;
    if (Array.isArray(this.calendarOptions.events)) {
      this.calendarOptions.events.push(value);
      this.calendarOptions.events = this.calendarOptions.events.flat();
      this.cdr.detectChanges();
    }
  }

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
      multiMonthPlugin,
    ],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay,listYear',
    },
    firstDay: 1,
    buttonText: {
      today: 'Hoy',
      month: 'Mes',
      week: 'Semana',
      day: 'Dia',
      list: 'Lista',
    },
    selectable: true,
    locales: [esLocale],
    showNonCurrentDates: false,
    events: [],
    displayEventTime: true,
    displayEventEnd: true,
    dateClick: this.handleDateClick.bind(this),
    eventClick: this.handleEventClick.bind(this),
    editable: true,
    eventStartEditable: true,
    eventDurationEditable: true,
    dragScroll: true,
    eventAllow: this.allowEdit.bind(this),
    eventContent: this.renderEventContent.bind(this),

    views: {
      dayGridMonth: {
        eventContent: this.renderMonthEventContent.bind(this),
      },
      timeGridWeek: {
        eventContent: this.renderWeekEventContent.bind(this),
      },
      timeGridDay: {
        eventContent: this.renderDayEventContent.bind(this),
      },
      listYear: {
        eventContent: this.renderDayEventContent.bind(this),
      },
    },
  };

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private generalLoaderSE: GeneralLoaderService,
    private landmarkSE: LandmarkService,
    private conflictsSE: ConflictsService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.setOnclicksOnButtons();
    const topBar = document.getElementsByClassName('fc-scroller-harness')[0];
  }

  /**
   * HANDLERS
   */

  successGetPublicHolidaysHandler(res: iResultHttp) {
    let { data } = res;
  }

  handleDateClick(arg) {
    const dateToPass: iDateClickCalendar = arg;
    this.dateClick.emit(dateToPass);
  }

  handleEventClick(arg) {
    // Las cosas están en arg.event. Son las que se ven en gris.
    const eventToPass: iEventClickCalendar = arg;
    this.eventClick.emit(eventToPass);
  }

  successUpdateLandmarkHandler(res: iResultHttp) {
    const { data } = res;
    this.refreshLandmarks.emit(true);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */

  setOnclicksOnButtons() {
    const previous = this.renderer.selectRootElement('.fc-prev-button');
    const next = this.renderer.selectRootElement('.fc-next-button');
    const today = this.renderer.selectRootElement('.fc-today-button');

    this.renderer.listen(previous, 'click', () =>
      this.emmitDateOptionsOnChange('previous')
    );
    this.renderer.listen(next, 'click', () =>
      this.emmitDateOptionsOnChange('next')
    );
    this.renderer.listen(today, 'click', () =>
      this.emmitDateOptionsOnChange('today')
    );
  }

  returnViewType(viewType): string {
    const viewOptions: any = {
      multiMonthYear: 'Year',
      dayGridMonth: 'Month',
      timeGridWeek: 'Week',
      timeGridDay: 'Day',
      listMonth: 'List',
    };
    return viewOptions[viewType];
  }

  emmitDateOptionsOnChange(action) {
    const date = this.calendarComponent!.getApi().getDate();
    const objectToEmmit: iDateChangeCalendar = {
      option: this.returnViewType(this.calendarComponent!.getApi().view.type),
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
      action: action,
    };
    this.dateChange.emit(objectToEmmit);
  }

  // Esto se extrae para que el padre tenga la lógica de mapear los datos

  allowEdit(date, editEvent: EventImpl | null): boolean {
    if (!editEvent) return false;

    const landmarkId = editEvent.id;
    const event = this.events.find((event) => event.id == landmarkId);
    if (!event) return false;

    let landmarkTypeId = event.landmarkTypeId;
    const canEdit =
      EventCalendar.returnEditableEventsInCalendar().includes(landmarkTypeId);

    if (!canEdit) return false;

    DebouncerService.formDebouncer(
      () => this.updateLandmarkDueToMoving(event, date),
      event.className,
      landmarkId,
      1500
    );

    return true;
  }

  updateLandmarkDueToMoving(event: any, date: any) {
    const startDate = iDate.javascriptConvert(date.start).toStringDate('JAP');
    const startHour = iDate.javascriptConvert(date.start).toStringHours();

    const endDate = iDate.javascriptConvert(date.end).toStringDate('JAP');
    const endHour = iDate.javascriptConvert(date.end).toStringHours();

    const allDay = event.allDay;

    const objectToPass = {
      id: event.id,
      startDate,
      startHour,
      endDate,
      endHour,
      allDay,
    };

    this.updateLandmark(objectToPass);
  }

  renderMonthEventContent(info) {
    const extendedProps = info.event._def.extendedProps;
    if (extendedProps?.holiday === true) {
      return { html: `<b style="color:white">${info.event.title}</b>` };
    }
    if (this.isMaster || this.isCurso) {
      return {
        html: `<div class="column" >
      <p>${extendedProps.startHour ?? ''}</p>
            ${this.returnElementsInHTMLElements(
              extendedProps.teacherNames,
              'p'
            )}
      <b>${(extendedProps.subtitle ?? '') || (extendedProps.title ?? '')}</b>
      </div>`,
      };
    }
    return {
      html: `<div class="column" >
      <p>${extendedProps.startHour ?? ''}</p>
      <b>${extendedProps.formationName ?? info.event.title ?? ''}</b>
      <b>${extendedProps.editionName ?? ''}</b>
      </div>`,
    };
  }

  // Renderizar contenido personalizado para eventos en vista semanal
  renderWeekEventContent(info) {
    const extendedProps = info.event._def.extendedProps;
    if (extendedProps?.holiday === true) {
      return { html: `<b style="color:white">${info.event.title}</b>` };
    }
    return {
      html: `<div class="column" >
      <b>${extendedProps.formationName ?? info.event.title ?? ''}</b>
      ${this.returnElementsInHTMLElements(extendedProps.teacherNames, 'p')}
      ${this.returnElementsInHTMLElements(extendedProps.companyNames, 'p')}
      <b>${extendedProps.editionName ?? ''}</b>
      <b>${extendedProps.classroomName ?? ''}</b>
      </div>`,
    };
  }

  // Renderizar contenido personalizado para eventos en vista diaria
  renderDayEventContent(info) {
    const extendedProps = info.event._def.extendedProps;
    if (extendedProps?.holiday === true) {
      return { html: `<b style="color:white">${info.event.title ?? ''}</b>` };
    }
    return {
      html: `<div class="row" >
      <p>${extendedProps.startHour ?? ''} h a ${extendedProps.endHour ?? ''}</p>
      <p>|</p>
      <b>${extendedProps.formationName ?? info.event.title ?? ''}</b>
      <p>|</p>
      ${this.returnElementsInHTMLElements(extendedProps.teacherNames, 'b')}
      <p>|</p>
      <b>${extendedProps.subtitle ?? ''}</b>
      <p>|</p>
      <b>${extendedProps.classroomName ?? ''}</b>
      <p>|</p>
      ${this.returnElementsInHTMLElements(extendedProps.companyNames, 'b')}

      </div>`,
    };
  }

  // Método genérico para renderizar contenido de eventos
  renderEventContent(info) {
    const extendedProps = info.event._def.extendedProps;
    let title = extendedProps.formationName ?? info.event.title;
    return {
      html: `
            ${
              this.isMaster || this.isCurso
                ? '<b>' + extendedProps.editionName + '</b>'
                : '<b>' + title ?? '' + '</b>'
            }
      `,
    };
  }

  returnElementsInHTMLElements(
    array: string[],
    element: 'p' | 'b' | 'span' | 'div'
  ) {
    const newArray = [...new Set(array)];
    return newArray.map((item) => `<${element}>${item}</${element}>`).join('');
  }
  /**
   * API CALLS
   */

  updateLandmark(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.landmarkSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateLandmarkHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
          {
            method: () => this.conflictsSE.manageConflicts(res),
            error: true,
          },
        ]);
      });
  }
}
