import {
  Component,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import {
  SubjectGeneratorService,
  iDate,
  iOptionsCustomInput,
  iOptionsSelector,
  iResultHttp,
} from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { GubrtService } from 'src/app/Services/Api/Gubrt.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

@Component({
  selector: 'FormInformacionPersonal',
  templateUrl: './FormInformacionPersonal.component.html',
  styleUrls: ['./FormInformacionPersonal.component.scss'],
})
export class FormInformacionPersonalComponent
  extends SubjectGeneratorService
  implements OnInit
{
  private _studentData: any = null;

  @Input() countriesList: any[] = [];
  @Input() dataFromFather: any = null;
  @Input() token: any = null;
  @Output() dataFromStepOne: EventEmitter<any> = new EventEmitter<any>();
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input()
  get studentData() {
    return this._studentData;
  }
  set studentData(value) {
    if (!value) return;
    this._studentData = value;
    value.enrollments.forEach((enrollment, index) => {
      this.getAttendanceModalitiesByEnrollment(enrollment, index);
    });
  }

  objectToSend: any = {};
  modalidadSeleccionada: any = [];
  fundae: any = null;
  isDocumentLoading: boolean = true;
  studentDataStepOne: any = null;
  fechaNacimiento: string = iDate
    .javascriptConvert(new Date())
    .toStringDate('EU');

  opcionesSexo: iOptionsSelector = {
    items: [
      { id: 1, label: 'Hombre' },
      { id: 2, label: 'Mujer' },
      { id: 3, label: 'Otro' },
      { id: 4, label: 'Prefiero no decirlo' },
    ],
    append: true,
    placeholder: 'Sexo',
    clearable: false,
  };
  opcionesModalidad1: iOptionsSelector = {
    items: [],
    append: true,
    placeholder: 'Modalidad',
    bindValue: 'id',
    bindLabel: 'name',
    clearable: false,
  };
  opcionesModalidad2: iOptionsSelector = {
    items: [],
    append: true,
    placeholder: 'Modalidad',
    bindValue: 'id',
    bindLabel: 'name',
    clearable: false,
  };
  opcionesBonificacionFundae: iOptionsSelector = {
    items: [
      { label: 'Bonificación IEM' },
      { label: 'Bonificación Empresa' },
      { label: 'Solo Bonificación' },
      { label: 'Sin Bonificación' },
    ],
    append: true,
    placeholder: 'Bonificación FUNDAE',
    bindValue: 'label',
    bindLabel: 'label',
    clearable: false,
  };
  optionsFecha: iOptionsCustomInput = {
    placeholder: 'Fecha de nacimiento',
    type: 'date',
    label: 'Fecha de nacimiento',
  };
  opcionesIdiomas: iOptionsSelector = {
    items: StaticDataHandlerService.getLanguages(),
    clearable: true,
    search: true,
    placeholder: 'Selecciona los idiomas',
    append: true,
    multiple: {
      isMultiple: true,
      multipleTextReplacer: `Varios idiomas seleccionados`,
      maxSelectedItems: 3,
    },
  };
  opcionesPais: iOptionsSelector = {
    items: [],
    search: true,
    placeholder: 'Selecciona el país',
  };

  constructor(
    private gubrtSE: GubrtService,
    private generalLoaderSE: GeneralLoaderService
  ) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['countriesList']) {
      if (changes['countriesList'].currentValue) {
        let nacionalidades = changes['countriesList'].currentValue.map(
          (country) => country.ethnicity
        );
        this.opcionesPais.items = nacionalidades;
      }
    }
    if (changes['studentData']) {
      if (changes['studentData'].currentValue) {
        this.studentDataStepOne = changes['studentData'].currentValue;
        this.modalidadSeleccionada = this.studentDataStepOne.enrollments.map(
          (enrollment) => enrollment.modality
        );
        this.fundae = this.studentDataStepOne.enrollments.map(
          (enrollment) => enrollment.fundae
        );
        this.isDocumentLoading = false;
      }
    }
    if (changes['dataFromFather']) {
      if (changes['dataFromFather'].currentValue) {
        let data = changes['dataFromFather'].currentValue;
        if (data.fundae) this.fundae = data.fundae;
        if (data.modalidadSeleccionada)
          this.modalidadSeleccionada = data.modalidadSeleccionada;
      }
    }
    if (changes['token']) {
      if (changes['token'].currentValue) {
        this.objectToSend.token = changes['token'].currentValue;
      }
    }
  }

  /**
   * HANDLERS
   */

  successGetAmbpiHandler(res: iResultHttp, index, enrollment) {
    const { data } = res;
    this[`opcionesModalidad${index + 1}`].items = data;
    this.setAttendanceModality(enrollment, index);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  errorGetAmbpiHandler() {
    window.open('/', '_self');
    StaticUtilitiesService.showError('No tienes permisos para estar aquí');
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */

  getAttendanceModalitiesByEnrollment(enrollment, index) {
    // Traemos los assistance modalities de cada promoción
    const objectToPass = {
      token: this.token,
      promotionId: enrollment.promotion.id,
    };
    this.getAmpid(objectToPass, index, enrollment);
  }

  setAttendanceModality(enrollment, index) {
    this.modalidadSeleccionada[index] = enrollment?.modality?.id ?? null;
  }

  emit() {
    let objectToPass: any = {
      fundae: this.fundae,
      modalidadSeleccionada:
        this.modalidadSeleccionada.length !== 0
          ? this.modalidadSeleccionada
          : this.studentDataStepOne.enrollments.map(
              (enrollment) => enrollment.modality
            ),
    };
    this.dataFromStepOne.emit(objectToPass);
  }
  sendToUpdate(value, key, option, index?) {
    switch (option) {
      case 'enrollment':
        this.objectToSend.enrollment = {};
        this.objectToSend.enrollment.id =
          this.studentDataStepOne.enrollments[index].id;
        this.objectToSend.enrollment[key] = value;
        this.update();
        delete this.objectToSend[key];
        delete this.objectToSend.enrollment.id;
        this.objectToSend.enrollment;
        break;
      case 'student':
        break;

      default:
        break;
    }

    this.emit();
  }

  /**
   * API CALLS
   */

  update() {
    const unsubSubject = this.createSubject();
    this.gubrtSE.editGubrtOldVersion(this.objectToSend);
    this.gubrtSE
      .getResultUpdate()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        //  this.refresh.emit(true);
        this.sendDataToSubject(unsubSubject);
      });
    this.gubrtSE
      .getResultUpdateError()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) return;
        this.isDocumentLoading = false;
        window.open('/', '_self');
        StaticUtilitiesService.showError('No tienes permisos para estar aquí');
        this.sendDataToSubject(unsubSubject);
      });
  }

  getAmpid(
    objectToPass: { token: string; promotionId: number },
    index: number,
    enrollment
  ) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.gubrtSE.getAmpid(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetAmbpiHandler(res, index, enrollment),
          },
          {
            method: () => this.errorGetAmbpiHandler(),
            error: true,
          },
        ]);
      });
  }
}
