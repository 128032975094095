

export interface iPromotionStudent {
  id: number;
  studentName: string;
  email: string;
  phone: string;
  modalityId: number | null;
  modality: string | null;
  fundae: string | null;
  enrollmentId: number | null;
  enrollmentState: number;
  attendanceQualification: number;
  projectQualification: number;
  finalQualification: number;
  projectState: number;
}

export class IPromotionStudent {
  static getEmptyObject(): iPromotionStudent {
    return {
      id: 0,
      studentName: '',
      email: '',
      phone: '',
      modalityId: null,
      modality: null,
      fundae: null,
      enrollmentId: null,
      enrollmentState: 0,
      attendanceQualification: 0,
      projectQualification: 0,
      finalQualification: 0,
      projectState: 0
    };
  }
}