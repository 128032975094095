
export interface iAcademicRegister {
    id: number;
    formationName: string | null;
    concept: string | null;
    date: string | null; // Consider using a more specific type like Date if you're going to manipulate date objects
    startHour: string | null;
    endHour: string | null;
    formationState: string;
    formationStateColor: string;
    type: string;
    dateState: string;
    dateStateColor: string;
    tutoringPromotion: string | null;
    totalHours: string | null;
}

export class IAcademicRegister {
    static createEmptyObject(): iAcademicRegister {
        return {
            id: 0,
            formationName: '',
            concept: '',
            date: '',
            startHour: '',
            endHour: '',
            formationState: '',
            formationStateColor: '',
            type: '',
            dateState: '',
            dateStateColor: '',
            tutoringPromotion: '',
            totalHours: '',
        };
    }
}