import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, SubjectManagerService, iBehaviourSubject, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PipedriveService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();
  subjectManager: iBehaviourSubject = new iBehaviourSubject();

  protected _MasterPipeDrive = new BehaviorSubject<any>(null);
  getResultMasterPipeDrive() {return this._MasterPipeDrive}
  clearResultMasterPipeDrive() {this._MasterPipeDrive.next(null)}
  protected _MasterPipeDriveError = new BehaviorSubject<any>(null);
  getResultMasterPipeDriveError() {return this._MasterPipeDriveError}
  clearResultMasterPipeDriveError() {this._MasterPipeDriveError.next(null)}

  protected _CoursePipeDrive = new BehaviorSubject<any>(null);
  getResultCoursePipeDrive() {return this._CoursePipeDrive}
  clearResultCoursePipeDrive() {this._CoursePipeDrive.next(null)}
  protected _CoursePipeDriveError = new BehaviorSubject<any>(null);
  getResultCoursePipeDriveError() {return this._CoursePipeDriveError}
  clearResultCoursePipeDriveError() {this._CoursePipeDriveError.next(null)}

  protected _IncompanyPipeDrive = new BehaviorSubject<any>(null);
  getResultIncompanyPipeDrive() {return this._IncompanyPipeDrive}
  clearResultIncompanyPipeDrive() {this._IncompanyPipeDrive.next(null)}
  protected _IncompanyPipeDriveError = new BehaviorSubject<any>(null);
  getResultIncompanyPipeDriveError() {return this._IncompanyPipeDriveError}
  clearResultIncompanyPipeDriveError() {this._IncompanyPipeDriveError.next(null)}

  constructor(private http: HttpClient, private SubjectManagerService: SubjectManagerService) {
    super();
  }

  getMasterPD(){
    this.http.get(this.urlApi + '/api/pipedrive/deals/master' , this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormation =  iStaticUtilities.normalizeNamesCollection(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedFormation});
        this.sendNextObservable(this._MasterPipeDrive, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._MasterPipeDriveError, this.res,true, { method: this.getMasterPD, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  getCoursePD(){
    this.http.get(this.urlApi + '/api/pipedrive/deals/course' , this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormation =  iStaticUtilities.normalizeNamesCollection(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedFormation});
        this.sendNextObservable(this._CoursePipeDrive, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._CoursePipeDriveError, this.res,true, { method: this.getCoursePD, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  getIncompanyPD(){
    this.http.get(this.urlApi + '/api/pipedrive/deals/incompany' , this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormation =  iStaticUtilities.normalizeNamesCollection(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedFormation});
        this.sendNextObservable(this._IncompanyPipeDrive, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._IncompanyPipeDriveError, this.res,true, { method: this.getIncompanyPD, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  lossCRMCard(card){
    this.http.put(this.urlApi+"/api/pipedrive/lost/deal/"+card.id, card, this.getHeader()).subscribe({
      next: (data:any) => {
         
        this.res = new iResultHttp({ status: 200, ok: true, message: this.res.message, data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.lossCRMCard, args: [card] });
        this.checkStatusError(error);
      },
    });
  }

  lossBrainCard(deal){
    this.http.put(this.urlApi+"/api/lost/"+deal.id, deal, this.getHeader()).subscribe({
      next: (data:any) => {
         
        this.res = new iResultHttp({ status: 200, ok: true, message: this.res.message, data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.lossBrainCard, args: [deal] });
        this.checkStatusError(error);
      },
    });
  }


}
