import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PopupService } from '@quasar-dynamics/basic-designsystem';
import {
  StaticUtilitiesService,
  iUnsubscribeDestroy,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AddDobleTitulacionPopupComponent } from 'src/app/Popups/AddPopup/AddDobleTitulacion-Popup/AddDobleTitulacion-Popup.component';
import { headerData } from 'src/app/Shared/Components/main-table/main-table.component';

@Component({
  selector: 'app-formaciones-new-version-doble-titulacion-table',
  templateUrl: './FormacionesNewVersion-DobleTitulacion-Table.html',
  styleUrls: ['./FormacionesNewVersion-DobleTitulacion-Table.component.scss'],
})
export class FormacionesNewVersionDobleTitulacionTableComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  private _total: number = 0;

  @Input() allDobleTitulacion: any = [];
  @Input() totalDobleTitulacion: number = 0;
  @Input() areReadyAllDobleTitulacion: boolean = false;
  @Input() filterAllDobleTitulacion: any = null;
  @Input() paginationOptions: any = null;
  @Input() get total() {
    return this._total;
  }
  set total(value: number) {
    this._total = value;
  }
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  tableHead: headerData = [
    {
      variant: 'bold',
      display: 'Nombre',
      key: 'name',
      alignment: 'start',
    },
    {
      variant: 'standard',
      display: 'Másteres incluidos',
      key: 'formations',
      alignment: 'start',
    },
    {
      variant: 'standard',
      display: 'Precio general ',
      key: 'price',
      alignment: 'start',
      euros: true,
    },
    {
      variant: 'chip',
      display: 'Estado',
      key: 'activeLabel',
      classNameGenerator: (data: any) => {
        if (!data) return '--redState';
        return data.toLowerCase() === 'activa' ? '--greenState' : '--redState';
      },
    },
  ];

  constructor(
    private staticUtilitiesSE: StaticUtilitiesService,
    private popupSE: PopupService
  ) {
    super();
  }

  ngOnInit() {}

  goToDetailView(id: number) {
    this.staticUtilitiesSE.goTo(
      'formaciones/formaciones-detalle/',
      slideNavigationTypes.slideToTop,
      { formation: id }
    );
  }

  openNuevaTitulacion(dobleTitulacion: any) {
    this.staticUtilitiesSE.goTo(
      '/formaciones/doble-matricula-detalle',
      slideNavigationTypes.slideToLeft,
      { doubleMaster: dobleTitulacion.id }
    );
  }

  pageChangeEmmiter(event) {
    this.pageChange.emit(event);
  }
}
