<div class="headerGeneralContainer">
  <div class="breadcrumsContainer">
    <div class="circle"></div>
    <mat-icon class="separator">
      chevron_right
    </mat-icon>
    <ng-container *ngFor="let breadCrum of breadCrums">
      <span [routerLink]="[breadCrum.link]" *ngIf="!breadCrum.bold" routerAnimationRight>
        {{breadCrum.name}}
      </span>
      <span [routerLink]="[breadCrum.link]" [class.bold]="breadCrum.bold" [queryParams]="queryParams"
        *ngIf="breadCrum.bold" routerAnimationLeft>
        {{breadCrum.name}}
      </span>
      <mat-icon class="separator">
        chevron_right
      </mat-icon>
    </ng-container>
  </div>
  <!-- <div class="notificationsContainer">
    <span class="mensaje">{{message}}</span>
    <span class="notificaciones">
      <img src="/assets/Icons/Header/notificaciones.svg" alt="">
    </span>
  </div> -->

</div>
