<div class="fullContainer">
  <div class="container">
    <div class="blueBox">
      <div class="lineaImportante">
        <div class="lineaInterior">
          <p class="fwBold">Complemento de experiencia laboral</p>
          <p class="fwBold">{{ hours?.specialistRate }} €/h</p>
        </div>
      </div>

      <div class="lineaImportante">
        <div class="lineaInterior">
          <p class="fwBold">Complemento de experiencia IEM</p>

          <p class="fwBold">{{ hours?.iemSeniority }} €/h</p>
        </div>
      </div>

      <div class="lineaImportante">
        <div class="lineaInterior">
          <p class="fwBold">Plus de escuela</p>

          <span class="infoSpecial sinHour">
            <CustomInput
              [type]="'number'"
              [readOnly]="!checkRolesAndPermissions()"
              (change)="updateValue(hours.plusEscuela, 'plusEscuela')"
              [placeHolder]="'Introduzca el valor'"
              [(ngModel)]="hours.plusEscuela"
            >
            </CustomInput>
            <p class="fwBold">€/h</p>
          </span>
        </div>
      </div>

      <!--
      <div class="">
        <p>Complemento de antigüedad en IEM</p>
        <p class="fwBold fsBig">{{hours?.iemSeniority}} €/Hora</p>
      </div>
      <div class="line">
        <p>Complemento máster inglés</p>
        <p class="fwBold fsBig">{{hours?.englishMasterRate}} €/Hora</p>
      </div>
      <div class="line">
        <p>Precio de tribunal de proyectos máster</p>
        <span class="inputPlusHour">
          <CustomInput [type]="'number'" [placeHolder]="'Intorduzca el valor'"></CustomInput>
          <p class="fwBold fsBig"> €/Hora</p>
        </span>
      </div>
      <div class="line">
        <p>Precio horas de tutorias</p>
        <span class="inputPlusHour">
          <CustomInput [type]="'number'" [placeHolder]="'Intorduzca el valor'"></CustomInput>
          <p class="fwBold fsBig"> €/Hora</p>
        </span>
      </div>
      <div class="line">
        <p>Participación en máster de Marketing</p>
        <span class="inputPlusHour">
          <CustomInput [type]="'number'" [placeHolder]="'Intorduzca el valor'" [(ngModel)]="hours.marketingMasterRate"></CustomInput>
          <p class="fwBold fsBig"> €/Hora</p>
        </span>
      </div> -->
    </div>
  </div>
</div>
