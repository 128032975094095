import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'GestionUsuarios-Profesores-Detalles-Calendario',
  templateUrl:
    './GestionUsuarios-Profesores-Detalles-Calendario.component.html',
  styleUrls: [
    './GestionUsuarios-Profesores-Detalles-Calendario.component.scss',
  ],
})
export class GestionUsuariosProfesoresDetallesCalendarioComponent
  implements OnInit
{
  private _dataTeacher: any;

  @Input() get dataTeacher() {
    return this._dataTeacher;
  }
  set dataTeacher(value) {
    if (value && value.id) this._dataTeacher = value;
  }

  constructor() {}

  ngOnInit() {}
}
