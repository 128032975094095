import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iResultHttp } from '@quasar-dynamics/basic-designsystem';
import {
  DefaultBaseService,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageHandlerService } from '../Utils/LocalStorageHandler.service';

@Injectable({
  providedIn: 'root',
})
export class LandmarkService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  private processLandmarkTypes(
    landmarkTypes: any,
    behaviorSubject: BehaviorSubject<any>
  ) {
    const normalizedLandmarkTypes =
      iStaticUtilities.normalizeNamesCollection(landmarkTypes);
    this.res = new iResultHttp({
      status: 200,
      ok: true,
      message: '',
      data: normalizedLandmarkTypes,
    });
    this.sendNextObservable(behaviorSubject, this.res);
  }

  getAll(behaviorSubject: BehaviorSubject<any>, params: any) {
    this.http
      .get(
        this.urlApi + '/api/landmark' + this.optionsGet(params),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAll,
            args: [behaviorSubject, params],
          });
        },
      });
  }

  getSingle(behaviorSubject: BehaviorSubject<any>, landmarkId: number) {
    this.http
      .get(
        this.urlApi + '/api/landmark/' + landmarkId,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingle,
            args: [behaviorSubject, landmarkId],
          });
        },
      });
  }

  getLandmarkTypesForAcademicRegister(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(
        this.urlApi + '/api/landmark/type/selector/academic',
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getLandmarkTypesForAcademicRegister,
            args: [behaviorSubject],
          });
        },
      });
  }

  getLandmarkStateSelector(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(
        this.urlApi + '/api/landmark/state/selector',
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getLandmarkStateSelector,
            args: [behaviorSubject],
          });
        },
      });
  }

  public getSelectorTypes(behaviorSubject: BehaviorSubject<any>, params?: any) {
    this.http
      .get(this.urlApi + '/api/landmark/type/selector' + this.optionsGet(params), this.getHeader())
      .subscribe({
        next: (data: any) => {
          LocalStorageHandlerService.setItem('landmarkTypes', data);
          this.processLandmarkTypes(data, behaviorSubject);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSelectorTypes,
            args: [behaviorSubject,params],
          });
        },
      });
  }

  create(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(this.urlApi + '/api/landmark', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  update(behaviorSubject: BehaviorSubject<any>, data: any){
    this.http
      .put(
        `${this.urlApi}/api/landmark/${data.id}`,
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data.data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.update,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  delete(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .delete(
        `${this.urlApi}/api/landmark/${id}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data.data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.delete,
            args: [behaviorSubject, id],
          });
        },
      });
  }

}
