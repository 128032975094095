import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-PersonalizarColumnas-Popup',
  templateUrl: './PersonalizarColumnas-Popup.component.html',
  styleUrls: ['./PersonalizarColumnas-Popup.component.scss']
})
export class PersonalizarColumnasPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PersonalizarColumnasPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit() {
  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
}
