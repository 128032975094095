import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iUser } from "./iUser";
import { iPricePerHour } from "./iPricePerHour";

export interface iTeacher {
    id: number,
    specialty?: string,
    incorporationDate?: iDate,
    typeOf?: string,
    terminationDate?: iDate,
    user?: iUser | any,
    pricePerHour?: iPricePerHour | any,
}

export class iTeacher extends iBaseObject {
    static convertFromCollection(ob: any): Array<iTeacher> {
        let iTeacherCollection: Array<iTeacher> = [];
        ob.forEach((element: any) => {
            iTeacherCollection.push(this.convertFromObject(element));
        });
        return iTeacherCollection;
    }

    static convertFromObject(ob: any): iTeacher {
        let iTeacherObj = new iTeacher();
        iTeacherObj.id = ob.id;
        iTeacherObj.specialty = ob.specialty;
        iTeacherObj.incorporationDate = iDate.phpConvert(ob.incorporationDate);
        iTeacherObj.typeOf = ob.typeOf;
        iTeacherObj.terminationDate = iDate.phpConvert(ob.terminationDate);
        iTeacherObj.user = ob.user ? iUser.convertFromObject(ob.user) : {};
        iTeacherObj.pricePerHour = ob.pricePerHour ? iPricePerHour.convertFromObject(ob.pricePerHour) : {};
        return iTeacherObj;
    }

    static createEmptyObject(): iTeacher {
        let iTeacherObj = new iTeacher();
        iTeacherObj.specialty = '';
        iTeacherObj.incorporationDate = iDate.javascriptConvert(new Date());
        iTeacherObj.typeOf = '';
        iTeacherObj.terminationDate = iDate.javascriptConvert(new Date());
        iTeacherObj.user = {};
        iTeacherObj.pricePerHour = {};
        return iTeacherObj;
    }
}

export interface iTeacherFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    specialty?: string,
    incorporationDate?: string,
    typeOf?: string,
    terminationDate?: string,
    user?: number,
    pricePerHour?: number,
}