import {
  Component,
  OnInit,
} from '@angular/core';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { Subject, filter, takeUntil } from 'rxjs';
import { PublicHolidaysService } from 'src/app/Services/Api/PublicHolidays.service';
import { TestComponentClass } from 'src/app/Shared/Classes/TestComponentClass';
import { IEMLoaderComponent } from 'src/app/Shared/Components/IEMLoader/IEMLoader.component';
import { iDateChangeCalendar } from 'src/app/Shared/Interfaces/Utils/full-calendar/iDateChangeCalendar';
import { LoginComponent } from '../Login/Login/Login.component';
import { AutomatriculaViewComponent } from '../Automatricula/AutomatriculaView/AutomatriculaView.component';
import { iOptionsSelector } from '@quasar-dynamics/basic-designsystem';
import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iOptionMenu } from 'src/app/Shared/Components/options-menu/options-menu.component';
import { PopupService } from '@quasar_dynamics/basic-designsystem';
import { ChooseEventTypePopupComponent } from 'src/app/Popups/ChooseEventType-Popup/ChooseEventType-Popup.component';
import { LocalStorageHandlerService } from 'src/app/Services/Utils/LocalStorageHandler.service';
import { EventCalendar } from "src/app/Shared/Classes/EventCalendar";
import { EventWindowService } from 'src/app/Services/Utils/EventWindow.service';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexLegend, ApexPlotOptions, ApexXAxis } from 'ng-apexcharts';
import moment from 'moment';

  export interface iChartOptions {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    legend: ApexLegend;
    xaxis: ApexXAxis;
    plotOptions: ApexPlotOptions;
  };

@Component({
  selector: 'app-Test',
  templateUrl: './Test.component.html',
  styleUrls: ['./Test.component.scss'],
})
export class TestComponent implements OnInit {
  //? Need to take to calendar
  canEdit: boolean = false;
  isNewEventReadyToFill: boolean = false;
  SubjectEventWindow: Subject<any> | null = null;

  //?

  testModel: string = 'testModel';

  SubjectArray: Subject<any>[] = [];

  optionsMenu: iOptionMenu[] = [
    {
      action: 'click',
      label: 'Options',
      icon: 'more_vert',
    },
  ];

  smallInputoptions: iOptionsCustomInput = {
    placeholder: 'Select a date',
    type: 'text',
  };

  options: iOptionsSelector = {
    items: ['name', 'patatas'],
    placeholder: 'Select a date',
    append: true,
    clearable: false,
    readonly: this.canEdit,
  };

  ciOptions: iOptionsCustomInput = {
    placeholder: 'Select a date',
    type: 'text',
    readOnly: this.canEdit,
  };



  chipOptions: { name: string; color: string }[] = [
    {
      name: 'Ramón',
      color: '--greenState',
    },
    {
      name: 'Paco',
      color: '--redState',
    },
    {
      name: 'Juan',
      color: '--turquoiseState',
    },
  ];

  chartOptions:iChartOptions = {
    series: [
      {
        name: "Bob",
        data: [
          {
            x: "Design",
            y: [
              new Date("2019-03-05").getTime(),
              new Date("2019-03-08").getTime()
            ]
          },
          {
            x: "Code",
            y: [
              new Date("2019-03-08").getTime(),
              new Date("2019-03-11").getTime()
            ]
          },
          {
            x: "Test",
            y: [
              new Date("2019-03-11").getTime(),
              new Date("2019-03-16").getTime()
            ]
          }
        ]
      },
      {
        name: "Joe",
        data: [
          {
            x: "Design",
            y: [
              new Date("2019-03-02").getTime(),
              new Date("2019-06-05").getTime()
            ]
          },
          {
            x: "Code",
            y: [
              new Date("2019-03-06").getTime(),
              new Date("2019-03-09").getTime()
            ]
          },
          {
            x: "Test",
            y: [
              new Date("2019-03-10").getTime(),
              new Date("2019-03-19").getTime()
            ]
          }
        ]
      }
    ],
    chart: {
      height: 350,
      type: "rangeBar"
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (val,options) => this.returnMarkerName(val,options)
    },
    xaxis: {
      type: "datetime",
      tickAmount: 1,
      labels: {
        formatter: function(val) {
          return moment(new Date(val)).format("MMMM")
        }
      },
    },
    legend: {
      position: "top"
    }
  };

  extraClass: TestComponentClass = new TestComponentClass();


  fases:any[] = [
    {
      nombre:'Metodologias ágiles',
      color:'#dedede',
      tareas:[{
        start: '2020-04-20',
        end: '2021-09-25',
        comprimida: false,
        nombre:'Metodologias ágiles',
      }]
    },
    {
      nombre:'Máster',
      color:'#fff2cc',
      tareas:[{
        start: '2020-04-20',
        end: '2024-09-25',
        comprimida: false,
        nombre:'Metodologias ágiles',
      }]
    }
  ]

  returnMarkerName(val,options):string{
    return this.chartOptions.series[options.seriesIndex].name ?? '';
  }

  constructor(
    private publicHolidaysSE: PublicHolidaysService,
    private generalLoaderSE: GeneralLoaderService,
    private popupSE: PopupService,
    private eventWindowSE: EventWindowService
  ) {}

  ngOnInit() {
    this.getEventWindowBS();
    this.getAllPublicHolidays(new Date().getFullYear());
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    if (this.SubjectEventWindow) {
      this.SubjectEventWindow.next('');
      this.SubjectEventWindow.complete();
    }
  }

  /**
   * HANDLERS
   */

  successGetAllPublicHolidaysHandler(res: iResultHttp) {
    let { data } = res;
    this.mapPublicHolidays(data);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */

  getEventWindowBS() {
    this.SubjectEventWindow = StaticUtilitiesService.createSubject();
    this.eventWindowSE
      .getIsOpen()
      .pipe(takeUntil(this.SubjectEventWindow))
      .subscribe((res) => {
        this.isNewEventReadyToFill = res;
      });
  }

  onDateChange(event: iDateChangeCalendar) {
    this.callPublickHolidaysIfNeeded(event);
    switch (event.option) {
      case 'Year':
        this.onDateChangeYear(event);
        break;
      case 'Month':
        this.onDateChangeMonth(event);

        break;
      case 'Week':
        break;
      case 'Day':
        break;
      case 'List':
        break;

      default:
        break;
    }
  }

  onEventClick(event) {
  }

  onDateClick(event) {
    const subject: Subject<any> = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ChooseEventTypePopupComponent);
    this.popupSE
      .returnData()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        //Creamos un nuevo evento dependiendo del tipo devuelto por el popup
        //* TODO: Cambiar el 5 por res.returnValue cuando ya tengamos los tipos de eventos
        let newEvent = new EventCalendar().createEventCalendarObject(
          5,
          event.dateStr,
          ''
        );
        // Lo guardamos en el local storage
        LocalStorageHandlerService.setItem('newEvent', newEvent);
        // Abrimos la ventana de eventos
        this.eventWindowSE.open();
        subject.next('');
        subject.complete();
      });
  }

  onDateChangeYear(event) {}

  onDateChangeMonth(event) {}

  callPublickHolidaysIfNeeded(event: iDateChangeCalendar) {
    if (event.year !== this.extraClass.currentYear) {
      this.extraClass.currentYear = event.year;
      this.getAllPublicHolidays(event.year);
    }
  }

  a($event) {
  }

  mapPublicHolidays(data) {
    let events: any = [];

    data.forEach((holiday) => {
      // Si la fiesta es nacional o de la comunidad valenciana se añade al calendario
      if (holiday.counties === null || holiday?.counties?.includes('ES-VC')) {
        events.push({
          title: holiday.localName,
          date: holiday.date,
          backgroundColor: '#000000',
          textColor: '#FFF',
          allDay: true,
          className: 'public-holiday',
          holiday: true,
          display: 'background',
          // rendering: 'background',
        });
      }
    });
    this.extraClass.events = events;
  }

  editToggle() {
    this.canEdit = !this.canEdit;
    this.options.readonly = this.canEdit;
    this.ciOptions.readOnly = this.canEdit;
  }

  /**
   * API CALLS
   */

  getAllPublicHolidays(year) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.publicHolidaysSE.getPublicHolidaysForAll(behaviorSubject, { year });
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllPublicHolidaysHandler(res),
        });
      });
  }
}
