interface iBaseEventCalendar {
  id: string | number | null;
  type: number;
  title: string;
  className: string;
  startDate: string;
}

export interface iMasterClass extends iBaseEventCalendar {
  type: 2;
  subtitle: string | null;
  startHour: string | null;
  endHour: string | null;
  formation: number | null;
  edition: number | null;
  teachers: any[] | null;
  classroom: number | null;
  status: number | string | null;
  observations: string;
  url?: string | null;
}

export interface iMasterTutoriaStudent extends iBaseEventCalendar {
  type: 16;
  subtitle: string | null;
  startHour: string | null;
  endHour: string | null;
  formation: number | null;
  edition: number | null;
  teachers: any[] | null;
  student: number;
  modality: number;
  status: number | string | null;
  observations: string;
  classroom?: number | null;
  url?: string | null;
}

export interface iMasterTribunal extends iBaseEventCalendar {
  type: 4;
  subtitle: string | null;
  startHour: string | null;
  endHour: string | null;
  teachers: any[] | null | null;
  asistant: any;
  classroom: number;
  observations: string;
  modality: number;
  allDay: boolean;

}

export interface iMasterOtros extends iBaseEventCalendar {
  type: 5;
  subtitle: string | null;
  startHour: string | null;
  endHour: string | null;
  formation: number | null;
  edition: number | null;
  teachers: any[] | null;
  classroom: number | null;
  observations: string;
  location: string;
}

export interface iCursosClass extends iBaseEventCalendar {
  type: 12;
  subtitle: string | null;
  startHour: string | null;
  endHour: string | null;
  formation: number | null;
  edition: number | null;
  teachers: any[] | null | null;
  classroom: number | null;
  status: number | string | null | null;
  observations: string;
  url?: string | null;
}

export interface iIncompanyClass extends iBaseEventCalendar {
  type: 14;
  subtitle: string | null;
  startHour: string | null;
  endHour: string | null;
  formation: number | null;
  edition: number | null;
  company: string | number;
  teachers: any[] | null;
  status: number | string | null;
  observations: string;
  sede?: number | null;
  classroom?: number | null;
  url?: string | null;
}

export interface iAlquiler extends iBaseEventCalendar {
  type: 8;
  subtitle: string | null;
  startHour: string | null;
  endHour: string | null;
  classroom: number | null;
  company: number | null;
  allDay: boolean;
  observations: string;
  endDate?: string | null;
}

export interface iEvento extends iBaseEventCalendar {
  type: 9;
  subtitle: string | null;
  startHour: string | null;
  endHour: string | null;
  classroom: number | null;
  observations: string;
  teachers: any[] | null;
  allDay: boolean;
  company: number | null;
  location: string;
  endDate?: string | null;
}

interface iFestivo extends iBaseEventCalendar {
  type: 10;
}

export interface iNoLectivo extends iBaseEventCalendar {
  type: 11;
  endDate: string | null;
  observations: string;
  allDay: boolean;
}

export interface iMasterTutoriaTeacher extends iBaseEventCalendar {
  type: 3;
  subtitle: string | null;
  startHour: string | null;
  endHour: string | null;
  teachers: any[] | null | null;
  status: number | string | null;
  observations: string;
  classroom?: number | null;
}

export interface iCursosOtros extends iBaseEventCalendar {
  type: 13;
  subtitle: string | null;
  startHour: string | null;
  endHour: string | null;
  formation: number | null;
  edition: number | null;
  teachers: any[] | null;
  classroom: number | null;
  observations: string;
  location: string;
}

export interface iIncompanyOtros extends iBaseEventCalendar {
  type: 15;
  subtitle: string | null;
  startHour: string | null;
  endHour: string | null;
  formation: number | null;
  edition: number | null;
  company: string | number | null;
  teachers: any[] | null;
  status: number | string | null;
  observations: string;
  location?: string | null;
  classroom?: number | null;
}

export interface iMasterTribunalEntregas extends iBaseEventCalendar {
  type: 18;
  subtitle: string | null;
  allDay:boolean |null ;
  startHour: string | null;
  endHour: string | null;
  formation: number | null;
  edition: number | null;
  observations: string;
}

export interface iMasterTribunalStudent extends iBaseEventCalendar{
  type: 17;
  subtitle: string | null;
  startHour: string | null;
  endHour: string | null;
  formation: number | null;
  edition: number | null;
  teachers: any[] | null;
  student: number;
  observations: string;
  classroom?: number | null;
  url?: string | null;

}

export enum eEventCalendarType {
  MasterClass = 2,
  MasterTutoriaStudent = 16,
  MasterTribunales = 4,
  MasterOtros = 5,
  CursosClases = 12,
  IncompanyClases = 14,
  Alquiler = 8,
  Evento = 9,
  Festivo = 10,
  NoLectivo = 11,
  MasterTutoriaTeacher = 3,
  CursosOtros = 13,
  IncompanyOtros = 15,
  MasterTribunalEntregas = 18,
  MasterTribunalStudent = 17,
}

export type iEventCalendar =
  | iMasterClass
  | iMasterTutoriaStudent
  | iMasterTribunal
  | iMasterOtros
  | iCursosClass
  | iIncompanyClass
  | iAlquiler
  | iEvento
  | iFestivo
  | iNoLectivo
  | iMasterTutoriaTeacher
  | iCursosOtros
  | iIncompanyOtros
  | iMasterTribunalEntregas;


