import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { GeneralLoaderService } from '@quasar-dynamics/basic-designsystem';
import {
  StaticUtilitiesService,
  iResultHttp,
  iUnsubscribeDestroy,
} from '@quasar-dynamics/basic-designsystem';
import { PopupService } from '@quasar_dynamics/basic-designsystem';
import { filter, Subject, takeUntil } from 'rxjs';
import { ChangeStatusEnrollmentPopupComponent } from 'src/app/Popups/ChangeStatusEnrollment-Popup/ChangeStatusEnrollment-Popup.component';
import { AutoenrollmentTokenService } from 'src/app/Services/Api/AutoenrollmentToken.service';
import { EnrollmentService } from 'src/app/Services/Api/Enrollment.service';
import { StudentService } from 'src/app/Services/Api/Student.service';
import { StudentPromotionService } from 'src/app/Services/Api/StudentPromotion.service';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { headerData } from 'src/app/Shared/Components/main-table/main-table.component';

@Component({
  selector: 'formacionesAlumno',
  templateUrl: './GestionUsuarios-Alumnos-Detalles-Formaciones.component.html',
  styleUrls: ['./GestionUsuarios-Alumnos-Detalles-Formaciones.component.scss'],
})
export class GestionUsuariosAlumnosDetallesFormacionesComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input() dataStudent: any = null;
  @Input() isDataLoaded: boolean = false;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  total: number = 0;
  formaciones: Array<any> =
    StaticDataHandlerService.getProductosAsociadosProfe();
  formacionesOpened: Array<number> = [];
  formations: any[] = [];

  IconsHandlerService = IconsHandlerService;

  headerData: headerData = [
    {
      variant: 'bold',
      display: 'Formación',
      key: 'formationName',
      alignment: 'start',
      className: 'truncateText max-width-250',
      width: '250px',
    },
    {
      variant: 'standard',
      display: 'Año',
      key: 'year',
      width: '100px',
      alignment: 'center',
      className: 'truncateText max-width-100',
    },
    {
      variant: 'standard',
      display: 'Convocatoria',
      key: 'month',
      width: '100px',
      className: 'truncateText max-width-100',
      alignment: 'center',
    },
    {
      variant: 'standard',
      display: 'Edición',
      key: 'edition',
      width: '150px',
      className: 'truncateText max-width-150',
      alignment: 'center',
    },
    {
      variant: 'standard',
      display: 'Modalidad',
      key: 'name',
      width: '150px',
      className: 'truncateText max-width-150',
      alignment: 'center',
    },
    {
      variant: 'chip',
      display: 'Matrícula',
      key: 'enrollmentState',
      width: '150px',
      alignment: 'center',
      classNameGenerator(any, index, row) {
        return row.enrollmentStateColor ?? '--redState';
      },
      border: true,
      className: 'fwBold',
    },
    {
      variant: 'chip',
      display: 'Estado TFM',
      key: 'projectState',
      width: '150px',
      alignment: 'center',
      classNameGenerator(any, index, row) {
        return row.projectStateColor ?? '--redState';
      },
      border: true,
    },
    {
      variant: 'standard',
      display: 'Nota expediente académico',
      key: 'finalQualification',
      width: '250px',

      className: 'truncateText max-width-150',
      alignment: 'center',
    },
    {
      variant: 'icons',
      display: '',
      key: '',
      icons: [
        {
          matIconName: 'recent_actors',
          action: (row: any) => {
            this.onEditClick(row);
          },
        },
        {
          image: this.IconsHandlerService.getSendBlueIcon(),
          action: (row: any) => {
            this.onSendEmailClick(row);
          },
        },
        {
          image: this.IconsHandlerService.getDownloadIcon(),
          action: (row: any) => {
            this.getEnnrollmentPDF(row.idEnrollment);
          },
        },
        {
          image: this.IconsHandlerService.getDeleteIcon(),
          action: (row: any) => {
            const isDobleTitulation = row.dobleTitulation;
            const objectToPass = {
              id: row.idStudentpromotion,
              enrollmentState: isDobleTitulation ? 5 : 4,
            };
            this.updateStateStudentPromotionAsDelete(objectToPass);
          },
        },
        {
          image: this.IconsHandlerService.getEditBlueIcon(),
          action: (row: any) => {
            this.openEditStatesPopup(row);
          },
        },
      ],
    },
  ];

  constructor(
    private autoenrollmentTokenSE: AutoenrollmentTokenService,
    private studentSE: StudentService,
    private enrollmentSE: EnrollmentService,
    private generalLoaderSE: GeneralLoaderService,
    private studentPromotionSE: StudentPromotionService,
    private popupSE: PopupService
  ) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataStudent']) {
      if (changes['dataStudent'].currentValue) {
        this.getFormationByStudent();
      }
    }
  }

  /**
   * HANDLERS
   */

  successGetEnnrollmentPDFHandler(res: iResultHttp) {
    const { data } = res;
    window.open(data.data, '_blank');
    StaticUtilitiesService.showFeedback(data.message);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateStateStudentPromotionHandlerAsDelete(res: iResultHttp) {
    const { data } = res;
    StaticUtilitiesService.showFeedback(
      String('Matriculación editada correctamente')
    );
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successSendEnnrollmentPDFHandler(res: iResultHttp) {
    const { data } = res;
    StaticUtilitiesService.showFeedback(
      'PDF de matrícula enviado correctamente'
    );
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateStudentPromotionHandler(res: iResultHttp) {
    const { data } = res;
    this.refresh.emit(true);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */
  controlOpeneds(id) {
    this.formacionesOpened.includes(id)
      ? this.formacionesOpened.splice(this.formacionesOpened.indexOf(id), 1)
      : this.formacionesOpened.push(id);
  }

  onEditClick(row) {
    let objectToPass: { enrollments: number[]; email: string } = {
      enrollments: [row.idEnrollment],
      email: row.email,
    };
    this.getAutoenrollmentLink(objectToPass);
  }

  onSendEmailClick(row) {
    const objectToPass: { enrollmentId: number; email: string } = {
      email: row.email,
      enrollmentId: row.idEnrollment,
    };
    this.sendEnnrollmentPDF(objectToPass);
  }
  openEditStatesPopup(row: any) {
    const subject = new Subject();
    this.popupSE.openPopup(ChangeStatusEnrollmentPopupComponent, {
      data: {
        studentPromotionId: row?.idStudentpromotion,
        formationId: row?.idFormation,
        editionId: row?.idEdition,
      },
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res) => {
        if (res === undefined) {
          return;
        }
        if (res === false) {
          subject.next(true);
          return;
        }

        const returnValue = res?.returnValue;
        const objectToPass = {
          id: row.idStudentpromotion,
          ...returnValue,
        };
        this.updateStudentPromotion(objectToPass);
        subject.next(true);
      });
  }

  /**
   * API CALLS
   */

  getFormationByStudent() {
    this.generalLoaderSE.addToLoaderAmount();

    this.studentSE.getAllProductsPerStudent(this.dataStudent.id);
    this.studentSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.formations = data;
        this.isDataLoaded = true;
        this.generalLoaderSE.removeFromLoaderAmount();
        this._unsubInd2.next('');
      });
    this.studentSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getURLAutoenrollment(id: number) {
    const studentEmail = this.dataStudent.user.email;
    const enrollmentId = id;

    const data = {
      enrollments: [enrollmentId],
      email: studentEmail,
    };

    this.autoenrollmentTokenSE.getAutoenrollmentToken(data);
    this.autoenrollmentTokenSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;
        window.open(data, '_blank');
        this._unsubInd.next('');
      });
  }

  getEnnrollmentPDF(enrollmentId: number) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.enrollmentSE.getEnnrollmentPDF(behaviorSubject, enrollmentId);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetEnnrollmentPDFHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateStateStudentPromotionAsDelete(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.studentPromotionSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successUpdateStateStudentPromotionHandlerAsDelete(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getAutoenrollmentLink(data) {
    this.autoenrollmentTokenSE.getAutoenrollmentToken(data);
    this.autoenrollmentTokenSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;
        window.open(data, '_blank');
        this._unsubInd.next('');
      });
    this.autoenrollmentTokenSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  sendEnnrollmentPDF(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.enrollmentSE.sendEnnrollmentPDF(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successSendEnnrollmentPDFHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateStudentPromotion(objectToPass: any) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.studentPromotionSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateStudentPromotionHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
