  
  <ng-container *ngIf="!options.readonly">
    <div class="horarioSelectors">
      <customInput [options]="options.input1Options" [ngModel]="ngModel1" (change)="getChange($event, 'ngModel1')"  ></customInput>
      <p>-</p>
      <customInput [options]="options.input2Options" [ngModel]="ngModel2" (change)="getChange($event, 'ngModel2')"  ></customInput>
    </div>
  </ng-container>
  
  <ng-container *ngIf="options.readonly">
    <span class="display" >{{ngModel1}} - {{ngModel2}}</span>
  </ng-container>
  
