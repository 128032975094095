import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { PopupService, iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { EditTutoringPopupComponent } from 'src/app/Popups/EditTutoring-Popup/EditTutoring-Popup.component';
import { EditTutoringreservationComponent } from 'src/app/Popups/EditTutoringreservation-Popup/EditTutoringreservation.component';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { TutoringService } from 'src/app/Services/Api/Tutoring.service';
import { AssureDeleteService } from 'src/app/Services/Utils/AssureDelete.service';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';
import { TutoriaByTeacherClass } from 'src/app/Shared/Classes/TutoriaByTeacherClass';
import { iTutoringSession } from 'src/app/Shared/Interfaces/Utils/iTutoringSession';

@Component({
  selector: 'Tutoria-By-Teacher',
  templateUrl: './Tutoria-By-Teacher.component.html',
  styleUrls: ['./Tutoria-By-Teacher.component.scss'],
})
export class TutoriaByTeacherComponent implements OnInit {
  extraClass: TutoriaByTeacherClass = new TutoriaByTeacherClass();

  constructor(
    private popupSE: PopupService,
    private generalLoaderSE: GeneralLoaderService,
    private tutoringSE: TutoringService,
    private teacherSE: TeacherService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private cdr: ChangeDetectorRef,
    private assureDeleteSE: AssureDeleteService
  ) {}

  ngOnInit() {
    this.getParams()
      .then((params) => (this.extraClass.params = params))
      .then(() => this.getTutoringsByTeacher())
      .catch((err) => {
        StaticUtilitiesService.showError(err);
        this.staticUtilitiesSE.goTo(
          '/formaciones',
          slideNavigationTypes.slideToLeft
        );
      });
    this.isDataLoadedHandler();
  }

  onRowClick(row) {
    this.openReservaTutoringPopup(row);
  }

  /**
   * HANDLERS
   */

  successEditTutoringHandler(res: iResultHttp) {
    const { data } = res;
    this.getTutoringsByTeacher();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteTutoringHandler(res: iResultHttp) {
    const { data } = res;
    this.getTutoringsByTeacher();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTutoringsByTeacherHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.tutorings = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateTutoringStudentHandler(res: iResultHttp) {
    const { data } = res;
    this.getTutoringsByTeacher();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successResetTutoringStudentHandler(res: iResultHttp) {
    const { data } = res;
    this.getTutoringsByTeacher();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteTutoringStudentHandler(res: iResultHttp) {
    const { data } = res;
    this.getTutoringsByTeacher();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */

  openEditPopup(tutoringId) {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(EditTutoringPopupComponent, {
      data: {
        tutoringId: tutoringId,
        teacherId: this.extraClass.params.teacherId,
      },
    });
    this.popupSE
      .returnData()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        const returnValue = res.returnValue;
        if (returnValue) {
          this.editTutoring(returnValue);
        }
        subject.next(true);
      });
  }

  onDeleteClick(tutoringId: number) {
    this.deleteTutoring(tutoringId);
  }

  ifCannotDeleteBecauseTutoringInUse(id) {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      accion: 'Eliminar',
      elemento: 'la tutoría aunque tenga alumnos asignados',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res) => {
        const returnValue = res?.returnValue;
        if (returnValue) this.deleteTutoring(id, true);
        subject.next('');
        subject.complete();
      });
  }

  getParams(): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = this.staticUtilitiesSE.getParams();
      if (!params || !params.teacherId) {
        reject('No tienes permisos para ver esta página.');
      } else {
        resolve(params);
      }
      return;
    });
  }

  openReservaTutoringPopup(row: iTutoringSession['students'][0]) {
    const subject = StaticUtilitiesService.createSubject();
    const { hours, date, tutoringStudentId } = row;
    this.popupSE.openPopup(EditTutoringreservationComponent, {
      data: {
        hours,
        date,
        tutoringStudentId,
        teacherName: this.extraClass.params.teacherName,
      },
    });
    this.popupSE
      .returnData()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        const returnValue = res.returnValue;
        if (returnValue) {
          this.updateTutoringStudent(returnValue);
        }
        subject.next(true);
      });
  }

  isDataLoadedHandler() {
    this.generalLoaderSE
      .getLoaderAmount()
      .pipe(takeUntil(this.extraClass.generalDataLoaderSubject))
      .subscribe((res) => {
        this.extraClass.isDataLoaded = res === 0;
        this.cdr.detectChanges();
      });
  }

  onDeleteTutoringStudentClick(tutoringStudentId) {
    this.assureDeleteSE.assureDelete('el slot de la tutoría', 'Eliminar', () =>
      this.deleteTutoringStudent(tutoringStudentId)
    );
  }

  /**
   * TRACK BY
   */
  tutoringTrackBy(index: number, item: any): number {
    return item.id;
  }

  /**
   * API CALLS
   */
  editTutoring(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successEditTutoringHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteTutoring(id: number, forceDelete: boolean = false) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.delete(behaviorSubject, id, forceDelete);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteTutoringHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
          {
            method: () => this.ifCannotDeleteBecauseTutoringInUse(id),
            error: true,
          },
        ]);
      });
  }

  getTutoringsByTeacher() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.teacherSE.getTutoringsByTeacherId(
      behaviorSubject,
      this.extraClass.params.teacherId
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetTutoringsByTeacherHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateTutoringStudent(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.updateTutoringStudent(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateTutoringStudentHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  resetTutoringStudent(id) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.resetTutoringStudent(behaviorSubject, id);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResetTutoringStudentHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteTutoringStudent(id: number) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.deleteTutoringStudent(behaviorSubject, id);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteTutoringStudentHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
