import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  GeneralLoaderService,
  iDate,
  iOptionsSelector,
  iResultHttp,
  iUnsubscribeDestroy,
  PopupService,
  slideNavigationTypes,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { CountryService } from 'src/app/Services/Api/Country.service';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { TopicService } from 'src/app/Services/Api/Topic.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

@Component({
  selector: 'app-GestionUsuarios-Profesores-Crear',
  templateUrl: './GestionUsuarios-Profesores-Crear.component.html',
  styleUrls: ['./GestionUsuarios-Profesores-Crear.component.scss'],
})
export class GestionUsuariosProfesoresCrearComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  optionSelected: number = 0;
  isActive: boolean = false;
  isLoading: boolean = false;
  paises: Array<any> = StaticDataHandlerService.getPaises();
  provincias: Array<any> = StaticDataHandlerService.getProvincias();
  isEdit: boolean = false;
  isDragging: boolean = false;
  isDraggingImage: boolean = false;
  file: any = null;
  image: any = null;
  isWorking: boolean = false;
  isSameAddress: boolean = false;
  teacherId: number = 0;
  editDocuments: any = [];
  sameAddress: boolean = false;
  isCompany: boolean = false;
  secondAddress: boolean = false;

  // Tipos
  DNI: any[] = [];
  CV: any[] = [];
  Acuerdo: any[] = [];
  Otros: any[] = [];
  types: any = [
    { value: this.DNI, name: 'DNI' },
    { value: this.CV, name: 'CV' },
    { value: this.Acuerdo, name: 'Acuerdo' },
    { value: this.Otros, name: 'Otros' },
  ];

  opcionesPaises: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Selecciona un país',
    bindLabel: 'nombre',
    bindValue: 'id',
    // append: true
  };
  opcionesNacionalidades: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Selecciona un país',
    bindLabel: 'ethnicity',
    bindValue: 'id',
    // append: true
  };
  opcionesPaisesDireccion: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Selecciona un país',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
  };
  opcionesIdiomas: iOptionsSelector = {
    items: StaticDataHandlerService.getLanguages(),
    clearable: true,
    search: true,
    placeholder: 'Selecciona los idiomas',
    append: true,
    multiple: {
      isMultiple: true,
      multipleTextReplacer: `Varios idiomas seleccionados`,
      maxSelectedItems: 3,
    },
  };
  opcionesSexo: iOptionsSelector = {
    items: ['Hombre', 'Mujer', 'Otro', 'Prefiero no decirlo'],
    clearable: true,
    search: true,
    placeholder: 'Selecciona un sexo',
    append: true,
  };
  opcionesLaboral: iOptionsSelector = {
    items: ['Asalariado', 'Autónomo'],
    clearable: true,
    search: true,
    placeholder: 'Situación laboral actual',
    append: true,
  };
  opcionesProvincias: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Selecciona una provincia',
    append: true,
  };

  opcionesTipo: iOptionsSelector = {
    items: ['Profesor', 'Posible profesor', 'Exprofesor', 'Descartado'],
    clearable: true,
    search: true,
    placeholder: 'Selecciona un tipo',
    append: true,
  };

  opcionesRetencion: iOptionsSelector = {
    items: ['Rendimientos del trabajo', 'Actividades económicas'],
    clearable: true,
    search: true,
    placeholder: 'Selecciona una opción',
    append: true,
  };

  dataToPass: any = {
    id: 0,
    user: {},
  };
  tematicasSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleTextReplacer: 'Varias temáticas seleccionadas',
    },
    bindLabel: 'name',
    bindValue: 'id',
    clearable: false,
    label: 'Temáticas *',
    placeholder: 'Seleccione temáticas',
    search: true,
  };

  data: any = {
    user: {
      email: '',
      secondaryEmail: '',
      name: '',
      surname: '',
      dni: '',
      biography: '',
      phone: '',
      phonePrefix: '+34',
      photo: null,
      gender: null,
      active: false,
      nationality: {
        id: null,
        iso_code: null,
        name: null,
        nombre: null,
      },
      typeOf: '',
      dateOfBirth: new iDate(iDate.toPhp(new Date())).toStringDate('EU'),
      languages: [],
      socialMedia: {},
      address: {
        country: {
          id: null,
          iso_code: null,
          name: null,
          nombre: null,
        },
        province: null,
        municipality: '',
        street: '',
        number: '',
        door: '',
        postalCode: '',
      },
      address2: {
        country: {
          id: null,
          iso_code: null,
          name: null,
          nombre: null,
        },
        province: null,
        municipality: '',
        street: '',
        number: '',
        door: '',
        postalCode: '',
      },
      sameAddress: this.isSameAddress,
      bankData: {
        bank: '',
        iban: '',
        bic: '',
        owner: '',
        company: '',
        cif: '',
        fiscalAddress: {
          country: {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          },
          province: null,
          municipality: '',
          street: '',
          number: '',
          door: '',
          postalCode: '',
        },
      },
    },
    fee: {
      courseEuroPerHour: null,
      incompanyEuroPerHour: null,
    },
    incorporationDate: new iDate(iDate.toPhp(new Date())).toStringDate('EU'),
    workExperienceSince: new iDate(iDate.toPhp(new Date())).toStringDate('EU'),
    document: [],
    topics: [],
  };
  constructor(
    private staticUtilitiesSE: StaticUtilitiesService,
    private router: Router,
    private popupSE: PopupService,
    private teacherSE: TeacherService,
    private countrySE: CountryService,
    private generalLoaderSE: GeneralLoaderService,
    private topicSE: TopicService
  ) {
    super();
  }
  ngAfterViewInit(): void {
    (document.querySelector('.form') as HTMLElement).addEventListener(
      'scroll',
      (e) => {
        if (
          (
            document.querySelector('.contactoContainer') as HTMLElement
          )?.getBoundingClientRect().top < 0
        ) {
          this.optionSelected = 1;
        }
        if (
          (
            document.querySelector('.contactoContainer') as HTMLElement
          )?.getBoundingClientRect().top > 0
        ) {
          this.optionSelected = 0;
        }
        if (
          (
            document.querySelector('.datosFiscales') as HTMLElement
          )?.getBoundingClientRect().top < 0
        ) {
          this.optionSelected = 2;
        }
        if (
          (
            document.querySelector('.academicos') as HTMLElement
          )?.getBoundingClientRect().top < 0
        ) {
          this.optionSelected = 3;
        }
        if (
          (
            document.querySelector('.precios') as HTMLElement
          )?.getBoundingClientRect().top < 0
        ) {
          this.optionSelected = 4;
        }
        if (
          (
            document.querySelector('.contactoContainer') as HTMLElement
          )?.getBoundingClientRect().top < -3350
        ) {
          this.optionSelected = 5;
        }
      }
    );
  }
  ngOnInit() {
    let params = this.staticUtilitiesSE.getParams();
    if (params.isEdit) {
      this.isEdit = params.isEdit;
      this.teacherId = params.idProfesor;
      this.dataToPass.id = params.idProfesor;
      this.getProfesorById(params.idProfesor);
    }
    this.getCountriesSelector();
    this.opcionesProvincias.items = [
      ...StaticDataHandlerService.getProvincias(),
    ];
    this.getTopicsSelector();
  }

  /**
   * HANDLERS
   */

  successAddTopicHandler(res: iResultHttp) {
    const { data } = res;
    this.getTopicsSelector();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteTopicHandler(res: iResultHttp) {
    const { data } = res;
    this.getTopicsSelector();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTopicsSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.tematicasSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateTeacherTopicsHandler(res: iResultHttp) {
    const { data } = res;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */
  scroll(type: string) {
    switch (type) {
      case 'contacto':
        (document.querySelector('.form') as HTMLElement).scroll({
          top: 0,
          behavior: 'smooth',
        });
        break;
      case 'fiscal':
        (document.querySelector('.form') as HTMLElement).scroll({
          top:
            (document.querySelector('.form') as HTMLElement).scrollHeight / 3.6,
          behavior: 'smooth',
        });
        break;
      case 'academicos':
        (document.querySelector('.form') as HTMLElement).scroll({
          top:
            (document.querySelector('.form') as HTMLElement).scrollHeight /
            2.23,
          behavior: 'smooth',
        });
        break;
      case 'documentacion':
        (document.querySelector('.form') as HTMLElement).scroll({
          top:
            (document.querySelector('.form') as HTMLElement).scrollHeight /
            1.72,
          behavior: 'smooth',
        });
        break;
      case 'imagen':
        (document.querySelector('.form') as HTMLElement).scroll({
          top: (document.querySelector('.form') as HTMLElement).scrollHeight,
          behavior: 'smooth',
        });
        break;
    }
  }
  confirmPopup() {
    if (this.router.url.includes('crear-profesor')) {
      this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
        accion: 'Descartar',
        elemento: 'la creación del profesor',
      });
      this.popupSE
        .returnData()
        .pipe(takeUntil(this._unsubInd))
        .subscribe((data) => {
          this.staticUtilitiesSE.goTo(
            '/profesores/mis-profesores/listado-profesores',
            slideNavigationTypes.slideToBottom
          );
          this._unsubInd.next('');
        });
    } else {
      this.staticUtilitiesSE.goTo(
        '/profesores/mis-profesores/listado-profesores',
        slideNavigationTypes.slideToBottom
      );
    }
  }

  backToTeacher() {
    let teacherId = this.data.id;
    this.staticUtilitiesSE.goTo(
      '/profesores/mis-profesores/ficha-profesor',
      slideNavigationTypes.slideToBottom,
      { profesor: teacherId }
    );
  }
  crearProfesor() {
    this.isLoading = true;
    if (!this.secondAddress) {
      delete this.data.user.address2;
    }
    this.data.user.sameAddress = this.isSameAddress;
    let copyOfData = {
      ...this.data,
      document: [...this.DNI, ...this.CV, ...this.Acuerdo, ...this.Otros],
    };
    this.teacherSE.create(copyOfData);
    this.teacherSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Profesor creado correctamente');
        this.isLoading = false;
        this.staticUtilitiesSE.goTo(
          '/profesores/mis-profesores/listado-profesores',
          slideNavigationTypes.slideToBottom
        );
        this._unsubInd.next('');
      });
    this.teacherSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getPrefix($event, key) {
    if (key == 'telefono') {
      this.data.user.phonePrefix = $event.code;
      if (this.isEdit) {
        this.dataToPass.user.phonePrefix = $event.code;
        this.editProfesor(this.dataToPass);
      }
    }
    if (key == 'telefono2') {
      this.data.user.phoneTwoPrefix = $event.code;
      if (this.isEdit) {
        this.dataToPass.user.phoneTwoPrefix = $event.code;
        this.editProfesor(this.dataToPass);
      }
    }
  }

  removeDocumento(type, index) {
    this[type].splice(index, 1);
  }

  updateImage(event) {
    let noFormat = this.image.filter(
      (image) =>
        image.file.type !== 'image/jpeg' && image.file.type !== 'image/png'
    );

    let noSize = this.image.filter((image) => image.file.size > 5000000);
    if (noFormat.length > 0) {
      StaticUtilitiesService.showError('Formato de documento no permitido');
      this.image = null;
      return;
    }
    if (noSize.length > 0) {
      StaticUtilitiesService.showError('Archivo demasiado pesado');
      this.image = null;
      return;
    }
    let photo = this.image[0].base64 as string;
    this.data.user.photo = photo;
    this.isDragging = false;
  }
  updateFile(event, typeOf) {
    let noFormat = this.file.filter(
      (file) => file.file.type !== 'application/pdf'
    );
    let noSize = this.file.filter((file) => file.file.size > 5000000);
    if (noFormat.length > 0) {
      StaticUtilitiesService.showError('Formato de documento no permitido');
      this.file = null;
      return;
    }
    if (noSize.length > 0) {
      StaticUtilitiesService.showError('Archivo demasiado pesado');
      this.file = null;
      return;
    }
    this[typeOf].push(
      ...this.file.map((file) => {
        return { name: file.file.name, url: file.base64, typeOf: typeOf };
      })
    );
    this.isDragging = false;
  }

  dragEnter(key) {
    if (key == 'image') this.isDraggingImage = true;
    if (key == 'document') this.isDragging = true;
  }
  dragLeave(key) {
    if (key == 'image') this.isDraggingImage = false;
    if (key == 'document') this.isDragging = false;
  }
  idiomasSelector(event) {
    event.length == 0
      ? (this.data.user.languages = null)
      : (this.data.user.languages = event);
    this.isCompleted();
    if (this.isEdit) {
      this.dataToPass.user = {};
      this.dataToPass.user.languages = this.data.user.languages;
      this.editProfesor(this.dataToPass);
      delete this.dataToPass.user;
    }
  }
  employmentStatus(event) {
    if (event == 'Asalariado') {
      this.isWorking = true;
      if (this.isEdit) {
        this.dataToPass.user = {};
        this.dataToPass.user.employmentStatus = event;
        this.editProfesor(this.dataToPass);
        delete this.dataToPass.user;
      }
    } else {
      this.isWorking = false;
      if (this.isEdit) {
        this.dataToPass.user = {};
        this.dataToPass.user.employmentStatus = event;
        this.editProfesor(this.dataToPass);
        delete this.dataToPass.user;
      }
    }
  }

  checkBoxSelect(event) {
    this.isSameAddress = event.checked;
    this.sameAddress = event.checked;

    let emptyBankData: any = {
      bank: '',
      iban: '',
      bic: '',
      owner: '',
      company: '',
      cif: '',
      fiscalAddress: {
        country: {
          id: null,
          iso_code: null,
          name: null,
          nombre: null,
        },
        province: null,
        municipality: '',
        street: '',
        number: '',
        door: '',
        postalCode: '',
      },
    };
    if (event.checked) {
      this.duplicateAddress();
    } else {
      this.data.user.bankData = { ...emptyBankData };
      if (this.isEdit) {
        this.dataToPass.user.bankData = emptyBankData;
        this.editProfesor(this.dataToPass);
      }
    }
  }

  duplicateAddress() {
    for (const key in this.data.user.bankData.fiscalAddress) {
      this.data.user.bankData.fiscalAddress[key] = this.data.user.address[key];
    }
    this.data.user.bankData.fiscalAddress.country.nombre =
      this.opcionesPaisesDireccion.items.find(
        (pais) => pais.id == this.data.user.address.country.id
      )?.name;
    this.data.user.bankData.fiscalAddress.email = this.data.user.email;
    this.data.user.bankData.fiscalAddress.phone = this.data.user.phone;

    if (this.isEdit) {
      this.dataToPass.user = {};
      this.dataToPass.user.bankData = {
        bank: '',
        iban: '',
        bic: '',
        owner: '',
        fiscalAddress: {
          country: {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          },
          province: null,
          municipality: '',
          street: '',
          number: '',
          door: '',
          postalCode: '',
        },
      };
      for (const key in this.data.user.bankData.fiscalAddress) {
        this.dataToPass.user.bankData.fiscalAddress[key] =
          this.data.user.address[key];
      }
      this.dataToPass.user.bankData.fiscalAddress.email = this.data.user.email;
      this.dataToPass.user.bankData.fiscalAddress.phone = this.data.user.phone;
      this.editProfesor(this.dataToPass);
    }
  }

  hasSecondAddress(event) {
    this.secondAddress = event.checked;
    if (!this.data.user.address2) {
      this.data.user.address2 = {
        country: {
          id: null,
          iso_code: null,
          name: null,
          nombre: null,
        },
        province: null,
        municipality: '',
        street: '',
        number: '',
        door: '',
        postalCode: '',
      };
    }
  }

  isCompanySelect(event) {
    this.isCompany = event.checked;
    this.sameAddress = false;

    let emptyBankData: any = {
      bank: '',
      iban: '',
      bic: '',
      owner: '',
      company: '',
      cif: '',
      fiscalAddress: {
        country: {
          id: null,
          iso_code: null,
          name: null,
          nombre: null,
        },
        province: null,
        municipality: '',
        street: '',
        number: '',
        door: '',
        postalCode: '',
      },
    };

    // Limpio datos de dirección que hubiera metido con "marcar como misma dirección", en el usuario y en el objeto a enviar en edición
    this.data.user.bankData = { ...emptyBankData };
    if (this.isEdit) {
      this.dataToPass.user.bankData = emptyBankData;
      this.editProfesor(this.dataToPass);
    }
  }

  removeImage() {
    this.data.user.photo = null;
  }
  updateLine(event, key, type) {
    switch (type) {
      case 'none':
        this.isCompleted();
        this.dataToPass[key] = event;
        this.editProfesor(this.dataToPass);
        delete this.dataToPass[key];
        break;

      case 'user':
        this.dataToPass.user = {};
        this.isCompleted();
        this.dataToPass['user'][key] = event;
        this.editProfesor(this.dataToPass);
        delete this.dataToPass.user;
        break;

      case 'company':
        this.dataToPass.company = {};
        this.isCompleted();
        this.dataToPass['company'][key] = event;
        this.editProfesor(this.dataToPass);
        delete this.dataToPass.company;
        break;

      case 'address':
        this.dataToPass.user = {};
        this.dataToPass.user.address = {};
        this.isCompleted();
        if (key == 'country') {
          this.dataToPass.user.address.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
          this.dataToPass.user.address[key].id = event;
        } else {
          this.dataToPass.user.address[key] = event;
        }
        this.editProfesor(this.dataToPass);
        delete this.dataToPass.user;
        break;

      case 'address2':
        this.dataToPass.user = {};
        this.dataToPass.user.address2 = {};
        this.isCompleted();
        if (key == 'country') {
          this.dataToPass.user.address2.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
          this.dataToPass.user.address2[key].id = event;
        } else {
          this.dataToPass.user.address2[key] = event;
        }
        this.editProfesor(this.dataToPass);
        delete this.dataToPass.user;
        break;

      case 'bankData':
        this.dataToPass.user = {};
        this.dataToPass.user.bankData = {};
        this.isCompleted();
        this.dataToPass.user.bankData[key] = event;
        this.editProfesor(this.dataToPass);
        delete this.dataToPass.user;
        break;

      case 'fiscalAddress':
        this.dataToPass.user = {};
        this.dataToPass.user.bankData = {};
        this.dataToPass.user.bankData.fiscalAddress = {};
        this.isCompleted();
        if (key == 'country') {
          this.dataToPass.user.bankData.fiscalAddress.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
          this.dataToPass.user.bankData.fiscalAddress[key].id = event;
        } else {
          this.dataToPass.user.bankData.fiscalAddress[key] = event;
        }
        this.editProfesor(this.dataToPass);
        delete this.dataToPass.user;
        break;

      case 'fee':
        this.dataToPass.fee = {};
        this.isCompleted();
        this.dataToPass.fee[key] = event;
        this.editProfesor(this.dataToPass);
        delete this.dataToPass.fee;
        break;

      case 'socialMedia':
        this.dataToPass.user = {};
        this.dataToPass.user.socialMedia = {};
        this.isCompleted();
        this.dataToPass.user.socialMedia = { ...this.data.user.socialMedia };
        this.editProfesor(this.dataToPass);
        delete this.dataToPass.user;
        break;

      default:
        break;
    }
  }

  editTeacherTopics(topicsIds) {
    const objectToPass = {
      teacherId: this.teacherId,
      topicsIds: topicsIds,
    };
    this.updateTeacherTopics(objectToPass);
  }

  /**
   * GETTERS & SETTERS
   */

  getDate(event) {
    this.data.user.dateOfBirth = new iDate(iDate.toPhp(event)).toStringDate(
      'EU'
    );
    this.isCompleted();
    if (this.isEdit) {
      this.dataToPass.user = {};
      this.dataToPass.user.dateOfBirth = this.data.user.dateOfBirth;
      this.editProfesor(this.dataToPass);
      delete this.dataToPass.user;
    }
  }
  getDateIncorporacion(event) {
    this.data.incorporationDate = new iDate(iDate.toPhp(event)).toStringDate(
      'EU'
    );
    this.isCompleted();
    if (this.isEdit) {
      this.dataToPass.incorporationDate = this.data.incorporationDate;
      this.editProfesor(this.dataToPass);
      delete this.dataToPass.incorporationDate;
    }
  }
  getDateWorkExperience(event) {
    this.data.workExperienceSince = new iDate(iDate.toPhp(event)).toStringDate(
      'EU'
    );
    this.isCompleted();
    if (this.isEdit) {
      this.dataToPass.workExperienceSince = this.data.workExperienceSince;
      this.editProfesor(this.dataToPass);
      delete this.dataToPass.workExperienceSince;
    }
  }
  setter(data) {
    this.data = { ...data, topics: data.topics.map((topic) => topic.id) };
    if (
      !this.data?.user?.bankData ||
      !this.data.user?.bankData?.fiscalAddress
    ) {
      this.data.user.bankData = {
        bank: '',
        iban: '',
        bic: '',
        owner: '',
        cif: '',
        company: '',
        ownerDni: '',
        fiscalAddress: {
          country: {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          },
          province: null,
          municipality: '',
          street: '',
          number: '',
          door: '',
          postalCode: '',
        },
      };
    }
  }

  setAddresses(data) {
    this.data.user.address = {
      country: {
        id: null,
        iso_code: null,
        name: null,
        nombre: null,
      },
      province: null,
      municipality: '',
      street: '',
      number: '',
      door: '',
      postalCode: '',
    };
    data.user.addresses.forEach((address) => {
      if (address.name == 'Dirección personal') {
        data.user.address = address;
        if (!address.country) {
          address.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
        }
      }
      if (address.name == 'Dirección personal 02') {
        data.user.address2 = address;
        this.secondAddress = true;
        if (!address.country) {
          address.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
        }
      }
      if (address.name == 'Dirección fiscal') {
        data.user.bankData.fiscalAddress = address;
        if (!address.country) {
          address.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
        }
      }
    });
  }
  /**
   * VALIDATIONS
   */
  isCompleted() {
    if (
      !this.data.user.email ||
      (StaticUtilitiesService.isValidFormat(this.data.user.email) &&
        this.data.user.nationality.id === null)
    ) {
      this.isActive = false;
      return;
    }

    if (StaticUtilitiesService.isValidFormat(this.data.user.email)) {
      this.isActive = StaticUtilitiesService.isCompleteObject(this.data);
    } else {
      StaticUtilitiesService.showError('Formato de email incorrecto');
      this.isActive = false;
    }
  }

  /**
   * API CALLS
   */

  getCountriesSelector() {
    this.countrySE.getCountries();
    this.countrySE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.opcionesPaises.items = [...data];
        this.opcionesNacionalidades.items = [...data];
        this.opcionesPaisesDireccion.items = [...data];
        this._unsubInd.next('');
      });
  }

  getTopicsSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.topicSE.getSelector(behaviorSubject, { teacher: true });
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetTopicsSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getProfesorById(id) {
    this.teacherSE.getSingle(id);
    this.teacherSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.setter(data);
        this.setAddresses(data);
        this._unsubInd2.next('');
      });
    this.teacherSE
      .getResultIndividualError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  editProfesor(data) {
    this.teacherSE.update(data);
    this.teacherSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'Profesor actualizado correctamente'
        );
        this._unsubInd3.next('');
      });
    this.teacherSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  addTopic(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.topicSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successAddTopicHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteTopic(ids: number) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.topicSE.delete(behaviorSubject, ids);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteTopicHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
  updateTeacherTopics(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.teacherSE.updateTeacherTopics(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateTeacherTopicsHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
