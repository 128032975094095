import { Component, OnInit } from '@angular/core';
import { GeneralLoaderService, StaticUtilitiesService, iOptionsCustomInput, slideNavigationTypes } from '@quasar-dynamics/basic-designsystem';
import { iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { BookTutoringService } from 'src/app/Services/Api/BookTutoring.service';
import { LocalStorageHandlerService } from 'src/app/Services/Utils/LocalStorageHandler.service';

@Component({
  selector: 'app-Tutoria-Alumno',
  templateUrl: './Tutoria-Alumno.component.html',
  styleUrls: ['./Tutoria-Alumno.component.scss']
})
export class TutoriaAlumnoComponent implements OnInit {

  documentoDeIdentidadCustomInputOptions:iOptionsCustomInput = {
    placeholder: 'Documento de identidad',
    type: 'text',
    label: 'Introduce tu documento de identidad',
    classList: 'tutoria-alumno',
  }

  isError:boolean = false;

  nif:string = '';

  constructor(private bookTutoringSE:BookTutoringService, private generalLoaderSE:GeneralLoaderService, private staticUtilitiesSE:StaticUtilitiesService) { }

  ngOnInit() {
  }

  /**
   * HANDLERS
   */

  successSendNIFToCheckIfStudentInSystemHandler(res:iResultHttp){
    const { data } = res;
    this.isError = false;
    this.setTokenOnLocalStorageAndMoveForward(data)
    this.generalLoaderSE.removeFromLoaderAmount();
   }

    errorSendNIFToCheckIfStudentInSystemHandler(res:iResultHttp){
      this.isError = true;
      this.generalLoaderSE.removeFromLoaderAmount();
    }

   /**
    * FUNCTIONALITY
    */

   prepareNIFToCheck(nif){
    if(!nif )return;
    const objectToPass = {nif};
    this.sendNIFToCheckIfStudentInSystem(objectToPass);
   }

   setTokenOnLocalStorageAndMoveForward(token){
    LocalStorageHandlerService.setItem('unt', token);
    this.staticUtilitiesSE.goTo('/tutoria-alumno/reserva', slideNavigationTypes.slideToLeft);
   }

  /**
   * API CALLS
   */

  sendNIFToCheckIfStudentInSystem(objectToPass) {
  this.generalLoaderSE.addToLoaderAmount();
  const subject = StaticUtilitiesService.createSubject();
  const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
  this.bookTutoringSE.sendNIFToCheckIfStudentInSystem(behaviorSubject, objectToPass);
  behaviorSubject
    .pipe(
      takeUntil(subject),
      filter((res) => res)
    )
    .subscribe((res: iResultHttp) => {
      StaticUtilitiesService.apiResponseHandler(res, subject, [
      {
        method: () => this.successSendNIFToCheckIfStudentInSystemHandler(res),
      },
      {
        method: () => this.errorSendNIFToCheckIfStudentInSystemHandler(res),
        error:true
      }
     ]);
    });
  }
  


}
