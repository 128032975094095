<div class="fullView">

  <div class="topLine" *ngIf="!isUserSelected">

    <div class="infoline">
      <mat-icon>info</mat-icon>
      <p>Añade nuevos usuarios y asígnale roles:</p>
    </div>

    <btn classList="genericbutton principalButton" (click)="addNewIEMUser()" >Añadir nuevo usuario</btn>

  </div>

  <!-- Body -->

  <div class="body">
    <Permisos-User-list [isUserListLoaded]="isUserListLoaded" [userList]="userList" [total]="total" [filtersUsers]="filtersUsers" (userSelected)="getUserSelected($event)" (refresh)="refresh($event)" *ngIf="!isUserSelected"></Permisos-User-list>
    <Permisos-Permisos [userSelected]="userSelected" [userPermisions]="userPermisions"  [permisionList]="permisionList" (changeView)="changeTheView($event)" *ngIf="isUserSelected"></Permisos-Permisos>
  </div>

</div>