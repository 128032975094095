import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {  PopupService, iResultHttp, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AsociarProductoProfesorPopupComponent } from 'src/app/Popups/AsociarProductoProfesor-Popup/AsociarProductoProfesor-Popup.component';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'productosProfesor',
  templateUrl: './GestionUsuarios-Profesores-Detalles-Productos.component.html',
  styleUrls: ['./GestionUsuarios-Profesores-Detalles-Productos.component.scss']
})
export class GestionUsuariosProfesoresDetallesProductosComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() dataTeacher: any = null;
  @Input() isDataLoaded: boolean = false;
  @Input() rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };



  productos: Array<any> = StaticDataHandlerService.getProductosAsociadosProfe();
  productsOpened: Array<number> = []
  constructor(private teacherSE:TeacherService, private popupSE:PopupService) {
    super();
  }

  ngOnInit() {
    setTimeout(() => {
      this.isDataLoaded = true;
    }, 1500);
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['dataTeacher']){
      if(changes['dataTeacher'].currentValue){
        this.getProductosAsociados();
      }
    }
  }

  /**
   * FUNCTIONALITIES
   */
  controlOpeneds(id){
    this.productsOpened.includes(id) ? this.productsOpened.splice(this.productsOpened.indexOf(id), 1) : this.productsOpened.push(id);
     
  }

  checkRolesAndPermissions(){
    return this.rolesAndPermissions.roles.includes('ROLE_ADMIN') || this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') || this.rolesAndPermissions.permissions.includes(2) 
  }

  openAsociarProductoPopup(){
    this.popupSE.openPopup(AsociarProductoProfesorPopupComponent, {teacherId: this.dataTeacher.id});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res=>{
      if(!res){return}
      this.getProductosAsociados();
      this._unsubInd2.next('')
    })
  }

  /**
   * API CALLS
   */

  getProductosAsociados(){
    this.teacherSE.getProductosAsociadosByTeacher(this.dataTeacher.id);
    this.teacherSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      let {data} = res;
       
      this.productos = data;
      this._unsubInd.next('')
    })
    this.teacherSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }
}
