<fullContainer>
  <ng-container>
    <header></header>
    <div class="formGeneralContainer">
      <div class="timeline">
        <div class="container">
          <p class="title"> {{action}} {{whatAmIAdding}}</p>
          <div class="options">
            <span [class.active]="optionSelected == 0" (click)="scroll('informacion')">
              <mat-icon>info</mat-icon>
              Información general
            </span>
            <span [class.active]="optionSelected == 1" (click)="scroll('descripcion')">
              <mat-icon>apartment</mat-icon>
              Datos de la empresa
            </span>
            <span [class.active]="optionSelected == 2" (click)="scroll('precio')">
              <mat-icon>school</mat-icon>
              Formación
            </span>
          </div>
        </div>
        <div class="footer">
          <btn classList="principalButton small fitWidth" *ngIf="!isEdit" (click)="confirmPopup()">
            <ng-container>
              Cancelar
            </ng-container>
          </btn>
          <btn classList="specialButton small fitWidth loading" *ngIf="!isEdit" (click)="crearFormacion()"
            [class.disabled]="!isActive">
            <ng-container *ngIf="!isLoading">
              Finalizar
            </ng-container>
            <ng-container *ngIf="isLoading">
              <loaderIcon class="white" size="small"></loaderIcon>
            </ng-container>
          </btn>
          <btn classList="specialButton small fitWidth loading" *ngIf="!isEdit" (click)="crearFormacion()"
            [class.disabled]="!isActive">
            <ng-container *ngIf="!isLoading">
              Enviar 
            </ng-container>
            <ng-container *ngIf="isLoading">
              <loaderIcon class="white" size="small"></loaderIcon>
            </ng-container>
          </btn>
        </div>

      </div>
      <div class="form">

        <div class="contactoContainer">
          <div class="header">
            <p class="title">
              <mat-icon>info</mat-icon>
              Información general
            </p>
          </div>
          <div class="body">
            <div class="doubleColumn">
              <div class="subColumn">
                <label for="">Estado</label>
                <selector [options]="opcionesEstado" [(ngModel)]="newData.state"
                  (change)="stateSelector(newData.state)"></selector>
              </div>
              <CustomInput [type]="'text'" class="longInput" [label]="'Nombre de la propuesta'"
                [placeHolder]="'Nombre de la propuesta'" [(ngModel)]="newData.name" name="nombre"
                (change)="(isEdit)? updateLine(newData.name, 'name', 'formation') : isCompleted()"></CustomInput>
            </div>

            <div class="doubleColumn">
              <CustomInput [type]="'text'" class="longInput" [readOnly]="true" [label]="'Empresa/Grupo de empresas'"
                [placeHolder]="'Empresa/Grupo de empresas'" [(ngModel)]="companyOrHoldingFromCrm"
                name="Empresa/Grupo de empresas"></CustomInput>
              <CustomInput [type]="'text'" class="longInput" [readOnly]="true" [label]="'Persona de contacto'"
                [placeHolder]="'Persona de contacto'" [(ngModel)]="contactPersonFromCrm" name="Persona de contacto">
              </CustomInput>
            </div>
            <div class="doubleColumn">
              <CustomInput [type]="'text'" class="longInput" [readOnly]="true" [label]="'Email de contacto'"
                [placeHolder]="'Email de contacto'" [(ngModel)]="emailFromCrm" name="Email de contacto">
              </CustomInput>
              <div class="inputSimulatorWithLabel">
                <p class="fakeLabel">Precio</p>
                <div class="inputSimulator">
                  <div class="input" [class.placeHolder]="newData.amount == 0 || newData.amount == null">
                    <ng-container *ngIf="newData.amount == 0 || newData.amount == null">
                      <label>Precio</label>
                    </ng-container>
                    <ng-container *ngIf="newData.amount != 0">{{newData.amount
                      | currency:'EUR'}}</ng-container>
                  </div>
                  <CustomInput [type]="'number'" [(ngModel)]="newData.amount" [placeHolder]="'Precio'"
                    (change)="(isEdit)? updateLine(newData.amount, 'amount', 'none') : isCompleted()" name="number">
                  </CustomInput>
                </div>
              </div>
            </div>


          </div>
        </div>
        <div class="datosFiscales">
          <div class="header">
            <p class="title">
              <mat-icon>apartment</mat-icon>
              Datos de la empresa
            </p>

          </div>
          <div class="body">
            <div class="subColumn">
              <label for="">Selecciona o crea una empresa</label>
              <selector [options]="opcionesEmpresa" [(ngModel)]="empresaSelected"
                (change)="companySelector(empresaSelected)"></selector>
            </div>
            <div class="doubleColumn">
              <CustomInput [type]="'text'" [readOnly]="isDataSelectedFomCompanies" [label]="'Razón social'" [(ngModel)]="newData.company.name"
                [placeHolder]="'Razón social'"
                (change)="(isEdit)? updateLine(newData.company.name, 'name', 'company') : isCompleted()"
                name="Razón social"></CustomInput>
              <CustomInput [type]="'text'" [readOnly]="isDataSelectedFomCompanies" [label]="'CIF/NIF'" [(ngModel)]="newData.company.cif"
                [placeHolder]="'CIF/NIF'"
                (change)="(isEdit)? updateLine(newData.company.cif, 'cif', 'company') : isCompleted()" name="CIF/NIF">
              </CustomInput>
            </div>

            <!-- Sucursal -->

            <div class="subColumn">
              <label for="">Selecciona o crea una sucursal</label>
              <selector [options]="opcionesAddress" [(ngModel)]="addressSelected"
                (change)="addressSelector(addressSelected)"></selector>
            </div>

            <div class="doubleColumn">
              <CustomInput [type]="'text'" [readOnly]="isDataSelectedFromAddress" [label]="'Nombre sucursal'" [(ngModel)]="newData.company.address.name"
                [placeHolder]="'Nombre sucursal'"
                (change)="(isEdit)? updateLine(newData.company.address.name, 'name', 'address') : isCompleted()"
                name="Nombre sucursal"></CustomInput>
              <CustomInput [type]="'text'" [readOnly]="isDataSelectedFromAddress" [label]="'Persona de contacto'"
                [(ngModel)]="newData.company.address.contactWith" [placeHolder]="'Persona de contacto'"
                (change)="(isEdit)? updateLine(newData.company.address.contactWith, 'contactWith', 'address') : isCompleted()"
                name="Persona de contacto"></CustomInput>
            </div>

            <div class="doubleColumn">
              <CustomInput [type]="'text'" [readOnly]="isDataSelectedFromAddress" [label]="'Email'" [(ngModel)]="newData.company.address.email"
                [placeHolder]="'Email'"
                (change)="(isEdit)? updateLine(newData.company.address.email, 'email', 'address') : isCompleted()"
                name="Email"></CustomInput>
              <div class="subColumn">
                <label for="">Teléfono</label>
                <div>
                  <p class="insteadOfCountrySelector" *ngIf="isDataSelectedFromAddress" [class.readOnly]="isDataSelectedFomHoldings">{{newData.company.address.phonePrefix}}</p>
                  <CountrySelector *ngIf="!isDataSelectedFromAddress" [country]="newData.company.address.phonePrefix" (emitCountry)="getPrefix($event, 'sucursal')"></CountrySelector>
                  <CustomInput [type]="'number'" [readOnly]="isDataSelectedFromAddress" [placeHolder]="'Teléfono'" [(ngModel)]="newData.company.address.phone"
                    name="telefono"
                    (change)="(isEdit)? updateLine(newData.company.address.phone, 'phone', 'address') : isCompleted()">
                  </CustomInput>
                </div>
              </div>
            </div>

            <div class="doubleColumn">
              <CustomInput [type]="'text'" [readOnly]="isDataSelectedFromAddress" [label]="'Calle'" [(ngModel)]="newData.company.address.street"
                [placeHolder]="'Calle'"
                (change)="(isEdit)? updateLine(newData.company.address.street, 'street', 'address') : isCompleted()"
                name="Calle"></CustomInput>

              <div class="doubleColumn">
                <CustomInput [type]="'text'" [readOnly]="isDataSelectedFromAddress" [label]="'Número'" [(ngModel)]="newData.company.address.number"
                  [placeHolder]="'Número'"
                  (change)="(isEdit)? updateLine(newData.company.address.number, 'number', 'address') : isCompleted()"
                  name="Número"></CustomInput>
                <CustomInput [type]="'text'" [readOnly]="isDataSelectedFromAddress" [label]="'Puerta'" [(ngModel)]="newData.company.address.door"
                  [placeHolder]="'Puerta'"
                  (change)="(isEdit)? updateLine(newData.company.address.door, 'door', 'address') : isCompleted()"
                  name="Puerta"></CustomInput>
              </div>
            </div>

            <div class="doubleColumn">
              <CustomInput [type]="'text'" [readOnly]="isDataSelectedFromAddress" [label]="'Ciudad'" [(ngModel)]="newData.company.address.municipality"
                [placeHolder]="'Ciudad'"
                (change)="(isEdit)? updateLine(newData.company.address.municipality, 'municipality', 'address') : isCompleted()"
                name="Ciudad"></CustomInput>
              <CustomInput [type]="'text'" [readOnly]="isDataSelectedFromAddress" [label]="'Código Postal'" [(ngModel)]="newData.company.address.postalCode"
                [placeHolder]="'Código Postal'"
                (change)="(isEdit)? updateLine(newData.company.address.postalCode, 'postalCode', 'address') : isCompleted()"
                name="Código Postal"></CustomInput>
            </div>

            <div class="doubleColumn">
              <CustomInput [type]="'text'" [readOnly]="isDataSelectedFromAddress" [label]="'Provincia'" [(ngModel)]="newData.company.address.province"
                [placeHolder]="'Provincia'"
                (change)="(isEdit)? updateLine(newData.company.address.province, 'province', 'address') : isCompleted()"
                name="Provincia"></CustomInput>
              <div class="subColumn">
                <label for="">Selecciona el país</label>
                <selector [options]="opcionesCountry" [disabled]="isDataSelectedFromAddress" [(ngModel)]="newData.company.address.country"
                  (change)="isCompleted()"></selector>
              </div>
            </div>

            <div class="checkBoxLine">
              <mat-checkbox (change)="vinculadoAGrupoEmpresarial($event)"></mat-checkbox>
              <label for="">Vinculado a grupo empresarial</label>
            </div>

            <!-- Grupo empresarial -->

            <div class="body" *ngIf="isVinculadoAGrupoEmpresarial">

            <div class="header">
              <p class="title">
                <mat-icon>corporate_fare</mat-icon>
                Datos del grupo empresarial
              </p>
            </div>

            <div class="subColumn">
              <label for="">Selecciona o crea un grupo empresarial</label>
              <selector [options]="opcionesHolding" [(ngModel)]="holdingSelected"
                (change)="holdingSelector(holdingSelected)"></selector>
            </div>

            <div class="doubleColumn">
              <CustomInput [readOnly]="isDataSelectedFomHoldings" [type]="'text'" [label]="'Nombre del grupo empresarial'"
                [(ngModel)]="holdingData.name" [placeHolder]="'Nombre del grupo empresarial'"
                (change)="(isEdit)? updateLine(holdingData.name, 'name', 'holding') : isCompleted()"
                name="Nombre del grupo empresarial"></CustomInput>
              <CustomInput [readOnly]="isDataSelectedFomHoldings" [type]="'text'" [label]="'CIF'"
                [(ngModel)]="holdingData.cif" [placeHolder]="'CIF'"
                (change)="(isEdit)? updateLine(holdingData.cif, 'cif', 'holding') : isCompleted()"
                name="CIF"></CustomInput>
              </div>
              <CustomInput [readOnly]="isDataSelectedFomHoldings" [type]="'text'" [label]="'Persona de contacto'"
                [(ngModel)]="holdingData.contactWith" [placeHolder]="'Persona de contacto'"
                (change)="(isEdit)? updateLine(holdingData.contactWith, 'contactWith', 'holding') : isCompleted()"
                name="Persona de contacto"></CustomInput>

            <div class="doubleColumn">
              <CustomInput [readOnly]="isDataSelectedFomHoldings" [type]="'text'" [label]="'Email'" [(ngModel)]="holdingData.email"
                [placeHolder]="'Email'"
                (change)="(isEdit)? updateLine(holdingData.email, 'email', 'holding') : isCompleted()"
                name="Email"></CustomInput>
              <div class="subColumn">
                <label for="">Teléfono</label>
                <div>
                  <p class="insteadOfCountrySelector" *ngIf="isDataSelectedFomHoldings" [class.readOnly]="isDataSelectedFomHoldings">{{holdingData.phonePrefix}}</p>
                  <CountrySelector *ngIf="!isDataSelectedFomHoldings" [country]="holdingData.phonePrefix" (emitCountry)="getPrefix($event, 'holding')"></CountrySelector>
                  <CustomInput [readOnly]="isDataSelectedFomHoldings" [type]="'number'" [placeHolder]="'Teléfono'" [(ngModel)]="holdingData.phone"
                    name="telefono"
                    (change)="(isEdit)? updateLine(holdingData.phone, 'phone', 'holding') : isCompleted()">
                  </CustomInput>
                </div>
              </div>
            </div>

            <div class="doubleColumn">
              <CustomInput [readOnly]="isDataSelectedFomHoldings" [type]="'text'" [label]="'Calle'" [(ngModel)]="holdingData.address.street"
                [placeHolder]="'Calle'"
                (change)="(isEdit)? updateLine(holdingData.address.street, 'street', 'holdingAddress') : isCompleted()"
                name="Calle"></CustomInput>

              <div class="doubleColumn">
                <CustomInput [readOnly]="isDataSelectedFomHoldings" [type]="'text'" [label]="'Número'" [(ngModel)]="holdingData.address.number"
                  [placeHolder]="'Número'"
                  (change)="(isEdit)? updateLine(holdingData.address.number, 'number', 'holdingAddress') : isCompleted()"
                  name="Número"></CustomInput>

                <CustomInput [readOnly]="isDataSelectedFomHoldings" [type]="'text'" [label]="'Puerta'" [(ngModel)]="holdingData.address.door"
                  [placeHolder]="'Puerta'"
                  (change)="(isEdit)? updateLine(holdingData.address.door, 'door', 'holdingAddress') : isCompleted()"
                  name="Puerta"></CustomInput>
              </div>
            </div>

            <div class="doubleColumn">
              <CustomInput [readOnly]="isDataSelectedFomHoldings" [type]="'text'" [label]="'Ciudad'" [(ngModel)]="holdingData.address.municipality"
                [placeHolder]="'Ciudad'"
                (change)="(isEdit)? updateLine(holdingData.address.municipality, 'municipality', 'holdingAddress') : isCompleted()"
                name="Ciudad"></CustomInput>
              <CustomInput [readOnly]="isDataSelectedFomHoldings" [type]="'text'" [label]="'Código Postal'"
                [(ngModel)]="holdingData.address.postalCode" [placeHolder]="'Código Postal'"
                (change)="(isEdit)? updateLine(holdingData.address.postalCode, 'postalCode', 'holdingAddress') : isCompleted()"
                name="Código Postal"></CustomInput>
            </div>

            <div class="doubleColumn">
              <CustomInput [readOnly]="isDataSelectedFomHoldings" [type]="'text'" [label]="'Provincia'" [(ngModel)]="holdingData.address.province"
                [placeHolder]="'Provincia'"
                (change)="(isEdit)? updateLine(holdingData.address.province, 'province', 'holdingAddress') : isCompleted()"
                name="Provincia"></CustomInput>
              <div class="subColumn">
                <label for="">Selecciona el país</label>
                <selector  [options]="opcionesCountry" [(ngModel)]="holdingData.address.country"
                  (change)="isCompleted()"></selector>
              </div>
            </div>

          </div>

        </div>
      </div>
        <div class="academicos">
          <div class="header">
            <p class="title">
              <mat-icon>school</mat-icon>
              Formación
            </p>
          </div>
          <div class="body">

            <div class="subColumn">
              <label for="">Selecciona o crea una formación</label>
              <selector [options]="opcionesFormacion" [(ngModel)]="formationSelected"
                (change)="selectedFormation(formationSelected)"></selector>
            </div>

            <CustomInput [type]="'text'" [label]="'Nombre de la formación'" [readOnly]="isDataSelectedFomFormations" [(ngModel)]="formationData.name"
            [placeHolder]="'Nombre de la formación'"
            (change)="isCompleted()"
            name="Nombre de la formación"></CustomInput>

            <div class="quadraColumn">

              <div class="subColumn">
                <label for="">Idioma</label>
                <selector [options]="opcionesIdiomas" [disabled]="isDataSelectedFomFormations" [(ngModel)]="formationData.language"
                  (change)="isCompleted()"></selector>
              </div>
              
              <div class="subColumn">
                <label for="">Modalidad</label>
                <selector [options]="opcionesModos" [disabled]="isDataSelectedFomFormations" [(ngModel)]="formationData.mode"
                (change)="isCompleted()"></selector>
              </div>
              
              <CustomInput [type]="'number'" [label]="'Duración (horas)'" [readOnly]="isDataSelectedFomFormations" [(ngModel)]="formationData.duration"
              [placeHolder]="'Duración (horas)'"
              (change)="isCompleted()"
              name="Duración (horas)"></CustomInput>
  
              <div class="inputSimulatorWithLabel" *ngIf="!isDataSelectedFomFormations">
                <p class="fakeLabel">Precio formación (orientativo)</p>
                <div class="inputSimulator">
                  <div class="input" 
                    [class.placeHolder]="formationData.modalityPrice[0].price == 0 || formationData.modalityPrice[0].price == null">
                    <ng-container
                      *ngIf="formationData.modalityPrice[0].price == 0 || formationData.modalityPrice[0].price == null">
                      <label>Precio formación (orientativo)</label>
                    </ng-container>
                    <ng-container
                      *ngIf="formationData.modalityPrice[0].price != 0">{{formationData.modalityPrice[0].price
                      | currency:'EUR'}}</ng-container>
                  </div>
                  <CustomInput [type]="'number'" [(ngModel)]="formationData.modalityPrice[0].price"
                    [placeHolder]="'Precio formación (orientativo)'"
                    (change)="isCompleted()"
                    name="number"></CustomInput>
                </div>
              </div>

              <div class="readOnlyInputSimulator" *ngIf="isDataSelectedFomFormations">
                <p class="fakeLabel">Precio formación (orientativo)</p>
                <div class="fakeInput">
                  <div class="input" 
                  [class.placeHolder]="formationData.modalityPrice[0].price == 0 || formationData.modalityPrice[0].price == null">
                  <ng-container
                    *ngIf="formationData.modalityPrice[0].price == 0 || formationData.modalityPrice[0].price == null">
                    <label>Precio formación (orientativo)</label>
                  </ng-container>
                  <ng-container
                    *ngIf="formationData.modalityPrice[0].price != 0">{{formationData.modalityPrice[0].price
                    | currency:'EUR'}}</ng-container>
                </div>
                </div>
              </div>

              


            </div>

            <CustomTextArea (change)=" isCompleted()" [disabled]="isDataSelectedFomFormations"  [label]="'Objetivos de la formación'" [placeHolder]="'Objetivos de la formación'" [(ngModel)]="formationData.objectives"></CustomTextArea>
            <CustomTextArea (change)=" isCompleted()" [disabled]="isDataSelectedFomFormations"  [label]="'Descripción de la formación'" [placeHolder]="'Descripción de la formación'" [(ngModel)]="formationData.description"></CustomTextArea>

          </div>
        </div>


        <div class="datosFiscales" [ngStyle]="{'opacity': '0', 'height': '27vh'}"></div>

      </div>
    </div>

  </ng-container>
</fullContainer>