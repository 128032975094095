import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iFormation } from "./iFormation";

export interface iMaster {
    id: number,
    formation?: iFormation | any,
}

export class iMaster extends iBaseObject {
    static convertFromCollection(ob: any): Array<iMaster> {
        let iMasterCollection: Array<iMaster> = [];
        ob.forEach((element: any) => {
            iMasterCollection.push(this.convertFromObject(element));
        });
        return iMasterCollection;
    }

    static convertFromObject(ob: any): iMaster {
        let iMasterObj = new iMaster();
        iMasterObj.id = ob.id;
        iMasterObj.formation = ob.formation ? iFormation.convertFromObject(ob.formation) : {};
        return iMasterObj;
    }

    static createEmptyObject(): iMaster {
        let iMasterObj = new iMaster();
        iMasterObj.formation = iFormation.createEmptyObject();
        return iMasterObj;
    }
}

export interface iMasterFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    formation?: number,
}