
<div class="detailViewContent">
  <ng-template 
    *ngIf="modules?.length === 0 && !isEditing" 
    [ngTemplateOutlet]="withoutContent"
  ></ng-template>
  <ng-container *ngIf="modules.length > 0">
    <DetailView-Content-ModulesContainer
      [isEditing]="isEditing"
      [modules]="modules"
      [promotion]="promotion"
      (onCreate)="handleCreateModule($event)"
    ></DetailView-Content-ModulesContainer>
  </ng-container>
  <button 
    class="detailViewContent__addModule" 
    *ngIf="isEditing && modules?.length === 0" 
    (click)="addModule()"
  ><mat-icon>add_circle_outline</mat-icon></button>
</div>

<ng-template #withoutContent ><p>No hay módulos que mostrar</p></ng-template>

