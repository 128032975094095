import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OutputsService {
  /**
   * This block of code is responsible for subscribing to each output event from the components that were dynamically inserted into the table.
   *
   * For each output event, it applies a filter to only proceed with the subscription if the emitted value (`res`) is a non-null string.
   *
   * Once the filter condition is met, it logs the following information:
   * - `res`: The emitted value from the output event.
   * - `outputObject.key`: The key of the output event.
   * - `outputObject.component`: The name of the component that emitted the output event.
   * - `outputObject.propertyName`: The property name of the output event.
   * - `outputObject.line`: The line number in the table where the component that emitted the output event is located.
   *
   * This is useful for debugging purposes, as it allows you to see the details of each output event that is emitted by the components in the table.
   */

  // this.outputsSE.outputsArray.forEach((outputObject, index) => {
  //   this.SubjectArray.push(new Subject());
  //   outputObject.value
  //     .pipe(takeUntil(this.SubjectArray[index]),filter((res) => res && typeof res == 'string'))
  //     .subscribe((res) => {
  //       console.log(
  //         res,
  //         outputObject.key,
  //         outputObject.component,
  //         outputObject.propertyName,
  //         outputObject.line
  //       );
  //     });
  // });

  /**
   * Array of output objects.
   */
  private _outputsArray: iOutput[] = [];

  /**
   * Trigger to subscribe to the outputs.
   */

  private _mustSubscribe: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor() {}

  /**
   * Gets the array of outputs.
   * @returns The array of outputs.
   */
  get outputsArray(): iOutput[] {
    return this._outputsArray;
  }

  /**
   * Sets the array of outputs. And triggers the subscription to the outputs.
   * @param value - The array of outputs to set.
   */
  set outputsArray(value: iOutput[]) {
    this._outputsArray = value;
    this.mustSubscribe.next(true);
    setTimeout(() => {
      this.mustSubscribe.next(false);
    }, 150);
  }

  /**
   * Gets the must subscribe trigger.
   * @returns The must subscribe trigger.
   */

  get mustSubscribe(): BehaviorSubject<boolean> {
    return this._mustSubscribe;
  }

  /**
   * Sets the must subscribe trigger.
   * @param value - The must subscribe trigger to set.
   */
  set mustSubscribe(value: BehaviorSubject<boolean>) {
    this._mustSubscribe = value;
  }

  public pushToArray(output: iOutput[]) {
    this._outputsArray = [...this._outputsArray, ...output];
    this.mustSubscribe.next(true);
    setTimeout(() => {
      this.mustSubscribe.next(false);
    }, 150);
  }

  public clearOutputs() {
    this._outputsArray = [];
    this.mustSubscribe.next(true);
    setTimeout(() => {
      this.mustSubscribe.next(false);
    }, 150);
  }
}

/**
 * Represents an output configuration for a component.
 */
export interface iOutput {
  /**
   * The name of the component.
   */
  component: string;

  /**
   * The name of the property.
   */
  propertyName: string;

  /**
   * An event emitter for the output value.
   */
  value: EventEmitter<any>;

  /**
   * The line number where the output is defined.
   */
  line: number;

  /**
   * The key associated with the output.
   */
  key: string;
}
