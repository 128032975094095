import { IPromotionBill, iPromotionBill } from "../Interfaces/Utils/iPromotionBill";
import { IPromotionDates, iPromotionDates } from "../Interfaces/Utils/iPromotionDates";
import { IPromotionInfoForView, iPromotionInfoForView } from "../Interfaces/Utils/iPromotionInfoForView";
import { IPromotionStudent, iPromotionStudent } from "../Interfaces/Utils/iPromotionStudent";
import { IPromotionTribunal, iPromotionTribunal } from "../Interfaces/Utils/iPromotionTribunal";
import { IPromotionTutoring, iPromotionTutoring } from "../Interfaces/Utils/iPromotionTutoring";

export class PromotionsDetailViewClass {
  // Promotion Info
  promotionInfoForView: iPromotionInfoForView  = IPromotionInfoForView.getEmptyObject();
  // Promotino Dates
  promotionDates: iPromotionDates = IPromotionDates.getEmptyObject();
  // Promotion Students
  promotionStudent: iPromotionStudent[] = [IPromotionStudent.getEmptyObject()];
  promotionStudentTotal: number = 0;

  // Tribunal promotion
  tribunalPromotion:iPromotionTribunal = IPromotionTribunal.getEmptyObject();

  // tutoringPromotion
  tutoringPromotion:iPromotionTutoring[] = [IPromotionTutoring.getEmptyObject()];

  promotionBilling: iPromotionBill = IPromotionBill.getEmptyObject();

  // Promotion Students Params
  promotionStudentsParams = {
    num_devoluciones:20,
    num_pagina:1,
  }

}


