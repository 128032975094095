import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, TokenManagmentService, iResultHttp, iStaticUtilities, sha256 } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from '../Utils/StaticUtilities.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();
  
  constructor(private http: HttpClient, public router: Router) {
    super();
  }

  login(data) {
    let credentials = {...data}
    credentials.password = sha256(credentials.password);
    this.http.post(this.urlApi + '/login/user', credentials).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: data["status"], ok: true, message: "Successfully logged in" });
        let normalizedTokens = iStaticUtilities.normalizeNames(data);
        this.setToken(normalizedTokens["token"]);
        this.setRefreshToken(normalizedTokens["refreshToken"]);
        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.login, args: [data] });
        this.checkStatusError(error);
      },
    })
  }
  me() {
    this.http.get(`${this.urlApi}/api/user/me`,this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data});
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.me, args: [] });
        this.checkStatusError(error);
      },
    })
  }
  refreshToken() {
    this.http.post(this.urlApi + '/api/refreshToken', { refresh_token: localStorage.getItem("refreshToken") }).subscribe({
      next: (data: any) => {
        let normalizedTokens = iStaticUtilities.normalizeNames(data);
        this.setToken(normalizedTokens["token"]);
        this.setRefreshToken(normalizedTokens["refreshToken"]);
        TokenManagmentService.completeRefreshToken();
      },
      error: (error) => {
        TokenManagmentService.tokenLost();
      },
    });
  }
  logOut() {
    localStorage.removeItem("token")
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userName")
    localStorage.removeItem("email")
    localStorage.removeItem("userType")
    localStorage.removeItem("roleUser");
    StaticUtilitiesService.showFeedback('Sesión cerrada correctamente')
    this.router.navigate(["/"])
  }
  recoverAccount(email:string){
    this.http.post(this.urlApi + '/recover/account', { email: email }).subscribe({
      next: (data:any) => {
        let normalizedResponse = iStaticUtilities.normalizeNames(data);
        this.res = new iResultHttp({ status: 200, ok: true, message: normalizedResponse["message"] });
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res= new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.recoverAccount, args: [email] });
        this.checkStatusError(error);
      },
    })
  }
  updatePassword(obj:any){
    this.http.post(this.urlApi + '/cambiarPassword', obj).subscribe({
      next: (data:any) => {
        let normalizedResponse = iStaticUtilities.normalizeNames(data);
        this.res = new iResultHttp({ status: 200, ok: true, message: normalizedResponse["message"] });
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res= new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.updatePassword, args: [obj] });
        this.checkStatusError(error);
      },
    })
  }

  cambiarPassword(obj:any){
    this.http.post(this.urlApi + '/change/password', obj).subscribe({
      next: (data:any) => {
        let normalizedResponse = iStaticUtilities.normalizeNames(data);
        this.res = new iResultHttp({ status: 200, ok: true, message: normalizedResponse["message"] });
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res= new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.cambiarPassword, args: [obj] });
        this.checkStatusError(error);
      },
    })
  }
}
