import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { iResultHttp, iStaticUtilities, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { PermissionService } from 'src/app/Services/Api/Permission.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'Permisos-Permisos',
  templateUrl: './Permisos-Permisos.component.html',
  styleUrls: ['./Permisos-Permisos.component.scss']
})
export class PermisosPermisosComponent extends iUnsubscribeDestroy implements  OnInit {

  @Input() permisionList: any[] = [];
  @Input() userPermisions: any[] = [];
  @Input() userSelected: any = null;

  @Output() changeView = new EventEmitter();

  matrizCheckboxes: any[] = [];

  constructor(private staticUtilitiesSE:StaticUtilitiesService, private permissionSE:PermissionService) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['permisionList'] && changes['permisionList'].currentValue){
      changes['permisionList'].currentValue.forEach(element => {
        this.matrizCheckboxes.push( [null, null]);
      });
    }
    if(changes['userPermisions'] && changes['userPermisions'].currentValue){
       
      let permisions = changes['userPermisions'].currentValue;
      permisions.forEach((element, index) => {
        // Le estamos diciendo que la columna de la martiz es la segunda
        if(element % 2 === 0){
          // Dividimos el numero que llega entre dos y le restamos uno para saber en que fila de la matriz se encuentra
          this.matrizCheckboxes[(element / 2) - 1][1] = element;
          // Le ponemos el otro checkbox en disabled
          this.matrizCheckboxes[(element / 2) - 1][0] = 'selectedTwo';
        }
        // Le estamos diciendo que la columna de la martiz es la primera
        if(element % 2 !== 0){
          // Dividimos el numero que llega entre dos y lo redondeamos hacia abajo para saber en que fila de la matriz se encuentra
          this.matrizCheckboxes[Math.floor(element/2)][0] = element;
        }
      });
    }
  }

  /**
   * FUNCTIONALITY
   */

  checkBoxChange(event, indexLine, indexCheckBox, element) {
     
    if(event.checked){
      this.matrizCheckboxes[indexLine][indexCheckBox] = element.id;
      if(indexCheckBox == 1){
        this.matrizCheckboxes[indexLine][0] = 'selectedTwo';
      }
    }
    if(!event.checked){
      this.matrizCheckboxes[indexLine][indexCheckBox] = null;
      if(indexCheckBox == 1){
        this.matrizCheckboxes[indexLine][0] = this.permisionList[indexLine].permissions[0].id;
      }
    }
  }

  goBack() {
    this.changeView.emit(false);
  }

  getDataFromLocalStorage() {
    let data = localStorage.getItem('usr')!;
    let decripted = iStaticUtilities.simpleDecrypt(data);
    let user = JSON.parse(decripted);
    return user;
  }
  
  async saveRoles() {
    let permission = await this.collectPermisionsFromMatriz();
    this.savePermisions(permission);
  }


  uploadDataToLocalStorage(user, permissionAndRoles){
    let data = {...user, ...permissionAndRoles};
    let parsed = JSON.stringify(data);
    let encripted = iStaticUtilities.simpleCrypt(parsed);
    localStorage.setItem('usr', encripted);
  }

  collectPermisionsFromMatriz(): Promise<any> {
    return new Promise((resolve, reject) => {
    let permission:any = [];
    this.matrizCheckboxes.forEach((line, index) => {
      line.forEach(element => {
        if(element !== null && element !== 'selectedTwo'){
          permission.push(element);
        }
      })
    });
    resolve(permission);
    });

  }

  savePermisions(permission) {
    let objectToPass = {
      user: this.userSelected.id,
      permission
    };
    this.permissionSE.assignPermissions(objectToPass);
    this.permissionSE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe((res:iResultHttp) => {
      if(!res)return;
      StaticUtilitiesService.showFeedback('Se han guardado los permisos correctamente');
      let permissionAndRoles = StaticUtilitiesService.getUserPermissionsAndRoles();
      let user = this.getDataFromLocalStorage();
      this.uploadDataToLocalStorage(user, permissionAndRoles);
      this.changeView.emit(false);
      this._unsubInd2.next('')
    })

    this.permissionSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  

}
