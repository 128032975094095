<div class="fullSection">
  <div class="areaDropExcel">
    <div class="areaExcel" fileInput (dragover)="onDragOver($event)" (dragleave)="dragleave($event)" [accept]="['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']" [(ngModel)]="data.excel"
    (change)="updateExcel(data.excel)">
      <div class="iconTextLine" *ngIf="!isDraggedOver">
        <img src="/assets/Icons/Excel/ExcelIcon.svg" alt="ExcelIcon">
        <p>Arrastre un archivo .xls</p>
      </div>
      <button fileInput class="buttonSubirExcel" [accept]="['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']" [(ngModel)]="data.excel"
      (change)="updateExcel(data.excel)" *ngIf="!isDraggedOver">Subir Archivo</button>

      <div class="iconTextLine" *ngIf="isDraggedOver">
        <p class="fwBold">Suelte el archivo aquí</p>
    </div>
  </div>
</div>