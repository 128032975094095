<Tutorias-Alumnos-Layout>
  <div class="fullContainer">
    <div class="titleAndOvertitle">
      <div class="overTitle">
        BIENVENIDA ELENA
      </div>
      <div class="title">
        Rellena los datos para la reserva
      </div>
    </div>
    <div class="rules">
      <p>Por favor, selecciona la tutoría. Para ello:</p>
      <p>1. Máster: seleccionar el Máster al que pertenezcas</p>
      <p>2. Tutor: elige el docente con el que quieres reservar una tutoría</p>
      <p>3. Modalidad Tutoría: selecciona si quieres tutoría presencial o streaming</p>
      <p>4. Elige entre las fechas y horas disponibles</p>
      <p>5. Añade algún comentario si lo requieres</p>
    </div>

    <form class="form">

      <div class="formLine doble">
        <selector name="master" class="tutoria-alumno" [(ngModel)]="extraClass.reserva.master"
          [options]="extraClass.masterSelectorOptions" style="width: 100%;"
          (change)="getTeachersByMastersByPublicToken();extraClass.setStep();extraClass.cleanModelsOnChange('master')"></selector>
        <selector name="t" class="tutoria-alumno" [(ngModel)]="extraClass.reserva.tutor"
          [options]="extraClass.tutorSelectorOptions" style="width: 100%;"
          [ngClass]="{'disabled':!extraClass.steps.tutor}" (change)="getDatesByPublicToken();extraClass.setStep();extraClass.cleanModelsOnChange('tutor')">
        </selector>
      </div>

      <div class="formLine doble">
        <div class="formLine doble">
          <selector name="f" class="tutoria-alumno" [ngClass]="{'disabled':!extraClass.steps.date}"
            [(ngModel)]="extraClass.reserva.date" [options]="extraClass.fechaSelectorOptions" style="width: 100%;"
            (change)="getSlotsByPublicToken();extraClass.setStep();extraClass.cleanModelsOnChange('date')">
          </selector>
          <selector name="h" class="tutoria-alumno" [ngClass]="{'disabled':!extraClass.steps.time}"
            [(ngModel)]="extraClass.reserva.time" [options]="extraClass.horaSelectorOptions" style="width: 100%;"
            (change)="getTutoringByPublicToken();extraClass.setStep();extraClass.cleanModelsOnChange('time');"></selector>
        </div>
        <selector name="mo" class="tutoria-alumno" [ngClass]="{'disabled':!extraClass.steps.modality}"
          [(ngModel)]="extraClass.reserva.modality" [options]="extraClass.modalidadSelectorOptions" style="width: 100%;"
          (change)="extraClass.cleanModelsOnChange('modality');extraClass.setStep()"></selector>
      </div>

      <div class="formLine">
        <customTextArea name="c" class="tutoria-alumno" [ngClass]="{'disabled':!extraClass.steps.comments}"
          [(ngModel)]="extraClass.reserva.comments" [options]="extraClass.comentariosTextAreaOptions"
          style="width: 100%;" (change)="extraClass.setStep()"></customTextArea>
      </div>
      <div class="formLine flexEnd">

        <btn classList="generalbutton principalButton fitWidth " type="submit" [class.disabled]="!extraClass.steps.comments"
          (click)="prepareBodyToSend()">
          Reservar
        </btn>
      </div>

    </form>
  </div>
</Tutorias-Alumnos-Layout>