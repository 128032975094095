import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StaticUtilitiesService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';

@Component({
  selector: 'FormacionesNewVersion-Incompany',
  templateUrl: './FormacionesNewVersion-Incompany.component.html',
  styleUrls: ['./FormacionesNewVersion-Incompany.component.scss']
})
export class FormacionesNewVersionIncompanyComponent implements OnInit {

  @Input() allIncompanies:any = [];
  @Input() totalIncompanies:number = 0;
  @Input() areReadyAllIncompanies:boolean = false;
  @Input() filterAllIncompanies:any = null;

  @Output() pageChange:EventEmitter<boolean> = new EventEmitter()


  constructor(private staticUtilitiesSE:StaticUtilitiesService) { }

  ngOnInit() {
  }

  goToDetailView(id:number){
    this.staticUtilitiesSE.goTo('formaciones/formaciones-detalle/', slideNavigationTypes.slideToTop, {formation:id});
  }

  pageChangeEmmiter(event) {
    this.pageChange.emit(event);
  }

}
