<fullContainer gradient="bajo">
  <ng-container>
    <header></header>
    <div class="formGeneralContainer">
      <div class="timeline">
        <div class="container">
          <p class="title"> {{action}} {{whatAmIAdding}}</p>
          <div class="options">
            <span [class.active]="optionSelected == 0" (click)="scroll('informacion')">
              <mat-icon>info</mat-icon>
              Información general
            </span>
            <span [class.active]="optionSelected == 1" (click)="scroll('descripcion')">
              <mat-icon>school</mat-icon>
              Descripción formación
            </span>
            <span [class.active]="optionSelected == 2" (click)="scroll('precio')">
              <mat-icon>payments</mat-icon>
              Precio formación
            </span>
          </div>
        </div>
        <div class="footer">
          <btn classList="principalButton small fitWidth" *ngIf="!isEdit" (click)="confirmPopup()">
            <ng-container>
              Cancelar
            </ng-container>
          </btn>
          <btn classList="specialButton small fitWidth loading" *ngIf="!isEdit" (click)="crearFormacion()" [class.disabled]="!isActive">
            <ng-container *ngIf="!isLoading">
              Finalizar
            </ng-container>
            <ng-container *ngIf="isLoading">
              <loaderIcon class="white" size="small"></loaderIcon>
            </ng-container>
          </btn>
          <btn classList="specialButton small fitWidth loading" *ngIf="isEdit" (click)="finishEdition()">
            <ng-container *ngIf="!isLoading">
              Salir
            </ng-container>
            <ng-container *ngIf="isLoading">
              <loaderIcon class="white" size="small"></loaderIcon>
            </ng-container>
          </btn>
        </div>

      </div>
      <div class="form">

        <div class="contactoContainer">
          <div class="header">
            <p class="title">
              <mat-icon>info</mat-icon>
              Información general
            </p>
          </div>
          <div class="body">
            <CustomInput [type]="'text'" class="longInput" [label]="'Nombre de la formación'" [placeHolder]="'Nombre de la formación'" [(ngModel)]="data.formation.name"
            name="nombre" (change)="(isEdit)? updateLine(data.formation.name, 'name', 'formation',null) : isCompleted()"></CustomInput>
          
            <div class="doubleColumn">
              <div class="subColumn">
                <label for="">Idiomas</label>
                <selector [options]="opcionesIdiomas" [(ngModel)]="data.formation.language" (change)="idiomasSelector(data.formation.language)"></selector>
              </div>
              <div class="subColumn">
                <label for="">Modalidad</label>
                <selector [options]="opcionesModos" [(ngModel)]="data.formation.mode" (change)="modalidadSelector(data.formation.mode)"></selector>
              </div>
            </div>
            <div class="doubleColumn">
              <div class="subColumn">
                <label for="">Tipo de formación</label>
                <selector [disabled]="disableSelector" [options]="opcionesTipo" [(ngModel)]="data.formation.typeOf" (change)="tipoSelector(data.formation.typeOf)"></selector>
              </div>
              <CustomInput [type]="'number'" class="longInput" [label]="'Duración de la formación (Horas)'" [placeHolder]="'Duración de la formación (Horas)'" [(ngModel)]="data.formation.duration"
              name="nombre" (change)="(isEdit)? updateLine(data.formation.duration, 'duration', 'formation',null) : isCompleted()"></CustomInput>
  
            </div>
          
          
          </div>
        </div>
        <div class="datosFiscales">
          <div class="header">
            <p class="title">
              <mat-icon>school</mat-icon>
              Descripción formación
            </p>

          </div>
          <div class="body">
            <CustomTextArea (change)="(isEdit)? updateLine(data.formation.objectives, 'objectives', 'formation',null) : isCompleted()"  [label]="'Objetivos de la formación'" [placeHolder]="'Objetivos de la formación'" [(ngModel)]="data.formation.objectives"></CustomTextArea>
            <CustomTextArea (change)="(isEdit)? updateLine(data.formation.description, 'description', 'formation',null) : isCompleted()"  [label]="'Descripción de la formación'" [placeHolder]="'Descripción de la formación'" [(ngModel)]="data.formation.description"></CustomTextArea>
          </div>
        </div>

        <div class="academicos">
          <div class="header">
            <p class="title">
              <mat-icon>payments</mat-icon>
              Precio formación
            </p>
          </div>
          <div class="body">
            <div class="doubleColumn">
              <div class="inputSimulatorWithLabel">
                <p class="fakeLabel">Cuota Única</p>
                  <div class="inputSimulator">
                    <div class="input"
                      [class.placeHolder]="data.formation.modalityPrice[0].price == 0 || data.formation.modalityPrice[0].price == null">
                      <ng-container *ngIf="data.formation.modalityPrice[0].price == 0 || data.formation.modalityPrice[0].price == null">
                        <label>Cuota Única</label>
                      </ng-container>
                      <ng-container *ngIf="data.formation.modalityPrice[0].price != 0">{{data.formation.modalityPrice[0].price
                        | currency:'EUR'}}</ng-container>
                    </div>
                    <CustomInput [type]="'number'"  [(ngModel)]="data.formation.modalityPrice[0].price"
                      [placeHolder]="'Cuota Única'" (change)="(isEdit)? updateLine(data.formation.modalityPrice[0].price, 'price', 'modalityPrice',0) : isCompleted()" name="number"></CustomInput>
                  </div>
                </div>
                <div class="inputSimulatorWithLabel" *ngIf="this.data.formation.typeOf != 'Incompany'">
                  <p class="fakeLabel">Reserva</p>
                    <div class="inputSimulator">
                      <div class="input"
                        [class.placeHolder]="data.formation.modalityPrice[1].price == 0 || data.formation.modalityPrice[1].price == null">
                        <ng-container *ngIf="data.formation.modalityPrice[1].price == 0 || data.formation.modalityPrice[1].price == null">
                          <label>Reserva</label>
                        </ng-container>
                        <ng-container *ngIf="data.formation.modalityPrice[1].price != 0">{{data.formation.modalityPrice[1].price
                          | currency:'EUR'}}</ng-container>
                      </div>
                      <CustomInput [type]="'number'"  [(ngModel)]="data.formation.modalityPrice[1].price"
                        [placeHolder]="'Reserva'" (change)="(isEdit)? updateLine(data.formation.modalityPrice[1].price, 'price', 'modalityPrice',1) : isCompleted()" name="number"></CustomInput>
                    </div>
                  </div>
            </div>
            <div class="tripleColumn">
              <div class="inputSimulatorWithLabel" *ngIf="this.data.formation.typeOf != 'Incompany'">
                <p class="fakeLabel">6 Cuotas</p>
                  <div class="inputSimulator">
                    <div class="input"
                      [class.placeHolder]="data.formation.modalityPrice[4].price == 0 || data.formation.modalityPrice[4].price == null">
                      <ng-container *ngIf="data.formation.modalityPrice[4].price == 0 || data.formation.modalityPrice[4].price == null">
                        <label>6 Cuotas</label>
                      </ng-container>
                      <ng-container *ngIf="data.formation.modalityPrice[4].price != 0">{{data.formation.modalityPrice[4].price
                        | currency:'EUR'}}</ng-container>
                    </div>
                    <CustomInput [type]="'number'"  [(ngModel)]="data.formation.modalityPrice[4].price"
                      [placeHolder]="'6 Cuotas'" (change)="(isEdit)? updateLine(data.formation.modalityPrice[4].price, 'price', 'modalityPrice',4) : isCompleted()" name="number"></CustomInput>
                  </div>
                </div>
              <div class="inputSimulatorWithLabel" *ngIf="this.data.formation.typeOf != 'Incompany'">
                <p class="fakeLabel">9 Cuotas</p>
                  <div class="inputSimulator">
                    <div class="input"
                      [class.placeHolder]="data.formation.modalityPrice[2].price == 0 || data.formation.modalityPrice[2].price == null">
                      <ng-container *ngIf="data.formation.modalityPrice[2].price == 0 || data.formation.modalityPrice[2].price == null">
                        <label>9 Cuotas</label>
                      </ng-container>
                      <ng-container *ngIf="data.formation.modalityPrice[2].price != 0">{{data.formation.modalityPrice[2].price
                        | currency:'EUR'}}</ng-container>
                    </div>
                    <CustomInput [type]="'number'"  [(ngModel)]="data.formation.modalityPrice[2].price"
                      [placeHolder]="'9 Cuotas'" (change)="(isEdit)? updateLine(data.formation.modalityPrice[2].price, 'price', 'modalityPrice',2) : isCompleted()" name="number"></CustomInput>
                  </div>
                </div>
        
              <div class="inputSimulatorWithLabel" *ngIf="this.data.formation.typeOf != 'Incompany'">
                <p class="fakeLabel">12 Cuotas</p>
                  <div class="inputSimulator">
                    <div class="input"
                      [class.placeHolder]="data.formation.modalityPrice[3].price == 0 || data.formation.modalityPrice[3].price == null">
                      <ng-container *ngIf="data.formation.modalityPrice[3].price == 0 || data.formation.modalityPrice[3].price == null">
                        <label>12 Cuotas</label>
                      </ng-container>
                      <ng-container *ngIf="data.formation.modalityPrice[3].price != 0">{{data.formation.modalityPrice[3].price
                        | currency:'EUR'}}</ng-container>
                    </div>
                    <CustomInput [type]="'number'"  [(ngModel)]="data.formation.modalityPrice[3].price"
                      [placeHolder]="'12 Cuotas'" (change)="(isEdit)? updateLine(data.formation.modalityPrice[3].price, 'price', 'modalityPrice',3) : isCompleted()" name="number"></CustomInput>
                  </div>
                </div>
            </div>
          </div>
        </div>


        <div class="datosFiscales" [ngStyle]="{'opacity': '0', 'height': '27vh'}"></div>

      </div>
    </div>
  </ng-container>
</fullContainer>