<fullContainer gradient="bajo">
  <ng-container>
    <header></header>
    <div class="formGeneralContainer">
      <div class="timeline">
        <div class="container">
          <p class="title" *ngIf="!isEdit">Añadiendo promoción</p>
          <p class="title" *ngIf="isEdit">Editando promoción</p>

          <div class="options">
            <span [class.active]="optionSelected == 0" (click)="scroll('informacion')">
              <mat-icon>info</mat-icon>
              Información general
            </span>
            <span [class.active]="optionSelected == 1" (click)="scroll('descripcion')">
              <mat-icon>school</mat-icon>
              Profesorado
            </span>
          </div>
        </div>
        <div class="footer">
          <btn *ngIf="!isEdit" classList="principalButton small fitWidth" (click)="confirmPopup()">
            <ng-container>
             <p >Cancelar</p> 
            </ng-container>
          </btn>

          <btn *ngIf="isEdit" classList="principalButton small fitWidth" (click)="goBack()">
            <ng-container>
             <p >Volver</p> 
            </ng-container>
          </btn>
          <btn *ngIf="!isEdit" classList="specialButton small fitWidth loading" (click)="createPromotion()" [class.disabled]="!isActive">
            <ng-container *ngIf="!isLoading">
              Finalizar
            </ng-container>
            <ng-container *ngIf="isLoading">
              <loaderIcon class="white" size="small"></loaderIcon>
            </ng-container>
          </btn>
        </div>

      </div>
      <div class="form">

        <div class="contactoContainer">
          <div class="header">
            <p class="title">
              <mat-icon>info</mat-icon>
              Información general
            </p>
          </div>
          <div class="body">
            <CustomInput [type]="'text'" class="longInput" [label]="'Nombre de la promoción'" [placeHolder]="'Nombre de la promoción'" [(ngModel)]="data.edition"
            name="edicion" (change)="(isEdit)? updateLine(data.edition, 'edition', 'none') : isCompleted()"></CustomInput>

            <div class="doubleColumn">
              <CustomInput [type]="'date'"  [label]="'Fecha de inicio'"  [placeHolder]="'Fecha de inicio'" [(ngModel)]="data.startDate"
              name="Fecha de inicio" [fecha]="data.startDate" (date)="(isEdit)? updateLine($event, 'startDate', 'date') : setStartDate($event)"></CustomInput>
              <CustomInput [type]="'date'" [label]="'Fecha de fin'" [placeHolder]="'Fecha de fin'" [(ngModel)]="data.endDate"
              name="Fecha de fin" [fecha]="data.endDate" (date)="(isEdit)? updateLine($event, 'endDate', 'date') : setEndDate($event)"></CustomInput>
            </div>
            <div class="doubleColumn">
              <div class="subColumn">
                <label for="">Estado</label>
                <selector [options]="opcionesEstado" [(ngModel)]="data.state" (change)="(isEdit)? updateLine($event, 'state', 'none') : stateSelector(data.state)"></selector>
              </div>
              <CustomInput [type]="'text'"  [label]="'Horario'" [placeHolder]="'Horario'" [(ngModel)]="data.schedule"
              name="horario" (change)="(isEdit)? updateLine(data.schedule, 'schedule', 'none') : isCompleted()"></CustomInput>  
            </div>
          </div>
        </div>
        <div class="datosFiscales">
          <div class="header">
            <p class="title">
              <mat-icon>school</mat-icon>
              Profesorado
            </p>

          </div>
          <div class="body">
            <div class="doubleColumn">
              <div class="subColumn">
                <label for="">Director</label>
                <selector [options]="opcionesDirector" [(ngModel)]="data.directors"  (change)="isEdit ? updateLine($event, 'Director', 'directors') : teacherSelector(data.directors,'directors')"></selector>
              </div>
              <div class="subColumn">
                <label for="">Coordinador/es</label>
                <selector [options]="opcionesCoordinadores" [(ngModel)]="data.coordinators" (add)="isEdit ? updateLine($event, 'coordinators', 'coordinators') : ''" (remove)="isEdit ? updateLine($event, 'Coordinador', 'delete') : ''" (change)="teacherSelector(data.coordinators,'coordinators')"></selector>
              </div>

            </div>
            <div class="subColumn">
              <label for="">Profesor/es</label>
              <selector [options]="opcionesProfesores" [(ngModel)]="data.teachers" (add)="isEdit ? updateLine($event, 'teachers', 'teachers') : ''" (remove)="isEdit ? updateLine($event, 'Profesor', 'delete'): ''" (change)="teacherSelector(data.teachers,'teacher')"></selector>
            </div>

          </div>
        </div>


        <div class="datosFiscales" [ngStyle]="{'opacity': '0', 'height': '27vh'}"></div>

      </div>
    </div>
  </ng-container>
</fullContainer>