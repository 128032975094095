import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DetailViewInformationClass } from 'src/app/Shared/Classes/DetailViewInformationClass';
import { TSendIsEditing } from '../../../DetailView-baseContainer/DetailView-baseContainer.component';
import {
  iDate,
  iResultHttp,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import {
  DebouncerService,
  GeneralLoaderService,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import { StaticUtilitiesService as OldStaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iPromotionInfoForView } from 'src/app/Shared/Interfaces/Utils/iPromotionInfoForView';
import { ShiftService } from 'src/app/Services/Api/Shift.service';
import { filter, takeUntil } from 'rxjs';
import { DispositionService } from 'src/app/Services/Api/Disposition.service';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { AcademicService } from 'src/app/Services/Api/Academic.service';
import { ClassroomService } from 'src/app/Services/Api/Classroom.service';
import { DocumentService } from 'src/app/Services/Api/Document.service';
import { ModuleService } from 'src/app/Services/Api/Module.service';
import { C } from '@fullcalendar/core/internal-common';
import {
  IUserData,
  iUserData,
} from 'src/app/Shared/Interfaces/Utils/iUserData';
import { GetPermisionsService } from 'src/app/Services/Utils/GetPermisions.service';
import { RoleGlobalStateService } from 'src/app/Services/Utils/RoleGlobalState.service';

@Component({
  selector: 'detailView-Information',
  templateUrl: './DetailView-Information.component.html',
  styleUrls: ['./DetailView-Information.component.scss'],
})
export class DetailViewInformationComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Output() update: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input()
  get promotionInfo() {
    return this.extraClass._promotionInfo;
  }
  set promotionInfo(value: iPromotionInfoForView | null) {
    if (!value) return;
    this.extraClass._promotionInfo = value;
    this.spreadPromotionInfo(this.extraClass._promotionInfo);
  }
  test = 'Activa';
  @Input() promotionId: number = 0;

  @Input() isMaster: boolean = false;

  isEditingHeader: boolean = false;

  selectedProgrammes: any[] = [];

  extraClass: DetailViewInformationClass = new DetailViewInformationClass();

  constructor(
    private generalLoaderSE: GeneralLoaderService,
    private shiftSE: ShiftService,
    private dispositionSE: DispositionService,
    private teacherSE: TeacherService,
    private promotionSE: PromotionFormationService,
    private academicSE: AcademicService,
    private classroomSE: ClassroomService,
    private documentSE: DocumentService,
    private moduleSE: ModuleService,
    public roleGlobalStateSE: RoleGlobalStateService
  ) {
    super();
  }

  ngOnInit() {
    this.getShiftSelector();
    this.getDispositionSelector();
    this.getTeacherSelector();
    this.getStateSelector();
    this.getClassroomSelector();
  }

  /**
   * HANDLERS
   */

  handlerChangeSelectedProgrammes(event: any) {
    this.selectedProgrammes = event;
  }

  handelChangeEdit(event: TSendIsEditing) {
    this.extraClass.editableController = {
      ...this.extraClass.editableController,
      ...event,
    };
  }

  setIsEditingPrecios($event: { [key: string]: boolean }) {
    const boolean = Object.values($event)[0];
    this.extraClass.setEditableAmounts(boolean);
  }

  successCreateShiftHandler(res: iResultHttp) {
    let { data } = res;
    this.extraClass.shiftSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteShiftHandler(res: iResultHttp) {
    let { data } = res;
    this.extraClass.shiftSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetShiftSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.shiftSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successCreateDispositionHandler(res: iResultHttp) {
    let { data } = res;
    this.extraClass.disposicionSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteDispositionHandler(res: iResultHttp) {
    let { data } = res;
    this.extraClass.disposicionSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetDispositionSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.disposicionSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTeacherSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.directorSelectorOptions.items =
      this.extraClass.coordinatorSelectorOptions.items = data.map((teacher) => {
        return {
          id: teacher.id,
          name: `${teacher.user.name} ${teacher.user.surname}`,
        };
      });
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdatePromotionHandler(res: iResultHttp, key: string) {
    const { data } = res;
    if (key === 'hasCoordinators') {
      this.getPromotionCoordinators();
    }
    this.update.emit(true);
  }
  successGetStateSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.chipSelectorStatusOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetClassroomSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.aulaSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successCreateDocumentHandler(res: iResultHttp) {
    const { data } = res;
    this.getDocumentsPromotion();
    this.generalLoaderSE.removeFromLoaderAmount();
  }
  successDeleteDocumentHandler(res: iResultHttp) {
    const { data } = res;
    this.getDocumentsPromotion();
    this.generalLoaderSE.removeFromLoaderAmount();
  }
  successGetDocumentsPromotionHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.documents = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }
  successupdateModuleHandler(res: iResultHttp) {
    const { data } = res;
    this.getPromotionCoordinators();
  }

  successGetPromotionCoordinatorsHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.coordinators = data;
  }

  /**
   *
   * FUNCTIONALITIES
   */

  spreadPromotionInfo(promotionInfo: iPromotionInfoForView) {
    const {
      coordinators,
      director,
      documents,
      generalInfo,
      header,
      modules,
      promotionId,
      amounts,
    } = promotionInfo;
    this.extraClass.promotionId = promotionId;
    this.extraClass.coordinators = coordinators;
    this.extraClass.director = director;
    this.extraClass.documents = documents;
    this.extraClass.generalInfo = generalInfo;
    this.extraClass.header = header;
    this.extraClass.modules = modules;
    this.extraClass.amounts = amounts;

    if (promotionId) {
      this.getDocumentsPromotion();
      if (this.isMaster) this.getPromotionCoordinators();
    }
  }

  returnFormattedDate(date) {
    if (!date) return null;
    return new Date(date);
  }

  handleDates(event, object, key) {
    this.extraClass[object][key] = iDate
      .javascriptConvert(new Date(event))
      .toStringDate('JAP');

    this.prepareObjectToUpdatePromotion(this.extraClass[object][key], key);
  }

  getElementToAdd(event: any) {
    const objectToPass = { name: event };
    this.createShift(objectToPass);
  }
  getElementToAddDisposition(event: any) {
    const objectToPass = { name: event };
    this.createDisposition(objectToPass);
  }

  handleClickedEdit() {
    this.isEditingHeader = !this.isEditingHeader;
    this.extraClass.chipSelectorStatusOptions.readonly = !this.isEditingHeader;
  }

  getModelChange(event: any, key: string) {
    this.prepareObjectToUpdatePromotion(event, key);
  }
  getModelChangeDisposition(event: any) {}

  deleteElementFromSelector(event) {
    this.deleteShift([event]);
  }

  changeLabelSelector(event, object, key) {
    this.extraClass[object][key] = event;
  }

  returnVariableName(
    id: number | null,
    option:
      | 'shift'
      | 'classroomDisposition'
      | 'classroom'
      | 'director'
      | 'coordinator'
  ): string {
    if (!id) return '';

    let items: any;
    switch (option) {
      case 'shift':
        items = this.extraClass.shiftSelectorOptions.items;
        break;
      case 'classroomDisposition':
        items = this.extraClass.disposicionSelectorOptions.items;
        break;
      case 'classroom':
        items = this.extraClass.aulaSelectorOptions.items;
        break;
      case 'director':
        items = this.extraClass.directorSelectorOptions.items;
        break;
      case 'coordinator':
        items = this.extraClass.coordinatorSelectorOptions.items;
        break;
      default:
        return '';
    }

    return items.find((item) => item.id === id)?.name ?? '';
  }

  deleteElementFromSelectorDisposition(event) {
    this.deleteDisposition([event]);
  }

  trackByCoordinator(index, item) {
    return item.id;
  }

  addCoordinatorsToInfo() {
    this.prepareObjectToUpdatePromotion(true, 'hasCoordinators');
  }

  openDocument(document) {
    window.open(document.documentUrl, '_blank');
  }

  prepareCreateDocument(files) {
    const objectToPass = {
      document: files,
    };
    this.createDocument(objectToPass);
  }

  getFile(event) {
    if (this.extraClass.multiple) {
      const files = event.map((file) => {
        return {
          promotion: this.extraClass.promotionId,
          name: file.file.name,
          url: file.base64,
          typeOf: 'Edición',
        };
      });

      this.prepareCreateDocument(files);
    }
  }

  onCreateGetModules(event) {
    this.extraClass.modules = event;
    this.getPromotionCoordinators();
  }

  updatePromotionFunction(value, key) {
    DebouncerService.formDebouncer(
      () => this.prepareObjectToUpdatePromotion(value, key),
      key,
      value,
      1000
    );
  }

  prepareObjectToUpdatePromotion(value, key) {
    const objectToPass = {
      [key]: value,
      id: this.extraClass.promotionId,
    };

    this.updatePromotion(objectToPass, key);
  }

  prepareObjectToUpdateModule(value, moduleId, key) {
    const objectToPass = {
      [key]: value,
      id: moduleId,
    };
    this.updateModule(objectToPass);
  }

  checkIfselectedDay(day: number): boolean {
    return this.extraClass.generalInfo.days.includes(day);
  }

  toggleDay(day, canEdit) {
    if (!canEdit) return;
    if (this.checkIfselectedDay(day)) {
      this.extraClass.generalInfo.days =
        this.extraClass.generalInfo.days.filter((d) => d !== day);
    } else {
      this.extraClass.generalInfo.days.push(day);
    }
    this.prepareObjectToUpdatePromotion(
      this.extraClass.generalInfo.days,
      'days'
    );
  }

  /**
   * API CALLS
   */

  getShiftSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.shiftSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetShiftSelectorHandler(res),
        });
      });
  }

  createShift(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.shiftSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successCreateShiftHandler(res),
        });
      });
  }

  deleteShift(ids: number[]) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.shiftSE.delete(behaviorSubject, ids);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteShiftHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getDispositionSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.dispositionSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetDispositionSelectorHandler(res),
        });
      });
  }

  createDisposition(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.dispositionSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successCreateDispositionHandler(res),
        });
      });
  }

  deleteDisposition(ids: number[]) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.dispositionSE.delete(behaviorSubject, ids);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteDispositionHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getTeacherSelector() {
    // this.generalLoaderSE.addToLoaderAmount();
    this.teacherSE.getTeachersSelector();
    this.teacherSE
      .getResult()
      .pipe(
        takeUntil(this._unsubInd),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.successGetTeacherSelectorHandler(res);
        this._unsubInd.next('');
      });
    this.teacherSE
      .getResultError()
      .pipe(
        takeUntil(this._unsub),
        filter((res) => res)
      )
      .subscribe((res) => {
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        this._unsub.next('');
      });
  }

  getStateSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.academicSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetStateSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getClassroomSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.classroomSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetClassroomSelectorHandler(res),
        });
      });
  }

  updatePromotion(objectToPass, key) {
    this.promotionSE.update(objectToPass);
    this.promotionSE
      .getResultUpdate()
      .pipe(
        takeUntil(this._unsubInd2),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.successUpdatePromotionHandler(res, key);
        this._unsubInd2.next('');
      });
    this.promotionSE
      .getResultUpdateError()
      .pipe(
        takeUntil(this._unsub),
        filter((res) => res)
      )
      .subscribe((res) => {
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  updateModule(objectToPass) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.moduleSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successupdateModuleHandler(res),
        });
      });
  }

  createDocument(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    this.documentSE.create(objectToPass);
    this.documentSE
      .getResultUpdate()
      .pipe(
        takeUntil(this._unsubInd2),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.successCreateDocumentHandler(res);
        this._unsubInd2.next('');
      });
    this.documentSE
      .getResultUpdateError()
      .pipe(
        takeUntil(this._unsub),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  deleteDocument(ids: number[]) {
    this.generalLoaderSE.addToLoaderAmount();
    this.documentSE.delete(ids);
    this.documentSE
      .getResultDelete()
      .pipe(
        takeUntil(this._unsubInd2),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.successDeleteDocumentHandler(res);
        this._unsubInd2.next('');
      });
    this.documentSE
      .getResultDeleteError()
      .pipe(
        takeUntil(this._unsub),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getDocumentsPromotion() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.getPromotionDocuments(
      behaviorSubject,
      this.extraClass.promotionId
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetDocumentsPromotionHandler(res),
        });
      });
  }

  getPromotionCoordinators() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.getPromotionCoordinators(
      behaviorSubject,
      this.extraClass.promotionId
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetPromotionCoordinatorsHandler(res),
        });
      });
  }
}
