import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Provider } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'LL',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'LL',
  },
};

@Component({
  selector: 'CustomDatepicker',
  templateUrl: './CustomDatepicker.component.html',
  styleUrls: ['./CustomDatepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CustomDatepickerComponent implements OnInit {
  date:FormControl = new FormControl(moment());

  @Output() dateChange:EventEmitter<Date> = new EventEmitter<Date>();

  @Input() set inputDate(date: string | undefined) {
    this.date = new FormControl(date);
  }
  constructor() {}

  ngOnInit() {}

  openDatePicker(picker: MatDatepicker<Date>) {
    picker.open();
  }

  dateChangeEmit(event:MatDatepickerInputEvent<Date>) {
    const date:Date | null = event.value;
    if(date){
      this.dateChange.emit(date);
    }
  }
}
