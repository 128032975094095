<div class="tableContainer">
  <ng-container *ngIf="dataSource.length === 0">
    <p class="noItems">{{ noItemsMessage }}</p>
  </ng-container>

  <ng-container *ngIf="dataSource.length > 0">
    <table class="mainTable" [class.mainTable--slim]="slim">
      <thead class="thead">
        <tr class="tr" [ngStyle]="{ 'grid-template-columns': tableColumns }">
          <ng-container *ngIf="selectMultiple">
            <th>
              <mat-checkbox [checked]="allselected" (change)="handlerClickSelectAll($event.checked)"></mat-checkbox>
            </th>
          </ng-container>
          <ng-container *ngFor="
              let headerItem of headerData;
              index as index;
              trackBy: trackByHeader
            ">
            <th class="th" [ngStyle]="{
                justifyContent: getAlignment(headerItem),
                minWidth: headerItem.width || '250px',
                maxWidth: headerItem.width || '250px'
              }" [class]="headerItem.variant + 'Header'">
              {{ headerItem.display }}
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody class="tbody">
        <ng-container class="tr" *ngFor="let item of dataSource; index as i; trackBy: trackByLines">
          <tr class="tr" (click)="handleClickRow(item)" [ngStyle]="{ 'grid-template-columns': tableColumns }">
            <ng-container *ngIf="selectMultiple">
              <td>
                <mat-checkbox [checked]="contains(item)" (change)="handlerSelectRow(i)"></mat-checkbox>
              </td>
            </ng-container>
            <ng-container *ngFor="
                let headerItem of headerData;
                index as index;
                trackBy: trackByHeader
              ">
              <td class="td" [ngStyle]="{
                  justifyContent: getAlignment(headerItem),
                  minWidth: headerItem.width || '250px',
                  maxWidth: headerItem.width || '250px'
                }">
                <!-- STANDART TYPE -->
                <ng-container *ngIf="headerItem.variant === 'standard'">
                  <ng-container *ngIf="headerItem?.date">

                    <ng-container *ngIf="item[headerItem.key]">
                      <p [ngClass]="headerItem?.className ?? ''"
                        [title]="(item[headerItem.key]?? '-' | date: headerItem.date?.pipeTemplate) + (headerItem.euros ? '€' : '')">
                        {{
                        (item[headerItem.key] ?? "-"
                        | date : headerItem.date?.pipeTemplate) +
                        (headerItem.euros
                        ? "
                        €"
                        : "")
                        }}
                      </p>
                    </ng-container>
                    <ng-container *ngIf="!item[headerItem.key]">
                      <p [ngClass]="headerItem?.className ?? ''" [title]=" '-' + (headerItem.euros ? ' €' : '')">
                        {{ "-" + (headerItem.euros ? " €" : "") }}
                      </p>
                    </ng-container>

                  </ng-container>

                  <ng-container *ngIf="!headerItem?.date">
                    <p [ngClass]="headerItem?.className ?? ''"
                      [title]="(item[headerItem.key]?? '-') + (headerItem.euros ? ' €' : '')">
                      {{(item[headerItem.key]?? '-') + (headerItem.euros ? ' €' : '')}}
                    </p>
                  </ng-container>
                </ng-container>

                <!-- BOLD TYPE -->
                <ng-container *ngIf="headerItem.variant === 'bold'">
                  <ng-container *ngIf="headerItem?.date">
                    <p [ngClass]="headerItem?.className ?? ''">
                      <ng-container *ngIf="item[headerItem.key]">
                        <b
                          [title]="(item[headerItem.key]?? '-' | date: headerItem.date?.pipeTemplate) + (headerItem.euros ? '€' : '')">
                          {{(item[headerItem.key]?? "-" | date: headerItem.date?.pipeTemplate) + (headerItem.euros ? '
                          €'
                          : '')}}
                        </b>
                      </ng-container>
                      <ng-container *ngIf="!item[headerItem.key]">
                        <b [title]="'-' + (headerItem.euros ? ' €' : '')">
                          {{"-" + (headerItem.euros ? ' €'
                          : '')}}
                        </b>
                      </ng-container>
                    </p>
                  </ng-container>
                  <ng-container *ngIf="!headerItem?.date">
                    <p [ngClass]="headerItem?.className ?? ''"
                      [title]="(item[headerItem.key]?? '-') + (headerItem.euros ? ' €' : '')">
                      <b>
                        {{
                        (item[headerItem.key] ?? "-") +
                        (headerItem.euros ? " €" : "")
                        }}
                      </b>
                    </p>
                  </ng-container>
                </ng-container>

                <!-- TOOLTIP TYPE -->
                <ng-container *ngIf="headerItem.variant === 'tooltip'">
                  <ng-container *ngIf="headerItem?.date">
                    <span [ngClass]="
                        (headerItem?.className ?? '') +
                        (headerItem.tooltipItemsLength(item) > 1
                          ? 'tooltipTable'
                          : '')
                      ">
                      <ng-container *ngIf="headerItem.itemToDisplay(item)">
                        <p>
                          {{
                          (headerItem.itemToDisplay(item) ?? "-"
                          | date : headerItem.date?.pipeTemplate) +
                          (headerItem.euros
                          ? "
                          €"
                          : "")
                          }}
                        </p>
                      </ng-container>
                      <ng-container *ngIf="!headerItem.itemToDisplay(item)">
                        {{ "-" + (headerItem.euros ? " €" : "") }}
                      </ng-container>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="!headerItem?.date">
                    <span [ngClass]="
                        (headerItem?.className ?? '') +
                        (headerItem.tooltipItemsLength(item) > 1
                          ? ' tooltipTable'
                          : '') +
                        (i > 5 && headerItem.tooltipItemsLength(item) > 1
                          ? ' above'
                          : '')
                      ">
                      <p>
                        {{
                        (headerItem.itemToDisplay(item) ?? "-") +
                        (headerItem.euros ? " €" : "")
                        }}
                      </p>

                      <ng-container *ngIf="headerItem.tooltipItemsLength(item) > 1">
                        <div class="tooltip">
                          <ng-container *ngFor="
                              let tooltipItem of headerItem.tooltipItems(item)
                            ">
                            <p class="tooltip__item">
                              {{ tooltipItem }}
                            </p>
                          </ng-container>
                        </div>
                      </ng-container>
                    </span>
                  </ng-container>
                </ng-container>

                <!-- BOX TYPE -->
                <ng-container *ngIf="headerItem.variant === 'box'">
                  <table-item-box [value]="item[headerItem.key]" [type]="
                      headerItem?.classNameGenerator(
                        item[headerItem?.key ?? ''] ?? '',
                        i,
                        item
                      ) ?? ''
                    "></table-item-box>
                </ng-container>

                <!-- CHIP TYPE -->
                <ng-container *ngIf="headerItem.variant === 'chip'">
                  <table-item-chip [value]="item[headerItem.key]" [type]="
                      headerItem?.classNameGenerator(
                        item[headerItem?.key ?? 0],
                        i,
                        item
                      ) ?? ''
                    " [className]="headerItem.className ?? ''" [date]="headerItem.date ?? null"
                    [border]="headerItem.border ?? false"></table-item-chip>
                </ng-container>

                <!-- COMPONENT TYPE -->

                <ng-container *ngIf="headerItem.variant === 'component'">
                  <div class="componentDiv" [ngClass]="headerItem.cellClassName ?? ''" appAnchor></div>
                </ng-container>

                <!-- BUTTON -->
                <ng-container *ngIf="headerItem.variant === 'button'">
                  <table-item-button [icon]="headerItem.icon" [buttonStyle]="
                      headerItem.buttonStyle
                        ? headerItem.buttonStyle
                        : 'principalButton'
                    " [text]="headerItem.text" [item]="item" [index]="i" [action]="headerItem.action"
                    [displayGenerator]="headerItem.displayGenerator"
                    (click)="handleClickBtn(item, headerItem.action)"></table-item-button>
                </ng-container>

                <!-- Dropdown -->
                <ng-container *ngIf="headerItem.variant === 'dropdown'">
                  <options-menu [options]="headerItem.options"></options-menu>
                </ng-container>

                <!-- ICON -->
                <ng-container *ngIf="headerItem.variant === 'icons'">
                  <div class="iconContainer" #iconContainer>
                    <ng-container *ngFor="let icon of headerItem.icons">
                      <span class="roundedIcon">
                        <ng-container *ngIf="icon.matIconName">
                          <mat-icon (click)="
                              $event.stopImmediatePropagation();
                              executeIconAction(icon.action, item)
                            ">{{ icon.matIconName }}</mat-icon>
                        </ng-container>

                        <ng-container *ngIf="icon.image">
                          <img src="{{ icon.image }}" (click)="
                              $event.stopImmediatePropagation();
                              executeIconAction(icon.action, item)
                            " />
                        </ng-container>
                      </span>
                    </ng-container>
                  </div>
                </ng-container>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-container>
</div>