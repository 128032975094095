import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iCourse, iCourseFilter } from 'src/app/Shared/Interfaces/iCourse';

@Injectable({
  providedIn: 'root'
})
export class CourseService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();
  
  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: iCourseFilter){
    this.http.get(this.urlApi + '/api/course' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedCourse = iStaticUtilities.normalizeNamesCollection(data.data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:normalizedCourse,total:data.total}});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAll, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getSingle(id: number){
    this.http.get(this.urlApi + '/api/course/'+id, this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedCourse = data ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedCourse});
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getSingle, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  getSelector(behaviorSubject:BehaviorSubject<any>){
    this.http
    .get(
      this.urlApi + '/api/courses/selector',
      this.getHeader()
    )
    .subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: data,
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.getSelector,
          args: [behaviorSubject],
        });
      },
    });

  }

  create(){
    this.http.post(this.urlApi+"/api/course", null, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  update(course:iCourse){
    this.http.put(this.urlApi+"/api/course/"+course.id, course, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [course] });
        this.checkStatusError(error);
      },
    });
  }

  delete(ids: Array<number|string>){
    let idsObjs = this.convertArrayObject(ids, "ids");
    this.http.delete(this.urlApi+"/api/course", this.sendBodyOptions(idsObjs) ).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [ids] });
        this.checkStatusError(error);
      },
    });
  }

}
