import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { iResultHttp, iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { PersonalizarColumnasPopupComponent } from 'src/app/Popups/PersonalizarColumnas-Popup/PersonalizarColumnas-Popup.component';
import { PreferencesService } from 'src/app/Services/Api/Preferences.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'gestionUsuariosProfesoresTabla',
  templateUrl: './GestionUsuarios-Profesores-Tabla.component.html',
  styleUrls: ['./GestionUsuarios-Profesores-Tabla.component.scss']
})
export class GestionUsuariosProfesoresTablaComponent extends iUnsubscribeDestroy implements OnInit {

  profesorSelected: Array<any> = [];
  allSelected: boolean = false;
  preferences: any = [];
  filtros: Array<any> = [
    { position: 1, label: "Nombre", realFilter: "nombre", checked: true },
    { position: 2, realFilter: "email", label: "Correo electrónico", checked: true },
    { position: 3, realFilter: "telefono", label: "Teléfono de contacto", checked: true },
    { position: 4, realFilter: "etiquetas", label: "Etiquetas", checked: true },
    { position: 5, realFilter: "estado", label: "Estado", checked: true },
  ]
  @Input() isDataLoaded: boolean = false;
  @Input() profesores: Array<any> = [];
  @Input() filters: any = {};
  @Input() total: number = 0;
  @Input() navigateActive: boolean = true;
  @Input() changePage: boolean = false;
  @Input() openPrefernecesPopup: boolean = false;
  @Input() downloadExcelPopup: boolean = false;
  @Output() selectedProfesores = new EventEmitter<any>();

  displayedColumns: any[] = [
    { displayName: "Nombre", property: 'name', checked: true },
    { displayName: "Email", property: 'mail', checked: true },
    { displayName: "Teléfono", property: 'phone', checked: true },
    { displayName: "DNI", property: 'dni', checked: true },
    { displayName: "Tipo de profesor", property: 'type', checked: true },
    { displayName: "Empresa", property: 'company', checked: true },
    { displayName: "Cargo", property: 'jobPosition', checked: true },
    { displayName: "Situación laboral", property: 'employmentStatus', checked: true },
    { displayName: "LinkedIn", property: 'linkedIn', checked: true },
    { displayName: "Instagram", property: 'instagram', checked: true },
    { displayName: "Facebook", property: 'facebook', checked: true },
    { displayName: "TikTok", property: 'tiktok', checked: true },
    { displayName: "X", property: 'x', checked: true },
    { displayName: "Otras", property: 'otras', checked: true },
    { displayName: "Tipo de retención", property: 'retentionType', checked: true },
  ];

  dataSource: Array<any> = [];
  disabledColumns: string[] = []

  constructor(private popupSE: PopupService, private staticUtilitiesSE: StaticUtilitiesService, private preferencesSE: PreferencesService) {
    super()
  }

  ngOnInit() {
    // this.getAllSettings()
  }
  ngOnChanges(change: SimpleChange) {
    if (change['changePage'] != undefined) {
      this.profesorSelected = [];
      this.allSelected = false;
      this.selectedProfesores.emit(this.profesorSelected);
    }
    if (change['openPrefernecesPopup'] != undefined) {
      if (change['openPrefernecesPopup'].currentValue) {
        this.personalizarColumnas();
      }
    }
    if (change['downloadExcelPopup'] != undefined) {
      if (change['downloadExcelPopup'].currentValue) {
        this.exportexcel();
      }
    }
  }
  /**
   * FUNCTIONALITIES
   */
  addSelected(profesor) {
    !this.profesorSelected.includes(profesor) ? this.profesorSelected.push(profesor) : this.profesorSelected.splice(this.profesorSelected.indexOf(profesor), 1);
    this.profesorSelected.length == this.profesores.length ? this.allSelected = true : this.allSelected = false;
    this.selectedProfesores.emit(this.profesorSelected);
  }
  selecteAll(event) {
    if (event.checked) {
      this.profesorSelected = [...this.profesores]
      this.allSelected = true;
    } else {
      this.profesorSelected = [];
      this.allSelected = false;
    }
    this.selectedProfesores.emit(this.profesorSelected);
  }
  personalizarColumnas() {

    this.popupSE.openPopup(PersonalizarColumnasPopupComponent, { filtros: (this.preferences.length > 0) ? this.preferences.filter(e => e.displayName != 'Nombre') : this.displayedColumns.filter(e => e.displayName != 'Nombre') })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res == null) { return; }
      let columnsToShow: any = []
      columnsToShow.push({ displayName: 'Nombre', property: 'name' });
      res.returnValue.forEach(element => {
        if (element.checked) {

          columnsToShow.push({ displayName: element.displayName, property: element.property });
        }
      });
      this.displayedColumns = columnsToShow;
      let dataToAdd = {
        type: 'Teacher',
        options: res.returnValue.map((element: any, index) => { return { property: element.property, checked: element?.checked ?? false, displayName: element.displayName } })
      }
      dataToAdd.options.unshift({ property: 'name', checked: true, displayName: 'Nombre' })
      this.setPreferences(dataToAdd);
      this._unsubInd.next("");
    })
  }
  checkUncheckedColumns(res) {
    res.map(element => {
      if (!element.checked) {
        Array.from(document.querySelectorAll(".mat-column-" + element.realFilter)).map(column => {
          (column as HTMLElement).style.display = "none"
        })
      } else {
        Array.from(document.querySelectorAll(".mat-column-" + element.realFilter)).map(column => {
          (column as HTMLElement).style.display = "revert"
        })
      }
    })
  }

  changeOrder(event) { }

  goTo(event) {
    this.navigateActive ? this.staticUtilitiesSE.goTo("/profesores/mis-profesores/ficha-profesor", slideNavigationTypes.slideToTop, { profesor: event.id }) : null;
  }
  // Descargar excel

  exportexcel(): void {
    let table = document.createElement("table");
    table.append((document.querySelector("table thead") as HTMLElement).cloneNode(true));
    table.querySelectorAll("thead th mat-icon").forEach((element) => {
      element.remove();
    });
    table.querySelector("thead th:first-of-type")?.remove();
    let rows = Array.from(document.querySelectorAll("table tbody tr "));
    let filteredRows = rows.filter((row) => {
      if (row.className === 'loaderLine') return null;
      return row.querySelector('mat-checkbox')!.getAttribute('ng-reflect-checked') === "true";
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append((document.querySelector("table tbody") as HTMLElement).cloneNode(true));
      table.querySelectorAll("tbody tr td:first-of-type").forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Profesores.xlsx');
  }

  /**
   * API CALLS
   */

  setPreferences(preferences) {

    this.preferencesSE.setStudentSettings(preferences);
    this.preferencesSE.getResultUpdate().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (!res) return;
      StaticUtilitiesService.showFeedback("Se han modificado las preferencias correctamente");
      this.getAllSettings();
      this._unsubInd3.next('');
    })
    this.preferencesSE.getResultUpdateError().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (!res) return;
      StaticUtilitiesService.showError("No se han podido modificar las preferencias");
      this._unsubInd3.next('');
    })
  }

  getAllSettings() {
    this.preferencesSE.getAllSettings({ type: 'Teacher' });
    this.preferencesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) return;
      let { data } = res;

      this.preferences = data.options;
      this.displayedColumns = data.options.filter(e => e.checked);
      this._unsubInd.next('');
    });
    this.preferencesSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

}
