import { Component, OnInit } from '@angular/core';
import {
  GeneralLoaderService,
  PopupService,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import { iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { AddCertificatePopupComponent } from 'src/app/Popups/AddPopup/AddCertificate-Popup/AddCertificate-Popup.component';
import { DiplomaService } from 'src/app/Services/Api/Diploma.service';
import { DiplomaTemplateService } from 'src/app/Services/Api/DiplomaTemplate.service';

@Component({
  selector: 'Configuracion-Certificados',
  templateUrl: './Configuracion-Certificados.component.html',
  styleUrls: ['./Configuracion-Certificados.component.scss'],
})
export class ConfiguracionCertificadosComponent implements OnInit {
  isCertificateListLoaded: boolean = false;

  displayedColumns: any[] = [{ displayName: 'Nombre', property: 'name' }];

  certificateList: any[] = [];

  constructor(
    private diplomaSE: DiplomaService,
    private generalLoaderSE: GeneralLoaderService,
    private popupSE: PopupService,
    private diplomaTemplateSE: DiplomaTemplateService
  ) {}

  ngOnInit() {
    this.getAllCertificates();
  }

  /**
   *
   * HANDLERS
   */

  successGetAllCertificatesHandler(res: iResultHttp) {
    const { data } = res;
    this.certificateList = data;
    this.isCertificateListLoaded = true;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateCertificateHandler(res: iResultHttp) {
    const { data } = res;
    this.getAllCertificates();
    StaticUtilitiesService.showFeedback('Certificado actualizado correctamente');
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   *
   * FUNCTIONALITY
   */

  openCertificatePopup(certificate) {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(AddCertificatePopupComponent, { ...certificate });
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res) => {
        if(res == undefined) return;
        const returnValue = res?.returnValue;
        if (returnValue) {
          const objectToPass = this.createFormData(returnValue, certificate);
          this.updateCertificate(objectToPass);
        }
        subject.next('');
        subject.complete();
      });
  }

  createFormData(returnValue, certificate) {
    const formData = new FormData();
    const files = returnValue.map((file) => file.file);
    formData.append('docx', files[0]);
    formData.append('type', certificate.type);
    return formData;
  }

  /**
   * API CALLS
   */

  getAllCertificates() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.diplomaSE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetAllCertificatesHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateCertificate(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.diplomaTemplateSE.upload(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateCertificateHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
