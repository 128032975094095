import { Subject } from "rxjs";
import { ITutoringSession, iTutoringSession } from "../Interfaces/Utils/iTutoringSession";

export class GestionUsuariosProfesoresDetallesTutoriasClass {
    _dataTeacher:any = null;
    teacherFullName: string = '';

    tutorings: iTutoringSession[] = [ITutoringSession.getEmptyObject()];

    isDataLoaded: boolean = false;
  
    generalDataLoaderSubject: Subject<boolean> = new Subject<boolean>();
  
}
