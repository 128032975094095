<div class="view">
  <main-table
    [dataSource]="allCursos"
    [headerData]="tableHead"
    [isDataLoaded]="areReadyAllCursos"
    (clickOnRow)="handleClickRow($event)"
  >
  </main-table>

  <ng-container
    *ngFor="
      let item of allCursos
        | paginate
          : {
              itemsPerPage: filterAllCursos.num_devoluciones,
              currentPage: filterAllCursos.num_pagina,
              totalItems: totalCursos
            }
    "
  ></ng-container>

  <pagination-controls
    [autoHide]="true"
    previousLabel="Anterior"
    nextLabel="Siguiente"
    (pageChange)="pageChangeEmmiter($event)"
  ></pagination-controls>
</div>
