<fullContainer>
  <ng-container>
    <header></header>
    <div class="subHeader">
      <toggleSubmenu
        class="secondary"
        [elements]="['Mostrar fases', 'Mostrar listado']"
        [(ngModel)]="optionSubmenuSelected"
        (onValueChange)="onToggleChange($event)"
        id="matriculaciones-masters"
      ></toggleSubmenu>
      <div class="buttonContainer">
        <btn
          classList="principalButton"
          (click)="downloadExcel()"
          *ngIf="optionSubmenuSelected === 1"
        >
          <ng-container> Descargar listado en .XLS </ng-container>
        </btn>
        <btn
          classList="principalButton"
          (click)="openAddStudentPopup('master')"
          *ngIf="checkRolesAndPermissions()"
        >
          <ng-container> Añadir alumno manualmente </ng-container>
        </btn>
      </div>
    </div>
    <div class="filters">
      <customSearcher
        ngDefaultControl
        [placeHolder]="placeholdersArray[optionSubmenuSelected]"
        [(ngModel)]="filter"
        (change)="getFilter(filter, 'genericFilter')"
      ></customSearcher>
      <div
        (click)="displayFilters()"
        class="filterButton"
        *ngIf="!isFilterDisplayed && optionSubmenuSelected === 1"
      >
        <span>Filtrar matriculaciones</span>
        <mat-icon>filter_list</mat-icon>
      </div>
      <div
        (click)="displayFilters()"
        class="filterButton active"
        *ngIf="isFilterDisplayed && optionSubmenuSelected === 1"
      >
        <span>Filtrar matriculaciones</span>
        <mat-icon>clear</mat-icon>
      </div>
    </div>
    <div class="filterContent" *ngIf="isFilterDisplayed">
      <div class="threeColumnSelectores">
        <selector
          [options]="selectorFormationOptions"
          (change)="getFormationAndPromotion($event, 'formation')"
        ></selector>
        <selector
          [options]="selectorPromotionOptions"
          (change)="getFilter($event, 'promotion')"
          *ngIf="objectToPass.id"
        ></selector>
        <selector
          [options]="selectorPhaseOptions"
          (change)="getFilter($event, 'phase')"
        ></selector>
      </div>
    </div>

    <matriculacionesPipeline
      [rolesAndPermissions]="rolesAndPermissions"
      (refresh)="refreshData($event)"
      [crmPipeDrive]="crmPipeDrive"
      [dataPhasesEnrollment]="dataPhasesEnrollment"
      *ngIf="optionSubmenuSelected == 0"
    ></matriculacionesPipeline>
    <matriculacionesTabla
      [rolesAndPermissions]="rolesAndPermissions"
      (refresh)="refreshTabla($event)"
      [downloadExcelFile]="downloadExcelFile"
      [total]="total"
      [filters]="filters"
      [matriculaciones]="matriculacionesLista"
      *ngIf="optionSubmenuSelected == 1"
    ></matriculacionesTabla>
    <pagination-controls
      [autoHide]="true"
      previousLabel="Anterior"
      nextLabel="Siguiente"
      *ngIf="optionSubmenuSelected == 1"
      (pageChange)="pageChange($event)"
    ></pagination-controls>
  </ng-container>
</fullContainer>
