<div class="popupGeneralContainer">
  <div class="header">
    <p>Cambiar promoción</p>
  </div>
  <div class="body">

    <div class="fullSection">
      <div class="subColumnLong">
        <label for="selector">Formación en la que se va a matricular</label>
        <selector [options]="selectorOptionsformation" [(ngModel)]="selectorFormation" (change)="detectHowManyFormationsSelected(selectorFormation)"></selector>
      </div>
    
      <div class="selectoresMatricula" *ngIf="arraySelectorPromotion.length > 0 ">
        <div class="subColumnLong" *ngFor="let promotion of arraySelectorPromotion ; let index = index ">
          <label for="selector">Seleccionar edición</label>
          <selector [(ngModel)]="promotion.selector" [options]="selectorOptionsPromotionArray![index]" (change)="getSelectorByPromotionId(promotion.selector);emitData()"></selector> 
          <selector [options]="opcionesSelectorModalidad" [(ngModel)]="attendanceModality" (change)="emitData()"></selector>
    
        </div>
      </div>
      <div class="tipoDeMatriculacion">
        <div class="subColumnLong">
          <label for="selector">Tipo de matriculación</label>  
        <selector [options]="optionsSelectorTipoMatricula" [(ngModel)]="enrollmentOption" (change)="emitData()"></selector>
        </div>
      </div>
      <div class="tipoDeMatriculacion">
        <div class="subColumnLong">
          <mat-checkbox (change)="detectSpecialConditions($event)">Condiciones especiales</mat-checkbox>
          <CustomInput *ngIf="specialConditions" [label]="'Condiciones especiales'" [placeHolder]="'Introduce las condiciones especiales para el alumno'" [(ngModel)]="conditionsText" (change)="emitData()"></CustomInput>
        </div>
      </div>
    </div>
    
  </div>
  <div class="footer">
    <btn classList="violetButton" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="principalButton" (click)="updateEnrollment()">
      <ng-container>
        Cambiar promoción
      </ng-container>
    </btn>
</div>