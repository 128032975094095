import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  GeneralLoaderService,
  iResultHttp,
  iUnsubscribeDestroy,
  PopupService,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import { Subject, filter, takeUntil } from 'rxjs';
import { AddTeacherBillPopupComponent } from 'src/app/Popups/AddPopup/AddTeacherBill-Popup/AddTeacherBill-Popup.component';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { SubirArchivoPopupComponent } from 'src/app/Popups/SubirArchivo-Popup/SubirArchivo-Popup.component';
import { SubirFacturaPopupComponent } from 'src/app/Popups/SubirFactura-Popup/SubirFactura-Popup.component';
import { BillService } from 'src/app/Services/Api/Bill.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService as OldStaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { GestionUsuariosProfesoresDetallesFacturasClass } from 'src/app/Shared/Classes/GestionUsuariosProfesoresDetallesFacturasClass';

@Component({
  selector: 'facturasProfesor',
  templateUrl: './GestionUsuarios-Profesores-Detalles-Facturas.component.html',
  styleUrls: ['./GestionUsuarios-Profesores-Detalles-Facturas.component.scss'],
})
export class GestionUsuariosProfesoresDetallesFacturasComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input() get dataTeacher() {
    return this.extraClass._dataTeacher;
  }
  set dataTeacher(value) {
    this.extraClass._dataTeacher = value;
    this.extraClass.teacherFullName = `${value.user.name} ${value.user.surname}`;
    if (value && value.id) this.getAllBillsByTeacher();
  }
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  extraClass: GestionUsuariosProfesoresDetallesFacturasClass =
    new GestionUsuariosProfesoresDetallesFacturasClass(
      this.openAddBillPopup.bind(this),
      this.openConfirmationPopup.bind(this)
    );

  facturas: Array<any> = StaticDataHandlerService.getFacturas();

  constructor(
    private popupSE: PopupService,
    private billSE: BillService,
    private generalLoaderSE: GeneralLoaderService
  ) {
    super();
  }

  ngOnInit() {
    this.rolesAndPermissions =
      OldStaticUtilitiesService.getUserPermissionsAndRoles();
  }

  /**
   * HANDLERS
   */

  successCreateBillTeacherHandler(res: iResultHttp) {
    const { data } = res;
    this.getAllBillsByTeacher();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */
  uploadDocumento() {
    this.popupSE.openPopup(SubirArchivoPopupComponent, {
      accion: 'Subir factura al profesor',
      formatos: ['application/pdf'],
      size: 10485760,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res == null) {
          return;
        }

        StaticUtilitiesService.showFeedback('Factura subida correctamente');
        this._unsubInd.next('');
      });
  }
  openFile(url) {
    window.open(url, '_blank');
  }
  checkRolesAndPermissions() {
    return (
      this.rolesAndPermissions.roles.includes('ROLE_ADMIN') ||
      this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') ||
      this.rolesAndPermissions.permissions.includes(2)
    );
  }

  onPageChange(page) {
    this.extraClass.filtroBill.num_pagina = page;
    this.getAllBillsByTeacher();
  }

  pageChange(event) {
    this.extraClass.filtroBill.num_pagina = event;
    this.getAllBillsByTeacher();
  }

  sendTypeToFilter(type) {
    this.extraClass.filtroBill.state = type;
    this.getAllBillsByTeacher();
  }

  getSearch(event) {
    const value = event.target.value;
    this.extraClass.filtroBill.genericFilter = value;
    if (!value) {
      delete this.extraClass.filtroBill.genericFilter;
    }
    this.getAllBillsByTeacher();
  }

  openAddBillPopup(edit = false, billId: number | null = null) {
    const subject = new Subject();
    this.popupSE.openPopup(AddTeacherBillPopupComponent, {
      data: {
        teacherName: this.extraClass.teacherFullName,
        teacherId: this.extraClass._dataTeacher.id,
        edit,
        billId,
      },
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res) => {
        const returnValue = res?.returnValue;
        if (returnValue) {
          if (edit) {
            this.updateBill(returnValue);
          } else {
            this.createBillTeacher(returnValue);
          }
        }
        subject.next('');
      });
  }

  openConfirmationPopup(id: number) {
    const subject = new Subject();
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      accion: 'Eliminar',
      elemento: 'la factura',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res) => {
        if (res?.returnValue) {
          this.deleteBill(id);
        }
        subject.next('');
      });
  }

  /**
   * API CALLS
   */

  getAllBillsByTeacher() {
    this.generalLoaderSE.addToLoaderAmount();
    this.extraClass.isDataLoaded = false;
    this.billSE.getAllByTeacher(
      this.extraClass._dataTeacher.id,
      this.extraClass.filtroBill
    );
    this.billSE
      .getResult()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;

        this.extraClass.teacherBills = data;
        this.extraClass.total = total;
        this.extraClass.isDataLoaded = true;
        this.generalLoaderSE.removeFromLoaderAmount();
        this._unsubInd4.next('');
      });
    this.billSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  createBillTeacher(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.billSE.createBillTeacher(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateBillTeacherHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateBill(objectToPass) {
    const subject = new Subject();
    this.generalLoaderSE.addToLoaderAmount();
    this.billSE.update(objectToPass);
    this.billSE
      .getResultUpdate()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.getAllBillsByTeacher();
        this.generalLoaderSE.removeFromLoaderAmount();
        subject.next('');
      });
    this.billSE
      .getResultUpdateError()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        subject.next('');
      });
  }

  deleteBill(id: number) {
    const subject = new Subject();
    this.generalLoaderSE.addToLoaderAmount();
    this.billSE.deleteBillTeacher(id);
    this.billSE
      .getResultDelete()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.getAllBillsByTeacher();
        this.generalLoaderSE.removeFromLoaderAmount();
        subject.next('');
      });
    this.billSE
      .getResultDeleteError()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        subject.next('');
      });
  }
}
