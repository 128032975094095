

<div class="fullView" skeleton [isDataLoaded]="areReadyAllDobleTitulacion">

  <p class="noData" *ngIf="allDobleTitulacion.length === 0">No hay elementos para mostrar</p>

  <!-- Tarjetas -->

  <div class="card" *ngFor="let dobleTitulacion of allDobleTitulacion | paginate:{
    itemsPerPage: filterAllDobleTitulacion.num_devoluciones,
    currentPage: filterAllDobleTitulacion.num_pagina,
    totalItems: totalDobleTitulacion
  }" (click)="openNuevaTitulacion(dobleTitulacion)">
    <div class="spaceBetween">
      <p class="title">{{dobleTitulacion.name}}</p>
      <status-pill [active]="dobleTitulacion.activeLabel === 'Activa' " ></status-pill>
    </div>
    <div class="mastersBlock">
      <p>{{dobleTitulacion.formations ?? '-'}}</p>
    </div>
    <div class="price">
      <p>{{dobleTitulacion.price ?? 0 | currency:'EUR'}}</p>
    </div>
  </div>
</div>
<pagination-controls [autoHide]="true" previousLabel="Anterior"  nextLabel="Siguiente" (pageChange)="pageChangeEmmiter($event)" *ngIf="allDobleTitulacion.length > 0"></pagination-controls>