import { headerData } from '../Components/main-table/main-table.component';
import {
  IFinanceTable,
  iFinanceTable,
} from '../Interfaces/Utils/iFinanceTable';

export class GestionUsuariosAlumnosDetallesFinancieroClass {
  dataStudent: any = null;

  finance: iFinanceTable[] = [IFinanceTable.getEmptyObject()];

  headerData: headerData = [
    {
      variant: 'standard',
      display: 'Nombre de formación',
      key: 'formationName',
      width: '250px',
      alignment: 'start',
    },
    {
      variant: 'standard',
      display: 'Año',
      key: 'year',
      width: '100px',
    },
    {
      variant: 'standard',
      display: 'Convocatoria',
      key: 'month',
      width: '250px',
    },
    {
      variant: 'standard',
      display: 'Promoción',
      key: 'edition',
      width: '250px',
    },
    {
      variant: 'standard',
      display: 'Importe',
      key: 'price',
      width: '150px',
      euros: true,
    },
    {
      variant: 'standard',
      display: 'Cantidad pagada',
      key: 'totalPaid',
      width: '150px',
      euros: true,
    },
    {
      variant: 'standard',
      display: 'Forma de pago',
      key: 'paymentModality',
      width: '150px',
    },
    {
      variant: 'standard',
      display: 'Método de pago',
      key: 'paymentMethod',
      width: '150px',
    },
    {
      variant: 'chip',
      display: 'Estado',
      key: 'status',

      width: '150px',
      classNameGenerator(any, index, row) {
        return row.statusColor;
      },
      border: true,
    },
  ];
}
