import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { AnimationControllerService, LoaderService, NotificationsService, iStaticUtilities, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaticUtilitiesService {

  constructor(private router: Router, private loader: LoaderService, private activatedRoute: ActivatedRoute, private animationControllerSE: AnimationControllerService) { }

  static showFeedback(message: string, extra?: string) {
    const notificaciones = new NotificationsService();
    extra ? notificaciones.showFeedBack(message, extra) : notificaciones.showFeedBack(message);
  }

  static showWarning(message: string, extra?: string) {
    const notificaciones = new NotificationsService();
    extra ? notificaciones.showWarning(message, extra) : notificaciones.showWarning(message);

  }

  static showError(message: string | String, extra?: string) {
    const notificaciones = new NotificationsService();
    extra ? notificaciones.showError(message, extra) : notificaciones.showError(message);

  }
  static isCompleteObject(obj: any) {
    return Object.values(obj).every(element => !!element && (typeof element !== "string" || element.length > 0));
  }
  static isEmptyArray(array: any) {
    return array.every(element => typeof element === "object" && !Array.isArray(element) && !StaticUtilitiesService.isCompleteObject(element));
  }
  static isNullObject(obj: any) {
    return obj == null;
  }
  static isValidFormat(email: string) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }
  static getUserPermissionsAndRoles(){
    let fromLocalStorage = localStorage.getItem('usr')!;
    let decripted = iStaticUtilities.simpleDecrypt( fromLocalStorage );
    let userData = JSON.parse(decripted);
    let {roles, permissions} = userData;
    return {roles, permissions};
  }
  openLoader() {
    this.loader.open();
  }

  closeLoader() {
    this.loader.closeSlow();
  }

  goTo(url: string, animation: slideNavigationTypes, params: any = null) {
    params == null ? this.router.navigate([url]) : this.router.navigate([url], { queryParams: params });
    this.animationControllerSE.setCurrentAnimation(animation);
  }

  getParams() {
    let sendParams: any = null
    this.activatedRoute.queryParamMap.subscribe(params => {
      sendParams = params['params'];
    })
    return sendParams;
  }

  getActualRoute() {
    return this.router.url;
  }

  static normalizeString(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim();
  }

}
