import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iTeacher } from "./iTeacher";

export interface iPricePerHour {
    id: number,
    master?: number,
    project?: number,
    incompany?: number,
    course?: number,
    seniority?: number,
    teacher?: iTeacher |any,
}

export class iPricePerHour extends iBaseObject {
    static convertFromCollection(ob: any): Array<iPricePerHour> {
        let iPricePerHourCollection: Array<iPricePerHour> = [];
        ob.forEach((element: any) => {
            iPricePerHourCollection.push(this.convertFromObject(element));
        });
        return iPricePerHourCollection;
    }

    static convertFromObject(ob: any): iPricePerHour {
        let iPricePerHourObj = new iPricePerHour();
        iPricePerHourObj.id = ob.id;
        iPricePerHourObj.master = ob.master;
        iPricePerHourObj.project = ob.project;
        iPricePerHourObj.incompany = ob.incompany;
        iPricePerHourObj.course = ob.course;
        iPricePerHourObj.seniority = ob.seniority;
        iPricePerHourObj.teacher = ob.teacher ? iTeacher.convertFromObject(ob.teacher) : {};
        return iPricePerHourObj;
    }

    static createEmptyObject(): iPricePerHour {
        let iPricePerHourObj = new iPricePerHour();
        iPricePerHourObj.master = -1;
        iPricePerHourObj.project = -1;
        iPricePerHourObj.incompany = -1;
        iPricePerHourObj.course = -1;
        iPricePerHourObj.seniority = -1;
        iPricePerHourObj.teacher = {};
        return iPricePerHourObj;
    }
}

export interface iPricePerHourFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    master?: number,
    project?: number,
    incompany?: number,
    course?: number,
    seniority?: number,
    teacher?: number,
}