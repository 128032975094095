<div class="popupGeneralContainer">
  <div class="header">
    <div class="imageAndDetails">
      <img src="" alt="" (error)="swapPlaceholder($event)">
      <div class="details">
        <p class="nombre">{{modalData.data.name}}</p>
        <div class="emailPhone">
          <p>{{modalData.data.mail}}</p>
          <p>{{modalData.data.phone}}</p>
          <p>DNIIIIIIIII</p>
        </div>
        <div class="promotions">
          <p>Las cosas</p>
        </div>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="blueBox">
      <div class="stateAndDate">
        <p class="state" [ngClass]="{pendienteVerificar: modalData.data.state === 'pendiente verificar', restoState: modalData.data.state !== 'pendiente verificar'}"> <b> {{modalData.data.state}} </b></p>
        <p class="date"> <b> Fecha </b></p>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn classList="specialButton" (click)="closePopup(modalData.filtros)">
      <ng-container>
        Cerrar
      </ng-container>
    </btn>
  </div>
</div>