import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  StaticUtilitiesService,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

@Component({
  selector: 'FormacionesNewVersion-Todos',
  templateUrl: './FormacionesNewVersion-Todos.component.html',
  styleUrls: ['./FormacionesNewVersion-Todos.component.scss'],
})
export class FormacionesNewVersionTodosComponent implements OnInit {
  @Output() pageChange: EventEmitter<boolean> = new EventEmitter();

  @Input() allFormations: any =
    StaticDataHandlerService.getFormacionesSkelleton();
  @Input() totalFormations: number = 0;
  @Input() areReadyAllFormations: boolean = false;
  @Input() filterAllFormations: any = null;

  constructor(private staticUtilitiesSE: StaticUtilitiesService) {}

  ngOnInit() {}

  goToDetailView(formation: any) {
    switch (formation.typeOf) {
      case 'master':
        this.staticUtilitiesSE.goTo(
          'formaciones/formaciones-detalle/',
          slideNavigationTypes.slideToTop,
          { master: formation.idMaster }
        );
        break;

      case 'course':
        this.staticUtilitiesSE.goTo(
          'formaciones/formaciones-detalle/',
          slideNavigationTypes.slideToTop,
          { curso: formation.idCourse }
        );
        break;

      default:
        break;
    }
  }

  pageChangeEmmiter(event) {
    this.pageChange.emit(event);
  }
}
