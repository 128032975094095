import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  GeneralLoaderService,
  PopupService,
  StaticUtilitiesService,
  iOptionsSelector,
  iResultHttp,
  iUnsubscribeDestroy,
} from '@quasar-dynamics/basic-designsystem';
import { Subject, filter, takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { SubirArchivoPopupComponent } from 'src/app/Popups/SubirArchivo-Popup/SubirArchivo-Popup.component';
import { SubirFacturaPopupComponent } from 'src/app/Popups/SubirFactura-Popup/SubirFactura-Popup.component';
import { BillService } from 'src/app/Services/Api/Bill.service';
import { StudentService } from 'src/app/Services/Api/Student.service';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { headerData } from 'src/app/Shared/Components/main-table/main-table.component';
import { iBill } from 'src/app/Shared/Interfaces/Utils/iBill';
import {
  IBillProformaTable,
  iBillProformaTable,
} from 'src/app/Shared/Interfaces/Utils/iBillProformaTable';
import { iBillFilter } from 'src/app/Shared/Interfaces/iBill';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'facturasAlumno',
  templateUrl: './GestionUsuarios-Alumnos-Detalles-Facturas.component.html',
  styleUrls: ['./GestionUsuarios-Alumnos-Detalles-Facturas.component.scss'],
})
export class GestionUsuariosAlumnosDetallesFacturasComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input() dataStudent: any = null;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  isDataLoaded: boolean = false;
  total: number = 0;
  facturas: Array<iBillProformaTable> = [IBillProformaTable.getEmptyObject()];
  filtroBill: iBillFilter = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  };
  formaciones: Array<any> =
    StaticDataHandlerService.getProductosAsociadosProfe();
  formacionesOpened: Array<number> = [];
  conceptSelected: any = null;

  amounts: {
    totalPaid: number;
    totalPrice: number;
    pendingAmount: number;
  } = {
    totalPaid: 0,
    totalPrice: 0,
    pendingAmount: 0,
  };

  conceptSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    clearable: true,
    label: 'Selecciona el concepto',
    placeholder: 'Selecciona el concepto',
    search: true,
  };

  headerData: headerData = [
    {
      variant: 'bold',
      display: 'Fecha de pago',
      key: 'paymentDate',
      date: {
        date: true,
        pipeTemplate: 'dd/MM/yyyy',
      },
      // alignment: 'start',
      width: '150px',
      className: 'truncateText maxWidth-150',
    },
    {
      variant: 'bold',
      display: 'Concepto',
      key: 'concept',
      alignment: 'center',
      width: '250px',
      className: 'truncateText maxWidth-250',
    },
    {
      variant: 'bold',
      display: 'Número de factura',
      key: 'number',
      alignment: 'center',
      width: '150px',
      className: 'truncateText maxWidth-150',
    },
    {
      variant: 'bold',
      display: 'Métiodo de pago',
      key: 'paymentMethod',
      alignment: 'center',
      width: '200px',
      className: 'truncateText maxWidth-200',
    },
    {
      variant: 'bold',
      display: 'Importe',
      key: 'amount',
      alignment: 'center',
      width: '150px',
      className: 'truncateText maxWidth-150',
      euros: true,
    },
    {
      variant: 'button',
      display: 'PDF',
      key: 'factura',
      displayGenerator(row) {
        return (
          row.document !== null &&
          row.document !== undefined &&
          row.document !== ''
        );
      },
      action: (any) => this.openUrl(any),
      buttonStyle: 'principalButton onlyIcon',
      icon: 'attach_file',
      width: '50px',
    },
    {
      variant: 'bold',
      display: 'Pagador',
      key: 'payBy',
      alignment: 'center',
      width: '250px',
      className: 'truncateText maxWidth-250',
    },
    {
      variant: 'chip',
      display: 'Estado',
      key: 'billStateName',
      alignment: 'center',
      width: '150px',
      className: 'fwBold',
      classNameGenerator(any, index, row) {
        return row.billStateColor;
      },
    },

    {
      variant: 'icons',
      display: '',
      key: '',
      width: '100px',
      icons: [
        {
          action: (row) => this.openPopupSubirFactura(true, row.id),
          image: IconsHandlerService.getEditBlueIcon(),
        },
        {
          action: (row) => this.openConfirmationPopup(row.id),
          image: IconsHandlerService.getDeleteIcon(),
        },
      ],
      alignment: 'center',
    },
  ];

  constructor(
    private billSE: BillService,
    private popupSE: PopupService,
    private generalLoaderSE: GeneralLoaderService,
    private studentSE: StudentService
  ) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataStudent']) {
      if (changes['dataStudent'].currentValue) {
        this.getBillsByStudent();
        this.getStudentsProformaByPromotionSelector();
      }
    }
  }

  /***
   * HANDLERS
   */

  successcreateBillStudentHandler(res: iResultHttp) {
    const { data } = res;
    this.getBillsByStudent();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetStudentsProformaByPromotionSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.conceptSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetPendingAndTotalAmountFromStudentPromotionHandler(res: iResultHttp) {
    const { data } = res;
    this.amounts = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */
  controlOpeneds(id) {
    this.formacionesOpened.includes(id)
      ? this.formacionesOpened.splice(this.formacionesOpened.indexOf(id), 1)
      : this.formacionesOpened.push(id);
  }

  openPopupSubirFactura(edit: boolean = false, billId = null) {
    const conceptObject = {
      studentPtomotionId: this.conceptSelected,
      concept:
        this.conceptSelectorOptions.items.find(
          (concept) => concept.id === this.conceptSelected
        )?.name ?? '',
    };
    this.popupSE.openPopup(SubirFacturaPopupComponent, {
      data: { edit, billId, ...conceptObject },
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        const returnValue = res?.returnValue;
        if (returnValue) {
          returnValue.studentId = this.dataStudent.id;
          if (edit) {
            this.updateBill(returnValue);
          } else {
            this.createBillStudent({
              ...returnValue,
              studentPromotionId: conceptObject.studentPtomotionId,
              concept: conceptObject.concept,
            });
          }
        }
        this._unsubInd2.next('');
      });
  }

  confirmarAccion(factura) {
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      accion: 'Liquidar',
      elemento: 'la factura del alumno',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((data) => {
        let facturaEdit: any = {
          id: factura.id,
          state: 'cobrado',
        };
        this.editBill(facturaEdit);
        this._unsubInd.next('');
      });
  }
  openFile(url) {
    window.open(url, '_blank');
  }
  deleteFactura(factura) {
    if (factura.state != 'pendiente') {
      StaticUtilitiesService.showError(
        'No se puede eliminar una factura que no esté pendiente de pago'
      );
      return;
    }
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      accion: 'Eliminar',
      elemento: 'la factura del profesor',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((data) => {
        if (!data) return;
        this.deleteBill(factura.id);
        this._unsubInd2.next('');
      });
  }

  pageChange(event) {
    this.filtroBill.num_pagina = event;
    this.getBillsByStudent();
  }
  uploadDocumento() {
    this.popupSE.openPopup(SubirArchivoPopupComponent, {
      accion: 'Subir factura al profesor',
      formatos: ['application/pdf'],
      size: 10485760,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res == null) {
          return;
        }

        StaticUtilitiesService.showFeedback('Factura subida correctamente');
        this._unsubInd.next('');
      });
  }

  openUrl(row: any) {
    window.open(row.document, '_blank');
  }

  openConfirmationPopup(id: number) {
    const subject = new Subject();
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      accion: 'Eliminar',
      elemento: 'la factura',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res) => {
        if (res?.returnValue) {
          this.deleteBill(id);
        }
        subject.next('');
      });
  }

  getConceptSelected(conceptSelected) {
    this.filtroBill.studentPromotion = conceptSelected;
    if (!conceptSelected) delete this.filtroBill.studentPromotion;
    if (conceptSelected)
      this.getPendingAndTotalAmountFromStudentPromotion(conceptSelected);
    this.getBillsByStudent();
  }

  /**
   * API CALLS
   */

  editBill(data) {
    this.billSE.update(data);
    this.billSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'Factura actualizada correctamente'
        );
        this.refresh.emit(true);
        this._unsubInd2.next('');
      });
    this.billSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getBillsByStudent() {
    this.generalLoaderSE.addToLoaderAmount();
    this.billSE.getAllByStudent(this.dataStudent.id, this.filtroBill);
    this.billSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.facturas = data;
        this.total = total;
        this.isDataLoaded = true;
        this.generalLoaderSE.removeFromLoaderAmount();
        this._unsubInd.next('');
      });
    this.billSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  createBillStudent(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.billSE.createBillStudent(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successcreateBillStudentHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateBill(objectToPass) {
    const subject = new Subject();
    this.generalLoaderSE.addToLoaderAmount();
    this.billSE.update(objectToPass);
    this.billSE
      .getResultUpdate()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.getBillsByStudent();
        this.generalLoaderSE.removeFromLoaderAmount();
        subject.next('');
      });
    this.billSE
      .getResultUpdateError()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        subject.next('');
      });
  }

  deleteBill(id) {
    this.billSE.deleteBillStudent(id);
    this.billSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Factura eliminada correctamente');
        this.refresh.emit(true);
        this._unsubInd9.next('');
      });
    this.billSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getStudentsProformaByPromotionSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.studentSE.getStudentsProformaByPromotionSelector(
      behaviorSubject,
      this.dataStudent.id
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successGetStudentsProformaByPromotionSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getPendingAndTotalAmountFromStudentPromotion(studentPromotionId: number) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.studentSE.getPendingAndTotalAmountFromStudentPromotion(
      behaviorSubject,
      studentPromotionId
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successGetPendingAndTotalAmountFromStudentPromotionHandler(
                res
              ),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
