import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopupService, iOptionsSelector, iResultHttp, iStaticUtilities, iUnsubscribeDestroy, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { takeUntil } from 'rxjs';
import { AddExcelPopupComponent } from 'src/app/Popups/AddPopup/AddExcel-Popup/AddExcel-Popup.component';
import { EnrollmentService } from 'src/app/Services/Api/Enrollment.service';

@Component({
  selector: 'app-Incompanies-Create-Promotions',
  templateUrl: './Incompanies-Create-Promotions.component.html',
  styleUrls: ['./Incompanies-Create-Promotions.component.scss']
})
export class IncompaniesCreatePromotionsComponent extends iUnsubscribeDestroy implements OnInit {

  optionSelected: number = 0;
  isActive: boolean = false;
  isLoading: boolean = false;
  isEdit: boolean = false;
  
  isPromotionSelected: boolean = false;
  isAlumnosLoaded: boolean = false;

  localStorageData: any = null;

  promocionSelected: any = null;
  selectedFUNDAE: any = null;

  formationId: number | null = null;
  propuestaFormalId: number | null = null;
  companyId: number | null = null;
  promotionId: number | null = null;

  data: any = {
    promotion: {
      startDate: new Date(),
      endDate: new Date(),
      schedule: "",
      state: null,
      edition: "",
      formation: null,
      teachers: null,
      coordinators: null,
      directors: null,
    },
    groupPrice: null,
    fundae:null,
    formalizedProposal: null,	
    group:{
      name: "",
      company: null,
      studentsGroup: [],
    },
  };

  // Inputs
  opcionesEstado: iOptionsSelector = {
    items: StaticDataHandlerService.getEstadosPromociones(),
    placeholder: "Estado",
    search: true,
  }
  opcionesProfesores: iOptionsSelector = {
    items: [],
    placeholder: "Profesor",
    search: true,
    bindLabel: "name",
    bindValue: "id",
    multiple: {
      isMultiple: true,
      multipleLimitReplacer: -1,

    }
  }
  opcionesCoordinadores: iOptionsSelector = {
    items: [],
    placeholder: "Profesor",
    search: true,
    bindLabel: "name",
    bindValue: "id",
    multiple: {
      isMultiple: true,
    }
  }
  opcionesDirector: iOptionsSelector = {
    items: [],
    placeholder: "Profesor",
    search: true,
    bindValue: "id",
    bindLabel: "name",
  }
  opcionesPromociones: iOptionsSelector = {
    items: [],
    placeholder: "Selecciona promoción",
    search: true,
    bindLabel: "edition",
    bindValue: "id",
  }
  opcionesFUNDAE: iOptionsSelector = {
    items: StaticDataHandlerService.getBonificacionesFUNDAE(),
    placeholder: "Selecciona bonificación FUNDAE",
    search: true,
  }


  constructor(private router: Router, private popupSE: PopupService, private staticUtilitiesSE: StaticUtilitiesService, private teacherSE: TeacherService, private promotionSE: PromotionFormationService, private enrollmentSE:EnrollmentService) {
    super();
  }

  ngOnInit() {
    this.getFromLocalStorage();
    this.getAllProfesores();
    this.getPromotionsByFormationId();
  }

  ngAfterViewInit(): void {
    (document.querySelector(".form") as HTMLElement).addEventListener("scroll", (e) => {
      if ((document.querySelector(".contactoContainer") as HTMLElement).getBoundingClientRect().top < 0) {
        this.optionSelected = 1;
      }
      if ((document.querySelector(".contactoContainer") as HTMLElement).getBoundingClientRect().top > 0) {
        this.optionSelected = 0;
      }
      if ((document.querySelector(".generalInfo") as HTMLElement).getBoundingClientRect().top < 0) {
        this.optionSelected = 2
      }
      if ((document.querySelector(".datosFiscales") as HTMLElement).getBoundingClientRect().top < 0) {
        this.optionSelected = 3
      }
    });
  }

  /**
   * GETTERS && SETTERS
   */

  stateSelector(event) {
    this.data.state = event;
    this.isCompleted();
  }
  teacherSelector(event, key) {
    switch (key) {
      case 'coordinators':
        this.data.coordinators = event;
        break;
      case 'teachers':
        this.data.teachers = event;
        break;
      case 'directors':
        this.data.directors = event;
        break;

      default:
        break;
    }
    this.isCompleted();
  }
  promotionSetter(data){
    this.data.promotion = {
      startDate: data.startDate,
      endDate: data.endDate,
      schedule: data.schedule,
      state: data.state,
      edition: data.edition,
      formation: data.formation.id,
      teachers: data.teacherPromotions.filter((teacher) => teacher.position.toLowerCase() === "profesor" || teacher.position.toLowerCase() === "profesor raso").map((teacher) => teacher.id),
      coordinators: data.teacherPromotions.filter((teacher) => teacher.position.toLowerCase() === "coordinador" || teacher.position.toLowerCase() === "coordinadores").map((teacher) => teacher.id),
      directors: data.teacherPromotions.filter((teacher) => teacher.position.toLowerCase() === "director" || teacher.position.toLowerCase() === "directores").map((teacher) => teacher.id),
    };
    this.isCompleted();
    this.isPromotionSelected = true;
  }

  /**
   * FUNCTIONALITY
   */

  scroll(type: string) {
    switch (type) {
      case "informacionPropuesta":
        (document.querySelector(".form") as HTMLElement).scroll({ top: 0, behavior: "smooth" })
        break;
      case "informacionGeneral":
        (document.querySelector(".form") as HTMLElement).scroll({ top: (document.querySelector(".form") as HTMLElement).scrollHeight / 5.05, behavior: "smooth" });
        break;
      case "profesorado":
        (document.querySelector(".form") as HTMLElement).scroll({ top: (document.querySelector(".form") as HTMLElement).scrollHeight / 2.2, behavior: "smooth" });
        break;
      case "grupo":
        (document.querySelector(".form") as HTMLElement).scroll({ top: (document.querySelector(".form") as HTMLElement).scrollHeight /1.2, behavior: "smooth" });
        break;
    }
  }
  confirmPopup() {
    if (this.router.url.includes("crear-profesor")) {
      this.popupSE.openPopup(ConfirmarAccionPopupComponent, { accion: 'Descartar', elemento: 'la creación de la formación' });
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
        this.staticUtilitiesSE.goTo("/formaciones/crear-formaciones", slideNavigationTypes.slideToBottom)
        this._unsubInd.next("")
      })
    } else {
      this.staticUtilitiesSE.goTo("/formaciones/crear-formaciones", slideNavigationTypes.slideToBottom)

    }
  }
  isCompleted() {
     
    if (StaticUtilitiesService.isCompleteObject(this.data) && StaticUtilitiesService.isCompleteObject(this.data.promotion) && StaticUtilitiesService.isCompleteObject(this.data.group) && this.data.group.studentsGroup.length > 0  ) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }
  updateLine(event, key, option) { }
  getFromLocalStorage() {
    let data = localStorage.getItem("formalProposal")!;
    let decripted = iStaticUtilities.simpleDecrypt(data);
     
    this.localStorageData = JSON.parse(decripted);
     
    this.formationId = this.localStorageData.formation.id;
    this.propuestaFormalId = this.localStorageData.id;
    this.companyId = this.localStorageData.company.id;
    this.data.promotion.formation = this.formationId;
    this.data.group.company = this.companyId;
    this.data.formalizedProposal = this.propuestaFormalId;
  }
  setStartDate(event) {
    this.data.startDate = event;
    this.isCompleted();
  }
  setEndDate(event) {
    this.data.endDate = event;
    this.isCompleted();
  }

  openExcelPopup() {
    this.popupSE.openPopup(AddExcelPopupComponent, { data: {nombre: `${this.data.group.name}`, formacion:`${this.data.group.name}`, studentList:(this.data.group.studentsGroup.length === 0) ? null : this.data.group.studentsGroup } });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd9)).subscribe((res) => {
      if (!res) { return }
      this.data.group.studentsGroup = res.data.map((student) => {return {student:{user:student}}});
      this.isAlumnosLoaded = true;
      this.isCompleted();
      this._unsubInd9.next('')
    });
  }

  promotionSelector(event) {
    this.promotionId = event;
    this.getPromotionById(event); 
  }
  fundaeSelector(event) {
    this.data.fundae = event;
    this.isCompleted();
  }

  /**
   * API CALLS
   */

  getAllProfesores() {
    this.teacherSE.getTeachersSelector();
    this.teacherSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      let { data } = res;
      let mappedData = res.data.map(teacher => { return { ...teacher, name: teacher.user.name + ' ' + teacher.user.surname } })
       
      this.opcionesProfesores.items = mappedData;
      this.opcionesCoordinadores.items = mappedData;
      this.opcionesDirector.items = mappedData;
      this._unsubInd.next('')
    })
    this.teacherSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  createIncompanyEnroll() {
    if(this.isPromotionSelected) this.data.promotion = this.promotionId;
    this.enrollmentSE.createEnrollmentIncompany(this.data);
    this.enrollmentSE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Pre matrícula creada correctamente');
      this.staticUtilitiesSE.goTo("/matriculaciones/incompanies/listado-matriculaciones", slideNavigationTypes.slideToBottom, )
      this._unsubInd2.next('')
    })
    this.enrollmentSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  getPromotionsByFormationId(){
    this.promotionSE.getPromotionsByFormationId(this.localStorageData.formation.id);
    this.promotionSE.getResultPromotionsByFormationId().pipe(takeUntil(this._unsubInd3)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      let { data } = res;
       
      this.opcionesPromociones.items = data;
      this._unsubInd3.next('')
    });
    this.promotionSE.getResultPromotionsByFormationIdError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    });
  }

  getPromotionById(id:number){
    this.promotionSE.getSingle(id);
    this.promotionSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      let {data} = res;
       
      this.promotionSetter(data)
      this._unsubInd.next('')
    })
    this.promotionSE.getResultIndividualError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })

  }


}