import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'CustomInput',
  templateUrl: './CustomInput.component.html',
  styleUrls: ['./CustomInput.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomInputComponent), // replace name as appropriate
      multi: true,
    },
  ],
})
export class CustomInputComponent implements OnInit {
  @Input() label: string = '';
  @Input() cantidadCaracteres: number | null = null;
  @Input() cantidadMax: number | null = null;
  @Input() type: string = '';
  @Input() placeHolder: string = '';
  @Input() icon: string = '';
  @Input() disabled: boolean = false;
  @Input() error: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Output() date = new EventEmitter<any>();

  visible: boolean = false;
  @Input() fecha: Date = new Date();

  constructor() {}

  //INTERNAL VALUES

  private _onChange = (_: any) => {};
  private _onTouched = () => {};
  private _oldData;

  _disabled: boolean = false;
  model;

  writeValue(obj: any): void {
    this.model = obj;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onNgModelChange(event) {
    this._oldData = this.model;
    this.model = event;
  }

  onModelChange($event) {
    this.model = $event;
    this._onChange(this.model);
  }

  ngOnInit() {}

  switchIcon() {
    if (this.type == 'password' || (this.type == 'text' && this.visible)) {
      if (!this.visible) {
        this.visible = true;
        this.icon = '/assets/Icons/General/passwordVisible.svg';
        this.type = 'text';
      } else {
        this.visible = false;
        this.icon = '/assets/Icons/General/passwordNoVisible.svg';
        this.type = 'password';
      }
    }
  }
  emitDate(event: Date) {
    this.date.emit(event);
  }
}
