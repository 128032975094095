<fullContainer gradient="bajo">
  <ng-container>
    <header></header>
    <div class="formGeneralContainer">
      <div class="timeline">
        <div class="container">
          <p class="title" *ngIf="isEdit">Editando el/la alumno/a {{data.user.name + ' ' + data.user.surname}}</p>
          <p class="title" *ngIf="!isEdit">Añadiendo un nuevo alumno/a<br> a IEM</p>
          <div class="options">
            <span [class.active]="optionSelected == 0" (click)="scroll('contacto')">
              <mat-icon>contact_mail</mat-icon>
              Datos de contacto
            </span>
            <span [class.active]="optionSelected == 1" (click)="scroll('fiscal')">
              <img src="/assets/Icons/General/datosFiscales.svg" alt="">
              Datos fiscales y bancarios
            </span>
            <span [class.active]="optionSelected == 2" (click)="scroll('academicos')">
              <mat-icon>school</mat-icon>
              Datos academicos
            </span>
            <span [class.active]="optionSelected == 3" (click)="scroll('documentacion')" *ngIf="!isEdit">
              <mat-icon>upload_file</mat-icon>
              Documentación
            </span>
            <span [class.active]="optionSelected == 4" (click)="scroll('imagen')" *ngIf="!isEdit">
              <mat-icon>photo</mat-icon>
              Imagen de perfil
            </span>

          </div>
        </div>
        <div class="footer">
          <btn classList="principalButton small fitWidth" (click)="confirmPopup()" *ngIf="!isEdit">
            <ng-container>
              Cancelar
            </ng-container>
          </btn>
          <btn classList="principalButton small fitWidth" (click)="backToStudent()" *ngIf="isEdit">
            <ng-container>
              Volver
            </ng-container>
          </btn>
          <btn classList="specialButton small fitWidth loading" (click)="crearAlumno()" [class.disabled]="!isActive"
            *ngIf="!isEdit">
            <ng-container *ngIf="!isLoading">
              Finalizar
            </ng-container>
            <ng-container *ngIf="isLoading">
              <loaderIcon class="white" size="small"></loaderIcon>
            </ng-container>
          </btn>
        </div>

      </div>
      <div class="form">
        <div class="contactoContainer">
          <div class="header">
            <p class="title">
              <mat-icon>contact_mail</mat-icon>
              Datos de contacto
            </p>

          </div>
          <div class="body">
            <div class="doubleColumn">
              <CustomInput [type]="'text'" [label]="'Nombre'" [placeHolder]="'Nombre'" [(ngModel)]="data.user.name"
                name="nombre" (change)="(isEdit)? updateLine(data.user.name, 'name', 'user') : isCompleted()">
              </CustomInput>
              <CustomInput [type]="'text'" [label]="'Apellidos'" [placeHolder]="'Apellidos'"
                [(ngModel)]="data.user.surname" name="apellidos"
                (change)="(isEdit)? updateLine(data.user.surname, 'surname', 'user') : isCompleted()"></CustomInput>
              <CustomInput [type]="'text'" [label]="'DNI / NIE / Pasaporte'" [placeHolder]="'DNI / NIE / Pasaporte'"
                [(ngModel)]="data.user.dni" name="cif"
                (change)="(isEdit)? updateLine(data.user.dni, 'dni', 'user') : isCompleted()"></CustomInput>
            </div>
            <div class="doubleColumn">
              <CustomInput [type]="'text'" [label]="'Alias'" [placeHolder]="'Alias'" [(ngModel)]="data.user.alias"
                name="alias" (change)="(isEdit)? updateLine(data.user.alias, 'alias', 'user') : isCompleted()">
              </CustomInput>
              <div class="subColumn">
                <label for="">¿Interesado en prácticas? </label>
                <selector [options]="opcionesInteresadoPracticas" [(ngModel)]="data.intershipInterested"
                  (change)="(isEdit)? updateLine(data.intershipInterested, 'intershipInterested', 'none') : isCompleted()">
                </selector>
              </div>
            </div>
            <div class="doubleColumn">
              <CustomInput [type]="'email'" [label]="'Correo electrónico'" [placeHolder]="'Correo electrónico'"
                [(ngModel)]="data.user.email" name="email" [required]="true"
                (change)="(isEdit)? updateLine(data.user.email, 'email', 'user') : isCompleted()">
              </CustomInput>
              <div class="subColumn">
                <label for="">Idiomas</label>
                <selector [options]="opcionesIdiomas" [(ngModel)]="data.user.languages"
                  (change)="idiomasSelector($event)"></selector>
              </div>
            </div>
            <div class="doubleColumn">
              <div class="subColumn">
                <label for="">País de residencia</label>
                <selector [options]="opcionesPaises" [(ngModel)]="data?.user?.address?.country.id"
                  (change)="(isEdit)? updateLine(data.user.address.country.id, 'country', 'address') : isCompleted()">
                </selector>
              </div>
              <div class="subColumn">
                <label for="">Teléfono</label>
                <div>
                  <CountrySelector [country]="data?.user?.phonePrefix ?? ''"
                    (emitCountry)="(isEdit)? updateLine(data.user.phonePrefix, 'phonePrefix', 'user') : getPrefix($event, 'telefono')">
                  </CountrySelector>
                  <CustomInput [type]="'number'" [placeHolder]="'Teléfono'" [(ngModel)]="data.user.phone"
                    name="telefono" (change)="(isEdit)? updateLine(data.user.phone, 'phone', 'user') : isCompleted()">
                  </CustomInput>
                </div>
              </div>
            </div>
            <div class="doubleColumn">
              <CustomInput [type]="'date'" [label]="'Fecha de nacimiento'" [placeHolder]="'Fecha de nacimiento'"
                (date)="getDate($event);" name="nacimiento" [(ngModel)]="data.user.dateOfBirth">
              </CustomInput>
              <div class="subColumn">
                <label for="">Sexo</label>
                <selector [options]="opcionesSexo" [(ngModel)]="data.user.gender"
                  (change)="(isEdit)? updateLine(data.user.gender, 'gender', 'user') : isCompleted()"></selector>
              </div>
            </div>
            <div class="doubleColumn">
              <CustomInput [type]="'text'" [label]="'Calle'" [placeHolder]="'Calle'"
                [(ngModel)]="data.user.address.street" name="calle"
                (change)="(isEdit)? updateLine(data.user.address.street, 'street', 'address') : isCompleted()">
              </CustomInput>
              <div class="tripleColumn">
                <CustomInput [type]="'number'" [label]="'Número'" [placeHolder]="'Número'"
                  [(ngModel)]="data.user.address.number" name="numero"
                  (change)="(isEdit)? updateLine(data.user.address.number, 'number', 'address') : isCompleted()">
                </CustomInput>
                <CustomInput [type]="'number'" [label]="'Puerta'" [placeHolder]="'Puerta'"
                  [(ngModel)]="data.user.address.door" name="puerta"
                  (change)="(isEdit)? updateLine(data.user.address.door, 'door', 'address') : isCompleted()">
                </CustomInput>
                <CustomInput [type]="'number'" [label]="'Código postal'" [placeHolder]="'Código postal'"
                  [(ngModel)]="data.user.address.postalCode" name="portal"
                  (change)="(isEdit)? updateLine(data.user.address.postalCode, 'postalCode', 'address') : isCompleted()">
                </CustomInput>
              </div>
            </div>
            <div class="doubleColumn">
              <CustomInput [type]="'text'" [label]="'Localidad'" [placeHolder]="'Localidad'"
                [(ngModel)]="data.user.address.municipality" name="localidad"
                (change)="(isEdit)? updateLine(data.user.address.municipality, 'municipality', 'address') : isCompleted()">
              </CustomInput>
              <CustomInput [type]="'text'" [label]="'Provincia'" [placeHolder]="'Provincia'"
                [(ngModel)]="data.user.address.province" name="provincia"
                (change)="(isEdit)? updateLine(data.user.address.province, 'province', 'address') : isCompleted()">
              </CustomInput>
            </div>

            <!-- dirección 2 -->

            <div class="checkBoxLine">
              <mat-checkbox (change)="hasSecondAddress($event)" [checked]="secondAddress"></mat-checkbox>
              <label for="">Segunda dirección</label>
            </div>

            <div class="doubleColumn" *ngIf="secondAddress">
              <CustomInput [type]="'text'" [label]="'Calle'" [placeHolder]="'Calle'"
                [(ngModel)]="data.user.address2.street" name="calle"
                (change)="(isEdit)? updateLine(data.user.address2.street, 'street', 'address2') : isCompleted()">
              </CustomInput>
              <div class="tripleColumn">
                <CustomInput [type]="'number'" [label]="'Número'" [placeHolder]="'Número'"
                  [(ngModel)]="data.user.address2.number" name="numero"
                  (change)="(isEdit)? updateLine(data.user.address2.number, 'number', 'address2') : isCompleted()">
                </CustomInput>
                <CustomInput [type]="'number'" [label]="'Puerta'" [placeHolder]="'Puerta'"
                  [(ngModel)]="data.user.address2.door" name="puerta"
                  (change)="(isEdit)? updateLine(data.user.address2.door, 'door', 'address2') : isCompleted()">
                </CustomInput>
                <CustomInput [type]="'number'" [label]="'Código postal'" [placeHolder]="'Código postal'"
                  [(ngModel)]="data.user.address2.postalCode" name="portal"
                  (change)="(isEdit)? updateLine(data.user.address2.postalCode, 'postalCode', 'address2') : isCompleted()">
                </CustomInput>
              </div>
            </div>
            <div class="tripleColumn" *ngIf="secondAddress">
              <div class="subColumn">
                <label for="">País</label>
                <selector [options]="opcionesPaises" [(ngModel)]="data?.user?.address2?.country.id"
                  (change)="(isEdit)? updateLine(data.user.address2.country.id, 'country', 'address2') : isCompleted()">
                </selector>
              </div>
              <CustomInput [type]="'text'" [label]="'Localidad'" [placeHolder]="'Localidad'"
                [(ngModel)]="data.user.address2.municipality" name="localidad"
                (change)="(isEdit)? updateLine(data.user.address2.municipality, 'municipality', 'address2') : isCompleted()">
              </CustomInput>
              <CustomInput [type]="'text'" [label]="'Provincia'" [placeHolder]="'Provincia'"
                [(ngModel)]="data.user.address2.province" name="provincia"
                (change)="(isEdit)? updateLine(data.user.address2.province, 'province', 'address2') : isCompleted()">
              </CustomInput>
            </div>


            <div class="doubleColumn">
              <div class="subColumn">
                <label for="">Nacionalidad<sup [ngStyle]="{'color': 'red'}">*</sup></label>
                <selector [options]="opcionesNacionalidades" [(ngModel)]="data.user.nationality.id"
                  (change)="(isEdit)? updateLine(data.user.nationality, 'nationality', 'user') : isCompleted()">
                </selector>
              </div>
              <div class="subColumn">
                <label for="">Situación laboral</label>
                <selector [options]="opcionesSiNo" [(ngModel)]="data.user.employmentStatus"
                  (change)="employmentStatus($event)">
                </selector>
              </div>
            </div>
            <div class="doubleColumn" *ngIf="isWorking">
              <CustomInput [type]="'text'" [label]="'Empresa'" [placeHolder]="'Empresa'" [(ngModel)]="data.user.company"
                name="empresa" (change)="(isEdit)? updateLine(data.user.company, 'company', 'user') : isCompleted()">
              </CustomInput>
              <CustomInput [type]="'text'" [label]="'Puesto de trabajo'" [placeHolder]="'Puesto de trabajo'"
                [(ngModel)]="data.user.jobPosition" name="puestoDeTrabajo"
                (change)="(isEdit)? updateLine(data.user.jobPosition, 'jobPosition', 'user') : isCompleted()">
              </CustomInput>
            </div>
            <div class="doubleColumn">
              <CustomInput [type]="'text'" [label]="'Instagram'" [placeHolder]="'Instagram'"
                [(ngModel)]="data.user.socialMedia.instagram" name="instagram"
                (change)="(isEdit)? updateLine(data.user.socialMedia.instagram, 'instagram', 'socialMedia') : isCompleted()">
              </CustomInput>
              <CustomInput [type]="'text'" [label]="'LinkedIn'" [placeHolder]="'LinkedIn'"
                [(ngModel)]="data.user.socialMedia.linkedin" name="linkedin"
                (change)="(isEdit)? updateLine(data.user.socialMedia.linkedin, 'linkedin', 'socialMedia') : isCompleted()">
              </CustomInput>
            </div>
            <div class="doubleColumn">
              <CustomInput [type]="'text'" [label]="'Facebook'" [placeHolder]="'Facebook'"
                [(ngModel)]="data.user.socialMedia.facebook" name="facebook"
                (change)="(isEdit)? updateLine(data.user.socialMedia.facebook, 'facebook', 'socialMedia') : isCompleted()">
              </CustomInput>
              <CustomInput [type]="'text'" [label]="'TikTok'" [placeHolder]="'TikTok'"
                [(ngModel)]="data.user.socialMedia.tiktok" name="tiktok"
                (change)="(isEdit)? updateLine(data.user.socialMedia.tiktok, 'tiktok', 'socialMedia') : isCompleted()">
              </CustomInput>
            </div>
            <div class="doubleColumn">
              <CustomInput [type]="'text'" [label]="'X'" [placeHolder]="'X'" [(ngModel)]="data.user.socialMedia.x"
                name="x" (change)="(isEdit)? updateLine(data.user.socialMedia.x, 'x', 'socialMedia') : isCompleted()">
              </CustomInput>
              <CustomInput [type]="'text'" [label]="'Otras'" [placeHolder]="'Otras'"
                [(ngModel)]="data.user.socialMedia.otras" name="otras"
                (change)="(isEdit)? updateLine(data.user.socialMedia.otras, 'otras', 'socialMedia') : isCompleted()">
              </CustomInput>
            </div>

          </div>
        </div>
        <div class="datosFiscales">
          <div class="header">
            <p class="title">
              <img src="/assets/Icons/General/datosFiscales.svg" alt="">
              Datos fiscales y bancarios
            </p>

          </div>
          <div class="body">
            <div class="doubleColumn">
              <div class="checkBoxLine">
                <mat-checkbox (change)="isCompanySelect($event)"></mat-checkbox>
                <label for="">Empresa</label>
              </div>
              <div class="checkBoxLine" *ngIf="!isCompany">
                <mat-checkbox (change)="checkBoxSelect($event)"></mat-checkbox>
                <label for="">Misma dirección que dirección de contacto</label>
              </div>
            </div>
            <!-- Si es empresa, nombre y CIF -->
            <div class="doubleColumn" *ngIf="isCompany">
              <CustomInput [type]="'text'" [label]="'Nombre de la empresa'" [placeHolder]="'Nombre de la empresa'"
                [(ngModel)]="data.user.bankData.company" name="companyName"
                (change)="(isEdit)? updateLine(data.user.bankData?.company, 'company', 'bankData') : isCompleted()">
              </CustomInput>
              <CustomInput [type]="'text'" [label]="'CIF/NIF'" [placeHolder]="'CIF/NIF'"
                [(ngModel)]="data.user.bankData.cif" name="cif"
                (change)="(isEdit)? updateLine(data.user.bankData?.cif, 'cif', 'bankData') : isCompleted()">
              </CustomInput>
            </div>

            <!-- Datos generales del usuario -->
            <div class="doubleColumn especial">
              <CustomInput [readOnly]="sameAddress" [type]="'text'" [label]="'Calle'" [placeHolder]="'Calle'"
                (change)="(isEdit)? updateLine(data.user.bankData.fiscalAddress.street, 'street', 'fiscalAddress') : isCompleted()"
                [(ngModel)]="data.user.bankData.fiscalAddress.street" name="calle"></CustomInput>
              <div class="subColumn">
                <CustomInput [readOnly]="sameAddress" [type]="'number'" [label]="'Número'" [placeHolder]="'Número'"
                  (change)="(isEdit)? updateLine(data.user.bankData.fiscalAddress.number, 'number', 'fiscalAddress') : isCompleted()"
                  [(ngModel)]="data.user.bankData.fiscalAddress.number" name="numero"></CustomInput>
                <CustomInput [readOnly]="sameAddress" [type]="'number'" [label]="'Puerta'" [placeHolder]="'Puerta'"
                  (change)="(isEdit)? updateLine(data.user.bankData.fiscalAddress.door, 'door', 'fiscalAddress') : isCompleted()"
                  [(ngModel)]="data.user.bankData.fiscalAddress.door" name="puerta"></CustomInput>
              </div>
            </div>
            <div class="doubleColumn">
              <div class="doubleColumn">
                <CustomInput [readOnly]="sameAddress" [type]="'text'" [label]="'Ciudad o municipio'"
                  [placeHolder]="'Ciudad o municipio'"
                  (change)="(isEdit)? updateLine(data.user.bankData.fiscalAddress.municipality, 'municipality', 'fiscalAddress') : isCompleted()"
                  [(ngModel)]="data.user.bankData.fiscalAddress.municipality" name="ciudad">
                </CustomInput>
                <CustomInput [readOnly]="sameAddress" [type]="'text'" [label]="'Provincia'" [placeHolder]="'Provincia'"
                  (change)="(isEdit)? updateLine(data.user.bankData.fiscalAddress.province, 'province', 'fiscalAddress') : isCompleted()"
                  [(ngModel)]="data.user.bankData.fiscalAddress.province" name="provincia"></CustomInput>
              </div>
              <div class="doubleColumn">
                <div *ngIf="!sameAddress" class="subColumn">
                  <label for="">País</label>
                  <selector [options]="opcionesPaisesDireccion"
                    [(ngModel)]="data?.user?.bankData?.fiscalAddress?.country.id"
                    (change)="(isEdit)? updateLine(data.user.bankData.fiscalAddress.country.id, 'country', 'fiscalAddress') : isCompleted()">
                  </selector>
                </div>
                <div *ngIf="sameAddress" class="subColumn especial">
                  <CustomInput [readOnly]="sameAddress" [type]="'text'" [label]="'País'" [placeHolder]="'País'"
                    [(ngModel)]="data.user.bankData.fiscalAddress.country.nombre" name="pais"></CustomInput>
                </div>
                <CustomInput [type]="'number'" [readOnly]="sameAddress"
                  (change)="(isEdit)? updateLine(data.user.bankData.fiscalAddress.postalCode, 'postalCode', 'fiscalAddress') : isCompleted()"
                  [label]="'Código postal'" [placeHolder]="'Código postal'"
                  [(ngModel)]="data.user.bankData.fiscalAddress.postalCode" name="codigoPostal">
                </CustomInput>
              </div>
            </div>
            <!-- Datos bancarios -->
            <div class="singleColumn">
              <CustomInput [type]="'text'" [label]="'Banco'"
                (change)="(isEdit)? updateLine(data.user.bankData.bank, 'bank', 'bankData') : isCompleted()"
                [placeHolder]="'Banco'" [(ngModel)]="data.user.bankData.bank" name="banco">
              </CustomInput>
            </div>
            <div class="doubleColumn">
              <CustomInput [type]="'text'" [label]="'Titular'"
                (change)="(isEdit)? updateLine(data.user.bankData.owner, 'owner', 'bankData') : isCompleted()"
                [placeHolder]="'Titular'" [(ngModel)]="data.user.bankData.owner" name="titular">
              </CustomInput>
              <CustomInput [type]="'text'" [label]="'DNI del titular'"
                (change)="(isEdit)? updateLine(data.user.bankData.ownerDni, 'ownerDni', 'bankData') : isCompleted()"
                [placeHolder]="'DNI del titular'" [(ngModel)]="data.user.bankData.ownerDni" name="titularDni">
              </CustomInput>
            </div>
            <div class="doubleColumn">
              <CustomInput [type]="'text'"
                (change)="(isEdit)? updateLine(data.user.bankData.iban, 'iban', 'bankData') : isCompleted()"
                [label]="'IBAN'" [placeHolder]="'IBAN'" [(ngModel)]="data.user.bankData.iban" name="iban"></CustomInput>
              <CustomInput [type]="'text'"
                (change)="(isEdit)? updateLine(data.user.bankData.bic, 'bic', 'bankData') : isCompleted()"
                [label]="'BIC/SWIFT'" [placeHolder]="'BIC/SWIFT'" [(ngModel)]="data.user.bankData.bic" name="swift">
              </CustomInput>
            </div>

          </div>
        </div>
        <div class="academicos">
          <div class="header">
            <p class="title">
              <mat-icon>school</mat-icon>
              Datos academicos
            </p>
          </div>
          <div class="body">
            <div class="doubleColumn">
              <CustomInput [type]="'date'" [label]="'Fecha de incorporación'" [placeHolder]="'Fecha de incorporación'"
                (date)="getDateIncorporacion($event);isCompleted()" [(ngModel)]="data.incorporationDate"
                name="incorporación"></CustomInput>
            </div>
            <div class="subColumn especial">
              <label for="">Biografía</label>
              <CustomTextArea [placeHolder]="'Biografía'" [(ngModel)]="data.user.biography" name="biografia"
                (change)="(isEdit)? updateLine(data.user.biography, 'biography', 'user') : isCompleted()">
              </CustomTextArea>
            </div>
          </div>
        </div>

        <div class="documentacion" *ngIf="!isEdit">
          <div class="header">
            <p class="title">
              <mat-icon>upload_file</mat-icon>
              Documentación
            </p>
          </div>
          <div class="body" *ngFor="let tipo of types">
            <div [(ngModel)]="file" class="dragAndDrop" fileInput [accept]="['application/pdf']" [multiple]="true"
              (dragenter)="dragEnter('document')" (dragleave)="dragLeave('document')"
              (change)="updateFile($event, tipo.name)">
              <div *ngIf="!isDragging">
                <span>
                  <img src="/assets/Icons/General/pdf.svg">
                  {{tipo.name}}
                </span>
                <btn classList="specialButton">
                  <ng-container>
                    Subir fichero
                  </ng-container>
                </btn>
              </div>
              <div *ngIf="isDragging">
                <span class="fwBold">
                  Suelta tu documento aquí
                </span>
              </div>
            </div>

            <!-- Documentos bajo tipo -->

            <div class="documentosSubidos">
              <div class="documento" *ngFor="let documento of tipo.value; let index = index">
                <span>
                  <img src="/assets/Icons/General/pdf.svg">
                  {{documento.name}}
                </span>
                <mat-icon (click)="removeDocumento(tipo.name, index)">close</mat-icon>
              </div>
            </div>

          </div>
        </div>


        <div class="imagenes" name="imagen" *ngIf="!isEdit">
          <div class="header">
            <p class="title">
              <mat-icon>photo</mat-icon>
              Imagen de perfil
            </p>
          </div>
          <div class="body">
            <div [(ngModel)]="image" class="dragAndDrop" fileInput [accept]="['image/jpeg','image/png']"
              (dragenter)="dragEnter('image')" (dragleave)="dragLeave('image')" (change)="updateImage($event)">
              <div *ngIf="!isDraggingImage">
                <span>
                  <mat-icon>photo</mat-icon>
                  {{ (image) ? 'Imagen de perfil subida' : 'Sube la imagen de perfil aquí'}}
                </span>
                <btn classList="specialButton" (click)="updateImage($event)">
                  <ng-container>
                    {{ (image) ? 'Reemplazar imagen' : 'Subir imagen'}}
                  </ng-container>
                </btn>
              </div>
              <div *ngIf="isDraggingImage">
                <span class="fwBold">
                  Suelta tu imagen aquí
                </span>
              </div>
            </div>
            <div class="documentosSubidos">
              <div class="documento" *ngIf="data.photo">
                <span>
                  <mat-icon>photo</mat-icon>
                  {{data.photo.name}}
                </span>
                <mat-icon (click)="removeImage()">close</mat-icon>
              </div>
            </div>
          </div>
          <div class="imageSelected" *ngIf="image">
            <mat-icon>image</mat-icon>
            <p>{{image[0].file.name}}</p>
          </div>
        </div>
        <div class="datosFiscales" [ngStyle]="{'opacity': '0', 'height': '16vh'}"></div>

      </div>
    </div>
  </ng-container>
</fullContainer>