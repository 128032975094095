<Tutorias-Alumnos-Layout>
  <div class="fullContainer">
    <div class="box">
      <div class="imageContainer">
        <img src="/assets/Images/Logos/success-tutoria-alumno.png" alt="success">
      </div>
      <div class="textContainer">
        <p class="title">¡Gracias por reservar una tutoría en IEM Digital Business School!</p>
        <p>Pronto recibirás un correo electrónico de confirmación con todos los detalles de la tutoría, incluyendo la fecha, hora y cualquier información adicional que puedas necesitar.</p>
        <p>Quedamos a tu disposición para cualquier pregunta o aclaración.</p>
        <p>¡Esperamos verte pronto!</p>
        <p>Ya puedes cerrar esta ventana o reservar otra tutoría :)</p>
      </div>
      <div class="formLine flexEnd">
        <btn classList="generalbutton principalButton" (click)="bookAnotherTutoring()" >Reserva otra tutoría</btn>
      </div>
    </div>
  </div>
</Tutorias-Alumnos-Layout>