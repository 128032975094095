import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopupService, StaticUtilitiesService, iUnsubscribeDestroy, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AddDobleTitulacionPopupComponent } from 'src/app/Popups/AddPopup/AddDobleTitulacion-Popup/AddDobleTitulacion-Popup.component';

@Component({
  selector: 'FormacionesNewVersion-DobleTitulacion',
  templateUrl: './FormacionesNewVersion-DobleTitulacion.component.html',
  styleUrls: ['./FormacionesNewVersion-DobleTitulacion.component.scss']
})
export class FormacionesNewVersionDobleTitulacionComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() allDobleTitulacion:any = [];
  @Input() totalDobleTitulacion:number = 0;
  @Input() areReadyAllDobleTitulacion:boolean = false;
  @Input() filterAllDobleTitulacion:any = null;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();


  constructor(private staticUtilitiesSE:StaticUtilitiesService, private popupSE: PopupService) { super() }

  ngOnInit() {
  }

  goToDetailView(id:number){
    this.staticUtilitiesSE.goTo('formaciones/formaciones-detalle/', slideNavigationTypes.slideToTop, {formation:id});
  }

  openNuevaTitulacion(dobleTitulacion: any) {
    this.staticUtilitiesSE.goTo(
      '/formaciones/doble-matricula-detalle',
      slideNavigationTypes.slideToLeft,
      { doubleMaster: dobleTitulacion.id }
    );
  }

  pageChangeEmmiter(event){
    this.pageChange.emit(event);
  }


}
