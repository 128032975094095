
export interface iTeacherPendingBills {
  type: string;
  id: number;
  formation: string;
  fee: number;
  concept: string;
  date: string;
  totalHours: string | number;
  hourlyFee: string | number;
  table: string;
  sessionId?: number;
}

export class ITeacherPendingBills {
  static getEmptyObject(): iTeacherPendingBills {
    return {
      type: '',
      id: 0,
      formation: '',
      fee: 0,
      concept: '',
      date: '',
      totalHours: '',
      hourlyFee: '',
      table: '',
      sessionId: 0,
    };
  }
}