import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { TopicService } from 'src/app/Services/Api/Topic.service';
import { TutoringService } from 'src/app/Services/Api/Tutoring.service';
import { EditTutoringPopupClass } from 'src/app/Shared/Classes/EditTutoringPopupClass';

@Component({
  selector: 'EditTutoring-Popup',
  templateUrl: './EditTutoring-Popup.component.html',
  styleUrls: ['./EditTutoring-Popup.component.scss'],
})
export class EditTutoringPopupComponent implements OnInit {
  extraClass: EditTutoringPopupClass = new EditTutoringPopupClass();

  constructor(
    public dialogRef: MatDialogRef<EditTutoringPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private generalLoaderSE: GeneralLoaderService,
    private topicSE: TopicService,
    private tutoringSE: TutoringService
  ) {}

  ngOnInit() {
    this.getTopicsSelector();
    this.getTutoringById();
    this.getTutoringStateSelector();
    this.getTopicsByTeacherId();
  }

  /**
   * HANDLERS
   */

  successDeleteTopicHandler(res: iResultHttp) {
    const { data } = res;
    this.getTopicsSelector();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTopicsSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.tematicasSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTutoringByIdHandler(res: iResultHttp) {
    const { data } = res;
    const { topicIds, observations } = data;
    this.extraClass.topicIds = topicIds;
    this.extraClass.observations = observations;
    this.extraClass.tutoringStateId = data.tutoringStateId;
    this.generalLoaderSE.addToLoaderAmount();
    setTimeout(() => {
      this.generateTopicsDisplay(topicIds);
      this.generalLoaderSE.removeFromLoaderAmount();
    }, 150);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTutoringStateSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.tutoringStatusSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTopicsByTeacherIdHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.tematicasSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  onTopicChange(topics) {
    this.generateTopicsDisplay(topics);
  }
  generateTopicsDisplay(topics) {
    const topicNamesArray = topics.map(
      (topicId) =>
        this.extraClass.tematicasSelectorOptions.items.find(
          (topic) => topic.id === topicId
        )?.name
    );
    const topicsToDisplay = topicNamesArray.join(' + ');
    this.extraClass.topicDisplay = topicsToDisplay;
  }

  checkIsComplete() {
    this.extraClass.isActive =
      this.extraClass.topicIds && this.extraClass.topicIds.length > 0;
  }

  onFinalizarClick() {
    const objectToPass = {
      id: this.modalData.data.tutoringId,
      topicIds: this.extraClass.topicIds,
      observations: this.extraClass.observations,
      tutoringStateId: this.extraClass.tutoringStateId,
    };
    this.closePopup(objectToPass);
  }
  /**
   * API CALLS
   */

  getTopicsSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.topicSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetTopicsSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteTopic(ids: number) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.topicSE.delete(behaviorSubject, ids);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteTopicHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getTutoringById() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.getSingle(behaviorSubject, this.modalData.data.tutoringId);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetTutoringByIdHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getTutoringStateSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.getTutoringStateSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetTutoringStateSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getTopicsByTeacherId() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.topicSE.getTopicsByTeacher(
      behaviorSubject,
      this.modalData.data.teacherId
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetTopicsByTeacherIdHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
