import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { StaticUtilitiesService, iOptionsSelector, iResultHttp, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { CompanyService } from 'src/app/Services/Api/Company.service';


@Component({
  selector: 'VariosAlumnosFirstStep',
  templateUrl: './VariosAlumnosFirstStep.component.html',
  styleUrls: ['./VariosAlumnosFirstStep.component.scss']
})
export class VariosAlumnosFirstStepComponent extends iUnsubscribeDestroy implements OnInit {
  @Output() dataUnVariosAlumnosFirstStep = new EventEmitter();
  @Input() dataFromParent: any = null;

  isSelectedCompany: boolean = false;
  selectorCompany: any = null;
  selectorOptionsCompany: iOptionsSelector = {
    items: [],
    search: true,
    placeholder: "Busque una empresa por CIF",
    bindLabel: 'cif',
  }
  data: any = {
    name: "",
    phone: "",
    email: "",
    phonePrefix: '+34'
  }
  constructor(private companySE: CompanyService) {
    super();
  }

  ngOnInit() {
    this.getCompaniesForSelector();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataFromParent']) {
      if(changes['dataFromParent'].currentValue) {
         
        this.selectorCompany = changes['dataFromParent'].currentValue.company;
        if(this.selectorCompany){this.isSelectedCompany = true;}
        this.data = changes['dataFromParent'].currentValue.data;
        this.setCompanyFromParent(this.data)
      } 
    }
  }

  /**
   * GETTERS && SETTERS
   */

  setCompanyFromParent(data) {
      this.data.name = data.name;
      this.data.phone = data.phone;
      this.data.email = data.email;
      this.data.phonePrefix = data.phonePrefix;
      this.data.cif = data.cif;
    }
    
  
    emitData() {
      this.dataUnVariosAlumnosFirstStep.emit({data:this.data, company:this.selectorCompany});
    }


  

  /**
   * FUNCTIONALITY
   */

  addPhonePrefix(event){
    this.data.phonePrefix = event.code;
    this.emitData();
  }

  selectedUser(event) {
     
    if (!event) {
      Object.keys(this.data).forEach((item: any) => {
        this.data[item] = "";
      }
      );
       
      this.isSelectedCompany = false;
      return;
    }
    this.isSelectedCompany = true;
     
    this.data.name = event.name;
    this.data.contactWith = event.contactWith;
    this.data.phone = event.phone;
    this.data.email = event.email;
    this.data.phonePrefix = event.phonePrefix;
    this.data.cif = event.cif;
  }

  /**
   * API CALLS
   */

  getCompaniesForSelector() {
    this.companySE.getAllCompaniesForFilter();
    this.companySE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) return;
      let { data } = res;
      this.selectorOptionsCompany.items = data;
       
      this._unsubInd.next('');
    });
    this.companySE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }
}
