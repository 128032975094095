import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { StaticUtilitiesService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { headerData } from 'src/app/Shared/Components/main-table/main-table.component';

@Component({
  selector: 'FormacionesNewVersion-Incompany-Table',
  templateUrl: './FormacionesNewVersion-Incompany-Table.component.html',
  styleUrls: ['./FormacionesNewVersion-Incompany-Table.component.scss']
})
export class FormacionesNewVersionIncompanyTableComponent implements OnInit {
  @Input() allIncompanies:any = [];
  @Input() totalIncompanies:number = 0;
  @Input() areReadyAllIncompanies:boolean = false;
  @Input() filterAllIncompanies:any = null;

  @Output() pageChange:EventEmitter<boolean> = new EventEmitter()

  printableData:any = [];

  tableHead:headerData = [
    {
      variant:'bold',
      display:'Nombre',
      key:'name',
      alignment:'start'
    },{
      variant:'standard',
      display:'Horas',
      key:'duration',
    },{
      variant:'standard',
      display:'Modalidad ',
      key:'mode',
    },{
      variant:'chip',
      display:'Estado',
      key:'activeLabel',
      classNameGenerator: (data: any,index:number,row:any) => {
        if (!row.active) return '--redState';
        return row.active ? '--greenState' : '--redState';
      },  
    }
  ];

  constructor(private staticUtilitiesSE:StaticUtilitiesService) { }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['allIncompanies']!==null){
      if(changes['allIncompanies'].currentValue.length!==0){
        this.printableData = this.allIncompanies.map(master => {
          return {
            ...master.formation,
            masterId:master.id
          }
        });
      };
    }
  }

  handleClickRow({id}){
    this.goToDetailView(id);
  }

  goToDetailView(id:number){
    this.staticUtilitiesSE.goTo('formaciones/formaciones-detalle/', slideNavigationTypes.slideToTop, {formation:id});
  }

  pageChangeEmmiter(event) {
    this.pageChange.emit(event);
  }
}
