<div class="addFileContainer" [(ngModel)]="file" class="dragAndDrop" fileInput [accept]="acceptedFileTypesArray"
  [multiple]="multiple" (dragenter)="dragEnter()" (dragleave)="dragLeave()" (change)="updateFile(file)">
  <ng-container *ngIf="!isDragging">
    <img [src]="IconsHandlerService.getUploadIcon()" alt="uploadIcon">
    <div class="namesLine">
     <ng-container  *ngFor="let file of filesArray;last as last">
      <ng-container *ngIf="last" >
        <p>
           {{file.file.name}}
          </p>
      </ng-container>
      <ng-container *ngIf="!last" >
        <p>
           {{file.file.name}},
          </p>
      </ng-container>
       
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="isDragging">
    <p class="dropText">Suelta tu fichero aquí</p>
  </ng-container>


</div>