import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StaticUtilitiesService, iOptionsSelector, iResultHttp, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { FormationService } from 'src/app/Services/Api/Formation.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';

@Component({
  selector: 'app-AsociarProductoProfesor-Popup',
  templateUrl: './AsociarProductoProfesor-Popup.component.html',
  styleUrls: ['./AsociarProductoProfesor-Popup.component.scss']
})
export class AsociarProductoProfesorPopupComponent extends iUnsubscribeDestroy implements OnInit {

  opcionesFormaciones: iOptionsSelector = {
    items: [],
    search: true,
    placeholder: "Selecciona una formación",
    bindLabel: "name",
    append:true
  }
  formation: number  = 0;
  selectorFilter = {
  }
  
  opcionesPromociones: iOptionsSelector = {
    items: [],
    search: true,
    clearable: false,
    placeholder: "Selecciona una promoción",
    bindLabel: "edition",
    bindValue: "id",
    append:true,
  }
  promotion: number | null = null;
  
  opcionesRoles: iOptionsSelector = {
    items: ['Coordinador', 'Profesor'],
    search: true,
    clearable: false,
    placeholder: "Selecciona un rol",
    append:true,
  }
  position: string | null = null;

  objectToPass: any = {
    promotion: null,
    teacher: 0,
    position: null
  }

  constructor(public dialogRef: MatDialogRef<AsociarProductoProfesorPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private formationSE: FormationService, private promotionSE: PromotionFormationService) { super() }


  ngOnInit() {
    this.getAllFormationsSelector();
  }

  /**
   * HANDLERS
   */

  successGetAllFormationsSelectorHandler(res: iResultHttp) {
    let { data } = res;
    this.opcionesFormaciones.items = data;
  }

  /**
* FUNCTIONALITY
*/

  selectFormation(event) {
    this.formation = event.id;
    this.getPromotions(this.formation);
    this.clearSelectors('formation');
  }

  clearSelectors(type: string) {
    switch (type) {
      case 'formation':
        this.objectToPass.promotion = null;
        this.objectToPass.position = null;
        break;
    case 'promotion':
        this.objectToPass.position = null;
        break;
      default:
        break;
    }
    this.isComplete()
  }

  selectPromotion(event) {
    this.clearSelectors('promotion')
  }

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  isComplete():boolean{
    return this.objectToPass.promotion && this.objectToPass.position;
  }

  /**
   * API CALLS
   */

  getAllFormationsSelector() {
    this.formationSE.getAllFormationsForSelectorOV(this.selectorFilter);
    this.formationSE.getResultAllFormationListOV().pipe(takeUntil(this._unsubInd)).subscribe((res: iResultHttp) => {
      if (!res) return;
      this.successGetAllFormationsSelectorHandler(res);
      this._unsubInd.next('');
    })
  }

  getPromotions(formationId: number) {
    this.promotionSE.getPromotionsByFormationId(formationId);
    this.promotionSE.getResultPromotionsByFormationId().pipe(takeUntil(this._unsubInd)).subscribe((res: iResultHttp) => {
      if (!res) return;
      this.opcionesPromociones.items = res.data;
      this._unsubInd.next('');
    })
    this.promotionSE.getResultPromotionsByFormationIdError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (!res) return;
      StaticUtilitiesService.showError(res.message);
      this._unsub.next('');
    });
  }

  assignTeacherToPromotion() {
    this.objectToPass.teacher = this.modalData.teacherId;
    this.promotionSE.assignTeacherToPromotion(this.objectToPass);
    this.promotionSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) return;
      StaticUtilitiesService.showFeedback('Profesor asignado correctamente');
      this.closePopup();
      this._unsubInd.next('');
    })
    this.promotionSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if (!res) return;
      StaticUtilitiesService.showError(res.message);
      this._unsub.next('');
    })
  }



}
