import { Component, OnInit } from '@angular/core';
import { GeneralLoaderService } from '@quasar-dynamics/basic-designsystem';
import {
  StaticUtilitiesService,
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { StudentService } from 'src/app/Services/Api/Student.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-GestionUsuarios-Alumnos-Listado',
  templateUrl: './GestionUsuarios-Alumnos-Listado.component.html',
  styleUrls: ['./GestionUsuarios-Alumnos-Listado.component.scss'],
})
export class GestionUsuariosAlumnosListadoComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  lineCounter: number = 0;
  filterLineArray: any = [];
  optionSubmenuSelected: number = 0;
  filters: any = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  };
  filtersAlumni: any = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  };
  total = 0;
  totalAlumni = 0;
  filter: string = '';
  alumnos: Array<any> = [];
  alumni: Array<any> = [];
  openPrerferencesAlumnoPopup: boolean = false;
  openPrerferencesAlumniPopup: boolean = false;
  isFilterDisplayed: boolean = false;
  downloadExcelFile: boolean = false;
  downloadExcelFileAlumni: boolean = false;
  formacionSelector: any = null;
  formacionSelectorDos: any = null;
  formacionSelectorTres: any = null;

  placeholdersArray: string[] = [
    'Busca por nombre y apellidos, email, teléfono, alias, fecha de nacimiento, género, fecha de registro, DNI o nacionalidad',
    'Busca por nombre y apellidos, email y año de formación',
  ];

  yearSelectorOptions: iOptionsSelector = {
    items: [],
    bindValue: 'year',
    clearable: true,
    bindLabel: 'year',
    append: true,
    placeholder: 'Selecciona un año',
  };
  cursosSelectorOptions: iOptionsSelector = {
    items: [
      { id: 'masters', label: 'Master' },
      { id: 'courses', label: 'Curso' },
      { id: 'incompanies', label: 'Incompanies' },
    ],
    clearable: true,

    placeholder: 'Tipo de formación',
    classList: 'roundedSelector ',
  };
  optionsSecondSelector: iOptionsSelector = {
    items: [],
    bindLabel: 'name',
    clearable: true,
    placeholder: 'Formación',
    classList: 'roundedSelector ',
  };
  optionsThirdSelector: iOptionsSelector = {
    items: [],
    bindLabel: 'edition',
    clearable: true,
    placeholder: 'Promoción',
    multiple: {
      isMultiple: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varias promociones seleccionadas',
    },
    classList: 'roundedSelector ',
  };

  constructor(
    private studentSE: StudentService,
    private promotionFormationSE: PromotionFormationService,
    private staticSE: StaticUtilitiesService,
    private generalLoaderSE: GeneralLoaderService
  ) {
    super();
  }

  ngOnInit() {
    // this.getAllStudents();
    // this.getAllStudentsAlumni();
    this.getFilterInfo();
  }
  /**
   * GETTERS & SETTERS
   */
  getFilter(filter, key) {
    if (this.optionSubmenuSelected == 0) {
      if (!filter) {
        delete this.filters[key];
        this.getAllStudents();
        return;
      }
      this.filters[key] = filter;
      this.getAllStudents();
    }
    if (this.optionSubmenuSelected == 1) {
      if (!filter) {
        delete this.filtersAlumni[key];
        this.getAllStudentsAlumni();
        return;
      }
      this.filtersAlumni[key] = filter;
      this.getAllStudentsAlumni();
    }
  }

  /**
   * FUNCTIONALITY
   */

  openPreferencesPopup() {
    if (this.optionSubmenuSelected == 0) {
      this.openPrerferencesAlumnoPopup = true;
    }
    if (this.optionSubmenuSelected == 1) {
      this.openPrerferencesAlumniPopup = true;
    }
  }
  changeAlumniBooleanToFalse(event) {
    this.openPrerferencesAlumniPopup = event;
  }
  changeAlumnoBooleanToFalse(event) {
    this.openPrerferencesAlumnoPopup = event;
  }

  addNewFilterLine() {
    let newLine = {
      id: this.lineCounter,
      type: this.formacionSelector.label,
      formation: this.formacionSelectorDos.name,
      promotions: this.formacionSelectorTres,
    };
    this.lineCounter++;
    this.filterLineArray.push(newLine);
    this.sendFilter();
  }

  sendFilter() {
    if (this.optionSubmenuSelected == 0) {
      this.filters['promotions'] = this.filterLineArray.map((promotion) =>
        promotion.promotions.map((item) => item.id)
      );
      if (this.filters['promotions'].length == 0) {
        delete this.filters['promotions'];
      }
      this.getAllStudents();
    }
    if (this.optionSubmenuSelected == 1) {
      this.filtersAlumni['promotions'] = this.filterLineArray.map((promotion) =>
        promotion.promotions.map((item) => item.id)
      );
      if (this.filtersAlumni['promotions'].length == 0) {
        delete this.filtersAlumni['promotions'];
      }
      this.getAllStudentsAlumni();
    }
  }

  deleteLineFilter(id) {
    this.filterLineArray = this.filterLineArray.filter((line) => line.id != id);
    this.sendFilter();
  }

  displayFilters() {
    this.isFilterDisplayed = !this.isFilterDisplayed;
  }

  selectOption(event) {
    let options = document.querySelectorAll('.option');
    switch (event) {
      case 'activo':
        options[1].classList.remove('active');
        options[0].classList.add('active');
        this.filters['state'] = 'cursando';
        this.getAllStudents();
        break;
      case 'abierto':
        options[0].classList.remove('active');
        options[1].classList.add('active');
        this.filters['state'] = 'matriculado';
        this.getAllStudents();
        break;
      default:
        break;
    }
  }

  callCourseType(event) {
    if (!event) {
      this.formacionSelector = null;
      return;
    }
    this.formacionSelectorDos = null;
    this.formacionSelectorTres = null;
    this.getFormationInfo(event.id);
  }
  callPromotion(event) {
    if (!event) {
      this.formacionSelectorDos = null;
      this.formacionSelectorTres = null;
      return;
    }
    this.formacionSelectorTres = null;
    this.getPromotionsInfo(event.formation);
  }
  deletePromotionFilter(event, index) {
    this.filterLineArray[index].promotions = this.filterLineArray[
      index
    ].promotions.filter((promotion) => promotion.id != event);
  }
  pageChange(event) {
    this.filters.num_pagina = event;
    this.getAllStudents();
  }
  pageChangeAlumni(event) {
    this.filtersAlumni.num_pagina = event;
    this.getAllStudentsAlumni();
  }

  downloadExcel() {
    if (this.optionSubmenuSelected == 0) {
      this.downloadExcelFile = true;
    }
    if (this.optionSubmenuSelected == 1) {
      this.downloadExcelFileAlumni = true;
    }
  }
  changeExcelBooleanToFalse(event) {
    this.downloadExcelFile = event;
  }
  changeExcelBooleanToFalseAlumni(event) {
    this.downloadExcelFileAlumni = event;
  }
  selectAPICall() {
    if (this.optionSubmenuSelected == 0) {
      this.getAllStudents();
    }
    if (this.optionSubmenuSelected == 1) {
      this.getAllStudentsAlumni();
    }
  }

  goToCreateStudent() {
    this.staticSE.goTo(
      '/alumnos/mis-alumnos/crear-alumno',
      slideNavigationTypes.slideToTop
    );
  }

  onToggleChange(event) {
    setTimeout(() => {
      this.optionSubmenuSelected = Number(event);
    }, 1);
    this.selectAPICall();
  }

  /**
   * API CALLS
   */

  getAllStudents() {
    // this.generalLoaderSE.addToLoaderAmount();
    this.studentSE.getAlumnList(this.filters);
    this.studentSE
      .getResultalumnList()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        let { data, total } = res.data;
        this.alumnos = data.map((alumno) => {
          return {
            ...alumno,
            name: `${alumno?.user?.name} ${alumno?.user?.surname}`,
            email: alumno?.user?.email,
            phone: alumno?.user?.phone,
            state: alumno?.user?.state,
            enrollments: alumno?.enrollments[0]?.promotion?.formation?.name,
            extraEnrollments: alumno?.enrollments?.length - 1,
            alias: alumno?.user?.alias,
            dni: alumno?.user?.dni,
            gender: alumno?.user?.gender,
            nationality: alumno?.user?.nationality,
            registerDate: iDate
              .javascriptConvert(new Date(alumno?.user?.registerDate))
              .toStringDate('EU'),
            fechaDeNacimiento: iDate
              .javascriptConvert(new Date(alumno?.user?.dateOfBirth))
              .toStringDate('EU'),
          };
        });
        this.total = total;
        // this.generalLoaderSE.removeFromLoaderAmount();
        this._unsubInd2.next('');
      });
    this.studentSE
      .getResultalumnListError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        // this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
  getAllStudentsAlumni() {
    this.studentSE.getAlumniList(this.filtersAlumni);
    this.studentSE
      .getResultalumniList()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        let { data, total } = res.data;
        this.alumni = data.map((alumno) => {
          return {
            ...alumno,
            name: `${alumno?.user?.name} ${alumno?.user?.surname}`,
            email: alumno?.user?.email,
            phone: alumno?.user?.phone,
            state: alumno?.user?.state,
            enrollments: alumno?.enrollments[0]?.promotion?.formation?.name,
            extraEnrollments: alumno?.enrollments?.length - 1,
            year: new Date(
              alumno?.enrollments[0]?.promotion?.endDate
            ).getFullYear(),
            alias: alumno?.user?.alias,
            fechaDeNacimiento: iDate
              .javascriptConvert(new Date(alumno?.user?.DateOfBirth))
              .toStringDate('EU'),
            dni: alumno?.user?.dni,
            gender: alumno?.user?.gender,
            nationality: alumno?.user?.nationality,
            registerDate: iDate
              .javascriptConvert(new Date(alumno?.user?.registerDate))
              .toStringDate('EU'),
          };
        });
        this.totalAlumni = total;

        this._unsubInd4.next('');
      });
    this.studentSE
      .getResultalumniListError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getFilterInfo() {
    this.studentSE.getFilterInfo({ filter: 'years' });
    this.studentSE
      .getResultfilterInfo()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        let { data } = res;
        this.yearSelectorOptions.items = data;
        this._unsubInd3.next('');
      });
    this.studentSE
      .getResultfilterInfoError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
  getFormationInfo(event) {
    this.studentSE.getFilterInfo({ filter: event });
    this.studentSE
      .getResultfilterInfo()
      .pipe(takeUntil(this._unsubInd7))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        let { data } = res;
        this.optionsSecondSelector.items = data;

        this._unsubInd7.next('');
      });
    this.studentSE
      .getResultfilterInfoError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
  getPromotionsInfo(event) {
    this.promotionFormationSE.getPromotionsByFormationId(event);
    this.promotionFormationSE
      .getResultPromotionsByFormationId()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) return;
        let { data } = res;
        this.optionsThirdSelector.items = data;

        this._unsubInd3.next('');
      });
    this.promotionFormationSE
      .getResultPromotionsByFormationIdError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
