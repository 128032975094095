import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DebouncerService,
  GeneralLoaderService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { DetailViewProjectsClass } from 'src/app/Shared/Classes/DetailViewProjectsClass';
import { TSendIsEditing } from '../../../DetailView-baseContainer/DetailView-baseContainer.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';
import {
  PopupService,
  iDate,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { AddTribunalPopupComponent } from 'src/app/Popups/AddTribunal-Popup/AddTribunal-Popup.component';
import { filter, takeUntil } from 'rxjs';
import { iPromotionTribunal } from 'src/app/Shared/Interfaces/Utils/iPromotionTribunal';
import { TribunalService } from 'src/app/Services/Api/Tribunal.service';
import { CustomDeliveryDateService } from 'src/app/Services/Api/CustomDeliveryDate.service';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { ProjectService } from 'src/app/Services/Api/Project.service';
import { TribunalStudentStateService } from 'src/app/Services/Api/TribunalStudentState.service';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';

@Component({
  selector: 'detailView-Projects',
  templateUrl: './detailView-Projects.component.html',
  styleUrls: ['./detailView-Projects.component.scss'],
})
export class DetailViewProjectsComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  private _promotionId: number = 0;
  extraClass: DetailViewProjectsClass = new DetailViewProjectsClass(
    this.sendTeacherTribunalDates.bind(this),
    this.sendStudentDates.bind(this)
  );

  IconsHandlerService = IconsHandlerService;
  @Output() update: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() get promotionId() {
    return this._promotionId;
  }
  set promotionId(value) {
    this._promotionId = value;
    setTimeout(() => {
      this.getStudentByPromotionSelector();
    }, 1);
  }

  @Input()
  get tribunalPromotion(): iPromotionTribunal {
    return this.extraClass._tribunalPromotion;
  }
  set tribunalPromotion(value: iPromotionTribunal) {
    this.extraClass._tribunalPromotion = value;
    this.splitTribunalPromotion(value);
  }

  IconHandlerService = IconsHandlerService;

  constructor(
    private popupSE: PopupService,
    private tribunalSE: TribunalService,
    private generalLoaderSE: GeneralLoaderService,
    private customDeliveryDateSE: CustomDeliveryDateService,
    private teacherSE: TeacherService,
    private projectSE: ProjectService,
    private tribubnalStudentStateSE: TribunalStudentStateService,
    private promotionSE: PromotionFormationService
  ) {
    super();
  }

  ngOnInit() {
    this.getTeacherSelector();
    this.getTeacherStateSelector();
    this.getStudentProjectStatusSelector();
  }

  /**
   * HANDLERS
   */

  successCreateTribunalHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.tribunals = data;
    this.setstudentSelectorOptions(data);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateDeliveryDateHandler(res: iResultHttp) {
    this.update.emit(true);
  }

  successCreateCustomDeliveryDateHandler(res: iResultHttp) {
    let { data } = res;
    this.extraClass.masterDeliveryDates = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTeacherSelectorHandler(res: iResultHttp) {
    let { data } = res;
    this.extraClass.addTeacherSelectorOptions.items = data.map((teacher) => {
      return {
        id: teacher.id,
        name: `${teacher.user.name} ${teacher.user.surname}`,
      };
    });
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successCreateTeacherProjectHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.teachers = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteCustomDeliveryDateHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.masterDeliveryDates = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteProjectTeacherHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.teachers = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTeacherStateSelectorHandler(res: iResultHttp) {
    let { data } = res;
    this.extraClass.teacherChipSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetStudentProjectStatusSelectorHandler(res: iResultHttp) {
    let { data } = res;
    this.extraClass.matrixOptionsStudentsStatus.forEach((row) => {
      row.forEach((element, index) => {
        element.items = data;
      });
    });
    this.extraClass.studentStatusSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateTeacherStatusHandler(res: iResultHttp) {
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteTribunalHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.tribunals = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateTribunalStudentHandler(res: iResultHttp) {
    const { data } = res;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetStudentByPromotionSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.matrixOptionsStudents.forEach((row, index) => {
      row.forEach((element, index) => {
        element.items = data;
      });
    });
    this.extraClass.studentSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }
  successSendTeacherTribunalDatesHandler(res: iResultHttp) {
    const { data } = res;
    this.generalLoaderSE.removeFromLoaderAmount();
  }
  successSendStudentDatesHandler(res: iResultHttp) {
    const { data } = res;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   *
   * FUNCTIONALITY
   */

  splitTribunalPromotion(tribunalPromotion: iPromotionTribunal) {
    const { masterDeliveryDates, projectId, teachers, tribunals } =
      tribunalPromotion;
    this.extraClass.projectId = projectId;
    this.extraClass.masterDeliveryDates = masterDeliveryDates;
    this.extraClass.teachers = teachers;
    this.extraClass.tribunals = tribunals;

    this.setstudentSelectorOptions(tribunals);
  }

  setstudentSelectorOptions(tribunals) {
    tribunals.forEach((tribunal, tribunalIndex) => {
      this.extraClass.matrixOptionsStudents.push([]);
      this.extraClass.matrixOptionsStudentsStatus.push([]);
      const students = tribunal.students;
      students.forEach((student, studentIndex) => {
        this.extraClass.matrixOptionsStudents[tribunalIndex].push(
          this.extraClass.studentSelectorOptions
        );
        this.extraClass.matrixOptionsStudentsStatus[tribunalIndex].push(
          this.extraClass.studentStatusSelectorOptions
        );
      });
    });
  }

  handelChangeEdit(event: TSendIsEditing) {
    const isTribunal = Object.keys(event).includes('Tribunales');
    const eventValue = event[Object.keys(event)[0]];

    this.extraClass.editableController = {
      ...this.extraClass.editableController,
      ...event,
    };
    this.extraClass.setReadonly();
    if (isTribunal) this.extraClass.setReadonlyTribunal(eventValue);
  }

  onCheckboxChange(event: MatCheckboxChange, index, teacher) {
    const push = this.extraClass.selectedItemsArray.push(teacher);
    const filter = this.extraClass.selectedItemsArray.filter(
      (item) => item.id !== teacher.id
    );
    const checked = event.checked;
    checked ? push : (this.extraClass.selectedItemsArray = filter);
  }

  getSelectedTeacher(event) {
    const objectToPass: { projectId: number; teacherId: number } = {
      projectId: this.extraClass.projectId,
      teacherId: event,
    };
    this.createTeacherProject(objectToPass);
  }

  openAddProjectPopup() {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(AddTribunalPopupComponent, { subject: subject });
    this.popupSE
      .returnData()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        const returnValue = res['returnValue'];
        if (returnValue === 'cancel') {
          subject.next(true);
          subject.complete();
          return;
        }
        const objectToPass = {
          projectId: this.extraClass.projectId,
          landmark: returnValue,
          name: returnValue.name,
        };
        this.createTribunal(objectToPass);
        subject.next(true);
        subject.complete();
      });
  }

  updateTitleCustomDeliveryDate(event, key, index) {
    const value = event.target.textContent;
    DebouncerService.formDebouncer(
      () => this.prepareCustomDeliveryDateForUpdate(value, key, index),
      key,
      value
    );
  }

  prepareCustomDeliveryDateForUpdate(event, key, index) {
    const customDeliveryDate = this.extraClass.masterDeliveryDates[index];
    const objectToPass = {
      id: customDeliveryDate.customDeliveryDateId,
      [key]: event,
    };
    this.updateDeliveryDate(objectToPass);
  }

  prepareDateToUpdate(event, key, index) {
    const date = iDate.javascriptConvert(new Date(event)).toStringDate('JAP');
    this.prepareCustomDeliveryDateForUpdate(date, key, index);
  }

  prepareCreateCustomDeliveryDate() {
    const objectToPass = {
      promotionId: this.promotionId,
    };
    this.createCustomDeliveryDate(objectToPass);
  }

  prepareUpdateTeacherState(event, key, id) {
    const objectToPass = {
      id: id,
      [key]: event,
    };
    this.updateTeacherStatus(objectToPass);
  }

  prepareUpdateStudent(event, key, id) {
    const objectToPass = { id, [key]: event };
    this.updateTribunalStudent(objectToPass);
  }

  ifCannotDeleteBecauseStudentsInTribunal(id) {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      accion: 'Eliminar',
      elemento: 'el tribunal aunque hayan alumnos',
    });
    this.popupSE
      .returnData()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.deleteTribunal(id, true);
        subject.next('');
        subject.complete();
      });
  }

  /**
   * TRACK BY
   */

  trackByFunction(index, item) {
    return index;
  }

  trackByFunctionTeacher(index, item) {
    return item.id;
  }

  /**
   * API CALLS
   */

  createTribunal(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tribunalSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateTribunalHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateDeliveryDate(objectToPass) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.customDeliveryDateSE.update(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateDeliveryDateHandler(res),
          },
        ]);
      });
  }

  createCustomDeliveryDate(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.customDeliveryDateSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateCustomDeliveryDateHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getTeacherSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    this.teacherSE.getTeachersSelector();
    this.teacherSE
      .getResult()
      .pipe(
        takeUntil(this._unsubInd),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        this.successGetTeacherSelectorHandler(res);
        this._unsubInd.next('');
      });
    this.teacherSE
      .getResultError()
      .pipe(
        takeUntil(this._unsub),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this.generalLoaderSE.removeFromLoaderAmount();
        this._unsub.next('');
      });
  }

  createTeacherProject(objectToPass: { projectId: number; teacherId: number }) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.createTeacherTribunal(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateTeacherProjectHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteCustomDeliveryDate(ids) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.customDeliveryDateSE.delete(behaviorSubject, ids);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteCustomDeliveryDateHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteTribunal(id, force = false) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tribunalSE.delete(behaviorSubject, id, force);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteTribunalHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
          {
            method: () => this.ifCannotDeleteBecauseStudentsInTribunal(id),
            error: true,
          },
        ]);
      });
  }

  deleteProjectTeacher(ids) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.delete(behaviorSubject, ids);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteProjectTeacherHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
  getTeacherStateSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.getTeacherStateSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetTeacherStateSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getStudentProjectStatusSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tribubnalStudentStateSE.getStateSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successGetStudentProjectStatusSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateTeacherStatus(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.updateProjectTeacher(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateTeacherStatusHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateTribunalStudent(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tribunalSE.editTribunalStudents(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateTribunalStudentHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getStudentByPromotionSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.getStudentsByPromotionSelector(
      behaviorSubject,
      this.promotionId
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetStudentByPromotionSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  sendTeacherTribunalDates(objectToPass: any) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.sendTeacherTribunalDates(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successSendTeacherTribunalDatesHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  sendStudentDates(objectToPass: any) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.sendStudentDates(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successSendStudentDatesHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
