import { CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StaticUtilitiesService } from '@quasar-dynamics/basic-designsystem';
import { iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ModuleService } from 'src/app/Services/Api/Module.service';
import { EventEmitter } from '@angular/core';

export interface iSession {
  name: string | null;
}

export interface iMockedDataItem {
  name: string | null;
  sessions: iSession[];
}

@Component({
  selector: 'DetailView-Content',
  templateUrl: './DetailView-Content.component.html',
  styleUrls: ['./DetailView-Content.component.scss'],
})
export class DetailViewContentComponent implements OnInit {
  @Output() onCreate = new EventEmitter<any>();

  @Input() isEditing: boolean = false;
  @Input() promotion: number = 0;
  @Input() modules: any[] = [];

  constructor(private moduleSE: ModuleService) {}

  ngOnInit() {}

  /**
   * HANDLERS
   */

  successCreateModuleHandler(res: iResultHttp) {
    let { data } = res;
    this.onCreate.emit(data);

  }

  /**
 * FUNCTIONALITIES
   */

  handleCreateModule(event){
    this.onCreate.emit(event);
  }

  addModule() {
    this.createModule({ promotion: this.promotion });
  }

  /**
   * API CALLS
   */

  createModule(objectToPass) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.moduleSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successCreateModuleHandler(res),
        });
      });
  }
}
