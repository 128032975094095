import { iCustomCheckboxOptions } from '../Interfaces/Utils/iCustomCheckboxOptions';

export class DetailViewCalendarClass {
  _promotionId: number = 0;
  
  isMaster: boolean = false;
  isCurso: boolean = false;

  checkBoxesArray: iCustomCheckboxOptions[] = []

  checkBoxesArrayMaster: iCustomCheckboxOptions[] = [
    {
      active: true,
      text: 'Máster',
      color: '#3F43AD',
      father: true,
      group: 'master',
      landmarkTypeId: null,
    },
    {
      active: true,
      text: 'Clases',
      color: '#3F43AD',
      group: 'master',
      moveRight: true,
      landmarkTypeId: [2],
    },
    {
      active: true,
      text: 'Tutoría - Profesores',
      color: '#6569BD',
      group: 'master',
      moveRight: true,
      landmarkTypeId: [3],
    },
    {
      active: true,
      text: 'Tutoría - Alumnos',
      color: '#6569BD',
      group: 'master',
      moveRight: true,
      landmarkTypeId: [16],
    },
    {
      active: true,
      text: 'Tribunal',
      color: '#8C8ECE',
      group: 'master',
      moveRight: true,
      landmarkTypeId: [4, 17, 18],
    },
    {
      active: true,
      text: 'Otros',
      color: '#B2B4DE',
      group: 'master',
      moveRight: true,
      landmarkTypeId: [5],
    },
    {
      active: true,
      text: 'Alquiler',
      color: '#FFB4B4',
      landmarkTypeId: [8],
    },
    {
      active: true,
      text: 'Eventos',
      color: '#FFC7FD',
      landmarkTypeId: [9],
    },
    {
      active: true,
      text: 'Festivos',
      color: '#B5B5B5',
      landmarkTypeId: [10],
    },
    {
      active: true,
      text: 'No lectivos',
      color: '#E7E7E7',
      landmarkTypeId: [11],
    },
  ];

  checkBoxesArrayCursos: iCustomCheckboxOptions[] = [
    {
      active: true,
      text: 'Cursos',
      color: '#78E6D0',
      moveRight: false,
      father: true,
      group: 'cursos',
      landmarkTypeId: null,
    },
    {
      active: true,
      text: 'Clases',
      color: '#78E6D0',
      group: 'cursos',
      moveRight: true,
      landmarkTypeId: [12],
    },
    {
      active: true,
      text: 'Otros',
      color: '#ADFFEF',
      group: 'cursos',
      moveRight: true,
      landmarkTypeId: [13],
    },
    {
      active: true,
      text: 'Alquiler',
      color: '#FFB4B4',
      landmarkTypeId: [8],
    },
    {
      active: true,
      text: 'Eventos',
      color: '#FFC7FD',
      landmarkTypeId: [9],
    },
    {
      active: true,
      text: 'Festivos',
      color: '#B5B5B5',
      landmarkTypeId: [10],
    },
    {
      active: true,
      text: 'No lectivos',
      color: '#E7E7E7',
      landmarkTypeId: [11],
    },
  ];
}
