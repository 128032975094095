<div class="customInputContainer" [class.disabled]="disabled" [class.readOnly]="readOnly">
  <div class="labelContainer" *ngIf="label != '' || cantidadCaracteres != null && cantidadMax != null">
    <label *ngIf="label != ''" for="">{{label}}<sup *ngIf="required" [ngStyle]="{'color': 'red'}">*</sup></label>
    <label class="caracteres" *ngIf="cantidadCaracteres != null && cantidadMax != null">
      <ng-container *ngIf="cantidadCaracteres != null">{{cantidadCaracteres}}</ng-container>
      <ng-container *ngIf="cantidadCaracteres == null">0</ng-container>
      /
      {{cantidadMax}}
      Carácteres
    </label>
  </div>
  <div class="inputContainer" [class.error]="error" *ngIf="type != 'date'">
    <img *ngIf="icon != '' && icon.includes('/assets/')" (click)="switchIcon()" [src]="icon" alt="">
    <mat-icon class="material-icons-outlined" *ngIf="icon != '' && !icon.includes('/assets/')"
      (click)="switchIcon()">{{icon}}</mat-icon>
    <input [type]="type" [min]="0" [ngModel]="model" [placeholder]="placeHolder" (ngModelChange)="onModelChange($event)"
      [readonly]="readOnly">
  </div>
  <ng-container *ngIf="type == 'date'">
    <div class="dateContainer">
      <input matInput [matDatepicker]="picker" readonly="true" [value]="fecha" (dateChange)="emitDate($event.value)">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </ng-container>
</div>