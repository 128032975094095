import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iDate, iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ClassroomService } from 'src/app/Services/Api/Classroom.service';
import { SlotService } from 'src/app/Services/Api/Slot.service';

@Component({
  selector: 'AddTribunal-Popup',
  templateUrl: './AddTribunal-Popup.component.html',
  styleUrls: ['./AddTribunal-Popup.component.scss'],
})
export class AddTribunalPopupComponent implements OnInit {
  dateCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Fecha',
    type: 'date',
    required: true,
    label: 'Fecha',
  };

  slotsSelectorOptions: iOptionsSelector = {
    items: [],
    label: 'Duración de los slots',
    placeholder: 'Selecciona una duración',
    required: true,
    bindLabel: 'slotMinutes',
    bindValue: 'id',
  };
  
  aulaSelectorOptions: iOptionsSelector = {
    items: [],
    label: 'Aula',
    placeholder: 'Selecciona un aula',
    required: true,
    bindLabel: 'name',
    bindValue: 'id',
  };

  nameCustonInputOptions: iOptionsCustomInput = {
    placeholder: 'Nombre del tribunal',
    type: 'text',
    required: true,
    label: 'Nombre del tribunal',

  }

  startHourCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Hora de inicio',
    type: 'time',
    required: true,
    label: 'Hora de inicio',
  };

  endHourCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Hora de fin',
    type: 'time',
    required: true,
    label: 'Hora de fin',
  };

  ngModels:any = {
    startDate: null,
    startHour: null,
    endHour: null,
    slotId: null,
    classroomId: null,
    name: null
  }

  isObjectComplete:boolean = false;


  constructor(
    public dialogRef: MatDialogRef<AddTribunalPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private generalLoaderSE: GeneralLoaderService,
    private slotSE:SlotService,
    private classroomSE:ClassroomService
  ) {}

  ngOnInit() {
    this.getSlotsDuration()
    this.getClassroomSelector()
  }

  /**
   * HANDLERS
   */

  successGetSlotsDurationHandler(res: iResultHttp) {
    const { data } = res;
    this.slotsSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetClassroomSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.aulaSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.closePopup('cancel');
  }

  getSelectedDate(event){
    this.ngModels.startDate = iDate.javascriptConvert(new Date(event)).toStringDate('JAP');
  }

  formatBody(){
    this.closePopup(this.ngModels)
  }

  checkFullObject(){
    this.isObjectComplete = !Object.values(this.ngModels).some(model => !model)
  }

  /**
   * API CALLS
   */

  getSlotsDuration() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.slotSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetSlotsDurationHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getClassroomSelector() {
  this.generalLoaderSE.addToLoaderAmount();
  const subject = StaticUtilitiesService.createSubject();
  const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
  this.classroomSE.getSelector(behaviorSubject);
  behaviorSubject
    .pipe(
      takeUntil(subject),
      filter((res) => res)
    )
    .subscribe((res: iResultHttp) => {
      StaticUtilitiesService.apiResponseHandler(res, subject, [
      {
        method: () => this.successGetClassroomSelectorHandler(res),
      },
      {
        method: () => this.generalLoaderSE.removeFromLoaderAmount(),
        error:true
      }
     ]);
    });
  }
}
