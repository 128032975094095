import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';

export interface iBlueDataPicker extends iOptionsCustomInput {
  type: 'date',
  readonly: boolean,
}

@Component({
  selector: 'blue-data-picker',
  templateUrl: './blue-data-picker.component.html',
  styleUrls: ['./blue-data-picker.component.scss'],
})
export class BlueDataPickerComponent implements OnInit {
  
  private _date: Date | null = new Date();
  
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() optionsCustomInput: iBlueDataPicker = {
    placeholder: 'Fecha',
    readonly: true,
    type: 'date',

  };
  @Input() 
  set date(value: Date | null ) {
    if(value){
      this._date = new Date(value);
    } else {
      this._date = null;
    }
  }
  get date(): Date | null {
    return this._date;
  }

  name: string = new Date().valueOf().toString();
  customInputValue: any = '';

  constructor() { }

  ngOnInit() {
  }

  sendNgModel(event) {
    this.change.emit(event);
  }
}
