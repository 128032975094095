import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { iDate } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'VariosAlumnosSubirExcel',
  templateUrl: './VariosAlumnosSubirExcel.component.html',
  styleUrls: ['./VariosAlumnosSubirExcel.component.scss']
})
export class VariosAlumnosSubirExcelComponent implements OnInit {
  @Output() isExcelLoaded = new EventEmitter();
  
  excel:any = null;
  isDraggedOver: boolean = false;
  data:any = {

  }
  constructor(private staticUtilitiesSE:StaticUtilitiesService) { }

  ngOnInit() {
  }

  /**
   * FUNCTIONALITY
   */

  onDrop(event: any) {
    this.isDraggedOver = false;
    event.preventDefault();
    this.updateExcel(event)
    // Aquí puedes realizar las acciones necesarias con el archivo
  }
  onDragOver(event: any) {
    event.preventDefault();
    this.isDraggedOver = true;
  }
  dragleave(event: any) {
    event.preventDefault();
    this.isDraggedOver = false;
  }

  updateExcel(excel: any) {
    this.excel = excel;
    if (excel[0].file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && excel[0].file.type != "application/vnd.ms-excel") {
      StaticUtilitiesService.showError("El archivo no tiene el formato correcto.")
      this.staticUtilitiesSE.closeLoader();

    } else {
      const reader: FileReader = new FileReader();
      reader.readAsBinaryString(excel[0].file);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
        this.getDataFromExcel(data);

      };
    }
  }
  getDataFromExcel(data: Array<any>) {
    const alumnos = data.map((element: any) => {
       
      return {
        name: element["Nombre"],
        surname: element["Apellidos"],
        email: element["Correo electrónico"],
        dni: element["DNI"],
        phone: element["Teléfono de contacto"],
        phonePrefix: '+ ' + element["Prefijo (sin el + delante. Ejemplo 34)"],  
      };
    });
    this.isExcelLoaded.emit({isExcelLoaded:true,data:alumnos})
  }


}
