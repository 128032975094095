import { Injectable } from '@angular/core';

export interface iEventTypesToRedirect {
  redirectsTo: string;
  type: number;
}

@Injectable({
  providedIn: 'root',
})
export class EventTypesService {
  private static eventTypesToRedirect: iEventTypesToRedirect[] = [
    {
      type: 2,
      redirectsTo: 'master',
    },
    {
      type: 3,
      redirectsTo: 'master',
    },
    {
      type: 4,
      redirectsTo: 'master',
    },
    {
      type: 12,
      redirectsTo: 'curso',
    },
    {
      type: 6,
      redirectsTo: 'curso',
    },
    {
      type: 7,
      redirectsTo: 'incompany',
    },
    {
      type:17,
      redirectsTo:'master'
    },
    {
      type:16,
      redirectsTo:'master'
    },
    {
      type:18,
      redirectsTo:'master'
    },
  ];

  constructor() {}

  public static getEventTypesToRedirect() {
    return this.eventTypesToRedirect;
  }
}
