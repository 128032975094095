import {
  iMasterOtros,
  iCursosOtros,
  iIncompanyOtros,
  iAlquiler,
  iEvento,
  iNoLectivo,
  iMasterClass,
  iMasterTribunalEntregas,
  eEventCalendarType,
  iMasterTribunalStudent,
  iMasterTribunal,
  iMasterTutoriaTeacher,
  iMasterTutoriaStudent,
  iCursosClass,
} from '../Interfaces/Utils/iEventCalendar';
import { iCourse } from '../Interfaces/iCourse';

export class EventCalendar {
  // Crea un nuevo objeto de evento de calendario
  private returnMasterOtros(type, startDate, startHour,promotionFormation?:{promotionId:number,formationId:number}) {
    let masterOtros: iMasterOtros = {
      type: type,
      className: 'master-otros',
      startDate: startDate,
      endHour: null,
      edition: (promotionFormation) ? promotionFormation.promotionId : null,
      formation: (promotionFormation) ? promotionFormation.formationId : null,
      id: null,
      observations: '',
      startHour: startHour,
      subtitle: null,
      classroom: null,
      teachers: null,
      title: '',
      location: '',
    };
    return masterOtros;
  }

  private returnCursosOtros(type, startDate, startHour,promotionFormation?:{promotionId:number,formationId:number}) {
    let cursosOtrosEvent: iCursosOtros = {
      type: type,
      className: 'cursos-otros',
      startDate: startDate,
      endHour: null,
      edition: (promotionFormation) ? promotionFormation.promotionId : null,
      formation: (promotionFormation) ? promotionFormation.formationId : null,
      id: null,
      observations: '',
      startHour: startHour,
      subtitle: null,
      classroom: null,
      teachers: null,
      title: '',
      location: '',
    };

    return cursosOtrosEvent;
  }

  private returnIncompaniesOtros(type, startDate, startHour) {
    let incompanyOtrosEvent: iIncompanyOtros = {
      type: type,
      className: 'incompany-otros',
      startDate: startDate,
      edition: null,
      endHour: null,
      formation: null,
      id: null,
      observations: '',
      startHour: startHour,
      status: null,
      subtitle: null,
      classroom: null,
      teachers: null,
      title: '',
      company: null,
      location: null,
    };
    return incompanyOtrosEvent;
  }

  private returnAlquiler(type, startDate, startHour) {
    let alquilerEvent: iAlquiler = {
      type: type,
      className: 'alquiler',
      startDate: startDate,
      id: null,
      observations: '',
      title: '',
      company: null,
      classroom: null,
      endHour: null,
      allDay: false,
      endDate: null,
      startHour: startHour,
      subtitle: null,
    };
    return alquilerEvent;
  }

  private returnEvento(type, startDate, startHour) {
    let eventoEvent: iEvento = {
      type: type,
      className: 'eventos',
      startDate: startDate,
      id: null,
      observations: '',
      title: '',
      company: null,
      classroom: null,
      endHour: null,
      allDay: false,
      endDate: null,
      startHour: startHour,
      subtitle: null,
      location: '',
      teachers: null,
    };
    return eventoEvent;
  }

  private returnNoLectivo(type, startDate, startHour) {
    let noLectivoEvent: iNoLectivo = {
      type: type,
      className: 'no-lectivos',
      startDate: startDate,
      endDate: null,
      id: null,
      allDay: true,
      observations: '',
      title: '',
    };
    return noLectivoEvent;
  }

  // Abre un nuevo evento de calendario
  private returnMasterClass(type, landmark) {
    let masterClass: iMasterClass = {
      type: type,
      url: landmark.meetingUrl,
      classroom: landmark.classroomId,
      status: landmark.sessionStateId,
      formation: landmark.formationId,
      edition: landmark.promotionId,
      className: landmark.className,
      startDate: landmark.startDate,
      startHour: landmark.startHour,
      endHour: landmark.endHour,
      id: landmark.id,
      observations: landmark.observations,
      subtitle: landmark.subtitle,
      title: landmark.title,
      teachers: landmark.teachers ,
    };
    return masterClass;
  }
  private returnMasterTribunalEntregas(type, landmark) {
    let masterTribunalEntregas: iMasterTribunalEntregas = {
      type: type,
      allDay: landmark.allDay,
      formation: landmark.formationId,
      edition: landmark.promotionId,
      className: landmark.className,
      startDate: landmark.startDate,
      startHour: landmark.startHour,
      endHour: landmark.endHour,
      id: landmark.id,
      observations: landmark.observations,
      subtitle: landmark.subtitle,
      title: landmark.title,
    };
    return masterTribunalEntregas;
  }
  private returnMasterTribunalStudent(type, landmark) {
    let masterTribunalStudent: iMasterTribunalStudent = {
      type: type,
      student: landmark.studentId,
      classroom: landmark.classroomId,
      url: landmark.meetingUrl,
      teachers: this.mapTeachers(landmark.teachersIds),
      formation: landmark.formationId,
      edition: landmark.promotionId,
      className: landmark.className,
      startDate: landmark.startDate,
      startHour: landmark.startHour,
      endHour: landmark.endHour,
      id: landmark.id,
      observations: landmark.observations,
      subtitle: landmark.subtitle,
      title: landmark.title,
    };
    return masterTribunalStudent;
  }

  private returnMasterTeacherTribunal(type, landmark) {
    let masterTribunalTeacher: iMasterTribunal = {
      type: type,
      subtitle: landmark.subtitle,
      classroom: landmark.classroomId,
      teachers: landmark.teachersIds,
      className: landmark.className,
      startDate: landmark.startDate,
      startHour: landmark.startHour,
      endHour: landmark.endHour,
      id: landmark.id,
      observations: landmark.observations,
      title: landmark.title,
      allDay: landmark.allDay,
      asistant: landmark.asistant,
      modality: landmark.attendanceModality,
    };
    return masterTribunalTeacher;
  }

  private returnMasterTutoriaProfesor(type, landmark) {
    let masterTutoringTeacher: iMasterTutoriaTeacher = {
      type: type,
      status: landmark.tutoringStateId,
      subtitle: landmark.subtitle,
      classroom: landmark.classroomId,
      teachers: this.mapTeachers(landmark.teachersIds),
      className: landmark.className,
      startDate: landmark.startDate,
      startHour: landmark.startHour,
      endHour: landmark.endHour,
      id: landmark.id,
      observations: landmark.observations,
      title: landmark.title,
    };
    return masterTutoringTeacher;
  }

  private returnMasterTutoriaStudent(type, landmark) {
    let masterTutoringStudent: iMasterTutoriaStudent = {
      type: type,
      edition: landmark.promotionId,
      formation: landmark.formationId,
      modality: landmark.tutoringAttendance,
      student: landmark.studentId,
      url: landmark.meetingUrl,
      status: landmark.tutoringStateId,
      subtitle: landmark.subtitle,
      classroom: landmark.classroomId,
      teachers: landmark.teachersIds,
      className: landmark.className,
      startDate: landmark.startDate,
      startHour: landmark.startHour,
      endHour: landmark.endHour,
      id: landmark.id,
      observations: landmark.observations,
      title: landmark.title,
    };
    return masterTutoringStudent;
  }

  private returnCourseClass(type, landmark) {
    let courseClass: iCursosClass = {
      type: type,
      edition: landmark.promotionId,
      formation: landmark.formationId,
      url: landmark.meetingUrl,
      status: landmark.sessionStateId,
      subtitle: landmark.subtitle,
      classroom: landmark.classroomId,
      teachers: landmark.teachers,
      className: landmark.className,
      startDate: landmark.startDate,
      startHour: landmark.startHour,
      endHour: landmark.endHour,
      id: landmark.id,
      observations: landmark.observations,
      title: landmark.title,
    };
    return courseClass;
  }

  private returnMasterOtrosSetted(type, landmark) {
    let masterOtros: iMasterOtros = {
      type: type,
      edition: landmark.promotionId,
      formation: landmark.formationId,
      subtitle: landmark.subtitle,
      location: landmark?.location ?? '',
      classroom: landmark.classroomId,
      teachers:  this.mapTeachers( landmark.teachersIds ),
      className: landmark.className,
      startDate: landmark.startDate,
      startHour: landmark.startHour,
      endHour: landmark.endHour,
      id: landmark.id,
      observations: landmark.observations,
      title: landmark.title,
    };
    return masterOtros;
  }

  private returnNoLectivoEvent(type, landmark) {
    let noLectivo: iNoLectivo = {
      type: type,
      className: landmark.className,
      startDate: landmark.startDate,
      id: landmark.id,
      observations: landmark.observations,
      title: landmark.title,
      allDay: landmark.allDay,
      endDate: landmark.endDate,
    };
    return noLectivo;
  }

  private returnAlquilerEvent(type, landmark) {
    let alquiler: iAlquiler = {
      type: type,
      classroom: landmark.classroomId,
      company: landmark.companyId,
      endHour: landmark.endHour,
      startHour: landmark.startHour,
      subtitle: landmark.subtitle,
      className: landmark.className,
      startDate: landmark.startDate,
      id: landmark.id,
      observations: landmark.observations,
      title: landmark.title,
      allDay: landmark.allDay ?? false,
      endDate: landmark.endDate,
    };
    return alquiler;
  }

  private returnEventoEvent(type, landmark) {
    let evento: iEvento = {
      type: type,
      location: landmark.location,
      teachers: this.mapTeachers(landmark.teachersIds),
      classroom: landmark.classroomId,
      company: landmark.companyId,
      endHour: landmark.endHour,
      startHour: landmark.startHour,
      subtitle: landmark.subtitle,
      className: landmark.className,
      startDate: landmark.startDate,
      id: landmark.id,
      observations: landmark.observations,
      title: landmark.title,
      allDay: landmark.allDay ?? false,
      endDate: landmark.endDate,
    };
    return evento;
  }

  private returnCursosOtrosEvent(type, landmark) {
    let cursosOtros: iCursosOtros = {
      type: type,
      location: landmark.location,
      teachers: this.mapTeachers(landmark.teachersIds),
      classroom: landmark.classroomId,
      endHour: landmark.endHour,
      startHour: landmark.startHour,
      subtitle: landmark.subtitle,
      className: landmark.className,
      startDate: landmark.startDate,
      id: landmark.id,
      observations: landmark.observations,
      title: landmark.title,
      edition: landmark.promotionId,
      formation: landmark.formationId,
    };
    return cursosOtros;
  }

  public createEventCalendarObject(
    type: eEventCalendarType,
    startDate: any,
    startHour: string,
    promotionFormation?:{promotionId:number,formationId:number}
  ) {
    switch (type) {
      case eEventCalendarType.MasterOtros:
        return this.returnMasterOtros(type, startDate, startHour,promotionFormation);

      case eEventCalendarType.CursosOtros:
        return this.returnCursosOtros(type, startDate, startHour,promotionFormation);

      case eEventCalendarType.IncompanyOtros:
        return this.returnIncompaniesOtros(type, startDate, startHour);

      case eEventCalendarType.Alquiler:
        return this.returnAlquiler(type, startDate, startHour);

      case eEventCalendarType.Evento:
        return this.returnEvento(type, startDate, startHour);

      case eEventCalendarType.NoLectivo:
        return this.returnNoLectivo(type, startDate, startHour);
      default:
        throw new Error('Invalid type');
    }
  }

  public setEventCalendarObject(type: eEventCalendarType, landmark: any) {
    switch (type) {
      case eEventCalendarType.MasterClass:
        return this.returnMasterClass(type, landmark);

      case eEventCalendarType.MasterTribunalEntregas:
        return this.returnMasterTribunalEntregas(type, landmark);

      case eEventCalendarType.MasterTribunalStudent:
        return this.returnMasterTribunalStudent(type, landmark);

      case eEventCalendarType.MasterTribunales:
        return this.returnMasterTeacherTribunal(type, landmark);

      case eEventCalendarType.MasterTutoriaTeacher:
        return this.returnMasterTutoriaProfesor(type, landmark);

      case eEventCalendarType.MasterTutoriaStudent:
        return this.returnMasterTutoriaStudent(type, landmark);

      case eEventCalendarType.CursosClases:
        return this.returnCourseClass(type, landmark);

      case eEventCalendarType.MasterOtros:
        return this.returnMasterOtrosSetted(type, landmark);

        case eEventCalendarType.NoLectivo:
        return this.returnNoLectivoEvent(type, landmark);

        case eEventCalendarType.Alquiler:
        return this.returnAlquilerEvent(type, landmark);

        case eEventCalendarType.Evento:
        return this.returnEventoEvent(type, landmark);

        case eEventCalendarType.CursosOtros:
        return this.returnCursosOtrosEvent(type, landmark);

      default:
        throw new Error('Invalid type');
    }
  }

  private mapTeachers(teachers) {
    if (!teachers) return [];
    return teachers.map((teacher) => teacher.id);
  }

  static returnEditableEventsInCalendar(){
    return [
      eEventCalendarType.MasterOtros,
      eEventCalendarType.NoLectivo,
      eEventCalendarType.Alquiler,
      eEventCalendarType.Evento,
      eEventCalendarType.CursosOtros,
      eEventCalendarType.IncompanyOtros,
    ]
  }
}
