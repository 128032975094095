import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StaticUtilitiesService, iResultHttp, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { PipedriveService } from 'src/app/Services/Api/Pipedrive.service';

@Component({
  selector: 'app-ConfirmarAccionPerder-Popup',
  templateUrl: './ConfirmarAccionPerder-Popup.component.html',
  styleUrls: ['./ConfirmarAccionPerder-Popup.component.scss']
})
export class ConfirmarAccionPerderPopupComponent extends iUnsubscribeDestroy implements  OnInit {

  data: any = {
    reason: null,
  };
  type:string = '';
  objectToLoss:any = {
    id:null,
    reason:null

  };

  constructor(public dialogRef: MatDialogRef<ConfirmarAccionPerderPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,private pipeDriveSE:PipedriveService) {
    super();
  }

  ngOnInit() {
    this.data.id = this.modalData.data.id;
    this.type = this.modalData.type;
     

  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  switchTypes(){
    switch (this.type) {
      case 'crm':
        this.lossCRMCard();
        break;
      case 'enrollment':
        this.objectToLoss.id = (this.modalData?.data?.type?.pipeDriveDealId)?? this.modalData?.data?.item?.pipedriveDealId;
        this.objectToLoss.enrollment = (this.modalData?.data?.type?.id) ?? this.modalData.data.item.id[0];
        this.objectToLoss.reason = this.data.reason;
         
         
         
        this.lossBrainCard();

        delete this.objectToLoss.enrollment;
        delete this.objectToLoss.reason;
        this.objectToLoss.id = null;
        break;
        
        case 'formalizedProposal':
        this.objectToLoss.id = this.modalData.data.item.pipedrive_deal_id;
        this.objectToLoss.formalizedProposal = this.modalData.data.item.id;
        this.objectToLoss.reason = this.data.reason;
        this.lossBrainCard();
        delete this.objectToLoss.formalizedProposal;
        delete this.objectToLoss.reason;
        this.objectToLoss.id = null;
        break;
    
      default:
        break;
    }
  }

  /**
   * API CALLS
   */

  lossCRMCard(){
    this.pipeDriveSE.lossCRMCard(this.data);
    this.pipeDriveSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
      if(!res){return}
       
      StaticUtilitiesService.showFeedback('La propuesta ha sido perdida');
      this.closePopup(true);
      this._unsubInd.next('')
    })
    this.pipeDriveSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  lossBrainCard(){
     
    this.pipeDriveSE.lossBrainCard(this.objectToLoss)
    this.pipeDriveSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('La propuesta ha sido perdida');
      this.closePopup(true);
      this._unsubInd.next('')
    })
    this.pipeDriveSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }
}
