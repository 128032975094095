
<div class="gridAndPagination">

<div class="fullView" skeleton [isDataLoaded]="areReadyAllIncompanies">
  <p class="noData" *ngIf="allIncompanies.length === 0">No hay elementos para mostrar</p>

  <!-- Tarjetas -->

  <div class="card" (click)="goToDetailView(incompany.formation.id)" *ngFor="let incompany of allIncompanies | paginate:{
    itemsPerPage: filterAllIncompanies.num_devoluciones,
    currentPage: filterAllIncompanies.num_pagina,
    totalItems: totalIncompanies
  }">
    <div class="spaceBetween">
      <p class="title">{{incompany.formation.name ?? '-'}}</p>
      <status-pill [active]="incompany.formation.active" ></status-pill>
    </div>
    <p class="textBody">
      {{incompany.formation.description ?? '-'}}
    </p>
    <div class="spaceBetween">
      <p class="title">{{incompany.formation.duration ?? '-'}} H</p>
      <span class="modality">
        <span class="modality-pill" *ngIf="incompany.formation.mode !== 'mixto'">
          <mat-icon>done</mat-icon>
          <p class="text">{{incompany.formation.mode}}</p>
        </span>

        <span class="modality-pill" *ngIf="incompany.formation.mode == 'mixto'">
          <mat-icon>done</mat-icon>
          <p class="text">Presencial</p>
        </span>
        
        <span class="modality-pill" *ngIf="incompany.formation.mode == 'mixto'">
          <mat-icon>done</mat-icon>
          <p class="text">Online</p>
        </span>
      </span>
    </div>
  </div>

</div>

<pagination-controls [autoHide]="true" previousLabel="Anterior"  nextLabel="Siguiente" (pageChange)="pageChangeEmmiter($event)"></pagination-controls>

</div>