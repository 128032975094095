import { Component, Input, OnInit } from '@angular/core';
import { PopupService, iDate, iResultHttp, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AddIEMUserComponent } from 'src/app/Popups/AddPopup/AddIEMUser/AddIEMUser.component';
import { PermissionService } from 'src/app/Services/Api/Permission.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iUserFilter } from 'src/app/Shared/Interfaces/iUser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'Configuracion-Permisos',
  templateUrl: './Configuracion-Permisos.component.html',
  styleUrls: ['./Configuracion-Permisos.component.scss']
})
export class ConfiguracionPermisosComponent extends iUnsubscribeDestroy implements OnInit {

  isUserSelected:boolean = false;
  isUserListLoaded:boolean = false;

  rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  permisionList: any[] = [];
  userPermisions:any[] = [];
  userList: any[] = [];
  userSelected:any = null;

  total:number = 0;

  filtersUsers:iUserFilter = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  }

  constructor(private permissionSE: PermissionService, private userSE:UserService, private popupSE:PopupService) {
    super();
  }

  ngOnInit() {
    this.rolesAndPermissions = StaticUtilitiesService.getUserPermissionsAndRoles()

    this.getAllPermisions();
    this.getAllIEMUsers();
  }

  /**
   * FUNCTIONALITY
   */

  changeTheView(event){
    this.isUserSelected = event;
  }
  getUserSelected(event){
    this.userSelected = event;
    this.getPermisionByUserId(event.id);
  }
  addNewIEMUser(){
    this.popupSE.openPopup(AddIEMUserComponent);
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd9)).subscribe((res:any)=>{
      if(!res){return}
      this.getAllIEMUsers();
      this._unsubInd9.next('');
    });
  }
  refresh(event){
    if(event) this.getAllIEMUsers()
  }

  checkRolesAndPermissions(){
    return this.rolesAndPermissions.roles.includes('ROLE_ADMIN') || this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') 
  }

  /**
   * API CALLS
   */

  getAllPermisions() {
    this.permissionSE.getPermissionList();
    this.permissionSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      let {data} = res;
       
      this.permisionList = data;
      this._unsubInd.next('')
    })
    this.permissionSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  getAllIEMUsers() {
    this.userSE.getIEMUsers(this.filtersUsers);
    this.userSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      let {data, total} = res.data;
      this.total = total;
      this.userList = data.map((item)=>{
        return{
          ...item, 
          fullName: `${item?.name} ${item?.surname}`, 
          state:(item.active)?'Activo':'Inactivo' ,
          registerDate: iDate.javascriptConvert(new Date(item.registerDate)).toStringDate('EU') 
        }});
      this.isUserListLoaded = true;
      this._unsubInd2.next('')
    })
    this.userSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  getPermisionByUserId(id:number){
    this.permissionSE.getPermissionByUserId(id);
    this.permissionSE.getResultGetPermisionByUserId().pipe(takeUntil(this._unsubInd3)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      let {data} = res;
       
      this.userPermisions = data;
      this.isUserSelected = true;
      this._unsubInd3.next('')
    })
    this.permissionSE.getResultGetPermisionByUserIdError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

}
