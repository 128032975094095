import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'EditHonorario-Popup',
  templateUrl: './EditHonorario-Popup.component.html',
  styleUrls: ['./EditHonorario-Popup.component.scss']
})
export class EditHonorarioPopupComponent implements OnInit {

  ngModels = {
    hourlyFee: null,
    generalFee: null,
  }

  hourlyFeeCustomInputOptions:iOptionsCustomInput = {
    placeholder: 'Honorario €/h',
    type: 'number',
    label: 'Honorario €/h',
  }

  generalFeeCustomInputOptions:iOptionsCustomInput = {
    placeholder: 'Honorarios',
    type: 'number',
    label: 'Honorarios',
  }

  constructor(public dialogRef: MatDialogRef<EditHonorarioPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) { }

  ngOnInit() {
    this.setData()
  }

    /**
  * FUNCTIONALITY
  */
    closePopup(returnValue?: any) {
      let p = { returnValue: returnValue };
      this.dialogRef.close(p);
    }

    setData(){
      this.ngModels.hourlyFee = this.modalData.data.hourlyFee;
      this.ngModels.generalFee = this.modalData.data.generalFee;
    }

    onFinalizar(){
      this.closePopup(this.ngModels);
    }

    setFeeToZeroIfEmpty(key){
      if(this.ngModels[key] == ''){
        this.ngModels[key] = 1;
      }
    }
  
}
