<div class="facturasGeneralContainer" >
  <div class="factura" *ngFor="let producto of productos" >
    <pill classList="facturas" skeleton [isDataLoaded]="isDataLoaded" (click)="controlOpeneds(producto?.formation?.id)" [class.opened]="productsOpened.includes(producto?.formation?.id)">
      <ng-container>
        <div class="container">
          <div>
            <span>{{producto?.formation?.type}}</span>
            <p class="titulo">
              {{producto?.formation?.name}}
            </p>
          </div>
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
      </ng-container>
    </pill>
    <div class="ediciones" [class.opened]="productsOpened.includes(producto?.formation?.id)">
      <div class="edicion" *ngFor="let promotion of producto?.formation?.promotions">
        <span>{{promotion?.edition}}</span>
        <div>
          <span>{{promotion?.startDate | date:'dd/MM/YYYY'}}</span>
          <span>{{promotion?.endDate | date:'dd/MM/YYYY'}}</span>
          <span class="capitalize">{{promotion?.position}}</span>
        </div>
      </div>
    </div>
  </div>

  <p class="noData" *ngIf="productos.length == 0">No hay productos relacionados con este profesor</p>
  
  <btn classList="principalButton generalbutton" (click)="openAsociarProductoPopup()"  >
    <ng-container >
      Asociar producto
    </ng-container>
  </btn>
</div>