import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iPromotionFormation } from "./iPromotionFormation";

export interface iModuleFormation {
    id: number,
    subject?: string,
    duration?: number,
    promotionFormation?: iPromotionFormation[],
}

export class iModuleFormation extends iBaseObject {
    static convertFromCollection(ob: any): Array<iModuleFormation> {
        let iModuleFormationCollection: Array<iModuleFormation> = [];
        ob.forEach((element: any) => {
            iModuleFormationCollection.push(this.convertFromObject(element));
        });
        return iModuleFormationCollection;
    }

    static convertFromObject(ob: any): iModuleFormation {
        let iModuleFormationObj = new iModuleFormation();
        iModuleFormationObj.id = ob.id;
        iModuleFormationObj.subject = ob.subject;
        iModuleFormationObj.duration = ob.duration;
        iModuleFormationObj.promotionFormation = ob.promotionFormation ? iPromotionFormation.convertFromCollection(ob.promotionFormation) : [];
        return iModuleFormationObj;
    }

    static createEmptyObject(): iModuleFormation {
        let iModuleFormationObj = new iModuleFormation();
        iModuleFormationObj.subject = '';
        iModuleFormationObj.duration = -1;
        iModuleFormationObj.promotionFormation = [];
        return iModuleFormationObj;
    }
}

export interface iModuleFormationFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    subject?: string,
    duration?: number,
    promotionFormation?: number,
}