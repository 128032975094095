<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>Editar tutoría</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="formLine">
      <add-and-search-selector
        style="width: 100%"
        [selectorOptions]="extraClass.tematicasSelectorOptions"
        [(ngModel)]="extraClass.topicIds"
        (deleteSelector)="deleteTopic($event)"
        (change)="onTopicChange($event); checkIsComplete()"
      ></add-and-search-selector>
    </div>

    <div class="formLine">
      <customInput
        style="width: 100%"
        [options]="extraClass.tematicasDisplaySelectorOptions"
        [(ngModel)]="extraClass.topicDisplay"
        (change)="checkIsComplete()"
      ></customInput>
    </div>

    <div class="formLine">
      <selector
        style="width: 100%"
        [options]="extraClass.tutoringStatusSelectorOptions"
        [(ngModel)]="extraClass.tutoringStateId"
        (change)="checkIsComplete()"
      ></selector>
    </div>

    <div class="formLine">
      <customTextArea
        style="width: 100%"
        [options]="extraClass.observacionesCustomTextareaOptions"
        [(ngModel)]="extraClass.observations"
        (input)="checkIsComplete()"
      ></customTextArea>
    </div>
  </div>
  <div class="footer">
    <btn classList="principalButton" (click)="forceClosePopup()">
      <ng-container> Cancelar </ng-container>
    </btn>
    <btn
      classList="specialButton"
      (click)="onFinalizarClick()"
      [class.disabled]="!extraClass.isActive"
    >
      <ng-container> Finalizar </ng-container>
    </btn>
  </div>
</div>
