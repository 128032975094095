<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>PRE MATRÍCULA</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <btn classList="principalButton" (click)="getURLAutoenrollment()">
      <ng-container>
        Rellenar ficha
      </ng-container>
    </btn>
    <btn classList="principalButton" (click)="reSendEmail()">
      <ng-container>
        Enviar email
      </ng-container>
    </btn>

  </div>
  <!-- <div class="footer">
  </div> -->
</div>