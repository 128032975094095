<ng-container *ngIf="optionsSelector?.readonly">
  <p class="textAlignCenter">{{ findLabel() }}</p>
</ng-container>

<ng-container *ngIf="!optionsSelector?.readonly">
  <selector
    (click)="$event.stopPropagation(); $event.stopImmediatePropagation()"
    [ngModel]="model"
    (ngModelChange)="onModelChange($event)"
    (change)="sendNgModel($event); onNgModelChange($event)"
    [options]="optionsSelector"
  ></selector>
</ng-container>
