<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>{{modalData.data.name}}</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">

    <div class="buttonCrearGrupo" (click)="closePopup('Group')">
      <div class="lineSelector">
        <div class="insideLine">
          <p>Crear Grupo</p>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
    </div>

    <div class="bloqueBotones">


    <p>Cerrar Propuesta Formal</p>

    <div class="buttons">
      
      <btn classList="generalbutton principalButton generalLossButton" (click)="closePopup('Perdida')">
        <ng-container >
          <mat-icon>thumb_down</mat-icon> 
          Perder
        </ng-container>
      </btn>
      <btn classList="generalbutton principalButton generalWinButton" (click)="closePopup('Ganada')">
        <ng-container>
          <mat-icon>thumb_up</mat-icon>
          Ganar
        </ng-container>
      </btn>

    </div>

  </div>


  </div>
  <!-- <div class="footer">
  </div> -->
</div>