<fullContainer>
  <header></header>
  <customSearcher class="calendar" [placeHolder]="'Busca la edición...'"></customSearcher>
  <div class="gantAndfiltersContainer">
    <div class="filters">
      <toggleSubmenu [(ngModel)]="extraClass.timelineSelectedRange" class="secondary activeBlue"
        [elements]="['3 meses', '6 meses', '12 meses', '24 meses']"
        (change)="changeRange(extraClass.timelineSelectedRange)"></toggleSubmenu>
      <btn classList="violetButton fitWidth padding816 height40" (click)="goToCalendar()">Calendario <img
          [src]="IconsHandlerService.getCalendarIcon()" alt="calendar"> </btn>
    </div>
    <gantt [anchoDia]="extraClass.anchoDia" [startDate]="extraClass.startDate" [endDate]="extraClass.endDate"
      [fases]="extraClass.fases"></gantt>
  </div>

</fullContainer>