<div class="fullPage">
  <div class="loginSection">
    <div class="loginBlock">
      <div class="logoIEM">
        <img src="/assets/Images/Logos/LogoIEMLogin.svg" alt="LogoIem">
      </div>
      <div class="textLogin">
        <p>Introduce tu nueva contraseña aquí</p>
      </div>
      <div class="separador">
        <span class="line"></span>
      </div>
      <div class="inputsLogin">
        <CustomInput class="customInputLogin"  [type]="'password'" [icon]="'lock'" [placeHolder]="'Nueva contraseña'" [(ngModel)]="data.password"
        name="user" (change)="checkIfPasswords()" (keyup)="checkPasswords()"></CustomInput>
        <CustomInput class="customInputLogin"  [type]="'password'" [icon]="'lock'" [placeHolder]="'Repetir la contraseña'" [(ngModel)]="data.password2"
        name="password"  (change)="checkIfPasswords()" (keyup)="checkPasswords()" ></CustomInput>
      </div>

      <div class="buttonLogin">
        <btn id='btn' (click)="sendCredentials()"  classList="loginButton" [ngClass]="{disabled: disabled}" > <loaderIcon class="loaderIconWhite" [size]="'small'" *ngIf="isLogging"></loaderIcon> <p *ngIf="!isLogging"> Recuperar la contraseña </p> </btn>
      </div>
    </div>
  </div>
  <div class="imagesSection">
    <div class="textImageBlock">
      <div class="text">
        <p>Profesionales</p>
        <p>que forman profesionales</p>
      </div>
      <div class="textDecoration">
        <img src="/assets/Icons/Decoracion/decoracionLoginIEM.svg" alt="decoracion">
      </div>
    </div>
    <div class="imagesGrid">
      <div class="azulVerdoso"></div>
      <div class="imageTop">
        <img src="/assets/Images/Login/womanTyping.png" alt="Mujer al ordenador">
      </div>
      <div class="imageBottom">
        <img src="/assets/Images/Login/handsTyping.png" alt="Mujer al ordenador">
      </div>
      <div class="grey"></div>
      <div class="violet"></div>

    </div>
  </div>
</div>