<div class="fullContainer">
    <div class="popupContainer">
        <div class="headerAndFooter">
            <div class="title">
                <p>
                    Calendario · Filtros
                </p>
            </div>
            <span class="closeButton" (click)="forceClosePopup()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
        <div class="filtersContainer">
            <div class="selectorLine">
                <selector class="calendarFilters" [(ngModel)]="extraClass.ngModels.events" [options]="extraClass.mainCalendarioSelectorOptions"
                    (change)="getEventTypes($event)"></selector>
            </div>

            <!-- Masters -->
            <div class="selectorLine">
                <selector class="calendarFilters" [(ngModel)]="extraClass.ngModels.selectedMasters"
                    [options]="extraClass.formacionmasterSelectorOptions"
                    (change)="getSelectedItems(extraClass.ngModels.selectedMasters, 'master')"></selector>
            </div>

            <ng-container *ngFor="let selectorOptions of extraClass.selectorOptionsArrayContainer.selectedmasterSelectorOptionsArray; index as index; trackBy:trackByMaster">
                <div class="selectorLine moveToRight">
                    <selector [(ngModel)]="extraClass.ngModels.selectedmasterNgModelsArray[index]" class="calendarFilters" [options]="selectorOptions"></selector>
                </div>
            </ng-container>
            
            <!-- Courses -->
            <div class="selectorLine">
                <selector [(ngModel)]="extraClass.ngModels.selectedCourses" class="calendarFilters" [options]="extraClass.formacioncourseSelectorOptions"
                (change)="getSelectedItems(extraClass.ngModels.selectedCourses, 'course')"
                ></selector>
            </div>
            
            <ng-container *ngFor="let selectorOptions of extraClass.selectorOptionsArrayContainer.selectedcourseSelectorOptionsArray; index as index; trackBy:trackByCourse">
                <div class="selectorLine moveToRight">
                    <selector [(ngModel)]="extraClass.ngModels.selectedcourseNgModelsArray[index]" class="calendarFilters" [options]="selectorOptions"></selector>
                </div>
            </ng-container>

            <!-- Aquí va a ir el ngFor con los cursos seleccionados -->
            <!-- <div class="selectorLine">
                <selector class="calendarFilters " [options]="extraClass.formacionIncompanySelectorOptions"></selector>
            </div> -->
            <!-- Aquí va a ir el ngFor con las incompanies seleccionados -->
            <div class="selectorLine">
                <selector class="calendarFilters" [(ngModel)]="extraClass.ngModels.teachers" [options]="extraClass.profesorSelectorOptions"></selector>
            </div>
            <div class="selectorLine">
                <selector class="calendarFilters" [(ngModel)]="extraClass.ngModels.classrooms" [options]="extraClass.aulaSelectorOptions"></selector>
            </div>
            <!-- <div class="selectorLine">
                <selector class="calendarFilters " [options]="extraClass.empresaSelectorOptions"></selector>
            </div> -->
            <!-- <div class="selectorLine">
                <selector class="calendarFilters" [(ngModel)]="extraClass.ngModels.status" [options]="extraClass.estadoSelectorOptions"></selector>
            </div> -->
        </div>
        <div class="headerAndFooter">
            <btn classList="generalbutton violetButton fitWidth" (click)="onClearFilters()" >Limpiar filtros</btn>
            <btn classList="generalbutton principalButton fitWidth" (click)="applyFilters()">Aplicar</btn>
        </div>
    </div>
</div>