import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'terminar-button-component',
  templateUrl: './terminar-button-component.component.html',
  styleUrls: ['./terminar-button-component.component.scss']
})
export class TerminarButtonComponentComponent implements OnInit {

  classList: string = 'generalbutton sendDates';

  constructor() { }

  ngOnInit() {
    this.classList = 'generalbutton sendDates'
  }
}
