import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { iOptionsSelector } from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'blue-selector',
  templateUrl: './blue-selector.component.html',
  styleUrls: ['./blue-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BlueSelectorComponent), // replace name as appropriate
      multi: true,
    },
  ]
})
export class BlueSelectorComponent implements OnInit {
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() optionsSelector!: iOptionsSelector 
  @Input() big:boolean = false;
  @Input() readonly:boolean = false;
  selectorClassName:string = `blueSelector`

  private _onChange = (_: any) => {};
  private _onTouched = () => {};
  private _oldData;

  _disabled: boolean = false;
  model;

  writeValue(obj: any): void {
    if (obj !== this.model) {
      this.model = obj;
      this._onChange(obj);
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
    // this._onChange(this.model);
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onNgModelChange(event) {
    this._oldData = this.model;
    this.model = event;
  }

  onModelChange($event) {
    this.model = $event;
    this._onChange(this.model);
  }

  name: string = new Date().valueOf().toString();
  customInputValue: any = '';

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['big']){
      if(changes['big'].currentValue){
        this.selectorClassName = this.selectorClassName + ` blueSelector--big`
      }
    }
  }

  sendNgModel(event) {
    this.change.emit(event);
    this.setDataIfBindValueId();
  }

  setDataIfBindValueId() {
    if(this.customInputValue)return
    if (this.optionsSelector.bindValue === 'id') {
      if(this.optionsSelector.multiple?.isMultiple){
        this.customInputValue = this.optionsSelector?.items.filter((item) => this?.model?.includes(item.id)).map((item) => item[this.optionsSelector.bindLabel ?? 'name']).join(', ');
        return;
      }
      this.customInputValue =
      this.optionsSelector.items.find((item) => item.id === this.model)?.[
        this.optionsSelector.bindLabel ?? 'name'
      ] ?? '';
    } else {
      this.customInputValue = this.model;
    }
  }
}