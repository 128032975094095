import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iFormation } from "./iFormation";

export interface iCourse {
    id: number,
    formation?: iFormation,
}

export class iCourse extends iBaseObject {
    static convertFromCollection(ob: any): Array<iCourse> {
        let iCourseCollection: Array<iCourse> = [];
        ob.forEach((element: any) => {
            iCourseCollection.push(this.convertFromObject(element));
        });
        return iCourseCollection;
    }

    static convertFromObject(ob: any): iCourse {
        let iCourseObj = new iCourse();
        iCourseObj.id = ob.id;
        iCourseObj.formation = ob.formation ? iFormation.convertFromObject(ob.formation) : iFormation.createEmptyObject();
        return iCourseObj;
    }

    static createEmptyObject(): iCourse {
        let iCourseObj = new iCourse();
        iCourseObj.formation = iFormation.createEmptyObject();
        return iCourseObj;
    }
}

export interface iCourseFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    formation?: number,
}