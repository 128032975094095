import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  GeneralLoaderService,
  IOptionCustomTextArea,
  StaticUtilitiesService,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { DispositionService } from 'src/app/Services/Api/Disposition.service';

@Component({
  selector: 'AddClassroom-Popup',
  templateUrl: './AddClassroom-Popup.component.html',
  styleUrls: ['./AddClassroom-Popup.component.scss'],
})
export class AddClassroomPopupComponent implements OnInit {
  isActive: boolean = false;
  isEdit: boolean = false;

  ngModels = {
    name: '',
    observations: '',
    dispositionId: null,
  };

  nameCustomInputOptions: iOptionsCustomInput = {
    label: 'Nombre',
    placeholder: 'Nombre del aula',
    type: 'text',
    required: true,
  };

  dispositionSelectorOptions: iOptionsSelector = {
    label: 'Disposición',
    placeholder: 'Selecciona una disposición',
    items: [
      { id: 1, name: 'Disposición 1' },
      { id: 2, name: 'Disposición 2' },
      { id: 3, name: 'Disposición 3' },
    ],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    search: true,
    clearable: false,
  };

  observationsCustomTextAreaOptions: IOptionCustomTextArea = {
    label: 'Observaciones',
    placeholder: 'Observaciones del aula',
    required: false,
    cols: 30,
    rows: 5,
  };

  constructor(
    public dialogRef: MatDialogRef<AddClassroomPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private dispositionSE:DispositionService,
    private generalLoaderSE:GeneralLoaderService
  ) {}

  ngOnInit() {
    this.getDispositionselector();
    if(this.modalData?.edit){
      this.setIsEditing()
    }
  }

  /**
   * HANDLERS
   */

  successGetDispositionselectorHandler(res: iResultHttp) {
    const { data } = res;
    this.dispositionSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */

  isCompleted() {
    if (this.ngModels.name) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }

  setIsEditing(){
    this.isEdit = true;
    this.ngModels = this.modalData.data
    this.isCompleted()
  }

  finishButton() {
    this.closePopup(this.ngModels);
  }

  forceClosePopup() {
    this.dialogRef.close();
  }

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  /**
   * API CALLS
   */

  getDispositionselector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.dispositionSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetDispositionselectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }


}
