import { Component, OnInit } from '@angular/core';
import {
  iStaticUtilities,
  iUnsubscribeDestroy,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { LoginService } from 'src/app/Services/Api/Login.service';
import { RoleGlobalStateService } from 'src/app/Services/Utils/RoleGlobalState.service';
import { StaticUtilitiesService as OldStaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-Login',
  templateUrl: './Login.component.html',
  styleUrls: ['./Login.component.scss'],
})
export class LoginComponent extends iUnsubscribeDestroy implements OnInit {
  isLogging: boolean = false;

  data: any = {};
  regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  disabled: boolean = true;
  emailCorrect: boolean = false;

  constructor(
    private loginSE: LoginService,
    private staticUtilitiesSE: OldStaticUtilitiesService,
    private roleGLobalStateSE: RoleGlobalStateService
  ) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.getButtonSize();
  }

  /**
   * FUNCTIONALITY
   */

  checkEmail(event) {
    if (!this.regex.test(event)) {
      OldStaticUtilitiesService.showError('El formato de email no es válido');
      this.emailCorrect = false;
      this.checkDisabled();
    } else {
      this.emailCorrect = true;
      this.checkDisabled();
    }
  }

  checkDisabled() {
    if (this.emailCorrect && this.data.password) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  getButtonSize() {
    let input = document.querySelector('.customInputLogin')!;
    let inputComputedStyle = window.getComputedStyle(input);
    let inputSize = parseFloat(inputComputedStyle.getPropertyValue('width'));
    (document.querySelector('#btn')! as HTMLElement).style.width =
      inputSize + 'px';
  }

  setMeLocalStorage(data) {
    let stringified = JSON.stringify(data);
    let cripted = iStaticUtilities.simpleCrypt(stringified);
    localStorage.setItem('usr', cripted);

    const { roles, permissions } =
      OldStaticUtilitiesService.getUserPermissionsAndRoles();
    this.roleGLobalStateSE.setRoleGlobalState(roles);
    this.roleGLobalStateSE.setPermissionsGlobalState(permissions);
  }

  goToForgottenPassword() {
    this.staticUtilitiesSE.goTo(
      'he-olvidado-mi-contrasena',
      slideNavigationTypes.slideToLeft
    );
  }

  /**
   * API CALLS
   */

  submitLogin() {
    this.isLogging = true;
    this.loginSE.login(this.data);
    this.loginSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) return;
        this.barraMe();
        this._unsubInd.next('');
      });
    this.loginSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) return;
        OldStaticUtilitiesService.showError(
          'Los datos de inicio de sesión no son correctos'
        );

        this.isLogging = false;
        this._unsub.next('');
      });
  }

  barraMe() {
    this.loginSE.me();
    this.loginSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) return;
        let { data } = res;
        this.setMeLocalStorage(data);
        OldStaticUtilitiesService.showFeedback('Login correcto');
        this.staticUtilitiesSE.goTo('home', slideNavigationTypes.slideToTop);
        this.isLogging = true;
        this._unsubInd2.next('');
      });
    this.loginSE
      .getResultIndividualError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) return;
        this._unsub.next('');
      });
  }
}
