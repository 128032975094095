import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ConfirmarAccion-Popup',
  templateUrl: './ConfirmarAccion-Popup.component.html',
  styleUrls: ['./ConfirmarAccion-Popup.component.scss']
})
export class ConfirmarAccionPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmarAccionPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit() {}

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
}
