import { Component, Input, OnInit } from '@angular/core';
import { StaticUtilitiesService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';

@Component({
  selector: 'ContainerAutomatricula',
  templateUrl: './ContainerAutomatricula.component.html',
  styleUrls: ['./ContainerAutomatricula.component.scss']
})
export class ContainerAutomatriculaComponent implements OnInit {

  @Input() displayButton: boolean = false;

  constructor(private staticUtilitiesSE:StaticUtilitiesService) { }

  ngOnInit() {
  }

  goToIem(){
    this.staticUtilitiesSE.goTo('/login', slideNavigationTypes.slideToBottom)
  }

}
