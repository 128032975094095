<div class="viewContainer">
  <div class="elementColumn" specName="crm">
    <p class="titlePipeline">CRM</p>
    <!-- Loader -->
    <loaderIcon *ngIf="loaderUp"></loaderIcon>
    <!-- If no data -->
    <div *ngIf="crmPipeDrive.length == 0 && !loaderUp">
      <p>No hay datos</p>
    </div>
    <div
      *ngFor="let item of crmPipeDrive"
      class="pipeLineContentCustom"
      (click)="checkRolesAndPermissions() ? clickCRM(item) : null"
    >
      <div class="imagePlusName" *ngIf="!isLoadingElem(item)">
        <span class="imageSpan">{{
          item.type.substr(0, 1).toUpperCase()
        }}</span>
        <p>
          <b class="truncateText">{{ item.name }}</b>
        </p>
      </div>
      <div class="emailPhoneState">
        <div class="emailPhone">
          <p>{{ item.amount }} {{ item.currency }}</p>
        </div>
        <div class="promotions">
          <p class="pipeUbicacion" *ngFor="let producto of item.products">
            {{ producto.name }}
          </p>
        </div>

        <div class="botones" *ngIf="checkRolesAndPermissions()">
          <btn
            classList="generalbutton pipeDriveLossButton onlyIcon"
            (click)="lossCard($event, item, 'crm')"
          >
            <mat-icon>thumb_down</mat-icon>
          </btn>
        </div>
      </div>
      <!-- <div class="promotions">
        <p class="pipeUbicacion" *ngFor="let formation of item.formacion">{{formation}}</p>
      </div> -->
    </div>
  </div>
  <!-- Columna Pre matrícula -->
  <div class="elementColumn" specName="preEnroll">
    <p class="titlePipeline">Pre Matrícula</p>
    <!-- Loader -->
    <loaderIcon *ngIf="loaderUp"></loaderIcon>
    <!-- If no data -->
    <div *ngIf="pipeList[0].items.length == 0 && !loaderUp">
      <p>No hay datos</p>
    </div>
    <!-- Content -->
    <div
      *ngFor="let item of pipeList[0].items"
      class="pipeLineContentCustom"
      (click)="openPreMatriculaPopup(item)"
      [ngClass]="{ isGroup: item.isGroup }"
    >
      <div class="upperCard">
        <div class="leftUpperCard">
          <div class="imagePlusName" *ngIf="!isLoadingElem(item)">
            <span class="imageSpan">{{ item.initials }}</span>
            <p>
              <b class="truncateText">{{ item.nombre }}</b>
            </p>
          </div>
          <div class="emailPhoneState">
            <div class="emailPhone">
              <p class="truncateText">{{ item.isGroup ? "" : item.mail }}</p>
              <p>
                {{
                  item.isGroup
                    ? ""
                    : "(" + item.prefijo + ")" + " " + item.telefono
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="promotions">
        <p class="pipeUbicacion" *ngFor="let formation of item.formacion">
          {{ formation }}
        </p>
        <p class="pipeUbicacion" *ngFor="let edition of item.edicion">
          {{ edition }}
        </p>
      </div>
      <div class="botones" *ngIf="checkRolesAndPermissions()">
        <btn
          classList="generalbutton trashLineFilterButton onlyIcon"
          *ngIf="!item.pipedriveDealId"
          (click)="openChangePromotionPopup($event, item)"
        >
          <mat-icon>edit</mat-icon>
        </btn>

        <btn
          classList="generalbutton trashLineFilterButton onlyIcon"
          *ngIf="!item.pipedriveDealId"
          (click)="lossCardNoPipedrive($event, item)"
        >
          <mat-icon>delete</mat-icon>
        </btn>

        <btn
          classList="generalbutton pipeDriveLossButton onlyIcon"
          *ngIf="item.pipedriveDealId"
          (click)="lossCard($event, item, 'enrollment')"
        >
          <mat-icon>thumb_down</mat-icon>
        </btn>
      </div>
    </div>
  </div>
  <!-- Columna Pendiente de revisión -->
  <div class="elementColumn" specName="preEnroll">
    <p class="titlePipeline">Pendiente de revisión</p>
    <!-- Loader -->
    <loaderIcon *ngIf="loaderUp"></loaderIcon>
    <!-- If no data -->
    <div *ngIf="pipeList[1].items.length == 0 && !loaderUp">
      <p>No hay datos</p>
    </div>
    <!-- Content -->
    <div
      *ngFor="let item of pipeList[1].items"
      class="pipeLineContentCustom"
      (click)="openMatriculaRevisaPopup(item)"
      [ngClass]="{ isGroup: item.isGroup }"
    >
      <div class="imagePlusName" *ngIf="!isLoadingElem(item)">
        <span class="imageSpan">{{ item.initials }}</span>
        <p>
          <b class="truncateText">{{ item.nombre }}</b>
        </p>
      </div>
      <div class="emailPhoneState">
        <div class="emailPhone">
          <p class="truncateText">{{ item.isGroup ? "" : item.mail }}</p>
          <p>
            {{
              item.isGroup ? "" : "(" + item.prefijo + ")" + " " + item.telefono
            }}
          </p>
        </div>
        <div class="botones" *ngIf="checkRolesAndPermissions()">
          <btn
            classList="generalbutton pipeDriveLossButton onlyIcon"
            *ngIf="item.pipedriveDealId"
            (click)="lossCard($event, item, 'enrollment')"
          >
            <mat-icon>thumb_down</mat-icon>
          </btn>
          <btn
            classList="generalbutton trashLineFilterButton onlyIcon"
            *ngIf="!item.pipedriveDealId"
            (click)="lossCardNoPipedrive($event, item)"
          >
            <mat-icon>delete</mat-icon>
          </btn>
        </div>
      </div>
      <div class="promotions">
        <p class="pipeUbicacion" *ngFor="let formation of item.formacion">
          {{ formation }}
        </p>
        <p class="pipeUbicacion" *ngFor="let edition of item.edicion">
          {{ edition }}
        </p>
      </div>
    </div>
  </div>
  <!-- Columna Pendiente de verificación -->
  <div class="elementColumn" specName="enroll">
    <p class="titlePipeline">Pendiente de verificación</p>
    <!-- Loader -->
    <loaderIcon *ngIf="loaderUp"></loaderIcon>
    <!-- If no data -->
    <div *ngIf="pipeList[2].items.length == 0 && !loaderUp">
      <p>No hay datos</p>
    </div>
    <!-- Content -->
    <div
      *ngFor="let item of pipeList[2].items"
      class="pipeLineContentCustom"
      (click)="openMatriculaConfirmPopup(item)"
      [ngClass]="{ isGroup: item.isGroup }"
    >
      <div class="imagePlusName" *ngIf="!isLoadingElem(item)">
        <span class="imageSpan">{{ item.initials }}</span>
        <p>
          <b class="truncateText">{{ item.nombre }}</b>
        </p>
      </div>
      <div class="emailPhoneState" *ngIf="!isLoadingElem(item)">
        <div class="emailPhone">
          <p class="truncateText">{{ item.isGroup ? "" : item.mail }}</p>
          <p>
            {{
              item.isGroup ? "" : "(" + item.prefijo + ")" + " " + item.telefono
            }}
          </p>
        </div>
        <div class="botones" *ngIf="checkRolesAndPermissions()">
          <btn
            classList="generalbutton pipeDriveLossButton onlyIcon"
            *ngIf="item.pipedriveDealId"
            (click)="lossCard($event, item, 'enrollment')"
          >
            <mat-icon>thumb_down</mat-icon>
          </btn>
          <btn
            classList="generalbutton trashLineFilterButton onlyIcon"
            *ngIf="!item.pipedriveDealId"
            (click)="lossCardNoPipedrive($event, item)"
          >
            <mat-icon>delete</mat-icon>
          </btn>
        </div>
      </div>
      <div class="promotions">
        <p class="pipeUbicacion" *ngFor="let formation of item.formacion">
          {{ formation }}
        </p>
        <p class="pipeUbicacion" *ngFor="let edition of item.edicion">
          {{ edition }}
        </p>
      </div>
    </div>
  </div>

  <span class="goUp" (click)="goUp()" #bubble>
    <mat-icon>arrow_upward</mat-icon>
  </span>
</div>
