import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { Observable } from 'rxjs';
import { StaticUtilitiesService } from '../StaticUtilities.service';

@Injectable({
  providedIn: 'root'
})

export class CanActivateFormationGuard implements CanActivate {

  constructor( private router:Router, private notificationSE:NotificationsService) { }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let rolesAndPermissions = StaticUtilitiesService.getUserPermissionsAndRoles();

    if( ! (rolesAndPermissions.roles.includes('ROLE_ADMIN') || rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') || rolesAndPermissions.permissions.includes(4) || rolesAndPermissions.permissions.includes(3)) ){
      this.router.navigate(['']);
      this.notificationSE.showWarning("No tienes permisos para acceder a esta página");
      return false;
    }    
    return true;
  }
  

}