import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  Type,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { SelectedDataTableBridgeService } from 'src/app/Services/Utils/SelectedDataTableBridge.service';
import { iOptionMenu } from '../options-menu/options-menu.component';
import { AppAnchorDirective } from '@quasar-dynamics/basic-designsystem';
import {
  OutputsService,
  iOutput,
} from 'src/app/Services/Utils/Outputs.service';
import { iTableTicketOptions } from '../../Interfaces/Utils/iTableTicketOptions';

export interface iDateTable {
  date: boolean;
  pipeTemplate:
    | 'dd/MM/yyyy'
    | 'dd/MM/yy'
    | 'dd/MM/yyyy HH:mm'
    | 'dd/MM/yy HH:mm';
}

interface SourceBase {
  display: string;
  key: string;
  size?: string;
  alignment?: 'start' | 'center' | 'end';
  width?: string;
}

interface SourceWithCallback extends SourceBase {
  classNameGenerator?: (any, index: number, row: any) => string;
}

interface SourceText extends SourceBase {
  variant: 'bold' | 'standard';
  euros?: boolean;
  className?: string;
  date?: iDateTable;
}

interface SourceChip extends SourceWithCallback {
  variant: 'chip' | 'box';
  className?: string;
  date?: iDateTable;
  border?: boolean;
}

interface SourceButton extends SourceBase {
  variant: 'button';
  action: (...any) => void;
  buttonStyle?: string;
  text?: string;
  icon?: string;
  displayGenerator?: (...any) => boolean;
}

interface SourceIconButton extends SourceButton {
  icon: string;
}

interface SourceItemDropdown extends SourceBase {
  variant: 'dropdown';
  options: iOptionMenu[];
  btnText?: string;
  icon?: string;
}

interface AnyComponent extends SourceBase {
  variant: 'component';
  component: Type<any>;
  cellClassName?: string;
  inputs?: {
    // Callback function that returns the value to set in the input
    callback: (index: number, key: string) => any;
    // Key of the data to get the value from
    key: string;
    // Key of the input in the child component.
    inputKey: string;
  }[];
}

interface Icons extends SourceBase {
  variant: 'icons';
  // Array of icons to display
  icons: {
    // callback function to execute when the icon is clicked
    action: (any) => void;
    // If the icon is a mat-icon, the name of the icon
    matIconName?: string;
    // If the icon is an image, the url of the image
    image?: string;
  }[];
}

interface Tick extends SourceBase {
  variant: 'tick';
  options: (row: any, index: number) => iTableTicketOptions;
}

interface TooltipDisplayer extends SourceBase {
  variant: 'tooltip';
  itemToDisplay: (row: any) => string;
  tooltipItems: (row: any) => any[];
  tooltipKeyToDisplay: string;
  tooltipItemsLength: (row: any) => number;
  tooltipClassName?: string;
  euros?: boolean;
  className?: string;
  date?: iDateTable;
}

type SourceItem =
  | SourceText
  | SourceChip
  | SourceButton
  | SourceIconButton
  | SourceItemDropdown
  | AnyComponent
  | Icons
  | TooltipDisplayer
  | Tick;

export type headerData = SourceItem[];

@Component({
  selector: 'main-table',
  templateUrl: './main-table.component.html',
  styleUrls: ['./main-table.component.scss'],
})
export class MainTableComponent implements OnInit {
  private _headerData: headerData = [];

  // Gets the anchors of the table
  @ViewChildren(AppAnchorDirective) anchors?: QueryList<AppAnchorDirective>;

  // Gets the icon container if it exists
  @ViewChild('iconContainer') iconContainer?: ElementRef;

  // Datos del header, también controla el mapeo del body
  @Input()
  get headerData() {
    return this._headerData;
  }
  set headerData(value) {
    this._headerData = value;
  }

  // Datos del cuarpo de la tabla, se mapea con el headerData
  @Input() dataSource: any[] = [];

  // Booleano que controla si los datos ya fueron cargados
  @Input() isDataLoaded: boolean = false;

  // Booleano que controla si la tabla es fina
  @Input() slim: boolean = false;

  // Booleano que controla si la tabla tiene selección multiple
  @Input() selectMultiple: boolean = false;

  @Input() noItemsMessage: string = 'No hay elementos para mostrar';

  // Emite los datos de las filas seleccionadas
  @Output() changeSelectedRows: EventEmitter<any[]> = new EventEmitter<any[]>();

  // Emite los datos de la fila que se ha pulsado
  @Output() clickOnRow: EventEmitter<any> = new EventEmitter<any>();

  // Emite la acción que se ha pulsado en el menú
  @Output() clickOnMenuOption: EventEmitter<string> =
    new EventEmitter<string>();

  // Se mappea con el headerData, si el headerData tiene botones, se le asigna el ancho de 80px, si no, 200px
  tableColumns: string = '';

  // Datos de las filas seleccionadas
  selectedData: Array<any> = [];

  // Booleano que controla si todas las filas están seleccionadas
  allselected: boolean = false;

  constructor(
    private selectedDataTableBridgeSE: SelectedDataTableBridgeService,
    private outputsSE: OutputsService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['headerData']) {
      if (changes['headerData'].currentValue?.length > 0) {
        const getSize = (item: SourceItem) => {
          if (item.size) {
            return item.size;
          }
          if (item.variant === 'button' || item.variant === 'dropdown') {
            return '80px';
          }
          return 'minmax(200px, 1fr)';
        };
        const arraySizes = changes['headerData'].currentValue.map(
          (item: SourceItem) => getSize(item)
        );
        if (this.selectMultiple) {
          arraySizes.unshift('50px');
        }
        this.tableColumns = arraySizes.join(' ');
      }
    }
  }

  ngAfterViewInit() {
    this.insertComponents();
    this.getIconsWidthAndSetItToItsHeader();
  }

  contains(element: any): boolean {
    for (let item of this.selectedData) {
      if (item.id === element.id) {
        return true;
      }
    }
    return false;
  }

  getIconsWidthAndSetItToItsHeader() {
    // Check if the icon container exists
    if (this.iconContainer) {
      // Get the width of the icon container and store it in the variable `iconsWidth`
      const iconsWidth =
        this.iconContainer.nativeElement.parentElement.offsetWidth + 'px';
      // Get all the elements with the class name 'iconsHeader' and store them in the variable `iconsHeader`
      const iconsHeader: HTMLCollectionOf<Element> =
        document.getElementsByClassName('iconsHeader');
      // Check if `iconsHeader` exists
      if (iconsHeader) {
        // Iterate over each `iconHeader` and set its 'style' attribute to have a width and min-width equal to `iconsWidth`
        // Array.from(iconsHeader).forEach((iconHeader) => {
        //   iconHeader.setAttribute('style', `width: ${iconsWidth}`);
        //   iconHeader.setAttribute('style', `min-width: ${iconsWidth}`);
        //   iconHeader.setAttribute('style', `max-width: ${iconsWidth}`);
        // });
      }
    }
  }

  /**
   * Inserts components into the main table.
   *
   * This method iterates over the anchors and inserts components based on the header data.
   * It sets inputs and outputs for each component and assigns the component as a child of the anchor.
   *
   * @returns void
   */
  insertComponents() {
    // Collects all the appAnchor directives and sets them to an array.
    const anchors = this.anchors?.toArray();
    // If there are no anchors, return.
    if (!anchors) return;

    // Anchor index.
    let anchorIndex: number = 0;
    // table line.
    let line: number = 0;
    // Column index.
    let columnIndex: number = 0;
    // Array of outputs to send to the service and get the subscriptions.
    let outputs: iOutput[] = [];

    // Array of header types. Storing the variant of each header item, to determine if it is a component.
    const headerTypes: string[] = this.headerData.map(
      (header) => header.variant
    );

    // Array of components. Storing the components to render.
    const components: Type<any>[] = this.headerData
      .filter(
        (header): header is AnyComponent => header.variant === 'component'
      )
      .map((header) => header.component);

    // Iterates over the anchors.
    anchors?.forEach((anchor, index) => {
      // Maximum number of columns.
      const maxNumber = headerTypes.length - 1;

      // If the header type is not a component, skip to the next one.
      while (headerTypes[columnIndex] !== 'component') {
        columnIndex++;
      }

      // If the header type is a component
      if (this.headerData[columnIndex].variant === 'component') {
        // Renders the component.
        const ref: ComponentRef<any> = this.renderComponent(
          anchor,
          anchorIndex,
          components
        );

        // Gets the component data.
        const componentData = this.headerData[columnIndex] as AnyComponent;

        // If the component has inputs, set them.
        if (componentData.inputs) {
          this.setInputsToComponent(componentData, ref, line);
        }

        // If the component has outputs, set them.
        this.setOutputs(ref, line, columnIndex, outputs);

        // Sets the component as a child of the anchor.
        anchor.setComponentAsChild(ref);

        // Increments the anchor index.
        anchorIndex++;
      }

      // Resets the column index if it reaches the maximum number of columns.
      if (maxNumber === columnIndex) {
        columnIndex = 0;
        anchorIndex = 0;
        line++;
      } else {
        columnIndex++;
      }
    });

    // Sets the outputs to the service.
    this.outputsSE.pushToArray(outputs);
  }

  /**
   * Renders a component using the provided anchor, anchor index, and component types.
   *
   * @param anchor - The anchor directive used to render the component.
   * @param anchorIndex - The index of the component in the components array.
   * @param components - An array of component types.
   * @returns The component reference of the rendered component.
   */
  renderComponent(
    anchor: AppAnchorDirective,
    anchorIndex: number,
    components: Type<any>[]
  ): ComponentRef<any> {
    // Renders the component.
    return anchor.renderComponent(components[anchorIndex]);
  }

  /**
   * Sets the inputs of a component based on the provided data.
   * @param componentData - The data containing the inputs to be set.
   * @param ref - The reference to the component instance.
   * @param line - The line of data to be used for setting the inputs.
   */
  setInputsToComponent(componentData, ref, line) {
    // Iterates over the inputs.
    componentData.inputs.forEach((input) => {
      // Gets the value of the input.
      const returned = input.callback(line, input.key);
      // Sets the value of the input.
      ref.instance[input.inputKey] = returned;
    });
  }

  /**
   * Sets the outputs for a given component instance.
   *
   * @param ref - The component instance reference.
   * @param line - The line number.
   * @param columnIndex - The column index.
   * @param outputs - The array to store the outputs.
   */
  setOutputs(ref, line, columnIndex, outputs) {
    // Iterates over the component instance.
    for (const key in ref.instance) {
      // If the key is an EventEmitter and is not 'close', push it to the outputs array.
      if (ref.instance[key] instanceof EventEmitter && key !== 'close') {
        // Pushes the output to the outputs array.
        outputs.push({
          propertyName: key,
          key: this.headerData[columnIndex].key,
          component: ref.componentType.name.toString() + '-' + line,
          line: line,
          value: ref.instance[key],
        });
      }
    }
  }

  handleClickRow(row: any) {
    this.clickOnRow.emit(row);
  }

  handleClickBtn(row: any, action: (any) => void) {
    return action(row);
  }

  submitSelecteds() {
    this.changeSelectedRows.emit(
      this.selectedDataTableBridgeSE.getSelectedData()
    );
  }

  handlerSelectRow(index: number) {
    const element = this.dataSource[index];
    this.selectedDataTableBridgeSE.setSelectedData(element);
    this.selectedData = this.selectedDataTableBridgeSE.getSelectedData();
    if (this.selectedData.length === this.dataSource.length) {
      this.selectedDataTableBridgeSE.setSelectedData(this.dataSource, true);
      this.allselected = true;
    } else {
      this.allselected = false;
    }
    this.submitSelecteds();
  }

  handlerClickSelectAll(checked: boolean) {
    if (checked) {
      this.selectedDataTableBridgeSE.setSelectedData(this.dataSource, true);
      this.selectedData = this.selectedDataTableBridgeSE.getSelectedData();
      this.allselected = true;
    } else {
      this.selectedDataTableBridgeSE.clearSelectedData();
      this.selectedData = this.selectedDataTableBridgeSE.getSelectedData();
      this.allselected = false;
    }
    this.submitSelecteds();
  }

  handlerClickMenuOption(action: string) {
    this.clickOnMenuOption.emit(action);
  }

  getAlignment(item: SourceItem) {
    if (!item.alignment || item.alignment === 'center') return 'center';
    return `flex-${item.alignment}`;
  }

  executeIconAction(action: Function, item: any) {
    action(item);
  }

  /**
   *
   * TrackBy functions
   */

  trackByHeader(index: number, item: SourceItem) {
    return index;
  }

  trackByLines(index: number, item: SourceItem) {
    return index;
  }
}
