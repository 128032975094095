import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject, Observable, Subject, filter, firstValueFrom, takeUntil } from 'rxjs';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicHolidaysService extends DefaultBaseService{
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) { 
    super();
   }

  getPublicHolidaysTest(behaviorSubject: BehaviorSubject<any>, data: { year: number; countryCode: string }) {
    return this.http
      .get(`https://date.nager.at/api/v3/publicholidays/${data.year}/${data.countryCode}`, this.getHeader());
  }

  async getPublicHolidaysForAll(behaviorSubject: BehaviorSubject<any>, data: { year: number }) {
    const countryCodes = ['ES']; 

    const observables = 
    countryCodes.map((countryCode) =>
      this.getPublicHolidaysTest(new BehaviorSubject<any>(null), { year: data.year, countryCode })
    );

    // [
    //   this.getPublicHolidaysTest(new BehaviorSubject<any>(null), { year: data.year, countryCode: 'ES' }),
    // ]

    try {
      const results:any[] = await firstValueFrom(forkJoin(observables));
      const allOptions = [].concat(...results);
      this.res = new iResultHttp({
        status: 200,
        ok: true,
        message: '',
        data: allOptions,
      });
      this.sendNextObservable(behaviorSubject, this.res);
    } catch (error) {
      console.error(error);
    }
  }
}
