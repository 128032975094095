<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="closePopup(false)">close</mat-icon>

    <p>¿Estás seguro de que desea cancelar la matrícula?</p>
    <mat-icon (click)="closePopup(false)">close</mat-icon>
  </div>
  <div class="body">
    <p>Si es así, por favor, indiquenos el motivo:</p>

    <div class="formLine">
      <selector
        [options]="reasonSelectorOptions"
        [(ngModel)]="ngModels.reason"
        style="width: 100%"
      ></selector>
    </div>
    <div class="formLine" *ngIf="ngModels.reason === 'Otro'">
      <customTextArea
        [options]="reasonOtherCustomTextareaOptions"
        [(ngModel)]="ngModels.reasonOther"
        style="width: 100%"
      ></customTextArea>
    </div>
  </div>

  <div class="footer">
    <btn classList="principalButton" (click)="closePopup(false)">
      <ng-container> Volver </ng-container>
    </btn>
    <btn classList="specialButton" (click)="closePopup(ngModels)">
      <ng-container> Cancelar la matrícula </ng-container>
    </btn>
  </div>
</div>
