import {
  eEventCalendarType,
  iEventCalendar,
} from '../Interfaces/Utils/iEventCalendar';
import { iCustomCheckboxOptions } from '../Interfaces/Utils/iCustomCheckboxOptions';

export class GestionUsuariosCalendarioGeneralClass {
  events: any = [];

  promotionId: number = 0;

  publicHolidays: any[] = [];

  teacherId: number = 0;

  formationId: number = 0;

  // calendarVisible
  calendarVisible: boolean = true;

  calendarFilters: any = {
    year: new Date().getFullYear(),
  };

  checkBoxesArray: iCustomCheckboxOptions[] = [
    {
      active: true,
      text: 'Máster',
      color: '#3F43AD',
      father: true,
      group: 'master',
      landmarkTypeId: null
    },
    {
      active: true,
      text: 'Clases',
      color: '#3F43AD',
      group: 'master',
      moveRight: true,
      landmarkTypeId: [2],
    },
    {
      active: true,
      text: 'Tutoría - Profesores',
      color: '#6569BD',
      group: 'master',
      moveRight: true,
      landmarkTypeId: [3],
    },
    {
      active: true,
      text: 'Tutoría - Alumnos',
      color: '#6569BD',
      group: 'master',
      moveRight: true,
      landmarkTypeId: [16],
    },
    {
      active: true,
      text: 'Tribunal',
      color: '#8C8ECE',
      group: 'master',
      moveRight: true,
      landmarkTypeId: [4, 17, 18],
    },
    {
      active: true,
      text: 'Otros',
      color: '#B2B4DE',
      group: 'master',
      moveRight: true,
      landmarkTypeId: [5],
    },
    {
      active: true,
      text: 'Cursos',
      color: '#78E6D0',
      moveRight: false,
      father: true,
      group: 'cursos',
      landmarkTypeId: null
    },
    {
      active: true,
      text: 'Clases',
      color: '#78E6D0',
      group: 'cursos',
      moveRight: true,
      landmarkTypeId: [12],
    },
    {
      active: true,
      text: 'Otros',
      color: '#ADFFEF',
      group: 'cursos',
      moveRight: true,
      landmarkTypeId: [13],
    },
    {
      active: true,
      text: 'Incompany',
      color: '#E3EB50',
      moveRight: false,
      father: true,
      group: 'incompany',
      landmarkTypeId: null
    },
    {
      active: true,
      text: 'Clases',
      color: '#E3EB50',
      group: 'incompany',
      moveRight: true,
      landmarkTypeId: [14],
    },
    {
      active: true,
      text: 'Otros',
      color: '#F3F4A9',
      group: 'incompany',
      moveRight: true,
      landmarkTypeId: [15],
    },
    {
      active: true,
      text: 'Alquiler',
      color: '#FFB4B4',
      landmarkTypeId: [8],
    },
    {
      active: true,
      text: 'Eventos',
      color: '#FFC7FD',
      landmarkTypeId: [9],
    },
    {
      active: true,
      text: 'Festivos',
      color: '#B5B5B5',
      landmarkTypeId: [10],
    },
    {
      active: true,
      text: 'No lectivos',
      color: '#E7E7E7',
      landmarkTypeId: [11],
    },
  ];

  isSelecting: boolean = false;

  event: iEventCalendar = {
    type: eEventCalendarType.Festivo,
    className: 'festivo',
    id: null,
    startDate: new Date().toISOString(),
    title: '',
  };
}
