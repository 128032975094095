
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { iResultHttp, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { SubirArchivoPopupComponent } from 'src/app/Popups/SubirArchivo-Popup/SubirArchivo-Popup.component';
import { DocumentService } from 'src/app/Services/Api/Document.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
@Component({
  selector: 'documentacionAlumno',
  templateUrl: './GestionUsuarios-Alumnos-Detalles-Documentacion.component.html',
  styleUrls: ['./GestionUsuarios-Alumnos-Detalles-Documentacion.component.scss']
})
export class GestionUsuariosAlumnosDetallesDocumentacionComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() dataStudent: any = null;
  @Input() isDataLoaded: boolean = false;

  @Output() refresh:EventEmitter<boolean> = new EventEmitter<boolean>();

  documentos: Array<any>= [];
  DNI: any = [];
  CV: any = [];
  Acuerdo: any = [];
  Otros: any = [];
  types: any = [{value: this.DNI, name: 'DNI'}, {value: this.CV, name: 'CV'}, {value: this.Otros, name: 'Otros'}]

  constructor(private popupSE: PopupService, private documentSE:DocumentService) {
    super()
  }

  ngOnInit() {

  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['dataStudent']){
      if(changes['dataStudent'].currentValue){
        this.documentos = changes['dataStudent'].currentValue?.documents ?? [];
        this.types.forEach(type => type.value = [])
        this.classifyDocuments(this.documentos);
        this.isDataLoaded = true;

      }
    }
  }

  /**
   * FUNCTIONALITIES
   */

  classifyDocuments(documents) {
    documents.map((doc) => {
      this.types.forEach(type => {
        if(type.name == doc.typeOf){
          type.value.push(doc);
        }
      });
    })
  }

  uploadDocumento(typeOf){
    this.popupSE.openPopup(SubirArchivoPopupComponent, {accion: "Subir documento al alumno", multiple:true ,formatos: ["application/pdf"], size: 10485760})
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return};
       
      let data:any = {
        document:[],
      };
       
      data.document = res['returnValue'].map((file) => {return {name: file.file.name, url: file.base64, student: this.dataStudent.id, typeOf: typeOf}})
      this.subirDocumento(data);
      this._unsubInd.next("");
    })
  }
  openFile(url){
    window.open(url, "_blank");
  }

  /**
   * API CALLS
   */

  eliminarDocumentoPopup(documento){
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {accion: "Eliminar documento", mensaje: "¿Estás seguro que quieres eliminar el documento?"});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd9)).subscribe(res=>{
      if(!res)return;
      this.deleteDocument([documento.id]);
      this._unsubInd9.next("");
    })
  }

  subirDocumento(data){
    this.documentSE.create(data);
    this.documentSE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe((res:iResultHttp)=>{
      if(!res){return}
       
      StaticUtilitiesService.showFeedback('Documento subido correctamente');
      this.refresh.emit(true);
      this._unsubInd2.next('')
    })
    this.documentSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  deleteDocument(ids:number[]){
    this.documentSE.delete(ids);
    this.documentSE.getResultDelete().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Documento eliminado correctamente');
      this.refresh.emit(true);
      this._unsubInd.next('')
    })
    this.documentSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }


}
