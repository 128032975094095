import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {
  StaticUtilitiesService,
  SubjectGeneratorService,
  iDate,
  iOptionsCustomInput,
  iOptionsSelector,
  iResultHttp,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { GubrtService } from 'src/app/Services/Api/Gubrt.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

@Component({
  selector: 'InformacionPersonal',
  templateUrl: './InformacionPersonal.component.html',
  styleUrls: ['./InformacionPersonal.component.scss'],
})
export class InformacionPersonalComponent
  extends SubjectGeneratorService
  implements OnInit
{
  @Input() countriesList: any[] = [];
  @Input() studentData: any = null;
  @Input() token: any = null;
  @Output() dataFromStepTwo: EventEmitter<any> = new EventEmitter<any>();
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  objectToPass: any = {};
  studentDataStepTwo: any = null;
  secondAddress: boolean = false;
  proxyNationality: any = null;
  optionsInputDateOfBirth: iOptionsCustomInput = {
    placeholder: 'Fecha de nacimiento',
    type: 'date',
    label: 'Fecha de nacimiento (*)',
  };
  opcionesSexo: iOptionsSelector = {
    items: [
      { id: 1, label: 'Hombre' },
      { id: 2, label: 'Mujer' },
      { id: 3, label: 'Otro' },
      { id: 4, label: 'Prefiero no decirlo' },
    ],
    append: true,
    placeholder: 'Sexo',
    clearable: false,
    bindValue: 'label',
  };
  opcionesIdiomas: iOptionsSelector = {
    items: StaticDataHandlerService.getLanguages(),
    clearable: true,
    search: true,
    placeholder: 'Selecciona los idiomas',
    append: true,
    multiple: {
      isMultiple: true,
      multipleTextReplacer: `Varios idiomas seleccionados`,
      maxSelectedItems: 3,
    },
  };
  opcionesPais: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Selecciona un país',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
  };
  opcionesNacionalidad: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Selecciona un país',
    append: true,
    bindLabel: 'ethnicity',
    bindValue: 'id',
  };

  opcionesLaboral: iOptionsSelector = {
    items: ['Activo', 'Inactivo'],
    placeholder: 'Situación laboral actual',
    append: true,
  };

  constructor(
    private userSE: UserService,
    private gubrtSE: GubrtService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['countriesList']) {
      if (changes['countriesList'].currentValue) {
        this.opcionesNacionalidad.items = changes['countriesList'].currentValue;
        this.opcionesPais.items = changes['countriesList'].currentValue;
      }
    }
    if (changes['studentData']) {
      if (changes['studentData'].currentValue) {
        this.studentDataStepTwo = changes['studentData'].currentValue;
        if (this.studentData.user.address == null)
          this.studentData.user.address = { country: { id: null } };
        if (this.studentData.user.address2) this.secondAddress = true;
      }
    }

    if (changes['token']) {
      if (changes['token'].currentValue) {
        this.objectToPass.token = changes['token'].currentValue.token;
      }
    }
  }

  ngAfterViewInit() {
    this.generateTooltip();
  }

  /**
   * FUNCTIONALITY
   */

  idiomasSelector(event) {}

  generateTooltip() {
    let customInput = this.elementRef.nativeElement.querySelector('.alias');
    let labelContainer = customInput.querySelector('.labelContainer');
    let infoIcon = this.renderer.createElement('img');
    infoIcon.src = '/assets/Icons/General/info.svg';
    this.renderer.addClass(infoIcon, 'infoIcon');
    this.renderer.appendChild(labelContainer, infoIcon);

    let tag = this.renderer.createElement('span');
    this.renderer.addClass(tag, 'tag');
    tag.innerHTML = 'Cómo quieres que te llamen en clase';
    this.renderer.appendChild(labelContainer, tag);
  }

  emit() {
    this.dataFromStepTwo.emit(this.studentDataStepTwo);
  }

  hasSecondAddress(event) {
    this.secondAddress = event.checked;
    if (!this.studentDataStepTwo.user.address2) {
      this.studentDataStepTwo.user.address2 = {
        country: {
          id: null,
          iso_code: null,
          name: null,
          nombre: null,
        },
        province: null,
        municipality: '',
        street: '',
        number: '',
        door: '',
        postalCode: '',
      };
    }
  }

  getCountrySelector(event, key) {
    if (key == 'one') {
      this.studentDataStepTwo.user.phonePrefix = event.code;
      this.sendToUpdate(
        this.studentDataStepTwo.user.phonePrefix,
        'phonePrefix',
        'user'
      );
    }
    if (key == 'two') {
      this.studentDataStepTwo.user.phoneTwoPrefix = event.code;
      this.sendToUpdate(
        this.studentDataStepTwo.user.phoneTwoPrefix,
        'phoneTwoPrefix',
        'user'
      );
    }
    this.emit();
  }

  fechaDeNacimiento(event) {
    this.studentDataStepTwo.user.dateOfBirth = iDate
      .javascriptConvert(new Date(event))
      .toStringDate('JAP');
    this.sendToUpdate(
      this.studentDataStepTwo.user.dateOfBirth,
      'dateOfBirth',
      'user'
    );
  }

  sendToUpdate(value, key, option) {
    switch (option) {
      case 'user':
        this.objectToPass.user = {};
        this.objectToPass.user[key] = value;
        this.update();
        delete this.objectToPass.user[key];
        delete this.objectToPass.user;
        break;

      case 'socialMedia':
        this.objectToPass.user = {};
        this.objectToPass.user.socialMedia = {};
        this.objectToPass.user.socialMedia = {
          ...this.studentDataStepTwo.user.socialMedia,
        };

        this.update();
        delete this.objectToPass.user;
        break;

      case 'address':
        this.objectToPass.user = { address: {} };

        if (key == 'country') {
          this.objectToPass.user.address.country = {
            id: null,
          };
          this.objectToPass.user.address[key].id = value;
        } else {
          this.objectToPass.user.address[key] = value;
        }
        this.update();
        delete this.objectToPass.user.address[key];
        delete this.objectToPass.user.address;
        delete this.objectToPass.user;
        break;

      case 'address2':
        this.objectToPass.user = { address2: {} };

        if (key == 'country') {
          this.objectToPass.user.address2.country = {
            id: null,
          };
          this.objectToPass.user.address2[key].id = value;
        } else {
          this.objectToPass.user.address2[key] = value;
        }
        this.update();
        delete this.objectToPass.user.address2[key];
        delete this.objectToPass.user.address2;
        delete this.objectToPass.user;
        break;
      default:
        break;
    }

    this.emit();
  }

  /**
   * API CALLS
   */

  update() {
    const unsubSubject = this.createSubject();
    this.gubrtSE.editGubrtOldVersion(this.objectToPass);
    this.gubrtSE
      .getResultUpdate()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        // this.refresh.emit(true);
        this.sendDataToSubject(unsubSubject);
      });
    this.gubrtSE
      .getResultUpdateError()
      .pipe(takeUntil(unsubSubject))
      .subscribe((res: iResultHttp) => {
        if (!res) return;
        window.open('/', '_self');
        StaticUtilitiesService.showError('No tienes permisos para estar aquí');
        this.sendDataToSubject(unsubSubject);
      });
  }
}
