<div class="fullPage">
  <div class="loginSection">
    <div class="loginBlock">
      <div class="logoIEM">
        <img src="/assets/Images/Logos/LogoIEMLogin.svg" alt="LogoIem">
      </div>
      <div class="textLogin">
        <p>¡Hola!</p>
        <p>Inserte aquí su correo y le enviaremos un enlace para recuperar su contraseña</p>
      </div>
      <div class="separador">
        <span class="line"></span>
      </div>
      <div class="inputsLogin">
        <CustomInput class="customInputLogin"  [type]="'email'" [icon]="'person'" [placeHolder]="'Introduzca su email'" [(ngModel)]="data.email"
        name="user" (change)="checkEmail(data.email)" ></CustomInput>
      </div>
      
      <div class="buttonLogin">
        <btn id='btn' (click)="sendRecoveryToken()"  classList="loginButton" [ngClass]="{disabled: disabled}" > <loaderIcon class="loaderIconWhite" [size]="'small'" *ngIf="isLogging"></loaderIcon> <p *ngIf="!isLogging"> Enviar email </p> </btn>
      </div>
    </div>
  </div>
  <div class="imagesSection">
    <div class="textImageBlock">
      <div class="text">
        <p>Profesionales</p>
        <p>que forman profesionales</p>
      </div>
      <div class="textDecoration">
        <img src="/assets/Icons/Decoracion/decoracionLoginIEM.svg" alt="decoracion">
      </div>
    </div>
    <div class="imagesGrid">
      <div class="azulVerdoso"></div>
      <div class="imageTop">
        <img src="/assets/Images/Login/womanTyping.png" alt="Mujer al ordenador">
      </div>
      <div class="imageBottom">
        <img src="/assets/Images/Login/handsTyping.png" alt="Mujer al ordenador">
      </div>
      <div class="grey"></div>
      <div class="violet"></div>

    </div>
  </div>
</div>