 interface ProgramModule {
    module: string | null;
    session: string |null;
    date: Date | null;
    startHour: string | null;
    endHour: string | null;
    teachers: any[];
    sessionNumber: number | string | null;
    classroomId: number | null;
    classroom: string | null;
    classroomDispositionId: number | null;
    classroomDisposition: string | null;
    id: number;
    stateId: number;
    state: string;
}

export interface iPromotionDates {
    program: ProgramModule[];
    tutorings: TutoringSession[];
    tribunals: ProjectSession[]; 
}

export class IPromotionDates {
    static getEmptyObject(): iPromotionDates {
        return {
            program: [
                {
                    module: null,
                    session: null,
                    sessionNumber:null,
                    id: 0,
                    date: null,
                    startHour: null,
                    endHour: null,
                    teachers: [],
                    classroomId: null,
                    classroom: null,
                    classroomDispositionId: null,
                    classroomDisposition: null,
                    stateId: 0,
                    state: ''
                }
            ],
            tutorings: [{
                tutoringId: 0,
                teacherName: '',
                landmarkParentId: 0,
                startDate: '',
                hours: '',
                slotMinutes: 0,
                topicNames: '',
                tutoringAttendanceName: '',
                classroomName: '',
                tutoringStateName: '',
                tutoringStateColor: ''
            
            }],
            tribunals: [
                {
                    title: '',
                    startDate: null,
                    slot: null,
                    classroom: null,
                    hours: null
                }
            ]
        };
    }
} 

interface TutoringSession {
    tutoringId: number;
    teacherName: string;
    landmarkParentId: number;
    startDate: string;
    hours: string;
    slotMinutes: number;
    topicNames: string;
    tutoringAttendanceName: string;
    classroomName: string;
    tutoringStateName: string;
    tutoringStateColor: string;
}

interface ProjectSession {
    title: string;
    startDate: string | null;
    slot: number | null;
    classroom: string | null;
    hours: string | null;
}


