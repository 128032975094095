import { Component, Input, OnInit } from '@angular/core';
import {
  DebouncerService,
  GeneralLoaderService,
  StaticUtilitiesService,
  iResultHttp,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { Subject, filter, takeUntil } from 'rxjs';
import { PublicHolidaysService } from 'src/app/Services/Api/PublicHolidays.service';
import { iDateChangeCalendar } from 'src/app/Shared/Interfaces/Utils/full-calendar/iDateChangeCalendar';
import { PopupService } from '@quasar_dynamics/basic-designsystem';
import { ChooseEventTypePopupComponent } from 'src/app/Popups/ChooseEventType-Popup/ChooseEventType-Popup.component';
import { LocalStorageHandlerService } from 'src/app/Services/Utils/LocalStorageHandler.service';
import { EventCalendar } from 'src/app/Shared/Classes/EventCalendar';
import { EventWindowService } from 'src/app/Services/Utils/EventWindow.service';
import { GestionUsuariosCalendarioGeneralClass } from 'src/app/Shared/Classes/GestionUsuariosCalendarioGeneralClass';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';
import { iCustomCheckboxOptions } from 'src/app/Shared/Interfaces/Utils/iCustomCheckboxOptions';
import { CalendarFiltersPopupComponent } from 'src/app/Popups/CalendarFilters-Popup/CalendarFilters-Popup.component';
import { LandmarkService } from 'src/app/Services/Api/Landmark.service';
import { iModelCalendarFiltersPopup } from 'src/app/Shared/Interfaces/Utils/iModelCalendarFiltersPopup';

@Component({
  selector: 'app-GestionUsuarios-CalendarioGeneral',
  templateUrl: './GestionUsuarios-CalendarioGeneral.component.html',
  styleUrls: ['./GestionUsuarios-CalendarioGeneral.component.scss'],
})
export class GestionUsuariosCalendarioGeneralComponent implements OnInit {
  @Input() isMaster: boolean = false;
  @Input() isCurso: boolean = false;
  @Input() isTeacher: boolean = false;
  @Input() get formationId() {
    return this.extraClass.formationId;
  }
  set formationId(value) {
    this.extraClass.formationId = value;
  }
  @Input() get teacherId() {
    return this.extraClass.teacherId;
  }
  set teacherId(value) {
    this.extraClass.teacherId = value;
    this.extraClass.calendarFilters.teacherId = value;
  }
  @Input()
  get checkboxesArray() {
    return this.extraClass.checkBoxesArray;
  }
  set checkboxesArray(value) {
    this.extraClass.checkBoxesArray = value;
  }
  @Input() get promotionId() {
    return this.extraClass.promotionId;
  }

  set promotionId(value) {
    this.extraClass.promotionId = value;
    this.extraClass.calendarFilters.promotionId = value;
    if (!value) delete this.extraClass.calendarFilters.promotionId;
  }

  canEdit: boolean = false;
  isNewEventReadyToFill: boolean = false;
  SubjectEventWindow: Subject<any> | null = null;
  IconsHandlerService = IconsHandlerService;

  extraClass: GestionUsuariosCalendarioGeneralClass =
    new GestionUsuariosCalendarioGeneralClass();

  constructor(
    private publicHolidaysSE: PublicHolidaysService,
    private generalLoaderSE: GeneralLoaderService,
    private popupSE: PopupService,
    private eventWindowSE: EventWindowService,
    private staticUtlilitiesSE: StaticUtilitiesService,
    private landmarkSE: LandmarkService
  ) {}

  ngOnInit() {
    this.getEventWindowBS();
    this.getAllPublicHolidays(new Date().getFullYear());
    this.getAllLandmarks();
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    if (this.SubjectEventWindow) {
      this.SubjectEventWindow.next('');
      this.SubjectEventWindow.complete();
    }
    localStorage.removeItem('calendarFilters');
    localStorage.removeItem('calendarFiltersArray');
  }

  /**
   * HANDLERS
   */

  successGetAllPublicHolidaysHandler(res: iResultHttp) {
    let { data } = res;
    this.mapPublicHolidays(data);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  formatDate(date: string, hour: string): string {
    return `${String(date).slice(0, 10)}T${hour}:00`;
  }

  successGetAllLandmarksHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.events = data.map((landmark) => {
      const endDate = landmark.endDate ?? landmark.startDate;
      return {
        ...landmark,
        start: this.formatDate(landmark.startDate, landmark.startHour),
        end: this.formatDate(endDate, landmark.endHour),
      };
    });
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetLandmarkByIdHandler(res: iResultHttp) {
    const { data } = res;
    if (data.teachers) {
      const teachers = data.teachers.map((teacher) => teacher.id);
      data.teachers = teachers;
    }
    this.setEvent(data.landmarkTypeId, data);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */

  onCheckboxChange(event: iCustomCheckboxOptions) {
    // Si el evento es padre, se activan o desactivan todos los hijos
    if (event.father) {
      this.extraClass.checkBoxesArray.forEach((checkbox) => {
        if (checkbox.group === event.group && !checkbox.father) {
          checkbox.active = event.active;
        }
      });
    }

    // Si el evento es hijo y se desactiva, se desactiva el padre
    if (!event.father && !event.active) {
      this.extraClass.checkBoxesArray.forEach((checkbox) => {
        if (checkbox.group === event.group && checkbox.father) {
          checkbox.active = false;
        }
      });
    }

    // Si el evento es hijo y se activa, se activa el padre si todos los hijos están activos
    if (!event.father && event.active) {
      let allChildren = this.extraClass.checkBoxesArray.filter(
        (checkbox) => checkbox.group === event.group && !checkbox.father
      );
      let allActiveChildren = allChildren.filter((checkbox) => checkbox.active);
      if (allChildren.length === allActiveChildren.length) {
        this.extraClass.checkBoxesArray.forEach((checkbox) => {
          if (checkbox.group === event.group && checkbox.father) {
            checkbox.active = true;
          }
        });
      }
    }

    const activeCheckboxes = this.extraClass.checkBoxesArray
      .filter((checkbox) => checkbox.active && checkbox.landmarkTypeId)
      .map((checkbox) => checkbox.landmarkTypeId)
      .flat();
    this.setCheckboxesInLocalStorage(activeCheckboxes);
    this.filterLandmarks(activeCheckboxes, 'types');
  }
  setCheckboxesInLocalStorage(activeCheckboxes) {
    let filtersToUpload: iModelCalendarFiltersPopup = {
      selectedMasters: [],
      selectedCourses: [],
      selectedmasterNgModelsArray: [],
      selectedcourseNgModelsArray: [],
      events: [],
      teachers: [],
      classrooms: [],
      status: [],
    };
    let filters = LocalStorageHandlerService.getEncodedItem('calendarFilters');
    if (filters) {
      filters['events'] = activeCheckboxes;
      filtersToUpload = { ...filters };
    } else {
      filtersToUpload['events'] = activeCheckboxes;
    }
    LocalStorageHandlerService.setEncodedItem(
      'calendarFilters',
      filtersToUpload
    );
  }

  onSearch($event) {
    const value = $event.target.value;
    DebouncerService.formDebouncer(
      () => this.filterLandmarks(value, 'genericFilter'),
      'genericFilter',
      value,
      500
    );
  }

  filterLandmarks(value, key) {
    this.extraClass.calendarFilters[key] = value;
    if (!value) delete this.extraClass.calendarFilters[key];
    this.getAllLandmarks();
  }

  goToTimeline() {
    this.staticUtlilitiesSE.goTo(
      '/calendario-general/timeline',
      slideNavigationTypes.slideToLeft
    );
  }

  refreshLandmarks() {
    this.getAllLandmarks();
  }

  deleteFilters(returnValue) {
    if (returnValue.teachers.length === 0) {
      delete this.extraClass.calendarFilters.teachers;
      delete returnValue.teachers;
    }
    if (returnValue.types.length === 0) {
      delete this.extraClass.calendarFilters.types;
      delete returnValue.types;
    }
    if (returnValue.promotions.length === 0) {
      delete this.extraClass.calendarFilters.promotions;
      delete returnValue.promotions;
    }
    if (returnValue.classrooms.length === 0) {
      delete this.extraClass.calendarFilters.classrooms;
      delete returnValue.classrooms;
    }
  }

  setSelectedEventsInCheckboxes(returnValue) {
    if (returnValue.types.length > 0) {
      const types = returnValue.types;
      this.extraClass.checkBoxesArray.forEach((checkbox) => {
        if (
          checkbox.landmarkTypeId &&
          checkbox.landmarkTypeId.some((id) => types.includes(id))
        ) {
          checkbox.active = true;
        } else {
          checkbox.active = false;
        }
      });
    } else {
      this.extraClass.checkBoxesArray.forEach((checkbox) => {
        checkbox.active = true;
      });
    }
  }

  openFilterPopup() {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(
      CalendarFiltersPopupComponent,
      {},
      'rightPopupAnimation'
    );
    this.popupSE
      .returnData()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        const returnValue = res?.returnValue;
        if (returnValue) {
          // this.deleteFilters(returnValue);
          this.setSelectedEventsInCheckboxes(returnValue);
          this.extraClass.calendarFilters = {
            ...this.extraClass.calendarFilters,
            ...returnValue,
          };
          this.getAllLandmarks();
        }
        subject.next('');
        subject.complete();
      });
  }

  getEventWindowBS() {
    this.SubjectEventWindow = StaticUtilitiesService.createSubject();
    this.eventWindowSE
      .getIsOpen()
      .pipe(takeUntil(this.SubjectEventWindow))
      .subscribe((res) => {
        this.isNewEventReadyToFill = res;
      });

    this.eventWindowSE
      .connectToReloadLandmarks()
      .pipe(takeUntil(this.SubjectEventWindow))
      .subscribe((res) => {
        if (res) {
          this.getAllLandmarks();
        }
      });
  }

  setIsSelectingAs(event) {
    this.extraClass.isSelecting = event;
  }

  onDateChange(event: iDateChangeCalendar) {
    this.callPublickHolidaysIfNeeded(event);
    switch (event.option) {
      case 'Year':
        this.onDateChangeYear(event);
        break;
      case 'Month':
        this.onDateChangeMonth(event);

        break;
      case 'Week':
        break;
      case 'Day':
        break;
      case 'List':
        break;

      default:
        break;
    }
  }

  onEventClick(event) {
    this.eventWindowSE.close();
    localStorage.removeItem('newEvent');
    const eventId = event.event.id;
    this.getLandmarkById(eventId);
  }

  onDateClick(event) {
    if (this.isTeacher) return;
    // Cerramos la ventana de eventos
    this.eventWindowSE.close();
    // Eliminamos el evento anterior
    localStorage.removeItem('newEvent');
    const time = new Date(event.dateStr).toTimeString().split(' ')[0];
    if (!this.isMaster && !this.isCurso) {
      this.openEventPickerPopup(event, time);
    }

    if (this.isMaster || this.isCurso) {
      const promotionFormation: { promotionId: number; formationId: number } = {
        promotionId: this.promotionId,
        formationId: this.formationId,
      };
      if (this.isMaster) this.createEvent(event, 5, time, promotionFormation);
      if (this.isCurso) this.createEvent(event, 13, time, promotionFormation);
    }
  }

  openEventPickerPopup(event, time) {
    const subject: Subject<any> = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ChooseEventTypePopupComponent);
    this.popupSE
      .returnData()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res) => {
        if (!res['returnValue']) return;
        //Creamos un nuevo evento dependiendo del tipo devuelto por el popup
        this.createEvent(event, res['returnValue'], time);
        subject.next('');
        subject.complete();
      });
  }

  createEvent(
    event,
    id,
    time,
    promotionFormation?: { promotionId: number; formationId: number }
  ) {
    let newEvent = new EventCalendar().createEventCalendarObject(
      id,
      event.dateStr,
      time,
      promotionFormation
    );
    // Lo guardamos en el local storage
    LocalStorageHandlerService.setItem('newEvent', newEvent);
    // Abrimos la ventana de eventos
    this.eventWindowSE.open();
  }

  setEvent(eventType, landmark) {
    const newEvent = new EventCalendar().setEventCalendarObject(
      eventType,
      landmark
    );
    LocalStorageHandlerService.setItem('newEvent', newEvent);
    // Abrimos la ventana de eventos
    this.eventWindowSE.open();
  }

  onDateChangeYear(event) {}

  onDateChangeMonth(event) {}

  callPublickHolidaysIfNeeded(event: iDateChangeCalendar) {
    if (event.year !== this.extraClass.calendarFilters.year) {
      this.extraClass.calendarFilters.year = event.year;
      this.getAllPublicHolidays(event.year);
      this.getAllLandmarks();
    }
  }

  mapPublicHolidays(data) {
    let events: any = [];

    data.forEach((holiday) => {
      // Si la fiesta es nacional o de la comunidad valenciana se añade al calendario
      if (holiday.counties === null || holiday?.counties?.includes('ES-VC')) {
        events.push({
          title: holiday.localName,
          date: holiday.date,
          backgroundColor: '#000000',
          textColor: '#FFF',
          allDay: true,
          className: 'public-holiday',
          holiday: true,
          display: 'background',
          // rendering: 'background',
        });
      }
    });
    this.extraClass.publicHolidays = events;
  }

  /**
   * API CALLS
   */

  getAllPublicHolidays(year) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.publicHolidaysSE.getPublicHolidaysForAll(behaviorSubject, { year });
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllPublicHolidaysHandler(res),
        });
      });
  }

  getAllLandmarks() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.landmarkSE.getAll(behaviorSubject, this.extraClass.calendarFilters);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetAllLandmarksHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getLandmarkById(landmarkId) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.landmarkSE.getSingle(behaviorSubject, landmarkId);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetLandmarkByIdHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
