import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventWindowService {

  private _isOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _hasToReloadLandmarks: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

constructor() { }

  getIsOpen(): BehaviorSubject<boolean>{
    return this._isOpen;
  }

  open(): void {
    this._isOpen.next(true);
  }

  close(): void {
    this._isOpen.next(false) ;
  }

  connectToReloadLandmarks(): BehaviorSubject<boolean>{
    return this._hasToReloadLandmarks;
  }

  reloadLandmarks(): void {
    this._hasToReloadLandmarks.next(true);
    setTimeout(() => {
      this._hasToReloadLandmarks.next(false);
    }, 150);
  }


}
