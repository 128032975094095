import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';

export class GestionUsuariosFormacionesComponentNewVersionClass {
  // custom input options
  minPrinceCustomInputOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Precio mínimo',
  };
  maxPrinceCustomInputOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Precio máximo',
  };
  startDateTutoringCustomInputOptions: iOptionsCustomInput = {
    type: 'date',
    placeholder: 'Fecha de inicio',
  };

  // Selector options
  modalityAttendanceSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Modalidad',
    clearable: true,
    multiple: {
      isMultiple: true,
      maxSelectedItems: 2,
      multipleTextReplacer: 'Varias modalidades seleccionadas',
    },
    search: true,
  };
  academicStateSelectorOptions: iOptionsSelector = {
    items: [
      {
        id: 1,
        name: 'Activo',
      },
      {
        id: 2,
        name: 'Inactivo',
      },
    ],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Estado',
    clearable: true,
    search: true,
  };
  promotionSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Promoción',
    clearable: true,
    search: true,
  };
  classroomSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Aula',
    clearable: true,
    search: true,
  };
  tutoringStateSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Estado',
    clearable: true,
    search: true,
  };
  masterSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Máster',
    clearable: true,
    search: true,
  };

  filtersNgModels = {
    minPrice: null,
    maxPrice: null,
    modalityAttendance: null,
    academicState: null,
    promotionId: null,
    masterId: null,
    startDate: null,
    classroomId: null,
    tutoringState: null,
  };
}
