import { Component, OnInit } from '@angular/core';
import { GeneralLoaderService, iResultHttp } from '@quasar-dynamics/basic-designsystem';
import { StaticUtilitiesService, slideNavigationTypes } from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { TimelineService } from 'src/app/Services/Api/Timeline.service';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';
import { CalendarioGeneralTimelineClass } from 'src/app/Shared/Classes/CalendarioGeneralTimelineClass';

@Component({
  selector: 'CalendarioGeneral-Timeline',
  templateUrl: './CalendarioGeneral-Timeline.component.html',
  styleUrls: ['./CalendarioGeneral-Timeline.component.scss']
})
export class CalendarioGeneralTimelineComponent implements OnInit {

  IconsHandlerService = IconsHandlerService;
  extraClass:CalendarioGeneralTimelineClass = new CalendarioGeneralTimelineClass()

  constructor(private staticUtilitiesSE:StaticUtilitiesService, private timelineSE:TimelineService, private generalLoaderSE:GeneralLoaderService) { }

  ngOnInit() {
    this.getAllTimeline();
  }

  ngAfterViewInit() {
    this.centerGantt();
  }

  /**
   * HANDLERS
   */

    
  successGetAllTimelineHandler(res:iResultHttp){
    const { course, master, incompany } = res.data.timeline;
    this.extraClass.fases[0].tareas = master;
    this.extraClass.fases[1].tareas = course;
    this.extraClass.fases[2].tareas = incompany;

    this.extraClass.fases = [...this.extraClass.fases]

    this.generalLoaderSE.removeFromLoaderAmount();
   }
 

  /**
   * FUNCTIONALITIES
   */

  goToCalendar() {
    this.staticUtilitiesSE.goTo('/calendario-general',slideNavigationTypes.slideToRight);
  }

  changeRange(event){
    const ranges = {
      0: 15,
      1: 7,
      2: 5,
      3: 3,
    } 
    this.extraClass.anchoDia = ranges[event];   
    this.getAllTimeline();  
    setTimeout(() => {
      
      this.centerGantt();
    }, 1); 
  }

  centerGantt() {
    const ganttContainer = document.getElementById('ganttContainer');
    if(ganttContainer){
      ganttContainer.scrollLeft = (ganttContainer.scrollWidth - ganttContainer.clientWidth) / 2;
    }
  }

  /**
   * API CALLS
   */

  getAllTimeline() {
  this.generalLoaderSE.addToLoaderAmount();
  const subject = StaticUtilitiesService.createSubject();
  const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
  this.timelineSE.getAll(behaviorSubject, this.extraClass.timelineFilters);
  behaviorSubject
    .pipe(
      takeUntil(subject),
      filter((res) => res)
    )
    .subscribe((res: iResultHttp) => {
      StaticUtilitiesService.apiResponseHandler(res, subject, [
      {
        method: () => this.successGetAllTimelineHandler(res),
      },
      {
        method: () => this.generalLoaderSE.removeFromLoaderAmount(),
        error:true
      }
     ]);
    });
  }

}
