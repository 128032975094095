export interface iUserData {
  id: number;
  email: string;
  name: string;
  surname: string;
  roles: string[];
  permissions: number[];
}

export class IUserData {
  public static getEmptyObject(): iUserData {
    return {
      id: 0,
      email: '',
      name: '',
      surname: '',
      roles: [],
      permissions: [],
    };
  }
}
