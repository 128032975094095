<div class="teacherCard__accordion">
  <div class="teacherCard__head">
    <p class="teacherCard__teacherName">Nombre del profe</p>
    <p class="teacherCard__teacherPrice">50€/hora</p>
    <Chip [bold]="true" size="sm" value="Pendiente" type="red" ></Chip>
    <div class="teacherCard__stars">
      <Stars [stars]="startsOptions" [readonly]="true"></Stars>
      <p>1.5</p>
    </div>
    <button (click)="switchOpen()" [ngClass]="{'teacherCard__expandBtn--open': open}" class="teacherCard__expandBtn">
      <mat-icon>expand_more</mat-icon>
    </button>
  </div>
  <div class="teacherCard__body" [ngClass]="{'teacherCard__body--open': open}" >
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Factura</th>
          <th>Pago</th>
          <th>Valoración</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of arrayToTest">
          <tr>
            <td>Asig. 01: INTRODUCCIÓN AL DISEÑO DE EXPERIENCIA</td>
            <td align="center">
              <Chip [bold]="true" [type]="'green'" [value]="'Si'" size="sm" ></Chip>
            </td>
            <td align="center">
              <Chip [bold]="true" [type]="'red'" [value]="'No'" size="sm" ></Chip>
            </td>
            <td align="center">
              <div class="teacherCard__body--box">
                <span>
                  4.5
                </span>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>