<div class="popupGeneralContainer">
  <div class="header">
    <p class="title">Asociar profesor a producto</p>
  </div>

  <div class="body">
    <div class="fullSection">
      <div class="subColumnLong">
        <label for="selector">Seleccionar formación</label>
        <selector [options]="opcionesFormaciones" (change)="selectFormation($event)">
          
        </selector>

        <div class="subColumnLong" *ngIf="formation">
          <label for="selector">Seleccionar promoción</label>
          <selector [(ngModel)]="objectToPass.promotion" [options]="opcionesPromociones" (change)="selectPromotion($event)"></selector>
          <label for="selector">Seleccionar rol</label>
          <selector [(ngModel)]="objectToPass.position" [options]="opcionesRoles" (change)="isComplete()"></selector>
        </div>

      </div>
    </div>
  </div>

  <div class="footer">
    <btn classList="violetButton" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="principalButton" [ngClass]="{'disabled':!isComplete()}" (click)="assignTeacherToPromotion()">
      <ng-container>
        Finalizar
      </ng-container>
    </btn>
  </div>

</div>