import { Component, Input, OnInit } from '@angular/core';
import {
  GeneralLoaderService,
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { AddTeacherBillPopupComponent } from 'src/app/Popups/AddPopup/AddTeacherBill-Popup/AddTeacherBill-Popup.component';
import { EditHonorarioPopupComponent } from 'src/app/Popups/EditHonorario-Popup/EditHonorario-Popup.component';
import { BillService } from 'src/app/Services/Api/Bill.service';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { GestionUsuariosProfesoresDetallesPendienteFacturacionClass } from 'src/app/Shared/Classes/GestionUsuariosProfesoresDetallesPendienteFacturacionClass';

@Component({
  selector: 'GestionUsuarios-Profesores-Detalles-PendienteFacturacion',
  templateUrl:
    './GestionUsuarios-Profesores-Detalles-PendienteFacturación.component.html',
  styleUrls: [
    './GestionUsuarios-Profesores-Detalles-PendienteFacturación.component.scss',
  ],
})
export class GestionUsuariosProfesoresDetallesPendienteFacturacionComponent
  implements OnInit
{
  @Input() get dataTeacher() {
    return this.extraClass._dataTeacher;
  }
  set dataTeacher(value) {
    this.extraClass._dataTeacher = value;
    this.extraClass.teacherFullName = `${value.user.name} ${value.user.surname}`;
    const interval = setInterval(() => {
      if (value && value.id) {
        this.sendTypeToFilter();
        clearInterval(interval);
      }
    }, 100);
  }

  extraClass: GestionUsuariosProfesoresDetallesPendienteFacturacionClass =
    new GestionUsuariosProfesoresDetallesPendienteFacturacionClass(
      this.openEditHonorarioPopup.bind(this)
    );

  constructor(
    private teacherSE: TeacherService,
    private generalLoaderSE: GeneralLoaderService,
    private popupSE: PopupService,
    private billSE: BillService
  ) {}

  ngOnInit() {}

  /**
   * HANDLERS
   */

  successGetPendingBillsByTeacherIdHandler(res: iResultHttp) {
    const { data, total } = res.data;
    this.extraClass.pendingBills = data;
    this.extraClass.totalPendingBills = total;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successUpdateTeacherPendingFeeHandler(res: iResultHttp) {
    const { data } = res;
    this.getPendingBillsByTeacherId();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successCreateBillTeacherHandler(res: iResultHttp) {
    const { data } = res;
    this.getPendingBillsByTeacherId();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */

  sendTypeToFilter() {
    switch (this.extraClass.filterTypeSubmenuSelected) {
      case 0:
        delete this.extraClass.pendingBillsFilters.type;
        break;

      case 1:
        this.extraClass.pendingBillsFilters.type = 2;
        break;

      case 2:
        this.extraClass.pendingBillsFilters.type = 12;
        break;

      case 3:
        this.extraClass.pendingBillsFilters.type = 14;
        break;

      case 4:
        this.extraClass.pendingBillsFilters.type = 3;
        break;

      case 5:
        this.extraClass.pendingBillsFilters.type = 4;
        break;

      default:
        break;
    }
    this.getPendingBillsByTeacherId();
  }

  onPageChange(event) {
    this.extraClass.pendingBillsFilters.num_pagina = event;
    this.getPendingBillsByTeacherId();
  }

  openEditHonorarioPopup(row) {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(EditHonorarioPopupComponent, {
      data: { generalFee: row.fee, hourlyFee: row.hourlyFee },
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res) => {
        const returnValue = res['returnValue'];
        if (returnValue) {
          this.prepareObjectToEditHonorarios(row, returnValue).then(
            (objectToPass) => this.updateTeacherPendingFee(objectToPass)
          );
        }
        subject.next('');
      });
  }

  prepareObjectToEditHonorarios(row, returnValue): Promise<any> {
    return new Promise((resolve) => {
      const objectToPass: any = {
        id: row.id,
        table: row.table,
      };
      if (returnValue.hourlyFee) {
        objectToPass.fee = returnValue.hourlyFee;
      } else {
        objectToPass.fee = returnValue.generalFee;
      }
      resolve(objectToPass);
    });
  }

  getSearch(event) {
    const search = event.target.value;
    this.extraClass.pendingBillsFilters.genericFilter = search;
    if (!search) delete this.extraClass.pendingBillsFilters.genericFilter;
    this.getPendingBillsByTeacherId();
  }

  openAddBillPopup() {
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(AddTeacherBillPopupComponent, {
      data: {
        teacherName: this.extraClass.teacherFullName,
        teacherId: this.extraClass._dataTeacher.id,
      },
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res) => {
        const returnValue = res.returnValue;
        if (returnValue) {
          this.createBillTeacher(returnValue);
        }
        subject.next('');
      });
  }

  onToggleChange(submenuSelected) {
    this.extraClass.filterTypeSubmenuSelected = Number(submenuSelected);
  }

  /**
   * API CALLS
   */

  getPendingBillsByTeacherId() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.teacherSE.getPendingBillsByTeacherId(
      behaviorSubject,
      this.extraClass._dataTeacher.id,
      this.extraClass.pendingBillsFilters
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetPendingBillsByTeacherIdHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  updateTeacherPendingFee(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.teacherSE.updateTeacherPendingFee(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateTeacherPendingFeeHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  createBillTeacher(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.billSE.createBillTeacher(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateBillTeacherHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
