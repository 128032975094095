<div class="fullView">
  <p class="fwBold">Matrícula normal</p>

  <div class="loaderdiv" *ngIf="!studentDataStepOne">
    <loaderIcon></loaderIcon>
  </div>
  <div class="inputs" *ngIf="studentDataStepOne">
    <div
      class="tricolumn"
      *ngFor="
        let enrollment of studentDataStepOne.enrollments;
        let index = index
      "
    >
      <CustomInput
        [readOnly]="true"
        label="Formación"
        [placeHolder]="'Formación'"
        [(ngModel)]="enrollment?.promotion.formation.name"
      ></CustomInput>
      <CustomInput
        [readOnly]="true"
        label="Edición"
        [placeHolder]="'Edición'"
        [(ngModel)]="enrollment?.promotion.edition"
      >
      </CustomInput>
      <div class="subColumn especial">
        <label>Modalidad</label>
        <selector
          [options]="opcionesModalidad1"
          [(ngModel)]="modalidadSeleccionada[index]"
          (change)="sendToUpdate($event, 'modality', 'enrollment', index)"
          *ngIf="index === 0"
        ></selector>
        <selector
          [options]="opcionesModalidad2"
          [(ngModel)]="modalidadSeleccionada[index]"
          (change)="sendToUpdate($event, 'modality', 'enrollment', index)"
          *ngIf="index === 1"
        ></selector>
      </div>
    </div>
  </div>
</div>
