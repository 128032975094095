
export interface iMainViewPromotion {
  id: number;
  formation: string;
  edition: string;
  shift?: string | null;
  hours?: string | null;
  startDate: string;
  endDate?: string | null;
  students: number;
  AFGroup?: string | null;
  classroom?: string | null;
  academicState: string;
  academicStateColor: string;
  formationType: string;
}

export class IMainViewPromotion {
  static createEmptyObject(): iMainViewPromotion {
    return {
      id: 0,
      formation: '',
      edition: '',
      shift: '',
      hours: '',
      startDate: '',
      endDate: '',
      students: 0,
      AFGroup: '',
      classroom: '',
      academicState: '',
      academicStateColor: '',
      formationType: '',
    }
  }
}
