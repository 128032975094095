import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { iResultHttp, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { SubirArchivoPopupComponent } from 'src/app/Popups/SubirArchivo-Popup/SubirArchivo-Popup.component';
import { DocumentService } from 'src/app/Services/Api/Document.service';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'documentosProfesor',
  templateUrl: './GestionUsuarios-Profesores-Detalles-Documentos.component.html',
  styleUrls: ['./GestionUsuarios-Profesores-Detalles-Documentos.component.scss']
})
export class GestionUsuariosProfesoresDetallesDocumentosComponent extends iUnsubscribeDestroy implements OnInit {
  
  @Input() dataTeacher: any = null;
  @Input() isDataLoaded: boolean = false;
  rolesAndPermissions: any = {
    roles: [],
    permissions: [],
  };

  @Output() refresh:EventEmitter<boolean> = new EventEmitter<boolean>();

  documentos: Array<any>= [];
  DNI: any = [];
  CV: any = [];
  Acuerdo: any = [];
  Otros: any = [];
  types: any = [{value: this.DNI, name: 'DNI'}, {value: this.CV, name: 'CV'}, {value: this.Acuerdo, name: 'Acuerdo'}, {value: this.Otros, name: 'Otros'}]

  constructor(private popupSE: PopupService, private teacherSE:TeacherService, private documentSE: DocumentService) {
    super()
  }

  ngOnInit() {
    this.rolesAndPermissions = StaticUtilitiesService.getUserPermissionsAndRoles()
  }
  
  ngOnChanges(changes:SimpleChanges){
    if(changes['dataTeacher']){
      if(changes['dataTeacher'].currentValue){
        this.documentos = this.dataTeacher?.documents ?? [];
        this.types.forEach(type => type.value = [])
        this.classifyDocuments(this.documentos);
        this.isDataLoaded = true;
      }
    }
  }

  /**
   * FUNCTIONALITIES
   */

  classifyDocuments(documents) {
    documents.map((doc) => {
      this.types.forEach(type => {
        if(type.name == doc.typeOf){
          type.value.push(doc);
        }
      });
    })
  }

  uploadDocumento(typeOf){
    this.popupSE.openPopup(SubirArchivoPopupComponent, {accion: "Subir documento al profesor", multiple:true ,formatos: ["application/pdf"], size: 10485760})
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return};
       
      let data:any = {
        document:[],
      };
      data.document = res['returnValue'].map((file) => {return {name: file.file.name, url: file.base64, teacher: this.dataTeacher.id, typeOf: typeOf}})
      this.subirDocumento(data);
      this._unsubInd.next("");
    })
  }
  openFile(url){
    window.open(url, "_blank");
  }
  checkRolesAndPermissions(){
    return this.rolesAndPermissions.roles.includes('ROLE_ADMIN') || this.rolesAndPermissions.roles.includes('ROLE_SUPER_ADMIN') || this.rolesAndPermissions.permissions.includes(2) 
  }


  /**
   * API CALLS
   */

  eliminarDocumentoPopup(documento){
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {accion: "Eliminar documento", mensaje: "¿Estás seguro que quieres eliminar el documento?"});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd9)).subscribe(res=>{
      if(!res)return;
      this.deleteDocument([documento.id]);
      this._unsubInd9.next("");
    })
  }

  subirDocumento(data){
    this.documentSE.create(data);
    this.documentSE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe((res:iResultHttp)=>{
      if(!res){return}
       
      StaticUtilitiesService.showFeedback('Documento subido correctamente');
      this.refresh.emit(true);
      this._unsubInd2.next('')
    })
    this.documentSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  deleteDocument(ids:number[]){
    this.documentSE.delete(ids);
    this.documentSE.getResultDelete().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Documento eliminado correctamente');
      this.refresh.emit(true);
      this._unsubInd.next('')
    })
    this.documentSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }


}
