import { Component, OnInit } from '@angular/core';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import {
  iDate,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, filter, takeUntil } from 'rxjs';
import { AcademicService } from 'src/app/Services/Api/Academic.service';
import { ClassroomService } from 'src/app/Services/Api/Classroom.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { GestionUsuariosMainViewClass } from 'src/app/Shared/Classes/GestionUsuariosMainViewClass';

@Component({
  selector: 'GestionUsuarios-MainView',
  templateUrl: './GestionUsuarios-MainView.component.html',
  styleUrls: ['./GestionUsuarios-MainView.component.scss'],
})
export class GestionUsuariosMainViewComponent implements OnInit {
  extraClass: GestionUsuariosMainViewClass = new GestionUsuariosMainViewClass(
    this.onDuplicatePromotion.bind(this),
    this.deleteArchievePromotion.bind(this)
  );

  userData: any = null;

  constructor(
    private promotionSE: PromotionFormationService,
    private generalLoaderSE: GeneralLoaderService,
    private classroomSE: ClassroomService,
    private academicSE: AcademicService,
    private staticUtilitiesSE: StaticUtilitiesService
  ) {}

  async ngOnInit() {
    this.userData = await this.getDataFromLocalStorage();
    this.getPromotionList();
    this.getClassroomSelector();
    this.getAcademicState();
    this.subscribeToGeneralLoader();
  }

  ngOnDestroy(): void {
    this.extraClass.subjectGeneralLoader.next('');
    this.extraClass.subjectGeneralLoader.complete();
  }

  /**
   * HANDLERS
   */

  successGetPromotionListHandler(res: iResultHttp) {
    const { data, total } = res.data;
    this.extraClass.mainViewPromotions = data;
    this.extraClass.totalMainViewPromotions = total;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetClassroomSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.classroomSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetAcademicStateHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.academicStateSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDuplicatePromotionHandler(res: iResultHttp) {
    const { message } = res;
    StaticUtilitiesService.showFeedback(String(message));
    this.getPromotionList();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteArchievePromotionHandler(res: iResultHttp) {
    const { data } = res;
    StaticUtilitiesService.showFeedback('Promoción eliminada correctamente');
    this.getPromotionList();

    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */

  getDataFromLocalStorage() {
    return new Promise((resolve, reject) => {
      let dataFromLocal = localStorage.getItem('usr')!;
      let decripted = iStaticUtilities.simpleDecrypt(dataFromLocal);
      let userData = JSON.parse(decripted);
      resolve(userData);
    });
  }

  getFilters(value, key: string) {
    if (key.toLowerCase().includes('date') && value)
      value = iDate.javascriptConvert(new Date(value)).toStringDate('JAP');
    this.extraClass.promotionFilter[key] = value;
    if (!value) delete this.extraClass.promotionFilter[key];
    this.getPromotionList();
  }

  handleDatesFilter(event, key: string) {
    this.extraClass.filterModels[key] = event;
  }

  goToFormations() {
    this.staticUtilitiesSE.goTo(
      'formaciones/listado-formaciones',
      slideNavigationTypes.slideToTop
    );
  }

  subscribeToGeneralLoader() {
    this.generalLoaderSE
      .getLoaderAmount()
      .pipe(takeUntil(this.extraClass.subjectGeneralLoader))
      .subscribe((res) => {
        this.extraClass.isDataLoaded = res === 0;
      });
  }

  pageChanged(value) {
    this.extraClass.promotionFilter.num_pagina = value;
    this.getPromotionList();
  }

  onDuplicatePromotion(row) {
    const objectToPass = { promotionId: row.id };
    this.duplicatePromotion(objectToPass);
  }

  goToPromotion(event) {
    const typeOf = this.removeAccentsAndLowerCase(event.formationType);
    this.staticUtilitiesSE.goTo(
      'formaciones/promocion-detalle',
      slideNavigationTypes.slideToTop,
      { promotion: event.id, typeOf: typeOf }
    );
  }

  removeAccentsAndLowerCase(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  /**
   * API CALLS
   */

  getPromotionList() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.getPromotionList(
      behaviorSubject,
      this.extraClass.promotionFilter
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetPromotionListHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getClassroomSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.classroomSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetClassroomSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getAcademicState() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.academicSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetAcademicStateHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  duplicatePromotion(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.duplicatePromotion(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDuplicatePromotionHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteArchievePromotion(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.deleteArchievePromotion(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteArchievePromotionHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
