<fullContainer>
  <ng-container >
    <header></header>
    <div class="subHeader">
      <toggleSubmenu class="secondary" [elements]="['Mostrar listado', 'Mostrar fases']" [(ngModel)]="optionSubmenuSelected"></toggleSubmenu>
      <div class="buttonContainer">
        <btn classList="principalButton" (click)="downloadExcel()" >
          <ng-container >
            Descargar listado en .XLS
          </ng-container>
        </btn>
      </div>
    </div>
    <div class="filters">
      <customSearcher ngDefaultControl placeHolder="Busca aquí los incompanies" [(ngModel)]="filter"
        (change)="getFilter(filter,'genericFilter')"></customSearcher>
      <div (click)="displayFilters()" class="filterButton" *ngIf="!isFilterDisplayed" >
        <span>Filtrar matriculaciones</span>
        <mat-icon>filter_list</mat-icon>
      </div>
      <div (click)="displayFilters()" class="filterButton active" *ngIf="isFilterDisplayed">
        <span>Filtrar matriculaciones</span>
        <mat-icon>clear</mat-icon>
      </div>
    </div>
    <div class="filterContent" *ngIf="isFilterDisplayed">
      <!-- <div class="threeColumnSelectores">
        <selector [options]="selectorGroupOptions" (change)="getFilter($event, 'group')"></selector>
        <selector [options]="selectorTypeOptions" (change)="getFilter($event, 'type')"></selector>
        <selector [options]="selectorPhaseOptions" (change)="getFilter($event, 'phase')"></selector>
      </div> -->
    </div>


    <!-- <Incompanies-List *ngIf="optionSubmenuSelected == 0" [downloadExcelFile]="downloadExcelFile" [total]="total" [filters]="filters" [incompanies]="incompanies" [isDataLoaded]="isDataLoaded"></Incompanies-List>
    <pagination-controls [autoHide]="true" previousLabel="Anterior"  nextLabel="Siguiente" *ngIf="optionSubmenuSelected == 0" (pageChange)="pageChange($event)"></pagination-controls> -->
    <!--Pipeline  -->
    <!-- <Incompanies-Pipeline *ngIf="optionSubmenuSelected == 1" [rolesAndPermissions]="rolesAndPermissions" [isThirdColumn]="isThirdColumn" [isCRMIncompanies]="isCRMIncompanies" [formalizedProposals]="formalizedProposals" [thirdColumnIncompanies]="thirdColumnIncompanies" [incompanyCRM]="incompanyCRM" [isPropuestaFormalLoaded]="isPropuestaFormalLoaded" (refresh)="refresh($event)"></Incompanies-Pipeline> -->

    <p class="noData">Este apartado estará en la siguiente fase</p>
    
  </ng-container>
</fullContainer>
