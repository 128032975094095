import {
  IOptionCustomTextArea,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';

export class TutoriaAlumnoRellenaDatosReservaClass {

  
    // selectorOptions
  masterSelectorOptions: iOptionsSelector = {
    items: [],
    clearable:false,
    label: 'Máster *',
    placeholder: 'Selecciona tu máster',
    classList: 'tutoria-alumno',
    append: true,
    bindLabel: 'name',
    bindValue: 'masterId',
    search: true,
  };
  tutorSelectorOptions: iOptionsSelector = {
    items: [],
    clearable:false,
    label: 'Tutor *',
    placeholder: 'Selecciona tu tutor',
    classList: 'tutoria-alumno',
    append: true,
    bindLabel: 'teacherName',
    bindValue: 'teacherId',
    search: true,
  };
  modalidadSelectorOptions: iOptionsSelector = {
    items: [],
    clearable:false,
    label: 'Modalidad de tutoría *',
    placeholder: 'Selecciona la modalidad',
    classList: 'tutoria-alumno',
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    search: true,
  };
  fechaSelectorOptions: iOptionsSelector = {
    items: [],
    clearable:false,
    label: 'Fecha *',
    placeholder: 'Selecciona la modalidad',
    classList: 'tutoria-alumno',
    append: true,
    bindLabel: 'startDate',
    bindValue: 'startDate',
    search: true,
    emptyItems: 'No hay fechas disponibles para este tutor'
  };
  horaSelectorOptions: iOptionsSelector = {
    items: [],
    clearable:false,
    label: 'Hora *',
    placeholder: 'Selecciona la modalidad',
    classList: 'tutoria-alumno',
    append: true,
    bindLabel: 'hours',
    bindValue: 'tutoringStudentId',
    search: true,
  };

  // Custom text area options
  comentariosTextAreaOptions: IOptionCustomTextArea = {
    cols: 30,
    rows: 2,
    placeholder: 'Comentarios',
    label: 'Comentarios',
    classList: 'tutoria-alumno',
  };

  //Reservas array
  reserva: iReserva = {
    master: null,
    tutor: null,
    modality: null,
    date: null,
    time: null,
    comments: null,
  };

  // stepsBoolean
  steps:{
    master: boolean,
    tutor: boolean,
    date: boolean,
    time: boolean,
    modality: boolean,
    comments: boolean,
  } = {
    master: true,
    tutor: false,
    date: false,
    time: false,
    modality: false,
    comments: false,
  };

  /**
   * FUNCTIONS
   */

  setStep() {
    this.steps.tutor = Boolean(this.reserva.master);
    this.steps.date = this.steps.tutor && Boolean(this.reserva.tutor);
    this.steps.time = this.steps.date && Boolean(this.reserva.date);
    this.steps.modality = this.steps.time && Boolean(this.reserva.time);
    this.steps.comments = this.steps.modality && Boolean(this.reserva.modality);
  }

  cleanModelsOnChange(type: 'master' | 'tutor' | 'modality' | 'date' | 'time'){
    switch (type) {
      case 'master':
        this.reserva.tutor = null;
        this.reserva.modality = null;
        this.reserva.date = null;
        this.reserva.time = null;
        this.reserva.comments = null;
        break;
      case 'tutor':
        this.reserva.modality = null;
        this.reserva.date = null;
        this.reserva.time = null;
        this.reserva.comments = null;
        break;
      case 'date':
        this.reserva.modality = null;
        this.reserva.time = null;
        this.reserva.comments = null;
        break;
      case 'time':
        this.reserva.modality = null;
        this.reserva.comments = null;
        break;
      case 'modality':
        this.reserva.comments = null;
        break;
    }

    this.setStep();
  
  }
}

export interface iReserva {
  master: string | null;
  tutor: string | null;
  modality: string | null;
  date: string | null;
  time: string | null;
  comments: string | null;
}
