import { environment } from 'src/environments/environment';
import { headerData } from '../Components/main-table/main-table.component';
import { iPromotionFormationFilter } from '../Interfaces/iPromotionFormation';
import {
  IMainViewPromotion,
  iMainViewPromotion,
} from '../Interfaces/iMainViewPromotion';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';
import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { start } from 'repl';
import { Subject } from 'rxjs';

export class GestionUsuariosMainViewClass {
  constructor(
    private onDuplicatePromotion: (row: iMainViewPromotion) => void,
    private deleteArchievePromotion: (row: iMainViewPromotion) => void
  ) {}

  subjectGeneralLoader = new Subject();
  isDataLoaded: boolean = true;

  totalMainViewPromotions: number = 0;

  // mainViewPromotions
  mainViewPromotions: iMainViewPromotion[] = [
    IMainViewPromotion.createEmptyObject(),
  ];

  // promotionFilter
  promotionFilter: iPromotionFormationFilter = {
    num_devoluciones: environment.numDevolucionesCalls,
    num_pagina: 1,
  };

  formationTypeChipColor: { [key: string]: string } = {
    master: '--color-principal',
    curso: '--cursos-color',
    incompany: '--yellowState',
  };

  filterModels: any = {
    startDate: null,
    endDate: null,
  };

  // headerData
  headerData: headerData = [
    {
      variant: 'chip',
      display: 'Tipo',
      key: 'formationType',
      alignment: 'center',
      border: true,
      className: 'fwBold',
      classNameGenerator: (any, index, row) => {
        return this.formationTypeChipColor[
          this.removeAccentsAndLowerCase(row.formationType)
        ];
      },
    },
    {
      variant: 'bold',
      display: 'Nombre formación',
      key: 'formation',
      alignment: 'start',
      className: 'truncateText maxWidth-250',
      width: '250px',
    },
    {
      variant: 'standard',
      display: 'Edición',
      key: 'edition',
      alignment: 'center',
      className: 'truncateText maxWidth-150',
      width: '150px',
    },
    {
      variant: 'standard',
      display: 'Turno',
      key: 'shift',
      alignment: 'center',
      className: 'truncateText maxWidth-150',
      width: '150px',
    },
    {
      variant: 'standard',
      display: 'Horario',
      key: 'hours',
      alignment: 'center',
      className: 'truncateText maxWidth-150',
      width: '150px',
    },
    {
      variant: 'standard',
      display: 'Inicio',
      key: 'startDate',
      alignment: 'center',
      className: 'truncateText maxWidth-150',
      width: '150px',
      date: {
        date: true,
        pipeTemplate: 'dd/MM/yyyy',
      },
    },
    {
      variant: 'standard',
      display: 'Finalización',
      key: 'endDate',
      alignment: 'center',
      className: 'truncateText maxWidth-150',
      width: '150px',
      date: {
        date: true,
        pipeTemplate: 'dd/MM/yyyy',
      },
    },
    {
      variant: 'standard',
      display: 'Nº Alumnos',
      key: 'students',
      alignment: 'center',
      className: 'truncateText maxWidth-150',
      width: '150px',
    },
    {
      variant: 'standard',
      display: 'AF - Grupo',
      key: 'AFGroup',
      alignment: 'center',
      className: 'truncateText maxWidth-150',
      width: '150px',
    },
    {
      variant: 'standard',
      display: 'Aula',
      key: 'classroom',
      alignment: 'center',
      className: 'truncateText maxWidth-150',
      width: '150px',
    },
    {
      variant: 'chip',
      display: 'Estado',
      key: 'academicState',
      alignment: 'center',
      className: 'fwBold',
      width: '150px',
      classNameGenerator: (any, index, row) => row.academicStateColor,
    },
    {
      variant: 'icons',
      display: '',
      key: '',
      icons: [
        {
          action: (row) => this.onDuplicatePromotion(row),
          image: IconsHandlerService.getDuplicateIcon(),
        },
        {
          action: (row) => this.deleteArchievePromotion(row),
          image: IconsHandlerService.getDeleteIcon(),
        },
      ],
    },
  ];

  // selectorOptions
  typeSelectorOptions: iOptionsSelector = {
    items: [
      { name: 'Master', value: 'master' },
      { name: 'Curso', value: 'course' },
      { name: 'Incompany', value: 'incompany' },
    ],
    append: true,
    bindLabel: 'name',
    bindValue: 'value',
    placeholder: 'Tipo de formación',
    search: true,
  };
  classroomSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Aula',
    search: true,
  };
  academicStateSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    placeholder: 'Estado',
    search: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 0,
      
    },
  };

  // customInputOptions
  startDateCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Fecha de inicio',
    type: 'date',
  };
  endDateCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Fecha de fin',
    type: 'date',
  };

  /**
   * FUNCTIONALITIES
   */

  removeAccentsAndLowerCase(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }
}
