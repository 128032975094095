<ng-select
  placeholder="Selecciona un estado"
  ngDefaultControl
  [name]="name"
  [ngModel]="model"
  (click)="$event.stopPropagation(); $event.stopImmediatePropagation()"
  (change)="sendNgModel($event)"
  [searchable]="false"
  [clearable]="false"
  [appendTo]="'body'"
  [disabled]="chipSelectorOptions.readonly"
  [items]="chipSelectorOptions.items"
  bindLabel="name"
  class="chipSelector"
>
  <ng-template ng-label-tmp let-item="item">
    <Chip
      [border]="chipSelectorOptions.border ?? false"
      [value]="item.name"
      [type]="item.color"
      [bold]="chipSelectorOptions.bold"
      [size]="chipSelectorOptions.size"
    ></Chip>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <Chip
      [border]="chipSelectorOptions.border ?? false"
      [value]="item.name"
      [type]="item.color"
      [bold]="chipSelectorOptions.bold"
      [size]="chipSelectorOptions.size"
    ></Chip>
  </ng-template>
</ng-select>
