import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from '@quasar_dynamics/basic-designsystem';

@Component({
  selector: 'app-SubirArchivo-Popup',
  templateUrl: './SubirArchivo-Popup.component.html',
  styleUrls: ['./SubirArchivo-Popup.component.scss']
})
export class SubirArchivoPopupComponent implements OnInit {
  file: any = null;
  multiple: boolean = false;
  constructor(public dialogRef: MatDialogRef<SubirArchivoPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificaciones: NotificationsService) { }

  ngOnInit() {
    this.multiple =  this.modalData.multiple;
  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  removeDocumento(documento, index) {
    this.file.splice(index, 1);
  }


  updateFile(event){
    this.file = event;
     
    if (!this.modalData.formatos.includes(this.file[0].file.type)) {
      this.notificaciones.showError("Formato de documento no permitido")
      this.file = null;
      return;
    }
    if (this.file[0].file.size > this.modalData.size) {
      this.notificaciones.showError("Archivo demasiado pesado");
      this.file = null;
      return;
    }
  }
}
