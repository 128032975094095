<ng-container *ngIf="options.readonly">
  <div class="displayItemsNoEdit">

    <ng-container *ngIf="labelItemArray.length > 0" >
      
      <ng-container *ngFor="let label of labelItemArray;index as index; trackBy:labelsTrackBy" >
        <span class="itemToShow">
          {{ label }}
        </span>
      </ng-container>
    </ng-container>
    </div>
    
</ng-container>


<ng-container *ngIf="!options.readonly" >
  
  <selector ngDefaultControl  [name]="name" [ngModel]="model"  (ngModelChange)="sendNgModel($event)"  [options]="options"></selector>
  
</ng-container>