import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iTeacher } from "./iTeacher";
import { iStudent } from "./iStudent";
import { iAddress } from "./iAddress";
import { iBankData } from "./iBankData";

export interface iUser {
    id: number,
    email?: string,
    roles?: any,
    password?: string,
    name?: string,
    surname?: string,
    registerDate?: iDate,
    DNI?: string,
    phone?: string,
    birthdate?: iDate,
    gender?: string,
    biography?: string,
    typeOf?: string,
    company?: string,
    jobPosition?: string,
    employmentStatus?: string,
        languages?: [],
    photo?: string,
    observation?: string,
        socialMedias?: [],
    state?: string,
    active?: boolean,
    nationality?: string,
    teacher?: iTeacher,
    student?: iStudent,
    address?: iAddress,
    bankData?: iBankData,
}

export class iUser extends iBaseObject {
    static convertFromCollection(ob: any): Array<iUser> {
        let iUserCollection: Array<iUser> = [];
        ob.forEach((element: any) => {
            iUserCollection.push(this.convertFromObject(element));
        });
        return iUserCollection;
    }

    static convertFromObject(ob: any): iUser {
        let iUserObj = new iUser();
        iUserObj.id = ob.id;
        iUserObj.email = ob.email;
        iUserObj.roles = ob.roles;
        iUserObj.password = ob.password;
        iUserObj.name = ob.name;
        iUserObj.surname = ob.surname;
        iUserObj.registerDate = iDate.phpConvert(ob.registerDate);
        iUserObj.DNI = ob.DNI;
        iUserObj.phone = ob.phone;
        iUserObj.birthdate = iDate.phpConvert(ob.birthdate);
        iUserObj.gender = ob.gender;
        iUserObj.biography = ob.biography;
        iUserObj.typeOf = ob.typeOf;
        iUserObj.company = ob.company;
        iUserObj.jobPosition = ob.jobPosition;
        iUserObj.employmentStatus = ob.employmentStatus;
        iUserObj.languages = ob.languages;
        iUserObj.photo = ob.photo;
        iUserObj.observation = ob.observation;
        iUserObj.socialMedias = ob.socialMedia;
        iUserObj.state = ob.state;
        iUserObj.active = ob.active;
        iUserObj.nationality = ob.nationality;
        iUserObj.teacher = ob.teacher ? iTeacher.convertFromObject(ob.teacher) : iTeacher.createEmptyObject();
        iUserObj.student = ob.student ? iStudent.convertFromObject(ob.student) : iStudent.createEmptyObject();
        iUserObj.address = ob.address ? iAddress.convertFromObject(ob.address) : iAddress.createEmptyObject();
        iUserObj.bankData = ob.bankData ? iBankData.convertFromObject(ob.bankData) : iBankData.createEmptyObject();
        return iUserObj;
    }

    static createEmptyObject(): iUser {
        let iUserObj = new iUser();
        iUserObj.email = '';
        iUserObj.roles = null;
        iUserObj.password = '';
        iUserObj.name = '';
        iUserObj.surname = '';
        iUserObj.registerDate = iDate.javascriptConvert(new Date());
        iUserObj.DNI = '';
        iUserObj.phone = '';
        iUserObj.birthdate = iDate.javascriptConvert(new Date());
        iUserObj.gender = '';
        iUserObj.biography = '';
        iUserObj.typeOf = '';
        iUserObj.company = '';
        iUserObj.jobPosition = '';
        iUserObj.employmentStatus = '';
        iUserObj.languages = [];
        iUserObj.photo = '';
        iUserObj.observation = '';
        iUserObj.socialMedias = [];
        iUserObj.state = '';
        iUserObj.active = false;
        iUserObj.nationality = '';
        iUserObj.teacher = iTeacher.createEmptyObject();
        iUserObj.student = iStudent.createEmptyObject();
        iUserObj.address = iAddress.createEmptyObject();
        iUserObj.bankData = iBankData.createEmptyObject();
        return iUserObj;
    }
}

export interface iUserFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    email?: string,
    roles?: any,
    password?: string,
    name?: string,
    surname?: string,
    registerDate?: string,
    DNI?: string,
    phone?: string,
    birthdate?: string,
    gender?: string,
    biography?: string,
    typeOf?: string,
    company?: string,
    jobPosition?: string,
    employmentStatus?: string,
    languages?: number,
    photo?: string,
    observation?: string,
    socialMedias?: number,
    state?: string,
    active?: boolean,
    nationality?: string,
    teacher?: number,
    student?: number,
    address?: number,
    bankData?: number,
}