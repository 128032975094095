import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';

@Injectable({
  providedIn: 'root'
})
export class FeeService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getFeesByTeacher(id: number){
    this.http.get(this.urlApi + '/api/fee/teacher/' + id, this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedEnrollment = data ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedEnrollment});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getFeesByTeacher, args: [id] });
        this.checkStatusError(error);
      },
    });

  }

  update(fee:any){
    this.http.put(this.urlApi+"/api/fee/"+fee.id, fee, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [fee] });
        this.checkStatusError(error);
      },
    });
  }

}
