import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  GeneralLoaderService,
  iOptionsSelector,
  iResultHttp,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { EnrollmentService } from 'src/app/Services/Api/Enrollment.service';
import { ProjectService } from 'src/app/Services/Api/Project.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { StudentPromotionService } from 'src/app/Services/Api/StudentPromotion.service';

@Component({
  selector: 'ChangeStatusEnrollment-Popup',
  templateUrl: './ChangeStatusEnrollment-Popup.component.html',
  styleUrls: ['./ChangeStatusEnrollment-Popup.component.scss'],
})
export class ChangeStatusEnrollmentPopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChangeStatusEnrollmentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private studentPromotionSE: StudentPromotionService,
    private generalLoaderSE: GeneralLoaderService,
    private enrollmentSE: EnrollmentService,
    private projectSE: ProjectService,
    private promotionFormationSE: PromotionFormationService
  ) {}

  ngModels = {
    enrollmentState: null,
    projectState: null,
    promotion: null,
  };

  enrollmentStateSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    clearable: false,
    label: 'Estado de la matrícula',
    placeholder: 'Seleccione un estado',
    search: true,
  };
  projectStateSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    clearable: false,
    label: 'Estado del proyecto',
    placeholder: 'Seleccione un estado',
    search: true,
  };
  editionSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'edition',
    bindValue: 'id',
    clearable: false,
    label: 'Promoción',
    placeholder: 'Seleccione una promoción',
    search: true,
  };

  ngOnInit() {
    this.getStudentPromotionState();
    this.getEnrollmentStateSelector();
    this.getProjectStateSelector();
    this.getPromotionsByFormationId();
  }

  /**
   * HANDLERS
   */

  successGetStudentPromotionStateHandler(res: iResultHttp) {
    const { data } = res;
    this.ngModels = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successgetEnrollmentStateSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.enrollmentStateSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetProjectStateSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.projectStateSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetPromotionsByFormationIdHandler(res: iResultHttp) {
    const { data } = res;
    this.editionSelectorOptions.items = data;
    this.setDataFromModal();

    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  setDataFromModal() {
    this.ngModels.promotion = this.modalData.data.editionId;
  }

  /**
   * API CALLS
   */

  getStudentPromotionState() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.studentPromotionSE.getState(
      behaviorSubject,
      this.modalData.data.studentPromotionId
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetStudentPromotionStateHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getEnrollmentStateSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.enrollmentSE.getEnrollmentStateSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successgetEnrollmentStateSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getProjectStateSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.getStateSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetProjectStateSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getPromotionsByFormationId() {
    const subject = StaticUtilitiesService.createSubject();
    this.generalLoaderSE.addToLoaderAmount();
    this.promotionFormationSE.getPromotionsByFormationId(
      this.modalData.data.formationId
    );
    this.promotionFormationSE
      .getResultPromotionsByFormationId()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetPromotionsByFormationIdHandler(res),
          },
        ]);
      });

    this.promotionFormationSE
      .getResultPromotionsByFormationIdError()
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        subject.next('');
        subject.complete();
        this.generalLoaderSE.removeFromLoaderAmount();
      });
  }
}
