<div 
  [style.top]="top" 
  [style.left]="left" 
  [style.width]="width" 
  [matTooltip]="nombreMostrar" 
  matTooltipClass="tooltip"
  [matTooltipPosition]="'above'" 
  [style.height]="height" 
  [style.background-color]="color ?? '#f9d8da'"
  [style.color]="textColor "
>
  <span 
    class="porcentaje" 
    [style.width]="tarea.completado * 100 + '%'" 
    *ngIf="mostrarAvance">
  </span>
  <span
      class="nombre" *ngIf="!tarea.comprimida">{{tarea.nombre}}
  </span>
  <span class="backArrow" *ngIf="startsBefore">
    <img src="/assets/Icons/General/backArrow.svg" alt="arrowLeft" [ngClass]="{'invert': textColor === '#000' || textColor === '#000000'}" >
</span>
  <span class="forwardArrow" *ngIf="endsAfter" >
    <img src="/assets/Icons/General/forwardArrow.svg" alt="arrowLeft" [ngClass]="{'invert': textColor === '#000' || textColor === '#000000'}" >
</span>

</div>