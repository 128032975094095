import { iBaseObject } from '@quasar_dynamics/basic-designsystem';
import { iDocument } from './iDocument';
import { iTeacher } from './iTeacher';
import { iStudent } from './iStudent';

export interface iBill {
  id: number;
  amount?: number;
  document?: iDocument;
  teacher?: iTeacher;
  student?: iStudent;
}

export class iBill extends iBaseObject {
  static convertFromCollection(ob: any): Array<iBill> {
    let iBillCollection: Array<iBill> = [];
    ob.forEach((element: any) => {
      iBillCollection.push(this.convertFromObject(element));
    });
    return iBillCollection;
  }

  static convertFromObject(ob: any): iBill {
    let iBillObj = new iBill();
    iBillObj.id = ob.id;
    iBillObj.amount = ob.amount;
    iBillObj.document = ob.document
      ? iDocument.convertFromObject(ob.document)
      : iDocument.createEmptyObject();
    iBillObj.teacher = ob.teacher
      ? iTeacher.convertFromObject(ob.teacher)
      : iTeacher.createEmptyObject();
    iBillObj.student = ob.student
      ? iStudent.convertFromObject(ob.student)
      : iStudent.createEmptyObject();
    return iBillObj;
  }

  static createEmptyObject(): iBill {
    let iBillObj = new iBill();
    iBillObj.amount = -1;
    iBillObj.document = iDocument.createEmptyObject();
    iBillObj.teacher = iTeacher.createEmptyObject();
    iBillObj.student = iStudent.createEmptyObject();
    return iBillObj;
  }
}

export interface iBillFilter {
  num_devoluciones: number;
  num_pagina: number;
  genericFilter?: string;
  studentPromotion?: number;
  amount?: number;
  document?: number;
  teacher?: number;
  student?: number;
}
export interface iBillTeacherFilter {
  num_devoluciones: number;
  num_pagina: number;
  genericFilter?: string;
  state?: number;
}
