import {
  Component,
  ComponentRef,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  Type,
  ViewChild,
} from '@angular/core';
import { TSendIsEditing } from 'src/app/Pages/GestionUsuarios/GestionUsuarios-Formaciones-newVersion/Formaciones-DetailView/_holder/DetailView-baseContainer/DetailView-baseContainer.component';
import { EventEmitter } from '@angular/core';
import { AppAnchorDirective } from '@quasar-dynamics/basic-designsystem';

export interface iEditableBoxComponentOptions {
  component: Type<any>;
  callback: Function;
}

@Component({
  selector: 'Editablebox',
  templateUrl: './Editablebox.component.html',
  styleUrls: ['./Editablebox.component.scss'],
})
export class EditableboxComponent implements OnInit {
  @Input() title!: string;
  @Input() bgColor: 'white' | 'gray' = 'white';
  @Input() fullHeight: boolean = false;
  @Input() canEdit: boolean = true;
  @Input() componentOptions:
    | iEditableBoxComponentOptions
    | iEditableBoxComponentOptions[]
    | null = null;
  // Send the isEditing state to the parent component
  @Output() sendIsEditing: EventEmitter<TSendIsEditing> =
    new EventEmitter<TSendIsEditing>();

  @ViewChild(AppAnchorDirective) appAnchor?: AppAnchorDirective;

  isEditing: boolean = false;
  isArray: boolean = false;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {}


  ngAfterViewInit(): void {
    this.isArray = Array.isArray(this.componentOptions);
    if (
      this.componentOptions &&
      !Array.isArray(this.componentOptions) &&
      this.componentOptions.component &&
      this.appAnchor
    ) {
      const componentFactory = this.appAnchor.renderComponent(
        this.componentOptions.component
      );
      this.setCallbackFunction(componentFactory, this.componentOptions);
      this.appAnchor.setComponentAsChild(componentFactory);
    }

    if (Array.isArray(this.componentOptions)) {
      this.componentOptions.forEach((componentOption) => {
        if (componentOption && componentOption.component && this.appAnchor) {
          const componentFactory = this.appAnchor.renderComponent(
            componentOption.component
          );
          this.setCallbackFunction(componentFactory, componentOption);
          this.appAnchor.setComponentAsChild(componentFactory);
        }
      });
    }
  }

  handleClickedEdit() {
    this.isEditing = !this.isEditing;
    this.sendIsEditing.emit({ [this.title]: this.isEditing });
  }

  setCallbackFunction(
    componentFactory: ComponentRef<any>,
    componentOptions: iEditableBoxComponentOptions
  ) {
    this.renderer.listen(
      componentFactory.location.nativeElement,
      'click',
      () => {
        componentOptions.callback();
      }
    );
  }
}
