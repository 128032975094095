<div class="gridAndPagination">
  <div class="fullView" skeleton [isDataLoaded]="areReadyAllCursos">
    <!-- Tarjetas -->

    <p class="noData" *ngIf="allCursos.length === 0">
      No hay elementos para mostrar
    </p>

    <div
      class="card"
      (click)="goToDetailView(curso.id)"
      *ngFor="
        let curso of allCursos
          | paginate
            : {
                itemsPerPage: filterAllCursos.num_devoluciones,
                currentPage: filterAllCursos.num_pagina,
                totalItems: totalCursos
              }
      "
    >
      <div class="spaceBetween">
        <p class="title">{{ curso.name ?? "-" }}</p>
        <Chip
          [type]="curso.active ? '--greenState' : '--redState'"
          [value]="curso.activeLabel"
        ></Chip>
      </div>
      <p class="textBody">
        {{ curso.description ?? "-" }}
      </p>
      <div class="spaceBetween">
        <p class="title">{{ curso.duration ?? "-" }} H</p>
        <p class="title end">{{ curso.price ?? 0 }} €</p>
      </div>
      <div class="attendanceModalitiesLine">
        <ng-container *ngFor="let modality of curso.modalities">
          <span class="modality-pill">
            <mat-icon>done</mat-icon>
            <p class="text">{{ modality.name ?? "-" }}</p>
          </span>
        </ng-container>
      </div>
    </div>
  </div>

  <pagination-controls
    [autoHide]="true"
    previousLabel="Anterior"
    nextLabel="Siguiente"
    (pageChange)="pageChangeEmmiter($event)"
  ></pagination-controls>
</div>
