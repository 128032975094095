import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'session-programacion-table',
  templateUrl: './session-programacion-table.component.html',
  styleUrls: ['./session-programacion-table.component.scss']
})
export class SessionProgramacionTableComponent implements OnInit {

  private _data: {title:string,text:string} = {title:'test',text:'tost'};

  @Input() get data(): {title:string,text:string} {
    return this._data;
  };

  set data(val: {title:string,text:string}) {
    this._data = val;
    setTimeout(() => {
      this.cdr.detectChanges();
    }
    ,0);
  }
  
  constructor(private cdr:ChangeDetectorRef) { }

  ngOnInit() {
  }
  
  ngAfterViewInit() {
  }



}
