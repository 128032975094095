<Editablebox bgColor="gray" [canEdit]="false"  >


  <accordion class="facturacion" [title]="'Facturas'">
    <div class="whiteBox">

      <main-table [dataSource]="extraClass.billingBills" [headerData]="extraClass.headerDataTableFacturas" ></main-table>

    </div>
  </accordion>

  <accordion class="facturacion" [title]="'Profesores con facturas pendientes'">
    <div class="whiteBox">
      <main-table [dataSource]="extraClass.billingTeachers" [headerData]="extraClass.headerDataTableProfesores"></main-table>
    </div>
  </accordion>


</Editablebox>