import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[joinSessionButton]',
})
export class JoinSessionButtonDirective implements OnInit {
  @Input() buttonText: string = 'Unirme a la sesión';
  @Input() url: string | null | undefined = 'www.google.com';

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngOnInit() {
    const button:HTMLElement = this.elementRef.nativeElement.querySelector('button');
    this.prepareButtonVisual(button);
    if(this.url){
      this.setOnclickEvent(button);    
    }
  }

  prepareButtonVisual(button:HTMLElement){
    this.renderer.addClass(button, 'generalbutton');
    this.renderer.addClass(button, 'joinSession');
    this.renderer.setProperty(button, 'innerText', this.buttonText);
  }

  setOnclickEvent(button:HTMLElement){
    this.renderer.setProperty(
      button,
      'onclick',
      () => window.open(this.url!) 
    );
  }
}
