import { Injectable } from '@angular/core';
import { StaticUtilitiesService } from '@quasar-dynamics/basic-designsystem';
import { PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';

@Injectable({
  providedIn: 'root',
})
export class AssureDeleteService {
  constructor(private popupSE: PopupService) {}

  assureDelete(element: string, action, callback: Function) {
    const Subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      elemento: element,
      accion: action,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(Subject))
      .subscribe((res) => {
        const returnValue = res?.returnValue;
        if (returnValue === undefined) return;
        if (returnValue) {
          callback();
        }
        Subject.next('');
        Subject.complete();
      });
  }
}
