import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'AddDisposition-Popup',
  templateUrl: './AddDisposition-Popup.component.html',
  styleUrls: ['./AddDisposition-Popup.component.scss']
})
export class AddDispositionPopupComponent implements OnInit {

  isActive: boolean = false;

  isEdit: boolean = false;

  ngModels = {
    name: '',
  }

  nameCustomInputOptions: iOptionsCustomInput = {
    label: 'Nombre',
    placeholder: 'Nombre de la disposición',
    type: 'text',
    required: true,
  };


  constructor(public dialogRef: MatDialogRef<AddDispositionPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) { }

  ngOnInit() {
    if(this.modalData?.edit){
      this.setIsEditing()
    }

  }

  /**
   * FUNCTIONALITIES
   */

  isCompleted() {
    if (this.ngModels.name) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }

  forceClosePopup() {
    this.dialogRef.close();
  }

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  finishButton() {
    this.closePopup(this.ngModels);
  }
  setIsEditing(){
    this.isEdit = true;
    this.ngModels = this.modalData.data
    this.isCompleted()
  }




}
