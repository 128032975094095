import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconsHandlerService } from 'src/app/Services/Utils/IconsHandler.service';
import {
  ITutoringSession,
  iTutoringSession,
} from '../../Interfaces/Utils/iTutoringSession';
import { headerData } from '../main-table/main-table.component';
import {
  GeneralLoaderService,
  iResultHttp,
  slideNavigationTypes,
  StaticUtilitiesService,
} from '@quasar-dynamics/basic-designsystem';
import { filter, Subject, takeUntil } from 'rxjs';
import { TutoringService } from 'src/app/Services/Api/Tutoring.service';

@Component({
  selector: 'TutoringByTeacher',
  templateUrl: './TutoringByTeacher.component.html',
  styleUrls: ['./TutoringByTeacher.component.scss'],
})
export class TutoringByTeacherComponent implements OnInit {
  @Output() onEditTutoringTeacher = new EventEmitter<iTutoringSession>();
  @Output() onDeleteTutoringTeacher = new EventEmitter<iTutoringSession>();
  @Output() onRowClick = new EventEmitter<iTutoringSession['students'][0]>();
  @Output() resetTutoringStudent = new EventEmitter<number>();
  @Output() onDeleteTutoringStudent = new EventEmitter<number>();

  @Input() tutorings: iTutoringSession[] = [ITutoringSession.getEmptyObject()];
  @Input() isDataLoaded: boolean = false;
  @Input() teacherName: string = '';

  IconsHandlerService = IconsHandlerService;

  headerData: headerData = [
    {
      variant: 'chip',
      display: 'Horario',
      key: 'hours',
      alignment: 'center',
      width: '150px',
      className: 'moduleChipLightBlue',
      classNameGenerator: () => '--light-blue',
    },
    {
      variant: 'bold',
      display: 'Alumno',
      key: 'studentName',
      className: 'truncateText maxWidth-150',
      width: '150px',
    },
    {
      variant: 'bold',
      display: 'Teléfono',
      key: 'phone',
      className: 'truncateText maxWidth-150',
      width: '150px',
    },
    {
      variant: 'bold',
      display: 'Email',
      key: 'email',
      className: 'truncateText maxWidth-150',
      width: '150px',
    },
    {
      variant: 'bold',
      display: 'Modalidad',
      key: 'tutoringAttendance',
      className: 'truncateText maxWidth-100',
      width: '100px',
    },
    {
      variant: 'button',
      display: 'Enlace a zoom',
      key: 'meetingUrl',
      width: '150px',
      alignment: 'center',
      action: (item, index) => {
        let url: string = '';
        if (item.meetingUrl.includes('http')) {
          url = item.meetingUrl;
        } else {
          url = 'https://' + item.meetingUrl;
        }
        window.open(url, '_blank');
      },
      buttonStyle: `principalButton fitWidth bold`,
      text: 'Enlace Zoom',
      displayGenerator: (item) => (item.meetingUrl ? true : false),
    },
    {
      variant: 'chip',
      display: 'Estado',
      key: 'tutoringStudentState',
      alignment: 'center',
      width: '150px',
      classNameGenerator: (label, index, row) => row.tutoringStudentStateColor,
    },
    {
      variant: 'standard',
      display: 'Máster',
      key: 'formationName',
    },
    {
      variant: 'standard',
      display: 'Comentarios',
      key: 'comment',
    },
    {
      variant: 'icons',
      display: '',
      key: '',
      icons: [
        {
          matIconName: 'backspace',
          action: (row) => {
            this.sendResetTutoringStudent(row.tutoringStudentId);
          },
        },
        {
          image: IconsHandlerService.getDeleteIcon(),
          action: (row) => {
            this.deleteSlot(row.tutoringStudentId);
          },
        },
      ],
    },
  ];

  loaderAmountsubject = new Subject<boolean>();

  constructor(
    private generalLoaderSE: GeneralLoaderService,
    private staticUtilitiesSE: StaticUtilitiesService
  ) {}

  ngOnInit() {
    this.generalLoaderSE
      .getLoaderAmount()
      .pipe(takeUntil(this.loaderAmountsubject))
      .subscribe((amount) => {
        this.isDataLoaded = amount == 0;
      });
  }

  ngOnDestroy(): void {
    this.loaderAmountsubject.next(true);
    this.loaderAmountsubject.complete();
  }

  /**
   * FUNCTIONALITY
   */

  sendEdit(tutoringTeacher) {
    this.onEditTutoringTeacher.emit(tutoringTeacher);
  }
  sendDelete(tutoringTeacher) {
    this.onDeleteTutoringTeacher.emit(tutoringTeacher);
  }
  onDuplicateClick(tutoringId: number) {
    this.staticUtilitiesSE.goTo(
      '/formaciones/crear-tutoria',
      slideNavigationTypes.slideToLeft,
      { duplicate: tutoringId }
    );
  }
  sendRowClick(student) {
    this.onRowClick.emit(student);
  }
  sendResetTutoringStudent(id) {
    this.resetTutoringStudent.emit(id);
  }
  deleteSlot(id: number) {
    this.onDeleteTutoringStudent.emit(id);
  }
}
