import {
  IOptionCustomTextArea,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';

export class TutoriaCrearClass {
  // booleans
  isEdit: boolean = false;
  isActive: boolean = false;

  // optionSelected
  optionSelected = 0;

  tutoringForDuplicate: number = 0;

  //   ngModels
  ngModels: {
    teacher: any;
    master: any;
    convocatoria: any;
    promotion: any;
    modality: any;
    classroom: any;
    startDate: Date;
    startTime: any;
    endTime: any;
    slots: null | number;
    topic: any;
    topicDisplay?: any;
    observations?: any;
  } = {
    teacher: null,
    master: null,
    convocatoria: null,
    promotion: null,
    modality: null,
    classroom: null,
    startDate: new Date(),
    startTime: null,
    endTime: null,
    slots: null,
    topic: null,
    topicDisplay: null,
    observations: null,
  };

  // selectorOptions
  teacherSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    clearable: false,
    label: 'Profesor *',
    placeholder: 'Seleccione un profesor',
    search: true,
  };
  masterSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleTextReplacer: 'Varios masters seleccionados',
      multipleLimitReplacer: 3,
    },
    bindLabel: 'name',
    bindValue: 'id',
    clearable: false,
    label: 'Máster *',
    placeholder: 'Seleccione master',
    search: true,
  };
  convocatoriaSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleTextReplacer: 'Varias convocatorias seleccionadas',
    },
    bindLabel: 'call',
    bindValue: 'startDate',
    clearable: false,
    label: 'Convocatoria *',
    placeholder: 'Seleccione convocatorias',
    search: true,
  };

  edicionesSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleTextReplacer: 'Varias ediciones seleccionadas',
    },
    bindLabel: 'edition',
    bindValue: 'id',
    clearable: false,
    label: 'Edición *',
    placeholder: 'Seleccione ediciones',
    search: true,
  };
  modalidadSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    clearable: false,
    label: 'Modalidad *',
    placeholder: 'Seleccione la modlaidad',
    search: true,
  };
  aulaSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    clearable: false,
    label: 'Aula *',
    placeholder: 'Seleccione un aula',
    search: true,
  };
  slotsSelectorOptions: iOptionsSelector = {
    items: [],
    label: 'Slots *',
    append: true,
    bindLabel: 'slotMinutes',
    bindValue: 'id',
    clearable: false,
    placeholder: 'Seleccione slots',
  };
  tematicasSelectorOptions: iOptionsSelector = {
    items: [],
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleTextReplacer: 'Varias temáticas seleccionadas',
    },
    bindLabel: 'name',
    bindValue: 'id',
    clearable: false,
    label: 'Temáticas *',
    placeholder: 'Seleccione temáticas',
    search: true,
  };

  //   Custom input options
  fechaSelectorOptions: iOptionsCustomInput = {
    type: 'date',
    label: 'Fecha *',
    placeholder: 'Seleccione una fecha',
    required: true,
  };
  horaInicioCustomInputOptions: iOptionsCustomInput = {
    type: 'time',
    label: 'Hora inicio *',
    placeholder: 'Seleccione una hora',
    required: true,
  };
  horaFinCustomInputOptions: iOptionsCustomInput = {
    type: 'time',
    label: 'Hora fin *',
    placeholder: 'Seleccione una hora',
    required: true,
  };
  tematicasDisplaySelectorOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Seleccione temáticas',
    readOnly: true,
  };

  //   Custom textarea options
  observacionesCustomTextareaOptions: IOptionCustomTextArea = {
    cols: 30,
    rows: 10,
    label: 'Observaciones',
    placeholder: 'Introduzca observaciones',
  };

  // Functions
  setReadonliesInSelectorOptions() {
    this.convocatoriaSelectorOptions.readonly =
      this.convocatoriaSelectorOptions.items.length === 0 ? true : false;
    this.edicionesSelectorOptions.readonly =
      this.edicionesSelectorOptions.items.length === 0 ? true : false;
  }
}
