import { Component, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { StaticUtilitiesService, iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { StudentService } from 'src/app/Services/Api/Student.service';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'UnAlumnoSelectedFirstStep',
  templateUrl: './UnAlumnoSelectedFirstStep.component.html',
  styleUrls: ['./UnAlumnoSelectedFirstStep.component.scss']
})
export class UnAlumnoSelectedFirstStepComponent extends iUnsubscribeDestroy implements OnInit {
  @Output() dataUnAlumnoSelectedFirstStep = new EventEmitter();
  @Input() dataFromParent: any = null;
  @Input() dataFromCRM: any = null;

  isSelectedUser: boolean = false;
  selectorStudent: any = null;
  selectorOptionsStudent: iOptionsSelector = {
    items: [],
    search: true,
    placeholder: "Busque un alumno por nombre o correo electrónico",
  }
  data: any = {
    name: "",
    surname: "",
    phone: "",
    email: "",
    phonePrefix: '+34'
  }
  constructor(private studentSE: StudentService) {
    super();
  }

  ngOnInit() {
    this.getAlumnosForSelector();
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataFromParent']) {
      if(changes['dataFromParent'].currentValue) {

         
        this.selectorStudent = changes['dataFromParent'].currentValue.student;
        if(this.selectorStudent){this.isSelectedUser = true;}
        this.data = changes['dataFromParent'].currentValue.data;
        this.setUserFromParent(this.data)
      } 
    }
  }

  /**
   * FUNCTIONALITY
   */

  selectedUser(event) {
     
    if (!event) {
      Object.keys(this.data).forEach((item: any) => {
        this.data[item] = "";
      });
       
      this.isSelectedUser = false;
      return;
    }
    this.isSelectedUser = true;
    this.data.name = event.name;
    this.data.surname = event.surname;
    this.data.phone = event.phone;
    this.data.email = event.email;
    this.data.phonePrefix = event.phonePrefix;
     
  }

  setUserFromParent(data){
     
    this.data.name = data.name;
    this.data.surname = data.surname;
    this.data.phone = data.phone;
    this.data.email = data.email;
    this.data.phonePrefix = data.phonePrefix;
  }

  emitData() {
    this.dataUnAlumnoSelectedFirstStep.emit({data:this.data, student:this.selectorStudent});
  }

  addPhonePrefix(event){
    this.data.phonePrefix = event.code;
    this.emitData();
  }
  /**
   * API CALLS
   */

  getAlumnosForSelector() {
    this.studentSE.getAllStudents({});
    this.studentSE.getResultallStudents().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) return;
      let { data } = res;
      this.selectorOptionsStudent.items = data.map((item: any) => { return { ...item, label: item.name + " - " + item.email } });
       
      this._unsubInd.next('');
    });
    this.studentSE.getResultallStudentsError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (!res) { return }
      if (res.status != 401) {
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    });

  }
}
