<fullContainer gradient="bajo">
  <ng-container>
    <header></header>
    <div class="formGeneralContainer">
      <div class="timeline">
        <div class="container">
          <p class="title">Añadiendo promoción</p>
          <div class="options">
            <span [class.active]="optionSelected == 0" (click)="scroll('informacionPropuesta')">
              <mat-icon>help_center</mat-icon>
              Información propuesta formal
            </span>
            <span [class.active]="optionSelected == 1" (click)="scroll('informacionGeneral')">
              <mat-icon>info</mat-icon>
              Información general
            </span>
            <span [class.active]="optionSelected == 2" (click)="scroll('profesorado')">
              <mat-icon>school</mat-icon>
              Profesorado
            </span>
            <span [class.active]="optionSelected == 3" (click)="scroll('grupo')">
              <mat-icon>people</mat-icon>
              Grupo
            </span>
          </div>
        </div>
        <div class="footer">
          <btn classList="principalButton small fitWidth" (click)="confirmPopup()">
            <ng-container>
              Cancelar
            </ng-container>
          </btn>
          <btn classList="specialButton small fitWidth loading" (click)="createIncompanyEnroll()" [class.disabled]="!isActive">
            <ng-container *ngIf="!isLoading">
              Finalizar
            </ng-container>
            <ng-container *ngIf="isLoading">
              <loaderIcon class="white" size="small"></loaderIcon>
            </ng-container>
          </btn>
        </div>

      </div>
      <div class="form">

        <div class="contactoContainer">
          <div class="header">
            <p class="title">
              <mat-icon>help_center</mat-icon>
              Información propuesta formal
            </p>
          </div>
          <div class="body">

            <div class="doubleColumn">

              <CustomInput [readOnly]="true" [type]="'text'" class="longInput" [label]="'Nombre de la propuesta formal'" [placeHolder]="'Nombre de la propuesta formal'" [(ngModel)]="localStorageData.name"
              name="edicion"></CustomInput>
              
              <CustomInput [readOnly]="true" [type]="'text'" class="longInput" [label]="'Nombre de la empresa'" [placeHolder]="'Nombre de la empresa'" *ngIf="localStorageData.company" [(ngModel)]="localStorageData.company.name"
              name="edicion"></CustomInput>
              
              <CustomInput [readOnly]="true" [type]="'text'" class="longInput" [label]="'Nombre del grupo de empresas'" [placeHolder]="'Nombre del grupo de empresas'" *ngIf="localStorageData.holding" [(ngModel)]="localStorageData.holding.name"
              name="edicion"></CustomInput>
              
            </div>

            <div class="doubleColumn">
              <div class="fakeInputReadOnly">
                <label for="">Presupuesto</label>
                <div class="fakeReadonly">
                  <p>{{localStorageData.amount | currency:'EUR':true}}</p>
                </div>
              </div>

              <div class="fakeInputReadOnly">
                <label for="">Gasto previo</label>
                <div class="fakeReadonly">
                  <p>{{localStorageData.spent | currency:'EUR':true}}</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        
        <div class="generalInfo">
          <div class="header">
            <p class="title">
              <mat-icon>info</mat-icon>
              Información general
            </p>
          </div>
          <div class="body">

            <div class="subColumn">
              <label for="">Selecciona promoción</label>
              <selector [options]="opcionesPromociones" [(ngModel)]="promocionSelected" (change)="promotionSelector(promocionSelected)"></selector>
            </div>


            <CustomInput [type]="'text'" [readOnly]="isPromotionSelected" class="longInput" [label]="'Nombre de la promoción'" [placeHolder]="'Nombre de la promoción'" [(ngModel)]="data.promotion.edition"
            name="edicion" (change)="(isEdit)? updateLine(data.promotion.edition, 'edition', 'none') : isCompleted()"></CustomInput>

            <div class="doubleColumn">
              <CustomInput [type]="'date'" [readOnly]="isPromotionSelected" [label]="'Fecha de inicio'" [placeHolder]="'Fecha de inicio'" [(ngModel)]="data.promotion.startDate"
              name="Fecha de inicio" (date)="(isEdit)? updateLine(data.promotion.startDate, 'startDate', 'none') : setStartDate($event)"></CustomInput>
              <CustomInput [type]="'date'" [readOnly]="isPromotionSelected" [label]="'Fecha de fin'" [placeHolder]="'Fecha de fin'" [(ngModel)]="data.promotion.endDate"
              name="Fecha de fin" (date)="(isEdit)? updateLine(data.promotion.endDate, 'endDate', 'none') : setEndDate($event)"></CustomInput>
            </div>
            <div class="doubleColumn">
              <div class="subColumn">
                <label for="">Estado</label>
                <selector [options]="opcionesEstado" [disabled]="isPromotionSelected" [(ngModel)]="data.promotion.state" (change)="stateSelector(data.promotion.state)"></selector>
              </div>
              <CustomInput [type]="'text'" [readOnly]="isPromotionSelected"  [label]="'Horario'" [placeHolder]="'Horario'" [(ngModel)]="data.promotion.schedule"
              name="horario" (change)="(isEdit)? updateLine(data.promotion.schedule, 'schedule', 'none') : isCompleted()"></CustomInput>  
            </div>
          </div>
        </div>

        <div class="datosFiscales">
          <div class="header">
            <p class="title">
              <mat-icon>school</mat-icon>
              Profesorado
            </p>

          </div>
          <div class="body">
            <div class="doubleColumn">  
              <div class="subColumn">
                <label for="">Director</label>
                <selector [options]="opcionesDirector" [disabled]="isPromotionSelected" [(ngModel)]="data.promotion.directors" (change)="teacherSelector(data.promotion.directors,'directors')"></selector>
              </div>
              <div class="subColumn">
                <label for="">Coordinador/es</label>
                <selector [options]="opcionesCoordinadores" [disabled]="isPromotionSelected" [(ngModel)]="data.promotion.coordinators" (change)="teacherSelector(data.promotion.coordinators,'coordinators')"></selector>
              </div>

            </div>
            <div class="subColumn">
              <label for="">Profesor/es</label>
              <selector [options]="opcionesProfesores" [disabled]="isPromotionSelected" [(ngModel)]="data.promotion.teachers" (change)="teacherSelector(data.promotion.teachers,'teacher')"></selector>
            </div>

          </div>
        </div>

        <div class="groupInfo">
          <div class="header">
            <p class="title">
              <mat-icon>people</mat-icon>
              Grupo
            </p>

          </div>
          <div class="body">
            <CustomInput [type]="'text'" [label]="'Nombre del grupo'" [placeHolder]="'Nombre del grupo'" [(ngModel)]="data.group.name"
            name="edicion" (change)="(isEdit)? updateLine(data.group.name, 'name', 'group') : isCompleted()"></CustomInput>
            <div class="doubleColumn">
            
              <div class="inputSimulatorWithLabel">
                <p class="fakeLabel">Precio del grupo</p>
                <div class="inputSimulator">
                  <div class="input" 
                    [class.placeHolder]="data.groupPrice == 0 || data.groupPrice == null">
                    <ng-container
                      *ngIf="data.groupPrice == 0 || data.groupPrice == null">
                      <label>Precio del grupo</label>
                    </ng-container>
                    <ng-container
                      *ngIf="data.groupPrice != 0">{{data.groupPrice
                      | currency:'EUR'}}</ng-container>
                  </div>
                  <CustomInput [type]="'number'" [(ngModel)]="data.groupPrice"
                    [placeHolder]="'Precio del grupo'"
                    (change)="isCompleted()"
                    name="number"></CustomInput>
                </div>
              </div>

              <div class="subColumn">
                <label for="">Bonificación</label>
                <selector [options]="opcionesFUNDAE" [(ngModel)]="selectedFUNDAE" (change)="fundaeSelector(selectedFUNDAE)"></selector>
              </div>

            </div>
            <btn classList="generalbutton principalButton" (click)="openExcelPopup()">{{(isAlumnosLoaded) ? ' ✓ Alumnos añadidos' : 'Añadir alumnos al grupo'}}</btn>
          </div>
        </div>


        <div class="datosFiscales" [ngStyle]="{'opacity': '0', 'height': '33vh'}"></div>

      </div>
    </div>
  </ng-container>
</fullContainer>