import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: any) {
    this.http.get(this.urlApi + '/api/company' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedCourse = iStaticUtilities.normalizeNamesCollection(data);
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedCourse });
        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAll, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getAllCompaniesForFilter() {
    this.http.get(this.urlApi + '/api/companies', this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedCourse = iStaticUtilities.normalizeNamesCollection(data);
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedCourse });
        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAllCompaniesForFilter, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  getIndividual(id:number){
    this.http.get(this.urlApi + '/api/company/'+id, this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedCompany = iStaticUtilities.normalizeNames(data);
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedCompany });
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getIndividual, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

}
