import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type TSendIsEditing = { [key: string]: boolean };

@Component({
  selector: 'DetailView-baseContainer',
  templateUrl: './DetailView-baseContainer.component.html',
  styleUrls: ['./DetailView-baseContainer.component.scss']
})
export class DetailViewBaseContainerComponent implements OnInit {

  @Input() title!: string;
  @Input() bgColor: 'white' | 'gray' = 'white' ;
  @Input() fullHeight: boolean = false;
  // Send the isEditing state to the parent component
  @Output() sendIsEditing: EventEmitter<TSendIsEditing> = new EventEmitter<TSendIsEditing>();

  isEditing: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  handleClickedEdit() {
    this.isEditing = !this.isEditing;
    this.sendIsEditing.emit({[this.title]: this.isEditing});
  }
}
