import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, SubjectManagerService, iBehaviourSubject, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iFormation, iFormationFilter } from 'src/app/Shared/Interfaces/iFormation';

@Injectable({
  providedIn: 'root'
})
export class FormationService extends DefaultBaseService  {

  res: iResultHttp = new iResultHttp();
  subjectManager: iBehaviourSubject = new iBehaviourSubject();

  protected _AllDobleTitulacion = new BehaviorSubject<any>(null);
  getResultAllDobleTitulacion() {return this._AllDobleTitulacion}
  clearResultAllDobleTitulacion() {this._AllDobleTitulacion.next(null)}
  protected _AllDobleTitulacionError = new BehaviorSubject<any>(null);
  getResultAllDobleTitulacionError() {return this._AllDobleTitulacionError}
  clearResultAllDobleTitulacionError() {this._AllDobleTitulacionError.next(null)}

  protected _AllFormationListOV = new BehaviorSubject<any>(null);
  getResultAllFormationListOV() {return this._AllFormationListOV}
  clearResultAllFormationListOV() {this._AllFormationListOV.next(null)}
  protected _AllFormationListOVError = new BehaviorSubject<any>(null);
  getResultAllFormationListOVError() {return this._AllFormationListOVError}
  clearResultAllFormationListOVError() {this._AllFormationListOVError.next(null)}

  protected _AllFormationByStudentId = new BehaviorSubject<any>(null);
  getResultAllFormationByStudentId() {return this._AllFormationByStudentId}
  clearResultAllFormationByStudentId() {this._AllFormationByStudentId.next(null)}

  protected _AllFormationByStudentIdError = new BehaviorSubject<any>(null);
  getResultAllFormationByStudentIdError() {return this._AllFormationByStudentId}
  clearResultAllFormationByStudentIdError() {this._AllFormationByStudentId.next(null)}

  constructor(private http: HttpClient, private SubjectManagerService: SubjectManagerService) {
    super();
  }

  getAll(queryParams: iFormationFilter){
    this.http.get(this.urlApi + '/api/formation' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormation = iFormation.convertFromCollection( iStaticUtilities.normalizeNamesCollection(data.data) );
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:normalizedFormation,total:data.total}});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAll, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getAllTable(queryParams: iFormationFilter){
    this.http.get(this.urlApi + '/api/formation/list' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormation =  iStaticUtilities.normalizeNamesCollection(data.data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:normalizedFormation,total:data.total}});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAllTable, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getAllDobleTitulaciones(queryParams: iFormationFilter){
    this.http.get(this.urlApi + '/api/double/master' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormation =  iStaticUtilities.normalizeNamesCollection(data.data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:normalizedFormation,total:data.total}});
        this.sendNextObservable(this._AllDobleTitulacion, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._AllDobleTitulacionError, this.res,true, { method: this.getAllDobleTitulaciones, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getAllFormationsForSelectorOV(queryParams: any){
    this.http.get(this.urlApi + '/api/formation/selector' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormation =   iStaticUtilities.normalizeNamesCollection(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedFormation});
        this.sendNextObservable(this._AllFormationListOV, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._AllFormationListOVError, this.res,true, { method: this.getAllFormationsForSelectorOV, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  getFormationsSelectorAll(){
    this.http.get(this.urlApi + '/api/formation/all/selector', this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getFormationsSelectorAll, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  getFormationsByStudentId(studentId:number, queryParams:any){
    this.http.get(this.urlApi + '/api/formation/selector/' + studentId + this.optionsGet(queryParams) , this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormation =   iStaticUtilities.normalizeNamesCollection(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedFormation});
        this.sendNextObservable(this._AllFormationByStudentId, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._AllFormationByStudentIdError, this.res,true, { method: this.getFormationsByStudentId, args: [studentId, queryParams] });
        this.checkStatusError(error);
      }
    });
  }

  getSingle(id: number){
    this.http.get(this.urlApi + '/api/formation/'+id, this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormation =  iStaticUtilities.normalizeNames(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedFormation});
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getSingle, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  getFormationIdByPromotionId(behaviorSubject: BehaviorSubject<any>, promotionId: number) {
    this.http
      .get(
        this.urlApi + '/api/formation/promotion/' + promotionId,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getFormationIdByPromotionId,
            args: [behaviorSubject, promotionId],
          });
        },
      });
  }

  create(formation:iFormation){
    this.http.post(this.urlApi+"/api/formation", formation, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [formation] });
        this.checkStatusError(error);
      },
    });
  }

  update(formation:iFormation){
    this.http.put(this.urlApi+"/api/formation/"+formation.id, formation, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [formation] });
        this.checkStatusError(error);
      },
    });
  }

  delete(ids: Array<number|string>){
    let idsObjs = this.convertArrayObject(ids, "ids");
    this.http.delete(this.urlApi+"/api/formation", this.sendBodyOptions(idsObjs) ).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [ids] });
        this.checkStatusError(error);
      },
    });
  }

  

}
