import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StaticUtilitiesService, iResultHttp, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { UserService } from 'src/app/Services/Api/User.service';

@Component({
  selector: 'app-AddIEMUser',
  templateUrl: './AddIEMUser.component.html',
  styleUrls: ['./AddIEMUser.component.scss']
})
export class AddIEMUserComponent extends iUnsubscribeDestroy implements OnInit {

  isActive: boolean = false;

  data: any = {
    name:'',
    email:'',
    surname:'',
    dni:'',
    phonePrefix:null,
    phone:'',
  };

  constructor(public dialogRef: MatDialogRef<AddIEMUserComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,private userSE:UserService) {
    super();
  }

  ngOnInit() {
  }

  /**
   * FUNCTIONALITY
   */

  isCompleted() {
    if(StaticUtilitiesService.isCompleteObject(this.data)){
      this.isActive = true;
    }else {
      this.isActive = false;
    }
  }

  addPhonePrefix(event){
    this.data.phonePrefix = event.code;
    this.isCompleted();
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  finishButton() {
    this.createUserForIEM();
  }

  /**
   * API CALLS
   */

  createUserForIEM() {
    this.userSE.create(this.data);
    this.userSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Usuario creado correctamente');
      this.dialogRef.close(this.data);
      this._unsubInd.next('')
    })
    this.userSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

}
