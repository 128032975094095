import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { slideNavigationTypes } from '@quasar-dynamics/basic-designsystem';
import { StaticUtilitiesService } from '@quasar_dynamics/basic-designsystem';
import { headerData } from 'src/app/Shared/Components/main-table/main-table.component';

@Component({
  selector: 'FormacionesNewVersion-Curso-Table',
  templateUrl: './FormacionesNewVersion-Curso-Table.component.html',
  styleUrls: ['./FormacionesNewVersion-Curso-Table.component.scss'],
})
export class FormacionesNewVersionCursoTableComponent implements OnInit {
  @Input() allCursos: any = [];
  @Input() totalCursos: number = 0;
  @Input() areReadyAllCursos: boolean = false;
  @Input() filterAllCursos: any = null;

  @Output() pageChange: EventEmitter<boolean> = new EventEmitter();

  printableData: any = [];

  tableHead: headerData = [
    {
      variant: 'bold',
      display: 'Nombre',
      key: 'name',
      alignment: 'start',
    },
    {
      variant: 'standard',
      display: 'Horas',
      key: 'duration',
    },
    {
      variant: 'tooltip',
      display: 'Modalidad ',
      key: '',
      itemToDisplay(row) {
        if (row.modalities.length === 0) return '-';
        return row.modalities.length === 1
          ? row.modalities[0].name
          : row.modalities.length + ' modalidades';
      },
      tooltipItems(row) {
        return row.modalities.map((item) => item.name);
      },
      tooltipItemsLength: (row) => row.modalities.length,
      tooltipKeyToDisplay: '',
    },
    {
      variant: 'standard',
      display: 'Precio general ',
      key: 'price',
    },
    {
      variant: 'chip',
      display: 'Estado',
      key: 'activeLabel',

      classNameGenerator: (state: any, index, row) => {
        return state === 'Activo' ? '--greenState' : '--redState';
      },
    },
  ];

  constructor(private staticUtilitiesSE: StaticUtilitiesService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['allCursos'] !== null) {
      if (changes['allCursos']?.currentValue.length !== 0) {
        this.printableData = this.allCursos.map((curso) => {
          return {
            ...curso.formation,
            cursoId: curso.id,
          };
        });
      }
    }
  }

  handleClickRow(row) {
    const { id } = row;
    this.goToDetailView(id);
  }

  goToDetailView(id: number) {
    this.staticUtilitiesSE.goTo(
      'formaciones/formaciones-detalle/',
      slideNavigationTypes.slideToTop,
      { curso: id }
    );
  }

  pageChangeEmmiter(event) {
    this.pageChange.emit(event);
  }
}
