import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralLoaderService } from '@quasar-dynamics/basic-designsystem';
import {
  iDate,
  iOptionsSelector,
  iResultHttp,
  iUnsubscribeDestroy,
  PopupService,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { type } from 'os';
import { takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { SubirArchivoPopupComponent } from 'src/app/Popups/SubirArchivo-Popup/SubirArchivo-Popup.component';
import { CountryService } from 'src/app/Services/Api/Country.service';
import { StudentService } from 'src/app/Services/Api/Student.service';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-GestionUsuarios-Alumnos-Crear',
  templateUrl: './GestionUsuarios-Alumnos-Crear.component.html',
  styleUrls: ['./GestionUsuarios-Alumnos-Crear.component.scss'],
})
export class GestionUsuariosAlumnosCrearComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  optionSelected: number = 0;
  isActive: boolean = false;
  isLoading: boolean = false;
  paises: Array<any> = StaticDataHandlerService.getPaises();
  provincias: Array<any> = StaticDataHandlerService.getProvincias();
  isEdit: boolean = false;
  isDragging: boolean = false;
  isDraggingImage: boolean = false;
  file: any = null;
  image: any = null;
  isWorking: boolean = false;
  isSameAddress: boolean = false;
  sameAddress: boolean = false;
  studentId: number = 0;
  editDocuments: any = [];
  isCompany: boolean = false;
  secondAddress: boolean = false;

  // Tipos
  DNI: any[] = [];
  CV: any[] = [];
  Acuerdo: any[] = [];
  Otros: any[] = [];
  types: any = [
    { value: this.DNI, name: 'DNI' },
    { value: this.CV, name: 'CV' },
    { value: this.Acuerdo, name: 'Acuerdo' },
    { value: this.Otros, name: 'Otros' },
  ];

  opcionesPaises: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Selecciona un país',
    bindLabel: 'nombre',
    bindValue: 'id',
    // append: true
  };
  opcionesPaisesDireccion: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Selecciona un país',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
  };
  opcionesNacionalidades: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Selecciona una nacionalidad',
    append: true,
    bindLabel: 'ethnicity',
    bindValue: 'id',
  };
  opcionesIdiomas: iOptionsSelector = {
    items: StaticDataHandlerService.getLanguages(),
    clearable: true,
    search: true,
    placeholder: 'Selecciona los idiomas',
    append: true,
    multiple: {
      isMultiple: true,
      multipleTextReplacer: `Varios idiomas seleccionados`,
      maxSelectedItems: 3,
    },
  };
  opcionesSexo: iOptionsSelector = {
    items: ['Hombre', 'Mujer', 'Otro', 'Prefiero no decirlo'],
    clearable: true,
    search: true,
    placeholder: 'Selecciona un sexo',
    append: true,
  };
  opcionesSiNo: iOptionsSelector = {
    items: ['Activo', 'Inactivo'],
    clearable: true,
    search: true,
    placeholder: 'Situación laboral actual',
    append: true,
  };
  opcionesProvincias: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Selecciona una provincia',
    append: true,
  };
  opcionesInteresadoPracticas: iOptionsSelector = {
    items: [
      { label: 'Interesado', id: 1 },
      { label: 'No interesado', id: 0 },
    ],
    placeholder: '¿Interesado en prácticas?',
    bindValue: 'id',
  };
  dataToPass: any = {
    id: 0,
    user: {},
  };
  data: any = {
    user: {
      email: '',
      name: '',
      surname: '',
      dni: '',
      biography: '',
      phone: '',
      phoneTwo: '',
      phonePrefix: '+34',
      phoneTwoPrefix: '+34',
      photo: null,
      gender: null,
      active: false,
      nationality: {
        id: null,
        iso_code: null,
        name: null,
        nombre: null,
      },
      dateOfBirth: new iDate(iDate.toPhp(new Date())).toStringDate('EU'),
      languages: [],
      socialMedia: {},
      address: {
        country: {
          id: null,
          iso_code: null,
          name: null,
          nombre: null,
        },
        province: null,
        municipality: '',
        street: '',
        number: '',
        door: '',
        postalCode: '',
      },
      address2: {
        country: {
          id: null,
          iso_code: null,
          name: null,
          nombre: null,
        },
        province: null,
        municipality: '',
        street: '',
        number: '',
        door: '',
        postalCode: '',
      },
      sameAddress: this.sameAddress,
      bankData: {
        bank: '',
        iban: '',
        bic: '',
        owner: '',
        company: '',
        cif: '',
        ownerDni: '',
        fiscalAddress: {
          country: {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          },
          province: null,
          municipality: '',
          street: '',
          number: '',
          door: '',
          postalCode: '',
        },
      },
    },
    incorporationDate: new iDate(iDate.toPhp(new Date())).toStringDate('EU'),
    document: [],
    intershipInterested: null,
  };
  constructor(
    private staticUtilitiesSE: StaticUtilitiesService,
    private router: Router,
    private popupSE: PopupService,
    private teacherSE: TeacherService,
    private studentSE: StudentService,
    private userSE: UserService,
    private countrySE: CountryService,
    private generalLoaderSE: GeneralLoaderService
  ) {
    super();
  }
  ngAfterViewInit(): void {
    (document.querySelector('.form') as HTMLElement).addEventListener(
      'scroll',
      (e) => {
        if (
          (
            document.querySelector('.contactoContainer') as HTMLElement
          ).getBoundingClientRect().top < 0
        ) {
          this.optionSelected = 1;
        }
        if (
          (
            document.querySelector('.contactoContainer') as HTMLElement
          ).getBoundingClientRect().top > 0
        ) {
          this.optionSelected = 0;
        }
        if (
          (
            document.querySelector('.datosFiscales') as HTMLElement
          ).getBoundingClientRect().top < 0
        ) {
          this.optionSelected = 2;
        }
        if (!this.isEdit) {
          if (
            (
              document.querySelector('.documentacion') as HTMLElement
            ).getBoundingClientRect().top < 400
          ) {
            this.optionSelected = 3;
          }
          if (
            (
              document.querySelector('.contactoContainer') as HTMLElement
            ).getBoundingClientRect().top < -2800
          ) {
            this.optionSelected = 4;
          }
        }
      }
    );
  }
  ngOnInit() {
    let params = this.staticUtilitiesSE.getParams();
    if (params.isEdit) {
      this.isEdit = params.isEdit;
      this.studentId = params.idStudent;
      this.dataToPass.id = params.idStudent;
      this.getStudentById(params.idStudent);
    }
    this.getCountriesSelector();
    this.opcionesProvincias.items = [
      ...StaticDataHandlerService.getProvincias(),
    ];
  }

  /**
   * FUNCTIONALITIES
   */
  scroll(type: string) {
    if (!this.isEdit) {
      switch (type) {
        case 'contacto':
          (document.querySelector('.form') as HTMLElement).scroll({
            top: 0,
            behavior: 'smooth',
          });
          break;
        case 'fiscal':
          (document.querySelector('.form') as HTMLElement).scroll({
            top:
              (document.querySelector('.form') as HTMLElement).scrollHeight /
              3.2,
            behavior: 'smooth',
          });
          break;
        case 'academicos':
          (document.querySelector('.form') as HTMLElement).scroll({
            top:
              (document.querySelector('.form') as HTMLElement).scrollHeight /
              1.98,
            behavior: 'smooth',
          });
          break;
        case 'documentacion':
          (document.querySelector('.form') as HTMLElement).scroll({
            top:
              (document.querySelector('.form') as HTMLElement).scrollHeight /
              1.53,
            behavior: 'smooth',
          });
          break;
        case 'imagen':
          (document.querySelector('.form') as HTMLElement).scroll({
            top: (document.querySelector('.form') as HTMLElement).scrollHeight,
            behavior: 'smooth',
          });
          break;
      }
    }
    if (this.isEdit) {
      switch (type) {
        case 'contacto':
          (document.querySelector('.form') as HTMLElement).scroll({
            top: 0,
            behavior: 'smooth',
          });
          break;
        case 'fiscal':
          (document.querySelector('.form') as HTMLElement).scroll({
            top:
              (document.querySelector('.form') as HTMLElement).scrollHeight /
              2.3,
            behavior: 'smooth',
          });
          break;
        case 'academicos':
          (document.querySelector('.form') as HTMLElement).scroll({
            top:
              (document.querySelector('.form') as HTMLElement).scrollHeight /
              1.42,
            behavior: 'smooth',
          });
          break;
      }
    }
  }
  confirmPopup() {
    if (this.router.url.includes('crear-alumno')) {
      this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
        accion: 'Descartar',
        elemento: 'la creación del alumno',
      });
      this.popupSE
        .returnData()
        .pipe(takeUntil(this._unsubInd))
        .subscribe((data) => {
          this.staticUtilitiesSE.goTo(
            '/alumnos/mis-alumnos/listado-alumnos',
            slideNavigationTypes.slideToBottom
          );
          this._unsubInd.next('');
        });
    } else {
      this.staticUtilitiesSE.goTo(
        '/alumnos/mis-alumnos/listado-alumnos',
        slideNavigationTypes.slideToBottom
      );
    }
  }

  backToStudent() {
    let studentId = this.data.id;
    this.staticUtilitiesSE.goTo(
      '/alumnos/mis-alumnos/ficha-alumno',
      slideNavigationTypes.slideToBottom,
      { alumno: studentId }
    );
  }
  crearAlumno() {
    this.isLoading = true;
    if (!this.secondAddress) {
      delete this.data.user.address2;
    }
    let copyOfData = {
      ...this.data,
      document: [...this.DNI, ...this.CV, ...this.Acuerdo, ...this.Otros],
    };
    this.studentSE.create(copyOfData);
    this.studentSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Alumno creado correctamente');
        this.isLoading = false;
        this.staticUtilitiesSE.goTo(
          '/alumnos/mis-alumnos/listado-alumnos',
          slideNavigationTypes.slideToBottom
        );
        this._unsubInd.next('');
      });
    this.teacherSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getPrefix($event, key) {
    if (key == 'telefono') {
      this.data.user.phonePrefix = $event.code;
      if (this.isEdit) {
        this.dataToPass.user.phonePrefix = $event.code;
        this.editStudent(this.dataToPass);
      }
    }
    if (key == 'telefono2') {
      this.data.user.phoneTwoPrefix = $event.code;
      if (this.isEdit) {
        this.dataToPass.user.phoneTwoPrefix = $event.code;
        this.editStudent(this.dataToPass);
      }
    }
  }
  removeDocumento(type, index) {
    this[type].splice(index, 1);
  }

  updateImage(event) {
    let noFormat = this.image.filter(
      (image) =>
        image.file.type !== 'image/jpeg' && image.file.type !== 'image/png'
    );
    let noSize = this.image.filter((image) => image.file.size > 5000000);
    if (noFormat.length > 0) {
      StaticUtilitiesService.showError('Formato de documento no permitido');
      this.image = null;
      return;
    }
    if (noSize.length > 0) {
      StaticUtilitiesService.showError('Archivo demasiado pesado');
      this.image = null;
      return;
    }
    let photo = this.image[0].base64 as string;
    this.data.user.photo = photo;
    this.isDragging = false;
  }
  updateFile(event, typeOf) {
    let noFormat = this.file.filter(
      (file) => file.file.type !== 'application/pdf'
    );
    let noSize = this.file.filter((file) => file.file.size > 5000000);
    if (noFormat.length > 0) {
      StaticUtilitiesService.showError('Formato de documento no permitido');
      this.file = null;
      return;
    }
    if (noSize.length > 0) {
      StaticUtilitiesService.showError('Archivo demasiado pesado');
      this.file = null;
      return;
    }
    this[typeOf].push(
      ...this.file.map((file) => {
        return { name: file.file.name, url: file.base64, typeOf: typeOf };
      })
    );
    this.isDragging = false;
  }

  dragEnter(key) {
    if (key == 'image') this.isDraggingImage = true;
    if (key == 'document') this.isDragging = true;
  }
  dragLeave(key) {
    if (key == 'image') this.isDraggingImage = false;
    if (key == 'document') this.isDragging = false;
  }
  idiomasSelector(event) {
    event.length == 0
      ? (this.data.user.languages = null)
      : (this.data.user.languages = event);
    this.isCompleted();
    if (this.isEdit) {
      this.dataToPass.user = {};
      this.dataToPass.user.languages = this.data.user.languages;
      this.editStudent(this.dataToPass);
      delete this.dataToPass.user;
    }
  }

  employmentStatus(event) {
    if (event == 'Activo') {
      this.isWorking = true;
      if (this.isEdit) {
        this.dataToPass.user = {};
        this.dataToPass.user.employmentStatus = event;
        this.editStudent(this.dataToPass);
        delete this.dataToPass.user;
      }
    } else {
      this.isWorking = false;
      if (this.isEdit) {
        this.dataToPass.user = {};
        this.dataToPass.user.employmentStatus = event;
        this.editStudent(this.dataToPass);
        delete this.dataToPass.user;
      }
    }
  }

  checkBoxSelect(event) {
    this.isSameAddress = event.checked;
    this.sameAddress = event.checked;

    let emptyBankData: any = {
      bank: '',
      iban: '',
      bic: '',
      owner: '',
      cif: '',
      company: '',
      ownerDni: '',
      fiscalAddress: {
        country: {
          id: null,
          iso_code: null,
          name: null,
          nombre: null,
        },
        province: null,
        municipality: '',
        street: '',
        number: '',
        door: '',
        postalCode: '',
      },
    };
    if (event.checked) {
      this.duplicateAddress();
    } else {
      this.data.user.bankData = { ...emptyBankData };
      if (this.isEdit) {
        this.dataToPass.user.bankData = emptyBankData;
        this.editStudent(this.dataToPass);
      }
    }
  }

  duplicateAddress() {
    for (const key in this.data.user.bankData.fiscalAddress) {
      this.data.user.bankData.fiscalAddress[key] = this.data.user.address[key];
    }
    // Si tenemos el id del país, buscamos en el selector de paises y nos traemos el objeto completo para que se muestre en el readonly
    if (this.data.user.bankData.fiscalAddress.country.id) {
      this.data.user.bankData.fiscalAddress.country =
        this.opcionesPaisesDireccion.items.find(
          (pais) => pais.id == this.data.user.bankData.fiscalAddress.country.id
        );
    }

    this.data.user.bankData.fiscalAddress.email = this.data.user.email;
    this.data.user.bankData.fiscalAddress.phone = this.data.user.phone;

    if (this.isEdit) {
      this.dataToPass.user = {};
      this.dataToPass.user.bankData = {
        bank: '',
        iban: '',
        bic: '',
        owner: '',
        fiscalAddress: {
          country: {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          },
          province: null,
          municipality: '',
          street: '',
          number: '',
          door: '',
          postalCode: '',
        },
      };
      for (const key in this.data.user.bankData.fiscalAddress) {
        this.dataToPass.user.bankData.fiscalAddress[key] =
          this.data.user.address[key];
      }
      this.dataToPass.user.bankData.fiscalAddress.email = this.data.user.email;
      this.dataToPass.user.bankData.fiscalAddress.phone = this.data.user.phone;
      this.editStudent(this.dataToPass);
    }
  }

  isCompanySelect(event) {
    this.isCompany = event.checked;
    this.sameAddress = false;

    let emptyBankData: any = {
      bank: '',
      iban: '',
      bic: '',
      owner: '',
      cif: '',
      company: '',
      ownerDni: '',
      fiscalAddress: {
        country: {
          id: null,
          iso_code: null,
          name: null,
          nombre: null,
        },
        province: null,
        municipality: '',
        street: '',
        number: '',
        door: '',
        postalCode: '',
      },
    };

    // Limpio datos de dirección que hubiera metido con "marcar como misma dirección", en el usuario y en el objeto a enviar en edición
    this.data.user.bankData = { ...emptyBankData };
    if (this.isEdit) {
      this.dataToPass.user.bankData = emptyBankData;
      this.editStudent(this.dataToPass);
    }
  }

  hasSecondAddress(event) {
    this.secondAddress = event.checked;
    if (!this.data.user.address2) {
      this.data.user.address2 = {
        country: {
          id: null,
          iso_code: null,
          name: null,
          nombre: null,
        },
        province: null,
        municipality: '',
        street: '',
        number: '',
        door: '',
        postalCode: '',
      };
    }
  }

  removeImage() {
    this.data.user.photo = null;
  }
  updateLine(event, key, type) {
    switch (type) {
      case 'none':
        this.isCompleted();
        this.dataToPass[key] = event;
        this.editStudent(this.dataToPass);
        delete this.dataToPass[key];
        break;

      case 'user':
        this.dataToPass.user = {};
        this.isCompleted();
        this.dataToPass['user'][key] = event;
        this.editStudent(this.dataToPass);
        delete this.dataToPass.user;
        break;

      case 'company':
        this.dataToPass.company = {};
        this.isCompleted();
        this.dataToPass['company'][key] = event;
        this.editStudent(this.dataToPass);
        delete this.dataToPass.company;
        break;

      case 'address':
        this.dataToPass.user = {};
        this.dataToPass.user.address = {};
        this.isCompleted();
        if (key == 'country') {
          this.dataToPass.user.address.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
          this.dataToPass.user.address[key].id = event;
        } else {
          this.dataToPass.user.address[key] = event;
        }
        this.editStudent(this.dataToPass);
        delete this.dataToPass.user;
        break;

      case 'address2':
        this.dataToPass.user = {};
        this.dataToPass.user.address2 = {};
        this.isCompleted();
        if (key == 'country') {
          this.dataToPass.user.address2.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
          this.dataToPass.user.address2[key].id = event;
        } else {
          this.dataToPass.user.address2[key] = event;
        }
        this.editStudent(this.dataToPass);
        delete this.dataToPass.user;
        break;

      case 'bankData':
        this.dataToPass.user = {};
        this.dataToPass.user.bankData = {};
        this.isCompleted();
        this.dataToPass.user.bankData[key] = event;
        this.editStudent(this.dataToPass);
        delete this.dataToPass.user;
        break;

      case 'fiscalAddress':
        this.dataToPass.user = {};
        this.dataToPass.user.bankData = {};
        this.dataToPass.user.bankData.fiscalAddress = {};
        this.isCompleted();
        if (key == 'country') {
          this.dataToPass.user.bankData.fiscalAddress.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
          this.dataToPass.user.bankData.fiscalAddress[key].id = event;
        } else {
          this.dataToPass.user.bankData.fiscalAddress[key] = event;
        }
        this.editStudent(this.dataToPass);
        delete this.dataToPass.user;
        break;

      case 'fee':
        this.dataToPass.fee = {};
        this.isCompleted();
        this.dataToPass.fee[key] = event;
        this.editStudent(this.dataToPass);
        delete this.dataToPass.fee;
        break;

      case 'socialMedia':
        this.dataToPass.user = {};
        this.dataToPass.user.socialMedia = {};
        this.isCompleted();
        this.dataToPass.user.socialMedia = { ...this.data.user.socialMedia };

        this.editStudent(this.dataToPass);
        delete this.dataToPass.user;
        break;

      default:
        break;
    }
  }

  /**
   * GETTERS & SETTERS
   */

  getDate(event) {
    this.data.user.dateOfBirth = iDate
      .javascriptConvert(new Date(event))
      .toStringDate('JAP');

    this.isCompleted();
    if (this.isEdit) {
      this.dataToPass.user = {};
      this.dataToPass.user.dateOfBirth = this.data.user.dateOfBirth;
      this.editStudent(this.dataToPass);
      delete this.dataToPass.user;
    }
  }
  getDateIncorporacion(event) {
    this.data.incorporationDate = new iDate(iDate.toPhp(event)).toStringDate(
      'EU'
    );
    this.isCompleted();
    if (this.isEdit) {
      this.dataToPass.incorporationDate = this.data.incorporationDate;
      this.editStudent(this.dataToPass);
      delete this.dataToPass.incorporationDate;
    }
  }
  setter(data) {
    if (data.intershipInterested == true) {
      data.intershipInterested = 1;
    }

    if (data.intershipInterested == false) {
      data.intershipInterested = 0;
    }
    this.data = data;

    if (!data.user.bankData) {
      this.data.user.bankData = {
        bank: '',
        iban: '',
        bic: '',
        owner: '',
        cif: '',
        company: '',
        ownerDni: '',
        fiscalAddress: {
          country: {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          },
          province: null,
          municipality: '',
          street: '',
          number: '',
          door: '',
          postalCode: '',
        },
      };
    }
  }

  setAddresses(data) {
    this.data.user.address = {
      country: {
        id: null,
        iso_code: null,
        name: null,
        nombre: null,
      },
      province: null,
      municipality: '',
      street: '',
      number: '',
      door: '',
      postalCode: '',
    };
    data.user.addresses.forEach((address) => {
      if (address.name == 'Dirección personal') {
        data.user.address = address;
        if (!address.country) {
          address.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
        }
      }
      if (address.name == 'Dirección personal 02') {
        data.user.address2 = address;
        this.secondAddress = true;
        if (!address.country) {
          address.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
        }
      }
      if (address.name == 'Dirección fiscal') {
        data.user.bankData.fiscalAddress = address;
        if (!address.country) {
          address.country = {
            id: null,
            iso_code: null,
            name: null,
            nombre: null,
          };
        }
      }
    });
  }

  returnCheckObject() {
    return {
      email: this.data.user.email,
      nombre: this.data.user.name,
      surname: this.data.user.surname,
      telefono: this.data.user.phone,
      telefonoPrefijo: this.data.user.phonePrefix,
    };
  }

  /**
   * VALIDATIONS
   */
  isCompleted() {
    let checkObject = this.returnCheckObject();
    if (
      (!this.data.user.email ||
        StaticUtilitiesService.isValidFormat(this.data.user.email)) &&
      this.data.user.nationality.id === null
    ) {
      this.isActive = false;
      return;
    }

    if (StaticUtilitiesService.isValidFormat(this.data.user.email)) {
      let clearedData: any = { ...this.data };
      delete clearedData.intershipInterested;
      this.isActive = StaticUtilitiesService.isCompleteObject(clearedData);
    } else {
      StaticUtilitiesService.showError('Formato de email incorrecto');
      this.isActive = false;
    }
  }

  /**
   * API CALLS
   */

  getCountriesSelector() {
    this.countrySE.getCountries();
    this.countrySE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.opcionesPaises.items = [...data];
        this.opcionesPaisesDireccion.items = [...data];
        this.opcionesNacionalidades.items = [...data];
        this._unsubInd.next('');
      });
  }

  getStudentById(id) {
    this.studentSE.getSingle(id);
    this.studentSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.setter(data);
        this.setAddresses(data);
        this._unsubInd2.next('');
      });
    this.studentSE
      .getResultIndividualError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.staticUtilitiesSE.goTo('', slideNavigationTypes.slideToRight);
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  editStudent(data) {
    this.studentSE.update(data);
    this.studentSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Alumno actualizado correctamente');
        this._unsubInd3.next('');
      });
    this.studentSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
