<div 
  cdkDropList  
  class="sessions__container"
  [cdkDropListDisabled]="!isEditing"
  (cdkDropListDropped)="handleDrop($event)" 
>
  <ng-container *ngIf="sessions.length > 0;else emptyModule">
    <ng-container *ngFor="let session of sessions; let sessionIndex = index; trackBy:sessionsTracking">
      <div 
        cdkDrag
        cdkDragLockAxis="y"
        class="detailViewContent__session" 
        [class.detailViewContent__session--editing]="isEditing"
      >
        <mat-icon 
          cdkDragHandle 
          *ngIf="isEditing" 
          class="detailViewContent__sessionDrag"
        >drag_indicator</mat-icon>
        <p
          text-editable
          [canEdit]="isEditing"
          placeholder="Nombre de la sesión"
          [ngModel]="session.sessionTitle"
          (handlerChange)="handleSessionNameChange($event, session)"
        >{{session.name}}</p>
        <button 
          *ngIf="isEditing" 
          (click)="handleClickDeleteSession(session)" 
          class="detailViewContent__sessionDelete"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </ng-container>
    <button 
      class="detailViewContent__addSessions"
      *ngIf="isEditing"
      (click)="addSession()"
    ><mat-icon>add_circle_outline</mat-icon></button>
  </ng-container>
  <button 
      class="detailViewContent__addSessions"
      *ngIf="isEditing && sessions.length === 0"
      (click)="addSession()"
    ><mat-icon>add_circle_outline</mat-icon></button>
</div>

<ng-template #emptyModule >
  <ng-container *ngIf="!isEditing">
    <p>Modulo vacío</p>
  </ng-container>
</ng-template>