import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ShowConflicts-Popup',
  templateUrl: './ShowConflicts-Popup.component.html',
  styleUrls: ['./ShowConflicts-Popup.component.scss']
})
export class ShowConflictsPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ShowConflictsPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) { }

  ngOnInit() {
  }

    /**
  * FUNCTIONALITY
  */
    closePopup(returnValue?: any) {
      let p = { returnValue: returnValue };
      this.dialogRef.close(p);
    }
    forceClosePopup() {
      this.dialogRef.close();
    }

}
