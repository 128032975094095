<fullContainer>
  <header></header>
  <div class="subHeader">
    <div class="buttonContainer">
      <btn classList="principalButton" (click)="downloadExcel()">
        <ng-container> Descargar listado en .XLS </ng-container>
      </btn>
      <btn
        classList="principalButton"
        (click)="goTo()"
        *ngIf="checkRolesAndPermissions()"
      >
        <ng-container>
          <mat-icon class="material-icons-outlined"
            >add_circle_outline</mat-icon
          >
          Añadir profesor
        </ng-container>
      </btn>
      <btn
        classList="onlyIcon gearButton"
        class="settings"
        (click)="openPreferences()"
      >
        <ng-container>
          <mat-icon>settings</mat-icon>
        </ng-container>
      </btn>
    </div>
  </div>
  <div class="filters">
    <customSearcher
      ngDefaultControl
      placeHolder="Busca aquí por nombre y apellidos, email, nombre de la formación, DNI, teléfono, tipo de profesor, cargo, situación laboral o empresa"
      [(ngModel)]="filter"
      (change)="getFilter(filter, 'genericFilter')"
    ></customSearcher>
    <div
      class="filterButton"
      *ngIf="!isFilterDisplayed"
      (click)="displayFilters()"
    >
      <span>Filtrar profesorado</span>
      <mat-icon>filter_list</mat-icon>
    </div>
    <div
      class="filterButton active"
      *ngIf="isFilterDisplayed"
      (click)="displayFilters()"
    >
      <span>Filtrar profesorado</span>
      <mat-icon>clear</mat-icon>
    </div>
  </div>
  <div class="filterContent" *ngIf="isFilterDisplayed">
    <div class="topBar">
      <div class="addProducts">
        <p class="fwBold">Añade productos al filtrado de la lista</p>
        <div class="lineaDeFiltros">
          <selector
            class="roundedSelector"
            [options]="cursosSelectorOptions"
            [(ngModel)]="formacionSelector"
            (change)="callCourseType(formacionSelector)"
          ></selector>
          <selector
            class="roundedSelector"
            [options]="optionsSecondSelector"
            [(ngModel)]="formacionSelectorDos"
            (change)="callPromotion(formacionSelectorDos)"
            *ngIf="formacionSelector"
          ></selector>
          <selector
            class="roundedSelector"
            [options]="optionsThirdSelector"
            [(ngModel)]="formacionSelectorTres"
            *ngIf="formacionSelector && formacionSelectorDos"
          ></selector>
          <btn
            classList="generalbutton filterButton"
            (click)="addNewFilterLine()"
            *ngIf="
              formacionSelector && formacionSelectorDos && formacionSelectorTres
            "
            ><mat-icon>add</mat-icon></btn
          >
        </div>
      </div>
      <div class="tipoandyear">
        <div class="year">
          <p class="fwBold">Año académico</p>
          <selector
            [options]="yearSelectorOptions"
            (change)="getFilter($event, 'year')"
          ></selector>
        </div>
      </div>
    </div>
    <div class="bottomBar">
      <div
        class="filterLine"
        *ngFor="let line of filterLineArray; let indexLine = index"
      >
        <btn
          classList="genericButton verySmall trashLineFilterButton"
          (click)="deleteLineFilter(line.id)"
          ><mat-icon class="deleteButtonFilterLine">delete</mat-icon></btn
        >
        <div class="selectoresFilterLine">
          <!-- <selector [options]="cursosSelectorOptions" [(ngModel)]="line.type"></selector>
        <selector [options]="optionsSecondSelector" [(ngModel)]="line.formation"></selector> -->
          <p class="blockLineFilter">
            {{ line.type }}
          </p>
          <p class="blockLineFilter">
            {{ line.formation }}
          </p>
          <p class="fwBold">
            {{ line.promotions.length == 1 ? "Edición: " : "Ediciones: " }}
          </p>
          <span
            class="seleccionesPromociones"
            *ngFor="let promotion of line.promotions"
          >
            {{ promotion.edition }}
            <!-- <mat-icon (click)="deletePromotionFilter(promotion.id, indexLine)">close</mat-icon> -->
          </span>
        </div>
      </div>
    </div>
  </div>
  <gestionUsuariosProfesoresTabla
    [isDataLoaded]="isDataLoaded"
    [downloadExcelPopup]="downloadExcelPopup"
    [openPrefernecesPopup]="openPrefernecesPopup"
    [total]="total"
    [filters]="filters"
    [profesores]="profesores"
  >
  </gestionUsuariosProfesoresTabla>
  <pagination-controls
    [autoHide]="true"
    previousLabel="Anterior"
    nextLabel="Siguiente"
    (pageChange)="pageChange($event)"
  ></pagination-controls>
</fullContainer>
