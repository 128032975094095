<div class="fullView">
  <p class="fwBold">Valida tu información personal y tus datos personales</p>
  
  <div class="loaderdiv" *ngIf="!studentDataStepTwo">
    <loaderIcon ></loaderIcon>
  </div>
  <div class="inputs" *ngIf="studentDataStepTwo">
    <div class="quadraColumn">
      <CustomInput [readOnly]="true" label="Nombre legal (*)" [placeHolder]="'Nombre legal'" [(ngModel)]="studentDataStepTwo.user.name" (change)="sendToUpdate(studentDataStepTwo.user.name,'name','user')"></CustomInput>
      <CustomInput [readOnly]="true" class="calleAvenida" label="Apellidos (*)" [placeHolder]="'Apellidos'" [(ngModel)]="studentDataStepTwo.user.surname"(change)="sendToUpdate(studentDataStepTwo.user.surname,'surname','user')"></CustomInput>
      <CustomInput label="Alias (*)" class="alias" [placeHolder]="'Alias'" [(ngModel)]="studentDataStepTwo.user.alias"(change)="sendToUpdate(studentDataStepTwo.user.alias,'alias','user')"></CustomInput>
    </div>
    <div class="quadraColumn">
      <div class="subColumn especial">
        <label>Sexo (*)</label>
        <selector [options]="opcionesSexo" [(ngModel)]="studentDataStepTwo.user.gender"(change)="sendToUpdate(studentDataStepTwo.user.gender,'gender','user')"></selector>
      </div>
      <customInput [options]="optionsInputDateOfBirth" [selectedDate]="studentDataStepTwo.user.dateOfBirth" (change)="fechaDeNacimiento($event)"></customInput>
      <CustomInput label="DNI / NIE / Pasaporte (*)" [placeHolder]="'DNI / NIE / Pasaporte'" [(ngModel)]="studentDataStepTwo.user.dni"(change)="sendToUpdate(studentDataStepTwo.user.dni,'dni','user')"></CustomInput>
      <div class="subColumnIdiomas">
        <label for="">Idiomas</label>
        <selector [options]="opcionesIdiomas" [(ngModel)]="studentDataStepTwo.user.languages" (change)="sendToUpdate(studentDataStepTwo.user.languages,'languages','user')"></selector>
      </div>
    </div>
    <div class="quadraColumn">
      <div class="subColumnIdiomas" >
        <label for="">Nacionalidad (*)</label>
        <selector [options]="opcionesNacionalidad" [(ngModel)]="studentDataStepTwo.user.nationality.id" (change)="sendToUpdate(studentDataStepTwo.user.nationality.id,'nationality','user')" ></selector>
      </div>
      <div class="telefono">
        <div class="subColumn">
          <label for="selector">Teléfono 1 (*)</label>
          <CountrySelector [country]="studentDataStepTwo.user.phonePrefix"  ngDefaultControl  (emitCountry)="getCountrySelector($event,'one')"  ></CountrySelector>        </div>
        <CustomInput [type]="'number'" [placeHolder]="'Teléfono 1'" class="width100" ngDefaultControl [(ngModel)]="studentDataStepTwo.user.phone" (change)="sendToUpdate(studentDataStepTwo.user.phone,'phone','user')"></CustomInput>
      </div>
      <div class="telefono">
        <div class="subColumn">
          <label for="selector">Teléfono 2</label>
          <CountrySelector [country]="studentDataStepTwo.user.phoneTwoPrefix"  ngDefaultControl  (emitCountry)="getCountrySelector($event,'two')"  ></CountrySelector>
        </div>
        <CustomInput [type]="'number'" [placeHolder]="'Teléfono 2'" class="width100" ngDefaultControl [(ngModel)]="studentDataStepTwo.user.phoneTwo" (change)="sendToUpdate(studentDataStepTwo.user.phoneTwo,'phoneTwo','user')"></CustomInput>
      </div>
      <CustomInput class="email" [type]="'email'" [readOnly]="true" label="Email (*)" [placeHolder]="'Email'" [(ngModel)]="studentDataStepTwo.user.email" (change)="sendToUpdate(studentDataStepTwo.user.email,'email','user')"></CustomInput>
    </div>
    <div class="dobleColumn">
      <CustomInput label="Instagram" [placeHolder]="'Instagram'" [(ngModel)]="studentDataStepTwo.user.socialMedia.instagram" (change)="sendToUpdate(studentDataStepTwo.user.socialMedia.instagram,'instagram','socialMedia')" ></CustomInput>
      <CustomInput label="LinkedIn" [placeHolder]="'LinkedIn'" [(ngModel)]="studentDataStepTwo.user.socialMedia.linkedin" (change)="sendToUpdate(studentDataStepTwo.user.socialMedia.linkedin,'linkedin','socialMedia')"></CustomInput>
    </div>
    <div class="dobleColumn">
      <CustomInput [type]="'text'" [label]="'Facebook'" [placeHolder]="'Facebook'" [(ngModel)]="studentDataStepTwo.user.socialMedia.facebook" name="facebook" (change)="sendToUpdate(studentDataStepTwo.user.socialMedia.facebook, 'facebook', 'socialMedia')">
      </CustomInput>
      <CustomInput [type]="'text'" [label]="'TikTok'" [placeHolder]="'TikTok'" [(ngModel)]="studentDataStepTwo.user.socialMedia.tiktok" name="tiktok" (change)="sendToUpdate(studentDataStepTwo.user.socialMedia.tiktok, 'tiktok', 'socialMedia')">
      </CustomInput>
    </div>
    <div class="dobleColumn">
      <CustomInput [type]="'text'" [label]="'X'" [placeHolder]="'X'" [(ngModel)]="studentDataStepTwo.user.socialMedia.x" name="x" (change)="sendToUpdate(studentDataStepTwo.user.socialMedia.x, 'x', 'socialMedia')">
      </CustomInput>
      <CustomInput [type]="'text'" [label]="'Otras'" [placeHolder]="'Otras'" [(ngModel)]="studentDataStepTwo.user.socialMedia.otras" name="otras" (change)="sendToUpdate(studentDataStepTwo.user.socialMedia.otras, 'otras', 'socialMedia')">
      </CustomInput>
    </div>
    <div class="dobleColumn">
      <div class="subColumnIdiomas">
        <CustomInput class="calleAvenida" label="Calle / Avenida" [placeHolder]="'Calle / Avenida'" [(ngModel)]="studentDataStepTwo.user.address.street" (change)="sendToUpdate(studentDataStepTwo.user.address.street,'street','address')"></CustomInput>
      </div>
      <div class="tricolumn">
        <CustomInput label="Número" [placeHolder]="'Número'" [(ngModel)]="studentDataStepTwo.user.address.number" (change)="sendToUpdate(studentDataStepTwo.user.address.number,'number','address')"></CustomInput>
        <CustomInput label="Puerta" [placeHolder]="'Puerta'" [(ngModel)]="studentDataStepTwo.user.address.door" (change)="sendToUpdate(studentDataStepTwo.user.address.door,'door','address')"></CustomInput>
        <CustomInput label="Código Postal" [placeHolder]="'Código Postal'" [(ngModel)]="studentDataStepTwo.user.address.postalCode" (change)="sendToUpdate(studentDataStepTwo.user.address.postalCode,'postalCode','address')"></CustomInput>
      </div>
    </div>
    <div class="tricolumn">
      <CustomInput label="Localidad" [placeHolder]="'Localidad'" [(ngModel)]="studentDataStepTwo.user.address.municipality" (change)="sendToUpdate(studentDataStepTwo.user.address.municipality,'municipality','address')"></CustomInput>
      <CustomInput label="Provincia" [placeHolder]="'Provincia'" [(ngModel)]="studentDataStepTwo.user.address.province" (change)="sendToUpdate(studentDataStepTwo.user.address.province,'province','address')"></CustomInput>
      <div class="subColumnIdiomas">
        <label for="">País</label>
        <selector [options]="opcionesPais" [(ngModel)]="studentDataStepTwo.user.address.country.id" (change)="sendToUpdate(studentDataStepTwo.user.address.country.id,'country','address')" ></selector>
      </div>
    </div>
    
    <div class="checkBoxLine" >
      <mat-checkbox (change)="hasSecondAddress($event)" [checked]="secondAddress"></mat-checkbox>
      <label for="">Segunda dirección</label>
    </div>
    <div class="dobleColumn" *ngIf="secondAddress">
      <div class="subColumnIdiomas">
        <CustomInput class="calleAvenida" label="Calle / Avenida" [placeHolder]="'Calle / Avenida'" [(ngModel)]="studentDataStepTwo.user.address2.street" (change)="sendToUpdate(studentDataStepTwo.user.address2.street,'street','address2')"></CustomInput>
        </div>
      <div class="tricolumn" *ngIf="secondAddress">
        <CustomInput label="Número" [placeHolder]="'Número'" [(ngModel)]="studentDataStepTwo.user.address2.number" (change)="sendToUpdate(studentDataStepTwo.user.address2.number,'number','address2')"></CustomInput>
        <CustomInput label="Puerta" [placeHolder]="'Puerta'" [(ngModel)]="studentDataStepTwo.user.address2.door" (change)="sendToUpdate(studentDataStepTwo.user.address2.door,'door','address2')"></CustomInput>
        <CustomInput label="Código Postal" [placeHolder]="'Código Postal'" [(ngModel)]="studentDataStepTwo.user.address2.postalCode" (change)="sendToUpdate(studentDataStepTwo.user.address2.postalCode,'postalCode','address2')"></CustomInput>
      </div>
    </div>
    <div class="tricolumn" *ngIf="secondAddress">
      <CustomInput label="Localidad" [placeHolder]="'Localidad'" [(ngModel)]="studentDataStepTwo.user.address2.municipality" (change)="sendToUpdate(studentDataStepTwo.user.address2.municipality,'municipality','address2')"></CustomInput>
      <CustomInput label="Provincia" [placeHolder]="'Provincia'" [(ngModel)]="studentDataStepTwo.user.address2.province" (change)="sendToUpdate(studentDataStepTwo.user.address2.province,'province','address2')"></CustomInput>
      <div class="subColumnIdiomas">
        <label for="">País</label>
        <selector [options]="opcionesPais" [(ngModel)]="studentDataStepTwo.user.address2.country.id" (change)="sendToUpdate(studentDataStepTwo.user.address2.country.id,'country','address2')" ></selector>
      </div>
    </div>
    <div class="quadraColumn">
      <div class="subColumnIdiomas">
        <label for="">Situación laboral (*)</label>
        <selector [options]="opcionesLaboral" [(ngModel)]="studentDataStepTwo.user.employmentStatus" (change)="sendToUpdate(studentDataStepTwo.user.employmentStatus,'employmentStatus','user')"></selector>
      </div>
      <CustomInput [(ngModel)]="studentDataStepTwo.user.company" label="Empresa (*)" [placeHolder]="'Empresa'" *ngIf="studentDataStepTwo.user.employmentStatus === 'Activo'" (change)="sendToUpdate(studentDataStepTwo.user.company,'company','user')"></CustomInput>
      <CustomInput [(ngModel)]="studentDataStepTwo.user.jobPosition" label="Cargo (*)" [placeHolder]="'Cargo'" *ngIf="studentDataStepTwo.user.employmentStatus === 'Activo'" (change)="sendToUpdate(studentDataStepTwo.user.jobPosition,'jobPosition','user')"></CustomInput>
    </div>
  </div>

</div>

