<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>Editar estado de la matriculación</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="formLine">
      <selector
        style="width: 100%"
        [options]="enrollmentStateSelectorOptions"
        [(ngModel)]="ngModels.enrollmentState"
      ></selector>
      <selector
        style="width: 100%"
        [options]="projectStateSelectorOptions"
        [(ngModel)]="ngModels.projectState"
      ></selector>
    </div>
    <div class="formLine">
      <selector
        style="width: 100%"
        [options]="editionSelectorOptions"
        [(ngModel)]="ngModels.promotion"
      ></selector>
    </div>
  </div>

  <div class="footer">
    <btn classList="principalButton" (click)="closePopup(false)">
      <ng-container> Cancelar </ng-container>
    </btn>
    <btn classList="specialButton" (click)="closePopup(ngModels)">
      <ng-container> Editar </ng-container>
    </btn>
  </div>
</div>
