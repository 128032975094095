export interface iPromotionTribunal {
  masterDeliveryDates: DeliveryDate[];
  teachers: Teachers[];
  tribunals: TribunalInterface[];
  projectId: number;
}
interface DeliveryDate {
  customDeliveryDateId: number;
  title: string;
  editable: boolean;
  startDate: null | Date;
}
interface Teachers {
  id: number;
  teacherId: number;
  teacherName: string;
  stateId: number;
  conflict: boolean;
}

interface TribunalInterface {
  tribunalId: number;
  tribunalName: string;
  landmarkId: number;
  startDate: string;
  slotMinutes: number;
  classroomId: number;
  classroomName: string;
  hours: string;
  students: StudentInterface[];
}

interface StudentInterface {
  tribunalStudentId: number;
  landmarkChildId: number;
  tribunalStudentStateId: number;
  tribunalStudentStateName: string;
  studentId: number | null;
  studentName: string | null;
  hours: string;
}

export class IPromotionTribunal {
  static getEmptyObject(): iPromotionTribunal {
    return {
      masterDeliveryDates: [
        {
          customDeliveryDateId: 0,
          title: '',
          editable: false,
          startDate: null,
        },
      ],
      teachers: [
        {
          id: 0,
          teacherId: 0,
          teacherName: '',
          stateId: 0,
          conflict: false,
        },
      ],
      tribunals: [
        {
          classroomId: 0,
          classroomName: '',
          hours: '',
          landmarkId: 0,
          slotMinutes: 0,
          tribunalId: 0,
          tribunalName: '',
          startDate: '',
          students: [
            {
              landmarkChildId: 0,
              studentId: 0,
              studentName: '',
              tribunalStudentId: 0,
              tribunalStudentStateId: 0,
              tribunalStudentStateName: '',
              hours: '',
            },
          ],
        }
      ],
      projectId: 0,
    };
  }
}
