<fullContainer>
  <header></header>

  <div class="fullView">
    <div class="topPart">

      <!-- Descripción -->
      <Editablebox title="Descripción" bgColor="white"  (sendIsEditing)="handelChangeEdit($event)">
        <div class="descriptionPart">

          <p class="dobleTitulacionTitle" text-editable [canEdit]="extraClass.editableController['Descripción']" [(ngModel)]="extraClass.description.name" (input)="formDebouncer(extraClass.description.name,'name')">
            {{extraClass.description.name}}
          </p>
          <status-pill class="formationDetail__descriptionChip" [active]="extraClass.description.activeLabel === 'Activa' " (click)="toggleActive()"></status-pill>

        </div>
      </Editablebox>

      <!-- Másteres incluidos. Sólamente se pueden editar si el length de student es 0 -->
      <Editablebox title="Másteres incluidos" bgColor="white"
        (sendIsEditing)="handelChangeEdit($event)" [canEdit]="extraClass.students.length == 0">

        <div class="masteresIncluidosPart">

          <selector [(ngModel)]="extraClass.includedMasters.masterOneId" [options]="extraClass.masterSelectorOptions" (change)="prepareToUpateMasters($event, 'masterOneId')" ></selector>
          <selector [(ngModel)]="extraClass.includedMasters.masterTwoId" [options]="extraClass.masterSelectorOptions" (change)="prepareToUpateMasters($event, 'masterTwoId')" ></selector>

        </div>
      </Editablebox>

      <!-- Características generales -->
      <Editablebox title="Características generales" bgColor="gray"
        (sendIsEditing)="handelChangeEdit($event)">
        <div class="caracteristicasGeneralesPart">

          <div class="whiteContainer">
            <ng-container *ngFor="let modality of extraClass.prices; first as first; index as index">
              <div class="spaceBetweenLine">
                <p class="formationDetail__general__prices__type">{{modality.modality}}</p>
                <div class="priceWithEuro">
                  <p class="formationDetail__general__prices__price" text-editable
                    [canEdit]="extraClass.editableController['Características generales']"
                    (input)="checkIfNumberAndEdit($event, 'price',index )">{{modality?.price }}</p>
                  <p class="bold">€</p>
                </div>
              </div>
            </ng-container>


          </div>

          <div class="whiteContainer">
            <div class="spaceBetweenLine">
              <p>Horas totales</p>
              <p>{{extraClass.hours}} horas</p>
            </div>
          </div>

        </div>


      </Editablebox>

    </div>

    <div class="bottomPart">
      <main-table [dataSource]="extraClass.students" [headerData]="extraClass.studentsHeaderData" ></main-table>
    </div>

  </div>

</fullContainer>