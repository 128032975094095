import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iTeacher } from "./iTeacher";
import { iBill } from "./iBill";
import { iStudent } from "./iStudent";

export interface iDocument {
    id: number,
    name?: string,
    url?: string,
    teacher?: iTeacher,
    bill?: iBill,
    student?: iStudent,
}

export class iDocument extends iBaseObject {
    static convertFromCollection(ob: any): Array<iDocument> {
        let iDocumentCollection: Array<iDocument> = [];
        ob.forEach((element: any) => {
            iDocumentCollection.push(this.convertFromObject(element));
        });
        return iDocumentCollection;
    }

    static convertFromObject(ob: any): iDocument {
        let iDocumentObj = new iDocument();
        iDocumentObj.id = ob.id;
        iDocumentObj.name = ob.name;
        iDocumentObj.url = ob.url;
        iDocumentObj.teacher = ob.teacher ? iTeacher.convertFromObject(ob.teacher) : iTeacher.createEmptyObject();
        iDocumentObj.bill = ob.bill ? iBill.convertFromObject(ob.bill) : iBill.createEmptyObject();
        iDocumentObj.student = ob.student ? iStudent.convertFromObject(ob.student) : iStudent.createEmptyObject();
        return iDocumentObj;
    }

    static createEmptyObject(): iDocument {
        let iDocumentObj = new iDocument();
        iDocumentObj.name = '';
        iDocumentObj.url = '';
        iDocumentObj.teacher = iTeacher.createEmptyObject();
        iDocumentObj.bill = iBill.createEmptyObject();
        iDocumentObj.student = iStudent.createEmptyObject();
        return iDocumentObj;
    }
}

export interface iDocumentFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    name?: string,
    url?: string,
    teacher?: number,
    bill?: number,
    student?: number,
}