<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="closePopup(null)">close</mat-icon>
    <p >Editar certificado {{modalData.name}}</p>
    <mat-icon (click)="closePopup(null)">close</mat-icon>
  </div>

  <div class="body" >
    <div class="formLine">
      <btn classList="generalbutton principalButton" (click)="downloadCurrentCertificate(modalData.url)">Descargar certificado actual</btn>
    </div>

    <add-file style="width:100%" [acceptedFileTypesArray]="acceptedFileTypesArray" [filesArray]="filesArray" [maxFileSizeMB]="maxFileSizeMb" [multiple]="false" (emitFiles)="onAddedFile($event)" ></add-file>
  </div>

  <div class="footer">
    <btn classList="principalButton" (click)="closePopup(null)">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn classList="specialButton" [class.disabled]="!isActive" (click)="closePopup(filesArray)">
      <ng-container>
        Finalizar
      </ng-container>
    </btn>
  </div>
</div>
