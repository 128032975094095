import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'small-input',
  templateUrl: './small-input.component.html',
  styleUrls: ['./small-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SmallInputComponent), // replace name as appropriate
      multi: true,
    },
  ]
})
export class SmallInputComponent implements OnInit {
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() optionsInput!: iOptionsCustomInput
  @Input() highlighted: boolean = false;

  private _onChange = (_: any) => {};
  private _onTouched = () => {};
  private _oldData;

  _disabled: boolean = false;
  model;

  writeValue(obj: any): void {
    if (obj !== this.model) {
      this.model = obj;
      this._onChange(obj);
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
    this._onChange(this.model);
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onNgModelChange(event) {
    this._oldData = this.model;
    this.model = event.target.value;
  }

  onModelChange($event) {
    this.model = $event;
    this._onChange(this.model);
  }

  name: string = new Date().valueOf().toString();
  customInputValue: any = '';

  constructor() { }

  ngOnInit() {
  }

  getClassname() {
    const baseClassName = 'smallInput';
    const classList:string[] = [baseClassName];
    if (this.highlighted) {
      classList.push('smallInput--highlighted');
    }
    if(this.optionsInput.readOnly) {
      classList.push('smallInput--readonly');
    }
    return classList.join(' ');
  }

  sendNgModel(event) {
    this.change.emit(event.target.value);
  }

}
