<fullContainer>
  <ng-container>
    <header></header>
    <div class="userInfoContainer" skeleton [isDataLoaded]="isDataLoaded">
      <div class="imagenDePerfil">
        <image
          class="profileImage circleImage"
          [image]="dataTeacher?.user?.photo"
          [placeHolderImage]="'/assets/Images/Placeholder/placeholder.jpg'"
        ></image>
        <div class="coverImage" *ngIf="checkRolesAndPermissions()">
          <div class="iconos" (click)="editImage()">
            <mat-icon>edit</mat-icon>
          </div>
        </div>
      </div>
      <div class="info">
        <div class="titles">
          <div class="nombreYAlias">
            <p class="nombre">
              {{ dataTeacher?.user?.name + " " + dataTeacher?.user?.surname }}
            </p>
            <p class="nombre">·</p>
            <p class="alias">{{ dataTeacher?.user?.alias }}</p>
          </div>
          <btn
            classList="specialButton"
            (click)="irAEditarProfesor()"
            *ngIf="checkRolesAndPermissions()"
          >
            <ng-container> Editar profesor </ng-container>
          </btn>
        </div>
        <div class="otherInfo">
          <div class="data">
            <span>
              {{ dataTeacher?.user?.email }}
              {{
                dataTeacher?.user?.secondaryEmail
                  ? ", " + dataTeacher?.user?.secondaryEmail
                  : ""
              }}
            </span>
            <span>
              ({{ dataTeacher?.user?.phonePrefix }})
              {{ dataTeacher?.user?.phone }}
            </span>
            <span>
              {{ dataTeacher?.user?.company }} -
              {{ dataTeacher?.user?.jobPosition }}
            </span>
          </div>
          <div class="data fechas">
            <span
              >Alta
              {{ dataTeacher?.incorporationDate | date : "MMMM, y" }}</span
            >
            <!-- <span>Activo {{activoDesde}}</span> -->
          </div>
        </div>
        <div class="footer">
          <span
            class="cursando"
            [class.active]="isDataLoaded"
            *ngFor="let topic of dataTeacher.topics"
            >#{{ topic.name }}</span
          >
          <span class="country" [class.active]="isDataLoaded">
            <i
              class="country_flags"
              size="ultra_small"
              [attr.country]="dataTeacher?.user?.address?.country?.isoCode"
            ></i>
            {{ dataTeacher?.user?.address?.province }},
            {{ dataTeacher?.user?.address?.country?.nombre }}
          </span>
        </div>
      </div>
    </div>
    <div class="submenu">
      <toggleSubmenu
        [elements]="
          checkRolesAndPermissionsBill()
            ? [
                'Información',
                'Honorarios',
                'Registro académico',
                'Documentación',
                'Tutorías',
                'Calendario',
                'Pendiente facturación',
                'Facturas'
              ]
            : [
                'Información',
                'Honorarios',
                'Registro académico',
                'Documentación',
                'Tutorías',
                'Calendario'
              ]
        "
        class="ficha_toggleSubmenu"
        [(ngModel)]="optionSubmenuSelected"
        (onValueChange)="onToggleChange($event)"
      ></toggleSubmenu>
    </div>
    <informacionProfesor
      [isDataLoaded]="isDataLoaded"
      [activoDesde]="activoDesde"
      [dataTeacher]="dataTeacher"
      *ngIf="optionSubmenuSelected == 0"
    ></informacionProfesor>
    <GestionUsuarios-Profesores-Detalles-Horas
      [isDataLoaded]="isDataLoaded"
      [dataTeacher]="dataTeacher"
      *ngIf="optionSubmenuSelected == 1"
    ></GestionUsuarios-Profesores-Detalles-Horas>
    <GestionUsuarios-Profesores-Detalles-RegistroAcademico
      [dataTeacher]="dataTeacher"
      *ngIf="optionSubmenuSelected == 2"
    ></GestionUsuarios-Profesores-Detalles-RegistroAcademico>
    <documentosProfesor
      [isDataLoaded]="isDataLoaded"
      (refresh)="refresh($event)"
      [dataTeacher]="dataTeacher"
      *ngIf="optionSubmenuSelected == 3"
    ></documentosProfesor>
    <ng-container *ngIf="optionSubmenuSelected === 4">
      <GestionUsuarios-Profesores-Detalles-Tutorias
        [dataTeacher]="dataTeacher"
      ></GestionUsuarios-Profesores-Detalles-Tutorias>
    </ng-container>

    <ng-container *ngIf="optionSubmenuSelected === 5">
      <GestionUsuarios-Profesores-Detalles-Calendario
        [dataTeacher]="dataTeacher"
      >
      </GestionUsuarios-Profesores-Detalles-Calendario>
    </ng-container>

    <ng-container
      *ngIf="optionSubmenuSelected === 6 && checkRolesAndPermissionsBill()"
    >
      <GestionUsuarios-Profesores-Detalles-PendienteFacturacion
        [dataTeacher]="dataTeacher"
      >
      </GestionUsuarios-Profesores-Detalles-PendienteFacturacion>
    </ng-container>

    <facturasProfesor
      (refresh)="refresh($event)"
      [dataTeacher]="dataTeacher"
      *ngIf="optionSubmenuSelected == 7 && checkRolesAndPermissionsBill()"
    ></facturasProfesor>
  </ng-container>
</fullContainer>
