<div class="informacionGeneralContainer">
  <pill classList="informacion" skeleton [isDataLoaded]="isDataLoaded">
    <ng-container>
      <div class="info">
        <div class="bio">
          <label for="">Bio</label>
          <p class="whiteSpace">
            {{dataTeacher?.user?.biography}}
          </p>
        </div>
        <div class="otrosDatos">
          <div>
            <label for="">Sexo</label>
            <p>{{dataTeacher?.user?.gender}}</p>
          </div>
          <div>
            <label for="">Fecha de nacimiento</label>
            <p>{{dataTeacher?.user?.dateOfBirth | date:'dd/MM/YYYY'}}</p>
          </div>
          <div>
            <label for="">Edad</label>
            <p>{{dataTeacher?.user?.age}}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
  <pill classList="informacion" skeleton [isDataLoaded]="isDataLoaded">
    <div class="info">
      <div class="otrosDatos">
        <div>
          <label for="">Idiomas</label>
          <p>{{dataTeacher?.languages}}</p>
        </div>
        <div>
          <label for="">País</label>
          <p>{{dataTeacher?.user?.address?.country?.nombre}}</p>
        </div>
        <div>
          <label for="">Nacionalidad</label>
          <p>{{dataTeacher?.user?.nationality?.ethnicity}}</p>
        </div>
        <div>
          <label for="">Provincia</label>
          <p>{{dataTeacher?.user?.address?.province}}</p>
        </div>
        <div>
          <label for="">Población</label>
          <p>{{dataTeacher?.user?.address?.municipality}}</p>
        </div>
        <div>
          <label for="">Dirección</label>
          <p>{{dataTeacher?.user?.address?.street + ' ,' + dataTeacher?.user?.address?.number }}</p>
        </div>
        <div>
          <label for="">DNI / NIE / Pasaporte</label>
          <p>{{dataTeacher?.user?.dni}}</p>
        </div>
      </div>
    </div>
  </pill>
  <pill classList="informacion" skeleton [isDataLoaded]="isDataLoaded">
    <div class="info">
      <div class="otrosDatos">
        <div>
          <label for="">Tipo de profesor</label>
          <p class="capitalize">{{dataTeacher?.typeOf}}</p>
        </div>
        <div>
          <label for="">Incorporación</label>
          <p class="capitalize"> {{ dataTeacher?.incorporationDate | date:'MMMM, y' }}</p>
        </div>
        <div>
          <label for="">Empresa</label>
          <p>{{dataTeacher?.user?.company ?? 'Sin empresa'}}</p>
        </div>
        <div>
          <label for="">Cargo</label>
          <p>{{dataTeacher?.user?.jobPosition ?? 'Sin empresa'}}</p>
        </div>

        <div>
          <label for="">Tiempo en activo</label>
          <p>{{dataTeacher?.activityTime}}</p>
        </div>
        <div>
          <label for="">Redes sociales</label>

          <div class="iconsRedesSociales">
            <span class="iconoRedSocial" (click)="openSocialMedia(dataTeacher?.user?.socialMedia?.linkedin)"
              *ngIf="dataTeacher?.user?.socialMedia?.linkedin">
              <img src="/assets/Icons/SocialMedia/linkedin.svg" alt="linkedinIcon">
            </span>
            <span class="iconoRedSocial" (click)="openSocialMedia(dataTeacher?.user?.socialMedia?.instagram)"
              *ngIf="dataTeacher?.user?.socialMedia?.instagram">
              <img src="/assets/Icons/SocialMedia/instagram.svg" alt="instagramIcon">
            </span>
            <span class="iconoRedSocial" (click)="openSocialMedia(dataTeacher?.user?.socialMedia?.facebook)"
              *ngIf="dataTeacher?.user?.socialMedia?.facebook">
              <img src="/assets/Icons/SocialMedia/facebook.svg" alt="facebookIcon">
            </span>
            <span class="iconoRedSocial" (click)="openSocialMedia(dataTeacher?.user?.socialMedia?.tiktok)"
              *ngIf="dataTeacher?.user?.socialMedia?.tiktok">
              <img src="/assets/Icons/SocialMedia/tiktok.svg" alt="tiktokIcon">
            </span>
            <span class="iconoRedSocial" (click)="openSocialMedia(dataTeacher?.user?.socialMedia?.x)"
              *ngIf="dataTeacher?.user?.socialMedia?.x">
              <img src="/assets/Icons/SocialMedia/x.svg" alt="xIcon">
            </span>
          </div>
        </div>
      </div>
    </div>
  </pill>
  <pill classList="informacion" skeleton [isDataLoaded]="isDataLoaded">
    <div class="info" [ngStyle]="{'justify-content': 'flex-start'}">
      <div class="datosBancarios">
        <label for="">Datos fiscales</label>
        <div>
          <label for="">Dirección</label>
          <p><span *ngIf="dataTeacher?.user?.bankData?.fiscalAddress?.street">
              {{dataTeacher?.user?.bankData?.fiscalAddress?.street + ' ,' +
              dataTeacher?.user?.bankData?.fiscalAddress?.number}}</span> <br>
            <span
              *ngIf="dataTeacher?.user?.bankData?.fiscalAddress?.municipality">{{dataTeacher?.user?.bankData?.fiscalAddress?.municipality
              + ' (' + dataTeacher?.user?.bankData?.fiscalAddress?.postalCode + '),' + ' ' +
              dataTeacher?.user?.bankData?.fiscalAddress?.country?.nombre }}</span>
          </p>
        </div>
      </div>
      <div class="datosBancarios">
        <label for="">Datos bancarios</label>
        <div>
          <label for="">IBAN</label>
          <p>{{dataTeacher?.user?.bankData?.iban}}</p>
        </div>
      </div>
      <div class="datosBancarios">
        <div [ngStyle]="{'border-bottom': 'none'}">
          <label for="">BIC/SWIFT</label>
          <p>{{dataTeacher?.user?.bankData?.bic}}</p>
        </div>
      </div>
      <div class="datosBancarios especial">
        <label for="">Tipo de retención</label>
        <p>{{dataTeacher?.user?.bankData?.retentionType}}</p>
      </div>
      <div class="datosBancarios especial">
        <label for="">Situación laboral</label>
        <p>{{dataTeacher?.user?.employmentStatus}}</p>
      </div>
    </div>

  </pill>
  <pill classList="informacion" *ngIf="!isEditing" skeleton [isDataLoaded]="isDataLoaded">
    <div class="info" [ngStyle]="{'justify-content': 'flex-start'}">
      <div class="textAndIcon">
        <label for="">Observaciones y anotaciones</label>
        <mat-icon (click)="changeEdit()" *ngIf="checkRolesAndPermissions()">
          <span class="tag">Editar</span>
          edit</mat-icon>
      </div>
      <div class="area">
        <div>{{dataTeacher?.user?.observation}}</div>
      </div>
    </div>
  </pill>
  <pill classList="informacion" *ngIf="isEditing" skeleton [isDataLoaded]="isDataLoaded">
    <div class="info" [ngStyle]="{'justify-content': 'flex-start'}">
      <div class="textAndIcon">
        <label for="">Observaciones y anotaciones</label>
        <mat-icon (click)="changeEdit()">
          <span class="tag">Cerrar</span>
          close</mat-icon>
      </div>
      <div class="area">
        <textarea (change)="editObresvaciones()" [(ngModel)]="dataTeacher.user.observation"></textarea>
      </div>
    </div>
  </pill>
</div>