import { transition, trigger } from '@angular/animations';
import { Component, OnInit, Type } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AnimationControllerService,
  DefaultBaseService,
  iUnsubscribeDestroy,
  NotificationsService,
  slideNavigationFunctions,
  slideNavigationTypes,
  TokenManagmentService,
  VariablesDesignSystemService,
} from '@quasar_dynamics/basic-designsystem';
import { filter, map, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from './Services/Api/Login.service';
import { GeneralLoaderService } from '@quasar-dynamics/basic-designsystem';
import { IEMLoaderComponent } from './Shared/Components/IEMLoader/IEMLoader.component';
import { InfiniteScrollService } from '@quasar-dynamics/basic-designsystem';
import { RoleGlobalStateService } from './Services/Utils/RoleGlobalState.service';
import { StaticUtilitiesService } from './Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routerTransition', [
      transition(
        '* => slideToLeft',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)
      ),
      transition(
        '* => slideToRight',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)
      ),
      transition(
        '* => slideToTop',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)
      ),
      transition(
        '* => slideToBottom',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToBottom
        )
      ),
      transition(
        '* => slideToLeftDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)
      ),
      transition(
        '* => slideToRightDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)
      ),
      transition(
        '* => slideToTopDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)
      ),
      transition(
        '* => slideToBottomDuplicate',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToBottom
        )
      ),
    ]),
  ],
})
export class AppComponent extends iUnsubscribeDestroy implements OnInit {
  title = 'IEM';
  private loaderComponent = IEMLoaderComponent;
  constructor(
    private router: Router,
    private titleSE: Title,
    public animService: AnimationControllerService,
    private defaultBaseSE: DefaultBaseService,
    private route: ActivatedRoute,
    private notificaciones: NotificationsService,
    private loginSE: LoginService,
    private roleGlobalStateSE: RoleGlobalStateService,
    private generalLoaderSE: GeneralLoaderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.generalLoaderSE.setLoaderComponent(IEMLoaderComponent);
    console.log(
      '%cDeveloped by: ',
      'color: rgb(90, 170,221); font-size: 24px;'
    );
    console.log(
      '%cQUASAR DYNAMICS',
      'color: rgb(90, 170,221); font-size: 64px;font-weigth: bold;'
    );
    console.log(
      '%cIf you like the project, you can find many more on our website:   https://quasardynamics.com/',
      'color: rgb(90, 170,221); font-size: 18px;'
    );

    VariablesDesignSystemService.setIsRelease(environment.production);
    VariablesDesignSystemService.setUrlApi(environment.urlApi);

    if (localStorage.getItem('usr')) {
      const { roles, permissions } =
        StaticUtilitiesService.getUserPermissionsAndRoles();
      this.roleGlobalStateSE.setRoleGlobalState(roles);
      this.roleGlobalStateSE.setPermissionsGlobalState(permissions);
    }

    TokenManagmentService.listenerTokenLost()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        this.notificaciones.showError(
          'Ha habido un problema, intentalo más tarde'
        );
        this.loginSE.logOut();
      });

    TokenManagmentService.listenerStartRefreshToken()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!location.pathname.includes('login')) {
          this.loginSE.refreshToken();
        }
      });

    this.animService.setCurrentAnimation(slideNavigationTypes.slideToTop);
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleSE.setTitle(`IEM - ${title}`);
        }
      });

    InfiniteScrollService.setComponentLoader(this.loaderComponent);
  }
}
