import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iPromotionFormation } from "./iPromotionFormation";
import { iStudent } from "./iStudent";

export interface iEnrollment {
    id: number,
    state?: string,
    enrollDate?: iDate,
    phase?: string,
    promotionFormation?: iPromotionFormation,
    student?: iStudent,
}

export class iEnrollment extends iBaseObject {
    static convertFromCollection(ob: any): Array<iEnrollment> {
        let iEnrollmentCollection: Array<iEnrollment> = [];
        ob.forEach((element: any) => {
            iEnrollmentCollection.push(this.convertFromObject(element));
        });
        return iEnrollmentCollection;
    }

    static convertFromObject(ob: any): iEnrollment {
        let iEnrollmentObj = new iEnrollment();
        iEnrollmentObj.id = ob.id;
        iEnrollmentObj.state = ob.state;
        iEnrollmentObj.enrollDate = iDate.phpConvert(ob.enrollDate);
        iEnrollmentObj.phase = ob.phase;
        iEnrollmentObj.promotionFormation = ob.promotionFormation ? iPromotionFormation.convertFromObject(ob.promotionFormation) : iPromotionFormation.createEmptyObject();
        iEnrollmentObj.student = ob.student ? iStudent.convertFromObject(ob.student) : iStudent.createEmptyObject();
        return iEnrollmentObj;
    }

    static createEmptyObject(): iEnrollment {
        let iEnrollmentObj = new iEnrollment();
        iEnrollmentObj.state = '';
        iEnrollmentObj.enrollDate = iDate.javascriptConvert(new Date());
        iEnrollmentObj.phase = '';
        iEnrollmentObj.promotionFormation = iPromotionFormation.createEmptyObject();
        iEnrollmentObj.student = iStudent.createEmptyObject();
        return iEnrollmentObj;
    }
}

export interface iEnrollmentFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    state?: string,
    enrollDate?: string,
    phase?: string,
    promotionFormation?: number,
    student?: number,
    typeOf?: string,
}