<div class="multipleSelector" [class.multipleSelector--readonly]="readonly">
  <ng-container *ngFor="let item of items">
    <button 
      (click)="handleClickButton(item)" 
      class="multipleSelector__item"
      [ngClass]="{'multipleSelector__item--selected': checkSelected(item)}"
    >
      {{item.label}}
    </button>
  </ng-container>
</div>