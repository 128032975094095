<div class="viewContainer">
  <div class="elementColumn" specName="crm">
    <p class="titlePipeline">CRM</p>
    <!-- Loader -->
    <!-- <loaderIcon *ngIf="!isCRMIncompanies"></loaderIcon> -->
    <div class="LoaderCRM" *ngIf="!isCRMIncompanies">
      <p>Conectando con Pipe Drive
        <span class="dot1" >.</span> <span class="dot2">.</span> <span class="dot3">.</span>        
      </p>
    </div>
    
    <!-- <div class="conexionFallida" *ngIf="conexionCRMFallida"> 
    <p>
      Conexión fallida 
    </p>
    </div> -->

    <!-- If no data -->
    <div *ngIf="!incompanyCRM && !isCRMIncompanies">
      <p>No hay datos</p>
    </div>
    <div *ngFor="let item of incompanyCRM" class="pipeLineContentCustom" (click)="clickCRM(item)">
      <div class="imagePlusName">
        <span class="imageSpan">{{item.name?.substr(0,1).toUpperCase()}}</span>
        <p><b>{{item.name}}</b></p>
      </div>
      <div class="emailPhoneState">
        <div class="emailPhone">
          <p>{{item.amount | currency:'EUR'}} {{item.currency}}</p>
        </div>
        <div class="botones" *ngIf="checkRolesAndPermissions()">
          <btn classList="generalbutton pipeDriveLossButton onlyIcon"
            (click)="$event.stopPropagation();lossCard(item, 'crm')">
            <mat-icon>thumb_down</mat-icon>
          </btn>

        </div>
      </div>
      <div class="stateLine">
        <p class="state">{{item.state}}</p>
      </div>
      <div class="promotions">
        <p class="pipeUbicacion" *ngFor="let producto of item.products">{{producto.name}}</p>
      </div>
    </div>
  </div>
  <!-- Columna Pre matrícula -->
  <div class="elementColumn" specName="preEnroll">
    <p class="titlePipeline">Propuesta Formal</p>
    <!-- Loader -->
    <loaderIcon *ngIf="!isPropuestaFormalLoaded"></loaderIcon>
    <!-- If no data -->
    <div *ngIf="formalizedProposals && !isPropuestaFormalLoaded">
      <p>No hay datos</p>
    </div>
    <!-- Content -->
    <div *ngFor="let item of formalizedProposals" class="pipeLineContentCustom hover"
      (click)="openPopupSelectorOfAction(item)" [ngClass]="{isGroup: item.isGroup}">
      <div class="imagePlusName">
        <span class="imageSpan">{{item.name?.substr(0,1)}}</span>
        <p><b>{{item.name}}</b></p>
      </div>
      <div class="emailPhoneState">
        <div class="emailPhone">
          <p>Presupuesto: {{item.amount}} EUR</p>
          <p>Gasto: {{item.spent}} EUR</p>
        </div>
        <!-- <div class="botones" *ngIf="checkRolesAndPermissions()">
          <btn classList="generalbutton pipeDriveLossButton onlyIcon" (click)="$event.stopPropagation();openPopupSelectorOfAction(item)">
            <mat-icon>kebab_dining</mat-icon>
          </btn>
        </div> -->
      </div>
      <div class="stateLine">
        <p class="state">{{item.state}}</p>
      </div>
      <div class="promotions">
        <p class="pipeUbicacion">{{item.formation?.name}}</p>
      </div>
    </div>
  </div>
  <div class="elementColumn" specName="enroll">
    <p class="titlePipeline">Pendiente de verificación</p>
    <!-- Loader -->
    <loaderIcon *ngIf="!isThirdColumn"></loaderIcon>
    <!-- If no data -->
    <div *ngIf="thirdColumnIncompanies.length == 0 && !isThirdColumn">
      <p>No hay datos</p>
    </div>
    <!-- Content -->
    <div *ngFor="let item of thirdColumnIncompanies" class="pipeLineContentCustom">
      <div class="imagePlusName">
        <span class="imageSpan">P</span>
        <p><b>{{item.formalizedProposal?.name}}</b></p>
      </div>
      <div class="eachFormation" *ngFor="let result of item.results" (click)="openMatriculaConfirmPopup(result)">
        <div class="imagePlusName">
          <span class="imageSpan">{{result.studentsGroup?.name?.substr(0,1)}}</span>
          <p><b>{{result.studentsGroup?.name}}</b></p>
        </div>
        <div class="emailPhoneState">
          <div class="emailPhone">
            <p>{{result?.groupPrice}} EUR</p>
          </div>
          <div class="botones" *ngIf="checkRolesAndPermissions()">
            <btn classList="generalbutton trashLineFilterButton onlyIcon"
              (click)="$event.stopPropagation();deleteGroup(result)">
              <mat-icon>delete</mat-icon>
            </btn>
          </div>
        </div>
        <div class="stateLine">
          <p class="state">{{result?.state}}</p>
        </div>

      </div>
    </div>
  </div>

  <span class="goUp" (click)="goUp()" #bubble>
    <mat-icon>arrow_upward</mat-icon>
  </span>
</div>