<div class="information__container">
  <div class="PromotionHeader" [ngClass]="{ editing: isEditingHeader }">
    <button class="absolutIconEdit" (click)="handleClickedEdit()">
      <mat-icon *ngIf="!isEditingHeader">edit</mat-icon>
      <mat-icon *ngIf="isEditingHeader">check_circle</mat-icon>
    </button>

    <h2>
      <span
        text-editable
        [canEdit]="isEditingHeader"
        [(ngModel)]="extraClass.header.edition"
        (input)="updatePromotionFunction(extraClass.header.edition, 'edition')"
        >{{ extraClass.header.edition }}</span
      >
      · {{ extraClass.header.formation }}
    </h2>
    <div class="PromotionHeader__chips">
      <div class="PromotionHeader__chip">
        <img src="/assets/Icons/Sidebar/misAlumnos.svg" alt="" />
        <span>
          {{ extraClass.header.students }}
        </span>
      </div>
      <div class="PromotionHeader__chip">
        <img src="/assets/Icons/General/book.svg" alt="" />
        <span> {{ extraClass.header.sessions }} sesiones </span>
      </div>
      <chip-selector
        [chipSelectorOptions]="extraClass.chipSelectorStatusOptions"
        [(ngModel)]="extraClass.header.state"
        (change)="prepareObjectToUpdatePromotion($event, 'state')"
      ></chip-selector>
    </div>
  </div>
  <div class="mainContentContainer">
    <section class="formationInfo leftSide">
      <Editablebox
        title="Información general"
        bgColor="gray"
        [fullHeight]="true"
        (sendIsEditing)="handelChangeEdit($event)"
      >
        <div class="formationInfo--general basicGrid">
          <div>
            <p>Fecha de inicio:</p>
            <ng-container
              *ngIf="extraClass.editableController['Información general']"
            >
              <customInput
                [(ngModel)]="extraClass.generalInfo.startDate"
                [selectedDate]="
                  returnFormattedDate(extraClass.generalInfo.startDate)
                "
                [options]="extraClass.startDateSelectorCustomInputOptions"
                (change)="handleDates($event, 'generalInfo', 'startDate')"
              ></customInput>
            </ng-container>
            <ng-container
              *ngIf="!extraClass.editableController['Información general']"
            >
              <span>{{
                extraClass.generalInfo.startDate | date : "dd/MM/yyyy"
              }}</span>
            </ng-container>
          </div>

          <div>
            <p>Fecha de fin:</p>
            <ng-container
              *ngIf="extraClass.editableController['Información general']"
            >
              <customInput
                [(ngModel)]="extraClass.generalInfo.endDate"
                [selectedDate]="
                  returnFormattedDate(extraClass.generalInfo.endDate)
                "
                [options]="extraClass.endDateSelectorCustomInputOptions"
                (change)="handleDates($event, 'generalInfo', 'endDate')"
              >
                ></customInput
              >
            </ng-container>
            <ng-container
              *ngIf="!extraClass.editableController['Información general']"
            >
              <span>{{
                extraClass.generalInfo.endDate | date : "dd/MM/yyyy"
              }}</span>
            </ng-container>
          </div>

          <div>
            <p>Acción Formativa</p>
            <ng-container
              *ngIf="extraClass.editableController['Información general']"
            >
              <customInput
                [(ngModel)]="extraClass.generalInfo.formativeAction"
                (change)="
                  prepareObjectToUpdatePromotion(
                    extraClass.generalInfo.formativeAction,
                    'formativeAction'
                  )
                "
                [options]="extraClass.accionFormativaCustomInputOptions"
              >
                ></customInput
              >
            </ng-container>
            <ng-container
              *ngIf="!extraClass.editableController['Información general']"
            >
              <span>{{ extraClass.generalInfo.formativeAction }}</span>
            </ng-container>
          </div>

          <div>
            <p>Grupo</p>
            <ng-container
              *ngIf="extraClass.editableController['Información general']"
            >
              <customInput
                (change)="
                  prepareObjectToUpdatePromotion(
                    extraClass.generalInfo.formativeGroup,
                    'formativeGroup'
                  )
                "
                [(ngModel)]="extraClass.generalInfo.formativeGroup"
                [options]="extraClass.groupCustomInputOptions"
              >
              </customInput>
            </ng-container>
            <ng-container
              *ngIf="!extraClass.editableController['Información general']"
            >
              <span>{{ extraClass.generalInfo.formativeGroup }}</span>
            </ng-container>
          </div>

          <div>
            <p>Aula General</p>
            <ng-container
              *ngIf="extraClass.editableController['Información general']"
            >
              <selector
                style="width: 100%"
                [(ngModel)]="extraClass.generalInfo.classroomId"
                [options]="extraClass.aulaSelectorOptions"
                (change)="
                  prepareObjectToUpdatePromotion(
                    extraClass.generalInfo.classroomId,
                    'classroom'
                  )
                "
              >
              </selector>
            </ng-container>
            <ng-container
              *ngIf="!extraClass.editableController['Información general']"
            >
              <span>{{
                returnVariableName(
                  extraClass.generalInfo.classroomId,
                  "classroom"
                )
              }}</span>
            </ng-container>
          </div>

          <div>
            <p>Disposición</p>

            <ng-container
              *ngIf="extraClass.editableController['Información general']"
            >
              <add-and-search-selector
                [selectorOptions]="extraClass.disposicionSelectorOptions"
                (change)="
                  changeLabelSelector($event, 'generalInfo', 'dispositionId')
                "
                [model]="extraClass.generalInfo.dispositionId"
                (deleteSelector)="deleteElementFromSelectorDisposition($event)"
                (addElementToSelector)="getElementToAddDisposition($event)"
                (selectedOptionOutput)="
                  getModelChange($event, 'classroomDisposition')
                "
              ></add-and-search-selector>
            </ng-container>

            <ng-container
              *ngIf="!extraClass.editableController['Información general']"
            >
              <span>{{
                returnVariableName(
                  extraClass.generalInfo.dispositionId,
                  "classroomDisposition"
                )
              }}</span>
            </ng-container>
          </div>

          <div>
            <p>Turno</p>

            <ng-container
              *ngIf="extraClass.editableController['Información general']"
            >
              <add-and-search-selector
                [selectorOptions]="extraClass.shiftSelectorOptions"
                (change)="changeLabelSelector($event, 'generalInfo', 'shift')"
                [model]="extraClass.generalInfo.shift"
                (deleteSelector)="deleteElementFromSelector($event)"
                (addElementToSelector)="getElementToAdd($event)"
                (selectedOptionOutput)="getModelChange($event, 'shift')"
              ></add-and-search-selector>
            </ng-container>

            <ng-container
              *ngIf="!extraClass.editableController['Información general']"
            >
              <span>{{
                returnVariableName(extraClass.generalInfo.shift, "shift")
              }}</span>
            </ng-container>
          </div>

          <div>
            <p>Horario</p>

            <ng-container
              *ngIf="extraClass.editableController['Información general']"
            >
              <div class="horarioSelectors">
                <customInput
                  [(ngModel)]="extraClass.generalInfo.startHour"
                  [options]="extraClass.startScheduleCustomInputOptions"
                  (change)="
                    prepareObjectToUpdatePromotion(
                      extraClass.generalInfo.startHour,
                      'startHour'
                    )
                  "
                ></customInput>
                <p>-</p>
                <customInput
                  [(ngModel)]="extraClass.generalInfo.endHour"
                  (change)="
                    prepareObjectToUpdatePromotion(
                      extraClass.generalInfo.endHour,
                      'endHour'
                    )
                  "
                  [options]="extraClass.endScheduleCustomInputOptions"
                ></customInput>
              </div>
            </ng-container>

            <ng-container
              *ngIf="!extraClass.editableController['Información general']"
            >
              <span
                >{{ extraClass.generalInfo.startHour }} -
                {{ extraClass.generalInfo.endHour }}</span
              >
            </ng-container>
          </div>
          <div class="long">
            <div class="days-container">
              <p>Días de impartición</p>
              <div class="daysContainer">
                <div
                  (click)="
                    toggleDay(
                      1,
                      extraClass.editableController['Información general']
                    )
                  "
                  class="day"
                  [ngClass]="{ selected: checkIfselectedDay(1) }"
                >
                  Lunes
                </div>
                <div
                  (click)="
                    toggleDay(
                      2,
                      extraClass.editableController['Información general']
                    )
                  "
                  class="day"
                  [ngClass]="{ selected: checkIfselectedDay(2) }"
                >
                  Martes
                </div>
                <div
                  (click)="
                    toggleDay(
                      3,
                      extraClass.editableController['Información general']
                    )
                  "
                  class="day"
                  [ngClass]="{ selected: checkIfselectedDay(3) }"
                >
                  Miercoles
                </div>
                <div
                  (click)="
                    toggleDay(
                      4,
                      extraClass.editableController['Información general']
                    )
                  "
                  class="day"
                  [ngClass]="{ selected: checkIfselectedDay(4) }"
                >
                  Jueves
                </div>
                <div
                  (click)="
                    toggleDay(
                      5,
                      extraClass.editableController['Información general']
                    )
                  "
                  class="day"
                  [ngClass]="{ selected: checkIfselectedDay(5) }"
                >
                  Viernes
                </div>
                <div
                  (click)="
                    toggleDay(
                      6,
                      extraClass.editableController['Información general']
                    )
                  "
                  class="day"
                  [ngClass]="{ selected: checkIfselectedDay(6) }"
                >
                  Sábado
                </div>
                <div
                  (click)="
                    toggleDay(
                      0,
                      extraClass.editableController['Información general']
                    )
                  "
                  class="day"
                  [ngClass]="{ selected: checkIfselectedDay(0) }"
                >
                  Domingo
                </div>
              </div>
            </div>
          </div>
        </div>
      </Editablebox>

      <Editablebox
        title="Remuneración docentes"
        bgColor="gray"
        (sendIsEditing)="handelChangeEdit($event); setIsEditingPrecios($event)"
        *ngIf="
          this.roleGlobalStateSE.hasAnyPermission([7, 8]) ||
          this.roleGlobalStateSE.hasAnyRole(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])
        "
        [canEdit]="
          this.roleGlobalStateSE.hasAnyPermission([8]) ||
          this.roleGlobalStateSE.hasAnyRole(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])
        "
      >
        <div class="prices-part">
          <customInput
            [options]="extraClass.teacherHourAmountCustomInputOptions"
            [(ngModel)]="extraClass.amounts.teachingAmount"
            (change)="
              prepareObjectToUpdatePromotion(
                extraClass.amounts.teachingAmount,
                'teachingAmount'
              )
            "
          ></customInput>
          <customInput
            *ngIf="isMaster"
            [options]="extraClass.projectHourAmountCustomInputOptions"
            [(ngModel)]="extraClass.amounts.projectAmount"
            (change)="
              prepareObjectToUpdatePromotion(
                extraClass.amounts.projectAmount,
                'projectAmount'
              )
            "
          ></customInput>
          <customInput
            *ngIf="isMaster"
            [options]="extraClass.tutoringCustomInputOptions"
            [(ngModel)]="extraClass.amounts.tutoringAmount"
            (change)="
              prepareObjectToUpdatePromotion(
                extraClass.amounts.tutoringAmount,
                'tutoringAmount'
              )
            "
          ></customInput>
          <customInput
            *ngIf="isMaster"
            [options]="extraClass.managementCustomInputOptions"
            [(ngModel)]="extraClass.amounts.managementAmount"
            (change)="
              prepareObjectToUpdatePromotion(
                extraClass.amounts.managementAmount,
                'managementAmount'
              )
            "
          ></customInput>
          <customInput
            *ngIf="isMaster"
            [options]="extraClass.coordinatorCustomInputOptions"
            [(ngModel)]="extraClass.amounts.coordinationAmount"
            (change)="
              prepareObjectToUpdatePromotion(
                extraClass.amounts.coordinationAmount,
                'coordinationAmount'
              )
            "
          ></customInput>
        </div>
      </Editablebox>

      <Editablebox
        title="Coordinadores"
        bgColor="gray"
        (sendIsEditing)="handelChangeEdit($event)"
        *ngIf="isMaster"
      >
        <div class="generalCoordinators">
          <ng-container
            *ngIf="extraClass.coordinators.length > 0; else addCoordinators"
          >
            <div class="formationInfo--coordinators basicGrid">
              <!-- ngFor -->
              <ng-container
                *ngFor="
                  let coordinator of extraClass.coordinators;
                  index as index;
                  trackBy: trackByCoordinator;
                  else: addCoordinators
                "
              >
                <div>
                  <span
                    >{{ coordinator.sequence }}: {{ coordinator.module }}</span
                  >
                  <ng-container
                    *ngIf="!extraClass.editableController['Coordinadores']"
                  >
                    <p>
                      {{
                        returnVariableName(coordinator.teacherId, "coordinator")
                      }}
                    </p>
                  </ng-container>
                  <ng-container
                    *ngIf="extraClass.editableController['Coordinadores']"
                  >
                    <selector
                      style="width: 100%"
                      [(ngModel)]="coordinator.teacherId"
                      [options]="extraClass.coordinatorSelectorOptions"
                      (change)="
                        prepareObjectToUpdateModule(
                          coordinator.teacherId,
                          coordinator.moduleId,
                          'teacherId'
                        )
                      "
                    >
                    </selector>
                  </ng-container>
                </div>
              </ng-container>
            </div>
            <ng-container
              *ngIf="extraClass.editableController['Coordinadores']"
            >
              <btn
                style="width: fit-content"
                classList="generalButton principalButton fitWidth"
                (click)="
                  prepareObjectToUpdatePromotion(false, 'hasCoordinators')
                "
                >Eliminar todos los coordinadores
              </btn>
            </ng-container>
          </ng-container>
        </div>
      </Editablebox>

      <Editablebox
        title="Director/a de la edición"
        (sendIsEditing)="handelChangeEdit($event)"
        bgColor="gray"
        *ngIf="isMaster"
      >
        <ng-container
          *ngIf="extraClass.editableController['Director/a de la edición']"
        >
          <selector
            [(ngModel)]="extraClass.director.directorId"
            [options]="extraClass.directorSelectorOptions"
            (change)="
              prepareObjectToUpdatePromotion(
                extraClass.director.directorId,
                'directorId'
              )
            "
          ></selector>
        </ng-container>
        <ng-container
          *ngIf="!extraClass.editableController['Director/a de la edición']"
        >
          <p class="formationInfo--director">
            {{ returnVariableName(extraClass.director.directorId, "director") }}
          </p>
        </ng-container>
      </Editablebox>

      <Editablebox
        title="Documentación y normativa"
        bgColor="gray"
        (sendIsEditing)="handelChangeEdit($event)"
        [fullHeight]="true"
      >
        <div class="formationInfo--docs basicGrid">
          <ng-container *ngFor="let document of extraClass.documents">
            <div>
              <div class="imageAndText">
                <img
                  src="/assets/Icons/Decoracion/Icono_archivo.svg"
                  alt="pdf"
                />
                <p (click)="openDocument(document)">
                  {{ document.documentName }}
                </p>
              </div>
              <ng-container
                *ngIf="
                  extraClass.editableController['Documentación y normativa']
                "
              >
                <img
                  class="cursor-pointer"
                  src="/assets/Images/Icons/delete.svg"
                  alt="delete"
                  (click)="deleteDocument([document.documentId])"
                />
              </ng-container>
            </div>
          </ng-container>
          <!-- AddPDF -->
          <ng-container
            *ngIf="extraClass.editableController['Documentación y normativa']"
          >
            <div>
              <button-add
                fileInput
                [accept]="extraClass.supportedFormats"
                [(ngModel)]="extraClass.ngFiles"
                [multiple]="extraClass.multiple"
                (change)="getFile(extraClass.ngFiles)"
              ></button-add>
            </div>
          </ng-container>
        </div>
      </Editablebox>
    </section>

    <section class="formationInfo leftSide">
      <Editablebox
        class="formationDetail__content__Container"
        title="Contenidos Generales"
        bgColor="gray"
        (sendIsEditing)="handelChangeEdit($event)"
      >
        <DetailView-Content
          (onCreate)="onCreateGetModules($event)"
          [promotion]="extraClass.promotionId"
          [modules]="extraClass.modules"
          [isEditing]="extraClass.editableController['Contenidos Generales']"
        ></DetailView-Content>
      </Editablebox>
    </section>
  </div>
</div>

<ng-template #addCoordinators>
  <ng-container *ngIf="extraClass.editableController['Coordinadores']">
    <button-add (click)="addCoordinatorsToInfo()"></button-add>
  </ng-container>
</ng-template>
