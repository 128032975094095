import { Component, Input, OnInit } from '@angular/core';
import {
  GeneralLoaderService,
  StaticUtilitiesService,
  iDate,
} from '@quasar-dynamics/basic-designsystem';
import { iResultHttp } from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { LandmarkService } from 'src/app/Services/Api/Landmark.service';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { GestionUsuariosProfesoresDetallesRegistroAcademicoClass } from 'src/app/Shared/Classes/GestionUsuariosProfesoresDetallesRegistroAcademicoClass';

@Component({
  selector: 'GestionUsuarios-Profesores-Detalles-RegistroAcademico',
  templateUrl:
    './GestionUsuarios-Profesores-Detalles-RegistroAcademico.component.html',
  styleUrls: [
    './GestionUsuarios-Profesores-Detalles-RegistroAcademico.component.scss',
  ],
})
export class GestionUsuariosProfesoresDetallesRegistroAcademicoComponent
  implements OnInit
{
  @Input() get dataTeacher() {
    return this.extraClass._dataTeacher;
  }
  set dataTeacher(value) {
    this.extraClass._dataTeacher = value;
    if (value && value.id) {
      this.getAcademicRegisterByTeacherId();
    }
  }

  extraClass: GestionUsuariosProfesoresDetallesRegistroAcademicoClass =
    new GestionUsuariosProfesoresDetallesRegistroAcademicoClass();

  constructor(
    private generalLoaderSE: GeneralLoaderService,
    private teacherSE: TeacherService,
    private landmarkSE: LandmarkService
  ) {}

  ngOnInit() {
    this.getLandmarkStateSelector();
  }

  /**
   * HANDLERS
   */

  successGetAcademicRegisterByTeacherIdHandler(res: iResultHttp) {
    const { data, total } = res.data;
    this.extraClass.academicRegister = data;
    this.extraClass.totalAcademicRegister = total;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetLandmarkStateSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.dateStateSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITIES
   */

  onPageChange(event) {
    this.extraClass.academicRegisterFilters.num_pagina = event;
    this.getAcademicRegisterByTeacherId();
  }

  getSearch(event) {
    this.extraClass.academicRegisterFilters.genericFilter = event;
    if (!event) delete this.extraClass.academicRegisterFilters.genericFilter;
    this.getAcademicRegisterByTeacherId();
  }

  sendTypeToFilter(optionSubmenuSelected) {
    switch (optionSubmenuSelected) {
      case 0:
        delete this.extraClass.academicRegisterFilters.type;
        break;

      case 1:
        this.extraClass.academicRegisterFilters.type = 2;
        break;

      case 2:
        this.extraClass.academicRegisterFilters.type = 12;
        break;

      case 3:
        this.extraClass.academicRegisterFilters.type = 14;
        break;

      case 4:
        this.extraClass.academicRegisterFilters.type = 3;
        break;

      case 5:
        this.extraClass.academicRegisterFilters.type = 4;
        break;

      default:
        break;
    }
    this.getAcademicRegisterByTeacherId();
  }

  getFilters(value, key) {
    if (key === 'date' && value)
      value = iDate.javascriptConvert(new Date(value)).toStringDate('JAP');
    this.extraClass.academicRegisterFilters[key] = value;
    if (!value) delete this.extraClass.academicRegisterFilters[key];
    this.getAcademicRegisterByTeacherId();
  }

  /**
   * API CALLS
   */

  getAcademicRegisterByTeacherId() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.teacherSE.getAcademicRegisterByTeacherId(
      behaviorSubject,
      this.extraClass._dataTeacher.id,
      this.extraClass.academicRegisterFilters
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successGetAcademicRegisterByTeacherIdHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getLandmarkStateSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.landmarkSE.getLandmarkStateSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetLandmarkStateSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
