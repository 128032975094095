import { Component, Input, OnInit } from '@angular/core';
import { GeneralLoaderService, StaticUtilitiesService, iResultHttp } from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { FormationService } from 'src/app/Services/Api/Formation.service';
import { DetailViewCalendarClass } from 'src/app/Shared/Classes/DetailViewCalendarClass';

@Component({
  selector: 'DetailView-Calendar',
  templateUrl: './DetailView-Calendar.component.html',
  styleUrls: ['./DetailView-Calendar.component.scss']
})
export class DetailViewCalendarComponent implements OnInit {


  @Input() get isCurso(){
    return this.extraClass.isCurso;
  };

  set isCurso(value){
    this.extraClass.isCurso = value;
    if(value)
      this.extraClass.checkBoxesArray = this.extraClass.checkBoxesArrayCursos;
  }
  @Input() get isMaster(){
    return this.extraClass.isMaster;
  };

  set isMaster(value){
    this.extraClass.isMaster = value;
    if(value)
      this.extraClass.checkBoxesArray = this.extraClass.checkBoxesArrayMaster;
  }

  @Input() get promotionId(){
    return this.extraClass._promotionId;
  };

  set promotionId(value){
    this.extraClass._promotionId = value;
    this.getFormationIdByPromotionId();
  }

  formationId: number = 0;


  extraClass:DetailViewCalendarClass = new DetailViewCalendarClass()



  constructor(private formationSE:FormationService, private generalLoaderSE:GeneralLoaderService) { }

  ngOnInit() {
  }

  /***
   * HANDLERS
   */

    
  successgetFormationIdByPromotionIdHandler(res:iResultHttp){
    const { data } = res;
    this.formationId = data;
    this.generalLoaderSE.removeFromLoaderAmount();
   }

  /***
   * API CALLS
   */

  getFormationIdByPromotionId() {
  this.generalLoaderSE.addToLoaderAmount();
  const subject = StaticUtilitiesService.createSubject();
  const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
  this.formationSE.getFormationIdByPromotionId(behaviorSubject, this.extraClass._promotionId);
  behaviorSubject
    .pipe(
      takeUntil(subject),
      filter((res) => res)
    )
    .subscribe((res: iResultHttp) => {
      StaticUtilitiesService.apiResponseHandler(res, subject, [
      {
        method: () => this.successgetFormationIdByPromotionIdHandler(res),
      },
      {
        method: () => this.generalLoaderSE.removeFromLoaderAmount(),
        error:true
      }
     ]);
    });
  }


}
