import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { cl } from '@fullcalendar/core/internal-common';
import {
  DebouncerService,
  GeneralLoaderService,
  StaticUtilitiesService,
  iResultHttp,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import {
  PopupService,
  iDate,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ConfirmarAccionPopupComponent } from 'src/app/Popups/ConfirmarAccion-Popup/ConfirmarAccion-Popup.component';
import { ClassroomService } from 'src/app/Services/Api/Classroom.service';
import { MasterService } from 'src/app/Services/Api/Master.service';
import { PromotionFormationService } from 'src/app/Services/Api/PromotionFormation.service';
import { SlotService } from 'src/app/Services/Api/Slot.service';
import { TeacherService } from 'src/app/Services/Api/Teacher.service';
import { TopicService } from 'src/app/Services/Api/Topic.service';
import { TutoringService } from 'src/app/Services/Api/Tutoring.service';
import { ConflictsService } from 'src/app/Services/Utils/Conflicts.service';
import { TutoriaCrearClass } from 'src/app/Shared/Classes/TutoriaCrearClass';
import { iTutoringSessionForDuplicate } from 'src/app/Shared/Interfaces/Utils/iTutoringSessionForDuplicate';

@Component({
  selector: 'Tutoria-Crear',
  templateUrl: './Tutoria-Crear.component.html',
  styleUrls: ['./Tutoria-Crear.component.scss'],
})
export class TutoriaCrearComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  extraClass: TutoriaCrearClass = new TutoriaCrearClass();

  constructor(
    private popupSE: PopupService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private teacherSE: TeacherService,
    private masterSE: MasterService,
    private generalLoaderSE: GeneralLoaderService,
    private promotionSE: PromotionFormationService,
    private tutoringSE: TutoringService,
    private classroomSE: ClassroomService,
    private slotSE: SlotService,
    private topicSE: TopicService,
    private conflictsSE: ConflictsService
  ) {
    super();
  }

  ngOnInit() {
    this.extraClass.setReadonliesInSelectorOptions();
    this.getTeachers();
    this.getMastersSelector();
    this.getAttendanceModalitySelector();
    this.getClassroomSelector();
    this.getSlotSelector();
    // this.getTopicsSelector();
    this.getParams().then((_) => {
      if (this.extraClass.tutoringForDuplicate)
        this.getTutoringInfoForDuplicate();
    });
  }

  ngAfterViewInit(): void {
    (document.querySelector('.form') as HTMLElement).addEventListener(
      'scroll',
      (e) => {
        if (
          (
            document.querySelector('.contactoContainer') as HTMLElement
          ).getBoundingClientRect().top < 0
        ) {
          this.extraClass.optionSelected = 1;
        }
        if (
          (
            document.querySelector('.contactoContainer') as HTMLElement
          ).getBoundingClientRect().top > 0
        ) {
          this.extraClass.optionSelected = 0;
        }
      }
    );
  }

  /**
   *
   * HANDLERS
   */

  sucessGetTeachersHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.teacherSelectorOptions.items = data.map((teacher) => {
      return {
        ...teacher,
        name: teacher.user.name + ' ' + teacher.user.surname,
      };
    });
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  sucessGetMastersSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.masterSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetConvocatoriasByMasterHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.convocatoriaSelectorOptions.items = data;
    this.extraClass.setReadonliesInSelectorOptions();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetPromotionsByMasterAndConvocatoriaHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.edicionesSelectorOptions.items = data;
    this.extraClass.setReadonliesInSelectorOptions();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetAttendanceModalitySelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.modalidadSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetClassroomSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.aulaSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetSlotSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.slotsSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTopicsSelectorHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.tematicasSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successDeleteTopicHandler(res: iResultHttp) {
    const { data } = res;
    this.getTopicsByTeacherId();
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successCreateTutoringHandler(res: iResultHttp) {
    const { data } = res;
    StaticUtilitiesService.showFeedback('Tutoría creada correctamente');
    this.staticUtilitiesSE.goTo(
      'formaciones/listado-formaciones',
      slideNavigationTypes.slideToBottom
    );
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTopicsByTeacherIdHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.tematicasSelectorOptions.items = data;
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetTutoringInfoForDuplicateHandler(res: iResultHttp) {
    const { data } = res;
    this.setDataFromTutoringToDuplicate(data);
    this.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   *
   * FUNCTIONALITIES
   */

  scroll(type: string) {
    switch (type) {
      case 'informacion':
        (document.querySelector('.form') as HTMLElement).scroll({
          top: 0,
          behavior: 'smooth',
        });
        break;
      case 'descripcion':
        (document.querySelector('.form') as HTMLElement).scroll({
          top:
            (document.querySelector('.form') as HTMLElement).scrollHeight / 3.7,
          behavior: 'smooth',
        });
        break;
    }
  }
  confirmPopup() {
    this.popupSE.openPopup(ConfirmarAccionPopupComponent, {
      accion: 'Descartar',
      elemento: 'la creación de la tutoria',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((data) => {
        if (data === undefined) return;
        if (data?.returnValue) {
          this.staticUtilitiesSE.goTo(
            '/formaciones',
            slideNavigationTypes.slideToBottom
          );
        }
        this._unsubInd.next('');
      });
  }

  goBack() {
    this.staticUtilitiesSE.goTo(
      '/formaciones/listado-formaciones',
      slideNavigationTypes.slideToBottom
    );
  }

  getConvocatorias(value, key) {
    DebouncerService.formDebouncer(
      () => this.getConvocatoriasByMaster({ [key]: value }),
      key,
      value
    );

    if (value.length === 0) {
      this.extraClass.convocatoriaSelectorOptions.items = [];
      this.extraClass.edicionesSelectorOptions.items.length = 0;
      this.extraClass.ngModels.promotion = null;
      this.extraClass.ngModels.convocatoria = null;

      this.extraClass.setReadonliesInSelectorOptions();
      return;
    }
  }

  getEditions(value, key) {
    DebouncerService.formDebouncer(
      () =>
        this.getPromotionsByMasterAndConvocatoria({
          [key]: value,
          formationIds: this.extraClass.ngModels.master,
        }),
      key,
      value
    );
    if (value.length === 0) {
      this.extraClass.edicionesSelectorOptions.items = [];
      this.extraClass.ngModels.promotion = null;
      this.extraClass.setReadonliesInSelectorOptions();
      return;
    }
  }

  onTopicChange(topics) {
    this.generateTopicsDisplay(topics);
  }

  generateTopicsDisplay(topics) {
    const topicNamesArray = topics.map(
      (topicId) =>
        this.extraClass.tematicasSelectorOptions.items.find(
          (topic) => topic.id === topicId
        )?.name
    );
    const topicsToDisplay = topicNamesArray.join(' + ');
    this.extraClass.ngModels.topicDisplay = topicsToDisplay;
  }

  onFinalizarClick() {
    const objectToPass = this.generateBody();
    this.createTutoring(objectToPass);
  }

  generateBody() {
    return {
      teacherId: this.extraClass.ngModels.teacher,
      promotionIds: this.extraClass.ngModels.promotion,
      tutoringAttendanceId: this.extraClass.ngModels.modality,
      topicIds: this.extraClass.ngModels.topic,
      observations: this.extraClass.ngModels.observations,
      landmark: {
        startDate: this.extraClass.ngModels.startDate,
        startHour: this.extraClass.ngModels.startTime,
        endHour: this.extraClass.ngModels.endTime,
        slotId: this.extraClass.ngModels.slots,
        classroomId: this.extraClass.ngModels.classroom,
        landmarkTypeId: 3,
      },
    };
  }
  checkIsComplete() {
    const objectToCheck = { ...this.extraClass.ngModels };
    delete objectToCheck.observations;
    const values = Object.values(objectToCheck);

    this.extraClass.isActive = values.every((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      } else {
        return value !== null;
      }
    });
  }

  setNgModelDate(date, key) {
    this.extraClass.ngModels[key] = iDate
      .javascriptConvert(date)
      .toStringDate('JAP');
  }

  clearTopics() {
    this.extraClass.tematicasSelectorOptions.items.length = 0;
    this.extraClass.ngModels.topic = [];
    this.extraClass.ngModels.topicDisplay = null;
  }

  getParams(): Promise<any> {
    return new Promise((res, _) => {
      const params = this.staticUtilitiesSE.getParams();
      if (params.duplicate) {
        this.extraClass.tutoringForDuplicate = params.duplicate;
        res(true);
      }
    });
  }

  setDataFromTutoringToDuplicate(data: iTutoringSessionForDuplicate) {
    const {
      classroomId,
      convocatoria,
      endHour,
      formations,
      observations,
      promotions,
      startDate,
      startHour,
      teacherId,
      topicName,
      topics,
      tutoringAttendance,
      slotId,
    } = data;
    let ngModels = this.extraClass.ngModels;

    ngModels.classroom = classroomId;
    ngModels.convocatoria = convocatoria;
    ngModels.endTime = endHour;
    ngModels.master = formations;
    ngModels.modality = tutoringAttendance;
    ngModels.promotion = promotions;
    ngModels.slots = slotId;
    ngModels.startDate = new Date(startDate);
    ngModels.startTime = startHour;
    ngModels.teacher = teacherId;
    ngModels.topic = topics;
    ngModels.topicDisplay = topicName;
    ngModels.observations = observations;

    if (teacherId) {
      this.getTopicsByTeacherId();

      if (topics) {
        setTimeout(() => {
          ngModels.topic = topics;
          ngModels.topicDisplay = topicName;
        }, 1000);
      }
    }
    if (formations) {
      this.getConvocatorias(formations, 'formationIds');
    }
    if (convocatoria) {
      this.getEditions(convocatoria, 'dates');
    }
  }

  /**
   * API CALLS
   */

  getTeachers() {
    this.generalLoaderSE.addToLoaderAmount();
    this.teacherSE.getTeachersSelector();
    this.teacherSE
      .getResult()
      .pipe(
        takeUntil(this._unsubInd),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.sucessGetTeachersHandler(res);
        this._unsubInd.next('');
      });
    this.teacherSE
      .getResultError()
      .pipe(
        takeUntil(this._unsub),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getMastersSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    this.masterSE.getMastersForFilterWithFormationId();
    this.masterSE
      .getResult()
      .pipe(
        takeUntil(this._unsubInd2),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.sucessGetMastersSelectorHandler(res);
        this._unsubInd2.next('');
      });
    this.masterSE
      .getResultError()
      .pipe(
        takeUntil(this._unsub),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.generalLoaderSE.removeFromLoaderAmount();
        if (res.status != 401) {
          if (res.status == 404) {
            StaticUtilitiesService.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            StaticUtilitiesService.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            StaticUtilitiesService.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }

  getConvocatoriasByMaster(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.convocatoriaPorMastersSelector(
      behaviorSubject,
      objectToPass
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetConvocatoriasByMasterHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getPromotionsByMasterAndConvocatoria(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.promotionSE.edicionPorMasterYConvocatoriaSelector(
      behaviorSubject,
      objectToPass
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successGetPromotionsByMasterAndConvocatoriaHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getAttendanceModalitySelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.getTutoringAttendanceSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetAttendanceModalitySelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getClassroomSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.classroomSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetClassroomSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getSlotSelector() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.slotSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetSlotSelectorHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getTopicsByTeacherId() {
    this.clearTopics();
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.topicSE.getTopicsByTeacher(
      behaviorSubject,
      this.extraClass.ngModels.teacher
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetTopicsByTeacherIdHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  deleteTopic(ids: number) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.topicSE.delete(behaviorSubject, ids);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successDeleteTopicHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  createTutoring(objectToPass) {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.create(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateTutoringHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
          {
            method: () => this.conflictsSE.manageConflicts(res),
            error: true,
          },
        ]);
      });
  }

  getTutoringInfoForDuplicate() {
    this.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.tutoringSE.getTutoringInfoForDuplicate(
      behaviorSubject,
      this.extraClass.tutoringForDuplicate
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetTutoringInfoForDuplicateHandler(res),
          },
          {
            method: () => this.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
