<div class="fullView">
  <div class="topBar">
    <p class="fwBold">Precio y financiación</p>
  </div>

  <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="wayToPay"
    (change)="sendToUpdate($event,'enrollment','enrrollment') ">
    <div class="precioLines" *ngFor="let formation of studentDataStepThree.prices">
      <ng-container *ngIf="studentDataStepThree.enrollments[0].type !== 'Solo proyecto';" >
      
      <ng-container *ngFor="let price of formation.formation">
        <div class="line" *ngIf="price.paymentModality.id !== 6 && price.paymentModality.id !== 2">
          <mat-radio-button class="example-radio-button" [value]="price.paymentModality.id" (change)="sendIsNeededMetodoDePago(price.paymentModality.id)" >
            <div class="insideRadioButton">
              <div>
              <p>{{price.paymentModality.modality}}</p>
            </div>
            <div>
              <p class="fwBold">{{price.price | currency:'EUR'}}</p>
            </div>
            </div>
          </mat-radio-button>
        </div>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="studentDataStepThree.enrollments[0].type == 'Solo proyecto';" >
      
      <ng-container *ngFor="let price of formation.formation">
        <div class="line"  *ngIf="price.paymentModality.id == 6">
          <mat-radio-button class="example-radio-button" [value]="price.paymentModality.id"  >
            <div class="insideRadioButton">
              <div>
              <p>{{price.paymentModality.modality}}</p>
            </div>
            <div>
              <p class="fwBold">{{price.price | currency:'EUR'}}</p>
            </div>
            </div>
          </mat-radio-button>
        </div>
      </ng-container>

    </ng-container>
      
      <div class="bodyMasIndicacion" *ngIf="wayToPay === 'Reserva + Pago fraccionado a través del Banco Sabadell (hasta 9 cuotas)' || wayToPay === 'Reserva + pago fraccionado (6 cuotas)'" [class.active]="wayToPay === 'Reserva + Pago fraccionado a través del Banco Sabadell (hasta 9 cuotas)' || wayToPay === 'Reserva + pago fraccionado (6 cuotas)'">
        <p class="fwBold">El importe de la reserva es de {{reserva | currency: 'EUR'}}</p>
      </div>
      <div *ngIf="condicionesEspeciales">
        <p class="fwBold conditionsText">Condiciones especiales</p>
        <div class="line">
        <mat-radio-button class="example-radio-button" [value]="'Condiciones especiales'">
          <div class="insideRadioButton">
            <p>{{condicionesEspeciales}}</p>
          </div>
        </mat-radio-button>
      </div>
      </div>
    </div>
  </mat-radio-group>



  <div class="bodyMasIndicacion" [class.active]="wayToPay === 'Reserva + Pago fraccionado a través del Banco Sabadell (hasta 9 cuotas)' || wayToPay === 'Pago fraccionado a través del Banco Sabadell (hasta 12 cuotas)' || wayToPay === 'Reserva + pago fraccionado (6 cuotas)' ">

    <p class="fwBold">Te enviaremos un correo solicitándote la documentación para tramitar la financiación.</p>
  </div>
  


</div>