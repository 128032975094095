import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IconsHandlerService {
  private static duplicateIcon = '/assets/Images/Icons/duplicate.svg';
  private static deleteIcon = '/assets/Images/Icons/delete.svg';
  private static workIcon = '/assets/Icons/General/work.svg';
  private static personIcon = '/assets/Icons/General/person.svg';
  private static modality = '/assets/Icons/General/modality.svg';
  private static infoIcon = '/assets/Icons/General/infoIcon.svg';
  private static dialogIcon = '/assets/Icons/General/dialog.svg';
  private static videoCam = '/assets/Icons/General/videoCam.svg';
  private static locationIcon = '/assets/Icons/General/location.svg';
  private static schoolIcon = '/assets/Icons/General/school.svg';
  private static outlinedPencil = '/assets/Icons/General/outlinedPencil.svg';
  private static deleteGreyIcon = '/assets/Icons/General/deletegrey.svg';
  private static penOutside = '/assets/Icons/General/penOutside.png';
  private static formationIcon = '/assets/Icons/General/formation.svg';
  private static studentIcon = '/assets/Icons/General/student.svg';
  private static timelineIcon = '/assets/Icons/General/timeline.svg';
  private static fijltrarIcon = '/assets/Icons/General/filtrar.svg';
  private static roundedCheckIcon = '/assets/Icons/General/roundedCheck.svg';
  private static calendarIcon = '/assets/Icons/General/calendar.svg';
  private static sendDatesIcon = '/assets/Icons/General/sendDatesIcon.svg';
  private static editBlueIcon = '/assets/Icons/General/editBlue.svg';
  private static uploadIcon = '/assets/Icons/General/uploadIcon.svg';
  private static downloadIcon = '/assets/Icons/General/downloadIcon.svg';
  private static sendBlueIcon = '/assets/Icons/General/sendBlueIcon.svg';
  private static bookmarkIcon = '/assets/Icons/General/bookmark.svg';

  constructor() {}

  public static getDuplicateIcon() {
    return this.duplicateIcon;
  }

  public static getDeleteIcon() {
    return this.deleteIcon;
  }

  public static getInfoIcon() {
    return this.infoIcon;
  }

  public static getDialogIcon() {
    return this.dialogIcon;
  }

  public static getVideoCam() {
    return this.videoCam;
  }

  public static getLocationIcon() {
    return this.locationIcon;
  }

  public static getSchoolIcon() {
    return this.schoolIcon;
  }

  public static getOutlinedPencil() {
    return this.outlinedPencil;
  }

  public static getDeleteGreyIcon() {
    return this.deleteGreyIcon;
  }

  public static getWorkIcon() {
    return this.workIcon;
  }

  public static getPersonIcon() {
    return this.personIcon;
  }

  public static getPenOutside() {
    return this.penOutside;
  }

  public static getModality() {
    return this.modality;
  }

  public static getFormationIcon() {
    return this.formationIcon;
  }

  public static getStudentIcon() {
    return this.studentIcon;
  }

  public static getTimelineIcon() {
    return this.timelineIcon;
  }

  public static getFiltrarIcon() {
    return this.fijltrarIcon;
  }

  public static getRoundedCheckIcon() {
    return this.roundedCheckIcon;
  }

  public static getCalendarIcon() {
    return this.calendarIcon;
  }

  public static getSendDatesIcon() {
    return this.sendDatesIcon;
  }

  public static getEditBlueIcon() {
    return this.editBlueIcon;
  }

  public static getUploadIcon() {
    return this.uploadIcon;
  }

  public static getDownloadIcon() {
    return this.downloadIcon;
  }

  public static getSendBlueIcon() {
    return this.sendBlueIcon;
  }

  public static getBookmarkIcon() {
    return this.bookmarkIcon;
  }
}
