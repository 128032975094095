import { Component, Input, OnInit } from '@angular/core';
import { StaticUtilitiesService, iResultHttp, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { StudentService } from 'src/app/Services/Api/Student.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'informacionAlumno',
  templateUrl: './GestionUsuarios-Alumnos-Detalles-Informacion.component.html',
  styleUrls: ['./GestionUsuarios-Alumnos-Detalles-Informacion.component.scss']
})
export class GestionUsuariosAlumnosDetallesInformacionComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() dataStudent: any = null;
  @Input() isDataLoaded: boolean = false;

  isEditing: boolean = false;
  timeoutId: any = null;

  tarifas: string = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "

  constructor(private studentSE:StudentService) { 
    super();
  }


  ngOnInit() {
    setTimeout(() => {
      this.isDataLoaded = true;
    }, 1500);
  }

  /**
   * FUNCTIONALITY
   */

  openSocialMedia(url){
    window.open(url, "_blank");
  }

  changeEdit(){
    this.isEditing = !this.isEditing;
  }

  
  /**
   * API CALLS
   */

  editObresvaciones(){
    this.studentSE.update({id: this.dataStudent.id, user:{ observation: this.dataStudent.user.observation }});
    this.studentSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Observaciones actualizadas correctamente');
      this._unsubInd.next('')
    })
    this.studentSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          StaticUtilitiesService.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          StaticUtilitiesService.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          StaticUtilitiesService.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }


}
