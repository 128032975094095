import { Component, OnInit } from '@angular/core';
import { StaticUtilitiesService, iStaticUtilities, iUnsubscribeDestroy, sha256, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { LoginService } from 'src/app/Services/Api/Login.service';
import { takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-RecoverPassword',
  templateUrl: './RecoverPassword.component.html',
  styleUrls: ['./RecoverPassword.component.scss']
})
export class RecoverPasswordComponent extends iUnsubscribeDestroy implements OnInit {

  isLogging: boolean = false;

  data: any = {
    password:null,
    password2:null
  };
  objectToPass: any = {
    new_password: this.data.password,
    recover_token: null,
  };
  regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  disabled: boolean = true;
  emailCorrect: boolean = false;


  constructor(private loginSE: LoginService, private staticUtilitiesSE:StaticUtilitiesService,private activatedRoute: ActivatedRoute,private router:Router) {
    super();
  }

  ngOnInit() {
    this.getToken();
  }
  
  ngAfterViewInit() {
    this.getButtonSize();
  }


  /**
   * FUNCTIONALITY
   */


  getToken() {
    if(!this.activatedRoute.snapshot.queryParamMap.get('token')){
      StaticUtilitiesService.showError('No tienes permiso para entrar a esta página')
      this.router.navigate(['/login'])
    }
    this.objectToPass.recover_token = this.activatedRoute.snapshot.queryParamMap.get('token')!.replaceAll(' ', '+')
  }

  checkPasswords(){
    if((this.data.password == this.data.password2) && (this.data.password !== null && this.data.password2 !== null)){
      this.disabled = false;
    }else{
      this.disabled = true;
    }
  }
  checkIfPasswords(){
    if((this.data.password !== this.data.password2) && (this.data.password  && this.data.password2 )){ 
      StaticUtilitiesService.showWarning('Las contraseñas no coinciden');
    }
  }

  checkDisabled(){
    if(this.data.password && this.data.password2){
      this.disabled = false;
    }else{
      this.disabled = true;
    }
  }

  getButtonSize() {
    let input = document.querySelector(".customInputLogin")!;
    let inputComputedStyle = window.getComputedStyle(input);
    let inputSize = parseFloat(inputComputedStyle.getPropertyValue("width"));
    (document.querySelector('#btn')! as HTMLElement).style.width = inputSize + 'px';
  }


  /**
   * API CALLS
   */  

  sendCredentials() {
    let sha256Data = sha256(this.data.password);
    let newObject = {
      recover_token: this.objectToPass.recover_token,
      new_password: sha256Data
    }
    this.loginSE.cambiarPassword(newObject);
    this.loginSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return;}
      StaticUtilitiesService.showFeedback('Se ha cambiado la contraseña correctamente');
      this.router.navigate(['/login'])
      this._unsubInd.next('');
    })
    this.loginSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(!res){return;}
      StaticUtilitiesService.showError(res.message)
      this._unsub.next('');
    })
  }

}
